import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "../../../components/core";
import { Api } from "../../../components/Axios";
import "./edit-sys-log.scss";
import { usePartnerStateContext } from "../../../store/PartnerContextProvider";
import CheckboxAutocomplete from "../../../components/core/AMAutoComplete/AMAutoComplete";
import { DialogActions } from "@mui/material";

type SyslogServer = {
  id?: string;
  host?: string;
  port?: string;
  transport?: string;
  auth_required: boolean;
  certificate?: string;
  cert_pvt_key?: string;
  cert_chain?: string;
  remote_server: string;
};

type ActionType = {
  type?: string;
  id?: string;
};

interface Props {
  id?: string;
  AfterEditOperation?: any;
}

export const EditPanel = ({ id, AfterEditOperation }: Props) => {
  const {
    register,
    getValues,
    handleSubmit,
    errors,
    setValue,
    watch,
    control,
  } = useForm<SyslogServer>();
  const [syslogServer, setSyslogServer] = useState<SyslogServer>();
  const [destinationType, setDestinationType] = useState<string>();
  const [testConnection, setTestConnection] = useState<string>("test");
  const [loadingSyslog, setLoadingSyslog] = useState<Boolean>(false);
  const [loadingTestSyslog, setLoadingTestSyslog] = useState<Boolean>(false);
  const [showenabled, setShowEnabled] = useState<Boolean>(false);
  const [tlsCheck, setTlsCheck] = useState<boolean>();
  const [portType, setPortType] = useState<string | undefined>();
  const { PartnerConfigState } = usePartnerStateContext();
  const { addToast } = useToasts();
  const watchPort = watch(["port", "host"]);
  const handleTlsFlagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.target.checked) {
      setTlsCheck(true);
      setShowEnabled(true);
    } else {
      setTlsCheck(false);
      setShowEnabled(false);
    }
  };
  const [selectedVal, setSelectedVal] = useState<any>([{}]);
  const [selectedTransportVal, setSelectedTransportVal] = useState<any>([{}]);

  

  const partnerName = PartnerConfigState?.PartnerName;

  const options = [
    { key: "syslog", value: "Remote SysLog Server" },
    {
      key: "s3_bucket",
      value: `Cloud Storage Bucket (Hosted by ${partnerName})`,
    },
  ];

  const transportOptions = [
    { key: "UDP", value: "UDP" },
    { key: "TCP", value: "TCP" },
    { key: "TLS", value: "TLS" },
  ];

  const getItemLabel = (
    option: { key: string; value: string } | { key: string; value: string }[]
  ) => {
    if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
    } else {
      return option?.value ? option?.value : "";
    }
  };

  useEffect(() => {
    Api.get("/audittrail/serverinfo")
      .then((res: { data: any }) => {
        console.log("Get request", res.data);
        setValue("host", res.data.host);
        setValue("port", res.data.port);
        if (res.data.port === "TLS") {
          //   setPortType("TLS")
        }
        // setValue("transport", res.data.transport)
        setPortType(res.data.transport);
        setValue("auth_required", res.data.auth_required);
        if (res.data.auth_required === true) {
          setTlsCheck(true);
          setShowEnabled(true);
        }
        setValue("certificate", res.data.certificate);
        setValue("cert_pvt_key", res.data.cert_pvt_key);
        setValue("cert_chain", res.data.cert_chain);
        setValue("remote_server", res.data.remote_server);
        setDestinationType(res.data.remote_server);
        setSelectedVal([options.find(opt => opt.key === res.data.remote_server)]);
        setSelectedTransportVal([transportOptions.find(opt => opt.key === res.data.transport)]);
      })
      .catch((error: any) => {});
  }, [id]);

  const handleTestConnection = () => {
    setTestConnection("test");
    setLoadingTestSyslog(true);
    const headers = { Operation: "PUT" };
    const values = getValues();
    if (values.remote_server) {
      values.remote_server = values.remote_server[0].key;
    }
    if(values?.transport){
        values.transport = values.transport[0].key;
    }
    Api.post("/audittrail/serverinfo?type=test", values, {
      headers: headers,
    })
      .then((res: { data: any }) => {
        setLoadingTestSyslog(false);
        // AfterEditOperation('edit')
        if (res.data === true) {
          setTestConnection("true");
        } else {
          setTestConnection("");
        }
      })

      .catch((error: any) => {
        setLoadingTestSyslog(false);
        if (error.response.status === 500) {
          setTestConnection("");
        }
      });
  };

  const onUpdate = (data: any) => {
    setLoadingSyslog(true);
    const headers = { Operation: "PUT" };
    const values = data;
    if (values.remote_server) {
      values.remote_server = values.remote_server[0].key;
    }
    if(values?.transport){
        values.transport = values.transport[0].key;
    }
    Api.post("/audittrail/serverinfo", data, { headers: headers })
      .then((res: { data: any }) => {
        console.log("Server Post...", data);
        setLoadingSyslog(false);
        AfterEditOperation("edit");
        addToast("Log server configuration updated successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error: any) => {
        setLoadingSyslog(false);
        AfterEditOperation("edit");
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error.response.status === 419) {
          addToast(
            "We encounted validation problem, please correct and try again.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        } else if (error.response.status === 404) {
          addToast("Please check and try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleSelectionChange = (event: any, filteredValue: any) => {
    setSelectedVal(filteredValue);
    setDestinationType(filteredValue[0].key);
  };

  const handleTransportSelectionChange = (event: any, filteredValue: any) => {
    setSelectedTransportVal(filteredValue);
    setPortType(filteredValue[0].key);
  }

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <div className="edit-sys-log-main">
        <label className="margintop0">
          {testConnection === "true" ? (
            <span style={{ color: "green" }}>Test Connection Success.</span>
          ) : testConnection === "" ? (
            <span style={{ color: "red" }}>Test Connection Failed.</span>
          ) : null}
        </label>
        <div className="edit-sys-log-form-container">
          <div className="form-control-item span-3">
            <label>Log Destination* </label>
            {/* <select className="form_drodown" name="remote_server" ref={register}
                            onChange={(e) => { setDestinationType(e.currentTarget.value); }} value={destinationType}
                        >

                            <option value="syslog">Remote Log Server</option>
                            <option value="s3_bucket">Cloud Storage Bucket(Hosted by {partnerName} )</option>
                        </select> */}

            <div style={{ width: "98%" , marginTop:'10px' }}>
              <Controller
                name="remote_server"
                control={control}
                value={selectedVal}
                render={({ onChange, value = "" }) => (
                  <CheckboxAutocomplete
                    label="Select options"
                    options={options}
                    value={selectedVal}
                    onChange={(event, newValue) => {
                      handleSelectionChange(event, newValue);
                      onChange(newValue);
                    }}
                    getItemLabel={getItemLabel}
                    multiple={false}
                    name="remote_server"
                  />
                )}
              />
            </div>
          </div>
          {destinationType != "s3_bucket" && (
            <>
              <div className="form-control-item">
                <label>Host*</label>
                <input
                  type="text"
                  placeholder="Enter host"
                  name="host"
                  ref={register({
                    required: false,
                  })}
                  defaultValue={syslogServer?.host}
                />
              </div>
              <div className="form-control-item">
                <label>Port*</label>
                <input
                  type="number"
                  placeholder="Enter Port"
                  name="port"
                  ref={register({
                    required: false,
                    min: {
                      value: 1,
                      message: "Please enter valid port.",
                    },
                  })}
                  defaultValue={syslogServer?.port}
                />
                {errors.port && (
                  <div className="error marginleft40per">
                    {errors.port.message}
                  </div>
                )}
              </div>
              <div className="form-control-item">
                <label>Transport* </label>
                {/* <select
                  className="form_drodown"
                  name="transport"
                  ref={register}
                  onChange={(e) => {
                    setPortType(e.currentTarget.value);
                  }}
                  value={portType}
                >
                  <option value="">Select Transport</option>
                  <option value="UDP">UDP</option>
                  <option value="TCP">TCP</option>
                  <option value="TLS">TLS</option>
                </select> */}

                <div className="transport-dropdown">
              <Controller
                name="transport"
                control={control}
                value={selectedVal}
                render={({ onChange, value = "" }) => (
                  <CheckboxAutocomplete
                    label="Select Transport"
                    options={transportOptions}
                    value={selectedTransportVal}
                    onChange={(event, newValue) => {
                      handleTransportSelectionChange(event, newValue);
                      onChange(newValue);
                    }}
                    getItemLabel={getItemLabel}
                    multiple={false}
                    name="transport"
                  />
                )}
              />
            </div>
              </div>
            </>
          )}
          {portType === "TLS" && destinationType != "s3_bucket" && (
            <>
              <div className="form-control-item span-2 flex-row-reverse">
                <label className="auto_width paddingright20">
                  TLS - Log Client Certficate required
                </label>
                <input
                  name="auth_required"
                  type="checkbox"
                  onChange={handleTlsFlagChange}
                  checked={tlsCheck ? tlsCheck : false}
                  ref={register}
                  className={"margintop20"}
                />
              </div>
              {showenabled &&
                portType === "TLS" &&
                destinationType != "s3_bucket" && (
                  <>
                    <div className="form-control-item span-3">
                      <label>Certificate*</label>
                      <textarea
                        name="certificate"
                        rows={6}
                        className="float_left"
                        placeholder="Enter Certificate in PEM-encoded format"
                        ref={register({
                          required: false,
                        })}
                        defaultValue={syslogServer?.certificate}
                      />
                    </div>
                    <div className="form-control-item span-3">
                      <label>Certificate Private Key*</label>
                      <textarea
                        name="cert_pvt_key"
                        rows={6}
                        className="float_left"
                        placeholder="Enter Certificate Private Key in PEM-encoded format"
                        ref={register({
                          required: false,
                        })}
                        defaultValue={syslogServer?.cert_pvt_key}
                      />
                    </div>
                    <div className="form-control-item span-3">
                      <label>Certificate Chain</label>
                      <textarea
                        name="cert_chain"
                        rows={6}
                        className="float_left"
                        placeholder="Enter Certificate chain in PEM-encoded format"
                        ref={register({
                          required: false,
                        })}
                        defaultValue={syslogServer?.cert_chain}
                      />
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </div>
      <div className="edit-sys-log-action-buttons dialog-button">
        <button
          type={"button"}
          style={{
            width: "auto",
            marginLeft: "-0.2rem",
            visibility: `${
              watchPort.port && watchPort.host && portType
                ? "visible"
                : "hidden"
            }`,
          }}
          className={" " + (loadingTestSyslog ? "loader" : "button_styled")}
          onClick={handleTestConnection}
        >
          Test Connection
        </button>

        <button
          type={loadingSyslog ? "button" : "submit"}
          className={
            "float_right " +
            (loadingSyslog ? "loader" : "button_styled") +
            ((watchPort.port && watchPort.host && portType) ||
            destinationType == "s3_bucket"
              ? ""
              : " disableItems")
          }
          disabled={
            (watchPort.port && watchPort.host && portType) ||
            destinationType == "s3_bucket"
              ? false
              : true
          }
        >
          Save
        </button>
      </div>
    </form>
  );
};
