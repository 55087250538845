import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";
import { usePostureAction } from "../../pages/identity_asset/ref/Hooks/PostureAction";
import { PostureMenuItemAction } from "../../pages/identity_asset/ref/PostureActionMenus/PostureActionMenu";

export interface MarkKnownOption {
    optionValue: string,
    optionLabel: string
}

const MarkKnownOptionsDefault: MarkKnownOption[] = [
    { optionValue: PostureMenuItemAction.KNOWN_APP, optionLabel: 'Known App' },
    { optionValue: PostureMenuItemAction.KNOWN_DIR, optionLabel: 'Known Directory/IDP' },
    { optionValue: PostureMenuItemAction.KNOWN_INTER, optionLabel: 'Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)' },
    { optionValue: PostureMenuItemAction.KNOWN_MFA, optionLabel: 'Known MFA Server' }
]

export const PostureMarkKnownPopUp = (props: any) => {
    const { isAuthorizedAction } = usePostureAction();
    const [markKnown, setMarkKnown] = useState(PostureMenuItemAction.KNOWN_APP.toString());
    const { addToast } = useToasts()
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isDisabledSelected, setIsDisabledSelected] = useState(false);
    const onProceedClick = () => {
        const result: string[] = [];
        let resultSet: string[] = [];
        if (props.query) {
            resultSet = [];
        } else {
            props.data.host_detail.forEach((item: any) => {
                if (item.ip && item.ip.length > 0) {
                    result.push(item.ip);
                }
                if (item.hostname && item.hostname.length > 0) {
                    result.push(item.hostname);
                }
            });
            resultSet = Array.from(new Set(result));
        }
        switch (markKnown) {
            case 'known_app': {
                handleKnownApp(resultSet);
            }
                break;
            case 'known_dir': {
                handleKnownDir(resultSet);
            }
                break;
            case 'known_inter': {
                handleKnownInter(resultSet);
            }
                break;
            case 'known_mfa': {
                handleKnownMFA(resultSet);
            }
                break;
        }
    }

    const handleKnownApp = (fqdn: any) => {
        let api;
        if (props.query) {
            let request = {
                exclude_incidents_list: props.exclude_incidents_list || [],
                search_query: props.query,
                mark_as_type: 'known'
            };
            api = Api.post('/bulkmarkasknown', request);
        } else {
            let request = {
                name: props.data._id,
                fqdn: fqdn,
                is_saas_app: props?.isSaaSApp || false,
                global_saas_id: '',
                is_known: true
            };
            api = Api.post('/knownapplications', request);
        }

        api
            .then((res: { data: any }) => {
                props.onClose();
                addToast('Asset has been added as known application.', {
                    appearance: 'success',
                    autoDismiss: true,
                });
                updateActions('known_app');
            })
            .catch((error: any) => {
                props.onClose();
                if (error?.response?.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status == 409) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const handleKnownDir = (fqdn: any) => {
        let api;
        if (props.query) {
            let request = {
                exclude_incidents_list: props.exclude_incidents_list || [],
                search_query: props.query,
                mark_as_type: 'directory'
            };
            api = Api.post('/bulkmarkasknown', request);
        } else {
            const request = {
                name: props.data._id,
                ips: fqdn
            };
            api = Api.post('/directories', request)
        }
        api
            .then((res: { data: any }) => {
                props.onClose();
                addToast("Asset has been added as known directory.", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                updateActions('known_dir');
            })
            .catch((error: any) => {
                props.onClose();
                if (error.response.status !== 201) {
                    addToast("One of entered IP/FQDN is already used in other configured Identity System", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const handleKnownInter = (fqdn: any) => {
        let api;
        if (props.query) {
            let request = {
                exclude_incidents_list: props.exclude_incidents_list || [],
                search_query: props.query,
                mark_as_type: 'intermediary'
            };
            api = Api.post('/bulkmarkasknown', request);
        } else {
            const request = {
                ips: fqdn,
                name: props.data._id
            }
            api = Api.post('/intermediary', request)
        }

        api
            .then((res: { data: any }) => {
                props.onClose();
                addToast("Asset has been added as known intermediary.", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                updateActions('known_inter');
            })
            .catch((error: any) => {
                props.onClose();
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status == 409) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }


    const handleKnownMFA = (fqdn: any) => {
        let api;
        if (props.query) {
            let request = {
                exclude_incidents_list: props.exclude_incidents_list || [],
                search_query: props.query,
                mark_as_type: 'mfa'
            };
            api = Api.post('/bulkmarkasknown', request);
        } else {
            let request = {
                name: props.data._id,
                ips: fqdn
            }
            api = Api.post('/mfa', request);
        }
        api
            .then((res: { data: any }) => {
                props.onClose();
                addToast("Asset has been added as known MFA.", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                updateActions('known_mfa');
            })
            .catch((error: any) => {
                props.onClose();
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: false,
                    })
                } else if (error.response.status == 409) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    useEffect(() => {
        if (props.markKnownOptions?.hidePopUp) {
            setMarkKnown(props.markKnownOptions.defaultAction);
            onProceedClick();
        }
    }, []);

    const updateActions = (markAction: string) => {
        if (props.actionId) {
            let actions = sessionStorage.getItem('actions') as any;
            if (actions) {
                actions = JSON.parse(actions);
            } else {
                actions = {};
            }
            actions[props.actionId + '-' + markAction as keyof typeof actions] = Date.now() + 600000;
            actions = JSON.stringify(actions);
            sessionStorage.setItem('actions', actions);
        }
    }

    const isDisabled = (item: MarkKnownOption) => {
        let actions = sessionStorage.getItem('actions') as any;
        if (actions) {
            actions = JSON.parse(actions);
            const key = props.actionId + '-' + item.optionValue.toString();
            if (actions[key as keyof typeof actions]) {
                const time = actions[key as keyof typeof actions];
                if (time > Date.now()) {
                    return 'disableItems';
                } else
                    return '';
            }
        }
        return '';
    }

    const isAllRadioDisabled = (option?: string) => {
        const countOptions =
            document.querySelectorAll('.mark-known-popup .disableItems').length;
        if (props.markKnownOptions?.options?.length == countOptions || countOptions == 4) {
            setIsAllSelected(true);
        } else
            setIsAllSelected(false);

        const selection = document.querySelectorAll(`.mark-known-popup .disableItems.${option}`).length;
        if (selection > 0) {
            setIsDisabledSelected(true);
        } else {
            setIsDisabledSelected(false);
        }
    }

    useEffect(() => { setTimeout(() => isAllRadioDisabled(markKnown), 100) }, [])
    return <>
        <Popup
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={!props.markKnownOptions?.hidePopUp}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={props.onClose}
            modal
        >
            <div className="modal">
                <div className="close" onClick={props.onClose}></div>
                <div className="header">{props.markKnownOptions?.title || 'Mark Known'}</div>
                <div className="content">
                    <div className="font16 margintop20 marginbottom20">
                        <span>What is <strong>{props.data._id}</strong>?</span>
                        <RadioGroup
                            aria-labelledby="mark-known-radio-group"
                            name="radio-buttons-group"
                            defaultValue={markKnown}
                            onChange={(e: any) => {
                                setMarkKnown(e.target.value);
                                setTimeout(() => isAllRadioDisabled(e.target.value))
                            }}
                            className='mark-known-popup'
                        >
                            {
                                props.markKnownOptions ?
                                    props.markKnownOptions.options.
                                        map((item: MarkKnownOption) => {
                                            const isAllowed = isAuthorizedAction(item?.optionValue?.toString());
                                            if (isAllowed) {
                                                return <FormControlLabel
                                                    className={isDisabled(item) + ' ' + item.optionValue.toString()}
                                                    value={item.optionValue.toString()} control={<Radio />}
                                                    label={item.optionLabel} />
                                            }
                                            if (item?.optionValue?.toString() === markKnown && !isDisabledSelected) {
                                                setIsDisabledSelected(true);
                                            }
                                            return undefined

                                        }).filter((i: any) => i) :
                                    MarkKnownOptionsDefault.
                                        map((item: MarkKnownOption) => {
                                            const isAllowed = isAuthorizedAction(item?.optionValue?.toString());
                                            if (isAllowed) {
                                                return <FormControlLabel
                                                    className={isDisabled(item) + ' ' + item.optionValue?.toString()}
                                                    value={item.optionValue?.toString()} control={<Radio />}
                                                    label={item.optionLabel} />
                                            }
                                            if (item?.optionValue?.toString() === markKnown && !isDisabledSelected) {
                                                setIsDisabledSelected(true);
                                            }
                                            return undefined;
                                        }).filter((i: any) => i)
                            }
                        </RadioGroup>
                    </div>
                </div>
                <div className="popup_footer">
                    <button style={{ float: 'right' }} type="button"
                        className={"button_main width25per " + ((isDisabledSelected || isAllSelected) ? ' disableItems' : '')} onClick={onProceedClick}
                    >Save</button>
                    <button style={{ display: 'inline', float: 'left', marginRight: '2%' }}
                        type="button" className="button_gray width25per margintop10"
                        onClick={props.onClose}>Cancel</button>
                </div>
            </div>
        </Popup>
    </>
}