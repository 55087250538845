import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Controller, useWatch } from 'react-hook-form';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Api } from '../../../components/Axios';
import { isValidIp } from '../../../utils/util-methods';
import { AVAILABLE_SIEMS, MESSAGES, SENSOR_SETTINGS_KEYS } from '../constants/sensor-labels';
import './flowSensorConfiguration.scss';
import { AlertComponent } from '../AlertComponent';
import { formatDate } from '../../../utils/util-methods';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';


const availableDirectoryTypes = [
    { key: 'ADDS', value: 'AD DS' },
    { key: 'ADLDS', value: 'AD LDS' }
];
export const ADSensorConfiguration = ({
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    adSensorData,
    control,
    type

}) => {
    const [dns_servers, setDnsServers] = useState([]);
    const [dnsSearchKeyErrorMessage, setDnsSearchKeyErrorMessage] =
        useState('');
    const [dns_search_domain_keys, setDnsDomainSearchKey] = useState([]);
    const [isGCQuery, setIsGCQuery] = useState(false);
    const [proxyType, setProxyType] = useState([]);
    const [noProxy, setNoProxy] = useState(false);
    const [availableProxies, setAvailableProxies] = useState([]);
    const [availableEnrichmentSensors, setAvailableEnrichmentSensors] =
        useState([]);
    const [availableSiemVendors, setAvailableSiemVendors] =
        useState(AVAILABLE_SIEMS);
    const [siem, setSiem] = useState('');
    const [alerts, setAlerts] = useState([]);
    const [sensorData, setSensorData] = useState(null);
    const [flowSensorValue, setFlowSensorValue] = useState([]);
    const [directoryType, setDirectoryType] = useState(
        [{ key: 'ADDS', value: 'AD DS' }]
    );

    const [availableFlowSensors, setAvailableFlowSensors] = useState([
        { key: 'SIEM', value: 'Flow Sensor1' },
        { key: 'ADCI', value: 'Flow Sensor2' },
        { key: 'NA', value: 'Flow Sensor3' },
        { key: 'COP', value: 'Flow Sensor4' }
    ]);

    useEffect(() => {
        if (adSensorData?.flow_sensor_id) {
            const selectedSensor = availableFlowSensors.find(
                (obj) => obj.key === adSensorData.flow_sensor_id
            );
            setTimeout(() => {
                if (selectedSensor) {
                    setFlowSensorValue([selectedSensor]);
                    setValue('flow_sensors', [selectedSensor]);
                }
            }, 100);

        }
    }, [adSensorData, availableFlowSensors]);

    const fetchFlowSensorsForSIEM = () => {
        Api.get('/sensors/configuredflowsensors')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.result) {
                        setAvailableFlowSensors(
                            res.data.result.map((data: any) => ({
                                key: data._id,
                                value: data.name
                            }))
                        );
                    } else {
                        setAvailableFlowSensors([]);
                    }
                }
            })
            .catch((error: any) => { });
    };

    useEffect(() => {
        if (adSensorData) {
            getProxies();
            setDnsDomainSearchKey(adSensorData['dns_search_domain_keys']);
            setIsGCQuery(adSensorData['query_gc']);
            setAlerts(getAlertMessages(adSensorData));
            const directory = availableDirectoryTypes.filter(item => item.key === adSensorData['directory_type'])
            setTimeout(() => {
                setDirectoryType(directory);
                setValue('directory_type', directory);
            }, 200);

        } else {
            // Initialize for the add state
            setTimeout(() => {
                if (type.toString() === '1') {
                    setDirectoryType([{ key: 'ADDS', value: 'AD DS' }]);
                    setValue('directory_type', [{ key: 'ADDS', value: 'AD DS' }]);
                }
                if (type.toString() === '17') {
                    setDirectoryType([{ key: 'ADLDS', value: 'AD LDS' }]);
                    setValue('directory_type', [{ key: 'ADLDS', value: 'AD LDS' }]);
                }
            }, 100);

        }
    }, [adSensorData]);

    const getItemLabel = (
        option:
            | { key: string; value: string }
            | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    const getProxies = () => {
        Api.get('/proxies')
            .then((res: { data: any }) => {
                if (res.data && res.data.result.length > 0) {
                    setAvailableProxies(res.data.result);
                    setNoProxy(false);
                    setTimeout(() => {
                        if (adSensorData) {
                            const match = res.data.result.find(
                                (item) => item._id == adSensorData['proxy_id']
                            );
                            setProxyType([{ key: match._id, value: match.name }]);
                            setValue('proxy_id', [
                                { key: match._id, value: match.name }
                            ]);
                        }

                    }, 100);

                } else {
                    setNoProxy(true);
                }
            })
            .catch(() => { });
    };

    useEffect(() => {
        getProxies();
        fetchFlowSensorsForSIEM();
    }, []);

    const registerFields = () => { };

    useEffect(() => {
        registerFields();
    }, []);

    useEffect(() => {
        register('proxy_id', {
            required: false,
            shouldUnregister: true
        });
        // register('directory_type', {
        //     required: true,
        //     shouldUnregister: true
        // });
    }, []);

    const getAlertMessages = (data) => {
        let res = [];
        if (
            moment
                .duration(moment().diff(moment(data.last_flow_time)))
                .asHours() < 2
        ) {
            res.push(
                createAlertMessage(
                    `${MESSAGES[0]} ${formatDate(data.last_flow_time)}`,
                    'success',
                    false
                )
            );
        } else {

            res.push(
                createAlertMessage(
                    data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z")
                        ? `${MESSAGES[1]} ${formatDate(data.last_flow_time)} Expand to view specific issue details.`
                        : `${MESSAGES[11]} No flows received yet. Expand to view specific issue details.`,
                    'error',
                    true
                )
            )
            if (data.flows > 0) {
                res.push(
                    createAlertMessage(`${MESSAGES[2]}`, 'success', false)
                );
            } else {
                // res.push(createAlertMessage(`${MESSAGES[3]}`, 'error', false));
            }
            res.push(
                createAlertMessage(
                    data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z")
                        ? `${MESSAGES[4]} ${formatDate(data.last_flow_time)}`
                        : `${MESSAGES[12]} No flows received yet.`,
                    'error',
                    false
                )
            );

            const additionalErrors = sensorSettingsErrors(data);
            if (additionalErrors.length > 0) {
                additionalErrors.forEach(item => {
                    res.push(
                        createAlertMessage(
                            `${item}`,
                            'error',
                            false
                        )
                    );
                })
            }

        }

        return res;
    };

    const sensorSettingsErrors = (data) => {
        let res = [];
        if (data.sensor_settings) {
            res = Object.keys(data.sensor_settings).filter(item => SENSOR_SETTINGS_KEYS.includes(item)).map((sensor_setting: any, idx: number) =>
                `${sensor_setting} - ${data.sensor_settings[sensor_setting]}`
            )
        }
        return res;
    }

    const createAlertMessage = (text, type, isSummary) => {
        return { type, isSummary, text };
    };

    return (
        <>

            <div className='form-body-div scrollbar-container add-sensor-body-div'>
                {alerts.length > 0 && <AlertComponent messages={alerts} />}
                <div className='sensor-flex-container'>
                    <div className='sensor-flex-container-item'>
                        <label>Name*</label>
                        <input
                            type='text'
                            placeholder='Enter Sensor name'
                            name='name'
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/,
                                shouldUnregister: true
                            })}
                            className={'flex-basis-50'}
                            autoComplete='off'
                        />
                    </div>

                    <div className='sensor-flex-container-item'>
                        <label>Site Code*</label>
                        <input
                            type='text'
                            placeholder='ENTER 4 DIGIT ALPHANUMERIC CODE'
                            name='location'
                            className='uppercase flex-basis-50'
                            maxLength={4}
                            ref={register({
                                required: true,
                                shouldUnregister: true
                            })}
                        />
                    </div>
                </div>

                <div className='sensor-flex-container'>
                    <div className='sensor-flex-container-item'>
                        <label className='paddingright20 marginbottom10'>
                            Nearest Flow Sensor*
                        </label>

                        <div className={'flex-basis-50'}>
                            <Controller
                                name='flow_sensors'
                                control={control}
                                value={flowSensorValue}
                                render={({ onChange, value = '' }) => (
                                    <CheckboxAutocomplete
                                        label='Select options'
                                        options={availableFlowSensors}
                                        value={value || flowSensorValue}
                                        onChange={(event, newValue) => {
                                            setFlowSensorValue(newValue);
                                            onChange(newValue);
                                        }}
                                        getItemLabel={getItemLabel}
                                        multiple={false}
                                        name='flow_sensors'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='font12 sensor-flex-container-item'>
                        <label className='paddingright20'>Proxy</label>
                        {noProxy ? (
                            <>
                                <select
                                    disabled
                                    className=' form_drodown flex-basis-50'
                                >
                                    <option value={'no-proxy'}>
                                        No proxy found
                                    </option>
                                </select>
                                <div className='flex-basis-50'>
                                    <Link
                                        to='/proxy'
                                        className='link-text font12'
                                    >
                                        Add Proxy
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{ width: '98.5%', marginTop: '12%' }}
                                className='autocom'
                            >
                                <CheckboxAutocomplete
                                    label='Select Proxy'
                                    className={'flex-basis-50 form_drodown'}
                                    options={availableProxies.map((item) => ({
                                        key: item._id,
                                        value: item.name
                                    }))}
                                    value={proxyType}
                                    onChange={(e, val) => {
                                        setProxyType(val);
                                        setValue('proxy_id', val);
                                    }}
                                    getItemLabel={getItemLabel}
                                    multiple={false}
                                    name="proxy_id"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className='sensor-flex-container hidden'>
                    <div className='font12 sensor-flex-container-item'>
                        <label className='paddingright20'>Directory Type</label>
                        <div
                            style={{ width: '95%', marginTop: '12%' }}
                            className='autocom'
                        >
                            <CheckboxAutocomplete
                                label='Select Directory Type'
                                className={'flex-basis-50 form_drodown'}
                                options={availableDirectoryTypes}
                                value={directoryType}
                                onChange={(e, val) => {
                                    setDirectoryType(val);
                                    setValue('directory_type', val);
                                }}
                                getItemLabel={getItemLabel}
                                multiple={false}
                                name="directory_type"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <>
                        <input
                            name='query_gc'
                            type='checkbox'
                            className={'margintop20'}
                            checked={isGCQuery}
                            onChange={() => setIsGCQuery(!isGCQuery)}
                            ref={register}
                        />
                        <label
                            style={{
                                float: 'unset'
                            }}
                            className='auto_width paddingright20'
                        >
                            Do not query the Global Catalog or LDAP Server
                        </label>
                        <div className='error clrBoth font12'>
                            Note: Directory query parameters are required for proper
                            functioning of Flow Sensor.&nbsp;
                            <Link className='link-text font12' to='/dirQueryParam'>
                                <span className='font12'>
                                    You can set them here.
                                </span>
                            </Link>{' '}
                        </div>
                    </>
                </div>
            </div>

        </>
    );
};
