import React, { useEffect } from 'react';

export const CloudTrailCollector = ({ register, setValue, collectorData }) => {

  
    useEffect(() => {
        if (collectorData) {
            setValue(
                'cloudtrail_sqs',
                collectorData.collector_config?.cloudtrail_sqs
            );
            setValue(
                'cloudtrail_aws_access_key',
                collectorData.collector_config?.cloudtrail_aws_access_key
            );
            setValue(
                'cloudtrail_aws_access_secret',
                collectorData.collector_config?.cloudtrail_aws_access_secret
            );
        }
    }, [collectorData]);
    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
            <div className='form_sub_section_header'>
                        AWS S3 Configuration
                    </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS SQS URL*</label>
                            <input
                                type='text'
                                name='cloudtrail_sqs'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS SQS URL'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Access Key ID*</label>
                            <input
                                type='text'
                                name='cloudtrail_aws_access_key'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS Access Key ID'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Secret Access Key*</label>
                            <input
                                type='text'
                                name='cloudtrail_aws_access_secret'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS Secret Access Key'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
