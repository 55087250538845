import { Tooltip } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';

export const AMCIDRHelpText = () => {    
    return <>
        <Tooltip
            classes={{ tooltip: 'generic-tooltip-popper', popper: 'am-cidr-help-text-popper' }}
            title={<div>
                <span>Supported Inputs with Examples</span>
                <ul style={{ marginLeft: '1em' }}>
                    <li>IPs: 17.34.12.12</li>
                    <li>Patterns: 172.9.*</li>
                    <li>CIDR: 192.168.0.1/24</li>
                    <li>Range: 10.20.30.40-80</li>
                    <li>FQDN: dev-ad.example.com</li>
                </ul>
            </div>}
        ><span style={{ marginLeft: '0.1em' }}><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', transform: 'skewX(-10deg)' }} /></span></Tooltip>
    </>
}