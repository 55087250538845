import { AxiosError } from "axios";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import { ExceptionSearchFieldNames } from "../../../../common/AddExceptionPopUp/constant/ExceptionSearchField";
import { Api } from '../../../../components/Axios';
import { useToasts } from "../../../../components/core";
import { getFilterObjFromTag, getControlType, getSearchFieldObject, getControlTypeforTextBox } from "../../../../components/core/Filter/utils";
import { issueTemplate, notSupportedKeys } from "../../../issue_prev/issues/constant/issueTemplate";
import { getIssueNameById } from "../../../playbooks/helpers/playbook-helper";

export interface IDPostureCloseIncidentProps {
    handleCloseIDPosture(): any;
    content: string[],
    query: string,
    issueCount?: number,
    reloadList?(): any,
    actionId?: string
}

export const IDPostureCloseIncident = ({ handleCloseIDPosture, query, content, issueCount, reloadList, actionId }: IDPostureCloseIncidentProps) => {
    const [statusComment, setStatusComment] = useState('');
    const [closeTicket, setCloseTicket] = useState(true);
    const { addToast } = useToasts();

    const getSearchFilterObjectFromTags = (tags: any[]) => {
        try {
            const textBoxSupportedKeys = notSupportedKeys.map(p => p.search_key);
            const advSearchSupportedKeys = issueTemplate.map(p => p.search_key);
            const allSearchKeys = advSearchSupportedKeys.concat(textBoxSupportedKeys);

            const filterObj: any = {};
            for (let tag of tags) {
                const [key, val, operator] = getFilterObjFromTag(tag, allSearchKeys);
                const controlType = getControlType(issueTemplate, key, operator);
                const textBoxKeyValueType = getControlTypeforTextBox(notSupportedKeys, key);
                let controlValue = null;
                if (textBoxKeyValueType !== null) {
                    controlValue = getSearchFieldObject(textBoxKeyValueType, val);
                }
                else {
                    controlValue = getSearchFieldObject(controlType, val);
                }
                if (key === 'any_field') {
                    const anyFieldValue = filterObj[key]?.value;
                    if (anyFieldValue && anyFieldValue?.length > 0) {
                        filterObj[key]?.value?.push(...controlValue);
                    }
                    else {
                        filterObj[key] = { value: controlValue, type: operator }
                    }
                }
                else {
                    filterObj[key] = { value: controlValue, type: operator }
                }
            }
            if (Object.keys(filterObj || {}).length > 0) {
                return filterObj;
            }
        } catch (error) {
            console.log(error);
            return {};
        }
        return {};
    }

    const handleCloseTicket = () => {
        setCloseTicket(!closeTicket);
    }

    const handleYesIDPosture = () => {
        const tempQuery = JSON.parse(query);
        const queryData = Object.keys(tempQuery).map((key) => `${key}:${tempQuery[key as keyof typeof tempQuery]}`);
        let searchFieldsList;
        const searchHeaders = {
            headers: {
                search_fields: {}
            }
        }

        if ((queryData || []).length > 0) {
            searchFieldsList = getSearchFilterObjectFromTags(queryData);
            searchHeaders.headers.search_fields = searchFieldsList;
        }

        const data = {
            'close_option': 2,
            'disable_filter': true,
            'issues_id_list': [],
            'unchecked_issues_id_list': [],
            'close_ticket': closeTicket,
            'search_query': searchFieldsList,
            status_comment: statusComment
        }
        postCloseInc(data, searchHeaders);
    }

    const postCloseInc = (data: any, headers: any) => {
        const tableEl = document.getElementsByClassName('tab-panel')[0];
        tableEl?.classList.add('disableItems');
        Api.post("/issues/bulkclose", data, headers)
            .then(res => {
                if (res.status === 200) {
                    if (actionId) {
                        let actions = sessionStorage.getItem('actions') as any;
                        if (actions) {
                            actions = JSON.parse(actions);
                        } else {
                            actions = {};
                        }
                        actions[actionId as keyof typeof actions] = Date.now() + 600000;
                        actions = JSON.stringify(actions);
                        sessionStorage.setItem('actions', actions);
                    }

                    /* addToast("Bulk close incident request sent successfully.",{
                        appearance:'success',
                        autoDismiss: true
                    }); */
                    addToast(`Closing of ${issueCount} incident` + (issueCount == 1 ? '' : 's') + ` is in progress and might take up to a few minutes.`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    // reloadIssues();
                    tableEl?.classList.remove('disableItems');
                    handleCloseIDPosture();
                    if (reloadList) {
                        // reloadList()
                    }
                }
            })
            .catch((err: AxiosError) => {
                tableEl?.classList.remove('disableItems');
                handleCloseIDPosture();
                if (err.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (err.response?.status === 401) {
                    window.location.href = '/login';
                } else if (err.response?.status === 403) {
                    addToast("Bulk close incident can not be performed due to invalid credentials.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
        // setOpenConfirmPassword(false)
    }

    const renderExceptionCriteria = () => {
        let isDirExists = false;
        const ExceptionFields = Object.keys(ExceptionSearchFieldNames);
        return content.filter((item: string) => {
            const criteriaName = item.split(':')[0];
            return ExceptionFields.includes(criteriaName);
        }).map((item: string) => {
            let criteria = item.substring(item.indexOf(':') + 1);
            let criteriaName = item.split(':')[0];

            if (criteriaName === 'rule_name' && criteria.split(',').length > 1) {
                criteriaName = 'rule_name';
            }

            if (criteriaName === 'rule_name') {
                criteria = criteria.split(",").map((i) => getIssueNameById(i.trim())).join(",")
            }
            if (criteriaName === 'dir') {
                isDirExists = true;
            }

            if (criteriaName == 'rule_name' && isDirExists && criteria == 'Shadow Assets') {
                criteria = 'Shadow Identity Systems';
            } else if (criteriaName == 'rule_name' && isDirExists && criteria.includes('Shadow Assets')) {
                criteria = criteria.replace('Shadow Assets', 'Shadow Identity Systems');
            }

            const name = ExceptionSearchFieldNames[criteriaName as keyof typeof ExceptionSearchFieldNames];
            return <div className=""
                title={''}
                dangerouslySetInnerHTML={{ __html: `<div><span style='font-size:1.2em;'>&#8226;</span> <b>${name}</b> : ${criteria}</div> ` }}
            />
        });
    }

    return <>

        <Popup
            closeOnDocumentClick={false}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            closeOnEscape={false}
            modal
            open={true}
        >
            <div className={"modal"}>
                <div className={"close"} onClick={handleCloseIDPosture}></div>
                <div className={"header"}><div className="pbheader_ellipisis" title={'Close Incidents'}>Close Incident{issueCount && issueCount == 1 ? '?' : 's?'}</div></div>
                <div className={"content"}>
                    {
                        issueCount && issueCount > 0 ?
                            <><div className="font18 margintop20 marginbottom20">
                                Existing incidents will be closed but new incidents can still be created.<br></br>
                                Do you want to close incident{issueCount && issueCount == 1 ? '?' : 's?'}
                            </div>
                                <div className="posture-close-inc-criteria">
                                    <h4>Incident closing criteria:</h4> {
                                        <div className="exception_table_scroll exe-issues">
                                            {
                                                renderExceptionCriteria()
                                            }
                                        </div>
                                    }
                                </div>
                                <div>
                                    <label>Closing comment (optional)</label>
                                    <textarea className='posture-close-inc-textarea' style={{ resize: 'none' }} onChange={(e: any) => setStatusComment(e.target.value)}></textarea>
                                </div>
                                <div>
                                    <input className="margintop10 font14 close-ticket-check" type="checkbox" checked={closeTicket} onChange={handleCloseTicket} />
                                    <span className="font14">Close all tickets associated with these incidents.</span>
                                </div></> : <>
                                <div className="font18 margintop20 marginbottom20">
                                    {'There are no issues to close.'}
                                </div>
                            </>

                    }
                </div>
                <div className="shadow_footer fl" style={{ marginTop: 0 }}></div>
                <div className="popup_footer fl">
                    <div className="policy_defualt_button" onClick={handleCloseIDPosture}>{issueCount && issueCount > 0 ? 'No' : 'Ok'}</div>
                    {issueCount && issueCount > 0 ? <div className="policy_save_button" onClick={handleYesIDPosture}>Yes</div> : null}
                </div>
            </div>
        </Popup>
    </>
}