import React from "react";
import Popup from "reactjs-popup";

export const SensorListPopUp = (props: any) => {
    const closeConfirm = () => {
        props.closeSensorList();
    }
    return (
        <Popup
            {...props}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={props.showPopUp}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={closeConfirm}
            modal
            className="sensor-list-on-proxy"
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header">{`Impacted Sensors`}</div>
                <div className="content">
                    <div className="font16 margintop10 marginbottom20">{`Please redeploy the API key for the sensors below because they have been modified:`}</div>
                    {props?.data?.map((item: any) => <li className="font13" key={item._id}>{item.name}</li>)}
                </div>
                <div className="popup_footer">
                    {/* <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>Cancel</button> */}
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={closeConfirm}>OK</button>
                </div>
            </div>
        </Popup>
    );
}