import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchStateContext
} from '../useTimeBasedSearch';
import { MenuItem, Select, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { TIME_RANGE_OPERATORS } from '../Constants';
import {
    CalendarIcon,
    DatePicker,
    DateTimePicker,
    DateView
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import ACTION_TYPES from '../TimeBasedSearchActionTypes';
import moment from 'moment';
import './CustomRangeComponent.scss';
import CheckboxAutocomplete from '../../core/AMAutoComplete/AMAutoComplete';
import { AlertComponent } from '../../../pages/sensors/AlertComponent';

const useStyles = makeStyles({
    select: {
        '& ul': {
            paddingTop: '0px',
            paddingBottom: '0px',
            borderRadius: '0px'
        },
        '& li': {
            '&:hover': {
                backgroundColor: 'rgb(104 104 104 / 14%)'
            },
            '&.Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&:hover': {
                    backgroundColor: 'rgb(104 104 104 / 14%)'
                }
            }
        }
    }
});

const CustomRangeComponent = () => {
    const date60DaysAgo = moment().subtract(60, 'days');
    const date15DaysAgo = moment().subtract(14, 'days');

    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const onTimeRangeChange = (e, val) => {
        dispatchAction({
            type: ACTION_TYPES.SELECT_TIME_RANGE,
            value: val[0]?.key || TIME_RANGE_OPERATORS.BETWEEN.id
        });
    };

    const onDateChange = (type, value, operator) => {
        const startDate = value && value.isValid() ? moment(value.valueOf()) : moment();
        dispatchAction({ type, value: startDate, operator });
    };

    const onDateRangeChange = (type, value) => {
        const startDate = value[0] && value[0].isValid() ? moment(value[0].valueOf()) : moment().set('hour', 0).set('minute', 0)
        const endDate = value[1] && value[1].isValid() ? moment(value[1].valueOf()) : moment();
        dispatchAction({
            type,
            value: [
                startDate, endDate
            ]
        });
    };

    const onDateTimeRangeOpen = () => {
        setTimeout(() => {
            const divEl = document.getElementsByClassName(
                'custom-range-date-picker-popper'
            )[0] as HTMLDivElement;
            if (divEl) {
                const uls = divEl.querySelectorAll(
                    '.MuiMultiSectionDigitalClock-root ul'
                ) as NodeList;
                uls.forEach((e: Node, index: number) => {
                    const el = e as HTMLUListElement;
                    if (index != 2) el.classList.add('scrollbar-container');
                });
                const btn = divEl.querySelector(
                    'button.MuiButton-textPrimary'
                ) as HTMLButtonElement;
                if (btn) {
                    const cls = ['button_styled', 'popup-footer-primary-btn'];
                    cls.forEach((i) => btn.classList.add(i));
                }

                const yearChange = divEl.querySelector(
                    '.MuiPickersCalendarHeader-labelContainer'
                );
                if (yearChange) {
                    yearChange.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                    });
                }

                const calendarContainer = divEl.querySelector(
                    '.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition'
                );
                if (calendarContainer) {
                    calendarContainer.classList.add('scrollbar-container');
                }
            }
        });
    };

    const getDatePickerComponent = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div>
                    <DatePicker
                        onOpen={() => { setOpen(true); onDateTimeRangeOpen(); }}
                        open={open}
                        onClose={() => setOpen(false)}
                        renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                onClick={(e) => { setOpen(true); onDateTimeRangeOpen() }}
                              />
                            );
                          }}
                        slots={{
                            switchViewIcon: () => <></>,
                            switchViewButton: () => <></>
                        }}
                        slotProps={{
                            popper: {
                                className: 'custom-range-date-picker-popper'
                            },
                            textField: {
                                onClick: () => { setOpen(true); onDateTimeRangeOpen() },
                            },
                            actionBar: {
                                actions: ['accept'],
                            },
                        }}
                        value={moment(store.selectedStartDate)}
                        onChange={(newVal) =>
                            onDateChange(ACTION_TYPES.SELECT_START_DATE, newVal, TIME_RANGE_OPERATORS.EQUALS.id)
                        }
                        onViewChange={onDateTimeRangeOpen}
                        maxDate={moment()}
                        minDate={date60DaysAgo}
                    />
                </div>
            </LocalizationProvider>
        );
    };

    const getDateTimePickerComponent = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div>
                    <DateTimePicker
                        onOpen={() => { setOpen(true); onDateTimeRangeOpen(); }}
                        open={open}
                        onClose={() => setOpen(false)}
                        renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                onClick={(e) => { setOpen(true); onDateTimeRangeOpen(); }}
                              />
                            );
                          }}
                        slots={{
                            switchViewIcon: () => <></>,
                            switchViewButton: () => <></>
                        }}
                        slotProps={{
                            popper: {
                                className: 'custom-range-date-picker-popper'
                            },
                            textField: {
                                onClick: () => { setOpen(true); onDateTimeRangeOpen() },
                            },
                            actionBar: {
                                actions: ['accept'],
                            }
                        }}
                        value={moment(store.selectedStartDate)}
                        onChange={(newVal) =>
                            onDateChange(ACTION_TYPES.SELECT_START_DATE, newVal, TIME_RANGE_OPERATORS.AFTER.id)
                        }
                        maxDate={moment()}
                        minDate={date15DaysAgo}
                        timeSteps={{ minutes: 1 }}
                    />
                </div>
            </LocalizationProvider>
        );
    };

    const getDateTimeRangePickerComponent = () => {
        const startDate = moment(store.selectedStartDate)
        const endDate = moment(store.selectedEndDate)

        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className='date-time-range-picker'>
                    <DateTimeRangePicker
                        value={[
                            startDate,
                            endDate
                        ]}
                        onChange={(newVal) =>
                            onDateRangeChange(
                                ACTION_TYPES.SELECT_DATE_RANGE,
                                newVal
                            )
                        }
                        onOpen={onDateTimeRangeOpen}
                        slotProps={{
                            popper: {
                                className: 'custom-range-date-picker-popper'
                            },
                            textField: ({ position }) => ({
                                label: '',
                                InputProps: {
                                    endAdornment: (
                                        <CalendarIcon
                                            style={{ fill: 'rgba(0,0,0, 0.6)' }}
                                        />
                                    )
                                }
                            })
                        }}
                        format="MMM DD YYYY,h:mm A"
                        maxDate={moment()}
                        minDate={date60DaysAgo}
                        timeSteps={{ minutes: 1 }}
                    />
                </div>
            </LocalizationProvider>
        );
    };

    const getDateComponent = () => {
        switch (store.selectedTimeRange) {
            case TIME_RANGE_OPERATORS.BETWEEN.id:
                return getDateTimeRangePickerComponent();
            case TIME_RANGE_OPERATORS.AFTER.id:
                return getDateTimePickerComponent();
            case TIME_RANGE_OPERATORS.EQUALS.id:
                return getDatePickerComponent();
        }
    };

    const getItemLabel = (
        option:
            | { key: string; value: string }
            | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    const getSelectedVal = () => {
        const selectedTimeRangeObj = Object.keys(TIME_RANGE_OPERATORS).find(
            (item) =>
                TIME_RANGE_OPERATORS[item]['id'] === store.selectedTimeRange
        );
        const result = TIME_RANGE_OPERATORS[selectedTimeRangeObj]
            ? TIME_RANGE_OPERATORS[selectedTimeRangeObj]
            : TIME_RANGE_OPERATORS.BETWEEN;
        return { key: result.id, value: result.label };
    };

    return (
        <div className='custom-range-container'>
            {store.isApplyDisabled && <AlertComponent messages={[{ type: "error", isSummary: false, text: "Adjust your date range to be less than 15 days." }]} />}
            <div className=''>
                <div className='custom-range-field-label marginbottom10'>Time Range</div>
                <div className='custom-range-operator-select'>
                    <CheckboxAutocomplete
                        label='Select Option'
                        options={Object.keys(TIME_RANGE_OPERATORS).map(
                            (item) => ({
                                key: TIME_RANGE_OPERATORS[item].id,
                                value: TIME_RANGE_OPERATORS[item].label
                            })
                        )}
                        value={getSelectedVal()}
                        onChange={onTimeRangeChange}
                        getItemLabel={getItemLabel}
                        multiple={false}
                        showSeparator={false}
                        hideClearBtn={true}
                    />
                </div>
            </div>

            <div>
                <div className='custom-range-group-row'>
                    <div className='date-picker-container'>
                        {getDateComponent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomRangeComponent;
