import React from "react";
import { Box, Button, Typography } from "@mui/material";

interface AMTableEmptyProps {
  title: string;
  subTitle?: string;
  buttonClick: () => void;
  imageClasses: string[]; // Array of class names for image styles
}

const AMTableEmpty: React.FC<AMTableEmptyProps> = ({
  title,
  subTitle,
  buttonClick,
  imageClasses,
}) => {
  const handleButtonClick = () => {
    buttonClick();
  };

  return (
    <div className="empty-table-container">
      <strong className="no-data-title">
        <div className="no-data"></div>No {title} Collectors Configured
      </strong>
      <Typography className="no-data-paragraph">{subTitle}</Typography>
      <div className="font-text" style={{ marginBottom: "24px" }}>
        Example of supported vendors include :
      </div>
      <Typography className="marquee-container">
        <div className="marquee">
          <div className="marquee-content">
            {imageClasses?.map((className, index) => (
              <div key={index} className={`marquee-item ${className}`}></div>
            ))}
            <a
              onClick={handleButtonClick}
              style={{
                
              }}
              className="link-text view-all-text"
            >
              View All
            </a>
          </div>
        </div>
      </Typography>
    </div>
  );
};

export default AMTableEmpty;
