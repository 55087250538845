import { BaseProps } from '../../constants/types';
import { useState, useEffect } from 'react';
import { SuspectedAccountSharingState } from '../../../../types/playbooks-config';
import { Controller, useForm } from 'react-hook-form';
import './account-takeover.scss';
import { MenuItem, Select } from '@mui/material';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';
import React from 'react';

const SuspectedAccessTokenSharing = ({ formType, getState, defaultState, parentState, isSavedOnce }: BaseProps<SuspectedAccountSharingState>) => {
    const [state, setState] = useState<SuspectedAccountSharingState>(defaultState);
    const { register, errors, control, getValues, setValue, reset } = useForm<SuspectedAccountSharingState>();

    useEffect(() => {
        getState(state)
    })

    useEffect(() => {
        setState(defaultState);
        reset(defaultState)
    }, [defaultState]);

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            reset(parentState);
        }
    }, [isSavedOnce]);

    const handleFormChange = () => {
        const s = getValues() as any;
        if (s?.session_token_params?.assertion_timeout) {
            s.session_token_params.assertion_timeout = s?.session_token_params?.assertion_timeout ?
                parseInt(s?.session_token_params?.assertion_timeout.toString()) : null;
        }
        setState(s)
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const getValue = (item) => {
        const arr = ['minutes', 'hours'].map((item) => ({ key: item, value: item }))
        const i = arr.filter((it) => it.key == item)[0];
        if (i) {
            return i.value;
        }
        return i;
    }

    return <>
        <form action="/" onChange={handleFormChange}>
            <div className={"account-take-over-container top-10-bottom-20" + (formType == 'view' ? ' disable-config-item' : '')}>
                <span className='playbook-config-title'>
                    Detect access tokens that were shared out within the token timeout period:</span>
                <div className="account-take-over-flex-item margintop15">
                    <label className='top15 label-flex'>Token Timeout Period:<input type='number'
                        value={state?.session_token_params?.assertion_timeout}
                        name='session_token_params.assertion_timeout' ref={register}
                        className='width50px'
                    ></input>
                        <Controller
                            render={({ ref, onChange, value = "" }) => (
                                <CheckboxAutocomplete
                                    classes={{ root: 'acc-take-over-sel' }}
                                    options={['minutes', 'hours'].map((item) => ({ key: item, value: item }))}
                                    value={{ key: state?.session_token_params?.period_timeout_unit, value: getValue(state?.session_token_params?.period_timeout_unit) }}
                                    getItemLabel={getItemLabel}
                                    hideClearBtn={true}
                                    multiple={false}
                                    onChange={(e, config) => {
                                        setValue('session_token_params.period_timeout_unit', config[0].key)
                                        onChange(config[0].key);
                                        handleFormChange();
                                    }}
                                />
                            )}
                            control={control}
                            name='session_token_params.period_timeout_unit'
                        /> &nbsp;&nbsp;
                        (5 minutes - 4 hours)
                    </label>
                </div>
            </div>
        </form>
    </>
}

export default SuspectedAccessTokenSharing;