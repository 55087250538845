export const getRiskLevel=(risk:string)=>{
    if(risk.toLowerCase()==="none"){
        return 0;
    }
    else if (risk.toLowerCase()==="low"){
        return 1;
    }
    else if (risk.toLowerCase()==="medium"){
        return 2;
    }
    else if (risk.toLowerCase()==="high"){
        return 3;
    }
    else if (risk.toLowerCase()==="critical"){
        return 4;
    }
}

export const getRisk=(riskLevel:number)=>{
    if(riskLevel===0){
        return "None";
    }
    else if (riskLevel===1){
        return "Low";
    }
    else if (riskLevel===2){
        return "Medium";
    }
    else if (riskLevel===3){
        return "High";
    }
    else if (riskLevel===4){
        return "Critical";
    }
}

export const getRiskLevelFromColor=(color:string)=>{
    if (color==="#cccccc"){
        return "low";
    }
    else if (color==="#fcbf2e"){
        return "medium";
    }
    else if (color==="#fd7122"){
        return "high";
    }
    else if (color==="#fa1262"){
        return "critical";
    }
}

