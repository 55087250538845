import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import styles from '../grid/Playbooks.module.css'
import { FormControlLabel, Switch } from "@material-ui/core";

const Days = ['Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'];
let i = 0;
const TimeArray = Array.from(Array(24), () => i++);

export interface IWeelkSelectorState {
    0: IWeeklyState,
    1: IWeeklyState,
    2: IWeeklyState,
    3: IWeeklyState,
    4: IWeeklyState,
    5: IWeeklyState,
    6: IWeeklyState,
}

export const DefaultWeekSelectorState: IWeelkSelectorState = {
    "0": {
        from: 0,
        to: 1,
        is_selected: false
    },
    "1": {
        from: 0,
        to: 1,
        is_selected: false
    },
    "2": {
        from: 0,
        to: 1,
        is_selected: false
    },
    "3": {
        from: 0,
        to: 1,
        is_selected: false
    },
    "4": {
        from: 0,
        to: 1,
        is_selected: false
    },
    "5": {
        from: 0,
        to: 1,
        is_selected: false
    },
    "6": {
        from: 0,
        to: 1,
        is_selected: false
    }
}

export interface IWeeklyState {
    from: number,
    to: number,
    is_selected: boolean,
    from_seconds?: number,
    to_seconds?: number
}

interface IWeeklyProps {
    handleClose(): any,
    handleSave(state: IWeelkSelectorState): any,
    defaultState: IWeelkSelectorState | any,
    formType?: string
}

export const WeelkSelector = ({ handleClose, handleSave, defaultState, formType }: IWeeklyProps) => {
    const [state, setState] = useState<IWeelkSelectorState>(defaultState || DefaultWeekSelectorState);
    const [error, setError] = useState('');
    
    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    const handleDayClick = (e: any, index: number) => {
        let tempState = JSON.parse(JSON.stringify(state));
        if (e.target.checked) {
            tempState[index] = { from: 0, to: 1, is_selected: true };
        } else {
            tempState[index] = { from: 0, to: 1, is_selected: false }
        }
        setState(tempState);
        setError('');
    }

    const getStatusByIndex = (index: number) => {
        if (!state) {
            return 'Closed';
        }
        return state[index as keyof typeof state]?.is_selected ? 'Open' : 'Closed';
    }

    const handleStartTimeChange = (e: any, index: number) => {
        let tempState = JSON.parse(JSON.stringify(state)) as IWeelkSelectorState;
        const item = tempState[index as keyof typeof state];
        if (item) {
            item.from = parseInt(e.target.value);
        }
        setState(tempState);
        setError('');
    }

    const handleEndTimeChange = (e: any, index: number) => {
        let tempState = JSON.parse(JSON.stringify(state)) as IWeelkSelectorState;
        const item = tempState[index as keyof typeof state];
        if (item) {
            item.to = parseInt(e.target.value);
        }
        setState(tempState);
        setError('');
    }

    const handleWeekSelectorSave = () => {
        const utcOffset = Math.abs(new Date().getTimezoneOffset());
        const res = Object.values(state);
        let er = false, is_selected = false;
        let err = 'Please select atleast one day and time.'

        for (let i = 0; i < res.length; i++) {
            const item = res[i] as IWeeklyState;
            if (item.is_selected) {
                is_selected = true;
                item.from_seconds = parseInt((item.from || 0).toString()) * 24 * 60 * 60 + (parseInt((item.from || 0).toString()) * 3600) + (utcOffset * 60);
                item.to_seconds = parseInt((item.to || 0).toString()) * 24 * 60 * 60 + (parseInt((item.to || 0).toString()) * 3600) + (utcOffset * 60);
            }
            if (item.from >= item.to) {
                err = 'Start time should be less than end time.';
                er = true;
                break;
            }
        }
        setError(err);

        if (!er && is_selected) {
            handleSave(state);
            setError('');
        }
    }

    const copyToAll = () => {
        const index = 0;
        setState((prevState) => {
            const tempState = { ...prevState };
            const item = tempState[index as keyof typeof state];
            for (let i = 1; i < Object.keys(state).length; i++) {
                tempState[i] = {
                    ...tempState[i],
                    to: item.to,
                    from: item.from,
                    is_selected: true,
                };
            }
            return tempState;
        });
        setError('');
    }


    return <>
        <Popup
            closeOnDocumentClick={false}
            overlayStyle={{ zIndex: 40000, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnEscape={false}
            modal
        >
            <div style={{ marginBottom: '5%' }} className={`${styles['modal']} week-selector-modal`}>
                <div className={styles.close} onClick={handleClose}></div>
                <div className={styles.header}>
                    <div>{'Access time allowed'}</div>
                    <span className="font12 font-family-metro-regular">Define the hours during which asset is allowed to be accessed</span>
                </div>
                <div className={styles.content}>
                    {
                        Days.map((item: string, index: number) => {
                            return <div style={{ display: 'flex', alignItems: 'end' }} key={item + index}>
                                <label>{item}</label>
                                <FormControlLabel
                                    classes={{
                                        root: (formType && ['edit', 'view'].includes(formType) ? ' disable-config-item' : ''),
                                    }}
                                    control={<Switch
                                        classes={{
                                            root: 'week-selector-switch-root'
                                        }}
                                        checked={getStatusByIndex(index) === 'Closed' ? false : true}
                                        onChange={(e) => handleDayClick(e, index)} />}
                                    // label={getStatusByIndex(index)} 
                                    label={''}
                                />

                                {
                                    getStatusByIndex(index) === 'Open' ?
                                        <>
                                            <select className={"scrollbar-container " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                                defaultValue={state[index as keyof typeof state]?.from || 0}
                                                value={state[index as keyof typeof state]?.from || 0}
                                                onChange={(e) => handleStartTimeChange(e, index)}>
                                                {
                                                    TimeArray.map((item: number, ind: number) => {
                                                        return <option key={'start-time-' + item}
                                                            value={ind}
                                                        >
                                                            {
                                                                ind < 12 ?
                                                                    `${item}:00 AM` :
                                                                    `${ind === 12 ? 12 : ind - 12}:00 PM`
                                                            }
                                                        </option>
                                                    })
                                                }
                                            </select>
                                            {<label className="to-label">TO</label>}
                                            <select
                                                className={"scrollbar-container " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                                defaultValue={state[index as keyof typeof state]?.to || 1}
                                                value={state[index as keyof typeof state]?.to || 1}
                                                onChange={(e) => handleEndTimeChange(e, index)}>
                                                {
                                                    TimeArray.map((item: number, ind: number) => {
                                                        return <option key={'end-time-' + item}
                                                            value={ind}
                                                        >
                                                            {
                                                                ind < 12 ?
                                                                    `${item}:00 AM` :
                                                                    `${ind === 12 ? 12 : ind - 12}:00 PM`
                                                            }
                                                        </option>
                                                    })
                                                }
                                            </select>
                                            {index == 0 && <div className="link-text copy-all-access-time copy_btn" onClick={copyToAll}>Copy to all</div>}
                                        </> : null
                                }

                            </div>
                        })
                    }
                </div>
                {error ? <div className="error" style={{ marginLeft: '1%' }}>Error: {error}</div> : null}
                {!(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '') && <div className="popup_footer">
                    <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={handleClose}>Cancel</button>
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={handleWeekSelectorSave}>Save</button>
                </div>}
            </div>
        </Popup>
    </>
}