import { createContext, useContext } from "react";

const TimeBasedSearchStateContext = createContext();

export const TimeBasedSearchStateContextProvider = ({children, value}) => { 
    return <TimeBasedSearchStateContext.Provider value={value}>{children}</TimeBasedSearchStateContext.Provider>
};

export const useTimeBasedSearchStateContext = () => {
    return useContext(TimeBasedSearchStateContext);
};

const TimeBasedSearchPropsContext = createContext();

export const TimeBasedSearchPropsContextProvider = ({children, value}) => { 
    return <TimeBasedSearchPropsContext.Provider value={value}>{children}</TimeBasedSearchPropsContext.Provider>
};

export const useTimeBasedSearchPropsContext = () => {
    return useContext(TimeBasedSearchPropsContext);
};

const TimeBasedSearchDispatchContext = createContext();

export const TimeBasedSearchDispatchContextProvider = ({ children, value }) => { 
    return <TimeBasedSearchDispatchContext.Provider value={value}>{children}</TimeBasedSearchDispatchContext.Provider>
};

export const useTimeBasedSearchDispatchContext = () => {
    return useContext(TimeBasedSearchDispatchContext);
};


export const useTimeBasedSearchStorage = () => {
    const _storeName = 'time_based_search';
    const fetchDataFromStore = () => {
        const _cache = sessionStorage.getItem(_storeName) || undefined;
        if (_cache) {
            return JSON.parse(_cache);
        }
        return undefined;
    }
   
    const loadDataToStore = (data) => {
        const _d = sessionStorage.getItem(_storeName);
        if (_d) {
            const _t = JSON.parse(_d);
            const _data = { ..._t, ...data };
            
            sessionStorage.setItem(_storeName, JSON.stringify(_data));
        } else {
            sessionStorage.setItem(_storeName, JSON.stringify(data));
        }
    }

    const clearDataFromStore = () => {
        sessionStorage.removeItem(_storeName);
    }

    return {
        fetchDataFromStore, loadDataToStore, clearDataFromStore
    }
}