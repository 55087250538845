import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import { Api } from '../../../components/Axios'
import { AMCIDRHelpText } from '../../ref/AMCIDRHelpText';
import './add-intermediary.scss';

type AddIntermediary = {
    name?: string;
    fqdn?: string;
    ips?: [];
    type?: string;
}

interface Props {
    AfterSensorOperation?: any;
}

export const AddIntermediary = ({ AfterSensorOperation }: Props) => {
    const { register, handleSubmit, errors } = useForm<AddIntermediary>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [ips, setIps] = useState([])
    const { addToast } = useToasts()

    const onAddIntermediary = (data: any) => {
        if (!ips || ips.length == 0) {
            addToast("Please add ips.", {
                appearance: 'error',
                autoDismiss: true,
            })
            return;
        }
        setLoading(true)
        data['ips'] = ips
        Api.post('/intermediary', data)
            .then((res: { data: any }) => {
                setLoading(false)
                AfterSensorOperation('add')
                addToast("Added successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status == 409) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const AddTags = (tag: any) => {
        const tempTags = tag.filter((item: string) => item && item.trim().length > 0);
        setIps(tempTags)
    }

    return (
        <form onSubmit={handleSubmit(onAddIntermediary)}>
            <div className="add-intermediary-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter name"
                            name="name"
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/
                            })}
                            className={(errors.name ? "error" : "")}
                        />
                       
                    </div>
                    <div className="form-control">
                        <label className='margin-10' style={{ display: 'flex' }}>IP Addresses/Host Name/FQDNs<AMCIDRHelpText /></label>
                        <TagsInput
                            value={ips}
                            onChange={AddTags}
                            renderLayout={(tagElements, inputElement) => {
                                return (
                                    <span>
                                        <div
                                            className="scrollbar-container directory-tag-container">
                                            {tagElements}
                                        </div>
                                        <div>
                                        {inputElement}
                                        </div>
                                    </span>
                                )
                            }}
                            inputProps={{ placeholder: "Add IP address, etc." }}
                            // validationRegex={(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9][^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
                            validationRegex={(/^(([\*]|[a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9]*[^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
                        />
                        <div className="marginleft40per error">Note: Hit 'Enter' or 'Tab' key after each IP address, etc.</div>
                    </div>
                </div>
            </div>
           
            { (Object.keys(errors || {}).length > 0) && (
                <div className="add-intermediary-errors-list-container">
                        {errors.name && errors.name.type === 'required' && <div className="error dot marginleft40per">Please enter name.</div>}
                        {errors.name && errors.name.type !== 'required' && <div className={`error marginleft40per ${errors.name.message && "dot"}`}>{errors.name.message}</div>}
                        {errors.name && errors.name.type === 'pattern' && <div className="error dot marginleft40per">Invalid name.</div>}
                </div>
            )}

            <div className="add-edit-action-buttons dialog-button">
                <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? "loader" : "add-edit-submit")}>
                    Save
                </button>
            </div>
        </form>
    )
}