export const ExceptionError = {
    name: {
        required: 'Please enter exception description.'
    },
    pbName: {
        required: 'Please select atleast one issue.'
    },
    flow_handling: {
        required: "Please select action for access flows."
    }
}