import React, { useEffect, useState, useRef } from 'react';
import { SuspiciousInboundConfigProps } from '../../../../types/playbooks-config';
import { getRiskLabel } from '../../helpers/playbook-helper';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';

const options = [
    { key: '1', value: "LOW_RISK_IP" },
    { key: '2', value: "MEDIUM_RISK_IP" },
    { key: '3', value: "HIGH_RISK_IP" },
    { key: '4', value: "CRITICAL_RISK_IP" }
]

const SpInboundAccessConfig = ({ parentState, isSavedOnce, getState, defaultState, formType }: SuspiciousInboundConfigProps) => {

    const [state, setState] = useState(defaultState);

    const unitRef = useRef() as React.MutableRefObject<HTMLSelectElement>;

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    useEffect(() => {
        getState(state);
    })

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const getValue = (o) => {
        if (!isNaN(o)) {
            return options[parseInt(o) - 1].value
        } else {
            return getItemLabel(o)
        }
    }

    return (
        <>
            <form action="/">
                <div className="popup_title fl" style={{ marginBottom: 0, width: '100%' }}>

                    <label style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '40%' }}>Source IP Reputation Minimum Threshold:</span>

                        {
                            (formType && ['edit', 'view'].includes(formType)) ?
                                <span style={{ fontWeight: 100 }}> {getRiskLabel(state?.ip_rep_threshold)}</span> :
                                <CheckboxAutocomplete
                                    classes={{ root: 'susp-inbound-sel' }}
                                    options={options}
                                    value={{ key: state?.ip_rep_threshold, value: getValue(state?.ip_rep_threshold) }}
                                    getItemLabel={getItemLabel}
                                    hideClearBtn={true}
                                    multiple={false}
                                    onChange={(e, config) => {
                                        setState({
                                            ...state,
                                            ip_rep_threshold: parseInt(config[0].key)
                                        })
                                    }}
                                />
                        }
                    </label>
                </div>
                <div className="clrBoth margintop30"></div>
            </form>
        </>
    )
}

export default React.memo(SpInboundAccessConfig);