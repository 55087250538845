
export const PERIOD_OPTIONS = {
    PRESETS: { id: "PRESETS", label: "Presets"},
    CUSTOM_RANGE: { id: "CUSTOM_RANGE", label: "Custom Range" },
    HISTOGRAM: { id: "HISTOGRAM", label: "Histogram" }
}

export const AVAILABLE_PRESETS = {
    LS_60_MINS: { id: 'LS_60_MINS', label: 'Last 60 minutes' },
    LS_4_HRS: { id: 'LS_4_HRS', label: 'Last 4 hours' },
    LS_24_HRS: { id: 'LS_24_HRS', label: 'Last 24 hours' },
    LS_3_DAYS: { id: 'LS_3_DAYS', label: 'Last 3 days' },
    LS_7_DAYS: { id: 'LS_7_DAYS', label: 'Last 7 days' },
    LS_14_DAYS: { id: 'LS_14_DAYS', label: 'Last 14 days' },
}

export const TIME_RANGE_OPERATORS = {
    BETWEEN: { id: 'between', label: 'Between' },
    EQUALS: { id: '=', label: 'Single Day' },
    AFTER: { id: '>', label: 'After' },
    // BEFORE: { id: '<', label: 'Before' }
}

export const TIME_BASED_SEARCH_FIELD = 'any_activity_time' 

export const VALIDATIONS = {
    DAYS_15: 'Adjust your date range to be less than 15 days.',
    DAYS_60: 'Invalid Date Selected. Update your date to be today or earlier'
}