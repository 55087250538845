import React, { useEffect, useState } from 'react';
import { Api } from '../../../../components/Axios';
import './mfa-config.css';
import { LackOfMFAConfigState } from '../../../../types/playbooks-config';
import { BasePropsViewOnly } from '../../constants/types';

interface MfaServerList {
    _id: string;
    name: string;
}

const MfaConfigView = ({ config }: BasePropsViewOnly<LackOfMFAConfigState>) => {
    const [serversList, setServersList] = useState<Array<MfaServerList>>([
        { _id: '', name: 'Select MFA Server' }
    ]);
    const isAllSelected =
        serversList &&
        config?.mfa_servers &&
        serversList?.length > 0 &&
        config?.mfa_servers?.length === serversList?.length;

    const getDefaultDays = () => {
        return Math.trunc(parseInt(config?.mfa_timeout?.toString()) / 24);
    };

    const getDefaultHours = () => {
        return parseInt(config?.mfa_timeout?.toString()) % 24;
    };

    useEffect(() => {
        loadMFAServers();
    }, []);

    const loadMFAServers = () => {
        Api.get('/mfa').then((res: any) => {
            setServersList(res.data.result);
        });
    };

    const getMFAServersString = (): string => {
        let selectedString = '';
        if (config?.mfa_servers && config?.mfa_servers.length > 0) {
            config?.mfa_servers?.forEach((i: any, index: number) => {
                const o = serversList.find((item: any) => item._id == i) as any;
                if (o) {
                    if (index <= 10) {
                        selectedString += o.name + '; ';
                    } else if (index == 11) {
                        selectedString += '...'
                    }
                }
            });
        }
        return selectedString.length > 0 ? selectedString : '';
    };

    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - <span>Lack of MFA: </span> */}
                MFA servers to be checked:{' '}
                {getMFAServersString().length > 0 ? getMFAServersString() : 'NA'}
                <ul className='ml-1'>
                    <li>
                        MFA session is valid for{' '}
                        <strong>{getDefaultDays()}</strong> days and &nbsp;
                        <strong>{getDefaultHours()}</strong> hours
                    </li>
                </ul>
            </li >
        </>
    );
};

export default React.memo(MfaConfigView);
