import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { SuspectedBruteForceState } from "../../../../types/playbooks-config";
import { Tooltip } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';
import { usePartnerStateContext } from "../../../../store/PartnerContextProvider";
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";

export const SuspectedBruteForce = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SuspectedBruteForceState>) => {
    const { PartnerConfigState } = usePartnerStateContext();
    const [state, setState] = useState<SuspectedBruteForceState>(defaultState);
    const [checked, setChecked] = React.useState<any>({
        num_auth_fail: isSavedOnce ? (parentState.dir_params.user_auth_fail_count_today ? true : false) :
            (state.dir_params.user_auth_fail_count_today ? true : false),
        num_acc_bad_pass: isSavedOnce ? (parentState.dir_params.dir_bad_password_nuser_curhour ? true : false)
            : (state?.dir_params.dir_bad_password_nuser_curhour ? true : false),
        schedule: true
    });

    useEffect(() => {
        getState({
            dir_params: {
                dir_bad_password_nuser_curhour: checked?.num_acc_bad_pass ?
                    state?.dir_params?.dir_bad_password_nuser_curhour : undefined,
                param_durations: [
                    {
                        param_name: state?.dir_params?.param_durations[0].param_name,
                        duration: state?.dir_params?.param_durations[0].duration
                    }
                ],
                user_auth_fail_count_today: checked?.num_auth_fail ? state?.dir_params?.user_auth_fail_count_today : undefined
            }
        })
    }, [state, checked])

    useEffect(() => {
        if (defaultState) {
            setState(defaultState);
            setChecked({
                num_auth_fail: (defaultState.dir_params.user_auth_fail_count_today ? true : false),
                num_acc_bad_pass: (defaultState.dir_params.dir_bad_password_nuser_curhour ? true : false),
                schedule: true
            })
        }
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            setChecked({
                num_auth_fail: (parentState.dir_params.user_auth_fail_count_today ? true : false),
                num_acc_bad_pass: (parentState.dir_params.dir_bad_password_nuser_curhour ? true : false),
                schedule: true
            })
        }
    }, [isSavedOnce])

    const handleNumAuthFail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            dir_params: {
                ...state?.dir_params,
                user_auth_fail_count_today: parseInt(e.target.value)
            }
        })

        setChecked({ ...checked, num_auth_fail: e.target.value ? true : false })
    }

    const handleAccBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state?.dir_params, dir_bad_password_nuser_curhour: parseInt(e.target.value) } })
        setChecked({ ...checked, num_acc_bad_pass: e.target.value ? true : false })

    }

    const onScheduleSelect = (e: React.ChangeEvent<HTMLSelectElement>, config) => {
        const duration = config[0].key;
        if (duration === 'day') {
            setState({
                dir_params: {
                    ...state?.dir_params, user_auth_fail_count_today: 20,
                    param_durations: [
                        {
                            duration: duration,
                            param_name: 'user_auth_fail_count_today'
                        }
                    ]
                }
            })
        } else {
            setState({
                dir_params: {
                    ...state?.dir_params, user_auth_fail_count_today: 12,
                    param_durations: [
                        {
                            duration: duration,
                            param_name: 'user_bad_password_count_curhour'
                        }
                    ]
                }
            })
        }
        setChecked({ ...checked, num_auth_fail: duration ? true : false })

    }

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, keyType: string) => {
        setChecked({ ...checked, [keyType]: e.target.checked })
    }

    useEffect(() => {
        if (state) {
            const object = JSON.parse(JSON.stringify(state)) as any;
            if (object?.dir_params) {
                if (checked?.num_auth_fail) {
                    object.dir_params.var1 = object.dir_params.param_durations[0].duration === 'day'
                        ? 'UserAuthFailCountToday' : 'UserBadPasswordCountCurHour';
                    object.dir_params.var2 = object.dir_params.param_durations[0].param_name;
                    object.dir_params.user_input1 = object.dir_params.user_auth_fail_count_today
                }
                if (!checked?.num_acc_bad_pass) {
                    delete object.dir_params.dir_bad_password_nuser_curhour;
                }
                if (getExpression) {
                    getExpression(object.dir_params);
                }
            }
        }
    })

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    return <>
        <div className="dir-main-container dir-brute-force">
            <div className="dir-container-row bold-text">
                <label className="playbook-config-title marginBottom0">
                    An account will be marked as under brute-force attack when:</label>
            </div>
            <div className="dir-container-row">
                <input type="checkbox"  id="num-attempts" onChange={(e) => onCheckboxChange(e, 'num_auth_fail')}
                    checked={checked?.num_auth_fail}
                    defaultChecked={checked?.num_auth_fail}
                    className={(['edit', 'view'].includes(formType || '') ? ' disable-config-item margintop20' : 'margintop20')}
                />
                <label
                    htmlFor="num-attempts"
                    className={(formType && ['edit', 'view'].includes(formType)) ? 'disable-config-item' : ''}
                >
                    Number of authentication failures in a

                    {
                        (formType && ['edit', 'view'].includes(formType)) ? <span className="view-label"> {
                            state?.dir_params?.param_durations[0].duration
                            //state?.dir_params?.time_duration
                        }</span>
                            :
                            <CheckboxAutocomplete
                                classes={{ root: 'brute-force-sel' }}
                                options={[{ key: 'day', value: 'day' }, { key: "hour", value: 'hour' }]}
                                value={{ key: state?.dir_params?.param_durations[0]?.duration, value: state?.dir_params?.param_durations[0]?.duration }}
                                onChange={onScheduleSelect}
                                hideClearBtn={true}
                                getItemLabel={getItemLabel}                                
                            />} for an account is more than
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label marginbottom10">
                        {
                            state?.dir_params?.user_auth_fail_count_today || ''
                        }</label> :
                        <input type="number" onChange={handleNumAuthFail} name="num_auth_fail" value={
                            state?.dir_params?.user_auth_fail_count_today || ''
                        } />}
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }} className="font14 margintop20">OR</div>
            <div className="dir-container-row">
                <input type="checkbox"  id="num-admin-attempts" onChange={(e) => onCheckboxChange(e, 'num_acc_bad_pass')}
                    checked={checked?.num_acc_bad_pass}
                    defaultChecked={checked?.num_acc_bad_pass}
                    className={(['edit', 'view'].includes(formType || '') ? ' disable-config-item margintop20' : ' margintop20')}
                />
                <label htmlFor="num-admin-attempts"
                    className={(formType && ['edit', 'view'].includes(formType)) ? 'disable-config-item' : ''}
                >
                    <strong>[AD Only]</strong><Tooltip
                        classes={{ tooltip: 'generic-tooltip-popper' }}
                        PopperProps={{
                            style: { zIndex: 999999 }
                        }}
                        title={<div className="width250">
                            {/* This parameter is used only for an Active Directory with an {`${PartnerConfigState.PartnerProductName}`} AD sensor deployed. */}
                            This parameter is used only for an Active Directory with an AuthMind AD sensor deployed.
                        </div>}
                    ><span style={{ marginLeft: '0.1em' }}><InfoIcon style={{ fontSize: 14, color: '#d4d8e1', transform: 'skewX(-10deg)' }} /></span></Tooltip>
                    Number of AD accounts with bad passwords every hour crosses
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">
                        {state?.dir_params.dir_bad_password_nuser_curhour}</label> :
                        <input type="number" onChange={handleAccBadPass} name="num_acc_bad_pass"
                            value={state?.dir_params?.dir_bad_password_nuser_curhour || ''} />
                }
            </div>
        </div>
    </>
}