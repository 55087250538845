import React, { useEffect } from "react";
import "flag-icons/css/flag-icons.min.css";

interface IAMCountryFlag {
    countryCode: string
}

export const AMCountryFlag = ({ countryCode }: IAMCountryFlag) => {
    return <>
        <span style={{
            display: 'inline-block',
            width: '0.9em',
            height: '0.9em',
            verticalAlign: 'middle'
        }} className={`fi fi-${countryCode.toLowerCase()}`}></span>
    </>
}