import React, { useState } from "react";
import SensorConfiguration from "./sensorConfiguration";
import { isAmopsUser } from "../../utils/util-methods";
import sensor from "../../Icons/sensor.png";
import microsoft from "../../Icons/supportedCollector/microsoft.svg";
import ibm from '../../Icons/supportedCollector/ibm.svg';
import splunk from '../../Icons/supportedCollector/splunk.svg';
import sumologic from '../../Icons/supportedCollector/sumo_logic.svg';

interface SensorSelectionProps {
    selectedSensor:string;
    onClick: (sensorType: string) => void;
  
}

const SensorSelection: React.FC<SensorSelectionProps> = ({ selectedSensor , onClick }) => {

    const handleClick = (sensorType: string) => {
        if (selectedSensor !== sensorType) {
            onClick(sensorType);
        }
    };

    const userDetails = localStorage.getItem('user') as string;
    const user = JSON.parse(userDetails);

    return (
        <div className="sensor-selection" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', padding: '10px 0' }}>
            <SensorConfiguration
                selected={selectedSensor === 'ad'}
                onClick={() => handleClick('ad')}
                title="AuthMind AD Sensor"
                description="Leverage an AuthMind AD Sensor to collect events and insights from a Microsoft AD Directory."
                imageUrl1={microsoft}
                imageUrl="microsoft"
  
            />
            <SensorConfiguration
                selected={selectedSensor === 'flow'}
                onClick={() => handleClick('flow')}
                title="Flow Sensor"
                description="Leverage a Flow Sensor to collect from one or more local data sources and/or local SIEMs."
                imageUrl1={sensor}
                imageUrl="sensor"
               
            />
            <SensorConfiguration
                selected={selectedSensor === 'enrichment'}
                onClick={() => handleClick('enrichment')}
                title="Enrichment Sensor"
                description="Enrichment Sensors are an alternative to a local Flow Sensor to enrich host details."
                imageUrl1={sensor}
                imageUrl="sensor"

            />
              <SensorConfiguration
                selected={selectedSensor === 'siem'}
                onClick={() => handleClick('siem')}
                title="SIEM Sensor"
                description="Integrate with an existing SIEM to collect one or more data sources to streamline deployments."
                imageUrl1={ibm}
                imageUrl2={splunk}
                imageUrl3={sumologic}
                imageUrl="splunk"
                
            />
             <SensorConfiguration
                selected={selectedSensor === 'adlds'}
                onClick={() => handleClick('adlds')}
                title="AuthMind AD/LDS Sensor"
                description="Leverage an AuthMind AD LDS Sensor to collect events and insights from a Microsoft AD LDS Directory."
                imageUrl1={microsoft}
                imageUrl="microsoft"
  
            />
            
         {/* {isAmopsUser(user.email) &&   <SensorConfiguration
                selected={selectedSensor === 'hostedFS'}
                onClick={() => handleClick('hostedFS')}
                title="Hosted Flow Sensor"
                description="Leverage a Flow Sensor to collect from one or more cloud data sources and/or cloud SIEMs."
                imageUrl1="https://cdn.builder.io/api/v1/image/assets/TEMP/b219043e69f2917005df31df63a61dc060fb4065319cb7f1dc85f3ab7c99334a?"
                imageUrl2="https://cdn.builder.io/api/v1/image/assets/TEMP/ee1d3c399b526a0b7692338e4a671fa520addec08a0ec5f33a8121bbff44d967?"
            /> } */}
        </div>
    );
};

export default SensorSelection;
