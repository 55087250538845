
const ACTION_TYPES = {
    SHOW_POPUP: 'SHOW_POPUP',
    HIDE_POPUP: 'HIDE_POPUP',
    SELECT_PERIOD_OPTION: 'SELECT_PERIOD_OPTION',
    SELECT_PERIOD_PRESET: 'SELECT_PERIOD_PRESET',
    APPLY_FILTERS: 'APPLY_FILTERS',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    RESET_FILTERS: 'RESET_FILTERS',
    UPDATE_FROM_PROPS: 'UPDATE_FROM_PROPS',
    SELECT_TIME_RANGE: 'SELECT_TIME_RANGE',
    SELECT_START_DATE: 'SELECT_START_DATE',
    SELECT_END_DATE: 'SELECT_END_DATE',
    SELECT_START_TIME: 'SELECT_START_TIME',
    SELECT_END_TIME: 'SELECT_END_TIME',
    SELECT_DATE_RANGE: 'SELECT_DATE_RANGE',
    SELECT_DATE_RANGE_HISTOGRAM: 'SELECT_DATE_RANGE_HISTOGRAM',
}

export default ACTION_TYPES;