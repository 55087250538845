import ACTION_TYPES from './TimeBasedSearchActionTypes';
import {

    useTimeBasedSearchActions
} from './TimeBasedSearchActions';
import { AVAILABLE_PRESETS, PERIOD_OPTIONS, TIME_RANGE_OPERATORS } from './Constants';
import { useTimeBasedSearchStorage } from './useTimeBasedSearch';
import moment from 'moment';
import { dateToEpoch } from './utils';

const defaultStartHour = 0;
const defaultStartMinute = 0;
const defaultEndHour = 23;
const defaultEndMinute = 59;

const initialState = {
    isFilterApplied: false,
    isApplyDisabled: false,
    showPopup: false,
    selectedPeriod: PERIOD_OPTIONS.PRESETS.id,
    selectedPreset: '',
    selectedPresetStartDate: '',
    selectedPresetEndDate: '',
    selectedTimeRange: TIME_RANGE_OPERATORS.BETWEEN.id,
    selectedStartDate: moment().set('hour', defaultStartHour).set('minute', defaultStartMinute),
    selectedEndDate: moment(),
    selectedStartTime: moment(),
    selectedEndTime: moment(),
    selectedStartDateHistogram: dateToEpoch(moment().subtract(14, 'days')),
    selectedEndDateHistogram: dateToEpoch(moment()),
    applyBtnValidMsg: 'Adjust your date range to be less than 15 days.'
};

export const resetDates = { 
    selectedPreset: '',
    selectedPresetStartDate: '',
    selectedPresetEndDate: '',
    selectedTimeRange: TIME_RANGE_OPERATORS.BETWEEN.id,
    selectedStartDate: moment().set('hour', defaultStartHour).set('minute', defaultStartMinute),
    selectedEndDate: moment(),
    selectedStartTime: moment(),
    selectedEndTime: moment(),
    selectedStartDateHistogram: dateToEpoch(moment().subtract(14, 'days')),
    selectedEndDateHistogram: dateToEpoch(moment()),
}


export const useTimeBasedSearchReducer = () => {
    const storage = useTimeBasedSearchStorage();
    const { togglePopup,
        selectPeriod,
        selectPeriodPreset,
        clearFilters,
        applyFilters,
        resetFilters,
        updateFromProps,
        selectTimeRange,
        selectDate,
        selectDateRange, selectDateRangeHistogram } = useTimeBasedSearchActions();

    const initialStore = storage.fetchDataFromStore() || initialState;

    const TimeBasedSearchReducer = (state, actions) => {
        // console.log({ action: actions.type, value: actions?.value });
        switch (actions.type) {
            case ACTION_TYPES.SHOW_POPUP:
                return togglePopup(state, actions.value);
            case ACTION_TYPES.HIDE_POPUP:
                return togglePopup(state, actions.value);
            case ACTION_TYPES.SELECT_PERIOD_OPTION:
                return selectPeriod(state, actions.value);
            case ACTION_TYPES.SELECT_PERIOD_PRESET:
                return selectPeriodPreset(state, actions.value);
            case ACTION_TYPES.APPLY_FILTERS:
                return applyFilters(state, actions.value, actions.resolve);
            case ACTION_TYPES.CLEAR_FILTERS:
                return clearFilters(state, initialState);
            case ACTION_TYPES.RESET_FILTERS:
                return resetFilters(state, initialStore);
            case ACTION_TYPES.UPDATE_FROM_PROPS:
                return updateFromProps(state, actions.value);
            case ACTION_TYPES.SELECT_TIME_RANGE:
                return selectTimeRange(state, actions.value);
            case ACTION_TYPES.SELECT_START_DATE:
            case ACTION_TYPES.SELECT_END_DATE:
                let dateField = actions.type === ACTION_TYPES.SELECT_START_DATE ? 'selectedStartDate' : 'selectedEndDate'
                return selectDate(state, actions.value, dateField, actions.operator);
            case ACTION_TYPES.SELECT_DATE_RANGE:
                return selectDateRange(state, actions.value);
            case ACTION_TYPES.SELECT_DATE_RANGE_HISTOGRAM:
                return selectDateRangeHistogram(state, actions.value);
            case ACTION_TYPES.SELECT_START_TIME:
            case ACTION_TYPES.SELECT_END_TIME:
                let timeField = actions.type === ACTION_TYPES.SELECT_START_TIME ? 'selectedStartTime' : 'selectedEndTime'
                return selectDate(state, actions.value, timeField);
            default:
                return state;
        }
    };
    return { TimeBasedSearchReducer, initialState, initialStore }
}



