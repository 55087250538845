import axios from 'axios'
const { REACT_APP_HOST_IP } = process.env
export const Api = axios.create({
  baseURL: REACT_APP_HOST_IP + "/api"
})
const data = window.localStorage.getItem('user')
if (data !== null) {
  const user = JSON.parse(data)
  if (typeof (user.token) != 'undefined') {
    Api.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }
}

Api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const data = localStorage.getItem('Api-Version');
  const headerResponse = response.headers['api-version'];
  if (headerResponse && data != headerResponse) {
    localStorage.setItem('Api-Version', headerResponse);
    localStorage.setItem('refresh-needed', 'true');
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});