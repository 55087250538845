import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';
import {Api} from '../../../components/Axios'

type DatafeedServer = {
    host?: string;
    port?: string;
    security?: string;
    authentication?: string;
    user_name?: string;
    password?: string;
    confirm_password?: string;
}

interface Props {
    id?: string;
    AfterEditOperation?: any;
}

export const EditPanel = ({id, AfterEditOperation}: Props) => {
    const { register, handleSubmit, errors, setValue, getValues } = useForm<DatafeedServer>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [datafeedServer, setdatafeedServer] = useState<DatafeedServer>()
    const { addToast } = useToasts()

    useEffect(() => {
        Api.get('/datafeed')
        .then((res: { data: any }) => {
            setValue("host", res.data.host)
            setValue("port", res.data.port)
            setValue("security", res.data.security)
            setValue("authentication", res.data.authentication)
            setValue("user_name", res.data.user_name)
        })
        .catch((error: any) => {
        })
    }, [])

    const onUpdateDatafeed = (data: any) => {
        setLoading(true)
        Api.post('/datafeed', data)
        .then((res: { data: any }) => {
            setLoading(false)
            AfterEditOperation('edit')
            addToast("Successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    const TestDatafeed = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const data = getValues()
        setLoading(true)
        const headers = {'Operation':'PUT'}
        Api.post('/datafeed', data, {headers: headers})
        .then((res: { data: any }) => {
            addToast("Test email send successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onUpdateDatafeed)}>
            <label>Base URL : </label>
            <input
                type="text"
                placeholder=""
                name="host"
                ref={register({
                    required: true,
                })}
                className={(errors.host ? "error" : "")}
                defaultValue="https://api.dehashed.com/search"
            />
            {errors.host && errors.host.type === 'required' && <div className="error marginleft40per">Please host name.</div>}
            {errors.host && errors.host.type !== 'required' && <div className="error marginleft40per">{errors.host.message}</div>}

            <label>Email : </label>
            <input
                type="text"
                placeholder="pankur@gmail.com"
                name="port"
                ref={register({
                    required: true,
                })}
                className={(errors.port ? "error" : "")}
                defaultValue="pankur@gmail.com"
            />
            {errors.port && errors.port.type === 'required' && <div className="error marginleft40per">Please enter port.</div>}
            {errors.port && errors.port.type !== 'required' && <div className="error marginleft40per">{errors.port.message}</div>}

            <label>Key : </label>
            <input
                type="text"
                placeholder=""
                name="host"
                ref={register({
                    required: true,
                })}
                className={(errors.host ? "error" : "")}
                defaultValue="4603eb4690796f77b57e927183d0a885"
            />
            {errors.security && errors.security.type === 'required' && <div className="error marginleft40per">Please select connection security.</div>}
            {errors.security && errors.security.type !== 'required' && <div className="error marginleft40per">{errors.security.message}</div>}

            <div className="footer_line"></div>
            <div className="pane_footer">
                <div className="launch_button">
                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                </div>
            </div>
        </form>
    )
}