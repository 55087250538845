import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import SlidingPanel from 'react-sliding-side-panel';
import 'reactjs-popup/dist/index.css';
import { IssueFilters } from "../../../types/response-types";
import { useToasts } from "../hooks/useToast";
import { Api } from "../../Axios";


type Props={
	issueFiltersData: IssueFilters | undefined,
    toggleWidget: any,
    disabledState: any,
    updateAfterFilter: any 
}

export const FilterWidget = ({issueFiltersData, toggleWidget, disabledState, updateAfterFilter}: Props) => {

    const search = useLocation().search;
    const disabled_filter_state:boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
    const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
    
    let history = useHistory();

    const [issueFilters, setIssueFilters] = useState<IssueFilters | undefined>(issueFiltersData)
    const [tagsAssetName, setTagsAssetName] = useState<string[]>([])
    const [tagsAssetHostName, setTagsAssetHostName] = useState<string[]>([])
    const [tagsAssetIP, setTagsAssetIP] = useState<string[]>([])
    const [tagsIdentityName, setTagsIdentityName] = useState<string[]>([])
    const [tagsIdentityHostName, setTagsIdentityHostName] = useState<string[]>([])
    const [tagsIdentityIP, setTagsIdentityIP] = useState<string[]>([])
    
    const [protocolType, setProtocolType] = useState<string[]>([])
    const [sensorType, setSensorType] = useState<string[]>([])
    const [riskType, setRiskType] = useState<number[]>([])
    const [statusType, setStatusType] = useState<string[]>([])
    const [rulesType, setRulesType] = useState<string[]>([])
    const [assetType, setAssetType] = useState<string[]>([])
    const [identityType, setIdentityType] = useState<string[]>([])
    const [openProtocol, setOpenProtocol] = useState<Boolean>(false)
    const [openSensors, setOpenSensors] = useState<Boolean>(false)
    const [openRisk, setOpenRisk] = useState<Boolean>(false)
    const [openStatus, setOpenStatus ] = useState<Boolean>(false)
    const [openRules, setOpenRules ] = useState<Boolean>(true)
    const [openIdentity, setOpenIdentity ]= useState<Boolean>(false)
    const [openIdentityType, setOpenIdentityType] = useState<Boolean>(false)
    const [openIdentityName, setOpenIdentityName] = useState<Boolean>(false)
    const [openIdentityHostName, setOpenIdentityHostName] = useState<Boolean>(false)
    const [openIdentityIP, setOpenIdentityIP] = useState<Boolean>(false)
    const [openAsset, setOpenAsset] = useState<Boolean>(false)
    const [openAssetType, setOpenAssetType] = useState<Boolean>(false)
    const [openAssetName, setOpenAssetName] = useState<Boolean>(false)
    const [openAssetHostName, setOpenAssetHostName] = useState<Boolean>(false)
    const [openAssetIP, setOpenAssetIP] = useState<Boolean>(false)
    const { register: registerFilters, handleSubmit: handleSubmitDashboard, formState: formState, errors: errors2} = useForm<IssueFilters>()
    const [ loading, setLoading ] = useState<Boolean>(false)
    const { addToast } = useToasts()

    useEffect(() => {
        //console.log("touchedFields", formState.touched);
    },[formState]); 

    
    const clearFilters=()=>{
        let ifpData:any = {
            dst_filter : {},
            excluded_protocols : null,
            excluded_status : null,
            src_filter : {},
            user_id : issueFilters?.ifp.user_id
        };

        let ifodata: any = issueFiltersData?.ifo

        let tempIssueFilter:IssueFilters = {
            ifo: ifodata,
            ifp: ifpData
        }

        setIssueFilters(tempIssueFilter);
        setProtocolType([]); 
        setRiskType([])
        setSensorType([])
        setStatusType([])
        setRulesType([])

        setIdentityType([])
        setTagsAssetHostName([]);
        setTagsAssetName([]);
        
        setAssetType([])
        setTagsIdentityHostName([]);
        setTagsIdentityName([]);

    }

    const handleClose=()=>{
        //console.log("touchedFields", formState.touched);
        if(Object.keys(formState.touched).length!==0) {
            if(window.confirm("Your saved changes will be gone. Do you want to proceed?"))
            {
                toggleWidget(false)
            }
        }
        else {
            toggleWidget(false)
        }
        //toggleWidget(false)
    }

    useEffect(()=>{
        if(issueFilters!==undefined) {
            //Rules
            let rulesArray:any = [];
            let finalRulesArray = issueFilters?.ifo.rules;
            if(issueFilters?.ifp.excluded_rules!==undefined) {
                rulesArray = issueFilters?.ifo.rules.filter(val => !issueFilters?.ifp.excluded_rules.includes(val))
                finalRulesArray = issueFilters?.ifp.excluded_rules.concat(rulesArray)
            }
            setRulesType(finalRulesArray)
            
            //Protocols
            let protocolArray:any = [];
            let finalProtocolArray = issueFilters?.ifo.protocols;
            if(issueFilters?.ifp.excluded_protocols!==null) {
                protocolArray = issueFilters?.ifo.protocols.filter(val => !issueFilters?.ifp.excluded_protocols.includes(val));
                finalProtocolArray = issueFilters?.ifp.excluded_protocols.concat(protocolArray);
            }
            setProtocolType(finalProtocolArray)

            //Sensors
            let sensorArray:any = [];
            let finalSensorArray = issueFilters?.ifo.sensor_locations;
            if(issueFilters?.ifp.excluded_sensor_locations!==undefined) {
                sensorArray = issueFilters?.ifo.sensor_locations.filter(val => !issueFilters?.ifp.excluded_sensor_locations.includes(val));
                finalSensorArray = issueFilters?.ifp.excluded_sensor_locations.concat(sensorArray);
            }
            setSensorType(finalSensorArray)

            //Risk
            let riskArray:any = [];
            let finalRiskArray = issueFilters?.ifo.risk;
            if(issueFilters?.ifp.excluded_risk!==undefined) {
                riskArray = issueFilters?.ifo.risk.filter(val => !issueFilters?.ifp.excluded_risk.includes(val));
                finalRiskArray = issueFilters?.ifp.excluded_risk.concat(riskArray);
            }
            setRiskType(finalRiskArray)
            
            //Status
            let statusArray:any = [];
            let finalStatusArray = issueFilters?.ifo.status;
            if(issueFilters?.ifp.excluded_status!==null) {
                statusArray = issueFilters?.ifo.status.filter(val => !issueFilters?.ifp.excluded_status.includes(val));
                finalStatusArray = issueFilters?.ifp.excluded_status.concat(statusArray);
            }
            setStatusType(finalStatusArray)

            setIdentityType(issueFilters.ifo.src_types)
            setTagsAssetHostName(issueFilters.ifp.dst_filter.excluded_hostname_ip_patterns === undefined ? [] : issueFilters.ifp.dst_filter.excluded_hostname_ip_patterns);
            setTagsAssetName(issueFilters.ifp.dst_filter.excluded_name_patterns === undefined ? [] : issueFilters.ifp.dst_filter.excluded_name_patterns);
            
            setAssetType(issueFilters.ifo.dst_types)
            setTagsIdentityHostName(issueFilters.ifp.src_filter.excluded_hostname_ip_patterns === undefined ? [] : issueFilters.ifp.src_filter.excluded_hostname_ip_patterns);
            setTagsIdentityName(issueFilters.ifp.src_filter.excluded_name_patterns === undefined ? [] : issueFilters.ifp.src_filter.excluded_name_patterns);
        }
    },[issueFilters])

    const collapseState=(item:any)=>{
        setOpenAsset(item==="Asset"? !openAsset : openAsset);
        setOpenAssetType(item==="Asset"? true : openAssetType);
        setOpenAssetName(item==="Asset"? false : openAssetName);
        setOpenAssetHostName(item==="Asset"? false : openAssetHostName);
        setOpenAssetIP(item==="Asset"? false : openAssetIP);
        setOpenIdentity(item==="Identity"? !openIdentity : openIdentity);
        setOpenIdentityType(item==="Identity"? true : openIdentityType);
        setOpenIdentityName(item==="Identity"? false : openIdentityName);
        setOpenIdentityHostName(item==="Identity"? false : openIdentityHostName);
        setOpenIdentityIP(item==="Identity"? false : openIdentityIP);
        setOpenProtocol(item==="Protocol"? !openProtocol : openProtocol);
        setOpenRisk(item==="Risk"? !openRisk : openRisk);
        setOpenSensors(item==="Sensors"? !openSensors : openSensors);
        setOpenStatus(item==="Status"? !openStatus : openStatus);
        setOpenRules(item==="Issues"? !openRules : openRules);
    }

    const collapseStateInner=(item:any)=>{
        setOpenAssetType(item==="Asset Type"? !openAssetType : openAssetType);
        setOpenAssetName(item==="Asset Name"? !openAssetName : openAssetName);
        setOpenAssetHostName(item==="Asset HostName"? !openAssetHostName : openAssetHostName);
        setOpenAssetIP(item==="Asset IP"? !openAssetIP : openAssetIP);
        setOpenIdentityType(item==="Identity Type"? !openIdentityType : openIdentityType);
        setOpenIdentityName(item==="Identity Name"? !openIdentityName : openIdentityName);
        setOpenIdentityHostName(item==="Identity HostName"? !openIdentityHostName : openIdentityHostName);
        setOpenIdentityIP(item==="Identity IP"? !openIdentityIP : openIdentityIP);
    }

    const updateFilters=(data: any)=>{
        setLoading(true);
        // Check if data set is part of which filter
        //Destination Filter
        const dst_filter_excluded_type:any = [];
        const dst_filter_excluded_name_patterns:any = tagsAssetName;
        const dst_filter_excluded_hostname_ip_patterns:any = tagsAssetHostName;
        // Source Filter
        const src_filter_excluded_type:any = [];
        const src_filter_excluded_name_patterns:any = tagsIdentityName;
        const src_filter_excluded_hostname_ip_patterns:any = tagsIdentityHostName;
        // Protocol Filter
        const excluded_protocols_filter:any = [];
        // Sensors Filter
        const excluded_sensors_filter:any = [];
        // Status Filter
        const excluded_risk_filter:any = []
        // Risk Filter
        const excluded_status_filter:any = []
        // Rules Filter
        const excluded_rules_filter:any = []

        let filteredWidget:any = Object.keys(data).filter(function(key) {
          return !data[key]
        });

        filteredWidget.map((item:any, index:any)=> {
            if(item.includes("protocol_")) excluded_protocols_filter.push(item.replace("protocol_","")) 
            if(item.includes("sensor_")) excluded_sensors_filter.push(item.replace("sensor_","")) 
            if(item.includes("risk_")) excluded_risk_filter.push(parseInt(item.replace("risk_",""))) 
            if(item.includes("status_")) excluded_status_filter.push(item.replace("status_",""))    
            if(item.includes("rules")) excluded_rules_filter.push(item.replace("rules_",""))     
            if(item.includes("asset_")) dst_filter_excluded_type.push(item.replace("asset_",""))       
            if(item.includes("identity_")) src_filter_excluded_type.push(item.replace("identity_",""))     
        })

        //Create Object for Exculded Protocols
        let ifpData = {
            dst_filter : {
                excluded_type: dst_filter_excluded_type,
                excluded_name_patterns: dst_filter_excluded_name_patterns,
                excluded_hostname_ip_patterns: dst_filter_excluded_hostname_ip_patterns
            },
            excluded_protocols : excluded_protocols_filter,
            excluded_sensor_locations : excluded_sensors_filter,
            excluded_risk : excluded_risk_filter,
            excluded_status : excluded_status_filter,
            excluded_rules : excluded_rules_filter,
            src_filter : {
                excluded_type: src_filter_excluded_type,
                excluded_name_patterns: src_filter_excluded_name_patterns,
                excluded_hostname_ip_patterns: src_filter_excluded_hostname_ip_patterns
            },
            user_id : issueFilters?.ifp.user_id
        };

        if(disabledFilter) {
            setLoading(false)

            addToast("Incident Filters Disabled successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
            toggleWidget(false);
            disabledState(true);
        }
        else {
            Api.post('/issues/filter', ifpData)
            .then((res: { data: any }) => {
                setLoading(false)

                addToast("Incident Filters Updated successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                toggleWidget(false);
                disabledState(false);
                //window.location.reload();
            })
            .catch((error: any) => {
                setLoading(false)
                if(error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if(error.response.status === 419) {
                    //setSErrors(error.response.data)
                } else if(error.response.status === 404) {
                    addToast("Please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })

        }
        
    }

    const handleDisabled = ()=> {
        setDisabledFilter(!disabledFilter);
    }


	return (
		<>	
			<SlidingPanel
              type={"left"}
              isOpen={true}
              size={20}
              noBackdrop={true}
            >
            <div >
              <form className="filter_panel" onSubmit={handleSubmitDashboard(updateFilters)}>
                <div className="filter_top">
                    <div className="filter_header">EXCLUDE FILTERS<span className="filter_close" onClick={handleClose}></span></div>
                    <div className="filter_disable">
                        <input 
                            name="disableFilter"
                            type="checkbox"
                            className={("float_left")}
                            defaultChecked={disabledFilter}
                            onChange={handleDisabled}
                        />
                        <label className="">Disable Filter</label>
                    </div>
                    <div className="filter_clear_button" onClick={clearFilters}>X &nbsp; Clear All Filters</div>
                </div>

                <div className={disabledFilter ? "filter_middle filter_no_scroll" : "filter_middle"}>

                        {disabledFilter?
                            <div className="filter_middle_overlay">
                            </div> : ""
                        }
                        <div onClick={()=>collapseState("Issues")} className="filter_list_title">
                            {openRules ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Issues
                            <div className={issueFilters!.ifp.excluded_rules !== null && issueFilters?.ifp.excluded_rules!==undefined ? "filter_button" : ""}></div>
                        </div>
                        <ul style={openRules ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}}  className="filter_listing">
                            {
                                issueFilters!== undefined ?
                                rulesType.map((item:any, index:any)=>{
                                    return (
                                        <li className="" key={item+index}>
                                            <input 
                                                name={"rules_"+item}
                                                id={"rules_"+item}
                                                type="checkbox"
                                                className={(" float_left marginleft20")}
                                                defaultChecked={issueFilters?.ifp.excluded_rules!==null && issueFilters?.ifp.excluded_rules!==undefined  ? !issueFilters?.ifp.excluded_rules.includes(item): true}
                                                ref={registerFilters}
                                            />
                                            <label htmlFor={"rules_"+item}>{item}</label>
                                        </li>
                                    )
                                })
                                : null
                            }

                        </ul>
                        
                        <div onClick={()=>collapseState("Identity")} className="filter_list_title">
                            {openIdentity ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Identity
                            <div className={Object.keys(issueFilters!.ifp.src_filter).length === 0 ? "" : "filter_button"}></div>
                        </div>
                        <ul style={openIdentity ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_depth">
                            <li>
                                {/* Identity Type */}
                                <div onClick={()=>collapseStateInner("Identity Type")} 
                                    style={(openIdentity) ? {"position":"relative"}  :{"visibility":"hidden",height:"0",padding:"0"}} 
                                    className="filter_list_title_inner">
                                    {openIdentity && openIdentityType ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                                    Type
                                    <div className={issueFilters!.ifp.src_filter.excluded_type === undefined ? "" : "filter_button"}></div>
                                </div>
                                <ul style={(openIdentity && openIdentityType) ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_inner"> 
                                    {
                                        issueFilters!== undefined ?
                                        identityType.map((item:any, index:any)=>{    
                                            return (
                                                <li className="" key={item+index}>
                                                    <input 
                                                        name={"identity_"+item}
                                                        type="checkbox"
                                                        className={(" float_left marginleft20")}
                                                        defaultChecked={issueFilters?.ifp.src_filter?.excluded_type === undefined? true : !issueFilters?.ifp.src_filter?.excluded_type.includes(item)}
                                                        ref={registerFilters}
                                                    />
                                                    <label className="">{item}</label>
                                                </li>
                                            )
                                        })
                                        : null
                                    }
                                </ul>
                            </li>
                            <li>
                                {/* Identity Name */}
                                <div onClick={()=>collapseStateInner("Identity Name")}  
                                    style={(openIdentity) ? {"position":"relative"}  :{"visibility":"hidden",height:"0",padding:"0"}} 
                                    className="filter_list_title_inner">
                                    {openIdentity && openIdentityName ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                                    Name
                                    <div className={issueFilters!.ifp.src_filter.excluded_name_patterns === undefined ? "" : "filter_button"}></div>
                                </div>
                                <ul style={openIdentity && openIdentityName ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_inner"> 
                                    {
                                        issueFilters!== undefined ?
                                        (<>
                                            <div className="filter_clear_button_inner" onClick={()=>{setTagsIdentityName([])}}>X Clear</div>
                                            <li className="filter_tag_input">
                                                <ReactTagInput 
                                                
                                                    tags={tagsIdentityName} 
                                                    onChange={(newTags:any) => setTagsIdentityName(newTags)}
                                                />
                                            </li>
                                        </>)
                                        : null
                                    }
                                </ul>
                            </li>
                            <li>
                                {/* Identity Host Name / IP */}
                                <div onClick={()=>collapseStateInner("Identity HostName")}  
                                    style={(openIdentity) ? {"position":"relative"}  :{"visibility":"hidden",height:"0",padding:"0"}} 
                                    className="filter_list_title_inner">
                                    {openIdentity && openIdentityHostName ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                                    Host Name / IP
                                    <div className={issueFilters!.ifp.src_filter.excluded_hostname_ip_patterns === undefined ? "" : "filter_button"}></div>
                                </div>
                                <ul style={openIdentity && openIdentityHostName ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_inner"> 
                                    {
                                        issueFilters!== undefined ?
                                        (<>
                                            <div className="filter_clear_button_inner" onClick={()=>{setTagsIdentityHostName([])}}>X Clear</div>
                                            <li className="filter_tag_input">
                                                <ReactTagInput 
                                                    tags={tagsIdentityHostName} 
                                                    onChange={(newTags:any) => setTagsIdentityHostName(newTags)}
                                                    ref={registerFilters}
                                                />
                                            </li>
                                        </>)
                                        : null
                                    }
                                </ul>
                            </li>
                        </ul>

                        <div onClick={()=>collapseState("Asset")} className="filter_list_title">
                            {openAsset ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Assets
                            <div className={Object.keys(issueFilters!.ifp.dst_filter).length === 0 ? "" : "filter_button"}></div>
                        </div>
                        <ul style={openAsset ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_depth">
                            <li>
                                {/* Asset Type */}
                                <div onClick={()=>collapseStateInner("Asset Type")} 
                                    style={(openAsset) ? {"position":"relative"} :{"visibility":"hidden",height:"0",padding:"0"}} 
                                    className="filter_list_title_inner">
                                    {openAsset && openAssetType ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                                    Type
                                    <div className={issueFilters!.ifp.dst_filter.excluded_type === undefined ? "" : "filter_button"}></div>
                                </div>
                                <ul style={(openAsset && openAssetType) ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_inner"> 
                                    {
                                        issueFilters!== undefined ?
                                        assetType.map((item:any, index:any)=>{
                                            return (
                                                <li className="" key={item+index}>
                                                    <input 
                                                        name={"asset_"+item}
                                                        type="checkbox"
                                                        className={(" float_left marginleft20")}
                                                        defaultChecked={issueFilters?.ifp.dst_filter?.excluded_type === undefined ? true : !issueFilters?.ifp.dst_filter?.excluded_type.includes(item)}
                                                        ref={registerFilters}
                                                    />
                                                    <label className="">{item}</label>
                                                </li>
                                            )
                                        })
                                        : null
                                    }
                                </ul>
                            </li>
                            <li>
                                {/* Asset Name */}
                                <div onClick={()=>collapseStateInner("Asset Name")} 
                                    style={(openAsset) ? {"position":"relative"}  :{"visibility":"hidden",height:"0",padding:"0"}} 
                                    className="filter_list_title_inner">
                                    {openAsset && openAssetName ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                                    Name
                                    <div className={issueFilters!.ifp.dst_filter.excluded_name_patterns === undefined ? "" : "filter_button"}></div>
                                </div>
                                <ul style={openAsset && openAssetName ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_inner"> 
                                    {
                                        issueFilters!== undefined ?
                                        (
                                        <>
                                        <div className="filter_clear_button_inner" onClick={()=>{setTagsAssetName([])}}>X Clear</div>
                                        <li className="filter_tag_input">
                                            <ReactTagInput 
                                                tags={tagsAssetName} 
                                                onChange={(newTags:any) => setTagsAssetName(newTags)}
                                            />
                                        </li>
                                        </>
                                        )
                                        : null
                                    }
                                </ul>
                            </li>
                            <li>
                                {/* Asset Host Name / IP */}
                                <div onClick={()=>collapseStateInner("Asset HostName")} 
                                    style={(openAsset) ? {"position":"relative"}  :{"visibility":"hidden",height:"0",padding:"0"}} 
                                    className="filter_list_title_inner">
                                    {openAsset && openAssetHostName ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                                    Host Name / IP
                                    <div className={issueFilters!.ifp.dst_filter.excluded_hostname_ip_patterns === undefined ? "" : "filter_button"}></div>
                                </div>
                                <ul style={openAsset && openAssetHostName ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing_inner"> 
                                    {
                                        issueFilters!== undefined ?
                                        (<>
                                            <div className="filter_clear_button_inner" onClick={()=>{setTagsAssetHostName([])}}>X Clear</div>
                                            <li className="filter_tag_input">
                                                <ReactTagInput 
                                                    tags={tagsAssetHostName} 
                                                    onChange={(newTags:any) => setTagsAssetHostName(newTags)}
                                                />
                                            </li>
                                        </>)
                                        : null
                                    }
                                </ul>
                            </li>
                        </ul>
                
                        <div onClick={()=>collapseState("Protocol")} className="filter_list_title">
                            {openProtocol ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Protocol
                            <div className={issueFilters!.ifp.excluded_protocols === null ? "" : "filter_button"}></div>
                        </div>
                        <ul style={openProtocol ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing">
                            {
                                issueFilters!== undefined ?
                                protocolType.map((item:any, index:any)=>{
                                    return (
                                        <li className="" key={item+index}>
                                            <input 
                                                name={"protocol_"+item}
                                                id={"protocol_"+item}
                                                type="checkbox"
                                                className={("float_left marginleft20")}
                                                defaultChecked={issueFilters?.ifp.excluded_protocols!==null ? 
                                                    !issueFilters?.ifp.excluded_protocols.includes(item): true}
                                                ref={registerFilters}
                                            />
                                            <label  htmlFor={"protocol_"+item}>{item}</label>
                                        </li>
                                    )
                                })
                                : null
                            }
                        </ul> 

                        <div onClick={()=>collapseState("Status")} className="filter_list_title">
                            {openStatus ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Status
                            <div className={issueFilters!.ifp.excluded_status === null ? "" : "filter_button"}></div>
                        </div>
                        <ul style={openStatus ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}}  className="filter_listing">
                            {
                                issueFilters!== undefined ?
                                statusType.map((item:any, index:any)=>{
                                    return (
                                        <li className="" key={item+index}>
                                            <input 
                                                name={"status_"+item}
                                                id={"status_"+item}
                                                type="checkbox"
                                                className={(" float_left marginleft20")}
                                                defaultChecked={issueFilters?.ifp.excluded_status!==null ? !issueFilters?.ifp.excluded_status.includes(item): true}
                                                ref={registerFilters}
                                            />
                                            <label htmlFor={"status_"+item}>{item}</label>
                                        </li>
                                    )
                                })
                                : null
                            }

                        </ul>

                        <div onClick={()=>collapseState("Risk")} className="filter_list_title">
                            {openRisk ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Risk
                            <div className={issueFilters!.ifp.excluded_risk === undefined ? "" : "filter_button"}></div>
                        </div>
                        <ul style={openRisk ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing">
                            {
                                issueFilters!== undefined ?
                                riskType.map((item:any, index:any)=>{
                                    return (
                                        <li className="" key={item+index}>
                                            <input 
                                                name={"risk_"+item}
                                                type="checkbox"
                                                className={(" float_left marginleft20")}
                                                defaultChecked={issueFilters?.ifp.excluded_risk!==null && issueFilters?.ifp.excluded_risk!==undefined ? !issueFilters?.ifp.excluded_risk.includes(item): true}
                                                ref={registerFilters}
                                            />
                                            <label className="">{
                                                item===0 ? (<span>None <div className="none_dot"></div></span>) :
                                                item===1 ? (<span>Low <div className='low_dot'></div></span>) :
                                                item===2 ? (<span>Medium <div className='medium_dot'></div></span>):
                                                item===3 ? (<span>High <div className='high_dot'></div></span>):
                                                item===4 ? (<span>Critical <div className='critical_dot'></div></span>): ""
                                            }</label>
                                        </li>
                                    )
                                })
                                : null
                            }

                        </ul> 

                        
                        <div onClick={()=>collapseState("Sensors")} className="filter_list_title">
                            {openSensors ? (<span>&#9660;</span>) : (<span>&#9658;</span>)}
                            Sensor Locations
                            <div className={issueFilters!.ifp.excluded_sensor_locations === undefined ? "" : "filter_button"}></div>
                        </div>
                        <ul style={openSensors ? {"display":"inline-block"} :{"display":"none",height:"0",padding:"0"}} className="filter_listing">
                            {
                                issueFilters!== undefined ?
                                sensorType.map((item:any, index:any)=>{
                                    return (
                                        <li className="" key={item+index}>
                                            <input 
                                                name={"sensor_"+item}
                                                id={"sensor_"+item}
                                                type="checkbox"
                                                className={("float_left marginleft20")}
                                                defaultChecked={issueFilters?.ifp.excluded_sensor_locations!==null && issueFilters?.ifp.excluded_sensor_locations!==undefined ? !issueFilters?.ifp.excluded_sensor_locations.includes(item): true}
                                                ref={registerFilters}
                                            />
                                            <label  htmlFor={"sensor_"+item}>{item}</label>
                                        </li>
                                    )
                                })
                                : null
                            }

                        </ul> 

                         
                </div>  

                <div className="filter_bottom">
                    <div className="apply_filter_button">
                        <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled width100per')}>Apply</button>
                    </div>
                </div>
              </form>
            </div>
            </SlidingPanel>
		</>	
		)
}