import React, { useEffect, useState } from 'react';
import { ShadowIdentityConfigProps } from '../../../../types/shadow-idn-config';

const ShadowIdentityConfig=({parentState, isSavedOnce, getState, defaultState}:ShadowIdentityConfigProps)=>{

    const [state, setState]=useState(defaultState);

    useEffect(()=>{
        setState(defaultState);
    }, [defaultState])

    useEffect(()=>{
        if(isSavedOnce){
            setState(parentState);
        }
    }, [isSavedOnce])

    useEffect(()=>{
        // console.log('ShadowIdentityConfig STATE', state);
        getState(state);
    })

    // const renderKnownDir=(kds: KnownDirectory[]|undefined)=>{
    //     return kds?.map((kd: KnownDirectory)=>{
    //         return (
    //             <React.Fragment key={kd.name}>
    //                 <input type="checkbox" id={kd.name} name={kd.name} checked={kd.isChecked}
    //                     onChange={(e)=>{
    //                         if(e.target.checked){
    //                             kd.isChecked=true
    //                         }
    //                         else{
    //                             kd.isChecked=false
    //                         }
    //                         setState([...kds]);
    //                     }}
    //                 />
    //                 <label htmlFor={kd.name} className="float_none">{kd.name}
    //                     {/* <div className="info_icon">
    //                         <div className="infotext">Extra information about this field/option</div>
    //                     </div> */}
    //                 </label>
    //                 <div className="clrBoth"></div>
    //             </React.Fragment>
    //         )
    //     })
    // }

    return(
        <>
            <form action="/">
                <div className="popup_title fl" style={{marginBottom:0}}>
                <input style={{marginBottom:0}} type="checkbox" checked={state?.skip_mfa_match} onChange={(e)=>setState({
                        skip_mfa_match: e.target.checked
                    })}/>Do not mark incidents as Lack of MFA
                </div>
                
                {/* <div className="playbook-config-title">An identity will be considered Shadow Identities if it is not managed by following Directory or IDP's: </div>
                <div className="clrBoth margintop20">&nbsp;</div>
                {(state && state.length>0) ? renderKnownDir(state) : 
                    <label className="float_none">No directories found. <Link to="/directories" style={{color:'blue'}}>Click here</Link> to add a new directory.</label>
                } */}
                <div className="clrBoth margintop30"></div>
            </form>
        </>
    )
}

export default React.memo(ShadowIdentityConfig);