import React from 'react';
import './blocks-skeleton.scss';

export const BlocksSkeleton = ()=>{
    return <>
     <div className='skeleton-blocks-container animate-pulse'>
                <div className='skeleton-title'></div>
                <div className='skeleton-content'>
                 
                </div>

            </div>
    </>
}