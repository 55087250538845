import { useEffect } from 'react';
import { getResolutionMatch } from '../../../utils/util-methods';

export const AWSCloudWatch = ({ register, setValue, collectorData }) => {
    useEffect(() => {
        if (collectorData) {
            setValue('aws_cloudwatch_log_group', collectorData.collector_config?.aws_cloudwatch_log_group);
            setValue('aws_cloudwatch_region', collectorData.collector_config?.aws_cloudwatch_region);
            setValue('aws_cloudwatch_access_key', collectorData.collector_config?.aws_cloudwatch_access_key);

            setValue(
                'aws_cloudwatch_access_secret',
                collectorData.collector_config?.aws_cloudwatch_access_secret
            );
            setValue('managed_ad', collectorData.collector_config?.managed_ad);
        }
    }, [collectorData]);
    return (
        <>
            <div className='collector-configuration-vendor-container'>
                <div className={`form_sub_section`}>
                    <div className='form_sub_section_header'>
                        AWS Managed AD Configuration
                    </div>
                    <div className='form_sub_section_content'>
                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>Log Group*</label>
                                <input
                                    type='text'
                                    name='aws_cloudwatch_log_group'
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Enter Log Group'}
                                />
                            </div>
                        </div>

                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>Region*</label>
                                <input
                                    type='text'
                                    name='aws_cloudwatch_region'
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Enter Region'}
                                />
                            </div>
                        </div>

                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>Access Key ID*</label>
                                <input
                                    type='text'
                                    name='aws_cloudwatch_access_key'
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Enter Access Key ID'}
                                />
                            </div>
                        </div>

                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>Secret Access Key*</label>
                                <input
                                    type='text'
                                    name='aws_cloudwatch_access_secret'
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Enter Secret Access Key'}
                                />
                            </div>
                        </div>

                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <input
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        type='checkbox'
                                        checked
                                        className='disable-config-item marginBottom5'
                                        name={'managed_ad'}
                                    ></input>
                                    <span
                                        style={{
                                            marginTop: getResolutionMatch(4, 2)
                                        }}
                                    >
                                        Collect AWS Managed Microsoft AD Logs
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
