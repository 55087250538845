import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from "use-query-params";
import AMAdminTable, { ITableAction, ITableColumn, ITableReference, ITableResult } from "../../components/core/AMTable/AMAdminTable"
import { useEffect, useRef, useState } from "react";
import { Api } from "../../components/Axios";
import { Axios, AxiosResponse, HttpStatusCode } from "axios";
import React from "react";
import { AMAdminPopUp, AMAdminSearchTable, IAMAdminSearchTableRef } from "../ref/AMAdminCommonComponents";
import moment from 'moment';
import './ApiToken.scss';
import { ApiToken } from "./Apitoken";
import { ApiTokenSummary } from "./ApiTokenSummary";
import { useToasts } from "../../components/core";
import { AMToolTipAd } from "../../components/core/AMTooltipAd/AMTooltipAd";
import InfoIcon from '@mui/icons-material/Info';
import { GlobalConsts } from "../../GlobalConst";
const { REACT_APP_HOST_IP } = process.env;

interface IApiTokenSummaryData {
    apitToken: string,
    clientId: string,
    showSummaryBool: boolean
}

export interface IApiToken {
    _id: string,
    client_name: string,
    company_id: string,
    created_by: string,
    created_date: string,
    expiration_date: string,
    modified_date: string,
    api_token: string,
    status: number,
    isExpired: boolean,
}

const ApiTokenColumns: ITableColumn<IApiToken>[] = [
    {
        headerText: 'Client Name',
        key: 'client_name',
        sortable: true,
        classes: 'align_left width380'
    },
    /* {
        headerText: 'Client Id',
        key: '_id',
        sortable: true,
        html: (data: IApiToken) => {
            return <span>{data._id.replace(/(.{8})(.{8})(.{8})(.{0,8})/, '$1-$2-$3-$4').replace(/-+$/, '')}</span>
        },
        classes: 'align_left'
    }, */
    {
        headerText: 'Created By',
        key: 'created_by',
        sortable: true,
        classes: 'align_left'
    },
    {
        headerText: 'Last Modified',
        key: 'modified_date',
        sortable: true,
        html: (data: IApiToken) => {
            return <span>{moment(data.modified_date).format('MMM DD YYYY,')}&nbsp;{moment(data.modified_date).format('h:mm A')}</span>
        }
    },
    {
        headerText: 'Expiration Date',
        key: 'expiration_date',
        sortable: true,
        classes: 'width150',
        headerHtml: () => {
            return <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Expiration Date
                <AMToolTipAd title="Tokens expire at the end of the selected day in GMT." >
                    <InfoIcon style={{ fontSize: 14, color: 'white', marginLeft: '0.5em' }} />
                </AMToolTipAd>
            </span>
        },
        html: (data: IApiToken) => {
            return <div className="expiry-token-container">
                <div className={"excl_warning " + (data.isExpired ? ' ' : ' hidden-block')} style={{ display: 'inline-block' }}></div>
                <span>{moment(data.expiration_date).format('MMM DD YYYY')}</span></div>
        }
    },
    {
        headerText: 'Status',
        key: 'status',
        sortable: true,
        html: (data: IApiToken) => {
            return <span>{(data.status == 1 && !data.isExpired) ? 'Active' : <span className="critical_red" >Inactive</span>}</span>
        }
    }

]

const ApiTokenActions: ITableAction<IApiToken>[] = [
    {
        actionId: "edit-api-client",
        actionLabel: "Manage API Client",
    },
    {
        actionId: "activate",
        actionLabel: "Activate",
    },
    {
        actionId: "deactivate",
        actionLabel: "Deactivate",
    },
    {
        actionId: "delete-secret",
        actionLabel: "Delete",
    },
];

const SearchSubHeader = <div className="api-token-list-search-subheader">Create and manage your API tokens here. <span className="link-text"
    onClick={() => window.open(`${GlobalConsts.ADMIN_GUIDE}/manage-api-tokens?${GlobalConsts.DOC_TOKEN}`)}
>Learn More</span></div>

const ApiTokenListing = ({ query, setQuery }: any) => {
    const [data, setData] = useState<ITableResult<IApiToken>>();
    const childRef = useRef<ITableReference>();
    const searchRef = useRef<IAMAdminSearchTableRef>();
    const [showTokenForm, setShowTokenForm] = useState(false);
    const [formData, setFormData] = useState<IApiToken>();
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [showRegenerateForm, setShowRenerateForm] = useState(false);
    const { addToast } = useToasts();
    const [listOfClients, setListOfClients] = useState<Array<string>>([]);
    const [showActivate, setShowActivate] = useState(false);
    const [showSummary, setShowSummary] = useState<IApiTokenSummaryData>({
        apitToken: "", clientId: "", showSummaryBool: false
    });
    const [formType, setFormType] = useState("add");

    ApiTokenActions[0].actionCallback = (e: IApiToken) => {
        Api.get(`/apitoken/${e._id}`).then((res: AxiosResponse<IApiToken>) => {
            setFormType("edit");
            setFormData(res.data)
            setShowTokenForm(true)
        }).catch((er) => {
            addToast('Error occurred while fetching token details', {
                appearance: 'error',
                autoDismiss: true
            })
        })
    }

    ApiTokenActions[0].shouldDisplay = (e: IApiToken) => {
        return !e.isExpired;
    }

    ApiTokenActions[3].actionCallback = (e: IApiToken) => {
        setFormData(e);
        setShowDeleteForm(true)
    }

    ApiTokenActions[1].actionCallback = (e: IApiToken) => {
        //Activate
        setFormData(e);
        setShowActivate(true);

    }

    ApiTokenActions[2].actionCallback = (e: IApiToken) => {
        //DeActivate
        setFormData(e);
        setShowActivate(true);
    }

    ApiTokenActions[1].shouldDisplay = (e: IApiToken) => {
        //Activate
        return e.status == 0 && !e.isExpired
    }

    ApiTokenActions[2].shouldDisplay = (e: IApiToken) => {
        //DeActivate
        return e.status == 1 && !e.isExpired
    }

    const PageAction = {
        onClickHandler: (e) => {
            closeTokenForm()
            setShowTokenForm(true)
        },
        label: "Add"
    }

    useEffect(() => {
        fetchTokenList()
    }, [query])

    const onSubmit = (data) => {
        if (childRef?.current?.onSearch) {
            childRef?.current?.onSearch(data);
        }
    }

    useEffect(() => {
        const list = data?.result.map((i: IApiToken) => i.client_name) as string[];
        setListOfClients(list);
    }, [data])

    const SearchTable = () => {
        return (
            <AMAdminSearchTable
                searchTitle="Manage API Tokens"
                searchPlaceHolder="Name"
                query={query.q}
                ref={searchRef}
                onSearchValue={onSubmit}
                action={PageAction}
                searchSubHeader={SearchSubHeader}
            />
        );
    };

    const fetchTokenList = () => {
        Api.get('apitoken', { params: query }).then((res: AxiosResponse<ITableResult<IApiToken>>) => {
            if (res.status == HttpStatusCode.Ok) {
                res.data.result.forEach((item: IApiToken) => {
                    const today = moment().startOf('day'); // Get today's date at midnight
                    const inputDate = moment(item.expiration_date).startOf('day');
                    if (inputDate.isBefore(today)) {
                        item.isExpired = true
                        item.status = 0
                    } else {
                        item.isExpired = false;
                    }
                })
                setData(res.data)
            }
        })
    }

    const afterSubmitFn = (data: any) => {
        setShowTokenForm(false);
        fetchTokenList();
        if (formType == "add") {
            setShowSummary({
                apitToken: data.token,
                clientId: data.client_id,
                showSummaryBool: true
            })
        }
    }

    const onSummaryClose = () => {
        setShowSummary({ ...showSummary, showSummaryBool: false });
    }

    const closeTokenForm = () => {
        setFormType("add");
        setShowTokenForm(false);
        setFormData(undefined);
        setShowDeleteForm(false)
        setShowRenerateForm(false);
        setShowActivate(false);
    }


    const proceedDeleteForm = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post(`/apitoken/${formData?._id}`, "", { headers: headers })
            .then((res: { data: any }) => {
                addToast("Token deleted successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                closeTokenForm();
                fetchTokenList();
            })
            .catch((error: any) => {
                addToast("Error occurred while deleting token.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
    }

    const proceedRegenerateForm = () => {
        const headers = { 'Operation': 'PATCH' }
        Api.post(`/apitoken/${formData?._id}`, "", { headers }).then((res) => {
            addToast("Token re-generated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
            closeTokenForm()
            setShowSummary({
                apitToken: res.data.token,
                clientId: res.data.client_id,
                showSummaryBool: true
            })
        }).catch((er) => {
            addToast("Error occurred while re-generating token.", {
                appearance: 'error',
                autoDismiss: true,
            })
        })
    }

    const proceedApiActivate = () => {
        const headers = { 'Operation': 'PUT' }
        Api.post(`/apitoken/activate/${formData?._id}`, {
            "status": formData?.status == 0 ? 1 : 0,
            "_id": formData?._id
        }, { headers: headers }).then((res) => {
            addToast(`Token ${formData?.status == 0 ? ' activated ' : ' deactivated '}successfully.`, {
                appearance: 'success',
                autoDismiss: true,
            })
            closeTokenForm()
            fetchTokenList()
        }).catch((er) => {
            addToast(`Error occurred while performing action.`, {
                appearance: 'error',
                autoDismiss: true,
            })
        });
    }

    return <>
        <SearchTable />
        <AMAdminTable
            columns={ApiTokenColumns}
            data={data}
            ref={childRef}
            actions={ApiTokenActions}
            query={query}
            setQuery={setQuery}
            refresh={fetchTokenList}
            showPagination={false}
        />
        {showTokenForm && <ApiToken afterSubmitFn={afterSubmitFn}
            closeForm={closeTokenForm}
            defaultvalue={formData} formType={formType}
            listOfClients={listOfClients}
        />}
        {showSummary?.showSummaryBool &&
            <ApiTokenSummary
                onClose={onSummaryClose}
                apitToken={showSummary.apitToken}
                clientId={showSummary.clientId}
            />}
        {showDeleteForm && <ApiDeleteConfirm
            clientName={formData?.client_name}
            closeForm={closeTokenForm}
            proceedForm={proceedDeleteForm} />}
        {showRegenerateForm &&
            <ApiIApiRegenerateConfirmConfirm
                clientName={formData?.client_name}
                closeForm={closeTokenForm}
                proceedForm={proceedRegenerateForm}
            />
        }
        {
            showActivate &&
            <ApiActivate
                clientName={formData?.client_name}
                activate={formData?.status}
                closeForm={closeTokenForm}
                proceedForm={proceedApiActivate}
            />
        }
    </>
}

export default withQueryParams(
    {
        q: StringParam,
        page: StringParam,
        rpp: NumberParam,
        sort_by: StringParam,
        order_by: StringParam,
        filters: withDefault(ArrayParam, []),
        hard_refresh: StringParam,
    },
    ApiTokenListing
);


interface IApiDeleteConfirm {
    clientName: string | undefined,
    closeForm(): void,
    proceedForm(): void
}
const ApiDeleteConfirm = ({ clientName, closeForm, proceedForm }: IApiDeleteConfirm) => {
    const ApiTokenDeleteButtons = {
        primary: {
            label: "Yes",
            action: proceedForm,
        },
        secondary: {
            label: "No",
            action: closeForm,
        },
    };
    return <AMAdminPopUp popUpTitle="Confirm Delete"
        popUpButtons={ApiTokenDeleteButtons}
        rootClass="api-token-confirm-delete"
        handleClose={closeForm}>
        <div className="font16">Are you sure you want to delete <strong>{`${clientName}`}</strong> ?</div>
    </AMAdminPopUp>
}

interface IApiRegenerateConfirm {
    clientName: string | undefined,
    closeForm(): void,
    proceedForm(): void
}
const ApiIApiRegenerateConfirmConfirm = ({ clientName, closeForm, proceedForm }: IApiRegenerateConfirm) => {
    const ApiTokenRegenerateButtons = {
        primary: {
            label: "Yes",
            action: proceedForm,
        },
        secondary: {
            label: "No",
            action: closeForm,
        },
    };
    return <AMAdminPopUp popUpTitle="Confirm Token Regenerate"
        popUpButtons={ApiTokenRegenerateButtons}
        rootClass="api-token-confirm-delete"
        handleClose={closeForm}>
        <div className="font16">Are you sure you want to re-generate token for <strong>{`${clientName}`}</strong>?</div>
    </AMAdminPopUp>
}


interface IApiActivate {
    clientName: string | undefined,
    closeForm(): void,
    proceedForm(): void,
    activate: number,
}
const ApiActivate = ({ clientName, closeForm, proceedForm, activate }: IApiActivate) => {
    const ApiTokenRegenerateButtons = {
        primary: {
            label: "Yes",
            action: proceedForm,
        },
        secondary: {
            label: "No",
            action: closeForm,
        },
    };
    return <AMAdminPopUp popUpTitle={`Confirm Token ${activate == 0 ? 'Activate' : 'Deactivate'}`}
        popUpButtons={ApiTokenRegenerateButtons}
        rootClass="api-token-confirm-delete"
        handleClose={closeForm}>
        <div className="font16">Are you sure you want to {activate == 0 ? 'activate ' : 'de-activate'} token for <strong>{`${clientName}`}</strong>?</div>
    </AMAdminPopUp>
}

