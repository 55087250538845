import React from 'react';

export const SuspectedDirectoryBotView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    A directory or IdP will be marked as under a bot/botnet
                    attack when:
                </span>
                <ul className='ml-1'>
                    <li>
                        Number of separate hosts used by an account in a day is
                        more than{' '}
                        <strong>
                            {config?.dir_params?.user_auth_fail_nhosts_today}
                        </strong>
                    </li>
                    <li>
                        Number of authentication failures within a day for the
                        account crosses{' '}
                        <strong>
                            {config?.dir_params?.user_auth_fail_count_today}
                        </strong>
                    </li>
                </ul>
            </li>
        </>
    );
};
