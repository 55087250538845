import React, { useRef, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useToasts } from '../components/core';
import { useForm } from 'react-hook-form'
import { Api } from '../components/Axios'

import './Login.css'
import { AMCaptcha } from '../components/core/AMCaptcha/AMCaptcha';
import BeforeLoginContainer from './BeforeLoginContainer';

type Inputs = {
    email: string
}

const ForgotPassword = () => {
    const [loading, setLoading] = useState({ loading: false, setData: false })
    const { addToast } = useToasts()
    const { register, handleSubmit, errors } = useForm<Inputs>();
    const childRef = useRef<any>();
    const onSubmit = (data: any) => {
        if (childRef && childRef.current && childRef.current.onValidateCaptcha()) {
            setLoading({ loading: true, setData: false })
            Api.post('/forgot-password', data)
                .then((res: { data: any }) => {
                    addToast("You will receive an email with instruction to reset password if your email is valid and registered.", {
                        appearance: 'success',
                        autoDismiss: true,
                        hideDuration: 4000
                    })
                    setLoading({ loading: false, setData: true })
                })
                .catch((error: any) => {
                    setLoading({ loading: false, setData: false });

                    let message;
                    const status = error?.response?.status;

                    switch (status) {
                        case 500:
                            message = "Sorry, something went wrong there, try again."
                            break;
                        case 419:
                            message = "We encounted validation problem, please correct and try again.";
                            break;
                        case 404:
                            message = "We are not able to find associated email, please check and try again.";
                            break;
                        case 405:
                            message = "A password reset link is already generated and can be regenerated after 15 mins'";
                            break;
                    }
                    if (message) {
                        addToast(message, {
                            appearance: 'error',
                            autoDismiss: true,
                            hideDuration: 3000
                        })
                    }
                });
        }
    }

    if (loading.setData) return <Redirect to="/login" />
    return (
        <BeforeLoginContainer>
            <div className="form_header">Forgot Password</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>Email</label>
                <input
                    type="text"
                    placeholder="Enter Your Registered Email"
                    name="email"
                    ref={register({
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter valid email."
                        }
                    })}
                    className={(errors.email ? "error" : "")}
                />
                {errors.email && errors.email.type === 'required' && <div className="error">Please enter email.</div>}
                {errors.email && errors.email.type !== 'required' && <div className="error">{errors.email.message}</div>}

                <div className="input_subtext">Reset password link will be sent to this email. <Link to="/login" className="login_link">Login?</Link></div>
                <div className="clrBoth"></div>
                <div className="clrBoth"></div>
                <div className='forgot-pwd-captcha-container'>
                    <AMCaptcha ref={childRef} />
                </div>
                <button type={(loading.loading ? 'button' : 'submit')} className={" " + (loading.loading ? 'loader' : 'button_login')}>Retrieve</button>

            </form>
            </BeforeLoginContainer>
    )
}

export default ForgotPassword