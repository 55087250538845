import { deepCompare } from "../../../utils/util-methods";
import { RuleType } from "../constants/Constants";
import { IUnauthPayloadRuleConfig } from "../rules-config/fencing-config/unauthorized-asset-access/UnauthorizedAssetAccessConfig";

const CSVRuleConfigValidate = {
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: (state: Array<IUnauthPayloadRuleConfig>, validateAll = true) => {
        const errorList = [] as Array<string>;
        const validateDuplicate = () => {
            for (let i = 0; i < state.length; i++) {
                for (let j = i + 1; j < state.length; j++) {
                    if (deepCompare(state[i], state[j])) {
                        return false;
                    }
                }
            }
            return true;
        }

        if (!state || state?.length === 0) { return `Playbook config is empty.`; }
        else if (state?.length > 0) {
            if (!validateDuplicate()) {
                return `Duplicate rule configuration found.`;
            }
            for (let i = 0; i < state.length; i++) {
                const item = state[i];
                if (validateAll && item?.destination[0] === 'No Assets' && (
                    (item?.dest_port && item?.dest_port?.length > 0) || (item?.src_port && item?.src_port?.length > 0) ||
                    (item?.access_protocol && item?.access_protocol?.length > 0)
                )) {
                    errorList.push(`Row ${i + 1} - 'No Assets' config should not have any parameters set.`)
                }
                else if (validateAll && item?.destination[0] === 'No Assets' && item?.source[0] === 'No Identities' && (
                    item.dest_port?.length === 0 || item.src_port?.length === 0 ||
                    item?.access_protocol?.length === 0
                )) {
                    errorList.push(`Row ${i + 1} - Invalid configuration provided.`)
                    return errorList;
                }
                else if (['No Assets', 'All Assets'].includes(item?.destination[0]) && 'No Identities' == item?.source[0]) {
                    if (validateAll) {
                        errorList.push(`Row ${i + 1} - Both identities and assets cannot be set to 'No Identities' & 'All Assets' or 'No Assets' respectively.`)
                    } else
                        return `Both identities and assets cannot be set to 'No Identities' & 'All Assets' or 'No Assets' respectively.`;
                } else if (item?.destination[0] === 'No Assets' &&
                    item?.source[0] === 'All Identities'
                ) {
                    if (validateAll) {
                        errorList.push(`Row ${i + 1} - 'All Identities' cannot have assets set to 'No Assets'.`)
                    } else
                        return `'All Identities' cannot have assets set to 'No Assets'.`
                } else if (item?.destination[0] === 'No Assets' || item?.destination[0] !== 'All Assets') {
                    continue;
                }
            }
        }
        if (validateAll) {
            return errorList;
        }
        return null;
    },
}

export const validateCSV = (ruleType: string, data: any, validateAll?: boolean) => {
    if (ruleType && ruleType in CSVRuleConfigValidate) {
        return CSVRuleConfigValidate[ruleType as keyof typeof CSVRuleConfigValidate](data, validateAll);
    }
    return null;
}