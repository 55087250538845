import React from "react"
import Popup from "reactjs-popup"

export const SensorUpdatePopUp = (props: any) => {
    const closeConfirm = (evt: any) => {
        if (props.closeConfirm)
            props.closeConfirm(evt);
    }

    return <>
        <Popup
            overlayStyle={{ zIndex: 100, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={closeConfirm}
            modal
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header">API Key Modified</div>
                <div className="content">
                    <div className="font16 margintop20">{props.text}</div>
                </div>
                <div className="popup_footer" style={{ textAlign: 'center', padding: '7px' }}>
                    <button style={{ display: 'inline' }} type="button" className="button_main width25per" onClick={closeConfirm}>OK</button>
                </div>
            </div>
        </Popup>
    </>
}