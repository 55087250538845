import React, { useEffect } from 'react';

export const CatoNetworkCollector = ({
    register,
    setValue,
    collectorData
}) => {
    useEffect(() => {
        if (collectorData) {
            setValue(
                'account_ids',
                collectorData.collector_config?.account_ids
            );
            setValue(
                'api_key',
                collectorData.collector_config?.api_key
            );
        }
    }, [collectorData]);

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
            <div className='form_sub_section_header'>
                        Cato Networks SASE Configuration
                    </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Account ID*</label>
                            <input
                                type='text'
                                name='account_ids'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter comma separated Cato Account Ids'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>API Key*</label>
                            <input
                                type='text'
                                name='api_key'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Cato API Key'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
