import React from "react"
import ModalPopup from "../../../../components/core/AMModalPopup/ModalPopup"
import { AccessesObj } from "../../../../types/response-types"
import './FailedDirPopUp.scss'
import { getRoundedUnit } from "../../../../utils/util-methods"
import moment from "moment"
import { AMCountryFlag } from "../../../../components/core/AMCountryFlag/AMCountryFlag"

interface IFailedDirPopUpProps {
    data: AccessesObj,
    onClose(): void
}
export const FailedDirPopUp = ({ data, onClose }: IFailedDirPopUpProps) => {
    return <ModalPopup
        onCloseHandler={() => onClose()}
        title={`Failed Directory Authentication for ${data?.directory_hostname || data?.directory_name}`}
        isOpen={true}
        classNames="failed-dir-pop-container scrollbar-container"
    >
        <div>
            <div>
                {/*  <div className="failed-dir-container-row">
                    <span>Directory Hostname - <strong><i>{data?.directory_hostname}</i></strong></span>
                </div> */}
                <div className="failed-dir-container-row flex-between">
                    <span className="flex-ellipsis"
                        title={data?.identity}
                    >Identity - <strong><i>{data?.identity}</i></strong></span>
                    <span className="connect-arrow"></span>
                    <span className="flex-ellipsis" style={{ flex: 1 }}
                        title={data?.asset}>Asset - <strong><i>{data?.asset}</i></strong></span>
                </div>
                <div className="failed-dir-container-row">
                    <span>Failed Percentage : {Number(data?.auth_failed_percent).toFixed(1)} %</span>
                </div>
                <div className="auth-count-container failed-dir-container-row flex-between">
                    <div title={data.directory_flow_fail_count.toString()}><span className="failed-dir-square color-red"></span><span
                        style={{ verticalAlign: 'top' }}
                    >Auth Failed Count: {
                            getRoundedUnit(data.directory_flow_fail_count)}</span></div>
                    <div title={data.directory_flow_success_count.toString()}><span className="failed-dir-square color-green"></span><span
                        style={{ verticalAlign: 'top' }}
                    >Auth Success Count: {
                            getRoundedUnit(data.directory_flow_success_count)
                        }</span></div>


                    <div title={data?.directory_flow_unknown_count?.toString() || ''}>
                        {(data?.directory_flow_unknown_count || 0) > 0 && <>
                            <span className="failed-dir-square color-gray"></span>
                            <span
                                style={{ verticalAlign: 'top' }}>Unknown Auth Count: {
                                    getRoundedUnit(data?.directory_flow_unknown_count || 0)
                                }</span></>}
                    </div>
                </div>            </div>
            <div>
                <label>Latest {data?.failed_auth_list?.length} Failed Authentication Sources</label>
            </div>
            <div className="scrollbar-container failed-dir-table" >
                <div className="failed-dir-table-container">
                    <table className="sticky_table_top">
                        <thead>
                            <tr>
                                <th>Failed Auth Time</th>
                                <th>Hostname</th>
                                <th className="align_left">Location Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.failed_auth_list && data?.failed_auth_list?.length > 0 && data?.failed_auth_list.map((i) => {
                                return <tr>
                                    <td>{moment(i.gen_timestamp).format('MMM DD YYYY,')}&nbsp;{moment(i.gen_timestamp).format('h:mm A')}</td>
                                    <td>{i.source_hostname}</td>
                                    <td className="align_left"><div className="display-flex"> <AMCountryFlag countryCode={i.country_code2} />
                                        {`${i.city_name} - ${i.country_name}`}
                                    </div></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="launch_button dialog-button">
            <button
                onClick={onClose}
                type={"button"} className={"float_right  button_styled"}>
                Close
            </button>
        </div>
    </ModalPopup >
}