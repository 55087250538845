import React from "react"
import { getRiskLevelColor, getRiskTitleByIndex } from "../../utils/util-methods"

export const IDScore = ({ data: { _id, score } }: any) => {
    return <div style={{ display: 'flex' }}><div className='risk_score_count'>{score < 0 ? '0.00' : score?.toFixed(2)?.toString() || '0.00'} </div>
        <div className="float_right" id={_id?.toString() || ''}>
            {
                Math.round(score) >= 80 ? <span className="dot_rule"
                    title={getRiskTitleByIndex(4)}
                    style={{ color: getRiskLevelColor(4) }}>&bull;</span> :
                    Math.round(score) >= 60 ?
                        <span className="dot_rule"
                            title={getRiskTitleByIndex(3)}
                            style={{ color: getRiskLevelColor(3) }}>&bull;</span> :
                        Math.round(score) >= 40 ?
                            <span className="dot_rule"
                                title={getRiskTitleByIndex(2)}
                                style={{ color: getRiskLevelColor(2) }}>&bull;</span> :
                            Math.round(score) >= 20 ?
                                <span
                                    className="dot_rule"
                                    title={getRiskTitleByIndex(1)}
                                    style={{ color: getRiskLevelColor(1) }}>&bull;</span> :
                                <span className="dot_rule_empty">&#9711;</span>
            }
        </div>
    </div>
}