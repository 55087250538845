import * as CsvParse from 'papaparse';

export const useCSVImport = () => {

    const fileReader = new FileReader();

    const handleOnCSVImportChange = (e: any) => {
        return new Promise((resolve, reject) => {
            const f = e;
            if (f) {
                fileReader.onload = (event: any) => {
                    const text = event.target?.result;
                    const parsedWithHeader = CsvParse.parse(text, { header: true });
                    const parsedWithoutHeader = CsvParse.parse(text, { header: false });
                    resolve({ parsedWithHeader, parsedWithoutHeader, preview: text });
                };
                fileReader.readAsText(f);
            } else {
                reject();
            }
        })
    };

    return {
        handleOnCSVImportChange
    }
}
