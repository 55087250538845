export const VulnerableIcon = ({ color = "" }) => {
    return (
        // <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
        //     <path
        //         style={{ fill: color }}
        //         d="m54.92,108.56l-.1-.05C28.94,95.12,12.21,64.51,12.21,30.53v-.35s.86-10.3.86-10.3l8.68,1.07c11.92-.08,23.4-4.39,33.26-12.46l3.49-3.51,3.49,3.51c9.92,8.1,21.4,12.39,33.26,12.46l8.68-1.07.86,10.62v.08c-.01,33.98-16.75,64.58-42.64,77.95l-.5.22-3.17,1.2-3.59-1.39ZM21.64,30.72c.08,30.12,14.52,57.15,36.87,69.07,22.35-11.93,36.81-38.97,36.87-69.07l-.03-.35c-13.08-.04-25.8-4.41-36.84-12.65-11.04,8.24-23.76,12.61-36.84,12.65l-.03.35Z"
        //     />
        //     <path
        //         style={{ fill: color }}
        //         d="m55.74,106.73C30.51,93.68,14.21,63.78,14.21,30.53v-.27s.69-8.15.69-8.15l6.74.83h.07c12.38-.06,24.35-4.53,34.61-12.94l.04-.03,2.14-2.16,2.15,2.16.04.03c10.27,8.41,22.24,12.89,34.62,12.94h.07s6.74-.83,6.74-.83l.69,8.46c-.01,33.24-16.32,63.14-41.55,76.17l-.34.15-2.4.91-2.77-1.07ZM19.64,30.64v.05c.05,31.04,15.11,58.95,38.38,71.11l.49.26.49-.26c23.26-12.15,38.32-40.07,38.37-71.11v-.04l-.2-2.4-1.68.13c-12.92,0-25.49-4.39-36.35-12.67l-.65-.49-.65.49c-10.86,8.28-23.43,12.66-36.35,12.67l-1.68-.13-.2,2.4Z"
        //     />
        //     <polygon style={{ fill: color }} points="43.46 64.19 58 49.65 33.21 24.86 39.58 18.49 70.73 49.65 56.19 64.19 81.08 89.09 74.72 95.45 43.46 64.19" />
        //     <polygon style={{ fill: color }} points="74.72 92.62 46.29 64.19 60.83 49.65 36.04 24.86 39.58 21.32 67.9 49.65 53.36 64.19 78.25 89.09 74.72 92.62" />
        // </svg>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
            <path style={{ fill: color }} d="m46.4,64.53l22.08,22.08c-3.01,2.74-6.28,5.08-9.8,6.96-18.22-9.72-30.02-31.76-30.07-56.34l.02-.28c4.16-.01,8.27-.57,12.28-1.64l17.35,17.36-11.86,11.86Z" />
            <path style={{ fill: color }} d="m88.72,36.95l.02.29c-.03,16.97-5.67,32.74-15.18,44.08l-16.78-16.79,11.86-11.86-20.08-20.08c3.51-1.58,6.9-3.57,10.11-5.97,9,6.72,19.38,10.29,30.04,10.32Z" />
            <path
                style={{ fill: color }}
                d="m57.46.19C25.83.19.19,25.83.19,57.45s25.63,57.27,57.27,57.27,57.26-25.63,57.26-57.27S89.09.19,57.46.19Zm38.99,36.93c-.01,27.72-13.67,52.67-34.78,63.57l-.41.19-2.59.98-2.92-1.13-.07-.03c-21.11-10.92-34.76-35.89-34.76-63.61v-.29s.7-8.4.7-8.4l7.08.87c9.72-.07,19.09-3.59,27.13-10.16l2.84-2.87,2.86,2.85c8.09,6.61,17.45,10.11,27.13,10.17l7.08-.87.71,8.66v.07Z"
            />
        </svg>
    );
};
