import React, { useState, useEffect } from "react";
import { BaseProps } from "../../constants/types";
import { DirParamThreshold, UnauthLoginTimeState } from "../../../../types/playbooks-config";
import { ThresholdSelect } from "../../ref/ThresholdSelect";
import { ResetState } from "../../constants/DefaultState";


const UnauthorizedLoginTime = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<UnauthLoginTimeState>) => {
    const [state, setState] = useState<UnauthLoginTimeState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleNumAccWithBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state?.dir_params, user_unauthorized_time_count_curhour: parseInt(e.target.value) } })
    }

    useEffect(() => {
        const user_input1 = state?.dir_params?.user_unauthorized_time_count_curhour;
        const object = {
            user_input1
        }
        if (getExpression) {
            getExpression(object);
        }
    })


    const onThresholdSelect = (value) => {
        let user_unauthorized_time_count_curhour = state?.dir_params?.user_unauthorized_time_count_curhour;
        const params: DirParamThreshold = {
            allow_ml_modify: false,
            param_name: 'user_unauthorized_time_count_curhour',
            threshold_confidence: undefined,
            threshold_type: 'manual'
        };
        if (value.target.value == 'automatic') {
            params.allow_ml_modify = true;
            params.threshold_type = value.target.value;
            params.threshold_confidence = 'low';
            user_unauthorized_time_count_curhour = ResetState[ruleType]?.dir_params?.user_unauthorized_time_count_curhour;
        }
        setState({ dir_params: { user_unauthorized_time_count_curhour: user_unauthorized_time_count_curhour}, });
    }

    return <>
        <div className="dir-main-container">
            <div className="dir-container-row bold-text">
                <label className="playbook-config-title marginBottom0">
                    An AD account will be marked as having repeated login attempts at invalid time when:</label>
            </div>
            <div className="dir-container-row">
                <label>
                    Number of login attempts in an hour exceeds
                </label>
                {/*  <ThresholdSelect onThresholdSelect={onThresholdSelect}
                    defaultValue={state?.dir_params?.param_thresholds?.[0]?.threshold_type || "manual"} /> */}
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_unauthorized_time_count_curhour}</label> :
                        <input
                            className=""
                            type="number"
                            onChange={handleNumAccWithBadPass} name="num_unauth_attempts"
                            value={state?.dir_params?.user_unauthorized_time_count_curhour} />

                }
            </div>
        </div></>
}

export default React.memo(UnauthorizedLoginTime);