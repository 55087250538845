import React, { useState } from "react";
import Popup from "reactjs-popup";
import './AMConfirmPassword.css';

export const AMConfirmPassword = (props: any) => {
    const [errors, setErrors] = useState<any>();
    const [password, setPassword] = useState<any>();
    const [inputType, setInputType] = useState<string>('password');
    const closeConfirm = (evt: any) => {
        if (props.closeConfirmPassword) {
            props.closeConfirmPassword(evt);
        }
        setPassword('');
        setInputType('password');
    }

    const confirmPasswordOperation = (evt: any) => {
        if (evt.key && evt.key != 'Enter') {
            return;
        }
        if (!password || (password && password.length < 0)) {
            setErrors({ password: { type: 'required' } });
            return;
        } else {
            setErrors({});
        }
        if (props.confirmPasswordOperation) {
            props.confirmPasswordOperation(evt);
        }
        setPassword('');
        setInputType('password');
    }

    const onChange = (evt: any) => {
        if (props.onPasswordEnter) {
            props.onPasswordEnter(evt.target.value);
        }
        setPassword(evt.target.value);
        setErrors({});
    }


    const manageEyeClick = (e: any) => {
        const isToggle = Array.from(e.currentTarget.classList).find((i: any) => i == 'eye-open-ampwd');
        if (!isToggle) {
            e.currentTarget.classList.add('eye-open-ampwd');
            setInputType('text');
        } else {
            e.currentTarget.classList.remove('eye-open-ampwd');
            setInputType('password');
        }
    }

    return <>
        <Popup
            {...props}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={props.openConfirmPassword}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={closeConfirm}
            modal
            className="am-confirm-password"
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header">{props.title}</div>
                <div className="content">
                    <div className="font16 margintop20 marginbottom20">{props.content}</div>
                    <input type={inputType} onChange={onChange} required onKeyPress={confirmPasswordOperation} />
                    <i className="eye-close-ampwd" id="togglePassword" onClick={manageEyeClick}></i>
                    {errors?.password && errors.password.type === 'required' && <div className="error marginleft40per">Please enter password.</div>}
                </div>
                <div className="popup_footer">
                    <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>Cancel</button>
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={confirmPasswordOperation}>Proceed</button>
                </div>
            </div>
        </Popup>
    </>
}