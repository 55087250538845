import React from 'react';
import './issue-trend-skeleton.scss'

export const IssueTrendSkeleton = ()=>{
    return <>
     <div className='skeleton-issue-trend-container animate-pulse'>
                <div className='skeleton-title'></div>
                <div className='skeleton-content'>
                </div>

            </div>
    </>
}