import { useEffect, useState } from 'react';
import { getCategoryByKey } from '../../../../utils/util-methods';
import { DIRECTORY_CATEGORY } from '../../../services/GlobalService';

interface IDirectoryCategoryProps {
	categories: Array<number>;
	title?: string;
}
export const DirectoryCategory = ({
	categories,
	title = 'Category'
}: IDirectoryCategoryProps) => {
	const [categoryList, setCategoryList] = useState(categories);

	useEffect(() => {
		if (categories?.length > 0) {
			const tempCat: Array<number> = [];
			categories.forEach((category: number) => {
				if (DIRECTORY_CATEGORY.includes(category)) {
					tempCat.splice(0, 0, category);
				} else {
					tempCat.push(category);
				}
			});
			setCategoryList(tempCat);
		}
	}, [categories]);

	return (
		<>
			{categoryList?.filter((value: number) => value > 0)?.length > 0 && (
				<>
					{title}:
					<br />
					<ul style={{ marginLeft: 10 }}>
						{categoryList
							?.filter((value: number) => value > 0)
							.map((category: number) => {
								return <li>{getCategoryByKey(category)}</li>;
							})}
					</ul>
				</>
			)}
		</>
	);
};
