import { useExceptionAdvanceSearch } from './useExceptions';
import { Tooltip } from '@mui/material';
import { ExceptionRulesSummary } from './ExceptionRulesSummary';
import { AMLabel } from '../../../components/core/AMTooltipAd/AMTooltipAd';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';

export const ExceptionSummary = () => {
    const { fetchExceptionDataFromStore } = useExceptionAdvanceSearch();
    const data = fetchExceptionDataFromStore();
    return <>
        <div className='exception-summary-container'>
            <div
                className='error-tooltip' style={{ display: 'flex', gap: '10px' }}>
                <WarningIcon color='error' className='warning-icon' />
                <div>Please review this summary carefully because this exception could lead to unintended closure of incidents or desired incidents not being created.</div>
            </div>
            <div className='exception-summary-row'>
                <h5>Description</h5>
                <span>{data?.name}</span>
            </div>
            <div className='exception-summary-row'>
                <div className='exception-summary-width-50-per'>
                    {/* <h5>What to do with access flows matching this Exception ?</h5> */}
                    <AMLabel
                        labelClasses='headingLabel'
                        title='What to do with access flows matching this Exception ?' >
                        Action for Access Flows
                    </AMLabel>
                    <span>{data?.flow_handling == 1 ? `Do not create incident` : `Create incident but mark as closed`}</span>
                </div>
                <div className='exception-summary-width-50-per'>
                    {/* <h5>Which issue types should this Exception apply to</h5> */}
                    <AMLabel
                        labelClasses='headingLabel' title='Which issue types should this Exception apply to' >For Issue Types</AMLabel>
                    <Tooltip
                        followCursor={true}
                        classes={{ tooltip: 'pattern-form-generator generic-tooltip-popper scrollbar-container' }}
                        PopperProps={{
                            className: 'pattern-form-generator popper-class', style: { zIndex: 10000000, maxWidth: '28%' }
                        }}
                        title={
                            <span>{data?.all_rules_enabled ? 'All Issues Selected' :
                                <ul>{data?.rule_names.map((i: string) => (<li>{getIssueNameById(i.replace('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password'))}</li>))}</ul>
                            } </span>}>
                        <span>{data?.all_rules_enabled ? 'All Issues Selected' : data?.rule_names.map(i => getIssueNameById(i)).join(', ').replace('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password')}</span>
                    </Tooltip>
                </div>
            </div>
            <div className='exception-summary-row'>
                <ExceptionRulesSummary criteria={data?.criteria} />
            </div>
        </div >
    </>
}