import React, { useEffect, useState } from "react"
import Popup from "reactjs-popup"
import { useToasts } from "../../components/core";

interface IApiTokenSummary {
    onClose(): void,
    apitToken: string,
    clientId: string
}

export const ApiTokenSummary = ({ onClose, apitToken, clientId }: IApiTokenSummary) => {
    const [showLoader, setShowLoader] = useState(true);
    const { addToast } = useToasts()

    useEffect(() => {
        setShowLoader(true)
        setTimeout(() => {
            setShowLoader(false)
        }, 800)
    }, [])

    return <>
        <Popup
            closeOnDocumentClick={false}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnEscape={false}
            modal
            className="api-token-summary-container-root"
        >
            <div className={"modal"}>
                <div className={"content"}>
                    {showLoader && <div className="loading" style={{ margin: '0 auto' }}></div>}
                    {!showLoader && <div className="api-token-summary-container">
                        <div className="token-summary-img"></div>
                        <div className="api-token-summary-flex-container">
                            <h2>Token Details</h2>
                            {/* <div className="secret-desc">Save these details for this API key as the Secret won't be shown again.</div> */}
                        </div>
                        {/* <div className="api-token-summary-flex-container">
                            <label>Client Id</label>
                            <input type="text" readOnly value={clientId.replace(/(.{8})(.{8})(.{8})(.{0,8})/, '$1-$2-$3-$4').replace(/-+$/, '')}>
                            </input>
                            <div className="new_copy_icon"
                                onClick={() => navigator.clipboard.writeText(clientId)}
                            ></div>
                        </div> */}
                        <div className="api-token-summary-flex-container">
                            <label>JWT Token</label>
                            <input type="text" readOnly
                                value={apitToken}
                            ></input>
                            <div className="new_copy_icon"
                                onClick={() => {
                                    navigator.clipboard.writeText(apitToken)
                                    addToast("API token copied.", {
                                        appearance: 'success',
                                        autoDismiss: true,
                                    })
                                }}
                            ></div>
                        </div>
                        <div className="api-token-summary-flex-container">
                            <div className="policy_save_button width100" onClick={onClose} >Ok</div>
                        </div>
                    </div>}
                </div>
            </div>
        </Popup>
    </>
}

interface IApiTokenClientFields {
    clientId: string,
    apitToken: string,
    proceedRegenerateForm(): void
}
export const ApiTokenClientFields = ({ apitToken, clientId, proceedRegenerateForm }: IApiTokenClientFields) => {
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const { addToast } = useToasts()

    useEffect(() => {

    }, [apitToken])

    const handleProceed = () => {
        proceedRegenerateForm();
        setLoading(true);
        setTimeout(() => {
            setLoading(false)
            setIsDisabled(true);
            setSeconds(10);
        }, 800)
    }

    useEffect(() => {
        let timer;
        if (isDisabled) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => {
                    if (prevSeconds > 1) {
                        return prevSeconds - 1;
                    } else {
                        clearInterval(timer);
                        setIsDisabled(false);
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isDisabled]);

    return <>
        <div className={"api-token-summary-container " + (loading ? ' disabled' : '')}>
            {loading && <div className="loading" style={{ position: 'absolute', zIndex: 100, margin: '15%' }}></div>}
            <div style={{ width: '100%' }} className="marginbottom10"><h3>Token Details</h3></div>
            {/*  <div className="api-token-summary-flex-container">
                <label>Client Id</label>
                <input type="text" readOnly value={clientId.replace(/(.{8})(.{8})(.{8})(.{0,8})/, '$1-$2-$3-$4').replace(/-+$/, '')}>
                </input>
                <div className="new_copy_icon"
                    onClick={() => navigator.clipboard.writeText(clientId)}
                ></div>
            </div> */}
            <div className="api-token-summary-flex-container">
                <label>JWT Token</label>
                <input type="text" readOnly
                    value={apitToken}
                ></input>
                <div className="new_copy_icon"
                    onClick={() => {
                        navigator.clipboard.writeText(apitToken)
                        addToast("API token copied.", {
                            appearance: 'success',
                            autoDismiss: true,
                        })
                    }}
                ></div>
            </div>
            <div className="api-token-summary-flex-container"
                style={{ justifyContent: 'end', alignItems: 'end' }}
            >
                <div className={"policy_save_button reset_secret_btn " + (isDisabled ? ' disabled grayscaleOne' : '')}
                    onClick={handleProceed} >Reset Token {`${isDisabled ? 'in (' + seconds + ')' : ''}`}</div>
            </div>
        </div>
    </>
}