import React, { useEffect, useRef, useState } from 'react';
import { CredentialQualityConfigAlgo, HashSecurityConfigProps, CredentialQualityConfigProtocol, HashSecurityConfigState } from '../../../../types/hash-security-config-types';


const HashSecurityConfig = ({ parentState, isSavedOnce, getState, defaultState, formType }: HashSecurityConfigProps) => {

    const [state, setState] = useState(parentState);

    const hashRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const saltRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (defaultState)
            setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce && (hashRef.current || saltRef.current)) {
            setState(parentState);
            if (!parentState?.hashFlag || (parentState.hashFlag && parentState.hashValue === undefined)) {
                hashRef.current.value = "";
            }
            if (!parentState?.saltFlag || (parentState.saltFlag && parentState.saltValue === undefined)) {
                saltRef.current.value = "";
            }
        }
    }, [isSavedOnce])

    useEffect(() => {
        // console.log('CredQUALITYConfig STATE', state);
        getState(state);
    })

    const renderAlgoValue = (algos: CredentialQualityConfigAlgo[] | undefined, algoFlag: boolean | undefined) => {
        return algos?.map((algo: CredentialQualityConfigAlgo) => {
            return (
                <React.Fragment key={algo.name}>
                    <input type="checkbox" id={algo.name} name={algo.name} checked={algo.isChecked}
                        className={(formType && ['edit', 'view'].includes(formType) ? ' disable-config-item' : '')}
                        disabled={!algoFlag}
                        onChange={(e) => {
                            if (formType && ['edit', 'view'].includes(formType))
                                return;
                            if (e.target.checked) {
                                algo.isChecked = true;
                            }
                            else {
                                algo.isChecked = false;
                            }
                            setState({
                                ...state,
                                algoValue: state?.algoValue
                            })
                        }}
                    />
                    <label className="float_none" htmlFor={algo.name}>{algo.name}</label>
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }

    const renderLegacyProcValue = (legacyProcs: CredentialQualityConfigProtocol[] | undefined, legFlag: boolean | undefined) => {
        return legacyProcs?.map((proc: CredentialQualityConfigProtocol) => {
            return (
                <React.Fragment key={proc.name}>
                    <input type="checkbox" id={proc.name} name={proc.name} checked={proc.isChecked}
                        className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        disabled={!legFlag}
                        onChange={(e) => {
                            if (formType && ['edit', 'view'].includes(formType))
                                return;
                            if (e.target.checked) {
                                proc.isChecked = true;
                            }
                            else {
                                proc.isChecked = false;
                            }
                            setState({
                                ...state,
                                legacyProcValue: state?.legacyProcValue
                            })
                        }}
                    />
                    <label className="float_none" htmlFor={proc.name}>{proc.name}</label>
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }

    const renderCommonProcValue = (commonProcs: CredentialQualityConfigProtocol[] | undefined, commonFlag: boolean | undefined) => {
        return commonProcs?.map((proc: CredentialQualityConfigProtocol) => {
            return (
                <React.Fragment key={proc.name}>
                    <input type="checkbox" id={proc.name} name={proc.name} checked={proc.isChecked}
                        className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        disabled={!commonFlag}
                        onChange={(e) => {
                            if (formType && ['edit', 'view'].includes(formType))
                                return;
                            if (e.target.checked) {
                                proc.isChecked = true;
                            }
                            else {
                                proc.isChecked = false;
                            }
                            setState({
                                ...state,
                                commonProcValue: state?.commonProcValue
                            })
                        }}
                    />
                    <label className="float_none" htmlFor={proc.name}>{proc.name}</label>
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }

    return (
        <>
            <form action="/">
                <div className="playbook-config-title">Authentication quality will be considered poor if:</div>              
                <input type="checkbox" id="hash-algo" name="hash-algo" checked={state?.algoFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                    onChange={(e) => {
                        if (formType && ['edit', 'view'].includes(formType))
                            return;
                        if (e.target.checked) {
                            setState({
                                ...state,
                                algoFlag: true
                            })
                        }
                        else {
                            let temp = state?.algoValue?.map((algo: CredentialQualityConfigAlgo) => {
                                return {
                                    name: algo.name,
                                    isChecked: false
                                }
                            })
                            setState({
                                ...state,
                                algoFlag: false,
                                algoValue: temp
                            })
                        }
                    }}
                />
                <label className="float_none" htmlFor="hash-algo"><b>Weak Hash algorithms</b> are been used:</label>
                <div className="clrBoth"></div>
                <div className="sub_options fl">
                    {renderAlgoValue(state?.algoValue?.slice(0, 2), state?.algoFlag)}
                </div>

                <div className="sub_options fl">
                    {renderAlgoValue(state?.algoValue?.slice(2, 4), state?.algoFlag)}
                </div>

                <div className="sub_options fl">
                    {renderAlgoValue(state?.algoValue?.slice(4), state?.algoFlag)}
                </div>

                <div className="clrBoth"></div>
            </form>
        </>
    )
}

export default React.memo(HashSecurityConfig);