import React, {useEffect, useState} from 'react'
import zxcvbn from 'zxcvbn'

import './PasswordStrengthMeter.css'

type Props = {
    password: string;
};

export const PasswordStrengthMeter = ({password}: Props) => {
    const [passwordMeterDetails, setPasswordMeterDetails] = useState({
        rating: "",
        bg_color: "",
        pp: "",
        crack_time: "",
        cost: "",
        populated: false
    })

    useEffect(() => {
        if(password !== "") {
            const bgColor = ['bg_danger', 'bg_danger', 'bg_danger', 'bg_warning', 'bg_success']
            const ratings = ["Weak", "Weak", "Weak", "Moderate", "Strong"]
            const width = ["25%", "50%", "75%", "100%"]
            const result = zxcvbn(password)
            const cts:any = result.crack_times_seconds.offline_slow_hashing_1e4_per_second
            setPasswordMeterDetails({
                rating: ratings[result.score],
                bg_color: bgColor[result.score],
                pp: width[result.score],
                crack_time: "Time to crack: " + result.crack_times_display.offline_slow_hashing_1e4_per_second,
                cost: "Cost of cracking: $ " + ((cts * 25)/3600).toFixed(3),
                populated: true
            })
        } else {
            setPasswordMeterDetails({
                rating: "",
                bg_color: "",
                pp: "",
                crack_time: "",
                cost: "",
                populated: false
            })
        }
    }, [password])


    if(passwordMeterDetails.populated === false) return null
    return (
        <>
            <div className="pass_strength_box">
                <div className={"progress_bar " + passwordMeterDetails.bg_color} id="progress" style={{width: passwordMeterDetails.pp}}>{passwordMeterDetails.rating}</div>
                <div className="crack_time">{passwordMeterDetails.crack_time}</div>
                <div className="crack_cost">{passwordMeterDetails.cost}</div>
            </div>
            <div className="clear_both"></div>
        </>
    )
}