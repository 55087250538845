import React from 'react';

const ProtocolQualityConfigView = ({ config }) => {
    const renderLegacyProcValue = (legacyProcs) => {
        let res = [];
        legacyProcs?.forEach((proc) => {
            if (proc.isChecked) {
                res.push(proc.name);
            }
        });
        return res.join(', ');
    };

    const renderCommonProcValue = (commonProcs) => {
        let res = [];
        commonProcs?.forEach((proc) => {
            if (proc.isChecked) {
                res.push(proc.name);
            }
        });
        return res.join(', ');
    };

    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>Authentication quality will be considered poor if:</span>
                <ul className='ml-1'>
                    {config.legacyProcFlag && (
                        <li>
                            Legacy (less secure) protocols{' '}
                            <strong>
                                {renderLegacyProcValue(config.legacyProcValue)}
                            </strong>
                        </li>
                    )}
                    {config.commonProcFlag && (
                        <li>
                            Common protocols{' '}
                            <strong>
                                {renderCommonProcValue(config.commonProcValue)}
                            </strong>{' '}
                            used without channel encryption
                        </li>
                    )}
                    {config.apq_ignore_private_ip && (
                        <li>
                            Do not apply if source and destination have private
                            IP addresses
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
};

export default React.memo(ProtocolQualityConfigView);
