import moment from "moment";
import React, { useEffect, useRef, useState } from "react"
import Popup from "reactjs-popup"
import { Api } from "../../../components/Axios";
import { convertToCommaValue } from "../../../utils/util-methods";
import './SensorCollectorPopUp.css'
export const SensorCollector = (props: any) => {
    const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [lastUpdate, setLastUpdate] = useState<string>();
    const [result, setResult] = useState<any>();
    const [loader, setLoader] = useState(false);
    const closeConfirm = (evt: any) => {
        if (props.closeConfirm)
            props.closeConfirm(evt);
    }

    const reloadCollectors = (evt: any) => {
        reloadRef.current.classList.add('reload_spin');
        loadCollectors();
        setTimeout(() => {
            if (reloadRef.current.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin');
                setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'))
            }
        }, 750)
    }

    useEffect(() => {
        loadCollectors();
    }, []);

    const loadCollectors = () => {
        setLoader(true);
        // const result = {
        //     "AWS VPC Flow Logs": { "flow_count": 13328922, "last_flow_time": "2022-06-17T11:09:44Z" },
        //     "OpenVPN": { "flow_count": 17520, "last_flow_time": "2022-06-17T11:09:10Z" },
        //     "Umbrella": { "flow_count": 384938, "last_flow_time": "2022-06-10T13:50:18Z" }
        // };
        Api.get(`sensors/${props.data.id}?required_collector_info=true`).then((res: any) => {
            setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
            // if (res.data) {
            //setResult(res.data);
            // } else {
            setResult(res.data);
            // }
            setLoader(false);
        }).catch((err: any) => {
            setLoader(false);
            console.log(err);
        });
    }

    return <>
        <Popup
            overlayStyle={{ zIndex: 100, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={closeConfirm}
            modal
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header" title={`Collectors on ${props.data.name}`}><span className="ellipsis_common">{`Collectors on`} <i>{props.data.name}</i></span></div>
                <div className="content">
                    <div className="max-height-400 scrollbar-container">
                        <table id="table" className="tablesorter sensor-collector-container max-height-400">
                            <thead>
                                <tr style={{ position: 'sticky', top: '0' }}>
                                    <th colSpan={16} style={{ background: "#ffffff", color: "#4e5267" }} >
                                        <div className="reload_container sensor-collector">
                                            <div ref={reloadRef} className="reload_color_icon" onClick={reloadCollectors}></div>
                                        </div>

                                        <div className="table_title">Last Updated: {lastUpdate}</div>
                                        {/* <div className="search_white_icon">&nbsp;</div> */}
                                        {/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}

                                    </th>
                                </tr>
                                <tr style={{ position: 'sticky', top: '0', zIndex: 100000 }}>
                                    <th><span className='align_left float_left'>Collector</span></th>
                                    <th><span className='align_left float_left'>Last Access Time</span></th>
                                    <th><span>Flows</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loader ?
                                        <td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td> :
                                        result ? Object.keys(result).map((item: any) => {
                                            return <tr key={item}>
                                                <td className="align_left width250" title={item}><div className="width250">{item}</div></td>
                                                <td className="align_left">{moment(result[item].last_flow_time).format('DD MMM YYYY, hh:mm A')}</td>
                                                <td>{convertToCommaValue(result[item].flow_count)}
                                                    <span className='heartbeat_flow'>
                                                        {(moment.duration(moment().diff(moment(result[item].last_flow_time))).asHours() < 2) ?
                                                            <span style={{ position: 'relative' }} className="heartbeat_green_dot">&#9679;</span>
                                                            :
                                                            (moment.duration(moment().diff(moment(result[item].last_flow_time))).asHours() > 1) && (moment.duration(moment().diff(moment(result[item].last_flow_time))).asHours() < 2) ?
                                                                <span style={{ position: 'relative' }} className="heartbeat_orange_dot">&#9679;</span>
                                                                :
                                                                <span style={{ position: 'relative' }} className="heartbeat_red_dot">&#9679;</span>}

                                                    </span>
                                                </td>
                                            </tr>
                                        }) :
                                            <tr>No collector information found</tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="popup_footer" style={{ textAlign: 'center', padding: '7px' }}>
                    <button style={{ display: 'inline' }} type="button" className="button_main width25per" onClick={closeConfirm}>OK</button>
                </div>
            </div>
        </Popup>
    </>
}