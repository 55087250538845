import React, {useEffect} from 'react'
import { Redirect, useLocation } from 'react-router-dom'

type Result = {
    compromised: number;
    flows: number;
    issues: number;
    score: {
        Compromised: number;
        good: number;
        moderate: number;
        weak: number;
    };
}

// const defaultReportDetails = {
//     compromised: 0,
//     flows: 0,
//     issues: 0,
//     score: {
//         Compromised: 0,
//         good: 0,
//         moderate: 0,
//         weak: 0
//     }
// };

type Listing = {
    destination: string;
    source: string;
}

const defaultReportDetails = {
    flows: 0,
    issues: 0,
    flow_details: 0,
    score: {
        Compromised: 0,
        good: 0,
        moderate: 0,
        weak: 0
    },
    trends: 0
}



type ReportContextType = {
    reportData: any;
    setReportData: (value: any) => void;
};

const ReportContext = React.createContext<ReportContextType | undefined>(
    undefined
);

type Props = {
    children: React.ReactNode;
};

export const ReportProvider = ({ children }: Props) => {
    let location = useLocation()
    const [reportData, setReportData] = React.useState(defaultReportDetails);

    useEffect(() => {
        // window.localStorage.removeItem('stats')
        // window.localStorage.removeItem('protocols')
        // window.localStorage.removeItem('destinations')
        // socket.on('initial_dashboard', function(data:string) {
        //     console.log(data)
        //     let p = JSON.parse(data)
        //     let stats = {
        //         flows: p.result.flows,
        //         issues: p.result.issues,
        //         flow_details: 0,
        //         score: {
        //             Compromised: p.result.score.Compromised,
        //             good: p.result.score.good,
        //             moderate: p.result.score.moderate,
        //             weak: p.result.score.weak,
        //         },
        //         trends: 0
        //     }
        //     window.localStorage.setItem('stats', JSON.stringify(stats))
        //     window.localStorage.setItem('protocols', JSON.stringify(p.result.protocol))
        //     window.localStorage.setItem('destination', JSON.stringify(p.result.destination))
        //     setReportData(stats)
        // })

        // socket.on('initial_access_flows', function(data:string) {
        //     window.localStorage.setItem('flow_stats', JSON.stringify([]))
        //     let stats = window.localStorage.getItem('stats')
        //     console.log("In Stats")
        //     if(stats !== null) {
        //         console.log("In Stats1")
        //         let details = JSON.parse(stats)
        //         let p = JSON.parse(data)
        //         if(p.response_code === 200) {
        //             var j:Array<{}> = []
        //             p.result.hits.hits.map((v:any) => {
        //                 j.push({id: v._id, user: v._source.param2.UserName, destination: v._source.param3.DestinationName})
        //             })
        //             details.flow_details = p.result.hits.hits.length
        //             window.localStorage.setItem('flow_stats', JSON.stringify(j))
        //             setReportData(details)
        //         }
        //     }
        // })

        // socket.on('initial_flow_trends', function(data:string) {
        //     window.localStorage.setItem('flow_trends', JSON.stringify([]))
        //     let stats = window.localStorage.getItem('stats')
        //     console.log("In Stats")
        //     if(stats !== null) {
        //         console.log("In Stats1")
        //         let details = JSON.parse(stats)
        //         let p = JSON.parse(data)
        //         if(p.response_code === 200) {
                    
        //             var trends:Array<{}> = []
        //             p.result.aggregations.flow_trends.buckets.map((v:any, i:number) => {
        //                 let d = v.key_as_string
        //                 let t = typeof(v.param5.doc_count) !== "undefined" ? v.param5.doc_count : 0
        //                 let den = 0
        //                 v.param5.issues.buckets.map((k:any, j:number) => {
                            
        //                     if(k.key === "Denied") {
        //                         den = k.doc_count
        //                     }
        //                 })
        //                 trends.push({t: v.key_as_string, d: den, s: (t - den)})
        //             })
        //             console.log(trends)
        //             details.trends = details.trends + 1
        //             window.localStorage.setItem('flow_trends', JSON.stringify(trends))
        //             setReportData(details)
        //         }
        //     }
        // })

        // socket.on('emit_flow', function(data:string) {
        //     let stats = window.localStorage.getItem('stats')
        //     let flows = window.localStorage.getItem('flow_stats')
        //     let d = JSON.parse(data)
            
        //     if(stats !== null && flows !== null) {
        //         console.log(stats)
        //         let details = JSON.parse(stats)
        //         let f = JSON.parse(flows)
        //         details.flow_details = details.flow_details + 1
        //         f.push({id: d.result.param1.ESID, user: d.result.param2.UserName, destination: d.result.param3.DestinationName, new: true})
        //         window.localStorage.setItem('flow_stats', JSON.stringify(f))
        //         window.localStorage.setItem('stats', JSON.stringify(details))
        //         setReportData(details)
        //     }
        // })

        // socket.on('emit_issue', function(data:string) {
        //     let stats = window.localStorage.getItem('stats');
        //     let flow_stats = window.localStorage.getItem('flow_stats');
        //     let d = JSON.parse(data);
        //     if(stats !== null && flow_stats !== null) {
        //         console.log(stats)
        //         let details = JSON.parse(stats)
        //         let s = JSON.parse(flow_stats)
        //         details.issues = details.issues + 1
                
        //         if(details.flow_details < 10) {
        //             details.flow_details = details.flow_details + 1
        //             s.push({source: d.result.source,destination: d.result.destination,new: true})
        //         } else {
        //             s.shift()
        //             s.push({source: d.result.source,destination: d.result.destination,new: true})
        //         }
        //         window.localStorage.setItem('stats', JSON.stringify(details))
        //         window.localStorage.setItem('flow_stats', JSON.stringify(s))
        //         setReportData(details)
        //     }
        // })
    }, []);

    return (
        <ReportContext.Provider value={{ reportData, setReportData }}>
            {children}
        </ReportContext.Provider>
    )
}

export const useReportDetails = () => React.useContext(ReportContext)