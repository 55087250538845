import { keys } from 'highcharts';
import { Api } from '../../../../components/Axios';
import { useToasts } from '../../../../components/core';
import { IPostureArchiveRequest } from '../types/PostureTypes';
import { AxiosResponse } from 'axios';
import * as qs from 'qs';

export interface IApiResponse extends AxiosResponse { }

const generateQueryParams = (queryMap: Record<string, string> | undefined): string => {
	if (queryMap && Object.keys(queryMap)?.length > 0) {
		return qs.stringify(queryMap);
	}
	return '';
}



export const PostureService = () => {
	const { addToast } = useToasts();
	const Archive = {
		POST: (
			request: Partial<IPostureArchiveRequest>,
			successCallback?: any
		) => {
			Api.post('/posture/archive', request)
				.then((res: IApiResponse) => {
					const msg =
						res.data <= 10
							? `Archive submitted. Updates will be completed in a few minutes`
							: `Archive submitted. Updates may take up to an hour to be completed.`;
					addToast(msg, {
						appearance: 'success',
						autoDismiss: true
					});
					if (successCallback) {
						successCallback();
					}
				})
				.catch((err) => {
					addToast(
						'Selected entities could not be processed for archival.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				});
		}
	};

	const ArchiveEstimate = {
		POST: (
			request: Partial<IPostureArchiveRequest>,
			successCallback?: any,
			errorCallback?: any
		) => {
			Api.post('/posture/archive/estimate', request)
				.then((res: IApiResponse) => {
					try {
						const { existing, new: newEstimated } = res.data.estimated_time;
						const existingTime = existing && existing.time ? existing.time : 0;
						const newTime = newEstimated && newEstimated.time ? newEstimated.time : 0;
						const totalTimeMins = parseInt(existingTime, 10) + parseInt(newTime, 10);
						let hours = 0;
						let days = 0;
						let msgIdx = 0;

						if (totalTimeMins >= 1440) {
							days = Math.round((totalTimeMins / (60 * 24)) / 0.5) * 0.5;
							msgIdx = 3;
						} else if (totalTimeMins < 1440 && totalTimeMins > 60) {
							hours = Math.ceil(totalTimeMins / 60);
							msgIdx = 2;
						} else if (totalTimeMins > 30 && totalTimeMins <= 60) {
							msgIdx = 1;
						} else {
							msgIdx = 0;
						}

						const messages = [
							<span>Based on selection this archive will take <strong>a few minutes</strong>. Are you sure you want to proceed?</span>,
							<span>Based on selection this archive will take approximately <strong>1 hour</strong>. Are you sure you want to proceed?</span>,
							<span>Based on selection this archive will take approximately <strong>{hours} hours</strong>. Are you sure you want to proceed?</span>,
							<span>Based on selection this archive will take approximately  <strong>{days} days</strong>. Are you sure you want to proceed?</span>];

						if (successCallback) {
							successCallback(<><br></br>{messages[msgIdx]}</>);
						}
					} catch (err) {
						if (errorCallback) {
							errorCallback(err);
						}
					}
				})
				.catch((err) => {
					if (errorCallback) {
						errorCallback(err);
					}
				});
		}
	};

	const PostureSummary = {
		GET: (
			params?: Record<string, string>,
			successCallback?: any,
			errorCallback?: any
		) => {
			const queryParams = generateQueryParams(params);
			Api.get(`/posturesummary?${queryParams}`)
				.then((res: IApiResponse) => {
					if (successCallback) {
						successCallback(res);
					}
				})
				.catch((error) => {
					if (errorCallback) {
						errorCallback(error);
					}
					if (error.response.status === 500) {
						addToast(
							'Sorry, something went wrong there, try again.',
							{
								appearance: 'error',
								autoDismiss: true
							}
						);
					} else if (error.response.status === 404) {
						addToast(
							'We are not able to find associated email, please check and try again.',
							{
								appearance: 'error',
								autoDismiss: true
							}
						);
					}
				});
		}
	};
	return { Archive, ArchiveEstimate, PostureSummary };
};
