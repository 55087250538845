import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import Highcharts, { Pointer } from 'highcharts';
import broken from 'highcharts/modules/broken-axis';
import HighchartsReact from 'highcharts-react-official';
import { IdAssetWidget } from '../../../types/response-types';
import { getResolutionMatch, getRoundedUnit } from '../../../utils/util-methods';
import HC_patternFill from "highcharts/modules/pattern-fill";
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';

HC_patternFill(Highcharts);
//broken(Highcharts);

interface Custom_Data {
	"title": string,
	"chartTitle": string,
	"totalCount": number | undefined,
	"issueCount": number | undefined,
	"is_exclude": boolean,
}

type Props = {
	customData: Custom_Data,
	issues: IdAssetWidget | undefined,
	rootDetail: IdAssetWidget | undefined
	groupData?: any;
	selectedData?: any;
}

export const RuleRootWidget = ({ customData, issues, rootDetail, groupData, selectedData }: Props) => {
	let history = useHistory();

	const [realTotal, setRealTotal] = useState<number>(0);
	const [chartOuterData, setChartOuterData] = useState<Highcharts.PointOptionsObject[]>([]);
	const rootDetails: any = rootDetail !== undefined ? rootDetail : [];

	const findNameById = (idToFind: string): string => {
		if (groupData) {
			for (const obj in groupData) {
				if (groupData.hasOwnProperty(obj)) {
					const foundItem = groupData[obj]?.playbooks?.find((item: any) => item._id === idToFind);
					if (foundItem) {
						return foundItem.name;
					}
				}
			}

		}
		return getIssueNameById(idToFind);
	}

	const isIssueType = (name: any): boolean => {

		if (name) {
			const foundItem = selectedData?.find((item: any) => item._id === name);
			return foundItem ? foundItem.is_issue_type : true;

		}

	}

	const calcData = () => {
		let innerData = [];
		let outerData = [];
		let innerCloneData: any = [];
		let innerCloneEmptyIssues: any = [];
		let colorsData: any = [];
		let index = 0;
		let totalIds = 0;
		let chartData: any = issues;
		if (chartData.length <= 2) {
			colorsData = ["#454545", "#B5B5B5"]
		} else if (chartData.length <= 4) {
			colorsData = ["#454545", "#949494", "#DBDBDB", "#F0F0F0"]
		} else {
			// colorsData = ["#454545", "#747474", "#949494", "#B5B5B5", "#DBDBDB", "#E5E5E5", "#F0F0F0"]
			colorsData = [
				"#454545", "#4d4d4d", "#555555", "#5d5d5d", "#656565", "#6d6d6d", "#757575", "#7d7d7d",
				"#858585", "#8a8a8a", "#8d8d8d", "#959595", "#9d9d9d", "#a5a5a5", "#adadad", "#b5b5b5", "#bdbdbd",
				"#c5c5c5", "#cacaca", "#cdcdcd", "#d5d5d5", "#dddddd", "#e5e5e5", "#ededed", "#f5f5f5", "#fafafa"
			];

		}

		for (let ob in chartData) {
			if (chartData[ob]) {
				totalIds += chartData[ob].issue_count;

				//If incident_count is less than 3% then set minimum dummy data to 3% of totl_issues or else just incident_count ...
				let curr_incident_count_per = (chartData[ob].issue_count / customData.totalCount!) * 100;
				let one_percent = customData.totalCount! / 100;
				let three_percent = Math.round(one_percent * 3);

				if (chartData[ob].type != "" && chartData[ob].issue_count !== 0) {
					outerData.push({
						y: (curr_incident_count_per < 3 && curr_incident_count_per > 0) ? three_percent : chartData[ob].issue_count,
						name: chartData[ob].name,
						id: chartData[ob].issue_count,
						description: colorsData[index],
						custom: customData.chartTitle === "Access" ? "" : rootDetails[chartData[ob].name],
						color: colorsData[index]
					})
				}
				index++;
			}

		}

		outerData.sort(function (a: any, b: any) {
			var keyA = a.id,
				keyB = b.id;
			// Compare the 2 dates
			if (keyA > keyB) return -1;
			if (keyA < keyB) return 1;
			return 0;
		});
		outerData.map((item: any, index: any) => {
			item.color = colorsData[index]
		})
		//Calculate Flows Data 
		setChartOuterData(outerData);
		setRealTotal(totalIds);

	}

	useEffect(() => {
		if (issues) {
			calcData();
		}
	}, [customData.is_exclude, selectedData, issues])

	const options: Highcharts.Options = {
		chart: {
			width: window.matchMedia("(min-width: 2560px)").matches ? 1140 : 620,
			height: window.matchMedia("(min-width: 2560px)").matches ? 330 : 180,
			type: 'pie',
			style: {
				fontFamily: 'Metropolis-Regular',
			},

		},
		credits: {
			enabled: false
		}
	};

	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

	const getSearchTerm = () => {
		if (customData.chartTitle === 'Identities') {
			return "page=1&q=identity_playbooks%3A"
		}

		if (customData.chartTitle === 'Directories') {
			return "page=1&q=directory_playbooks%3A"
		}

		if (customData.chartTitle === 'Assets') {
			return "page=1&q=asset_playbooks%3A"
		}

	}

	useLayoutEffect(() => {
		//console.log(customData.totalCount)
		setChartOptions(
			{
				...chartOptions,
				title: {
					useHTML: true,
					text: `<div class="ia_center_area margintop10"><span class="ia-tooltip-hover font18" style="color:#f28490;">${getRoundedUnit(customData.totalCount)} </span>` +
						`<br><span class="font9" style="color:#f28490;">${customData.chartTitle}<br>${customData.chartTitle === "Access" ? "Protocols" : "with issues"}</span></div>`,
					style: {
						fontFamily: "Metropolis-Regular",
						fontSize: window.matchMedia("(min-width: 2560px)").matches ? "24px" : "12px",
						color: "#000000",
					},
					verticalAlign: 'middle',
					floating: false,
					x: window.matchMedia("(min-width: 2560px)").matches ? 430 : 200,
					y: window.matchMedia("(min-width: 2560px)").matches ? 24 : 12,
				},
				tooltip: {
					shared: true,
					shadow: false,
					padding: 0,
					borderRadius: 0,
					borderWidth: 0,
					backgroundColor: 'transparent',
					useHTML: true,
					formatter: function () {
						let points: any = this?.point;

						const current_count: any = points.id;
						const total_count: any = realTotal;
						const percent: any = (current_count / total_count) * 100;
						let tooltipMarkup = "<table class='ia-tooltip-content'>";
						customData.chartTitle === "Access" ?
							tooltipMarkup += "<tr ><td style='background-color:" + points.description +
							"'>&nbsp</td><td class='ia_tooltip_percent'><b>" + Math.round(percent) + "%</b></td><td style='border-left:1px solid grey;'><b>" +
							getRoundedUnit(points.id) + '</b></td><td>' +
							findNameById(points.name) + '</td></tr>' :

							tooltipMarkup += "<tr><td style='background-color:" + points.description +
							"'>&nbsp</td><td class='ia_tooltip_percent'><div style='white-space:pre-wrap;'><b>" + Math.round(percent) + "%</b><br/>" +
							findNameById(points.name) + "</div></td><td style='border-left:1px solid grey;text-align:left;vertical-align:middle;'>" +
							points.custom?.map((item: any) => { if (item.issue_count > 0) return ("<b>" + getRoundedUnit(item.issue_count) + "</b> " + item.type + "<br/>") }).join('') + '</td><td></td></tr>';
						tooltipMarkup += "</table>"
						return tooltipMarkup;
					}
				},
				legend: {
					enabled: true,
					layout: 'vertical',
					align: 'left',
					y: getResolutionMatch(60, 40),
					className: 'scrollbar-container smry-widget-legend-container',
					// floating: true,
					verticalAlign: 'top',
					itemMarginTop: window.matchMedia("(min-width: 2560px)").matches ? 4 : 2,
					symbolRadius: 0,
					useHTML: true,
					symbolPadding: 0,
					symbolWidth: 0,
					symbolHeight: 0,
					squareSymbol: false,
					navigation: {
						enabled: false
					},
					labelFormatter: function () {
						const current_count = this.options.id;
						const total_count = realTotal;
						const percent = (current_count / total_count) * 100;

						if (percent === 0) {
							return ''; // Return null to hide the legend item when percent is zero
						}

						const name = findNameById(this.name);
						return `
							<div class="square-widget-container">
								<div class="square-widget" style="background-color:${this.color}"></div>
								<div class="square-widget-label" title="${name} (${current_count} | ${Math.round(percent)}%)">
									${name} (${current_count} | ${Math.round(percent)}%)
								</div>
							</div>
						`;
					},

					width: window.matchMedia("(min-width: 2560px)").matches ? 580 : 380,
					itemStyle: {
						fontWeight: "normal",
						width: 500,
						fontSize: window.matchMedia("(min-width: 2560px)").matches ? "24px" : "11px",
						lineHeight: window.matchMedia("(min-width: 2560px)").matches ? "20px" : "12px",
					}					
				},
				series: [{
					type: "pie",
					...(window.matchMedia("(min-width: 2560px)").matches ? { center: [chartOuterData?.length == 0 ? '87%' : '72%', '50%'] } : {}),
					/* ...(!window.matchMedia("(min-width: 2560px)").matches && chartOuterData?.length == 0 ? { center: ['88%', '50%'] } : {}), */
					...(!window.matchMedia("(min-width: 2560px)").matches ? { center: [chartOuterData?.length == 0 ? '88%' : '50%', '50%'] } : {}),
					showInLegend: true,
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 220 : 110,
					size: window.matchMedia("(min-width: 2560px)").matches ? 320 : 160,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 100 : 50,
					data: chartOuterData,
					cursor: "pointer",
					point: {
						events: {
							click: function (event) {
								const ruleType: any = this.options.name;
								const isIssue = isIssueType(this.options.name);
								if (customData.title === "ACCESS PROTOCOLS") {
									if (ruleType === "Other Protocols")
										return false
									else
										return history.push({
											search: "page=1&q=asset_protocol%3A" + ruleType
										});
								}
								else {
									// const redirect_rule = Object.keys(pbmap).find(key => pbmap[key] === ruleType)
									const redirect_rule = ruleType;
									return isIssue ? history.push({
										search: "page=1&q=rule_name%3A" + redirect_rule,
										state: { breadcrumbId: 'IdentityPosture' }
									}) : history.push({
										search: getSearchTerm() + redirect_rule,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								}
							},
							legendItemClick: function (e) {
								const isIssue = isIssueType(this.options?.name);
								const ruleType: any = this.options?.name;
								if (customData.title === "ACCESS PROTOCOLS") {
									if (ruleType === "Other Protocols")
										return false
									else
										history.push({
											search: "page=1&q=asset_protocol%3A" + ruleType
										});
								}
								else {
									// const redirect_rule = Object.keys(pbmap).find(key => pbmap[key] === ruleType)
									const redirect_rule = ruleType;
									isIssue ? history.push({
										search: "page=1&q=rule_name%3A" + redirect_rule,
										state: { breadcrumbId: 'IdentityPosture' }
									}) :
	
										history.push({
											search: getSearchTerm() + redirect_rule,
											state: { breadcrumbId: 'IdentityPosture' }
										});
								}
								return false;
							}
						}

						// Or

						// click: function(event) {
						// 	const ruleType:any = this.options.name;
						// 		if(customData.title==="IDENTITIES")
						// 		return history.push({
						// 			search: "page=1&q=rule_name%3A"+ruleType
						// 			Object.keys(pbmap).find(key => pbmap[key] === ruleType) 
						// 		});
						// 		if(customData.title==="ASSETS")
						// 		return history.push({
						// 		//	search: "page=1&q=rule_name%3A"+ruleType
						// 		Object.keys(pbmap).find(key => pbmap[key] === ruleType) 
						// 		});
						// 	if(customData.title==="ACCESSES")
						// 		return history.push({
						// 		//	search: "page=1&q=rule_name%3A"+ruleType
						// 		Object.keys(pbmap).find(key => pbmap[key] === ruleType) 
						// 		});
						// 		if(customData.title==="DIRECTORIES")
						// 		return history.push({
						// 		//	search: "page=1&q=rule_name%3A"+ruleType
						// 		Object.keys(pbmap).find(key => pbmap[key] === ruleType) 
						// 		});
						// 	}
					},
					dataLabels: {
						enabled: false,
						connectorShape: "straight",
						style: {
							fontSize: '1em',
							fontWeight: 'normal',
							textTransform: 'uppercase'
						}
					},
					states: {
						hover: {
							brightness: 0,
							halo: {
								size: window.matchMedia("(min-width: 2560px)").matches ? 10 : 5,
								opacity: 0.8
							},
						},
						inactive: {
							opacity: 1
						}
					}
				}]
			}

		)

	}, [chartOuterData, selectedData, issues])


	useEffect(() => {
		setTimeout(() => { chartRef.current?.chart.redraw(); }, 100);
	}, [chartOuterData, selectedData, issues])


	const chartRef = useRef<{
		chart: Highcharts.Chart;
		container: React.RefObject<HTMLDivElement>;
	}>(null);

	return (
		<>
			<div className="idasset_chart sumry-widget-container">
				<div className="idasset_chart_title">
					<label className="ruleroot">{customData.title}</label>
				</div>
				<div className='chart-container rule-widget-chart-container'>
					<HighchartsReact
						ref={chartRef}
						highcharts={Highcharts}
						options={chartOptions}
					/>
				</div>
			</div>
		</>
	)
}