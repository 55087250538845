export const UnknownIcon = ({ color = "" }) => {
    return (
        // <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
        //     <path
        //         style={{ fill: color }}
        //         d="m51.19,69.44v-15.48l1.85-.14c6.36-.47,10.16-3.13,10.16-5.43s-2.06-4.85-6.01-4.85c-3.15,0-5.86,1.49-8.54,4.7l-1.4,1.68-6.84-6.84,1.17-1.4c4.43-5.32,9.93-8.02,16.32-8.02,9.2,0,15.88,5.92,15.88,14.08,0,6.36-5.02,12-12.4,14.15v7.55h-10.2Z"
        //     />
        //     <path
        //         style={{ fill: color }}
        //         d="m57.91,35.67c-6.46,0-11.24,3.04-14.79,7.3l4,4c2.58-3.1,5.81-5.42,10.07-5.42,4.97,0,8.01,3.29,8.01,6.85,0,3.81-4.97,6.91-12.01,7.42v11.62h6.2v-7.1c7.23-1.55,12.4-6.78,12.4-12.59,0-6.78-5.55-12.08-13.88-12.08Z"
        //     />
        //     <path style={{ fill: color }} d="m56.29,89.58c-3.49,0-6.33-2.87-6.33-6.39s2.84-6.2,6.33-6.2,6.2,2.84,6.2,6.2-2.78,6.39-6.2,6.39Z" />
        //     <path
        //         style={{ fill: color }}
        //         d="m53.87,108.56l-.1-.05C27.88,95.12,11.16,64.51,11.16,30.53v-.35s.86-10.31.86-10.31l8.68,1.07c11.92-.08,23.39-4.39,33.26-12.46l3.49-3.51,3.49,3.51c9.92,8.1,21.4,12.39,33.26,12.46l8.68-1.07.86,10.62v.08c-.01,33.98-16.75,64.58-42.64,77.95l-.5.22-3.17,1.2-3.59-1.39ZM20.59,30.72c.08,30.12,14.52,57.15,36.87,69.07,22.35-11.93,36.81-38.97,36.87-69.07l-.03-.35c-13.08-.04-25.8-4.41-36.84-12.65-11.04,8.24-23.76,12.61-36.84,12.65l-.03.35Z"
        //     />
        //     <path
        //         style={{ fill: color }}
        //         d="m54.69,106.73C29.46,93.68,13.16,63.78,13.16,30.53v-.27s.69-8.15.69-8.15l6.74.83h.07c12.38-.06,24.35-4.53,34.61-12.94l.04-.03,2.14-2.16,2.15,2.16.04.03c10.27,8.41,22.24,12.89,34.62,12.94h.07s6.74-.83,6.74-.83l.69,8.46c-.01,33.24-16.32,63.14-41.55,76.17l-.34.15-2.4.91-2.77-1.07ZM18.59,30.64v.05c.05,31.04,15.11,58.95,38.38,71.11l.49.26.49-.26c23.26-12.15,38.32-40.07,38.37-71.11v-.04l-.2-2.4-1.68.13c-12.92,0-25.49-4.39-36.35-12.67l-.65-.49-.65.49c-10.86,8.28-23.43,12.66-36.35,12.67l-1.68-.13-.2,2.4Z"
        //     />
        // </svg>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
            <path
                style={{ fill: color }}
                d="m86.59,36.95c-10.66-.03-21.03-3.6-30.04-10.32-9.01,6.72-19.38,10.29-30.05,10.32l-.02.28c.06,24.57,11.85,46.62,30.07,56.34,18.24-9.73,30.02-31.79,30.07-56.33l-.04-.29Zm-30.99,48.3c-2.84,0-5.15-2.34-5.15-5.21s2.31-5.06,5.15-5.06,5.06,2.31,5.06,5.06-2.26,5.21-5.06,5.21Zm4.16-22.58v6.15h-8.32v-12.63l1.51-.11c5.19-.38,8.29-2.55,8.29-4.43,0-1.97-1.68-3.96-4.89-3.96-2.58,0-4.79,1.22-6.97,3.84l-1.14,1.38-5.58-5.59.96-1.14c3.61-4.33,8.1-6.54,13.31-6.54,7.52,0,12.96,4.82,12.96,11.48,0,5.19-4.09,9.79-10.11,11.55ZM57.45.19C25.83.19.19,25.83.19,57.45s25.63,57.27,57.26,57.27,57.27-25.63,57.27-57.27S89.07.19,57.45.19Zm36.87,36.93c-.01,27.72-13.67,52.67-34.78,63.57l-.4.19-2.59.98-2.94-1.13-.07-.03c-21.11-10.92-34.76-35.89-34.76-63.61v-.29s.7-8.4.7-8.4l7.08.87c9.72-.07,19.09-3.59,27.13-10.16l2.84-2.87,2.86,2.85c8.09,6.61,17.45,10.11,27.13,10.17l7.08-.87.71,8.66v.07Z"
            />
        </svg>
    );
};
