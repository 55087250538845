import { useEffect, useState } from "react";
import ModalPopup from "../../../../components/core/AMModalPopup/ModalPopup"
import { getDefaultConfigState } from "../../helpers/playbook-helper";
import * as Ref from '..';
import React from "react";
import './PlaybookConfig.scss'
import { IPlaybookConfigProps } from "./PlaybookTypes";
import { validate } from "../../helpers/Validators";
import { AMModalError } from "../../../../components/core/AMModalPopup/AMModalError";
import { PlaybookErrors } from "./PlaybookErrors";

export const PlaybookConfig = ({ isConfigSaved, ruleType, saveRule, config, closeConfigPopUp, formType }: IPlaybookConfigProps) => {
    const { loadDefaultPlaybookConfigByRuleType } = Ref.usePlaybookConfig();
    const [defConfigState, setDefConfigState] = useState<unknown>();
    const [configState, setConfigState] = useState<unknown>(config);
    const [errors, setErrors] = useState({})
    const handleReset = () => {
        setDefConfigState(getDefaultConfigState(ruleType || ''));
    }

    useEffect(() => {
        setConfigState(config);
    }, [config])

    useEffect(() => {
        /* console.log(configState) */
    }, [configState]);

    const getConfigStateFromChild = (state: any) => {
        setConfigState(state);
    }

    const getExpressionFromChild = () => { }

    const saveRuleConfig = () => {
        const err = validate(ruleType || '', configState);
        if (!err) {
            saveRule(configState);
        } else {
            setErrors({ issueConfig: { type: 'Issue Config', message: `${err}` } });
        }
    }

    return <ModalPopup
        title={'Configure ' + ruleType}
        onCloseHandler={closeConfigPopUp}
        isOpen={true}
        classNames="playbook-config-modal-root-container"
    >
        {
            loadDefaultPlaybookConfigByRuleType(ruleType || '', {
                parentState: configState, isSavedOnce: true,
                getState: getConfigStateFromChild, defaultState: defConfigState,
                getExpression: getExpressionFromChild, ruleType: ruleType,
                formType: formType == 'view' ? formType : 'create'
            }
            )
        }
        <AMModalError errorMap={PlaybookErrors} errors={errors} />
        {
            formType != 'view' &&
            <div className="popup-container-button-container dialog-button">
                <div
                    className={"float_right btn_styled btn_gray save_btn"}
                    onClick={handleReset}>
                    Reset to default
                </div>
                <div
                    className={"float_right btn_styled save_btn"}
                    onClick={saveRuleConfig}
                >
                    Save
                </div>
            </div>}
    </ModalPopup >
}