import React from 'react';

const SuspectedDisabledAccountView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    A disabled AD account will be marked as under attack when
                    number of login attempts in an hour is more than{' '}
                    <strong>
                        {config?.dir_params.user_disabled_logon_count_curhour}
                    </strong>
                </span>
            </li>
        </>
    );
};

export default React.memo(SuspectedDisabledAccountView);
