import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { useWatch } from 'react-hook-form';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Api } from '../../../components/Axios';
import { isValidIp } from '../../../utils/util-methods';
import { AVAILABLE_SIEMS, MESSAGES, SENSOR_SETTINGS_KEYS } from '../constants/sensor-labels';
import './flowSensorConfiguration.scss';
import { AlertComponent } from '../AlertComponent';
import { formatDate } from '../../../utils/util-methods';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';

export const EnrichmentSensorConfiguration = ({
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    flowSensorData
}) => {
    const [dns_location, setDnslocation] = useState([]);
    const [dnsSearchKeyErrorMessage, setDnsSearchKeyErrorMessage] =
        useState('');
    const [dns_search_domain_keys, setDnsDomainSearchKey] = useState([]);
    const [isQuery, setIsQuery] = useState(false);
    const [proxyType, setProxyType] = useState([]);
    const [dns_name, setDns_name] = useState('');
    const [noProxy, setNoProxy] = useState(false);
    const [availableProxies, setAvailableProxies] = useState([]);
    const [availableEnrichmentSensors, setAvailableEnrichmentSensors] =
        useState([]);
    const [availableSiemVendors, setAvailableSiemVendors] =
        useState(AVAILABLE_SIEMS);
    const [siem, setSiem] = useState('');
    const [alerts, setAlerts] = useState([]);

  


    const AddDnsLocation = (tag: any) => {
        for (let index = 0; index < tag.length; index++) {
            if (!isValidIp(tag[index])) {
                setError('dns_location', { type: 'invalidIp' });
                return;
            } else {
                clearErrors('dns_location');
                const ipAddressesString = tag.join(',');
                setValue('dns_location', ipAddressesString);
            }
        }
        setDnslocation(tag);
    };

    useEffect(() => {
        if (flowSensorData) {
            getProxies();
            setDnsDomainSearchKey(flowSensorData['dns_search_domain_keys']);
            setIsQuery(flowSensorData['query_gc']);
            setValue(
                'dns_location',
                flowSensorData?.dns_server_setting?.dns_location.split(',')
            );

            if (flowSensorData?.dns_server_setting?.dns_location) {
                setDnslocation(flowSensorData?.dns_server_setting?.dns_location.split(','));
            } else {
                setDnslocation([]);
            }

            console.log({ flowSensorData })
            setAlerts(getAlertMessages(flowSensorData));
        }
    }, [flowSensorData]);

    const getProxies = () => {
        Api.get('/proxies')
            .then((res: { data: any }) => {
                if (res.data && res.data.result.length > 0) {
                    setAvailableProxies(res.data.result);
                    setNoProxy(false);
                    if (flowSensorData) {
                        const match = res.data.result.find(
                            (item) =>
                                item._id == flowSensorData['proxy_id']
                        );
                        setProxyType([{ key: match._id, value: match.name }]);
                        setValue("proxy_id", [{ key: match._id, value: match.name }]);
                    }
                } else {
                    setNoProxy(true);
                }
            })
            .catch(() => { });
    };

 

    useEffect(() => {
        getProxies();
    }, []);

   
    useEffect(() => {
        register('dns_location', { required: true });
        return () => {
            register('dns_location', { required: false });
        };
    }, []);

    useEffect(() => {
        register('proxy_id', {
            required: false,
            shouldUnregister: true
        });
    }, []);

    const getAlertMessages = (data) => {
        let res = [];
        if ((moment.duration(moment().diff(moment(data.last_flow_time))).asHours() < 2)) {
            res.push(createAlertMessage(`${MESSAGES[0]} ${formatDate(data.last_flow_time)}`, "success", false))
        } else {
            
            res.push(
                createAlertMessage(
                    data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z") 
                        ? `${MESSAGES[1]} ${formatDate(data.last_flow_time)} Expand to view specific issue details.`
                        : `${MESSAGES[11]} No flows received yet. Expand to view specific issue details.`,
                    'error',
                    true
                )
            )
            if (data.flows > 0) {
                res.push(createAlertMessage(`${MESSAGES[2]}`, "success", false))
            } else {
                // res.push(createAlertMessage(`${MESSAGES[3]}`, "error", false))
            }
            res.push(
                createAlertMessage(
                    data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z") 
                    ? `${MESSAGES[4]} ${formatDate(data.last_flow_time)}`
                    : `${MESSAGES[12]} No flows received yet.`,
                    'error',
                    false
                )
            );
        }

        const additionalErrors = sensorSettingsErrors(data);
        if (additionalErrors.length > 0) { 
            additionalErrors.forEach(item => { 
                res.push(
                    createAlertMessage(
                        `${item}`,
                        'error',
                        false
                    )
                );
            })
        }

        return res;
    }

    const sensorSettingsErrors = (data) => {
        let res = [];
        if (data.sensor_settings) {
            res = Object.keys(data.sensor_settings).filter(item => SENSOR_SETTINGS_KEYS.includes(item)).map((sensor_setting: any, idx: number) =>
                `${sensor_setting} - ${data.sensor_settings[sensor_setting]}`
            )
        }
        return res;
    }

    const createAlertMessage = (text, type, isSummary) => { 
        return { type, isSummary, text }
    }

    const getItemLabel = (
        option:
            | { key: string; value: string }
            | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    return (
        <>
            
            <div className='form-body-div scrollbar-container add-sensor-body-div'>
               {alerts.length > 0 && <AlertComponent messages={alerts} />}
                <div className='sensor-flex-container'>
                    <div className='sensor-flex-container-item'>
                        <label>Name*</label>
                        <input
                            type='text'
                            placeholder='Enter Sensor name'
                            name='name'
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/,
                                shouldUnregister: true
                            })}
                            className={'flex-basis-50'}
                            autoComplete='off'
                        />
                    </div>

                    <div className='sensor-flex-container-item'>
                        <label>Site Code*</label>
                        <input
                            type='text'
                            placeholder='ENTER 4 DIGIT ALPHANUMERIC CODE'
                            name='location'
                            className='uppercase flex-basis-50'
                            maxLength={4}
                            ref={register({
                                required: true,
                                shouldUnregister: true
                            })}
                        />
                    </div>
                </div>

                <div className='sensor-flex-container'>
                    <div className='font12 sensor-flex-container-item'>
                        <label className='paddingright20'>Proxy</label>
                        {noProxy ? (
                            <>
                                <select
                                    disabled
                                    className=' form_drodown flex-basis-50'
                                >
                                    <option value={'no-proxy'}>
                                        No proxy found
                                    </option>
                                </select>
                                <div className='flex-basis-50'>
                                    <Link
                                        to='/proxy'
                                        className='link-text font12'
                                    >
                                        Add Proxy
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div
                            style={{ width: '98.5%', marginTop: '12%' }}
                            className='autocom'
                        >
                            <CheckboxAutocomplete
                                label='Select Proxy'
                                className={'flex-basis-50 form_drodown'}
                                options={availableProxies.map((item) => ({
                                    key: item._id,
                                    value: item.name
                                }))}
                                value={proxyType}
                                onChange={(e, val) => {
                                    setProxyType(val);
                                    setValue("proxy_id", val)
                                }}
                                getItemLabel={getItemLabel}
                                multiple={false}
                            />
                        </div>
                        )}
                    </div>
                </div>
                <>
					<input
						name='query_gc'
						type='checkbox'
						className={'margintop20'}
						// checked={isQuery}
						onChange={() => setIsQuery(!isQuery)}
						ref={register}
					/>
					<label
						style={{
							float: 'unset'
						}}
						className='auto_width paddingright20'
					>
						Do not query the Global Catalog or LDAP Server
					</label>
					<div className='error clrBoth font12'>
						Note: Not querying any global catalog or LDAP directory
						is not recommended.&nbsp;
						<Link className='link-text font12' to='/dirQueryParam'>
							<span className='font12'>
								Catalogs can be configured here.
							</span>
						</Link>
					</div>
				</>

                <div className={`form_sub_section`}>
                    <div className='form_sub_section_header'>
                        DNS Server Configuration
                    </div>
                    <div className='form_sub_section_content'>
                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label htmlFor='searchDomain'>
                                Base Domain* 
                                </label>
                                <input
                                    type='text'
                                    id='searchDomain'
                                    placeholder='Enter 1 or more comma separated base domains'
                                    name='dns_name'
                                    // required
                                    ref={register({
                                        required: true
                                        // pattern: /^[^'"]*$/
                                    })}
                                    className={'flex-basis-70'}
                                />
                            </div>
                        </div>
                        <>
                            <label style={{ width: '100%' }}>
                                IP Address*{' '}
                                <span className='sub-text'>
                                    (It is recommended to have Private DNS as
                                    first IP address in the list.)
                                </span>
                            </label>

                            <TagsInput
                                value={dns_location}
                                onChange={AddDnsLocation}
                                inputProps={{
                                    placeholder: 'Enter IP Addresses'
                                }}
                            />
                            <div className='warning-container'>
                                <InfoIcon
                                    style={{
                                        fontSize: '1.5em',
                                        color: '#87929E',
                                        fill: 'none',
                                        stroke: '#87929E',
                                        marginBottom: '0.2em'
                                    }}
                                />
                                <span className='sub-text'>
                                    Note: Hit 'Enter' or 'Tab' key after each
                                    DNS server IP.
                                </span>
                            </div>
                        </>
                    </div>
                </div>

              
            </div>
        </>
    );
};
