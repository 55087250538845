import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { useToasts } from "../../../components/core";
import { Api } from "../../../components/Axios";
import Stepper from "../../../components/Stepper/stepper";
import AMTabs, { TabData } from "../../../components/core/AMTab/AMTab";
import { AMModalError } from "../../../components/core/AMModalPopup/AMModalError";
import { SensorErrors } from "../constants/SensorErrors";
import { useSensor } from "./useSensor";
import { FlowSensorConfiguration } from "./FlowSensorConfiguration";
import "../Sensor.scss";
import { InstallSensor } from "./InstallSensor";
import { ADSensorConfiguration } from "./ADSensorConfiguration";
import { EnrichMentSensor } from "./enrichmentSensor";
import { EnrichmentSensorConfiguration } from "./EnrichmentSensorConfiguration";

const STEPS_LABELS = ["Configure", "Install"];

export const FlowSensorContainer = ({
  id = "",
  onCloseHandler,
  AfterSensorOperation,
  sensorType,
}) => {
  const [loading, setLoading] = useState(false);
  const [totalSteps, setTotalSteps] = useState(2);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepperLabels, setStepperLabels] = useState(STEPS_LABELS);
  const [sensorId, setSensorId] = useState(id);
  const [isEdit, setIsEdit] = useState(id !== "" ? true : false);
  const [currentTab, setCurrentTab] = useState(0);
  const [flowSensorData, setFlowSensorData] = useState(null);
  const {
    register,
    setError,
    getValues,
    setValue,
    clearErrors,
    watch,
    trigger,
    errors,
    control,
  } = useForm({ mode: "onChange" });
  const {
    clearSensorDataStore,
    fetchSensorDataFromStore,
    loadSensorDataToStore,
  } = useSensor();
  const { addToast } = useToasts();
  const values = watch();

  const onNext = async () => {
    const c = await trigger();
    if (c) {
      loadSensorDataToStore(values);
      saveFlowSensorConfiguration();
    }
  };

  const nextStepNavigation = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setTimeout(() => {
      const d = fetchSensorDataFromStore();
      Object.keys(d).forEach((i: string) => {
        setValue(i, d[i]);
      });
    });
  };

  useEffect(() => {
    console.log({ errors });
  }, [errors]);

  const saveFlowSensorConfiguration = () => {
    const data = fetchSensorDataFromStore();
    if (isEdit) {
      saveConfiguration(preparePostData(data));
    } else {
      saveConfiguration(preparePostData(data), nextStepNavigation);
    }
  };

    const preparePostData = (data) => {
    console.log({data})
    const commonFields = {
      name: data["name"],
      query_gc: data["query_gc"],
      dns_servers: data["dns_servers"],
      proxy_id: data["proxy_id"] && data["proxy_id"].length > 0 ? data["proxy_id"][0]['key'] : ""
    };
  
    const typeSpecificFields :any = {
      '3': {
        type: "3",
        location: data["location"],
        dns_search_domain_keys: data["dns_search_domain_keys"] ? data["dns_search_domain_keys"].split(",") : [],
        override_ip: data["override_ip"],
        enrichment_sensor_id: data["enrichment_sensor_id"] && data["enrichment_sensor_id"].length > 0 ? data["enrichment_sensor_id"][0]['key'] : ""
      },
      '1': {
        type: "1",
        directory_type: 'ADDS',
        location: data["location"],
        flow_sensor_id: data["flow_sensors"] && data["flow_sensors"][0]?.key
      },
      '17': {
        type: "17",
        directory_type: 'ADLDS' ,
        location: data["location"],
        flow_sensor_id: data["flow_sensors"] && data["flow_sensors"][0]?.key
      },
      '10': {
        type: "10",
        dns_name: data["dns_name"],
        location: data["location"],
        dns_location: data['dns_location'] ?  data['dns_location'] :''
      },
      '100': {
        type: "3",
        location: data["location"],
        dns_search_domain_keys: data["dns_search_domain_keys"] ? data["dns_search_domain_keys"].split(",") : [],
        override_ip: data["override_ip"],
        enrichment_sensor_id: data["enrichment_sensor_id"],
        is_am_hosted: true
      }
    };
  
    const res = typeSpecificFields[sensorType] 
      ? { ...commonFields, ...typeSpecificFields[sensorType] } 
      : {};
  
    if (sensorId !== "" && flowSensorData !== null) {
      return { ...flowSensorData, ...res };
    }
  
    return res;
  };
  

  const saveConfiguration = (data, callback) => {
    let headers = {},
      apiUrl = ``;

    if (sensorId !== "") {
      headers = { Operation: "PUT" };
      apiUrl = `/sensors/${sensorId}`;
    } else {
      apiUrl = `/sensors`;
    }
    Api.post(apiUrl, data, { headers: headers })
      .then((res) => {
        setLoading(false);
        if (res) {
          if (isEdit) {
            let msg = "";
            if (res?.data) {
              if (res?.data.api_key_regenrated) {
                msg = `Please redeploy API key for '${data.name}' as the sensor has been updated.`;
              } else {
                msg = `Please regenerate and redeploy API key for '${data.name}'  as the sensor has been updated.`;
              }
            }
            if (AfterSensorOperation) AfterSensorOperation("edit", msg);
            addToast("Sensor updated successfully.", {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            setSensorId(res.data._id);
            setFlowSensorData(res.data);
            if (callback) callback();
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else if (error.response.status === 419) {
          addToast(error.response.data, {
            appearance: "error",
            autoDismiss: true,
            autoHideDuration: 5000,
          });
        } else if (error.response.status === 404) {
          addToast(
            "We are not able to find associated email, please check and try again.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        } else if (error.response.status == 409) {
          addToast(error.response.data, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const onPrevious = () => {
    loadSensorDataToStore(values);
    setCurrentStep((prevStep) => prevStep - 1);
    setTimeout(() => {
      const d = fetchSensorDataFromStore();
      Object.keys(d).forEach((i: string) => {
        setValue(i, d[i]);
      });
    });
  };

  const getNextBtnLabel = (curStep) => {
    return curStep === 0 ? "Add" : "Ok";
  };

  const getActionBtnLabel = (curTab) => {
    return curTab == 0 ? "Save" : "Ok";
  };

  const onTabSelect = (tabId) => {
    if (tabId == 0) fetchFlowSensor();
    setCurrentTab(tabId);
  };

  const fetchFlowSensor = () => {
    Api.get("/sensors/" + id)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setFlowSensorData(res.data);
          setTimeout(() => {
            setValue("dns_servers", res.data.dns_servers);
            setValue("dns_search_domain_keys", res.data.dns_search_domain_keys);
            setValue("name", res.data.name);
            setValue("proxy_id", res.data.proxy_id || undefined);

            setValue("override_ip", res.data.override_ip);
            setValue("enrichment_sensor_id", res.data.enrichment_sensor_id);
            setValue("query_gc", res.data.query_gc);
            setValue("location", res.data.location);
            setValue(
                'dns_name',
                res?.data.dns_server_setting?.dns_name
            );
          //   setValue(
          //     'dns_location',
          //     res.data.dns_server_setting?.dns_location
          // );
          });
        }
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    if (isEdit && id !== "") {
      fetchFlowSensor();
    }
  }, [id]);

  const createFlowSensorJSX = () => {
    return (
      <form
        className="sensor-form-container"
        onSubmit={(e) => e.preventDefault()}
      >
        <Stepper
          totalSteps={totalSteps}
          currentStep={currentStep}
          stepperLabels={stepperLabels}
        />

        {currentStep === 0 && (
          <> 
            {sensorType === "3" && (
              <FlowSensorConfiguration
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                isEdit={isEdit}
         
              />
            )}
            {sensorType === "1" && (
              <ADSensorConfiguration
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                control={control}
                type={sensorType}
               
           
              />
            )}
             {sensorType === "17" && (
              <ADSensorConfiguration
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                control={control}
                type={sensorType}
               
           
              />
            )}
            {sensorType === "10" && (
              <EnrichmentSensorConfiguration
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              getValues={getValues}
            
              />
            )}
             {sensorType === "100" && (
              <FlowSensorConfiguration
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              getValues={getValues}
              isHostedFlowSensor={true}
    
              />
            )}
          </>
        )}
        {currentStep === 1 && sensorId !== "" && (
         <InstallSensor
         sensorId={sensorId}
         sensorType={
             sensorType === "3" || sensorType === "100" ? "Flow Sensor" :
             sensorType === "1" ? "AD Sensor" :
             sensorType === "10" ? "Enrichment Sensor" : sensorType === "17" ? "AD Sensor" : ''
     }
     />
        )}

        <AMModalError errors={errors} errorMap={SensorErrors} />
        <div className="sensor-footer-container">
          <div className="launch_button">
            {currentStep === 0 && (
              <button
                type="button"
                onClick={() => onCloseHandler("step0")}
                className={"float_left stepper-action-btn button_grey"}
              >
                Cancel
              </button>
            )}

            {/* {currentStep !== 0 && (
                            <button
                                type='button'
                                onClick={onPrevious}
                                className={
                                    'float_left stepper-action-btn button_white'
                                }
                            >
                                Previous
                            </button>
                        )} */}
            <button
              onClick={currentStep === 0 ? onNext : () => onCloseHandler("ok")}
              type="button"
              className={"float_right stepper-action-btn button_styled"}
            >
              {getNextBtnLabel(currentStep)}
            </button>
          </div>
        </div>
      </form>
    );
  };

  const getConfigurationComponent = () => {
    if (sensorType === 3 || sensorType === 100) {
        return (
            <FlowSensorConfiguration
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                flowSensorData={flowSensorData}
                isHostedFlowSensor={flowSensorData?.is_am_hosted}
                isEdit={isEdit}
            />
        );
    }
    if (sensorType === 1) {
        return (
            <ADSensorConfiguration
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                control={control}
                adSensorData={flowSensorData}
                type={sensorType}
            />
        );
    }
    if (sensorType === 17) {
      return (
          <ADSensorConfiguration
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              getValues={getValues}
              control={control}
              adSensorData={flowSensorData}
              type={sensorType}
          />
      );
  }
    if (sensorType === 10) {
        return (
            <EnrichmentSensorConfiguration
                register={register}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                getValues={getValues}
                flowSensorData={flowSensorData}
            />
        );
    }
    return null;
};

  const editFlowSensorJSX = () => {
    const tabs: TabData[] = [
      {
        label: "CONFIGURATION",
        Component: getConfigurationComponent(),
        selected: true,
      },
      {
        label: "Install",
        Component: (
            <InstallSensor
         sensorId={sensorId}
         sensorType={
          sensorType.toString() === "3" || sensorType.toString() === "100" ? "Flow Sensor" :
          sensorType.toString() === "1" ? "AD Sensor" :
          sensorType.toString() === "10" ? "Enrichment Sensor" : sensorType.toString() === "17" ? "AD Sensor" : ''
       } ></InstallSensor>
        ),
        selected: false,
      },
    ];

    return (
      <form
        className="sensor-form-container"
        onSubmit={(e) => e.preventDefault()}
      >
        <Box style={{ marginTop: "20px" }}>
          <AMTabs tabs={tabs} isPopupBased={true} onTabClick={onTabSelect} />
        </Box>
        <AMModalError errors={errors} errorMap={SensorErrors} />
        <div className="sensor-footer-container">
          <div className="launch_button">
          {currentTab === 0 && <button
              type="button"
              onClick={() => onCloseHandler("tab0")}
              className={"float_left stepper-action-btn button_grey"}
            >
              Cancel
            </button>} 
            <button
              onClick={currentTab == 0 ? onNext :  () => onCloseHandler("ok")}
              type="button"
              className={"float_right stepper-action-btn button_styled"}
            >
              {getActionBtnLabel(currentTab)}
            </button>
          </div>
        </div>
      </form>
    );
  };

  return <>{isEdit ? editFlowSensorJSX() : createFlowSensorJSX()}</>;
};
