import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from '../../components/core';
import { Api } from "../../components/Axios";
import { DirParamCollection, DirQueryParamFormsProps } from "./DirQueryParamTypes";
import { AMPasswordInput } from "../../components/core/AMPasswordInput/AMPasswordInput";
import './DirQueryParam.scss'
import CheckboxAutocomplete from "../../components/core/AMAutoComplete/AMAutoComplete";


interface IFormControlOptions {
    id: string,
    label: string
}
interface IFormControl {
    key: string,
    label: string,
    placeholder?: string,
    type: string,
    show?: boolean,
    options?: Array<IFormControlOptions>,
    styles?: React.CSSProperties,
    labelStyles?: React.CSSProperties,
    className?: string
}

const DirQueryFormInputs = [
    {
        key: 'name',
        label: 'Name*',
        type: 'text',
        placeholder: 'Enter name'
    },
    {
        key: 'host',
        label: 'LDAP Host*',
        type: 'text',
        placeholder: 'Enter LDAP Host(e.g. Primary Domain Controller)'
    },
    {
        key: 'port',
        label: 'LDAP Port*',
        type: 'text',
        placeholder: 'Enter LDAP Port(e.g. 389 for LDAP, 636 for LDAPS)'
    },
    {
        key: 'base_domain',
        label: 'Base Domain for LDAP Search*',
        type: 'text',
        placeholder: 'Enter Base Domain for LDAP Search'
    },
    {
        key: 'binddn',
        label: 'LDAP UserName*',
        type: 'text',
        placeholder: 'Enter LDAP User name(Bind DN, e.g. CN=johndoe,CN=Users, DC=example,DC=com)'
    },
    {
        key: 'password',
        label: 'Password*',
        type: 'password',
        placeholder: 'Enter Password'
    }, {
        key: 'type',
        label: 'Type*',
        type: 'select',
        labelStyles: {
            marginTop: 0
        },
        className: 'marginTop18 marginbottom10',
        options: [
            {
                id: 'LDAP', label: 'LDAP'
            }
        ]
    },
] as Array<IFormControl>

export const DirQueryForm = ({ dirQueryData, onFormActionSuccess }: DirQueryParamFormsProps) => {
    const [loading, setLoading] = useState<Boolean>(false);
    const { addToast } = useToasts()
    const { register, handleSubmit, setValue, control } = useForm<DirParamCollection>();
    const [errorState, setErrorState] = useState('');

    useEffect(() => {
        if (dirQueryData) {
            Object.keys(dirQueryData).forEach((i: string) => {
                if (dirQueryData[i as keyof typeof dirQueryData]) {
                    if (i === 'type') {
                        setValue("type", { key: dirQueryData[i as keyof typeof dirQueryData], value: dirQueryData[i as keyof typeof dirQueryData] })
                    } else {
                        setValue(i as keyof typeof dirQueryData, dirQueryData[i as keyof typeof dirQueryData]);
                    }
                }
            })
        }
    }, [])

    const updateDirQueryParams = (data: any) => {
        setLoading(true)
        const headers = { 'Operation': 'PUT' }
        data.type = data?.type[0]?.key || data?.type?.key || data.type;
        Api.post(`/globaldirectory/${dirQueryData?._id}`, data, { headers })
            .then(() => {
                onFormActionSuccess();
                setLoading(false)
                addToast("Dir Query Params updated successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error: any) => {
                handleError(error);
            })
    }

    const addQueryParams = (data: any) => {
        setLoading(true)
        data['type'] = data['type'] && data['type'].length > 0 ? data['type'][0]['key'] : ''
        Api.post('/globaldirectory', data)
            .then(() => {
                setLoading(false);
                onFormActionSuccess();
                addToast("Dir Query Params added successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error: any) => {
                handleError(error);
            })
    }

    const handleError = (error: any) => {
        setLoading(false)
        if (error?.response?.data) {
            addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 500) {
            addToast("Sorry, something went wrong there, try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 419) {
            addToast("We encounted validation problem, please correct and try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 404) {
            addToast("We are not able to find associated email, please check and try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status == 409) {
            addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    const onAddDirQueryParams = (data: any) => {
        if (dirQueryData?._id) {
            updateDirQueryParams(data);
        } else {
            addQueryParams(data);
        }
    }

    const getItemLabel = (
        option: { key: string; value: string } | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : "";
        } else {
            return option?.value ? option?.value : "";
        }
    };

    const loadControl = (config: IFormControl) => {
        switch (config.type) {
            case 'password':
                return <AMPasswordInput
                    type={config.type}
                    placeholder={config.placeholder}
                    name={config.key}
                    ref={register({
                        required: true,
                    })}
                // className={(errors.password ? "error" : "")}
                // defaultValue={dirqpServer?.password}
                />
            case 'select':
                return <div className="directory-type-dropdown-container" style={{ width: "94%" }}>
                    <Controller
                        name={config.key}
                        control={control}
                        defaultValue={[]}
                        ref={register({
                            required: true,
                        })}
                        render={({ onChange, value = "" }) => (
                            <CheckboxAutocomplete
                                label="Select options"
                                options={config?.options.map(item => ({ key: item.label, value: item.label }))}
                                value={value}
                                onChange={(event, newValue) => onChange(newValue)}
                                getItemLabel={getItemLabel}
                                multiple={false}
                                name={config.key}
                                formType="edit"
                                hideClearBtn={true}
                            />
                        )}
                    />
                </div>
                return <select ref={register({
                    required: true,
                })} name={config.key}>
                    {
                        config?.options && config?.options?.map((i: IFormControlOptions) => <option value={i.label}>{i.label}</option>)
                    }
                </select>
            default:
                return <input ref={register({
                    required: true,
                })} name={config.key} placeholder={config.placeholder} type={config.type} title={config.placeholder} />
        }
    }

    return <form onSubmit={handleSubmit(onAddDirQueryParams)} className="dir-query-params-container">
        {
            DirQueryFormInputs.map((i: IFormControl) => {
                return <div className="flex-basis-50">
                    <label
                        className={i.className}
                        style={{
                            height: 'fit-content',
                            ...i.labelStyles
                        }}>{i.label}</label>{
                        loadControl(i)
                    }
                </div>
            })
        }
        {errorState && <div className="error marginleft40per">{errorState}</div>}
        <div className="pane_footer_dir flex-basis-50 flex-1 margintop10 ">
            <div className="launch_button dialog-button">
                <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
            </div>
        </div>
    </form>
}