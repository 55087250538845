import moment from "moment";
import { dateToEpoch } from "../../utils/util-methods";
import { AVAILABLE_PRESETS } from "./Constants";
import { useTimeBasedSearchStorage } from "./useTimeBasedSearch";

// export const getPresetActualTimeValue = (preset) => {
//     const storage = useTimeBasedSearchStorage();
//     const data = storage.fetchDataFromStore();

//     if (data && data.selectedPresetStartDate !== "" && data.selectedPresetEndDate !== "") { 
//         return [data.selectedPresetStartDate, data.selectedPresetEndDate]
//     }
//     switch (preset) {
//         case AVAILABLE_PRESETS.LS_60_MINS.id:
//             return [dateToEpoch(moment()
//                 .subtract(60, 'minutes').toLocaleString()), dateToEpoch(moment().toLocaleString())];
//         case AVAILABLE_PRESETS.LS_4_HRS.id:
//             return [dateToEpoch(moment()
//                 .subtract(4, 'hours').toLocaleString()), dateToEpoch(moment().toLocaleString())];
//         case AVAILABLE_PRESETS.LS_24_HRS.id:
//             return [dateToEpoch(moment()
//                 .subtract(24, 'hours').toLocaleString()), dateToEpoch(moment().toLocaleString())];
//         case AVAILABLE_PRESETS.LS_3_DAYS.id:
//             return [dateToEpoch(moment()
//                 .subtract(3, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
//         case AVAILABLE_PRESETS.LS_7_DAYS.id:
//             return [dateToEpoch(moment()
//                 .subtract(7, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
//         case AVAILABLE_PRESETS.LS_14_DAYS.id:
//             return [dateToEpoch(moment()
//                 .subtract(30, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
//     }

//     return [];
// }

export const getPresetTimeValue = (preset) => {
    switch (preset) {
        case AVAILABLE_PRESETS.LS_60_MINS.id:
            return [dateToEpoch(moment()
                .subtract(60, 'minutes').toLocaleString()), dateToEpoch(moment().toLocaleString())];
        case AVAILABLE_PRESETS.LS_4_HRS.id:
            return [dateToEpoch(moment()
                .subtract(4, 'hours').toLocaleString()), dateToEpoch(moment().toLocaleString())];
        case AVAILABLE_PRESETS.LS_24_HRS.id:
            return [dateToEpoch(moment()
                .subtract(24, 'hours').toLocaleString()), dateToEpoch(moment().toLocaleString())];
        case AVAILABLE_PRESETS.LS_3_DAYS.id:
            return [dateToEpoch(moment()
                .subtract(3, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
        case AVAILABLE_PRESETS.LS_7_DAYS.id:
            return [dateToEpoch(moment()
                .subtract(7, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
        case AVAILABLE_PRESETS.LS_14_DAYS.id:
            return [dateToEpoch(moment()
                .subtract(14, 'days').toLocaleString()), dateToEpoch(moment().toLocaleString())];
    }
    return [];
}

export const isApplyBtnDisabled = (date1, date2, diff) => {
    const a = moment(date1);
    const b = moment(date2);
    if (a.isBefore(moment().subtract(61, 'days'))
        || a.isAfter(moment())
        || b.isBefore(moment().subtract(61, 'days'))
        || b.isAfter(moment())
    ) {
        return true;    
    }
    if (diff) { 
        return diff < b.diff(a, 'days') || b.diff(a, 'days') < 0;
    }
    return 14 < b.diff(a, 'days') || b.diff(a, 'days') < 0;
}

export const getPageLinks = (pageType) => {
    let res = `https://docs.authmind.com/user-guide/Working-version`;
    switch (pageType) { 
        case 'identities':
        case 'directories':
        case 'assets':
        case 'accesses':
            res += `/using-time-based-filters`;
            break;
        case 'incidents':
            res += `/using-time-based-filters-to-search-incidents`;
            break;
    }

    res += `?t=ugHs-iLNlb_WjZs4yBJDyg`
    return res;
}
