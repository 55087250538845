import React from "react";
import Popup from "reactjs-popup";

export enum SSOSaveActionType {
    ENABLE = 'enable',
    DISABLE = 'disable'
}

interface SSOPopUpProps {
    actionType: SSOSaveActionType,
    closeConfirm: any,
    okConfirm: any
}

export const SSOSavePopUp = (props: SSOPopUpProps) => {
    const closeConfirm = (evt: any) => {
        if (props.closeConfirm)
            props.closeConfirm(evt);
    }

    const okConfirm = (evt: any) => {
        if (props.okConfirm)
            props.okConfirm(evt);
    }

    return <>
        <Popup
            overlayStyle={{ zIndex: 1000, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={closeConfirm}
            modal
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header">SSO Configuration</div>
                <div className="content">
                    <div className="font16 margintop20">Are you sure you want to {props.actionType.toString()} SSO?
                        {
                            props.actionType == SSOSaveActionType.ENABLE ?
                                ` This change will affect the login behaviour of the user.` : null
                        } </div>
                </div>
                <div className="popup_footer">
                    <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button>
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={okConfirm}>Yes</button>
                </div>
            </div>
        </Popup>
    </>
}