import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from '../../../../components/core';
import Popup from 'reactjs-popup';
import { Api } from '../../../../components/Axios';
import { UserConfigState, UserData } from '../../../../types/response-types';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';

type Props = {
    openFlag: boolean | undefined,
    updateParentState: (state: UserConfigState | undefined) => void,
    parentState: UserConfigState | undefined,
    closeHandler?(): void,
    formType?: string
}

const CreateIncConfig = ({ openFlag, updateParentState, parentState, closeHandler, formType }: Props) => {

    const [isOpen, setIsOpen] = useState<boolean | undefined>(openFlag);
    let history = useHistory();

    const [state, setState] = useState<UserConfigState | undefined>(parentState);

    const [option, setOption] = useState<Array<any>>([]);

    const [error, setError] = useState<string | undefined>();

    let { addToast } = useToasts();

    useEffect(() => {
        setIsOpen(openFlag);
    }, [openFlag])

    const handleClose = () => {
        if (closeHandler) {
            closeHandler();
        }
    }

    const handleSave = () => {
        if (validateData()) {
            setIsOpen(false);
            updateParentState(state);
        }
    }

    const fetchOptions = async () => {
        Api.get("/users")
            .then(res => {
                if (res.status === 200) {
                    let options = res.data.result.map((user: UserData) => {
                        return { key: user._id, value: user.name }
                    })
                    setOption(options);
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 401) {
                    window.location.href = '/login'
                }
                else {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    useEffect(() => {
        fetchOptions();
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, config) => {
        setError(undefined);
        setState({
            name: config[0].value,
            user_id: config[0].key
        });
    }

    const validateData = () => {
        if (state === undefined || state.name?.length === 0) {
            setError("Admin");
            return false;
        }
        return true;
    }
    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key || op.key == 0) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const getItemValue = (i) => {
        const item = option.filter((ite) => ite._id == i)[0];
        if (item) {
            return item.name
        }
        return ''
    }

    return (
        <>
            <Popup
                closeOnDocumentClick={false}
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                open={isOpen}
                closeOnEscape={false}
                modal
            //lockScroll
            >
                <div className="modal">
                    <div className="close" onClick={handleClose}></div>
                    <div className="header">Create Ticket</div>
                    <div className="content">
                        <label style={{ width: '100%' }} className='display_flex_center'>
                            <span style={{ width: '40%' }}>Assign ticket to:</span>
                            <CheckboxAutocomplete
                                options={option}
                                value={{ value: state?.name, key: state?.user_id }}
                                getItemLabel={getItemLabel}
                                onChange={handleChange}
                                multiple={false}
                                hideClearBtn={true}
                                disabled={['view'].includes(formType || '')}
                            />
                            <div className="clrBoth"></div>
                        </label>
                    </div>
                    {error ? <span className="error margintop10 marginleft20">* {error} is required</span> : null}
                    <div className="shadow_footer fl"></div>
                    {
                        formType != 'view' &&
                        <div className="popup_footer fl">
                            <div className="policy_save_button" style={{ margin: "0 auto", float: "none" }} onClick={handleSave}>Save</div>
                        </div>}
                </div>
            </Popup>
        </>
    )
}

export default React.memo(CreateIncConfig);