import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { useToasts } from '../../../components/core';
import 'react-tagsinput/react-tagsinput.css';
import { AddExceptionPopUp } from '../../../common/AddExceptionPopUp/AddExceptionPopUp';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';
import { AMModalError } from '../../../components/core/AMModalPopup/AMModalError';
import { AMLabel } from '../../../components/core/AMTooltipAd/AMTooltipAd';
import Stepper from '../../../components/Stepper/stepper';
import { getPlaybookList } from '../../playbooks/helpers/playbook-helper';
import { IPatternConfigItem, IPatternFormValue } from '../../playbooks/ref/PatternForm/PatternFormGenerator';
import { ExceptionError } from '../ref/ExceptionErrors';
import { ExceptionsAdvanceSearch, IExceptionAdvancedSearchFormRef } from '../ref/ExceptionsAdvanceSearch';
import { ExceptionAdvancedSearchConfig } from '../ref/ExceptionsAdvanceSearchConfig';
import { ExceptionProps, Exceptions, ExceptionsList } from '../ref/ExceptionsRef';
import { ExceptionSummary } from '../ref/ExceptionsSummary';
import { useExceptionAdvanceSearch, useExceptionService } from '../ref/useExceptions';
import './AddException.scss';

const EXCEPTION_FIELDS = ExceptionAdvancedSearchConfig.map((field: IPatternConfigItem) => field.id);
const totalSteps = 3;

const loadOptions = () => {
    const result = [{ key: 'all', value: 'All Issues' }];
    return [...result, ...getPlaybookList()]
}

export const AddPanel = ({ AfterExceptionsOperation, formType = 'create', id, actionType }: ExceptionProps) => {
    const formRef = useRef<IExceptionAdvancedSearchFormRef>(null)
    const { register, handleSubmit, errors, setValue, watch, setError, clearErrors } = useForm<Exceptions>({
        defaultValues: {
            flow_handling: 1
        }
    });
    const values = watch();
    const [pbName, setPBName] = React.useState<any[]>(loadOptions().map((i) => i.key));
    const [formData, setFormData] = useState<any>();
    const [open, setOpen] = useState<boolean | undefined>(false)
    const [loading, setLoading] = useState<Boolean>(false)
    const [isAllRules, setIsAllRules] = useState<boolean | undefined>(true)
    const { addToast } = useToasts()
    const [showExceptionPopUp, setShowExceptionPopUp] = useState(false);
    const [tagData, setTagData] = useState<any>([]);
    const [rulesData, setRulesData] = useState<Array<IPatternFormValue[]>>([]);
    const [exceptionFormValid, setExceptionFormValid] = useState(false);
    const { addData, updateData, fetchExceptionById } = useExceptionService();
    const { fetchExceptionDataFromStore, loadExceptionDataToStore, transformValues,
        deTransformFormValues, convertToKeyValue } = useExceptionAdvanceSearch()!;
    const exceptionFormRef = useRef<HTMLSpanElement>(null);

    const handleYes = (data: any) => {
        const d = fetchExceptionDataFromStore();
        const res = [] as IPatternFormValue[];
        d?.criteria?.forEach((i: IPatternFormValue[]) => {
            res.push(deTransformFormValues(i));
        })
        const pbNameFil = pbName.filter((i) => i !== 'all')
        let request = {
            name: d.name,
            rule_names: pbNameFil,
            flow_handling: parseInt(d.flow_handling),
            all_rules_enabled: isAllRules,
            criteria: res
        };

        if (d?._id && actionType !== 'copy') {
            updateData(request, d?._id, setLoading, AfterExceptionsOperation)
        } else {
            addData(request, setLoading, AfterExceptionsOperation)
        }
        setOpen(false)
    }

    const handleNo = () => {
        setOpen(false)
    }

    const onAddExceptions = (data: any) => {
        if (pbName.length === 0) {
            return false;
        } else if (((data.src_ips === "" &&
            data.src_port === "" &&
            data.src_hostnames === "" &&
            data.src_type === "" &&
            data.dst_ips === "" &&
            data.dst_port === "" &&
            data.dst_hostnames === "" &&
            data.dst_type === "") &&
            data.sensor_locations === "" &&
            data.protocols === "" && data.s_names === '' && data.d_names === '' && data.dir_hostnames === "" && data.dir_names === "")) {
            return false;
        }
        else {
            setOpen(true);
            setFormData(data);
            return false;
        }
    }

    useEffect(() => {
        (async () => {
            if (id && exceptionFormRef?.current) {
                exceptionFormRef.current?.getElementsByClassName('add-exception-form')[0]?.classList?.add('disableItems');
                exceptionFormRef.current?.getElementsByClassName('show-loader')[0]?.classList?.add('loader');
                try {
                    let { data } = await fetchExceptionById(id);
                    // data = JSON.parse(`{"name":"Test009","rule_names":["Access from Unauthorized Countries","Access to Unauthorized Countries","Access using Anonymous IP","Access using Public VPN","Auth Hash Quality","Auth Hash Security","Auth Protocol Quality","Compromised Password","Compromised User","Deviation in Daily Asset Activity","Enumeration of AD Admins","Enumeration of AD Users","Exposed Assets","Lack of MFA","Repeated AD Login Attempts at Invalid Time","Repeated AD Login Attempts from Invalid Device","Shadow Access","Shadow Assets","Shadow Directory","Shadow External Access","Suspected AD NTLM Relay Attack","Suspected Attack on Disabled AD Account","Suspected Attack on Expired AD Account","Suspected Attack on Locked AD Account","Suspected Directory/IdP Bot Attack","Suspected Directory/IdP Identity Brute-force Attack","Suspected Directory/IdP Password Spray Attack","Suspicious Inbound Access","Suspicious Outbound Access","Unauthorized Asset Access","Unknown Access","Unknown SaaS Access","Weak Password"],"flow_handling":1,"all_rules_enabled":true,"criteria":[[{"s_ip":{"value":["12.12.12.12"],"type":"equals"}},{"s_is_cdn":{"value":true,"type":"equals"}}],[{"s_is_icloudrelday":{"value":"false","type":"equals"}}]]}`)
                    Object.keys(data).forEach((i: string) => {
                        if (!(actionType === 'copy' && i === 'name')) {
                            setValue(i, data[i]);
                        }

                    });
                    const searchFilter = [] as any;
                    data.criteria.forEach((i: any) => {
                        let transformed = transformValues(i);
                        convertToKeyValue(transformed)
                        searchFilter.push(transformed);
                    });
                    loadExceptionDataToStore({ ...data, criteria: searchFilter });
                    const isAll = data?.rule_names?.length === ExceptionsList.length;
                    setIsAllRules(isAll)
                    if (isAll) {
                        setPBName(['all', ...data.rule_names])
                    } else {
                        setPBName(data.rule_names)
                    }
                    setRulesData(searchFilter)
                } catch (e) {
                    console.log(e);
                    AfterExceptionsOperation();
                    addToast('Error occurred while fetching exception details', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } finally {
                    exceptionFormRef.current?.getElementsByClassName('add-exception-form')[0]?.classList?.remove('disableItems');
                    exceptionFormRef.current?.getElementsByClassName('show-loader')[0]?.classList?.remove('loader');
                }
            }
        })()
    }, [id])

    useEffect(() => {
        const searchException = {
            dst_port: 'd_port',
            src_port: 's_port',
            dst_hostnames: 'd_hostname',
            src_hostnames: 's_hostname',
            src_ips: 's_ip',
            sensor_locations: 'loc',
            transport_protocol: 't_protocol',
            dst_ips: 'd_ip',
            protocols: 'd_protocol',
            s_names: 's_name',
            d_names: 'd_name',
            dir_hostnames: 'dir_hostname'
        }
        const redirect_data = localStorage.getItem('addException');
        if (redirect_data) {
            const data = JSON.parse(redirect_data);
            const r: Array<IPatternFormValue[]> = [[]];
            data.forEach((i: any) => {
                const index = i.indexOf('~') > -1 ? i.indexOf('~') : i.indexOf('|') > -1 ? i.indexOf('|') : i.indexOf(':');
                let key: undefined | string = undefined;
                if (index > -1) {
                    key = i.substr(0, index);
                    let value = i.substr(index + 1);
                    /* De-duplication of fields */
                    const valArray = value.split ? value.split(',') : [];
                    if (valArray.length > 1) {
                        const s = new Set(valArray);
                        value = Array.from(s).join(',');
                    }
                    if (key && ['rule_name'].includes(key)) {
                        if (key === 'rule_name') key = 'rule_name';
                        value = value.split(',').map(i => i.trim());
                        setIsAllRules(false);
                        setPBName(value);
                        setValue(key, value);
                    } else {
                        if (key && key in searchException) {
                            key = searchException[key as keyof typeof searchException];
                        }

                        if (key && EXCEPTION_FIELDS.includes(key)) {
                            r[0].push({
                                searchKey: key,
                                value, operator: 'equals'
                            });
                            setValue(key, value);
                        }
                    }
                    const highlightField = document.getElementsByClassName(`MuiOutlinedInput-notchedOutline`)[0];
                    if (highlightField) {
                        highlightField.classList.add(`highlight-exception-field`)
                    }
                }
            });
            setRulesData(r);
        }
    }, []);

    const handleConfirmAddException = () => { }

    const [currentStep, setCurrentStep] = useState(0);

    const handleNext = () => {
        if (currentStep === 0) {
            if (values.name && pbName?.length > 0 && values?.flow_handling) {
                const res = {
                    name: values.name,
                    rule_names: pbName,
                    flow_handling: values.flow_handling,
                    all_rules_enabled: isAllRules,
                }
                loadExceptionDataToStore(res);
            } else {
                if (!values.name) {
                    setError('name', { type: 'required' });
                    return;
                } else if (pbName?.length === 0) {
                    setError('pbName', { type: 'required' });
                    return;
                } else if (!values.flow_handling) {
                    setError('flow_handling', { type: 'required' });
                    return;
                }
            }
        } else if (currentStep === 1) {
            const _patternRefCache: Array<IPatternFormValue[]> = formRef.current?.getExceptionFormIntermediateValues() || [];
            loadExceptionDataToStore({ criteria: _patternRefCache });
            setRulesData(_patternRefCache);
        }
        setCurrentStep((prevStep) => prevStep + 1)
    }

    const handleBack = () => {
        if (currentStep === 1) {
            const _patternRefCache: Array<IPatternFormValue[]> = formRef.current?.getExceptionFormIntermediateValues() || [];
            setRulesData(_patternRefCache);
            setTimeout(() => {
                let result = fetchExceptionDataFromStore();
                if (result) {
                    result = { ...result, criteria: _patternRefCache };
                    Object.keys(result).forEach((i: string) => setValue(i, result[i]));
                }
            })
        }
        setCurrentStep((prevStep) => prevStep - 1)
    }

    const setIsExceptionFormValid = (isValid: boolean) => {
        if (exceptionFormValid !== isValid) {
            setExceptionFormValid(isValid);
        }
    }

    useEffect(() => {
        register('pbName');
        register("flow_handling", { required: true });
        // setValue("flow_handling", 0)
    }, [])


    const getAccessFlowLabel = (option) => {
        if (option?.value) {
            return option.value
        } else {
            return option === '1' ? 'Do not create incident' : 'Create incident but mark as closed'
        }
    }

    const getIssueLabel = (o) => {
        if (typeof o == "string") {
            return o
        } else if (o?.key) {
            return o?.value;
        }
        return ""
    }

    const handlePBNameChange = (e, o) => {
        const withKey = o.map((i) => i.key).filter(i => i);
        const clickedKey = e?.currentTarget?.dataset?.name
        if (clickedKey && clickedKey === 'all') {
            if (withKey.lengh === 0) {
                setIsAllRules(false)
                setPBName([])
            } else {
                setPBName(o.map((i) => i.key))
                setIsAllRules(true)
            }
            return
        }

        const id = e.target.id;
        let pb = pbName.filter((i) => i !== 'all');
        const excludeCurrent = pb.filter((i) => i === id);
        if (e.code === "Backspace") {
            setPBName([]);
            setIsAllRules(false);
            return
        } else if (id === "") {
            pb = [...withKey.filter((i) => i !== 'all')];
        } else if (excludeCurrent.length === 0) {
            pb = [id, ...pb];
        } else {
            pb = pb.filter((i) => i !== id);
        }
        setPBName(pb)
        clearErrors();
        setIsAllRules(false)
    }


    const getValue = () => {
        const arr = loadOptions();
        const pbNameArray = pbName?.map((i) => {
            if (!i.key && !i.value) {
                i = {
                    key: i,
                    value: arr.filter((it) => it?.key === i)[0]?.value
                }
            }
            return i
        });
        return pbNameArray;
    }

    return (
        <span ref={exceptionFormRef}>
            <Popup overlayStyle={{ zIndex: 15001, background: 'rgba(227, 242, 253, .6)' }}
                open={open}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal

            // //lockScroll
            >
                <div className={"modal ticket_modal"}>
                    <div className={"content pad_bottom_10"}>
                        <div className="popup_title fl align_center add-exception-warning" style={{ marginBottom: 0, textAlign: "center", width: '100%', display: 'flex' }}>
                            <span>All incidents matching the exception criteria will be closed. Continue?</span>
                        </div>
                        <div className="clrBoth"></div>
                    </div>
                    <div className="popup_footer fl" style={{ borderTop: "none", paddingTop: 0 }}>
                        <div className="policy_defualt_button cancl_policy_popup" onClick={handleNo}>No</div>
                        <div className="policy_save_button cancl_policy_popup" onClick={() => handleYes(formData)}>Yes</div>
                    </div>
                </div>
            </Popup>
            <div className='show-loader' style={{ marginLeft: '45%', marginTop: '14%', position: 'absolute' }}></div>
            <form onSubmit={handleSubmit(onAddExceptions)} className='add-exception-form' >
                <Stepper totalSteps={totalSteps} currentStep={currentStep}
                    stepperLabels={['Essentials', 'Rules', 'Summary']}
                />
                <div className='add-exception-container'>
                    {currentStep === 0 && <div className="add-exception-form-container">
                        <div className="form-control-item span-2" >
                            <label>Description*</label>
                            <input
                                type="text"
                                placeholder="Enter Exceptions description"
                                name="name"
                                ref={register({
                                    required: true
                                })}
                                onChange={() => clearErrors('name')}
                                className={(errors.name ? "error" : "")}
                                id={'exception-form-name'}
                            />
                        </div>
                        <div className="form-control-item" style={{ width: '85%' }}>
                            <AMLabel title={'What to do with access flows matching this Exception ?'}
                                labelClasses='textLabel'
                                labelContainerProps={{ classes: 'marginbottom10' }}
                            >Action for Access Flows</AMLabel>
                            <CheckboxAutocomplete
                                label={"Select action for access flows"}
                                className={"form_dropdown"}
                                options={[{ key: '1', value: 'Do not create incident' },
                                { key: '2', value: "Create incident but mark as closed" }]}
                                onChange={(e, config) => {
                                    if (config[0].key) {
                                        setValue("flow_handling", config[0].key)
                                        clearErrors()
                                    }
                                }}
                                value={{ key: values?.flow_handling, value: (getAccessFlowLabel(values?.flow_handling)) }}
                                getItemLabel={getAccessFlowLabel}
                                hideClearBtn={true}
                                name={"flow_handling"}
                            />
                        </div>
                        <div className="form-control-item" style={{ justifyContent: 'start', marginLeft: '-10%' }}>
                            <AMLabel
                                labelClasses='textLabel'
                                labelContainerProps={{ classes: 'marginbottom10' }}
                                title={'Which issue types should this Exception apply to ?'} >
                                For Issue Types*
                            </AMLabel>
                            <CheckboxAutocomplete
                                multiple={true}
                                options={loadOptions()}
                                getItemLabel={getIssueLabel}
                                onChange={handlePBNameChange}
                                value={getValue()}
                            />
                        </div>
                    </div>
                    }
                    {currentStep === 1 && <div className="add-exception-form-container">
                        <div className="form-control-item span-2">
                            <ExceptionsAdvanceSearch ref={formRef} defaultState={rulesData} setIsExceptionFormValid={setIsExceptionFormValid}
                                issueTypes={pbName} isAllIssues={isAllRules}
                            />
                        </div>
                    </div>}
                    {
                        currentStep === 2 && <><ExceptionSummary />
                        </>
                    }
                </div>
                <AMModalError errors={errors} errorMap={ExceptionError} />
                <div className="add-exception-action-buttons dialog-button">
                    <button type="button" onClick={handleBack} className={"float_right " + ((currentStep === 1 ||
                        currentStep === 2)
                        ? '' : ' hide ') + "add-exception-submit button_gray"}>
                        Previous
                    </button>

                    {(currentStep === 0 || currentStep === 1) && <button onClick={handleNext} type="button" className={"float_right " + (loading ? " loader " : "add-exception-submit") +
                        ((currentStep === 1 && (!exceptionFormValid)) || false ? ' disableItems ' : '')
                    }>
                        Next
                    </button>}

                    {currentStep === 2 && <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? " loader " : "add-exception-submit")}>
                        Submit
                    </button>}
                </div>
            </form >
            {showExceptionPopUp ? <AddExceptionPopUp handleClose={() => setShowExceptionPopUp(false)} handleConfirmAddException={handleConfirmAddException} criteria={tagData} showAll={true} /> : null}
        </span >
    )
}
