import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { SplunkFieldArrayNew } from '../Splunk/Constants';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';
import { Box } from '@mui/material';

const AVAILABLE_TIMEZONE = moment.tz
  .names()
  .map(zone => ({ zone, offset: moment.tz(zone).format('Z') }))
  .sort((a, b) => {
    const offsetA = parseInt(a.offset.replace(':', ''), 10);
    const offsetB = parseInt(b.offset.replace(':', ''), 10);
    return offsetA - offsetB;
  });

export const CollectorConfigurationSumoLogic = ({
    collectorName,
    register,
    setValue,
    collectorData,
    dynamicCollectors = null
}) => {
    const [dataObj, setDataObj] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState([]);

    useEffect(() => {
        const tempDataObj = SplunkFieldArrayNew.find(
            (item) => item.name === collectorName
        );
        setDataObj(tempDataObj);
        register('source_timezone', {
            required: true,
            shouldUnregister: true
        });
        setValue('source_timezone', [
            { key: 'UTC_+00:00', value: 'UTC ( +00:00)' }
        ]);
        setSelectedTimezone([{ key: 'UTC_+00:00', value: 'UTC ( +00:00)' }]);
        // setValue('source_timezone', tempDataObj.default_timezone);
    }, []);

    const getGroupIdx = (collectorGroup) => {
        return dynamicCollectors.collectors.findIndex(
            (item) => item.id === collectorGroup
        );
    };

    useEffect(() => {
        if (collectorData) {
            if (dynamicCollectors && dynamicCollectors.collectors.length > 1) {
                Object.keys(collectorData.collector_config).forEach((item) => {
                    const idx = getGroupIdx(item);
                    if (idx == '-1') return;
                    setValue(
                        `index_${idx}`,
                        collectorData.collector_config[item]['index']
                    );
                    setValue(
                        `source_prefix_${idx}`,
                        collectorData.collector_config[item]['source_prefix']
                    );

                    const timezone = AVAILABLE_TIMEZONE.filter(
                        (innerItem) =>
                            `${innerItem.zone}_${innerItem.offset}` ===
                            collectorData.collector_config[item][
                                'source_timezone'
                            ]
                    ).map((innerItem) => ({
                        key: `${innerItem.zone}_${innerItem.offset}`,
                        value: `${innerItem.zone} ( ${innerItem.offset})`
                    }));
                    setValue(
                        `source_timezone`,
                        timezone
                    );
                    setSelectedTimezone(timezone);
                });
            } else {
                setValue('index', collectorData.collector_config?.index);
                setValue(
                    'source_prefix',
                    collectorData.collector_config?.source_prefix
                );
                const timezone = AVAILABLE_TIMEZONE.filter(
                    (item) =>
                        `${item.zone}_${item.offset}` ===
                        collectorData.collector_config?.source_timezone
                ).map((item) => ({
                    key: `${item.zone}_${item.offset}`,
                    value: `${item.zone} ( ${item.offset})`
                }));
                setValue('source_timezone', timezone);
                setSelectedTimezone(timezone);
            }
        }
    }, [collectorData]);

    const getItemLabel = (
        option:
            | { key: string; value: string }
            | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    const onTimezoneSelect = (e, val) => {
        setValue('source_timezone', val);
        setSelectedTimezone(val);
    };

    const getDynamicFields = (metaData, idx) => {
        return (
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    {metaData.label} Configuration
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Sumo Logic Index Name</label>
                            <input
                                type='text'
                                name={`index_${idx}`}
                                ref={register({
                                    required: false,
                                    shouldUnregister: true
                                })}
                                placeholder={'Sumo Logic Index Name'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Sumo Logic AWS S3 Bucket Folder</label>
                            <input
                                type='text'
                                name={`source_prefix_${idx}`}
                                ref={register({
                                    required: false,
                                    shouldUnregister: true
                                })}
                                placeholder={'Sumo Logic AWS S3 Bucket Folder'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getCommonFields = () => {
        return (
            <div className='sensor-flex-container'>
                <div className='sensor-flex-container-item'>
                    <label>Sumo Logic Source Timezone*</label>
                    <div
                        style={{ width: '69.5%', marginTop: '6%' }}
                        className='autocom'
                    >
                        <CheckboxAutocomplete
                            label='Select Timezone'
                            className={'flex-basis-50 form_drodown'}
                            options={AVAILABLE_TIMEZONE.map((item) => ({
                                key: `${item.zone}_${item.offset}`,
                                value: `${item.zone} ( ${item.offset})`
                            }))}
                            value={selectedTimezone}
                            onChange={(e, val) => {
                                onTimezoneSelect(e, val);
                            }}
                            getItemLabel={getItemLabel}
                            multiple={false}
                            disabled={
                                dataObj ? dataObj.disabledTimezone : false
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {dynamicCollectors && dynamicCollectors.collectors.length > 1 ? (
                <div className='collector-configuration-vendor-container'>
                    <div className='form_sub_section dynamic-form-sub-section'>
                        <Box className={'collector-configuration-info'}>
                            <div className='float-left'>
                                <div
                                    className='status float-left'
                                ></div>
                                <div className='float-left font-text'>
                                    This collector can query events from
                                    multiple SIEM indexes. At least one is
                                    required to setup this collector.
                                </div>
                            </div>
                        </Box>
                        {dynamicCollectors.collectors.map((item, idx) =>
                            getDynamicFields(item, idx)
                        )}
                        {getCommonFields()}
                    </div>
                </div>
            ) : (
                <div className='collector-configuration-vendor-container'>
                    <div className={`form_sub_section`}>
                        <div className='form_sub_section_content'>
                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>Sumo Logic Index Name*</label>
                                    <input
                                        type='text'
                                        name='index'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Sumo Logic Index Name'}
                                    />
                                </div>
                            </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>
                                        Sumo Logic AWS S3 Bucket Folder*
                                    </label>
                                    <input
                                        type='text'
                                        name='source_prefix'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={
                                            'Sumo Logic AWS S3 Bucket Folder'
                                        }
                                    />
                                </div>
                            </div>

                            {getCommonFields()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
