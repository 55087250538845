import React, { useEffect } from "react"
import { BaseProps } from "../../constants/types";
import './directory-config.css';
import { EnumAdUserState } from "../../../../types/playbooks-config";



type TChecked = {
    dir_bad_username_count_curhour: boolean,
    dir_bad_username_nuser_curhour: boolean
}

const EnumADUser = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<EnumAdUserState>) => {
    const [state, setState] = React.useState<EnumAdUserState>(defaultState);
    /*   const [checked, setChecked] = React.useState<TChecked>({
          dir_bad_username_count_curhour: isSavedOnce ? (parentState?.dir_params?.dir_bad_username_count_curhour ? true : false) : (state?.dir_params?.dir_bad_username_count_curhour ? true : false),
          dir_bad_username_nuser_curhour: isSavedOnce ? (parentState?.dir_params?.dir_bad_username_nuser_curhour ? true : false) : (state?.dir_params?.dir_bad_username_nuser_curhour ? true : false)
      });
   */
    const [checked, setChecked] = React.useState<TChecked>({
        dir_bad_username_count_curhour: true,
        dir_bad_username_nuser_curhour: true
    });

    useEffect(() => {
        const result = {} as any;
        const st = state?.dir_params;
        Object.keys(checked).forEach((i: string) => {
            if (i && checked[i as keyof typeof checked] && st && st[i as keyof typeof st]) {
                result[i as keyof typeof result] = st[i as keyof typeof st];
            }
        });
        if (Object.keys(result).length > 0) {
            getState({ dir_params: result });
        } else {
            getState({ dir_params: { dir_bad_username_count_curhour: undefined, dir_bad_username_nuser_curhour: undefined } });
        }
    }, [state, checked])

    useEffect(() => {
        setState(defaultState);
        setChecked({
            dir_bad_username_count_curhour: (defaultState?.dir_params?.dir_bad_username_count_curhour ? true : false),
            dir_bad_username_nuser_curhour: (defaultState?.dir_params?.dir_bad_username_nuser_curhour ? true : false)
        })
    }, [defaultState]);

    useEffect(() => {
        const user_input1 = state?.dir_params?.dir_bad_username_count_curhour;
        const user_input2 = state?.dir_params?.dir_bad_username_nuser_curhour;
        const object = {
            user_input1, user_input2
        }
        if (getExpression) {
            getExpression(object);
        }
    })


    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            setChecked({
                dir_bad_username_count_curhour: (parentState?.dir_params?.dir_bad_username_count_curhour ? true : false),
                dir_bad_username_nuser_curhour: (parentState?.dir_params?.dir_bad_username_nuser_curhour ? true : false)
            })
        }
    }, [isSavedOnce])

    const handleNumAttempts = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state.dir_params, dir_bad_username_count_curhour: parseInt(e.target.value) } });
        setChecked({ ...checked, dir_bad_username_count_curhour: e.target.value ? true : false })
    }

    const handleAdminAttempts = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state.dir_params, dir_bad_username_nuser_curhour: parseInt(e.target.value) } })
        setChecked({ ...checked, dir_bad_username_nuser_curhour: e.target.value ? true : false })
    }

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, keyType: string) => {
        setChecked({ ...checked, [keyType]: e.target.checked })
    }

    return <>
        <div className="dir-main-container">
            <div className="dir-container-row bold-text">
            <label className="playbook-config-title marginBottom0">
                    An Active Directory will be marked as under user enumeration when:</label>
            </div>
            <div className="dir-container-row">
                {/* {
                    !formType &&
                    <input type="checkbox" id="num-attempts" onChange={(e) => onCheckboxChange(e, 'dir_bad_username_count_curhour')}
                        checked={checked?.dir_bad_username_count_curhour}
                        defaultChecked={checked?.dir_bad_username_count_curhour}
                    />} */}
                <label htmlFor="num-attempts">
                    Number of attempts made in an hour is more than
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label marginbottom10">{state?.dir_params?.dir_bad_username_count_curhour}</label> :
                        <input type="number" onChange={handleNumAttempts} name="num_attempts" value={state?.dir_params?.dir_bad_username_count_curhour || ''} />}
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }} className="font14">AND</div>
            <div className="dir-container-row">
                {/*  {!formType &&
                    <input type="checkbox" id="num-admin-attempts" onChange={(e) => onCheckboxChange(e, 'dir_bad_username_nuser_curhour')}
                        checked={checked?.dir_bad_username_nuser_curhour}
                        defaultChecked={checked?.dir_bad_username_nuser_curhour} />} */}
                <label htmlFor="num-admin-attempts">
                    Number of user accounts attempted in an hour crosses
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.dir_bad_username_nuser_curhour}</label> :
                        <input type="number" onChange={handleAdminAttempts} name="num_admin_attempts" value={state?.dir_params?.dir_bad_username_nuser_curhour || ''} />
                }
            </div>
        </div>
    </>
}

export default React.memo(EnumADUser);