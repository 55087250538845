import React from 'react';

export const SuspectedBruteForceView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An account will be marked as under brute-force attack when:
                </span>
                <ul className='ml-1'>
                    {config.dir_params.user_auth_fail_count_today && (
                        <li>
                            Number of authentication failures in a{' '}
                            <strong>
                                {config.dir_params.param_durations[0].duration}
                            </strong>{' '}
                            for an account is more than{' '}
                            <strong>
                                {config.dir_params.user_auth_fail_count_today}
                            </strong>
                        </li>
                    )}
                    {config?.dir_params?.dir_bad_password_nuser_curhour &&
                        config?.dir_params?.dir_bad_password_nuser_curhour !==
                            '' && (
                            <li>
                                [AD Only] Number of AD accounts with bad
                                passwords every hour crosses{' '}
                                <strong>
                                    {
                                        config?.dir_params
                                            ?.dir_bad_password_nuser_curhour
                                    }
                                </strong>
                            </li>
                        )}
                </ul>
            </li>
        </>
    );
};
