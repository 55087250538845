import React from 'react';
import '../../sub/siemConfiguration.scss';

export const SplunkSiemConfiguration = ({ register }) => {
    return (
        <div className='siem-vendor-configuration-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Collect Splunk Logs
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Splunk Server FQDN/IP*</label>
                            <input
                                type='text'
                                placeholder='Enter Splunk Server FQDN/IP'
                                name='splunk_hostname'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Splunk Server Port*</label>
                            <input
                                type='text'
                                placeholder='Enter Splunk Server Port'
                                name='splunk_port'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Splunk API Token*</label>
                            <input
                                type='text'
                                placeholder='Enter Splunk API Token'
                                name='splunk_token'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
