import React, { useState, useEffect } from "react";
import { RuleType } from "../../constants/Constants";

export const ShadowIdentitySystemConfigView = ({ config, ruleType, params }) => {
  const categories = {
    0: "Local Identity Systems",
    560: "Cloud IAM and IGA",
    580: "Cloud Identity Providers",
    640: "Cloud Multi-factor Authentication",
    660: "Cloud Password Management",
    680: "Cloud Privileged Access Management",
    700: "Cloud Secrets Management",
    620: "Cloud Identity Services (Other)",
  };

  const [commaSeparatedValues, setCommaSeparatedValues] = useState("");

  useEffect(() => {
    const updatedValues = config.IdentitySystemCategories.map(
      (item) => categories[item]
    ).join(", ");
    setCommaSeparatedValues(updatedValues);
  }, [config]);

  const [valuesArray, setValuesArray] = useState([]);

  useEffect(() => {
    if (commaSeparatedValues) {
      const updatedArray = commaSeparatedValues.split(',');
      setValuesArray(updatedArray);
    }
  }, [commaSeparatedValues]);

  return (
    <>
      {params?.isPlaybookListing ? <>
        <li key={commaSeparatedValues.length}>
          <span>
            An Identity System will be considered a Shadow Identity System for the following Identity System Categories : &nbsp;
            {config.IdentitySystemCategories &&
              config.IdentitySystemCategories.length > 0 && (
                <>
                  <strong>{commaSeparatedValues.length > 50 ? commaSeparatedValues?.slice(0, 46) + '...' : commaSeparatedValues}</strong>
                </>
              )}
          </span></li>
      </> : <li key={commaSeparatedValues.length}>
        {ruleType === RuleType.SHADOW_DIRECTORY ? (
          <span>
            An Identity System will be considered a Shadow Identity System for the following Identity System Categories :
            {config.IdentitySystemCategories &&
              config.IdentitySystemCategories.length > 0 && (
                <>
                  <strong>{commaSeparatedValues}</strong>
                </>
              )}
          </span>
        ) : (
          <span>
            An Identity System will be considered a Shadow Identity System for the following Identity System Categories :
            {config.IdentitySystemCategories &&
              config.IdentitySystemCategories.length > 0 && (
                <>
                  <ul className='ml-1' style={{ marginTop: '0px' }}>
                    {valuesArray.length > 0 &&
                      valuesArray.slice(0, 3).map((value, index) => (
                        <li key={value}>{value}</li>
                      ))}
                  </ul>
                </>
              )}
          </span>
        )}
      </li>}
    </>
  );
};

export default ShadowIdentitySystemConfigView;
