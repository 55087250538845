import { VpnLock } from '@mui/icons-material';
import moment from 'moment';
import React, { useCallback } from 'react';

const MAX_PORT_NUM = 65535;
const MAX_64_INT = 922337203685477589;

const useValidate = () => {
	const validations = {
		isNumber: function (value) {
			return /^\d+$/.test(value);
		},
		required: function (val) {
			// this will treat 0 also as false
			let isValid = true;
			if (Array.isArray(val)) {
				if (val?.length === 0) {
					isValid = false;
				}
				val.forEach((p) => {
					if (!p) {
						isValid = false;
					}
				});
			} else {
				if (!val) {
					isValid = false;
				}
			}
			return isValid;
		},
		range: function (val) {
			return true;
		},
		dateRange: function (value) {
			const [from, to] = value;
			if (!(from || to)) {
				return false;
			} else if (from > to) {
				return false;
			}
			return true;
		},
		positiveNumbersOnly: function (values) {
			try {
				let r = /^\d+(,\d+)*$/;
				if (!r.test(values)) {
					return false;
				} else {
					const arr = values.split(',');
					let isValid = true;
					for (let i = 0; i < arr.length; i++) {
						if (parseInt(arr[i]) > MAX_64_INT) {
							isValid = false;
							break;
						}
					}
					return isValid;
				}
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		positiveNumbersLastIn: function (values) {
			console.log({ values });
			try {
				if (Array.isArray(values)) {
					let r = /^\d+(,\d+)*$/;
					return r.test(values[0]);
				}
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		positiveRange: function (values) {
			try {
				let maxNum = -1;
				if (values.length !== 2) {
					return false;
				}
				for (let i = 0; i < values.length; i++) {
					if (!this.isNumber(values[i])) {
						return false;
					}
					if (maxNum >= parseInt(values[i])) {
						return false;
					} else {
						maxNum = parseInt(values[i]);
					}
				}
				return true;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		numbersWithRange: function (values) {
			try {
				let r = /^(\d+|\d+(-\d+)?)?$/;
				if (!r.test(values)) {
					return false;
				} else {
					const arr = values.split('-');
					let isValid = true;
					const a1 = parseInt(arr[0]);
					const a2 = parseInt(arr[1]);
					if (a1 > MAX_64_INT || a2 > MAX_64_INT || a2 < a1) {
						return false;
					}
					return isValid;
				}
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		multipleNumbersWithRange: function (values) {
			try {
				let r = /^(\d+|\d+(-\d+)?)(,\d+(-\d+)?)*$/;
				return r.test(values);
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		max_65535: function (values) {
			try {
				if (Array.isArray(values)) {
					if (values.length === 2) {
						return (
							MAX_PORT_NUM >= values[0] &&
							MAX_PORT_NUM >= values[1]
						);
					}
					return false;
				} else if (values.includes(',')) {
					return values.split(',').every((item) => {
						if (item.includes('-')) {
							let tempItem = item.split('-');
							if (tempItem.length === 2) {
								return (
									MAX_PORT_NUM >= tempItem[0] &&
									MAX_PORT_NUM >= tempItem[1]
								);
							}
						} else {
							return MAX_PORT_NUM >= item;
						}
						return false;
					});
				} else {
					if (values.includes('-')) {
						let tempItem = values.split('-');
						if (tempItem.length === 2) {
							return (
								parseInt(tempItem[0]) < parseInt(tempItem[1]) &&
								MAX_PORT_NUM >= parseInt(tempItem[0]) &&
								MAX_PORT_NUM >= parseInt(tempItem[1])
							);
						}
					} else {
						return MAX_PORT_NUM >= values;
					}
				}
			} catch (error) {
				return false;
			}
		},
		numbersBetweenZeroHundred: (value) => {
			const regex = /^(100|[1-9]?[0-9])$/;
			return regex.test(value);
		},
		numbersBetweenOneToHundred: (value) => {
			const regex = /^(100|[1-9][0-9]?|[1-9])$/;
			return regex.test(value);
		},
		numbersBetweenZeroNightyNine: (value) => {
			const regex = /^(0|[1-9][0-9]?)$/;
			return regex.test(value);
		},
		maxSixtyDays: (value) => {
			if (value && value.length == 2) {
				const val = value[0];
				const operator = value[1];
				if (parseInt(val) == 0) {
					return false;
				} else if (operator == 'day(s)' && parseInt(val) > 60) {
					return false;
				} else if (operator == 'hour(s)' && parseInt(val) > 1440) {
					return false;
				}
				return true;
			}
			return false;
		},
		percentWithRange: (values) => {
			try {
				let r = /^(\d+|\d+(-\d+)?)?$/;
				if (!r.test(values)) {
					return false;
				} else {
					const arr = values.split('-');
					let isValid = true;
					const a1 = parseInt(arr[0]);
					const a2 = parseInt(arr[1]);
					if (a1 > 100 || a2 > 100 || a2 < a1 || a1 < 0 || a2 < 0) {
						return false;
					}
					return isValid;
				}
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		greaterThanZero: (values) => {
			if (parseInt(values) < 1) {
				return false;
			}
			return true;
		}
	};

	const validate = useCallback((validationList = [], value) => {
		let isValid = true;
		for (let i = 0; i < validationList.length; i++) {
			isValid = validations[validationList[i]](value);
			if (!isValid) {
				return false;
			}
		}
		return isValid;
	}, []);

	return {
		validate
	};
};

export default useValidate;
