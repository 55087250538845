export const issueTemplate = [
	{
		"id": "destination/asset",
		"label": "Asset/Destination",
		"isGroupRoot": true,
		"key": "destination/asset",
		"value": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Country",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"placeholder": "Select country",
		"search_key": "dest_countries",
		"validations": [
			"required"
		],
		"value": "Asset/Destination Country",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Hostname",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "d_hostname",
		"validations": [
			"required"
		],
		"value": "Asset/Destination Hostname",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination State",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_state",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"value": "Asset/Destination State",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination City",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "asset_city",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"value": "Asset/Destination City",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination IP",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "d_ip",
		"validations": [
			"required"
		],
		"value": "Asset/Destination IP",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination MAC",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "d_mac",
		"validations": [
			"required"
		],
		"value": "Asset/Destination MAC",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "d_name",
		"validations": [
			"required"
		],
		"value": "Asset/Destination Name",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset/Destination Type",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "d_type",
		"validations": [
			"required"
		],
		"value": "Asset/Destination Type",
		"groupBy": "Asset/Destination",
		placeholder: "Select asset/destination type"
	},
	{
		"label": "Asset/Destination Port",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "!=",
				"label": "!=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange",
					"max_65535"
				]
			}
		],
		"placeholder": "Enter comma-separated numeric values",
		"search_key": "d_port",
		"validations": [
			"required",
			"positiveNumbersOnly"
		],
		"value": "Asset/Destination Port",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Protocol",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"placeholder": "Select protocol",
		"search_key": "d_protocol",
		"validations": [
			"required"
		],
		"value": "Protocol",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset Is Public",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_public",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"value": "Asset Is Public",
		"groupBy": "Asset/Destination"
	},
	/* 
	Temp fix for ENGG-3376. Amruta will continue on checking this
	{
		"label": "Asset Is Saas",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		search_key: 'asset_is_saas',
		validations: ['required'],
		pageType: ['Assets', 'Accesses']
	}, */
	{
		"label": "Asset Is Known",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_known",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"value": "Asset Is Known",
		"groupBy": "Asset/Destination"
	},
	{
		"label": "Asset is an Identity System",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "asset_is_directory",
		"validations": [
			"required"
		],
		"pageType": [
			"Assets",
			"Accesses"
		],
		"value": "Asset is an Identity System",
		"groupBy": "Asset/Destination"
	},
	{
		"casing": "camel",
		"label": "Asset/Destination Category",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "dst_category",
		"validations": [
			"required"
		],
		"value": "Asset/Destination Category",
		"groupBy": "Asset/Destination",
		placeholder: "Select asset/destination category"
	},
	{
		"id": "source/identity",
		"label": "Identity/Source",
		"isGroupRoot": true,
		"key": "source/identity",
		"value": "Identity/Source"
	},
	{
		"label": "Identity/Source Country",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"placeholder": "Select country",
		"search_key": "src_countries",
		"validations": [
			"required"
		],
		"value": "Identity/Source Country",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Hostname",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "s_hostname",
		"validations": [
			"required"
		],
		"value": "Identity/Source Hostname",
		"groupBy": "Identity/Source"
	},
	{
		label: 'Identity/Source State',
		operators: [
			{
				id: 'equals',
				label: 'equals',
				valueType: 'text-array'
			},
			{
				id: 'not_equals',
				label: 'not equals',
				valueType: 'text-array'
			},
			{
				id: 'matches',
				label: 'matches',
				valueType: 'text-array'
			},
			{
				id: 'not_matches',
				label: 'not matches',
				valueType: 'text-array'
			}
		],
		placeholder: 'Enter comma-separated values',
		search_key: 'src_state',
		validations: ['required'],
		pageType: ['Identities', 'Accesses'],
		"groupBy": "Identity/Source"
	},
	{
		label: 'Identity/Source City',
		operators: [
			{
				id: 'equals',
				label: 'equals',
				valueType: 'text-array'
			},
			{
				id: 'not_equals',
				label: 'not equals',
				valueType: 'text-array'
			},
			{
				id: 'matches',
				label: 'matches',
				valueType: 'text-array'
			},
			{
				id: 'not_matches',
				label: 'not matches',
				valueType: 'text-array'
			}
		],
		placeholder: 'Enter comma-separated values',
		search_key: 'src_city',
		validations: ['required'],
		pageType: ['Identities', 'Accesses'],
		"groupBy": "Identity/Source"
	},
	{
		label: 'Identity/Source IP',
		operators: [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "s_ip",
		"validations": [
			"required"
		],
		"value": "Identity/Source IP",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source MAC",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "s_mac",
		"validations": [
			"required"
		],
		"value": "Identity/Source MAC",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "s_name",
		"validations": [
			"required"
		],
		"value": "Identity/Source Name",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Port",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "!=",
				"label": "!=",
				"valueType": "number-array",
				"placeholder": "Enter comma-separated numeric values",
				"validations": [
					"required",
					"multipleNumbersWithRange",
					"max_65535"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly",
					"max_65535"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange",
					"max_65535"
				]
			}
		],
		"placeholder": "Enter comma-separated numeric values",
		"search_key": "s_port",
		"validations": [
			"required",
			"positiveNumbersOnly"
		],
		"value": "Identity/Source Port",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity/Source Type",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "s_type",
		"validations": [
			"required"
		],
		"value": "Identity/Source Type",
		"groupBy": "Identity/Source",
		placeholder: "Select identity/source type"
	},
	{
		"label": "Identity Is Public",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "identity_is_public",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"value": "Identity Is Public",
		"groupBy": "Identity/Source"
	},
	{
		"label": "Identity Is Known",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "identity_is_known",
		"validations": [
			"required"
		],
		"pageType": [
			"Identities",
			"Accesses"
		],
		"value": "Identity Is Known",
		"groupBy": "Identity/Source"
	},
	{
		"casing": "camel",
		"label": "Identity/Source Category",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "src_category",
		"validations": [
			"required"
		],
		"value": "Identity/Source Category",
		"groupBy": "Identity/Source",
		placeholder: "Select identity/source category"
	},
	{
		"id": "directory",
		"label": "Directory",
		"isGroupRoot": true,
		"key": "directory",
		"value": "Directory"
	},
	{
		"label": "Directory Hostname",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "dir_hostname",
		"validations": [
			"required"
		],
		"value": "Directory Hostname",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Name",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"search_key": "dir_name",
		"validations": [
			"required"
		],
		"value": "Directory Name",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Domain",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "domain",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"value": "Directory Domain",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Type",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "select"
			}
		],
		"search_key": "dir_type",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"value": "Directory Type",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Services (Protocol)",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"search_key": "dir_services",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"value": "Directory Services (Protocol)",
		"groupBy": "Directory"
	},
	{
		"label": "Directory Server IP",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "dir_ip",
		"validations": [
			"required"
		],
		"pageType": [
			"Directory",
			"Accesses"
		],
		"value": "Directory Server IP",
		"groupBy": "Directory"
	},
	{
		"casing": "camel",
		"label": "Directory Category",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "dir_category",
		"validations": [
			"required"
		],
		"value": "Directory Category",
		"groupBy": "Directory",
		placeholder: "Select directory category"
	},
	{
		"id": "misc",
		"label": "Miscellaneous",
		"isGroupRoot": true,
		"key": "misc",
		"value": "Miscellaneous"
	},
	{
		"label": "Activity/Open Since",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "datetime"
			}
		],
		"search_key": "open_since",
		"validations": [
			"required"
		],
		"value": "Activity/Open Since",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Any Field",
		"operators": [
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"search_key": "any_field",
		"validations": [
			"required"
		],
		"value": "Any Field",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "First Activity Time",
		"operators": [
			{
				"id": "<",
				"label": "<",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "datetime-range",
				"validations": [
					"required",
					"dateRange"
				]
			},
			{
				"id": "last_in",
				"label": "in last",
				"valueType": "time-number",
				"validations": [
					"required",
					"positiveNumbersLastIn"
				],
				"placeholder": "Enter numeric value"
			}
		],
		"search_key": "first_flow_time",
		"validations": [
			"required",
			"dateRange"
		],
		"value": "First Activity Time",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Incident #",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "number-array"
			}
		],
		"placeholder": "Enter comma-separated numeric values",
		"search_key": "id",
		"validations": [
			"required",
			"positiveNumbersOnly"
		],
		"value": "Incident #",
		"groupBy": "Miscellaneous"
	},
	{
		label: 'Access Control IP',
		search_key: 'src_inter_ip',
		type: 'text',
		operators: [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "text-array"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "text-array"
			},
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Enter comma-separated values",
		"validations": [
			"required"
		],
		"value": "Intermediary IP",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Issue Highlights",
		"operators": [
			{
				"id": "matches",
				"label": "matches",
				"valueType": "text-array"
			},
			{
				"id": "not_matches",
				"label": "not matches",
				"valueType": "text-array"
			}
		],
		"placeholder": "Text value containing",
		"search_key": "issue",
		"validations": [
			"required"
		],
		"value": "Issue Highlights",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Last Activity Time",
		"operators": [
			{
				"id": "<",
				"label": "<",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "datetime",
				"validations": [
					"required"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "datetime-range",
				"validations": [
					"required",
					"dateRange"
				]
			},
			{
				"id": "last_in",
				"label": "in last",
				"valueType": "time-number",
				"validations": [
					"required",
					"positiveNumbersLastIn"
				],
				"placeholder": "Enter numeric value"
			}
		],
		"search_key": "last_flow_time",
		"validations": [
			"required",
			"dateRange"
		],
		"value": "Last Activity Time",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Matching Issue",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "array-autocomplete"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "array-autocomplete"
			}
		],
		"search_key": "rule_name",
		"validations": [
			"required"
		],
		"value": "Matching Issue",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Number of Flows",
		"operators": [
			{
				"id": "=",
				"label": "=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"numbersWithRange"
				]
			},
			{
				"id": "<",
				"label": "<",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "<=",
				"label": "<=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">",
				"label": ">",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": ">=",
				"label": ">=",
				"valueType": "number",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveNumbersOnly"
				]
			},
			{
				"id": "between",
				"label": "between",
				"valueType": "number-range",
				"placeholder": "Enter numeric value",
				"validations": [
					"required",
					"positiveRange"
				]
			}
		],
		"search_key": "num_of_flows",
		"validations": [
			"required",
			"positiveRange"
		],
		"value": "Number of Flows",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Playbook Names",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "pb_name",
		"validations": [
			"required"
		],
		"value": "Playbook Names",
		"groupBy": "Miscellaneous"
	},
	{
		"casing": "camel",
		"label": "Risk",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "risk",
		"validations": [
			"required"
		],
		"value": "Risk",
		"groupBy": "Miscellaneous"
	},
	{
		"label": "Sensor Site Code",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "loc",
		"validations": [
			"required"
		],
		"value": "Sensor Site Code",
		"groupBy": "Miscellaneous",
		placeholder: "Select sensor site code"
	},
	{
		"label": "Status",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not_equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"search_key": "status",
		"validations": [
			"required"
		],
		"value": "Status",
		"groupBy": "Miscellaneous"
	},
	{
		label: 'Time Based Search Preset',
		operators: [
			{
				id: 'equals',
				label: 'equals',
				valueType: 'select'
			}
		],
		search_key: 'any_activity_time',
		validations: ['required'],
		pageType: ['All']
	}
];

export const notSupportedKeys = [
	{
		operator: "equals", // default for textbox search
		search_key: "any_activity_time",
		valueType: "select",
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'e_time',
		valueType: 'datetime'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 's_time',
		valueType: 'datetime'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'is_external',
		valueType: 'bool'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'root_issue_id',
		valueType: 'number'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'hash',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'exception_id',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'dir_protocol',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'dir_present',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'directories',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 's_sub_type',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 't_protocol',
		valueType: 'text'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 'e_m_time',
		valueType: 'datetime'
	},
	{
		operator: 'equals', // default for textbox search
		search_key: 's_m_time',
		valueType: 'datetime'
	}
];

// export const allSearchKeys = notSupportedKeys.map(p=>p.search_key).concat(issueTemplate.map(p=>p.search_key));

export const supportedOperators = new Map([
	['equals', ':'],
	['not_equals', '^'],
	['between', '|'],
	['matches', '~'],
	['not_matches', '!~'],
	['=', '='],
	['!=', '!='],
	['<', '<'],
	['<=', '<='],
	['>', '>'],
	['>=', '>='],
	['last_in', 'last_in'],
	['contains', '~']
]);