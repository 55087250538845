import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import styles from "../grid/Playbooks.module.css";
import {
    IPatterFormRef, IPatternConfigItem, IPatternFormValue,
    IPatternPayload, PatternFormGenerator
} from "./PatternForm/PatternFormGenerator";
import { deTransformPbIdAssetCustomList, getConfigItemByName } from "../helpers/playbook-helper";
import { RuleType } from '../constants/Constants';

interface IIdPopUpProps {
    popUpTitle: string;
    handleCloseIdAssetPopUp(): any;
    state: IIdAssetState,
    handleSaveIdAssetPopUp(data: IIdAssetState): any;
    formType: string,
    popFormConfig: IIdAssetPopUpFormConfig,
    patternFormConfig: Array<IPatternConfigItem>,
    issueType?: string,
    showCustomList?: boolean
}

interface IIdAssetPopUpFormConfigField {
    id: string,
    name: string,
    value: string,
    label: string
}
export interface IIdAssetPopUpFormConfig {
    fields: Array<IIdAssetPopUpFormConfigField>,
    defaultValue: string,
    defaultChecked: string
}

export interface IIdAssetState {
    type: Array<string>,
    pattern: Record<string, IPatternPayload>
}

export const IdPopUp = ({ popUpTitle, handleCloseIdAssetPopUp, state,
    handleSaveIdAssetPopUp, formType, popFormConfig, patternFormConfig, issueType, showCustomList }: IIdPopUpProps) => {
    const [type, setType] = useState<string | undefined>();
    const [typeData, setTypeData] = useState<string>('');
    const patterRef = useRef<IPatterFormRef>(null);
    const popUpRef = useRef<HTMLDivElement>(null);
    const [customList, setCustomList] = useState<Array<IPatternFormValue>>([{ operator: "", searchKey: "", value: "" }]);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [dummyState, setDummyState] = useState();
    useEffect(() => {
        if (state?.pattern && Object.keys(state?.pattern).length > 0) {
            deTransformCustomList();
        } else {
            setType(state?.type[0])
            setTypeData(state?.type[0]);
        }
    }, [state]);

    const handleCloseSource = () => {
        handleCloseIdAssetPopUp()
    }

    const deTransformCustomList = () => {
        const result = deTransformPbIdAssetCustomList(state);
        setCustomList(result);
        setType("Custom List");
    }

    const transformCustomList = () => {
        const result = patterRef.current?.handlePatternFormSave() as IPatternFormValue[];
        const o = {} as any;
        result.forEach((i: IPatternFormValue) => {
            const config = getConfigItemByName(i.searchKey);
            let val;
            if (
                config?.valueType === 'string-array' &&
                !Array.isArray(i.value)
            ) {
                val = i.value.split(',');
            } else if (
                config?.valueType === 'number-array' &&
                !Array.isArray(i.value)
            ) {
                val = i.value.split(',').map((a: string) => parseInt(a));
            } else if (config?.valueType === 'boolean') {
                val = i.value === 'true';
            } else {
                val = i.value;
            }

            if (Array.isArray(val) && val?.length > 0 && val[0].key) {
                val = val.map((i) => (i.value))
            }

            o[i.searchKey] = {
                value: val,
                type: i.operator
            }
                ;
        });
        return o;
    }

    const handleSaveSource = () => {
        const nonCustomValues = popFormConfig.fields.map((i: IIdAssetPopUpFormConfigField) => i.value);
        if (!type || !nonCustomValues.includes(type)) {
            const pattern = transformCustomList();
            handleSaveIdAssetPopUp({
                type: [],
                pattern
            });
        } else {
            handleSaveIdAssetPopUp({
                type: [typeData], pattern: {}
            })
        }
    }

    const validateForm = (e: boolean) => {
        if (formType == 'view') return;
        const nonCustomValues = popFormConfig.fields.map((i: IIdAssetPopUpFormConfigField) => i.value);
        if (inputRef?.current && (!type || !nonCustomValues.includes(type))) {
            if (!e) {
                inputRef?.current?.classList.add('disableItems');
            } else {
                inputRef?.current?.classList.remove('disableItems');
            }
        } else {
            inputRef?.current?.classList.remove('disableItems');
        }
        // updateScroll();
    }

    const onControlRemove = (controlIndex: number) => {
        if (patterRef?.current) {
            const result = patterRef.current?.handlePatternFormSave() as IPatternFormValue[];
            result.splice(controlIndex, 1);
            setCustomList(result);
        }
    };

    const PatternForm = () => {
        return <PatternFormGenerator
            showOperatorBetween={false}
            classes={{
                groupedListClass: 'adv-pattern-form-list-container'
            }}
            config={patternFormConfig.filter((i: IPatternConfigItem) => (!i.unsupportedPb || i.unsupportedPb && issueType && !i?.unsupportedPb[issueType]))}
            ref={patterRef}
            defaultState={{ searchFilter: customList }}
            formType={formType}
            validateForm={validateForm}
            onControlRemove={(i) =>
                onControlRemove(i)
            }
            propsConfig={{ isGroupedList: true }}
            onFieldAdd={updateScroll}
        /* defaultState={{
            searchFilter: [{ value: '5455', searchKey: 'hostname', operator: 'not_equals' },
            { value: '3443', searchKey: 'ip', operator: 'equals' },
            { value: '23434', searchKey: 'name', operator: 'equals' }
            ]
        }} */
        />
    }

    const updateScroll = async () => {
        await setTimeout(() => { }, 500);
        if (popUpRef?.current) {
            const el = popUpRef.current.getElementsByClassName('pattern-form-container')[0];
            if (el) {
                const ul = el.getElementsByTagName('ul')[0];
                if (ul) {
                    ul.scrollTop = ul.scrollHeight;
                }
            }
        }
    }

    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
        lockScroll   >
        <div style={{ marginBottom: '5%' }} className={`${styles['modal']} id-asset-pop-modal`} ref={popUpRef}>
            <div className={styles.close} onClick={handleCloseSource}></div>
            <div className={styles.header + ''}>{popUpTitle}</div>
            <div className={styles.content + ' id-asset-pop-modal-content'}>
                <form action="" className={"unauth-asset-access id-popup-rules"}>
                    <div className="clrBoth"></div>
                    <span className={(['view'].includes(formType) ? ' disable-config-item' : '')}>
                        {
                            popFormConfig.fields.map((item: any) => {
                                return <>
                                    <input type="radio" id={item.id} name={item.name} value={item.value}
                                        checked={type === item.value}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setType(e.target.value);
                                                setTypeData(e.target.value);
                                                popUpRef.current?.classList.remove('lg');
                                                validateForm(true);
                                            }
                                        }}
                                    />
                                    <label htmlFor={item.id} className="marginleft20 float_none">{item.label}</label><br />
                                    <div className="clrBoth margintop10"></div>
                                </>
                            })
                        }
                    </span>
                    {showCustomList && (
                        <span className={(['view'].includes(formType) ? ' disable-config-item' : '')}>
                            <input type="radio" id="cutomize_list" name="sources" value="Custom List"
                                checked={type === 'Custom List'}
                                onChange={(e) => {
                                    setType(e.target.value)
                                    setTypeData('')
                                    if (e.target.checked == true) {
                                        popUpRef.current?.classList.add('lg');
                                    }
                                }}
                            />
                            <label htmlFor="cutomize_list" className="marginleft20 float_none">Custom List</label>
                        </span>
                    )}
                    {
                        type === 'Custom List' && <><div className="clear_both margintop10"></div>
                            <PatternForm />
                        </>
                    }
                </form>
            </div>
            {
                !['view'].includes(formType) &&
                <>
                    <div className="shadow_footer fl"></div>
                    <div className="popup_footer fl">
                        <div className={"policy_save_button"}
                            style={{ margin: "0 auto", float: "none" }} onClick={handleSaveSource}
                            ref={inputRef}
                        >Save</div>
                    </div>
                </>}
        </div>
    </Popup>
}