import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BaseProps } from "../../../constants/types";
import { DailyDeviationState, DailyDeviationConfig } from "../../../../../types/playbooks-config";
import CheckboxAutocomplete from "../../../../../components/core/AMAutoComplete/AMAutoComplete";

const OperatorMap = {
    'higher': '>',
    'lower': '<'
}

export const DeviationInDailyAsset = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<DailyDeviationState>) => {
    const [state, setState] = useState<DailyDeviationState>(defaultState);
    const { register, errors, getValues, setValue } = useForm<DailyDeviationState>();


    useEffect(() => {
        register("today_flow_count_7_day_avg.operator")
    }, [])
    useEffect(() => {
        getState(state);
    })

    useEffect(() => {
        setState(defaultState);
    }, [defaultState]);

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce]);

    const handleFormChange = () => {
        const ob = getValues();
        Object.keys(ob).forEach((i: string) => {
            const o = ob[i as keyof typeof ob] as DailyDeviationConfig;
            let per = undefined;
            if (o?.percent) {
                let tempPer = o?.percent?.toString();
                if (tempPer.split('.').length > 1 && tempPer.split('.')[1]?.length > 11) {
                    per = parseFloat(tempPer).toFixed(10);
                } else {
                    if (o?.percent < 0) {
                        per = '';
                    } else {
                        per = parseFloat(o?.percent?.toString());
                    }
                }
            }
            o.percent = per;
            o.value = o?.value ? parseFloat(o?.value?.toString()) : undefined;
        });
        setState(ob);
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const handleOnDevOperator = (e, config) => {
        setValue("today_flow_count_7_day_avg.operator", config[0].key)
        handleFormChange();
    }


    return <>
        <form className={"deviation-playbook-container " + (['view', 'edit'].includes(formType || '') ? ' disable-config-item' : '')} onChange={handleFormChange}>
            <label className="title-label playbook-config-title">Access to an asset should be marked as a deviation when:</label>
            <span><input
                id="today_flow_count_7_day_avg_is_checked"
                type="checkbox" ref={register} name="today_flow_count_7_day_avg.is_checked"
                checked={state?.today_flow_count_7_day_avg?.is_checked || false}
                onChange={() => { }}
            />
                <label htmlFor="today_flow_count_7_day_avg_is_checked">
                    The current day’s flow count for the asset goes <input type='number' min={0.1} max={99.99} maxLength={8} minLength={8}
                        name="today_flow_count_7_day_avg.percent"
                        value={state?.today_flow_count_7_day_avg?.percent}
                        ref={register}
                        onChange={() => { }}
                    /> % &nbsp;
                    <CheckboxAutocomplete
                        classes={{ root: 'deviation-select-root' }}
                        value={{ key: state?.today_flow_count_7_day_avg?.operator, value: state?.today_flow_count_7_day_avg?.operator }}
                        options={[{ key: 'higher', value: 'higher' }, { key: 'lower', value: 'lower' }]}
                        getItemLabel={getItemLabel}
                        hideClearBtn={true}
                        onChange={handleOnDevOperator}
                    />&nbsp; &nbsp;than the 7-day average</label></span>
            <label className="or-text">OR</label>
            <span>
                <input type='checkbox'
                    checked={state?.today_flow_count_gt_7_day_max?.is_checked}
                    name="today_flow_count_gt_7_day_max.is_checked"
                    id="today_flow_count_gt_7_day_max.is_checked"
                    ref={register}
                    onChange={() => { }}
                />
                <label htmlFor="today_flow_count_gt_7_day_max.is_checked">The current day’s flow count for the asset goes <input ref={register}
                    type='number' min={0.1} max={99.99} maxLength={8} minLength={8}
                    onChange={() => { }}
                    value={state?.today_flow_count_gt_7_day_max?.percent}
                    name="today_flow_count_gt_7_day_max.percent"
                /> % higher than the 7-day maximum</label></span>
            <label className="or-text">OR</label>
            <span>
                <input type="checkbox"
                    ref={register}
                    checked={state?.today_flow_count_lt_7_day_min.is_checked}
                    id="today_flow_count_lt_7_day_min.is_checked"
                    name="today_flow_count_lt_7_day_min.is_checked"
                    onChange={() => { }}
                />
                <label htmlFor="today_flow_count_lt_7_day_min.is_checked">The current day’s flow count for the asset goes <input
                    ref={register}
                    value={state?.today_flow_count_lt_7_day_min?.percent}
                    type='number' min={0.1} max={99.99} maxLength={8} minLength={8}
                    onChange={() => { }}
                    name="today_flow_count_lt_7_day_min.percent"
                /> % lower than the 7-day minimum</label></span>
            <label className="or-text">OR</label>
            <span>
                <input
                    ref={register}
                    type="checkbox"
                    checked={state?.today_flow_count_gt_yesterday?.is_checked}
                    name="today_flow_count_gt_yesterday.is_checked"
                    id="today_flow_count_gt_yesterday.is_checked"
                    onChange={() => { }}
                /><label htmlFor="today_flow_count_gt_yesterday.is_checked">The current day’s flow count for the asset goes <input type='number'
                    ref={register}
                    onChange={() => { }}
                    min={0.1} max={99.99} maxLength={8} minLength={8}
                    value={state?.today_flow_count_gt_yesterday?.percent}
                    name="today_flow_count_gt_yesterday.percent"
                /> % higher than the previous day’s count</label></span>
            {/* <select
                        ref={register}
                        value={state?.today_flow_count_gt_yesterday?.operator}
                        name="today_flow_count_gt_yesterday.operator" id="operators" defaultValue={'higher'}
                    >
                        <option value="higher">higher</option>
                        <option value="lower">lower</option>
                    </select> */}

            <label className="or-text">OR</label>
            <span>
                <input type="checkbox"
                    ref={register}
                    checked={state?.today_flow_count_gt_count?.is_checked}
                    name="today_flow_count_gt_count.is_checked"
                    id="today_flow_count_gt_count.is_checked"
                    onChange={() => { }}
                />
                <label htmlFor="today_flow_count_gt_count.is_checked">The current day’s flow count for the asset goes higher than <input type='number'
                    onChange={() => { }}
                    value={state?.today_flow_count_gt_count?.value}
                    ref={register}
                    min={0.1} max={99.99} maxLength={8} minLength={8} name="today_flow_count_gt_count.value" />
                </label></span>
        </form>
    </>
}