import React, { useEffect } from "react"
import Popup from "reactjs-popup"
import { IAMConfirmPopUpButtonType } from "../../pages/identity_asset/ref/PostureActionMenus/PostureExceptionConfirmPopUp";
import { ExceptionSearchFieldNames, ExceptionSearchFields } from "./constant/ExceptionSearchField";
import { getIssueNameById } from "../../pages/playbooks/helpers/playbook-helper";

export const AddExceptionPopUp = (props: any) => {
    const handleClose = (evt: any) => {
        props.handleClose(evt);
    }

    const handleYes = (evt: any) => {
        props.handleConfirmAddException(evt);
    }

    const renderExceptionCriteria = () => {
        const ExceptionFields = Object.keys(ExceptionSearchFieldNames);
        return props.criteria.filter((item: string) => {
            const criteriaName = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
            return ExceptionSearchFields.includes(criteriaName) || (props?.showAll && ExceptionFields.includes(criteriaName));
        }).map((item: string) => {
            const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
            let criteria = item.substring(index + 1);
            let criteriaName = item.substring(0, index);
            if (criteriaName === 'rule_name' && criteria.split(',').length > 1) {
                criteriaName = 'rule_name';
            }
            if (criteriaName === 'rule_name') {
                criteria = criteria.split(",").map((i) => getIssueNameById(i.trim())).join(",")
            }
            const name = ExceptionSearchFieldNames[criteriaName as keyof typeof ExceptionSearchFieldNames];
            return <div className=""
                title={''}
                dangerouslySetInnerHTML={{ __html: `<div><span style='font-size:1.2em;'>&#8226;</span> <b>${name}</b> : ${criteria}</div>` }}
            />
        });
    }

    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
        className='exception-popup'
    >
        <div className={"modal"}>
            <div className={"close"} onClick={handleClose}></div>
            <div className={"header"}>Add Exception</div>
            <div className={"content"}>
                <div className="font18 margintop20 marginbottom20">
                    Existing incidents will be closed and new ones will stop getting created for the criteria below.
                    Are you sure you want to add this exception?
                </div>
                <div>
                    <h4>Exception criteria:</h4> {
                        <div className="exception_table_scroll exe-issues">
                            {
                                renderExceptionCriteria()
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="shadow_footer fl" style={{ marginTop: 0 }}></div>
            <div className="popup_footer fl">
                <div className="policy_defualt_button" data-buttontype={IAMConfirmPopUpButtonType.SECONDARY.toString()} onClick={handleClose}>No</div>
                <div className="policy_save_button" data-buttontype={IAMConfirmPopUpButtonType.PRIMARY.toString()} onClick={handleYes}>Yes</div>
            </div>
        </div>
    </Popup>
}
