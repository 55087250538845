export const CATALOG_LIST = [
    { label: "Auth0", key: "Auth0", fqdns: ["auth0.com", "*.auth0.com"] },
    { label: "DashLane", key: "DashLane", fqdns: ["dashlane.com", "*.dashlane.com"] },
    { label: "Duo", key: "Duo", fqdns: ["duo.com", "*.duo.com", "*.duosecurity.com"] },
    { label: "Frontegg", key: "Frontegg", fqdns:  ["frontegg.com", "*.frontegg.com"] },
    { label: "Cyberark (Idaptive)", key: "Cyberark (Idaptive)", fqdns: ["idaptive.com", "*.idaptive.com"] },
    { label: "JumpCloud", key: "JumpCloud", fqdns: ["jumpcloud.com", "*.jumpcloud.com"] },
    { label: "LastPass", key: "LastPass", fqdns: ["lastpass.com", "*.lastpass.com"] },
    { label: "LogMeOnce", key: "LogMeOnce", fqdns: ["logmeonce.com", "*.logmeonce.com"] },
    { label: "Okta", key: "Okta", fqdns: ["*.okta.com", "*.oktapreview.com", "*.okta.mil", "*.oktacdn.com"] },
    { label: "OneLogin", key: "OneLogin", fqdns: ["onelogin.com", "*.onelogin.com"] },
    { label: "Ping Identity", key: "Ping Identity", fqdns: ["pingone.com", "*.pingone.com", "*.pingidentity.com", "*.pingfederate.com"] },
    { label: "SecureAuth", key: "SecureAuth", fqdns: ["secureauth.com", "*.secureauth.com"] },
    { label: "StickyPassword", key: "StickyPassword", fqdns: ["stickypassword.com", "*.stickypassword.com"] }
];
