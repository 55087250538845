export const UnauthorizedIcon = ({ color = "" }) => {
    return (
        // <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
        //     <path
        //         style={{ fill: color }}
        //         d="m55.41,108.56l-.1-.05C29.43,95.12,12.71,64.51,12.71,30.53v-.35s.86-10.3.86-10.3l8.68,1.07c11.92-.08,23.39-4.39,33.26-12.46l3.49-3.51,3.49,3.51c9.92,8.1,21.4,12.39,33.26,12.46l8.68-1.07.86,10.62v.08c-.01,33.98-16.75,64.58-42.64,77.95l-.5.22-3.17,1.2-3.59-1.39ZM22.14,30.72c.08,30.12,14.52,57.15,36.87,69.07,22.35-11.93,36.81-38.97,36.87-69.07l-.03-.35c-13.08-.04-25.8-4.41-36.84-12.65-11.04,8.24-23.76,12.61-36.84,12.65l-.03.35Z"
        //     />
        //     <path
        //         style={{ fill: color }}
        //         d="m56.24,106.73C31.01,93.68,14.71,63.78,14.71,30.53v-.27s.69-8.15.69-8.15l6.74.83h.07c12.38-.06,24.35-4.53,34.61-12.94l.04-.03,2.14-2.16,2.15,2.16.04.03c10.27,8.41,22.24,12.89,34.62,12.94h.07s6.74-.83,6.74-.83l.69,8.46c-.01,33.24-16.32,63.14-41.55,76.17l-.34.15-2.4.91-2.77-1.07ZM20.14,30.64v.05c.05,31.04,15.11,58.95,38.38,71.11l.49.26.49-.26c23.26-12.15,38.32-40.07,38.37-71.11v-.04l-.2-2.4-1.68.13c-12.92,0-25.49-4.39-36.35-12.67l-.65-.49-.65.49c-10.86,8.28-23.43,12.66-36.35,12.67l-1.68-.13-.2,2.4Z"
        //     />
        //     <rect style={{ fill: color }} x="50.51" y="-10.83" width="9" height="137.27" transform="translate(-24.76 55.82) rotate(-45)" />
        // </svg>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
            <path style={{ fill: color }} d="m26.85,38.78l44.32,44.32c-4.11,4.74-8.9,8.67-14.26,11.54-18.11-9.67-29.85-31.48-30.06-55.86Z" />
            <path style={{ fill: color }} d="m75.66,77.21l-40.04-40.04c7.54-1.42,14.77-4.61,21.29-9.48,9,6.72,19.38,10.29,30.04,10.32l.02.29c-.02,14.52-4.16,28.16-11.31,38.91Z" />
            <path
                style={{ fill: color }}
                d="m76.62,88.55l14.93,14.93c-9.53,7.06-21.32,11.24-34.09,11.24C25.83,114.72.19,89.09.19,57.45c0-12.77,4.18-24.56,11.24-34.08l8.24,8.24-.51,6.25v.29c-.01,27.72,13.63,52.69,34.75,63.61l.07.03,2.92,1.13,2.59-.98.41-.17c6.29-3.25,11.92-7.76,16.72-13.21Z"
            />
            <path
                style={{ fill: color }}
                d="m114.72,57.45c0,16.21-6.74,30.84-17.55,41.27l-15.99-15.99c8.53-12.21,13.48-27.88,13.49-44.56v-.06l-.71-8.67-7.08.87c-9.67-.06-19.04-3.57-27.13-10.17l-2.86-2.86-2.84,2.87c-7.55,6.18-16.27,9.65-25.34,10.1l-12.53-12.53C26.61,6.93,41.24.19,57.45.19c31.64,0,57.27,25.63,57.27,57.26Z"
            />
        </svg>
    );
};
