import React from 'react';

const EnumADUserView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An Active Directory will be marked as under user enumeration
                    when:
                </span>
                <ul className='ml-1'>
                    <li>
                        Number of attempts made in an hour is more than{' '}
                        <strong>
                            {config.dir_params.dir_bad_username_count_curhour}
                        </strong>
                    </li>

                    <li>
                        Number of user accounts attempted in an hour crosses{' '}
                        <strong>
                            {config?.dir_params?.dir_bad_username_nuser_curhour}
                        </strong>
                    </li>
                </ul>
            </li>
        </>
    );
};

export default React.memo(EnumADUserView);
