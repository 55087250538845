import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import { useHistory, Link } from "react-router-dom"
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import { Api } from '../../components/Axios'
import { AddPanel, AddSaaSPanel, EditPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core';
import { ActionMenu } from '../../theme/ActionMenu'
import purify from 'dompurify';
import { useAuthDetails } from '../../components/Authorization'
import Popup from 'reactjs-popup';
import { Titles, SOURCE_CATALOG } from './const/Constants'
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'

import './listing.scss'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    name: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

type ActionType = {
    type?: string;
    id?: string;
    username?: string;
    title?: string;
    is_saas?: boolean;
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}

const Groups = ({ query, setQuery }: any) => {
    const { register, handleSubmit } = useForm<Search>()
    const { authDetails } = useAuthDetails()!;
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [result, setResult] = useState<Result>();
    const [panelWidth, setPanelWidth] = useState(30);
    const { addToast } = useToasts();
    const [selectedApps, setSelectedApps] = useState<any>();
    const [searchState, setSearchState] = useState<any>({});
    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page || 100,
        order_by: order,
        sort_by: sort
    }

    const [nameSortDir, setNameSortDir] = useState<string | undefined>("tablesort_down");
    const [ipSortDir, setIPSortDir] = useState<string | undefined>("tablesort_down");
    const [saasDir, setSaaSDir] = useState<string | undefined>("tablesort_down");
    const [newCustomApps, setNewCustomApps] = useState([]);

    const highlightSortDir = () => {
        if (query.sort_by === "name" && query.order_by === "asc") {
            setNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "name" && query.order_by === "desc") {
            setNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "ip" && query.order_by === "asc") {
            setIPSortDir("tablesort_up_selected")
        } else if (query.sort_by === "ip" && query.order_by === "desc") {
            setIPSortDir("tablesort_down_selected")
        } else if (query.sort_by === "is_saas_app" && query.order_by === "asc") {
            setSaaSDir("tablesort_up_selected")
        } else if (query.sort_by === "is_saas_app" && query.order_by === "desc") {
            setSaaSDir("tablesort_down_selected")
        }
    }

    useEffect(() => {
        highlightSortDir();
    }, [query])

    useEffect(() => {
        console.log(query)
        Listing()
        if (query?.launchApp == "saas") {
            setActionType({ id: "", type: "saas" });
        }
    }, [query])

    const deleteConfirm = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post('/knownapplications/' + actionType?.id, "", { headers: headers })
            .then((res: { data: any }) => {
                addToast("Known Public Application deleted successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                p.page = 1
                setQuery(p)
                Listing()
                closeConfirm()
            })
            .catch((error: any) => {
            })
    }

    useEffect(() => {
        switch (actionType?.type) {
            case 'add':
                setPanelTitle(actionType.title ? actionType.title : "Add Custom Application")
                setOpenPanel(true);
                setPanelWidth(30);
                setPanelSize(30);
                return

            case 'edit':
                setPanelTitle(actionType.is_saas ? "Edit SaaS Application" : "Edit Custom Application")
                setOpenPanel(true);
                setPanelWidth(30);
                setPanelSize(30);
                return

            case 'delete':
                setOpenConfirm(true)
                setPanelWidth(30);
                setPanelSize(30);
                return
            case 'saas':
                setPanelTitle(Titles.AddKnownApplication);
                setOpenPanel(true);
                setPanelWidth(30);
                setPanelSize(30);
                return;
            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const [openConfirm, setOpenConfirm] = useState(false);
    const closeConfirm = () => setOpenConfirm(false);

    const AfterAddNewSaaSApplicationClick = (evt: any, searchSt: any) => {
        // setOpenPanel(false);
        if (evt && evt.length > 0) {
            setSelectedApps(evt);
        } else {
            setSelectedApps([])
        }
        setActionType({ id: "", type: "add", title: Titles.CatalogTitle });
        setSearchState(searchSt);
    }

    const updateSearchState = (evt: any) => {
        setSearchState(evt);
    }

    const RightPanel = (props: any) => {
        return (
            <>
                <SlidingPanel
                    type={panelType}
                    isOpen={openPanel}
                    size={panelSize}
                    panelClassName="sidepane fix_width"
                >
                    <div className="pane_container">
                        <div className="pane_heading">{panelTitle}</div>
                        <div className="pane_close" onClick={onPanelClose}></div>
                        <div className="hr_ruler"></div>
                        <div className={`panel_body panel_body_${panelWidth}`}>
                            {actionType?.type === "add" &&
                                <AddPanel AfterKnownApplicationOperation={AfterKnownApplicationOperation}
                                    panelTitle={panelTitle} onPanelClose={onPanelClose}
                                />
                            }

                            {actionType?.type === "edit" &&
                                <EditPanel id={actionType.id} AfterKnownApplicationOperation={AfterKnownApplicationOperation} />
                            }

                            {actionType?.type === "saas" &&
                                <AddSaaSPanel AfterKnownApplicationOperation={AfterKnownApplicationOperation}
                                    AfterAddNewSaaSApplicationBtn={AfterAddNewSaaSApplicationClick}
                                    AfterAddCustomApplicationClick={AfterAddCustomApplicationClick}
                                    selectedApps={selectedApps}
                                    clearSelectedApps={clearSelectedApps} onPanelClose={onPanelClose}
                                />
                            }
                        </div>
                    </div>
                </SlidingPanel>
            </>
        )
    }

    const renderPopup = () => {
        return (
            <ModalPopup
                onCloseHandler={() => onPanelClose()}
                title={panelTitle}
                isOpen={openPanel}
            >
                {actionType?.type === "add" &&
                    <AddPanel AfterKnownApplicationOperation={AfterKnownApplicationOperation}
                        panelTitle={panelTitle} onPanelClose={onPanelClose}
                    />
                }

                {actionType?.type === "edit" &&
                    <EditPanel id={actionType.id} AfterKnownApplicationOperation={AfterKnownApplicationOperation} onPanelClose={onPanelClose} />
                }

                {actionType?.type === "saas" &&
                    <AddSaaSPanel AfterKnownApplicationOperation={AfterKnownApplicationOperation}
                        AfterAddNewSaaSApplicationBtn={AfterAddNewSaaSApplicationClick}
                        AfterAddCustomApplicationClick={AfterAddCustomApplicationClick}
                        selectedApps={selectedApps}
                        clearSelectedApps={clearSelectedApps}
                        onPanelClose={onPanelClose}
                        newCustomApps={newCustomApps}
                    />
                }

            </ModalPopup>
        )
    }


    const Listing = () => {
        Api.get('/knownapplications', {
            params: p
        })
            .then((res: { data: any }) => {
                setResult(res.data)
            })
            .catch((error: any) => {
            })
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setActionType({
            id: e.currentTarget.id,
            username: e.currentTarget.dataset.name,
            type: e.currentTarget.dataset.action,
            is_saas: e.currentTarget.dataset.issaas == 'true'
        })
        e.preventDefault()
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const AfterKnownApplicationOperation = (action: string, tempNewCustomApp?: any) => {
        setOpenPanel(false)
        if (["saas", "add"].includes(action))
            setActionType({ id: "", type: "saas" });

        if (action === 'add') {
            if (actionType?.title != Titles.CatalogTitle)
                setOpenPanel(false)
            if (tempNewCustomApp) {
                setSelectedApps([...selectedApps, `${tempNewCustomApp.name} (${SOURCE_CATALOG.CUSTOM})`])
                setNewCustomApps([...newCustomApps, tempNewCustomApp]);
            }
            p.page = 1
            p.q = ""
            setQuery(p)
            Listing()
        } else {
            Listing()
        }
    }

    const AfterAddCustomApplicationClick = (items: any, searchSt: any) => {
        if (items && items.length > 0) {
            setSelectedApps(items);
        } else {
            setSelectedApps([]);
        }
        setActionType({ id: "", type: "add", title: Titles.CustomTitle });
        setSearchState(searchSt);
    }

    const LoadSorting = (field: string) => {
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if (p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    // const Sorting = (name: string, field: string) => {
    //     const fl = name.toLowerCase()
    //     return (
    //         <Link to="#" data-field={field} onClick={LoadSorting}>
    //             <div className={SortBy(field)}>{name}</div>
    //         </Link>
    //     )
    // }
    const clearSelectedApps = () => {
        setSelectedApps([]);
        localStorage.removeItem('saas_state');
    }

    const onClickAddApplication = () => {
        clearSelectedApps();
        setActionType({ id: "", type: "saas" });
    }

    const onPanelClose = () => {
        if (actionType?.type == 'add') {
            setActionType({ id: '', type: 'saas' });
            setOpenPanel(true);
        } else {
            setOpenPanel(false);
            setSearchState({});
        }
    }

    return (
        <>
            {/* <RightPanel /> */}
            {renderPopup()}
            <div className='page_title_area'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div className='font18'>Known Applications</div>                        
                            <div className='search_container issue_page scrollbar-container marginleft20 admin-page-search-container'>
                                <div
                                    className='search_white_icon flex_none'
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    &nbsp;
                                </div>
                                &nbsp;
                                <input
                                    className='search_input flex_none admin-page'
                                    defaultValue={p.q}
                                    placeholder='Name, Server Name and IP'
                                    ref={register}
                                    name='query'
                                />                           
                        </div>
                        {authDetails.permissions.Admin.knownapps !==
                            'readonly' ? (
                                <div
                                    className='launch_button admin_page_launch_btn'                                    
                                >
                                    <button
                                        type='button'
                                        className='button_main'
                                        onClick={onClickAddApplication}
                                    >
                                        Add
                                    </button>
                                    {/* <button type="button" className="button_main admin-app-btn" onClick={() => setActionType({ id: "", type: "add" })}>Add Custom Application</button> */}
                                </div>
                            ) : (
                                <div
                                    className='launch_button hide-launch-button'                                    
                                >
                                    <div
                                        className='button_main'
                                        style={{ background: 'transparent' }}
                                    ></div>
                                </div>
                            )}
                    </div>
                </form>
            </div>
            <table
                id='table'
                className='tablesorter sticky_table_top admin-page'
            >
                <thead>
                    <tr>
                        <th
                            className={nameSortDir}
                            onClick={() => {
                                LoadSorting('name');
                                setNameSortDir(SortBy('name'));
                                setIPSortDir('tablesort_down');
                                setSaaSDir('tablesort_down');
                            }}
                        >
                            <span className='align_left float_left'>Name</span>
                        </th>

                        {/*<th>{Sorting("Server Name/FQDN", "fqdn")}</th>*/}
                        <th
                            className={saasDir}
                            onClick={() => {
                                LoadSorting('is_saas_app');
                                setSaaSDir(SortBy('is_saas_app'));
                                setIPSortDir('tablesort_down');
                                setNameSortDir('tablesort_down');
                            }}
                        >
                            <span className='align_left float_left'>
                                SaaS App?
                            </span>
                        </th>
                        <th>
                            <span className='align_left float_left'>
                                IP Addresses/FQDNs/Patterns
                            </span>
                        </th>
                        {authDetails.permissions.Admin.knownapps !==
                        'readonly' ? (
                            <th>Action</th>
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    {result &&
                        result.result.length > 0 &&
                        result.result.map((u: any) => (
                            <tr key={u._id}>
                                <td style={{ width: '25%' }}>
                                    <div className='copy_field copy_host_count copy_field_known_application'>
                                        <span
                                            className='ellipsis_idassetname'
                                            title={u.name.toString()}
                                        >
                                            {u.name}
                                        </span>
                                        <div
                                            className='copy_idassetname_known'
                                            title='Copy this text to Clipboard'
                                            onClick={() =>
                                                navigator.clipboard.writeText(
                                                    u.name
                                                )
                                            }
                                        ></div>
                                    </div>
                                </td>
                                <td
                                    className='align_left'
                                    style={{ width: '10%' }}
                                >
                                    {u.is_saas_app ? 'Yes' : 'No'}
                                </td>
                                <td className='align_left'>
                                    {u.source_catalog &&
                                    u.source_catalog === 'BC' ? (
                                        'Dynamic Range defined'
                                    ) : u.fqdn ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: purify.sanitize(
                                                    u.fqdn?.join('<br />')
                                                )
                                            }}
                                        />
                                    ) : (
                                        'N.A.'
                                    )}
                                </td>
                                {authDetails.permissions.Admin.knownapps !==
                                'readonly' ? (
                                    <td style={{ width: '10%' }}>
                                        <ActionMenu>
                                            <ul>
                                                {u.source_catalog &&
                                                u.source_catalog === 'BC' ? (
                                                    <li>
                                                        <Link
                                                            to='#'
                                                            onClick={Action}
                                                            data-action='delete'
                                                            data-name={u.name}
                                                            id={u._id}
                                                        >
                                                            Delete
                                                        </Link>
                                                    </li>
                                                ) : (
                                                    <>
                                                        <li>
                                                            <Link
                                                                to='#'
                                                                onClick={Action}
                                                                data-action='edit'
                                                                data-name={
                                                                    u.name
                                                                }
                                                                id={u._id}
                                                                data-issaas={
                                                                    u.is_saas_app
                                                                }
                                                            >
                                                                Edit
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to='#'
                                                                onClick={Action}
                                                                data-action='delete'
                                                                data-name={
                                                                    u.name
                                                                }
                                                                id={u._id}
                                                            >
                                                                Delete
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}
                                            </ul>
                                        </ActionMenu>
                                    </td>
                                ) : null}
                            </tr>
                        ))}

                    {!result && (
                        <tr>
                            <td colSpan={5}>Processing</td>
                        </tr>
                    )}

                    {result && result.result.length == 0 && (
                        <tr>
                            <td colSpan={5}>
                                No Known Public Application found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Delete confirmation Popup */}
            <Popup
                open={openConfirm}
                closeOnEscape={false}
                onClose={closeConfirm}
                modal
                overlayStyle={{
                    zIndex: 15001,
                    background: 'rgba(227, 242, 253, .6)'
                }}
            >
                <div className='modal'>
                    <div className='close' onClick={closeConfirm}></div>
                    <div className='header'>
                        Delete Known Public Application
                    </div>
                    <div className='content'>
                        <div className='font16 margintop20 marginbottom20'>
                            Are you sure you want to delete known public
                            application: <strong>{actionType?.username}</strong>{' '}
                            ?
                        </div>
                    </div>
                    <div className='popup_footer'>
                        <button
                            style={{ display: 'inline' }}
                            type='button'
                            className='button_gray width25per margintop10'
                            onClick={closeConfirm}
                        >
                            No
                        </button>
                        <button
                            style={{ float: 'right' }}
                            type='button'
                            className='button_main width25per'
                            onClick={deleteConfirm}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Popup>

            {result && result.result.length > 0 && (
                <ReactPaginate
                    previousLabel={'← Previous'}
                    nextLabel={'Next →'}
                    pageCount={Math.ceil(result.total / result.rpp)}
                    onPageChange={Paginate}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                />
            )}
        </>
    );
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    launchApp: StringParam
}, Groups)