import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { AssetsObj, AssetMatchedRuleObj, MatchedRuleData, ServerResponse, IssueParams } from '../../../../types/response-types';
import { Link } from 'react-router-dom'
import * as qs from "qs";
import { Api } from '../../../../components/Axios';
import { Params } from '../../../users/listing';
import { Route, Switch } from 'react-router';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { convertToCommaValue } from '../../../../utils/util-methods';
import { DashboardRuleMap } from '../../../../constants/dashboard-rule';
type Props={
    data: AssetsObj | undefined,
    prevURL: string | undefined,
}

const RuleMatch=({query, setQuery, data, prevURL, handleRuleMatchClose}: any)=>{
    const dummyData = data.rules;
    const [newData, setNewData] = useState(Object.values(dummyData));
    //console.log(newData);
    const highlightSortPopup=()=>{
        if(query.sort1_by==="name" && query.order1_by==="asc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.name,
                  keyB = b.name;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              })
            setNewData(newData);
            setNameSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="name" && query.order1_by==="desc"){
            //console.log(newData.sort().reverse())
            newData.sort(function(a:any, b:any) {
                var keyA = a.name,
                  keyB = b.name;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              }).reverse()
            setNewData(newData)
            setNameSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="flow_count" && query.order1_by==="asc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.issue_flow_count,
                  keyB = b.issue_flow_count;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              })
            setNewData(newData);
            console.log(newData);
            setFlowCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="flow_count" && query.order1_by==="desc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.issue_flow_count,
                  keyB = b.issue_flow_count;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              }).reverse()
            setNewData(newData)
            setFlowCountSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="issue_count" && query.order1_by==="asc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.issue_count,
                  keyB = b.issue_count;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              })
            setNewData(newData);
            setIssuesCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="issue_count" && query.order1_by==="desc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.issue_count,
                  keyB = b.issue_count;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              }).reverse()
            setNewData(newData)
            setIssuesCountSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="identity_count" && query.order1_by==="asc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.identity_count,
                  keyB = b.identity_count;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              })
            setNewData(newData);
            setIdentityCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="identity_count" && query.order1_by==="desc"){
            newData.sort(function(a:any, b:any) {
                var keyA = a.identity_count,
                  keyB = b.identity_count;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              }).reverse()
            setNewData(newData)
            setIdentityCountSortPopup("tablesort_down_selected")
        }  else {
            setNameSortPopup("tablesort_down")
            setFlowCountSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
            setIdentityCountSortPopup("tablesort_down")
        }
    }

    useEffect(()=>{
        highlightSortPopup();
    },[query])


    useEffect(()=>{
        //console.log(newData);
    },[newData])

    let history = useHistory();
    const handleClose=()=>{
        //window.location.href= "/accesses";
        // history.push(prevURL);
        handleRuleMatchClose();
    }

    const {
        order_by: order,
        sort_by: sort,
        order1_by: order1,
        sort1_by: sort1,
    } = query;

    const [ruleId, setRuleId] = useState<string | undefined>();
    const [open, setOpen] = useState<boolean|undefined>();
    const [ruleMatch, setRuleMatch] = useState<AssetMatchedRuleObj[]|undefined>();
    const [responseData, setResponseData] = useState<ServerResponse>();

    const [nameSortPopup, setNameSortPopup] = useState<string | undefined>("tablesort_down");
    const [flowCountSortPopup, setFlowCountSortPopup] = useState<string | undefined>("tablesort_down");
    const [issuesCountSortPopup, setIssuesCountSortPopup] = useState<string | undefined>("tablesort_down");
    const [identityCountSortPopup, setIdentityCountSortPopup] = useState<string | undefined>("tablesort_down");

    let param: IssueParams = {
        // page: page_number,
        // rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        order1_by: order1,
        sort1_by: sort1,
    };

    const LoadSorting = (field: string) => {
        if (param.sort1_by === field) {
          param.order1_by = param.order1_by === "desc" ? "asc" : "desc";
          setQuery(param);
        } else {
          param.sort1_by = field;
          param.order1_by = "asc";
          setQuery(param);
        }
      };
    
    const SortBy = (name: string) => {
        
        if (param.sort1_by === name) {
            return param.order1_by === "desc"
            ? "tablesort_down"
            : "tablesort_up";
        }
    };

    const handleSort=(e: React.MouseEvent)=>{
        if(e.currentTarget.textContent==="Issue Matched"){
            LoadSorting("name");
            setNameSortPopup(SortBy("name"));
            setFlowCountSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
            setIdentityCountSortPopup("tablesort_down")
        }
        else if(e.currentTarget.textContent==="Flows #"){
            LoadSorting("flow_count");
            setFlowCountSortPopup(SortBy("flow_count"));
            setNameSortPopup("name")
            setIssuesCountSortPopup("tablesort_down")
            setIdentityCountSortPopup("tablesort_down")
        }
        else if(e.currentTarget.textContent==="Incidents #"){
            LoadSorting("issue_count");
            setIssuesCountSortPopup(SortBy("issue_count"));
            setNameSortPopup("issue_count")
            setFlowCountSortPopup("tablesort_down")
            setIdentityCountSortPopup("tablesort_down")
        }
        else if(e.currentTarget.textContent==="Identities #"){
            LoadSorting("identity_count");
            setIdentityCountSortPopup(SortBy("identity_count"));
            setNameSortPopup("tablesort_down")
            setFlowCountSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
        }
    }


    const handleIssueClick=(e:React.MouseEvent<HTMLDivElement>)=>{
        let ruleName = e.currentTarget.id.toString();
        const isDirectory = data?.is_flow_for_directory;
        let currHostDetails = data?.asset_host_detail;
        let assetsRef = "";
        currHostDetails.map((item:any, index:any)=>{
            if(index<100)
            assetsRef += item.ip+"-"+item.port+",";
        })
        if(isDirectory)
            history.push("/issues?disable_filter=true&order_by=desc&page=1&q=is_external:false%2Brule_name:"+ruleName+"%2Bs_name:"+data?.identity+"%2Bs_type:"+data.identity_type+"%2Bd_protocol:"+data.asset_protocol+"%2Bd_type:"+data.asset_type+"%2Bdir_hostname:"+data.asset+"%2Bstatus:Open")
        else
            history.push("/issues?disable_filter=true&order_by=desc&page=1&q=is_external:false%2Brule_name:"+ruleName+"%2Bs_name:"+data?.identity+"%2Bs_type:"+data.identity_type+"%2Bd_type:"+data.asset_type+"%2Bd_protocol:"+data.asset_protocol+"%2Bd_name:"+data.asset+"%2Bstatus:Open")
    }
   
    return(
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                modal={true}
                closeOnEscape={false}
                // //lockScroll
            >
                <div className={"modal id_asset_modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"}>
                        <span className="rule_table_header" title={(data?.identity ? `${data.identity} → ` : "") + (data?.asset ? `${data.asset}` : "")}>Issue Match Details for the Access "{data?.identity ? `${data.identity} → ` : ""}<i>{data?.asset ? `${data.asset}` : ""}</i>"</span>
                        <span className='rules_count'>({convertToCommaValue(data?.rules_count)})</span>
                    </div>
                    <div className={"content"}>
                        <table id="table" className="tablesorter">
                            <thead>
                                <tr>
                                    <th className={nameSortPopup} onClick={handleSort}><span className='align_left float_left'>Issue Matched</span></th>
                                    <th className={flowCountSortPopup} onClick={handleSort}>Flows #</th>
                                    <th className={issuesCountSortPopup} onClick={handleSort}>Incidents #</th>
                                </tr>
                            </thead>
                            <tbody>
                            { newData.map((item:any, index:any) => {
                                    return (
                                        item.issue_count===0 ? null : 
                                        <>
                                        <tr>
                                            <td className="align_left">{item.name}</td>
                                            <td>{convertToCommaValue(item.issue_flow_count)}</td>
                                            <td>
                                                {item.issue_count !== 0 ?
                                                    <div onClick={handleIssueClick} id={item.name}  className="shadowbox num_flows">
                                                        {convertToCommaValue(item.issue_count)}
                                                    </div>
                                                :
                                                    <div >
                                                        {convertToCommaValue(item.issue_count)}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table> 
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(withQueryParams({
    sort_by: StringParam,
    order_by: StringParam,
    sort1_by: StringParam,
    order1_by: StringParam,
}, RuleMatch) );