import { RuleType } from "./Constants";

const LOMAccess = [{ key: 1, value: 'For every new asset' }, { key: 0, value: 'On every new identity → Asset combination' }];
const UNSAccess = [{ key: 1, value: 'For every new SaaS app' }, { key: 0, value: 'On every new identity → SaaS app combination' }];
const SIAccess = [{ key: 1, value: 'For every new asset' }, { key: 0, value: 'On every new external IP → Asset combination' }];
const SHDWAccess = [{ key: 1, value: 'For every new asset' }, { key: 0, value: 'On every new identity → Asset combination' }];
const SOBAccess = [{ key: 2, value: 'For every new identity' }, { key: 0, value: 'On every new identity → External IP combination' }];

const AdminNotifyMap = {
    [RuleType.SUSPICIOUS_INBOUND_ACCESS]: SIAccess,
    [RuleType.LACK_OF_MFA]: LOMAccess,
    [RuleType.UNKNOWN_SAAS_ACCESS]: UNSAccess,
    [RuleType.SHADOW_ACCESS]: SHDWAccess,
    [RuleType.SUSPICIOUS_OUTBOUND_ACCESS]: SOBAccess
}

export const AdminNotify = (RuleType: RuleType): any => {
    if (AdminNotifyMap[RuleType as keyof typeof AdminNotify]) {
        return AdminNotifyMap[RuleType as keyof typeof AdminNotify];
    }
    return null;
}