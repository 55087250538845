import React, { useEffect, useState } from 'react';

export const QRadarSiemConfiguration = ({ register, verifySSL }: any) => {
    const [isSSL, setIsSSL] = useState(false);

    useEffect(() => {
        if (isSSL !== verifySSL)
            setIsSSL(verifySSL)
    }, [verifySSL])

    return (
        <div className='siem-vendor-configuration-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Collect QRadar Logs
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='qradar-row-input-size'>
                            <label
                                style={{
                                    width: '90%'
                                }}
                            >
                                QRadar API URL*
                            </label>
                            <input
                                type='text'
                                placeholder='Enter QRadar API URL'
                                name='qradar_url'
                                style={{
                                    width: '94%'
                                }}
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                            />
                        </div>
                        <div className='qradar-row-checkbox-size'>
                            <label></label>
                            <label>
                                <input
                                    type='checkbox'
                                    name='qradar_verify_ssl'
                                    onChange={() => setIsSSL(!isSSL)}
                                    ref={register({
                                        shouldUnregister: true
                                    })}
                                    checked={isSSL}
                                />
                                Verify certificate
                            </label>
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>QRadar SSL Certificate{ isSSL ? "*": ""}</label>
                            <textarea
                                wrap='off'
                                style={{
                                    margin: '10px 0px',
                                    fontFamily: 'monospace',
                                    resize: 'none'
                                }}
                                rows={8}
                                placeholder='QRadar certificate key'
                                name='qradar_certificate'
                                ref={register({
                                    required: isSSL,
                                    shouldUnregister: true
                                })}
                                disabled={!isSSL}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>QRadar API Token*</label>
                            <input
                                type='text'
                                placeholder='QRadar API Token'
                                name='qradar_token'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
