import React, { Component, ReactNode } from 'react';
import { AppError } from './AppError';
import { Container } from '@mui/material';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

interface State {
  hasError: boolean;
  error: any;
  errorInfo: any;
}

class ErrorBoundary extends Component<ErrorBoundaryProps,  State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false , error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true , error: null, errorInfo: null};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return <AppError>
          <Container maxWidth={1200} margin="auto" paddingTop={0}>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state?.error?.toString()}
              <br />
              {this.state?.errorInfo?.componentStack}
            </details>
          </Container>
      </AppError>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
