import React, { useState, useEffect } from 'react';

export const CiscoUmbrellaCollector = ({
    register,
    setValue,
    collectorData
}) => {
    const [managedBucket, setManagedBucket] = useState('self_managed_bucket');
    useEffect(() => {
        if (collectorData) {
            setManagedBucket(collectorData.collector_config?.managed_bucket);
            setTimeout(() => { 
                setValue(
                    'managed_bucket',
                    collectorData.collector_config?.managed_bucket
                );
                if (
                    collectorData.collector_config?.managed_bucket ===
                    'self_managed_bucket'
                ) {
                    setValue(
                        'sqsurl',
                        collectorData.collector_config?.sqsurl
                    );
                } else {
                    setValue(
                        'bucket_arn',
                        collectorData.collector_config?.bucket_arn
                    );
                }
                setValue(
                    'accesskey',
                    collectorData.collector_config?.accesskey
                );
                setValue(
                    'secretkey',
                    collectorData.collector_config?.secretkey
                );
            })
        }
    }, [collectorData]);

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Cisco Umbrella Configuration:
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <div className='cisco-sensor-container'>
                                <input
                                    type='radio'
                                    name='managed_bucket'
                                    defaultChecked={true}
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    value={'self_managed_bucket'}
                                    onChange={() =>
                                        setManagedBucket('self_managed_bucket')
                                    }
                                />{' '}
                                <label>
                                    Self-managed S3 bucket (via an AWS SQS
                                    Queue)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <div className='cisco-sensor-container'>
                                <input
                                    type='radio'
                                    name='managed_bucket'
                                    value={'cisco_managed_bucket'}
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    onChange={() =>
                                        setManagedBucket('cisco_managed_bucket')
                                    }
                                />{' '}
                                <label>Cisco-managed S3 bucket</label>
                            </div>
                        </div>
                    </div>
                    {managedBucket === 'self_managed_bucket' ? (
                        <>
                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>SQS URL</label>
                                    <input
                                        type='text'
                                        name='sqsurl'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter AWS SQS URL'}
                                    />
                                </div>
                            </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>SQS and S3 Access Key ID*</label>
                                    <input
                                        type='text'
                                        name='accesskey'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter Access Key ID'}
                                    />
                                </div>
                            </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>SQS and S3 Secret Access Key*</label>
                                    <input
                                        type='text'
                                        name='secretkey'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter Secret Access Key'}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>S3 Bucket Datapath</label>
                                    <input
                                        type='text'
                                        name='bucket_arn'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter Bucket Datapath'}
                                    />
                                </div>
                            </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>S3 Access Key ID*</label>
                                    <input
                                        type='text'
                                        name='accesskey'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter Access Key ID'}
                                    />
                                </div>
                            </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>S3 Secret Access Key*</label>
                                    <input
                                        type='text'
                                        name='secretkey'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'Enter Secret Access Key'}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
