import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
export const PostureTabTooltip = ({ children }: any) => {
    const PostureContext = usePostureStateContext();
    const [t, setT] = useState<string>('');

    const onTabHover = (e: React.SyntheticEvent<HTMLElement>) => {
        const div = e.target as HTMLElement;        
        if (!div?.classList.contains('tab') && PostureContext.PostureSearchState.isAdSearchApplied) {
            setT(`To enable this tab, please clear the search.`)
        } else {
            setT('');
        }
    }

    return <Tooltip
        followCursor={true}
        onMouseOver={onTabHover}
        classes={{ tooltip: 'generic-tooltip-popper scrollbar-container ' }}
        PopperProps={{
            className: 'popper-class', style: { zIndex: 10000000, maxWidth: '28%' }
        }}
        title={t}
    >{children}
    </Tooltip >
}