import React from "react";
import "./skeleton.scss";
import { 
    DonutSkeleton, 
    TopIssuesSkeleton, 
    RiskyProtocolSkeleton, 
    BlocksSkeleton, 
    IssueTrendSkeleton, 
    UnknownAccessSkeleton, 
    Past24HoursIncidentSkeleton, 
    RiskTrendIncidentSkeleton, 
    RecentAccessNewSkeleton } from "./index";

type Props = {
    type: string;
};

const Skeleton = ({ type }: Props) => {
    switch (type) {
        case "risk_trend":
            return (
                <>
                    <div className="skeleton-main">
                        <RiskTrendIncidentSkeleton />
                    </div>
                </>
            );
        case "24hrs_incidents":
            return (
                <>
                    <div className="skeleton-main">
                        <Past24HoursIncidentSkeleton />
                    </div>
                </>
            );
        case "unknown_access":
            return (
                <>
                    <div className="skeleton-main">
                        <UnknownAccessSkeleton />
                    </div>
                </>
            );
        case "recent_access":
            return (
                <>
                    <div className="skeleton-main">
                        <RecentAccessNewSkeleton />
                    </div>
                </>
            );
        case "issue_trend":
            return (
                <>
                    <div className="skeleton-main">
                        <IssueTrendSkeleton />
                    </div>
                </>
            );
        case "blocks":
            return (
                <>
                    <div className="skeleton-main">
                        <BlocksSkeleton />
                    </div>
                </>
            );
        case "top_new_risky":
            return (
                <>
                    <div className="skeleton-main">
                        <TopIssuesSkeleton />
                    </div>
                </>
            );
        case "donut_chart":
            return (
                <div className="skeleton-main">
                    <DonutSkeleton />
                </div>
            );
        case "risky_protocols":
            return (
                <div className="skeleton-main">
                    <RiskyProtocolSkeleton />
                </div>
            );
        default:
            return <></>;
    }
};

export default Skeleton;
