export const UnmanagedIcon = ({ color = "" }) => {
    return (
        // <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
        //     <polygon style={{ fill: color }} points="35.75 71.69 51.63 55.8 35.75 39.92 42.23 33.44 58.11 49.33 73.99 33.44 80.47 39.92 64.59 55.8 80.47 71.69 73.99 78.16 58.11 62.28 42.23 78.16 35.75 71.69" />
        //     <path
        //         style={{ fill: color }}
        //         d="m53.87,108.56l-.1-.05C27.88,95.12,11.16,64.51,11.16,30.53v-.35s.86-10.3.86-10.3l8.68,1.07c11.92-.08,23.39-4.39,33.26-12.46l3.49-3.51,3.49,3.51c9.92,8.1,21.4,12.39,33.26,12.46l8.68-1.07.86,10.62v.08c-.01,33.98-16.75,64.58-42.64,77.95l-.5.22-3.17,1.2-3.59-1.39ZM20.59,30.72c.08,30.12,14.52,57.15,36.87,69.07,22.35-11.93,36.81-38.97,36.87-69.07l-.03-.35c-13.08-.04-25.8-4.41-36.84-12.65-11.04,8.24-23.76,12.61-36.84,12.65l-.03.35Z"
        //     />
        //     <path
        //         style={{ fill: color }}
        //         d="m54.69,106.73C29.46,93.68,13.16,63.78,13.16,30.53v-.27s.69-8.15.69-8.15l6.74.83h.07c12.38-.06,24.35-4.53,34.61-12.94l.04-.03,2.14-2.16,2.15,2.16.04.03c10.27,8.41,22.24,12.89,34.62,12.94h.07s6.74-.83,6.74-.83l.69,8.46c-.01,33.24-16.32,63.14-41.55,76.17l-.34.15-2.4.91-2.77-1.07ZM18.59,30.64v.05c.05,31.04,15.11,58.95,38.38,71.11l.49.26.49-.26c23.26-12.15,38.32-40.07,38.37-71.11v-.04l-.2-2.4-1.68.13c-12.92,0-25.49-4.39-36.35-12.67l-.65-.49-.65.49c-10.86,8.28-23.43,12.66-36.35,12.67l-1.68-.13-.2,2.4Z"
        //     />
        // </svg>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
            <path
                style={{ fill: color }}
                d="m57.45.19C25.83.19.19,25.83.19,57.45s25.63,57.27,57.26,57.27,57.27-25.63,57.27-57.27S89.07.19,57.45.19Zm39.52,36.93c-.01,27.72-13.67,52.67-34.78,63.57l-.4.19-2.59.98-2.94-1.13-.07-.03c-21.11-10.92-34.76-35.89-34.76-63.61v-.29s.7-8.4.7-8.4l7.08.87c9.72-.07,19.09-3.59,27.13-10.16l2.86-2.87,2.84,2.85c8.1,6.61,17.45,10.11,27.13,10.17l7.08-.87.71,8.66v.07Zm-37.76-10.49c-9.01,6.72-19.38,10.29-30.05,10.32l-.02.28c.06,24.57,11.85,46.62,30.07,56.34,18.24-9.73,30.02-31.79,30.07-56.33l-.02-.29c-10.67-.03-21.04-3.6-30.05-10.32Zm18.77,43.32l-5.28,5.28-12.96-12.95-12.96,12.95-5.28-5.28,12.95-12.96-12.95-12.96,5.28-5.28,12.96,12.96,12.96-12.96,5.28,5.28-12.96,12.96,12.96,12.96Z"
            />
        </svg>
    );
};
