import { useHistory } from 'react-router-dom';
import { AMToolTipAd } from '../../../../components/core/AMTooltipAd/AMTooltipAd';
import './PostureArchive.scss';
import InfoIcon from '@mui/icons-material/Info';
import { useState, useEffect } from 'react';
import { AMGenericConfirmPopUp } from '../../../../components/core/AMGenericConfirmPopUp/AMGenericConfirmPopUp';
import { useAuthDetails } from '../../../../components/Authorization';
import { usePostureArchival } from '../Hooks/Posture';
import React from 'react';

interface IPostureActionButtonsProps {
    onArchiveClick(): void,
    selectCount: number,
    isOpenIncidents?: boolean,
    onArchiveEstimate?: any,
    entity: string,
    selectAll: boolean,
    isHidden?: boolean
}

export interface IPostureActionButtonsRef extends HTMLButtonElement {
    onArchiveBtnClick?(): void
}


export const PostureActionButtons = React.forwardRef<IPostureActionButtonsRef, IPostureActionButtonsProps>(({ onArchiveClick, selectCount, isOpenIncidents,
    onArchiveEstimate, entity, selectAll, isHidden }: IPostureActionButtonsProps, ref) => {
    const OPEN_INCIDENT_MESSAGE = <span>You have selected <strong>{selectCount} {entity}</strong> to be archived.
        In addition, all associated open incidents will be closed.</span>;
    const [showConfirm, setShowConfirm] = useState(false);
    const { authDetails } = useAuthDetails()!;
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(onArchiveEstimate ? true : false);
    const [userInput, setUserInput] = useState('');
    const [popUpButtons, setPopUpButtons] = useState({
        no: {
            id: 'no', label: 'No'
        },
        yes: {
            id: 'yes', label: 'Yes'
        }
    });


    React.useImperativeHandle(ref, () => ({
        onArchiveBtnClick() {
            return archiveBtnClick();
        }
    } as IPostureActionButtonsRef));

    useEffect(() => {
        const btns = JSON.parse(JSON.stringify(popUpButtons));
        if (selectAll) {
            btns.yes.isDisabled = true;
        } else {
            btns.yes.isDisabled = false;
        }
        setPopUpButtons(btns);
    }, [selectAll])

    const archiveBtnClick = () => {
        setShowConfirm(true);
    }

    const handleOnArchiveProceed = () => {
        setShowConfirm(false)
        onArchiveClick();
    }

    useEffect(() => {
        if (showConfirm && onArchiveEstimate) {
            setLoading(true);
            onArchiveEstimate((msg: string): void => {
                setLoading(false);
                setMessage(msg);
            }, (err: any): void => {
                setLoading(false);
                setMessage("");
            });
        }
    }, [showConfirm])

    const getMessageJSX = () => {
        return (
            <>
                {isOpenIncidents && <span className='font14 inlineBlock margintop10'>
                    {OPEN_INCIDENT_MESSAGE}</span>}
                {<div className='font14 inlineBlock margintop10 marginbottom10'>{message}</div>}
                {selectAll && <div className='font14'>
                    <br></br>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '50%' }}>To confirm, please type "Archive":</span>
                        <input style={{ height: '2.5em', marginLeft: '-20%', width: '30%' }} type='text'
                            onChange={(e) => {
                                setUserInput(e.target.value);
                                const btns = JSON.parse(JSON.stringify(popUpButtons));
                                if (e?.target?.value && e?.target?.value?.toLowerCase() == 'archive') {
                                    btns.yes.isDisabled = false;
                                } else {
                                    btns.yes.isDisabled = true;
                                }
                                setPopUpButtons(btns);
                            }}
                        />
                    </div>
                </div>}
            </>
        );
    }

    return <>
        {
            authDetails?.permissions?.Posture?.manual_archive == 'readwrite' && <>
                <div className="posture_action_btn_container"
                    style={{ visibility: isHidden ? 'hidden' : 'visible', display: 'none' }}
                >
                    {/* <button className={'button_styled issue-advance-search posture_action_btn_container_item'}>Delete</button> */}
                    <button
                        ref={ref}
                        className={('button_styled issue-advance-search posture_action_btn_container_item ' + (selectCount > 0 ? ' ' : 'disableItems grayscale'))}
                        onClick={archiveBtnClick}>Archive</button>
                </div>
                <AMGenericConfirmPopUp title={'Confirm Archive'}
                    showConfirm={showConfirm}
                    buttons={popUpButtons} onCloseHandler={() => setShowConfirm(false)}
                    onProceedHandler={handleOnArchiveProceed}
                >{loading ? <div className='loader spinner'></div> : getMessageJSX()}
                </AMGenericConfirmPopUp>
            </>
        }
    </>
})

interface IPostureArchiveTooltipProps {
    numberOfDays?: string | number
}

export const PostureArchiveTooltip = ({ numberOfDays = '60' }: IPostureArchiveTooltipProps) => {
    const history = useHistory();

    const handlePostureSettingsLink = () => {
        history.push('/general_settings?type=posture')
    }

    return <>
        {/*   <AMToolTipAd
            classes={{
                popper: 'posture-archive-tooltip-popper'
            }}
            title={
                <span>Currently showing data for {numberOfDays} days. To change this, please go to Admin menu, then General Settings, and then <span className='link-text'
                    onClick={handlePostureSettingsLink}
                >Posture Settings</span>.</span>
            }
        >
            <InfoIcon
                className='posture-archive-tooltip-icon' />
        </AMToolTipAd> */}
    </>
}