export const POSTURE_ISSUE = { label: "Posture Issues", type: "Posture Issues" };

export const ENTITIES = {
    IDENTITY: { id: 'IDENTITY', tab: 'identity', pageType: 'Identities', path: 'identities' },
    ASSETS: { id: 'ASSETS', tab: 'assets', pageType: 'Assets', path: 'assets' },
    ACCESSES: { id: 'ACCESSES', tab: 'accesses', pageType: 'Accesses', path: 'accesses' },
    IDENTITY_SYSTEMS: { id: 'IDENTITY_SYSTEMS', tab: 'identity systems', pageType: 'Directory', path: 'directory' }
}

export const CREATE_PLAYBOOK_ROLES = ['admin', 'analyst', 'root'];

export const PAGE_TYPES = [ENTITIES.IDENTITY.pageType, ENTITIES.ASSETS.pageType, ENTITIES.ACCESSES.pageType, ENTITIES.IDENTITY_SYSTEMS.pageType];