import "./unknown-access-skeleton.scss";

export const UnknownAccessSkeleton = () => {
    return (
        <>
            <div className="skeleton-unknown-access-container animate-pulse">
                <div className="skeleton-title"></div>
                <div className="skeleton-content">
                    <div className="skeleton-top-side"></div>
                    <div className="skeleton-bottom-side"></div>
                </div>
            </div>
        </>
    );
};
