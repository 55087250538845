export const SettingsErrors = {
    posture: {
        data_archive_time: {
            required: 'Data Archive time is empty.',
            min: 'Data Archive Time - Please enter value between 15-60.',
            max: 'Data Archive Time - Please enter value between 15-60.'
        },
        posture_view_time: {
            required: 'Posture View time is empty.',
            min: 'Posture View Time - Please enter value between 15-60.',
            max: 'Posture View Time - Please enter value between 15-60.'
        }
    }
}