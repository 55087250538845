export const ProxyConstants = {
    username: 'Username*',
    password: 'Password*',
    enterBasicUser: 'Enter Username',
    enterBasicPass: 'Enter Password',
    sslInspect: 'SSL Inspection',
    enterNtlmUser: 'Enter NTLM/Negotiate Username',
    enterNtlmPass: 'Enter NTLM/Negotiate Password',
    ntlmUser: 'NTLM/Negotiate Username*',
    ntlmPass: 'NTLM/Negotiate Password*',
    domain: 'Domain*',
    enterDomain: 'Enter NTLM/Negotiate Domain',
    sslCertificate: 'Proxy Root CA Certificate',
    enterSSLCertificate: 'Enter SSL Certificate (.pem format)',
    errorBasicUser: 'Please enter username',
    errorBasicPassword: 'Please enter password',
    errorNTLMUser: 'Please enter username',
    errorNTLMPass: 'Please enter password',
    errorNTLMDomain: 'Please enter domain',
    enterValidCertificate: 'Please enter valid certificate'
}