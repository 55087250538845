import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import './alertComponent.scss';

const testData1 = [
    { type: 'inactive', isSummary: false, text: 'This is incorrect.' },
    { type: 'error', isSummary: true, text: 'This is incorrect.' },
    { type: 'success', isSummary: false, text: 'This is incorrect.' },
    { type: 'error', isSummary: false, text: 'This is incorrect.' }
];

const testData2 = [
    { type: 'success', isSummary: false, text: 'This is incorrect.' }
];

export const AlertComponent = ({ messages = [] }) => {
    const summaryMsgObj = messages.find((msg) => msg.isSummary);

    const getMsgJSX = (message) => {
        if (message.isSecondary) {
            return (<div className='alert-secondary-container'>
                <div className={`alert-item ${message.type}`}>
                    <div className='alert-item-msg-secondary'>{message.text}</div>
                </div>
                </div>
            );
        }
        if (message.type === 'inactive') {
            return (
                <div className={`alert-item ${message.type}`}>
                    <InfoIcon
                        style={{
                            fontSize: '1.5em',
                            color: '#87929E',
                            fill: 'none',
                            stroke: '#87929E',
                            marginBottom: '0.2em'
                        }}
                    />
                    <div className='alert-item-msg'>{message.text}</div>
                </div>
            );
        } else {
            return (
                <div className={`alert-item ${message.type}`}>
                    <div
                        className={`alert-item-logo ${message.type}-icon`}
                    ></div>
                    <div className='alert-item-msg'>{message.text}</div>
                </div>
            );
        }
    };

    if (messages.length === 0) {
        return <></>;
    }

    return (
        <div className='alert-main-container'>
            {summaryMsgObj ? (
                <Accordion
                    disableGutters
                    elevation={0}
                    className={`${summaryMsgObj.type}-background`}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='alert-collapsible-container'
                        id='alert-collapsible-container'
                        style={{
                            fontSize: '12px',
                            fontWeight: '500'
                        }}
                        sx={{
                            '& .MuiSvgIcon-fontSizeMedium': {
                                fontSize: '14px',
                                fontWeight: '500',
                                color: 'black'
                            }
                        }}
                    >
                        {getMsgJSX(summaryMsgObj)}
                    </AccordionSummary>
                    <AccordionDetails
                        style={{
                            padding: '0px'
                        }}
                    >
                        <div className='alert-item-container'>
                            {messages
                                .filter((msg) => !msg.isSummary)
                                .map((msg) => getMsgJSX(msg))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ) : (
                <div
                    className={`alert-item-container ${messages[0].type}-background`}
                >
                    {getMsgJSX(messages[0])}
                </div>
            )}
        </div>
    );
};
