import React from "react";
import "./risk-trend-incident-skeleton.scss";

export const RiskTrendIncidentSkeleton = () => {
    return (
        <>
            <div className="skeleton-risk-trend-incident-container animate-pulse">
                <div className="skeleton-content">
                    <div className="total-section"></div>
                    {[...Array(4).keys()].map((el) => {
                        return <div className="risk-type-section"></div>;
                    })}
                </div>
            </div>
        </>
    );
};
