import React, { useEffect, useState } from 'react';

export const OktaCollector = ({ register, setValue, collectorData }) => {
    const [mfaEnabledCheck, setMfaEnabledCheck] = useState(false);
    useEffect(() => {
        if (collectorData) {
            setValue(
                'okta_sub_domain',
                collectorData.collector_config?.okta_sub_domain
            );
            setValue(
                'okta_api_token',
                collectorData.collector_config?.okta_api_token
            );
            setValue(
                'mfa_enabled',
                collectorData.collector_config?.mfa_enabled
            );
            setMfaEnabledCheck(collectorData.collector_config?.mfa_enabled);
        }
    }, [collectorData]);

    const handleMfaEnabledChange = (e) => {
        e.stopPropagation();
        if (e.target.checked) {
            setMfaEnabledCheck(true);
        } else {
            setMfaEnabledCheck(false);
        }
    };
    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Okta Collector Configurations
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Sub Domain*</label>
                            <input
                                type='text'
                                name='okta_sub_domain'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Sub Domain'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>API Token*</label>
                            <input
                                type='text'
                                name='okta_api_token'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter API Token'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='sensor-flex-container'>
                <div className='sensor-flex-container-item'>
                    <input
                        name='mfa_enabled'
                        type='checkbox'
                        className={'margintop20'}
                        checked={mfaEnabledCheck}
                        onChange={handleMfaEnabledChange}
                        ref={register}
                    />
                    <label
                        className='auto_width paddingright20'
                        style={{ float: 'unset' }}
                    >
                        Performs MFA?
                    </label>
                </div>
            </div>
        </div>
    );
};
