import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

export const MarkKnownIPPopUp = ({ knownIPData, onClose, onProceed }: any) => {
    const [selectedRange, setSelectedRange] = useState<any>(undefined);
    useEffect(() => {
        const len = knownIPData.filter((item: any) => item.id).length;
        if (len == 1) {
            const i = knownIPData.filter((item: any) => item.id);
            onProceed(i[0]);
        }
    }, []);
    return <>
        <Popup
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={onClose}
            modal
        >
            <div className="modal">
                <div className="close"
                    onClick={onClose}
                >
                </div>
                <div className="header">{'Add to Known IP Ranges'}</div>
                <div className="content">
                    <div className="font16 margintop20 marginbottom20">
                        <span>Please select ip/hostnames to be added to known ip ranges</span>
                        <RadioGroup
                            aria-labelledby="mark-known-radio-group"
                            name="radio-buttons-group"
                            onChange={(e: any) => {
                                const i = knownIPData.find((item: any) => item.id == e.target.value);
                                setSelectedRange(i);
                            }}
                            className='mark-known-popup'
                        >
                            {
                                knownIPData.filter((item: any) => item.id)
                                    .map((item: any, index: number) => {
                                        return <FormControlLabel
                                            // className={ }
                                            value={item.id} control={<Radio />}
                                            label={item.label + ' : ' + `[${item.id}]`} />
                                    })
                            }

                        </RadioGroup>
                    </div>
                </div>
                <div className="popup_footer">
                    <button style={{ float: 'right' }} type="button"
                        className={"button_main width25per " + (!selectedRange ? ' disableItems' : '')}
                        onClick={(e: any) => onProceed(selectedRange)}
                    >Proceed</button>
                    <button style={{ display: 'inline', float: 'left', marginRight: '2%' }}
                        type="button" className="button_gray width25per margintop10"
                        onClick={onClose}
                    >Cancel</button>
                </div>
            </div>
        </Popup>
    </>
}