import { useEffect, useRef } from "react";

export default function usePrevious(value: number | undefined) {
    const ref = useRef<number | undefined>(value);
    useEffect(() => {
        setTimeout(() => {
            ref.current = value;
        }, 15000);
    }, [value]);
    return ref.current;
}
