import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Verify from './Verify'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import './Login.css'
import { Eula, EulaDeclinePopUp } from './eula/eula'
const BeforeLogin = () => {

    return (
        <Switch>
            <Route exact path='/' component={Verify} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/reset_password' component={ResetPassword} />
            <Route exact path='/eula' component={Eula} />
            <Route exact path='/eula-reject' component={EulaDeclinePopUp} />
            <Route exact path="/root-user" render={({ match }) => { return <Login isRootUser={true} />; }} />
            <Route path="/health">
                <h3>Hey There!!! The App is Healthy</h3>
            </Route>
        </Switch>
    )
}

export default BeforeLogin