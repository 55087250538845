import React, { createContext, useContext, useEffect, useState } from "react";
const { REACT_APP_HOST_IP } = process.env;

//TODOWhiteLabel
const BaseURL = REACT_APP_HOST_IP
// const BaseURL = "http://dev.authmind.net/";
const RouteURL = "/static-lib/";

// Define the type for the context provider
type PartnerContextType = {
    PartnerConfigState: {
        PartnerEmail: string;
        PartnerProductName: string;
        PartnerName: string;
        PartnerShortProduct: string;
        PartnerSignature: string;
        PartnerLogoPath: string,
        PartnerTeaserLogo: string,
        setParterConfigState: (config: any) => void;
    };
};

// Create the context
const PartnerContext = createContext<PartnerContextType | undefined>(undefined);

interface IPartnerConfig {
    product_name: string,
    company_name: string,
    short_product_name: string,
    support_email: string,
    signature: string
}


interface PartnerStateContextProviderProps {
    initialValue: IPartnerConfig;
    children: React.ReactNode;
    value?: IPartnerConfig
}

export const PartnerStateContextProvider: React.FC<PartnerStateContextProviderProps> = ({
    initialValue,
    children,
    value
}: PartnerStateContextProviderProps) => {

    const [configState, setConfigState] = useState<any | undefined>({
        PartnerEmail: initialValue.support_email,
        PartnerProductName: initialValue.product_name,
        PartnerName: initialValue.company_name,
        PartnerShortProduct: initialValue.short_product_name,
        PartnerSignature: initialValue.signature,
        PartnerLogoPath: BaseURL + RouteURL + "images/logo.png",
        PartnerTeaserLogo: BaseURL + RouteURL + "images/teaser.png",
    });

    useEffect(() => {
        if (value && value.company_name)
            setConfigState({
                PartnerEmail: value.support_email,
                PartnerProductName: value.product_name,
                PartnerName: value.company_name,
                PartnerShortProduct: value.short_product_name,
                PartnerSignature: value.signature,
                PartnerLogoPath: BaseURL + RouteURL + "images/logo.png",
                PartnerTeaserLogo: BaseURL + RouteURL + "images/teaser.png",
            })
    }, [value])

    const setConfigurationState = (config: any) => {
        setConfigState(config);
    };

    const contextValue: PartnerContextType = {
        PartnerConfigState: {
            PartnerEmail: configState.PartnerEmail,
            PartnerName: configState.PartnerName,
            PartnerProductName: configState.PartnerProductName,
            PartnerShortProduct: configState.PartnerShortProduct,
            PartnerSignature: configState.PartnerSignature,
            PartnerLogoPath: BaseURL + RouteURL + "images/logo.png",
            PartnerTeaserLogo: BaseURL + RouteURL + "images/teaser.png",
            setParterConfigState: setConfigurationState,
        },
    };

    // Use type assertion to specify the type of Provider component
    return (
        <PartnerContext.Provider value={contextValue as PartnerContextType}>
            {children}
        </PartnerContext.Provider>
    );
};

export const usePartnerStateContext = (): PartnerContextType => {
    const context = useContext(PartnerContext);
    if (!context) {
        throw new Error("usePartnerStateContext must be used within a PartnerStateContextProvider");
    }
    return context;
};
