import React, { useEffect, useState } from "react";
import { RuleType } from "../../../constants/Constants";
import '../../../playbook.css';
import '../unauth-access.css';
import { AutoCompleteInput } from "../../../../../components/core/AutoCompleteInput/AutoCompleteInput";
import { Api } from "../../../../../components/Axios";
import { BaseProps } from "../../../constants/types";
import { AccessToUnauthCountriesState } from "../../../../../types/playbooks-config";
import CheckboxAutocomplete from "../../../../../components/core/AMAutoComplete/AMAutoComplete";




const OperatorMap = {
    'equals': 'in',
    'not equals': 'not in'
}


export const AccessToUnauthCountries = ({ parentState, isSavedOnce, getState,
    defaultState, formType, getExpression, ruleType }: BaseProps<AccessToUnauthCountriesState>) => {
    const [state, setState] = useState<AccessToUnauthCountriesState>(defaultState);
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        Api.get('/countries').then((res: any) => {
            const con = res.data.map((i) => ({ key: i, value: i }));
            setCountries(con)
        }).catch((res: any) => {
            setCountries([])
            // setCountries(["India", "Pakistan", "Australia", "Turkey", "United States", "Britain"] as any) 
        })
    }, []);

    const setSelectedCountries = (e, value: any) => {
        setState(
            {
                unauthorized_countries: {
                    countries: (value?.map((i) => i.value)),
                    operator: state?.unauthorized_countries?.operator || 'equals'
                }
            },
        );
    };

    useEffect(() => {
        getState(state);
    })

    useEffect(() => {
        const op = state?.unauthorized_countries?.operator;
        const countriesArr = state?.unauthorized_countries?.countries || [];
        const object = {
            operator: OperatorMap[op as keyof typeof OperatorMap],
            countries: "'" + countriesArr.join("','") + "'"
        }
        if (getExpression) {
            getExpression(object);
        }
    })

    const onOperatorSelect = (e: any, config) => {
        setState({
            unauthorized_countries: {
                ...state?.unauthorized_countries, operator: config[0].value
            }
        })
    }

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const getItemLabel = (op) => {
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }


    return <div className="access-unatuh-container">
        <div style={{ width: '100%' }}><label className="playbook-config-title">
            {
                ruleType === RuleType.ACCESS_TO_UNAUTH_COUNTRIES ?
                    'Access should be marked as “access to an unauthorized country” when:' :
                    'Access should be marked as “access from an unauthorized country” when:'
            }
        </label></div>
        {
            (formType && ['view'].includes(formType)) ?
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} >
                    <label className='access-label'>{
                        ruleType === RuleType.ACCESS_TO_UNAUTH_COUNTRIES ? "The access's destination country"
                            : "The access's source country"
                    }</label>
                    <label style={{ width: '25%' }}>{state?.unauthorized_countries?.operator || 'equals'}</label>
                    <label className="ellipsis_common" title={state?.unauthorized_countries?.countries?.join(', ')}><strong>{state?.unauthorized_countries?.countries?.join(', ')}</strong></label>
                </div> : <div style={{ display: 'flex', width: '100%' }} className="gap10">
                    <label style={{ width: '50%' }}>{
                        ruleType === RuleType.ACCESS_TO_UNAUTH_COUNTRIES ? "The access's destination country"
                            : "The access's source country"
                    }</label>
                    <CheckboxAutocomplete
                        classes={{ root: "countries-operator" }}
                        options={[{ key: 'equals', value: 'equals' }, { key: 'not equals', value: 'not equals' }]}
                        getItemLabel={getItemLabel}
                        onChange={onOperatorSelect}
                        value={{ key: state?.unauthorized_countries?.operator || 'equals', value: state?.unauthorized_countries?.operator || 'equals' }}
                        label={"Select Operator"}
                        hideClearBtn={true}
                    />
                    <CheckboxAutocomplete
                        options={countries}
                        getItemLabel={getItemLabel}
                        multiple={true}
                        onChange={setSelectedCountries}
                        value={state?.unauthorized_countries?.countries?.map((i) => ({ key: i, value: i })) || []}
                        label={"Type country name"}
                    />
                </div>
        }

    </div >
}