
import { Fade, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import './PlaybookTableActions.scss'
import React from "react";
import { IPlaybookActions, IPlaybookTableActionsProps } from "./PlaybookTypes";

export const PlaybookTableActions = <T extends unknown>({ actions, rowData, formType, isDisabled, id = 'pb-table-action' }: IPlaybookTableActionsProps<T>) => {
    return <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
        classes={{ tooltip: 'pb-action-content' }}
        style={{ display: 'block' }}
        PopperProps={{ style: { zIndex: 99999999 } }}
        /* open={true} */
        title={<div >
            <ul>
                {
                    actions && actions.map((i: IPlaybookActions<T>, ind: number) => {
                        return i.hasOwnProperty('isApplicable') && i.isApplicable == false ? <></> : <li key={`${i.actionId}-${ind}`}
                            className={(i.isDisabled ? ' disableItems' : '')}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                if (i.actionCallback)
                                    i?.actionCallback(rowData, e)
                            }}>
                            <Link to="#" onClick={(e) => e.preventDefault()}>{i.actionLabel}</Link>
                        </li>
                    })
                }
            </ul>
        </div>}>
        {
            actions && actions.filter((i) => !i.hasOwnProperty('isApplicable') || (i.hasOwnProperty('isApplicable') && i.isApplicable != false)).length > 0 ?
                <div
                    id={id}
                    className={"pb_action_button" + ((['view'].includes(formType || '') || isDisabled) ? ' disabled_action disableItems' : '')}>
                </div> : <></>}
    </Tooltip>
}