import React from "react";
import { RuleType } from "./Constants";
import { UnauthAssetAccessConfig } from "../rules-config";
import { WeekSelector } from "../ref";
import { IUnauthConfigState, IUnauthPayloadRuleConfig } from "../rules-config/fencing-config/unauthorized-asset-access/UnauthorizedAssetAccessConfig";
import { ResetState } from "./DefaultState";

export const CustomConfig = {
    [RuleType.UNAUTHORIZED_ASSET_ACCESS]: {
        loadComponent: (getConfigStateFromChild: any, configState: any, getExpressionFromChild?: any, formType?: string, customData?: any) => {
            return <UnauthAssetAccessConfig getState={getConfigStateFromChild} parentState={configState} formType={formType} getExpression={getExpressionFromChild} />;
        },
        updatePayload: (payloadRequest: any) => {
            delete payloadRequest.source;
            delete payloadRequest.destination;
            delete payloadRequest.source_pattern;
            delete payloadRequest.destination_pattern;
            payloadRequest.rule.config = { unauthorized_asset: payloadRequest.rule.config }
            return payloadRequest;
        },
        loadTableHeaders: (formType?: string) => {
            return formType && ['view', 'edit'].includes(formType) ? ['Playbook Name', 'Define Parameters', 'Authorized Identities & Hosts', 'Authorized Intermediary', 'Authorized Assets', 'Actions', 'Risk', 'Priority']
                : ['Playbook Name', 'Define Parameters', 'Authorized Identities & Hosts', 'Authorized Intermediary', 'Authorized Assets', 'Actions', 'Risk']

        },
        decodePayload: (payload: any, isHeader: boolean, config?: any) => {
            if (config?.isCSV) {
                const a_map = {
                    'Asset/Destination Name': 'd_name',
                    'Asset/Destination IP': 'd_ip',
                    'Asset/Destination Hostname': 'd_hostname',
                    'Destination Ports': 'd_port',
                    'Protocols': 'd_protocol'
                };
                const i_map = {
                    'Identity/Source Name': 's_name',
                    'Identity/Source IP': 's_ip',
                    'Identity/Source Hostname': 's_hostname',
                    'Source Ports': 's_port'
                }

                const inter_map = {
                    'Access Control IP': 'src_inter_ip',
                    'Directory/IDP Name': 'dir_name',
                    'Directory/IDP Hostname': 'dir_hostname'
                }

                const getAttributes = (item: any, map: any) => {
                    const result = {};
                    Object.keys(map).forEach((i: string) => {
                        if (item[i]) {
                            const nm = map[i];
                            const p = !i.includes('ip') ? ['*'] : ['/', '-', '*'];
                            result[nm] = {
                                type: p.some((it: string) => item[i].includes(it)) ? 'matches' : 'equals',
                                value: [...item[i].split(',')]
                            }
                        }
                    });
                    return result;
                }

                const data = payload?.map((item: any) => {
                    if (!isHeader && Array.isArray(item)) {
                        /* if no header expected is to follow the sample csv header positions */
                        const headerArray = ["Identity/Source Name","Identity/Source Hostname","Identity/Source IP","Source Ports","Access Control IP","Asset/Destination Name","Asset/Destination Hostname","Asset/Destination IP","Directory/IDP Name","Directory/IDP Hostname","Destination Ports","Protocols"];
                        const it = {} as any;
                        headerArray.forEach((ite: string, index: number) => {
                            it[ite] = item[index];
                        });
                        item = it;
                    }
                    return {
                        /*   params: {
                              dest_port: item?.["Destination Ports"] ? item["Destination Ports"].split(",").filter((i: any) => i) : [],
                              src_port: item?.["Source Ports"] ? item["Source Ports"].split(",").filter((i: any) => i) : [],
                              access_protocol: item?.["Protocols"] ? item["Protocols"].split(",").filter((i: any) => i) : [],
                              access_time: WeekSelector.DefaultWeekSelectorState
                          }, */
                        asset: {
                            type: ['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? [item['Asset/Destination Name']] : [],
                            pattern: !['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? (getAttributes(item, a_map)) : {}
                        },
                        identity: {
                            type: ['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? [item['Identity/Source Name']] : [],
                            pattern: !['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? (getAttributes(item, i_map)) : {}
                        },
                        intermediary: {
                            type: ['Any Access Control'].includes(item['Access Control IP']) || !item['Access Control IP'] ? ['Any Access Control'] : [],
                            pattern: !['Any Access Control'].includes(item['Access Control IP']) ? (getAttributes(item, inter_map)) : {}
                        },
                        config: 'NA'
                    }
                });
                return data;
            } else {
                const data = payload?.unauthorized_asset?.map((item: IUnauthPayloadRuleConfig) => {
                    return {
                        params: {
                            dest_port: item?.dest_port || [],
                            src_port: item?.src_port || [],
                            access_protocol: item?.access_protocol || [],
                            access_time: item?.access_time || WeekSelector.DefaultWeekSelectorState
                        },
                        asset: {
                            type: item?.destination || [],
                            pattern: item.destination_pattern
                        },
                        identity: {
                            type: item?.source || [],
                            pattern: item.source_pattern
                        },
                        intermediary: {
                            type: item?.intermediary_pattern ? ['Custom List'] : ['Any Access Control'],
                            pattern: item?.intermediary_pattern
                        },
                        config: 'NA'
                    } as IUnauthConfigState
                }) as Array<IUnauthConfigState>;
                return data;
            }
        },
        convertCsvData: (payload: any[]) => {
            const result = [] as any;
            payload?.forEach((item: any, index: number) => {
                if (item) {
                    result[index] = { ...item.params };
                    result[index].source_pattern = { ...item?.identity?.pattern };
                    result[index].destination_pattern = { ...item?.asset?.pattern };
                    result[index].source = [...item?.identity?.type]
                    result[index].destination = [...item?.asset?.type]
                }
            })
            return result;
        }
    },
    [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: {
        decodePayload: (payload: any, isHeader: boolean, config?: any) => {
            if (config?.isCSV) {
                const a_map = {
                    'Asset/Destination Name': 'd_name',
                    'Asset/Destination IP': 'd_ip',
                    'Asset/Destination Hostname': 'd_hostname',
                    'Destination Ports': 'd_port',
                    'Protocols': 'd_protocol'
                };
                const i_map = {
                    'Identity/Source Name': 's_name',
                    'Identity/Source IP': 's_ip',
                    'Identity/Source Hostname': 's_hostname',
                    'Source Ports': 's_port'
                }

                const inter_map = {
                    'Access Control IP': 'src_inter_ip',
                    'Directory/IDP Name': 'dir_name',
                    'Directory/IDP Hostname': 'dir_hostname'
                }

                const getAttributes = (item: any, map: any) => {
                    const result = {};
                    Object.keys(map).forEach((i: string) => {
                        if (item[i]) {
                            const nm = map[i];
                            const p = !i.includes('ip') ? ['*'] : ['/', '-', '*'];
                            result[nm] = {
                                type: p.some((it: string) => item[i].includes(it)) ? 'matches' : 'equals',
                                value: [...item[i].split(',')]
                            }
                        }
                    });
                    return result;
                }

                const data = payload?.map((item: any) => {
                    if (!isHeader && Array.isArray(item)) {
                        /* if no header expected is to follow the sample csv header positions */
                        const headerArray = ["Identity/Source Name","Identity/Source Hostname","Identity/Source IP","Source Ports","Access Control IP","Asset/Destination Name","Asset/Destination Hostname","Asset/Destination IP","Directory/IDP Name","Directory/IDP Hostname","Destination Ports","Protocols"];
                        const it = {} as any;
                        headerArray.forEach((ite: string, index: number) => {
                            it[ite] = item[index];
                        });
                        item = it;
                    }
                    return {
                        /*   params: {
                              dest_port: item?.["Destination Ports"] ? item["Destination Ports"].split(",").filter((i: any) => i) : [],
                              src_port: item?.["Source Ports"] ? item["Source Ports"].split(",").filter((i: any) => i) : [],
                              access_protocol: item?.["Protocols"] ? item["Protocols"].split(",").filter((i: any) => i) : [],
                              access_time: WeekSelector.DefaultWeekSelectorState
                          }, */
                        asset: {
                            type: ['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? [item['Asset/Destination Name']] : [],
                            pattern: !['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? (getAttributes(item, a_map)) : {}
                        },
                        identity: {
                            type: ['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? [item['Identity/Source Name']] : [],
                            pattern: !['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? (getAttributes(item, i_map)) : {}
                        },
                        intermediary: {
                            type: ['Any Access Control'].includes(item['Access Control IP']) || !item['Access Control IP'] ? ['Any Access Control'] : [],
                            pattern: !['Any Access Control'].includes(item['Access Control IP']) ? (getAttributes(item, inter_map)) : {}
                        },
                        config: 'NA'
                    }
                });
                return data;
            } else {
                const data = payload?.unauthorized_asset?.map((item: IUnauthPayloadRuleConfig) => {
                    return {
                        params: {
                            dest_port: item?.dest_port || [],
                            src_port: item?.src_port || [],
                            access_protocol: item?.access_protocol || [],
                            access_time: item?.access_time || WeekSelector.DefaultWeekSelectorState
                        },
                        asset: {
                            type: item?.destination || [],
                            pattern: item.destination_pattern
                        },
                        identity: {
                            type: item?.source || [],
                            pattern: item.source_pattern
                        },
                        intermediary: {
                            type: item?.intermediary_pattern ? ['Custom List'] : ['Any Access Control'],
                            pattern: item?.intermediary_pattern
                        },
                        config: 'NA'
                    } as IUnauthConfigState
                }) as Array<IUnauthConfigState>;
                return data;
            }
        },
        convertCsvData: (payload: any[]) => {
            const result = [] as any;
            payload?.forEach((item: any, index: number) => {
                if (item) {
                    result[index] = { ...item.params };
                    result[index].source_pattern = { ...item?.identity?.pattern };
                    result[index].destination_pattern = { ...item?.asset?.pattern };
                    result[index].source = [...item?.identity?.type]
                    result[index].destination = [...item?.asset?.type]
                }
            })
            return result;
        }
    },
    [RuleType.ACCOUNT_TAKEOVER]: {
        decodePayload: (payload: any, isHeader: boolean, config?: any) => {
            if (config?.isCSV) {
                const a_map = {
                    'Asset/Destination Name': 'd_name',
                    'Asset/Destination IP': 'd_ip',
                    'Asset/Destination Hostname': 'd_hostname',
                    'Asset/Destinatio Ports': 'd_port',
                    'Protocol': 'd_protocol'
                };
                const i_map = {
                    'Identity/Source Name': 's_name',
                    'Identity/Source IP': 's_ip',
                    'Identity/Source Hostname': 's_hostname',
                    'Identity/Source Ports': 's_port'
                }

                const inter_map = {
                    'Access Control IP': 'src_inter_ip',
                    'Directory/IDP Name': 'dir_name',
                    'Directory/IDP Hostname': 'dir_hostname'
                }

                const getAttributes = (item: any, map: any) => {
                    const result = {};
                    Object.keys(map).forEach((i: string) => {
                        if (item[i]) {
                            const nm = map[i];
                            const p = !i.includes('ip') ? ['*'] : ['/', '-', '*'];
                            result[nm] = {
                                type: p.some((it: string) => item[i].includes(it)) ? 'matches' : 'equals',
                                value: [...item[i].split(',')]
                            }
                        }
                    });
                    return result;
                }

                const data = payload?.map((item: any) => {
                    if (!isHeader && Array.isArray(item)) {
                        /* if no header expected is to follow the sample csv header positions */
                        const headerArray = ["Identity/Source Name","Identity/Source Hostname","Identity/Source IP","Source Ports","Access Control IP","Asset/Destination Name","Asset/Destination Hostname","Asset/Destination IP","Directory/IDP Name","Directory/IDP Hostname","Destination Ports","Protocols"];
                        const it = {} as any;
                        headerArray.forEach((ite: string, index: number) => {
                            it[ite] = item[index];
                        });
                        item = it;
                    }
                    return {
                        asset: {
                            type: ['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? [item['Asset/Destination Name']] : [],
                            pattern: !['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? (getAttributes(item, a_map)) : {}
                        },
                        identity: {
                            type: ['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? [item['Identity/Source Name']] : [],
                            pattern: !['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? (getAttributes(item, i_map)) : {}
                        },
                        intermediary: {
                            type: ['Any Access Control'].includes(item['Access Control IP']) || !item['Access Control IP'] ? ['Any Access Control'] : [],
                            pattern: !['Any Access Control'].includes(item['Access Control IP']) ? (getAttributes(item, inter_map)) : {}
                        },
                        config: ResetState[RuleType.ACCOUNT_TAKEOVER]
                    }
                });
                return data;
            } else {
                const data = payload?.unauthorized_asset?.map((item: IUnauthPayloadRuleConfig) => {
                    return {
                        params: {
                            dest_port: item?.dest_port || [],
                            src_port: item?.src_port || [],
                            access_protocol: item?.access_protocol || [],
                            access_time: item?.access_time || WeekSelector.DefaultWeekSelectorState
                        },
                        asset: {
                            type: item?.destination || [],
                            pattern: item.destination_pattern
                        },
                        identity: {
                            type: item?.source || [],
                            pattern: item.source_pattern
                        },
                        intermediary: {
                            type: item?.intermediary_pattern ? ['Custom List'] : ['Any Access Control'],
                            pattern: item?.intermediary_pattern
                        },
                        config: 'NA'
                    } as IUnauthConfigState
                }) as Array<IUnauthConfigState>;
                return data;
            }
        },
        convertCsvData: (payload: any[]) => {
            const result = [] as any;
            payload?.forEach((item: any, index: number) => {
                if (item) {
                    result[index] = { ...item.params };
                    result[index].source_pattern = { ...item?.identity?.pattern };
                    result[index].destination_pattern = { ...item?.asset?.pattern };
                    result[index].source = [...item?.identity?.type]
                    result[index].destination = [...item?.asset?.type]
                }
            })
            return result;
        }
    },
    [RuleType.SUSPECTED_ACCESS_TOKEN]: {
        decodePayload: (payload: any, isHeader: boolean, config?: any) => {
            if (config?.isCSV) {
                const a_map = {
                    'Asset/Destination Name': 'd_name',
                    'Asset/Destination IP': 'd_ip',
                    'Asset/Destination Hostname': 'd_hostname',
                    'Asset/Destinatio Ports': 'd_port',
                    'Protocol': 'd_protocol'
                };
                const i_map = {
                    'Identity/Source Name': 's_name',
                    'Identity/Source IP': 's_ip',
                    'Identity/Source Hostname': 's_hostname',
                    'Identity/Source Ports': 's_port'
                }

                const getAttributes = (item: any, map: any) => {
                    const result = {};
                    Object.keys(map).forEach((i: string) => {
                        if (item[i]) {
                            const nm = map[i];
                            const p = !i.includes('ip') ? ['*'] : ['/', '-', '*'];
                            result[nm] = {
                                type: p.some((it: string) => item[i].includes(it)) ? 'matches' : 'equals',
                                value: [...item[i].split(',')]
                            }
                        }
                    });
                    return result;
                }

                const data = payload?.map((item: any) => {
                    if (!isHeader && Array.isArray(item)) {
                        /* if no header expected is to follow the sample csv header positions */
                        const headerArray = ["Identity/Source Name","Identity/Source Hostname","Identity/Source IP","Source Ports","Asset/Destination Name","Asset/Destination Hostname","Asset/Destination IP","Destination Ports","Protocols"];
                        const it = {} as any;
                        headerArray.forEach((ite: string, index: number) => {
                            it[ite] = item[index];
                        });
                        item = it;
                    }
                    return {
                        asset: {
                            type: ['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? [item['Asset/Destination Name']] : [],
                            pattern: !['No Assets', 'All Assets'].includes(item['Asset/Destination Name']) ? (getAttributes(item, a_map)) : {}
                        },
                        identity: {
                            type: ['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? [item['Identity/Source Name']] : [],
                            pattern: !['No Identities', 'All Identities'].includes(item['Identity/Source Name']) ? (getAttributes(item, i_map)) : {}
                        },
                        config: ResetState[RuleType.SUSPECTED_ACCESS_TOKEN]
                    }
                });
                return data;
            } else {
                const data = payload?.unauthorized_asset?.map((item: IUnauthPayloadRuleConfig) => {
                    return {
                        params: {
                            dest_port: item?.dest_port || [],
                            src_port: item?.src_port || [],
                            access_protocol: item?.access_protocol || [],
                            access_time: item?.access_time || WeekSelector.DefaultWeekSelectorState
                        },
                        asset: {
                            type: item?.destination || [],
                            pattern: item.destination_pattern
                        },
                        identity: {
                            type: item?.source || [],
                            pattern: item.source_pattern
                        },
                        intermediary: {
                            type: item?.intermediary_pattern ? ['Custom List'] : ['Any Access Control'],
                            pattern: item?.intermediary_pattern
                        },
                        config: 'NA'
                    } as IUnauthConfigState
                }) as Array<IUnauthConfigState>;
                return data;
            }
        },
        convertCsvData: (payload: any[]) => {
            const result = [] as any;
            payload?.forEach((item: any, index: number) => {
                if (item) {
                    result[index] = { ...item.params };
                    result[index].source_pattern = { ...item?.identity?.pattern };
                    result[index].destination_pattern = { ...item?.asset?.pattern };
                    result[index].source = [...item?.identity?.type]
                    result[index].destination = [...item?.asset?.type]
                }
            })
            return result;
        }
    }
}