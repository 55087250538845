import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import { Api } from '../../../components/Axios'
import { Checkbox } from '@mui/material'
import { AMCIDRHelpText } from '../../ref/AMCIDRHelpText';
import './edit-known-application.scss';

type KnownApplication = {
    name?: string;
    fqdn?: [];
}

interface Props {
    id?: string;
    AfterKnownApplicationOperation?: any;
    onPanelClose: any;
}

export const EditPanel = ({ id, AfterKnownApplicationOperation, onPanelClose }: Props) => {
    const { register, handleSubmit, errors, setValue } = useForm<KnownApplication>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [KnownApplication, setKnownApplication] = useState<KnownApplication>()
    const [fqdn, setFqdn] = useState([])
    const { addToast } = useToasts();
    const [isSaaSApp, setIsSaaSApp] = useState<boolean | undefined>(true);
    const [globalSaaS, setGlobalSaaS] = useState<string>('');
    const [isKnownApp, setIsKnownApp] = useState<boolean | undefined>(false);
    const [ipErrors, setIpErrors] = useState<any>();
    const [fqdnErrors, setFqdnErrors] = useState<any>();
    const [disableTag, setDisableTag] = useState<any>(false);
    const [applicationName, setApplicationName] = useState<any>();


    const onUpdateKnownApplication = (data: any) => {

        let request = {
            name: data.name,
            fqdn: data.fqdn,
            is_saas_app: false,
            global_saas_id: '',
            is_known: isKnownApp,
            source_catalog: data.source_catalog ? data.source_catalog : "NA"
        }

        if (fqdn.length == 0) {
            setFqdnErrors({ custom: 'IP/FQDN/pattern cannot be empty' });
            return;
        }

        setLoading(true)
        data['fqdn'] = fqdn;
        request['fqdn'] = fqdn;
        request['is_saas_app'] = isSaaSApp || false;
        request['global_saas_id'] = globalSaaS || '';
        const headers = { 'Operation': 'PUT' }
        Api.post('/knownapplications/' + id, request, { headers: headers })
            .then((res: any) => {
                setLoading(false)
                AfterKnownApplicationOperation('edit')
                const title = (isSaaSApp || globalSaaS) ? "SaaS application updated successfully, all related incidents resolved." :
                    "Custom application updated successfully, all related incidents resolved.";
                if (res.status == 207) {
                    addToast(res.data, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                } else
                    addToast(title, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
            })
            .catch((error: any) => {
                setLoading(false)
                if (error?.response?.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error?.response?.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    useEffect(() => {
        Api.get('/knownapplications/' + id)
            .then((res: { data: any }) => {
                console.log(res.data)
                setValue("name", res.data.name)
                setApplicationName(res.data.name);
                if (res.data.fqdn) {
                    setFqdn(res.data.fqdn)
                }
                setIsSaaSApp(res.data.is_saas_app || false);
                setGlobalSaaS(res.data.global_saas_id);
                setIsKnownApp(res.data.is_known);

            })
            .catch((error: any) => {
            })
    }, [])

    const AddTags = (tag: any) => {
        setDisableTag(true);
        if (tag.length > 0) {
            let tagName = tag[tag.length - 1];
            Api.get(`/knownglobalsaasapplications?q=${tagName}&include_all=True`).then((res: any) => {
                setFqdnErrors({});
                if (res && res.data.result && res.data.result.length > 0) {
                    for (let i of res.data.result) {
                        if (i.fqdn.includes(tagName) && i.name != applicationName) {
                            setFqdnErrors({ custom: 'Another app with the same IP/FQDN/pattern is already present. Please check.' });
                            setDisableTag(true);
                            break;
                        } else {
                            setDisableTag(false);
                            setFqdnErrors({});
                        }
                    }
                }
            }
            ).catch(err => console.log(err));
        }
        setDisableTag(false);
        const fqdn = tag.filter((item: string) => item && item.trim().length > 0);
        setFqdn(fqdn)
        setFqdnErrors({});
    }


    const handleSaaSFlag = (evt: any) => {
        setIsSaaSApp(!isSaaSApp);
    }


    const handleApplicationNameChange = (evt: any) => {
        const appName = evt.target.value;
        if (appName && appName.trim().length >= 3 && appName != applicationName) {
            Api.get(`/knownglobalsaasapplications?q=${appName}&include_all=True`).then((res: any) => {
                setIpErrors({});
                if (res && res.data.result && res.data.result.length > 0) {
                    for (let i of res.data.result) {
                        if (i.name == appName) {
                            setIpErrors({ custom: 'Another app with the same name is already present. Please check.' });
                            break;
                        } else {
                            setIpErrors({});
                        }
                    }
                }
            }
            ).catch(err => console.log(err));
        } else {
            setIpErrors({});
        }
    }

    const defaultRenderInput = (props: any) => {
        let { onChange, value, addTag, ...other } = props
        return (
            <input type='text' onChange={onChange} value={value} {...other} disabled={disableTag} />
        )
    }


    return (
        <form onSubmit={handleSubmit(onUpdateKnownApplication)} className='edit-known-application-form'>
            <div className='edit-known-application-container'>
                <div className="edit-known-application-form-container">
                    <div className="form-control-item">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder=""
                            name="name"
                            ref={register({
                                required: true,
                                minLength: 3,
                                pattern:/^[^'"]*$/
                            })}
                            className={(errors.name ? "error" : "")}
                        // onChange={handleApplicationNameChange}
                        />
                    </div>
                    <div className="form-control-item span-2">
                        <label className="marginbottom10" style={{display: 'flex'}}>IP Addresses/Host Names/FQDNs/Patterns*<AMCIDRHelpText/></label>
                        <TagsInput
                            value={fqdn}
                            onChange={AddTags}
                            inputProps={{ placeholder: "Add IP address, etc." }}
                            renderLayout={(tagElements, inputElement) => {
                                return (
                                    <span>
                                        <div className="scrollbar-container directory-tag-container">
                                            {tagElements}
                                        </div>
                                        <div>
                                        {inputElement}
                                        </div>
                                    </span>
                                )
                            }}
                            renderInput={defaultRenderInput}
                            // validationRegex={(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9][^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/)}
                        />
                        <div className="marginleft40per error">Hit 'Enter' or 'Tab' key after each IP or Server Name/FQDN to enter more than one. This supports entry of patterns.</div>
                    </div>
                </div>
                {(Object.keys(errors || {}).length > 0  || fqdnErrors?.custom) && (
                    <div className="edit-known-application-errors-list-container">
                        {errors.name && errors.name.type === 'required' && <div className="error dot marginleft40per">Please enter application name.</div>}
                        {errors.name && errors.name.type === 'minLength' && <div className="error dot marginleft40per">Application name should be atleast 3 characters long.</div>}
                        {errors.name && errors.name.type !== 'required' && <div className={`error marginleft40per ${errors.name.message && "dot"}`}>{errors.name.message}</div>}
                        {errors.name && errors.name.type === 'pattern' && <div className="error dot marginleft40per">Invalid application name.</div>}
                        {ipErrors?.custom && <div className={`error marginleft40per ${ipErrors?.custom && "dot"}`}>{ipErrors?.custom}</div>}

                        {fqdnErrors?.custom && <div className={`error marginleft40per ${fqdnErrors?.custom && "dot"}`}>{fqdnErrors?.custom}</div>}

                    </div>)
                }
            </div>

            <div className="add-edit-action-buttons known-application-action-buttons dialog-button">
            <button type={('button')}
                        onClick={onPanelClose}
                        className="button_styled ghost"
                    >Cancel</button>
                    <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? "loader" : "add-edit-submit")}>
                        Save
                    </button>
            </div>

            {/* <div className="pane_footer">
                <div className="launch_button">
                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled') + (disableTag || ipErrors?.custom ? ' disableItems' : '')}>Save</button>
                </div>
            </div> */}
        </form>
    )
}