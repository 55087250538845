import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import { RiskLevelData } from "../../../types/response-types";
import "./RiskChart.scss";
import { useHistory } from "react-router-dom";
import { getRiskLevelFromColor } from "../../../utils/risk-level";
import { getResolutionMatch, scrambleIt } from "../../../utils/util-methods";
import { getIssueNameById } from "../../../pages/playbooks/helpers/playbook-helper";

type Props = {
    chartData:
        | {issue_count: number, rule_name:string}[]
        | undefined
        | null;
    color: string | undefined | null;
    count: number | undefined | null;
};

function prepareChartData(data:any,color:string) {
    const objList = [];
    const top5 = data?.sort((a,b) => {
        if(a.issue_count < b.issue_count) return 1;
        if(a.issue_count > b.issue_count) return -1;
        return 0;
    }).slice(0,6);
    
    if (!top5 || (top5?.length || 0) < 0) {
        return objList;
    }

    const randomList = scrambleIt(top5);
    for(let i=0;i<6;i++){
        const {rule_name:name='', issue_count:y=0, entity_type= '' } = randomList[i] || {name: '',y: 0, entity_type:''};
        objList.push({name,y,color,entity_type});
  
    }
    // top5?.forEach((obj) => {
    //     objList.push({
    //         name: obj?.rule_name,
    //         color: color,
    //         y: obj?.issue_count,
    //     });
    // });
    return objList;
}

const RiskChart = ({ chartData, color, count, onBarClick }: Props) => {
    const [chartOptions, setChartOptions] = useState<any>(null);
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);
    
    useEffect(()=>{
        setChartOptions({
            chart: {
                // borderColor: '#EBBA95',
                // borderWidth: 2,
                spacingBottom: 0,
                spacingTop: 0,
                type: "column",
                renderTo: "chart",
                style: {
                    fontFamily: "Metropolis-Regular",
                },
                events: {
                    load: () => {
                        setTimeout(() => {
                            let containers = document.getElementsByClassName("risk-chart-container");
                            if (containers?.length > 0) {
                                chartRef.current?.chart.setSize(containers[0].clientWidth, containers[0].clientHeight, false);
                                chartRef.current.chart.update({
                                    plotOptions: {
                                        series:
                                        {
                                            pointWidth: (containers[0].clientWidth / 8)
                                        }
                                    }
                                });
                                
                            }
                        }, 100);
                    },
                },
            },
            tooltip: {
                shape: "rect",
                hideDelay: 100,
                positioner: function (w, h, p) {
                    return {
                        x: p.plotX+getResolutionMatch(-100,-50),
                        y: getResolutionMatch(80,40)
                    };
                },
                useHTML: true,
                padding: 10,
                shadow: false,
                borderWidth: 0,
                backgroundColor: "#ffffff00",
                formatter: function () {
                    const dd = this;
                    //<div class='risk-issue-percentage'><span>${ Math.round(this.y / (count||1) * 100)}%</span></div>
                    const percent = String(parseFloat(this.y / (count||1) * 100).toFixed(2)||'').replace('.00','');
                    const header = `
                    <div class='risk-trend-tooltip-container' style='background-color:${color};'>
                    
                    <div class='risk-issue-percentage'><span>${ percent }%</span></div>
                    <div class='risk-trend-label'><b>${this.y}</b>&nbsp;&nbsp;Issues | ${getIssueNameById(this.key)}</div>
                    </div>`;
                    return header;
                },
                style: {
                    zIndex: 100000
                }
            },
            credits: undefined,
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            subtitle: {
                text: "",
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                tickLength: 0,
                labels: {
                    enabled: false,
                },
            },
            yAxis: {
                gridLineColor: "#ffffff",
                type: "logarithmic",
                title: {
                    text: "",
                },
                labels: {
                    enabled: false,
                    tickIntervel: 100,
                },
            },
            plotOptions: {
                series: {
                    //animation: false,
                    groupPadding: 0,
                    pointPadding:-0.3,
                    cursor: 'pointer',
                    events: {
                        click: function (e) {
                                onBarClick(e.point.name,e.point?.entity_type);
                                }
                    }
                },
            },
            series: [
                {
                    data: prepareChartData(chartData,color)
                    // data: [
                    //     {
                    //         "name": "Shadow Assets",
                    //         "color": "#fa1262",
                    //         "y": 305
                    //     },
                    //     {
                    //         "name": "Shadow Directory",
                    //         "color": "#fa1262",
                    //         "y": 21
                    //     },
                    //     {
                    //         "name": "Exposed Assets",
                    //         "color": "#fa1262",
                    //         "y": 19
                    //     },
                    //     {
                    //         "name": "Shadow Access",
                    //         "color": "#fa1262",
                    //         "y": 6
                    //     },
                    //     {
                    //         "name": "Suspicious Outbound Access",
                    //         "color": "#fa1262",
                    //         "y": 3
                    //     }
                    // ]
                },
            ],
        });
    },[])

    return (
        <>
            <div className="risk-chart-container">
            <HighchartsReact 
                constructorType={"chart"} 
                ref={chartRef} 
                highcharts={Highcharts} 
                options={chartOptions} />
            </div>
        </>
    );
};

export default React.memo(RiskChart);
