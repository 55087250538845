export const ExceptionSearchFields = [
	'rule_name',
	's_name',
	'd_name',
	'd_protocol',
	'd_type',
	's_type',
	'dir_hostname',
	'd_ip',
	'd_port',
	's_port',
	'd_hostname',
	's_hostname',
	's_ip',
	'loc',
	't_protocol',
	'dir_names',
	'dst_category',
	'dir_category',
	'src_category'
];

export const ExceptionSearchFieldNames = {
	rule_name: 'Issue Applicable',
	s_name: 'Identity Name',
	d_name: 'Asset Name',
	d_protocol: 'Protocols',
	d_type: 'Destination Type',
	s_type: 'Source Type',
	dir_hostname: 'Directory Hostnames',
	d_ip: 'Destination IP',
	d_port: 'Destination Port',
	s_port: 'Source Port',
	d_hostname: 'Destination Hostname',
	s_hostname: 'Source Hostname',
	s_ip: 'Source IP',
	loc: 'Sensor Site Codes',
	t_protocol: 'Transport Protocols',
	rule_names: 'Issues Applicable',
	src_ips: 'Source Ip',
	src_port: 'Source Port',
	src_hostnames: 'Source Hostnames',
	src_type: 'Source Type',
	dst_ips: 'Destination Ip',
	dst_port: 'Destination Port',
	dst_hostnames: 'Destination Hostnames',
	dst_type: 'Destination Type',
	sensor_locations: 'Sensor Site Codes',
	protocols: 'Protocols',
	transport_protocol: 'Transport Protocol',
	dir_hostnames: 'Directory Hostnames',
	s_names: 'Identity Name',
	d_names: 'Asset Name',
	inc_count: 'Incident Count',
	dir: 'Directory',
	dir_names: 'Directory Name',
	src_ports: 'Source Ports',
	dst_ports: 'Destination Ports',
	dst_category: 'Destination Category',
	dir_category: 'Directory Category',
	src_category: 'Source Category'
};
