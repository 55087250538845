import React, { ReactNode } from 'react';
import './sensorPopup.scss';
import Popup from 'reactjs-popup';

interface Props {
    classNames?: string;
    title: string;
    onCloseHandler: (state: boolean) => void;
    children: ReactNode;
    isOpen: boolean;
    zIndexCustom?: any;
    logoClass?: string;
    subTitle?: string;
    guideLink?: string;
}

export const SensorPopup = ({
    classNames,
    title,
    onCloseHandler,
    children,
    isOpen,
    zIndexCustom,
    logoClass,
    subTitle,
    guideLink
}: Props) => {
    return (
        <Popup
            position='right center'
            closeOnDocumentClick={false}
            overlayStyle={{
                zIndex: zIndexCustom ? zIndexCustom : 15001,
                background: 'rgba(227, 242, 253, .6)'
            }}
            open={isOpen}
            closeOnEscape={false}
            modal
        >
            <div className={`${classNames} popup-container`}>
                <div className='popup-container-header'>
                    <div className='header' >
                        <div className='header-left-group'>
                            <div
                                className={`${
                                    logoClass
                                        ? logoClass?.toLowerCase()
                                        : 'default-logo'
                                } header-left-group-img`}
                            ></div>
                            <div className='header-title-container'>
                                <div className='header-title' title={title}>{title}</div>
                                <div className='header-subtitle text-ellipsis'>
                                    {subTitle}{' '}
                                    {guideLink && (
                                        <a
                                            className='link-text'
                                            href={guideLink}
                                            target='_blank'
                                            style={{ paddingLeft: '5px' }}
                                        >
                                            Learn More
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div
                            onClick={() => onCloseHandler(false)}
                            className='popup-close'
                        ></div>
                </div>
                <div className='popup-container-body'>{children}</div>
            </div>
        </Popup>
    );
};
