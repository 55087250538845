import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { SuspectedDirBotAttackState } from "../../../../types/playbooks-config";



export const SuspectedDirectoryBot = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SuspectedDirBotAttackState>) => {
    const [state, setState] = useState<SuspectedDirBotAttackState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleSeparateHost = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            dir_params: {
                ...state.dir_params, user_auth_fail_nhosts_today: parseInt(e.target.value)
            }
        })
    }

    const handleAuthFail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { ...state.dir_params, user_auth_fail_count_today: parseInt(e.target.value) } })
    }

    useEffect(() => {
        /*  if (getExpression) {
             getExpression(JSON.parse(JSON.stringify(state?.dir_params)))
         } */
    })

    return <>
        <form className="suspected-dir-bot-container">
            <div className="suspected-dir-bot-row bold-text">
                <label className="playbook-config-title marginBottom0">
                    A directory or IdP will be marked as under a bot/botnet attack when:</label>
            </div>
            <div className="suspected-dir-bot-row">
                <label>
                    Number of separate hosts used by an account in a day is more than
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_auth_fail_nhosts_today}</label> :
                        <input type="number" onChange={handleSeparateHost} name="num_sep_host" value={state?.dir_params?.user_auth_fail_nhosts_today} />}
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }} className="font14">AND</div>
            <div className="suspected-dir-bot-row">
                <label>
                    Number of authentication failures within a day for the account crosses
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_auth_fail_count_today}</label> :
                        <input type="number" onChange={handleAuthFail} name="num_fail_per_day" value={state?.dir_params?.user_auth_fail_count_today} />
                }
            </div>
        </form>
    </>
}