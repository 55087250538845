export const SuspiciousIcon = ({ color = "" }) => {
    return (
        // <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
        //     <path
        //         style={{ fill: color }}
        //         d="m50.04,66.52c-1.7-1.63-2.68-3.82-2.75-6.18-.06-2.37.8-4.6,2.42-6.3,1.63-1.71,3.82-2.69,6.18-2.75h.23c.41,0,.83.02,1.26.08,1.79.26,3.46,1.06,4.81,2.34,1.71,1.62,2.69,3.81,2.74,6.16.07,2.35-.79,4.6-2.41,6.32-1.62,1.7-3.82,2.68-6.18,2.74h-.23c-.4,0-.82-.02-1.25-.08-1.81-.26-3.47-1.06-4.82-2.33Zm7.05-39.89c-9.01,6.72-19.38,10.29-30.05,10.32l-.02.28c.04,13.7,3.72,26.61,10.13,37.04l5.29-5.27c-1.65-2.52-2.56-5.45-2.63-8.47-.12-4.36,1.48-8.51,4.47-11.68,3.01-3.16,7.06-4.96,11.42-5.08h.37c.82,0,1.6.05,2.37.16,3.37.47,6.46,1.97,8.93,4.32,3.17,2.99,4.98,7.05,5.08,11.42.12,4.36-1.47,8.51-4.47,11.66s-7.06,4.98-11.42,5.08h-.43c-.79,0-1.57-.05-2.31-.15-2.11-.3-4.1-1-5.94-2.07l-6.34,6.29c4.39,5.43,9.64,9.92,15.57,13.07,18.24-9.73,30.02-31.79,30.07-56.33l-.02-.29c-10.67-.03-21.04-3.6-30.05-10.32Zm57.63,30.82c0,31.64-25.63,57.27-57.26,57.27S.19,89.09.19,57.45,25.84.19,57.46.19s57.26,25.63,57.26,57.26Zm-19.87-20.4l-.7-8.66-7.08.87c-9.68-.06-19.04-3.57-27.14-10.17l-2.84-2.85-2.85,2.87c-8.04,6.57-17.4,10.09-27.13,10.16l-7.07-.87-.7,8.4v.29c-.01,15.85,4.46,30.8,12.22,42.74l-8.23,8.17,5.44,5.17,7.28-7.22c5.07,6.14,11.15,11.19,18.03,14.75l.08.03,2.92,1.13,2.59-.98.4-.19c21.11-10.91,34.77-35.85,34.78-63.57v-.07Z"
        //     />
        // </svg>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
            <path
                style={{ fill: color }}
                d="m50.04,66.52c-1.7-1.63-2.68-3.82-2.75-6.18-.06-2.37.8-4.6,2.42-6.3,1.63-1.71,3.82-2.69,6.18-2.75h.23c.41,0,.83.02,1.26.08,1.79.26,3.46,1.06,4.81,2.34,1.71,1.62,2.69,3.81,2.74,6.16.07,2.35-.79,4.6-2.41,6.32-1.62,1.7-3.82,2.68-6.18,2.74h-.23c-.4,0-.82-.02-1.25-.08-1.81-.26-3.47-1.06-4.82-2.33Zm7.05-39.89c-9.01,6.72-19.38,10.29-30.05,10.32l-.02.28c.04,13.7,3.72,26.61,10.13,37.04l5.29-5.27c-1.65-2.52-2.56-5.45-2.63-8.47-.12-4.36,1.48-8.51,4.47-11.68,3.01-3.16,7.06-4.96,11.42-5.08h.37c.82,0,1.6.05,2.37.16,3.37.47,6.46,1.97,8.93,4.32,3.17,2.99,4.98,7.05,5.08,11.42.12,4.36-1.47,8.51-4.47,11.66s-7.06,4.98-11.42,5.08h-.43c-.79,0-1.57-.05-2.31-.15-2.11-.3-4.1-1-5.94-2.07l-6.34,6.29c4.39,5.43,9.64,9.92,15.57,13.07,18.24-9.73,30.02-31.79,30.07-56.33l-.02-.29c-10.67-.03-21.04-3.6-30.05-10.32Zm57.63,30.82c0,31.64-25.63,57.27-57.26,57.27S.19,89.09.19,57.45,25.84.19,57.46.19s57.26,25.63,57.26,57.26Zm-19.87-20.4l-.7-8.66-7.08.87c-9.68-.06-19.04-3.57-27.14-10.17l-2.84-2.85-2.85,2.87c-8.04,6.57-17.4,10.09-27.13,10.16l-7.07-.87-.7,8.4v.29c-.01,15.85,4.46,30.8,12.22,42.74l-8.23,8.17,5.44,5.17,7.28-7.22c5.07,6.14,11.15,11.19,18.03,14.75l.08.03,2.92,1.13,2.59-.98.4-.19c21.11-10.91,34.77-35.85,34.78-63.57v-.07Z"
            />
        </svg>
    );
};
