import React from "react";
import AMTabs, { TabData } from "../../components/core/AMTab/AMTab";
import Collectors from "./collectors";
import Sensors from "./sensor";
import { Box } from "@mui/material";
import SupportedCollector from "./supportedCollector";
import ManageCollector from "./manageCollector";


const EOD: React.FC = () => {
  const tabs: TabData[] = [
    {
        label: "DEPLOYMENT SUMMARY",
        Component: <ManageCollector></ManageCollector>,
        route: "/deploy/manageCollector",
        selected: false
      },
      {
        label: "COLLECTOR STATUS",
        Component: <Collectors></Collectors>,
        route: "/deploy/collector",
        selected: false
      },
    {
      label: "MANAGE SENSORS & SIEM",
      Component: <Sensors></Sensors>,
      route: "/deploy/sensor",
      selected: false
    },
   
    {
        label: "SUPPORTED COLLECTORS & SIEM",
        Component: <SupportedCollector></SupportedCollector>,
        route: "/deploy/supportedCollector",
        selected: false
      },
  ];

  const tabClick =(val:string)=>{
    sessionStorage.removeItem('sensor');
  }

  return (
    <Box style={{marginTop:'20px'}}>
      <AMTabs tabs={tabs} onTabClick={tabClick} />
    </Box>
  );
};

export default EOD;