import React, { forwardRef, useState } from "react"
import './AMPasswordInput.css';

export const AMPasswordInput = forwardRef((props: any, ref: any) => {
    const [inputType, setInputType] = useState<string>('password');
    const [errors, setErrors] = useState<any>();
    const [password, setPassword] = useState<any>();

    const manageEyeClick = (e: any) => {
        const isToggle = Array.from(e.currentTarget.classList).find((i: any) => i == 'eye-open-ampwdin');
        if (!isToggle) {
            e.currentTarget.classList.add('eye-open-ampwdin');
            setInputType('text');
        } else {
            e.currentTarget.classList.remove('eye-open-ampwdin');
            setInputType('password');
        }
    }

    /*     const onChange = (evt: any) => {
            if (props.onChange) {
                props.onChange(evt);
            }
            setPassword(evt.target.value);
            setErrors({});
        } */

    const onValidityCheck = (error: any) => {
        if (!password || (password && password.length < 0)) {
            setErrors({ password: { type: 'required' } });
            return { password: { type: 'required' } };
        } else {
            setErrors({});
        }
        setPassword('');
        return null;
    }

    return <>
        <div className='ampwdin'>
            <input {...props} type={inputType} autoComplete="off" ref={ref} />
            <i className="eye-close-ampwdin" onClick={manageEyeClick}></i>
            {props?.errors?.required && <div className="error marginleft40per">Please enter password.</div>}
            {props?.errors?.custom && <div className="error marginleft40per"> {props?.errors?.custom}</div>}
        </div>
    </>
})