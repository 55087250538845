import React from 'react';

const HashSecurityConfigView = ({ config }) => {
    const renderAlgoValue = () => {
        let res = [];
        config.algoValue?.forEach((algo) => {
            if (algo.isChecked) {
                res.push(algo.name);
            }
        });
        return res.join(', ');
    };

    return (
        <>
            {config.algoFlag && (
                <li>
                    {/* <strong>Parameters</strong> - */}
                    <span>
                        Authentication quality will be considered poor if weak
                        Hash algorithms <strong>{renderAlgoValue()}</strong> are
                        been used
                    </span>
                </li>
            )}
        </>
    );
};

export default React.memo(HashSecurityConfigView);
