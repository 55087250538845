import React from 'react'
import { Header } from './Header'
import { SnackbarProvider } from 'notistack';
import './index.css'

type Props = {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <Header />
        {children}
      </SnackbarProvider>
    </>
  )
}