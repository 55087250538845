import React, { useEffect } from 'react';

export const CrowdStrikeCollector = ({
    register,
    setValue,
    collectorData
}) => {
    useEffect(() => {
        if (collectorData) {
            setValue(
                'client_id',
                collectorData.collector_config?.client_id
            );
            setValue(
                'secret',
                collectorData.collector_config?.secret
            );
            setValue(
                'base_url',
                collectorData.collector_config?.base_url
            );
        }
    }, [collectorData]);

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
            <div className='form_sub_section_header'>
                        CrowdStrike Configuration
                    </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Client ID*</label>
                            <input
                                type='text'
                                name='client_id'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Crowdstrike Client Id'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Secret*</label>
                            <input
                                type='text'
                                name='secret'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Crowdstrike Secret'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Base URL*</label>
                            <input
                                type='text'
                                name='base_url'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Crowdstrike Base Url'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
