import { PlaybookTableHeaders } from "../constants/Constants";
import { CustomConfig } from "../constants/CustomPlaybooks"
import { loadDefaultPlaybook } from "../constants/DefaultPlaybooks";
import { loadDefaultPlaybookViewOnly } from "../constants/DefaultPlaybooksViewOnly";

export const usePlaybookConfig = () => {

    const loadCustomConfigByRuleType = (ruleType: string, getConfigStateFromChild: any, parentState: any, getExpressionFromChild?: any, formType?: string, data?: any) => {
        return ruleType in CustomConfig ? CustomConfig[ruleType as keyof typeof CustomConfig].loadComponent(getConfigStateFromChild, parentState, getExpressionFromChild, formType, data) : null;
    }

    const updateRequestBodyBeforeSend = (ruleType: string, payload: any) => {
        /* Hook to transform data before sending to backend */
        return ruleType in CustomConfig ? CustomConfig[ruleType as keyof typeof CustomConfig].updatePayload(payload) : payload;
    }

    const loadTableHeaders = (ruleType: string, formType?: string) => {
        return ruleType in CustomConfig ? CustomConfig[ruleType as keyof typeof CustomConfig].loadTableHeaders(formType || '') : PlaybookTableHeaders;
    }

    const updateResponseBodyBeforeLoad = (ruleType: string, payload: any, isHeader: boolean, config?: any) => {
        return ruleType in CustomConfig ? CustomConfig[ruleType as keyof typeof CustomConfig].decodePayload(payload, isHeader, config) : payload;
    }

    const loadDefaultPlaybookConfigByRuleType = (ruleType: string, props: any) => {
        return loadDefaultPlaybook(ruleType, props);
    }

    const convertCsvData = (ruleType: string, payload: any[]) => {
        return ruleType in CustomConfig ? CustomConfig[ruleType as keyof typeof CustomConfig].convertCsvData(payload) : payload;
    }

    const loadDefaultPlaybookConfigViewByRuleType = (ruleType: string, props: any) => {
        return loadDefaultPlaybookViewOnly(ruleType, props.rules, props?.params);
    }

    return {
        loadCustomConfigByRuleType,
        updateRequestBodyBeforeSend,
        loadTableHeaders,
        updateResponseBodyBeforeLoad,
        loadDefaultPlaybookConfigByRuleType,
        convertCsvData,
        loadDefaultPlaybookConfigViewByRuleType
    }
}