export const CompromisedIcon = ({ color = "" }) => {
    return (
        // <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
        //     <path
        //         style={{ fill: color }}
        //         d="m57.44,86.57c-1.65,0-3.07-.59-4.24-1.75-1.16-1.16-1.75-2.59-1.75-4.24s.59-3.07,1.75-4.24c1.16-1.16,2.59-1.75,4.24-1.75s3.07.59,4.25,1.75c1.17,1.16,1.77,2.59,1.77,4.24s-.59,3.05-1.77,4.22c-1.17,1.17-2.6,1.77-4.25,1.77Z"
        //     />
        //     <rect style={{ fill: color }} x="52.43" y="33.67" width="10.02" height="33.62" />
        //     <rect style={{ fill: color }} x="54.43" y="35.67" width="6.02" height="29.62" />
        //     <path
        //         style={{ fill: color }}
        //         d="m53.87,108.56l-.1-.05C27.88,95.12,11.16,64.51,11.16,30.53v-.35s.86-10.3.86-10.3l8.68,1.07c11.92-.08,23.39-4.39,33.26-12.46l3.49-3.51,3.49,3.51c9.92,8.1,21.4,12.39,33.26,12.46l8.68-1.07.86,10.62v.08c-.01,33.98-16.75,64.58-42.64,77.95l-.5.22-3.17,1.2-3.59-1.39ZM20.59,30.72c.08,30.12,14.52,57.15,36.87,69.07,22.35-11.93,36.81-38.97,36.87-69.07l-.03-.35c-13.08-.04-25.8-4.41-36.84-12.65-11.04,8.24-23.76,12.61-36.84,12.65l-.03.35Z"
        //     />
        //     <path
        //         style={{ fill: color }}
        //         d="m54.69,106.73C29.46,93.68,13.16,63.78,13.16,30.53v-.27s.69-8.15.69-8.15l6.74.83h.07c12.38-.06,24.35-4.53,34.61-12.94l.04-.03,2.14-2.16,2.15,2.16.04.03c10.27,8.41,22.24,12.89,34.62,12.94h.07s6.74-.83,6.74-.83l.69,8.46c-.01,33.24-16.32,63.14-41.55,76.17l-.34.15-2.4.91-2.77-1.07ZM18.59,30.64v.05c.05,31.04,15.11,58.95,38.38,71.11l.49.26.49-.26c23.26-12.15,38.32-40.07,38.37-71.11v-.04l-.2-2.4-1.68.13c-12.92,0-25.49-4.39-36.35-12.67l-.65-.49-.65.49c-10.86,8.28-23.43,12.66-36.35,12.67l-1.68-.13-.2,2.4Z"
        //     />
        // </svg>
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.91 114.91">
            <path
                style={{ fill: color }}
                d="m57.46.19C25.83.19.2,25.83.2,57.45s25.63,57.27,57.26,57.27,57.26-25.63,57.26-57.27S89.08.19,57.46.19Zm38.45,36.93c-.01,27.72-13.66,52.67-34.77,63.57l-.41.19-2.59.98-2.92-1.13-.08-.03c-21.11-10.92-34.75-35.89-34.75-63.61v-.29l.7-8.4,7.08.87c9.72-.07,19.07-3.59,27.13-10.16l2.84-2.87,2.85,2.85c8.09,6.61,17.45,10.11,27.13,10.17l7.08-.87.7,8.66v.07Zm-37.76-10.49c-9,6.72-19.38,10.29-30.04,10.32l-.02.28c.06,24.57,11.84,46.62,30.06,56.34,18.24-9.73,30.03-31.79,30.07-56.33l-.02-.29c-10.67-.03-21.04-3.6-30.05-10.32Zm-4.09,13.02h8.17v27.42h-8.17v-27.42Zm7.55,41.7c-.96.96-2.12,1.44-3.47,1.44s-2.51-.48-3.45-1.42c-.94-.96-1.43-2.12-1.43-3.46s.49-2.51,1.43-3.45c.94-.96,2.11-1.43,3.45-1.43s2.52.48,3.47,1.42c.96.96,1.43,2.12,1.43,3.46s-.48,2.48-1.43,3.44Z"
            />
        </svg>
    );
};
