import { Box, Hidden, Tooltip } from "@mui/material";
import moment from "moment";
import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  withQueryParams,
} from "use-query-params";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";
import AMAdminTable, {
  ITableResult,
  ITableColumn,
  ITableAction,
  ITableReference,
} from "../../components/core/AMTable/AMAdminTable";
import {
  convertToCommaValue,
  formatDate,
  isAmopsUser,
} from "../../utils/util-methods";
import InfoIcon from "@mui/icons-material/Info";
import { useAuthDetails } from "../../components/Authorization";
import {
  AMAdminPopUp,
  AMAdminSearchTable,
  IAMAdminSearchTableRef,
} from "../ref/AMAdminCommonComponents";
import AMTabs from "../../components/core/AMTab/AMTab";
import AMTableEmpty from "../../components/core/AMTable/AMAdminTableEmpty";
import { SensorPopup } from "./SensorPopup";
import { FlowSensorContainer } from "./sub/FlowSensorContainer";
import {
  FLOW_SENSOR,
  POPUP_SUB_TITLE_META,
  SIEM_VENDOR,
} from "./constants/sensor-labels";
import { SiemConfiguration } from "./sub/SiemConfiguration";
import { CollectorConfigurationContainer } from "./sub/CollectorConfigurationContainer";
import { getPopupSubtitle } from "./SensorUtils";
import { Opacity } from "@mui/icons-material";

interface CollectorCollection {
  _id: string;
  category: string;
  collection_method: any;
  collector_config: any | null;
  collector_data_source: string;
  collector_sensor_id: string;
  collectors_count: number;
  created_date: string; // Date string format
  flows: number;
  last_flow_time: string; // Date string format
  last_heart_beat: string; // Date string format
  modified_date: string; // Date string format
  name: string;
  sensor_collector_key: string;
  site_code: string;
  vendor_name: string;
}

interface IPopUp {
  type: string;
  data: CollectorCollection | any;
}

const is4K = window.matchMedia("(min-width: 2560px)").matches ? 2 : 1;

const AnimatedDots = () => {
  return (
    <div className="animated-dots">
      <span className="dots">.</span>
    </div>
  );
};

const renderHeartbeatFlow = (data: any) => {
  if (
    data.fs_last_heart_beat &&
    moment(data.fs_last_heart_beat).isBefore("2021-01-01T00:00:00Z") && !data.sensor_settings
  ) {
    return (
      <div className="heartbeat_flow" title="Logs will flow < 15 minutes">
        {"Logs will flow in < 15 minutes"}
        {/* <div style={{ float: "left", marginRight: "3px" }}>Configuring</div>{" "}
        <AnimatedDots /> */}
    </div>
    );
  } else if (
    data.last_flow_time &&
    moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z")
  ) {
    return (
      <div className="heartbeat_flow">{formatDate(data.last_flow_time)}</div>
    );
  } else {
    return (
      <div className="heartbeat_flow" title="No Flows Collected Yet">
        {"No Flows Collected Yet"}
      </div>
    );
  }
};

const renderHeartbeatDot = (data: any) => {
  if (
    data.fs_last_heart_beat &&
    moment(data.fs_last_heart_beat).isBefore("2021-01-01T00:00:00Z") && !data.sensor_settings
  ) {
    return (
      <span
        style={{
          position: "relative",
          transform: "translateX(-50%)",
          fontSize: "30px",
          color: "#CCC",
        }}
      >
        &#9679;
      </span>
    );
  } else if (data.last_flow_time) {
    const hoursDifference = moment
      .duration(moment().diff(moment(data.last_flow_time)))
      .asHours();
    if (hoursDifference < 1) {
      return (
        <span
          style={{
            position: "relative",
            transform: "translateX(-50%)",
            fontSize: "30px",
          }}
          className="heartbeat_green_dot"
        >
          &#9679;
        </span>
      );
    } else if (hoursDifference >= 1 && hoursDifference < 2) {
      return (
        <span
          style={{
            position: "relative",
            transform: "translateX(-50%)",
            fontSize: "30px",
          }}
          className="heartbeat_orange_dot"
        >
          &#9679;
        </span>
      );
    } else {
      return (
        <span
          style={{
            position: "relative",
            transform: "translateX(-50%)",
            fontSize: "30px",
          }}
          className="heartbeat_red_dot"
        >
          &#9679;
        </span>
      );
    }
  } else {
    return (
      <span
        style={{
          position: "relative",
          transform: "translateX(-50%)",
          fontSize: "30px",
        }}
        className="heartbeat_red_dot"
      >
        &#9679;
      </span>
    );
  }
};

const CollectorsList = ({ query, setQuery }: any) => {
  const history = useHistory();
  const adchildRef = useRef<ITableReference>();
  const networkchildRef = useRef<ITableReference>();
  const onpremchildRef = useRef<ITableReference>();
  const [addata, setAddata] = useState<
    ITableResult<CollectorCollection | any> | any
  >();
  const [networkdata, setNetworkdata] = useState<
    ITableResult<CollectorCollection | any> | any
  >();
  const [onpremdata, setOnpremdata] = useState<
    ITableResult<CollectorCollection | any> | any
  >();
  const [showPopUp, setShowPopUp] = useState<IPopUp>({ type: "", data: null });
  const { addToast } = useToasts();
  const { authDetails } = useAuthDetails()!;
  const searchRef = useRef<IAMAdminSearchTableRef>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [showFlowSensor, setShowFlowSensor] = useState(false);
  const [showSiemVendor, setShowSiemVendor] = useState(false);
  const [showCollectorConfiguration, setShowCollectorConfiguration] =
    useState(false);

  const handleClose = () => {
    setShowPopUp({ type: "", data: null });
  };

  const navigateToCollectors = (data: CollectorCollection) => {
    sessionStorage.setItem(
      "sensor",
      JSON.stringify({
        id: data?.collector_sensor_id,
        name: data?.collection_method?.label,
        typedata: "manageSensor",
        data_src: data.collector_data_source,
      })
    );
    history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=20`);
  };

  const CollectorTableColumns: ITableColumn<CollectorCollection>[] = [
    {
      headerText: "Collector Type",
      key: "collector_data_source",
      classes: "align_left ellipsis",
      sortable: true,
      html: (data: CollectorCollection) => {
        return (
          <div>
            <div
              className={
                data.vendor_name.replace(/\s+/g, "_").toLowerCase() +
                " collector-logo logo-size"
              }
            ></div>
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.collector_data_source}
            >
              <div className="text-ellipsis vendor-name">
                {data.collector_data_source}
              </div>
            </Tooltip>
          </div>
        );
      },
      styles: {
        width: is4K * 325,
      },
    },
    {
      headerText: "Collector Name",
      key: "name",
      classes: "align_left ellipsis vert_middle",
      sortable: true,
      html: (data: CollectorCollection) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis">
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.name}
            >
              <div
                style={{
                  width: `${is4K * 190}px`,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {data.name}
              </div>
            </Tooltip>
          </div>
        );
      },
      styles: {
        width: is4K * 200,
      },
    },
    {
      headerText: "Collection Method",
      key: "collection_method",
      sortable: true,
      classes: "align_left vert_middle",
      html: (data: CollectorCollection) => {
        return (
          <Tooltip
            classes={{ tooltip: "generic-tooltip-popper" }}
            title={data.collection_method.label}
          >
            <div
              className="heartbeat_flow text-ellipsis"
              style={{ marginTop: "-2px", minWidth: "50px", width: "120px" }}
            >
              {data.collection_method.label}
            </div>
          </Tooltip>
        );
      },
      styles: {
        width: is4K * 120,
      },
    },
    {
      headerText: "Site Code",
      key: "site_code",
      classes: "align_left  vert_middle",
      sortable: true,
    },
    {
      headerText: "Collectors",
      key: "collectors_count",
      classes: "vert_middle ",
      sortable: true,
      html: (data: CollectorCollection) => {
        return data.collectors_count > 0 ? (
          <a
            className="shadowbox"
            onClick={() => {
              navigateToCollectors(data);
            }}
          >
            {data.collectors_count}
          </a>
        ) : (
          <span> {data.collectors_count}</span>
        );
      },
    },
    {
      headerText: "Status",
      key: "last_flow_time",
      sortable: false,
      classes: "align_center vert_middle",
      html: (data: CollectorCollection) => {
        return renderHeartbeatDot(data);
      },
      styles: {
        width: is4K * 50,
      },
    },
    {
      headerText: "Flows",
      key: "flows",
      sortable: true,
      classes: "vert_middle",
      html: (data: CollectorCollection) => {
        return <span>{convertToCommaValue(data.flows)}</span>;
      },
    },
    {
      headerText: "Latest Flow",
      key: "last_flow_time",
      sortable: true,
      classes: "align_left vert_middle",
      html: (data: CollectorCollection) => {
        return renderHeartbeatFlow(data);
      },
      styles: {
        width: is4K * 150,
      },
    },
  ];

  const NetworkCollectorTableColumns: ITableColumn<CollectorCollection>[] = [
    {
      headerText: "Collector Type",
      key: "collector_data_source",
      classes: "align_left ellipsis",
      sortable: true,
      html: (data: CollectorCollection) => {
        return (
          <div>
            <div
              className={
                data.vendor_name.replace(/\s+/g, "_").toLowerCase() +
                " collector-logo logo-size"
              }
            ></div>

            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.collector_data_source}
            >
              <div className="text-ellipsis vendor-name">
                {data.collector_data_source}
              </div>
            </Tooltip>
          </div>
        );
      },
      styles: {
        width: is4K * 325,
      },
    },

    {
      headerText: "Collector Name",
      key: "name",
      classes: "align_left ellipsis vert_middle",
      sortable: true,
      html: (data: CollectorCollection) => {
        return (
          <div style={{ display: "flex" }} className="ellipsis">
            <Tooltip
              classes={{ tooltip: "generic-tooltip-popper" }}
              title={data.name}
            >
              <div
                style={{
                  width: `${is4K * 190}px`,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {data.name}
              </div>
            </Tooltip>
          </div>
        );
      },
      styles: {
        width: is4K * 200,
      },
    },
    {
      headerText: "Collection Method",
      key: "collection_method",
      sortable: true,
      classes: "align_left vert_middle",
      html: (data: CollectorCollection) => {
        return (
          <Tooltip
            classes={{ tooltip: "generic-tooltip-popper" }}
            title={data.collection_method.label}
          >
            <div
              className="heartbeat_flow text-ellipsis"
              style={{ marginTop: "-2px", minWidth: "50px", width: "120px" }}
            >
              {data.collection_method.label}
            </div>
          </Tooltip>
        );
      },
      styles: {
        width: is4K * 120,
      },
    },
    {
      headerText: "Site Code",
      key: "site_code",
      classes: "align_left  vert_middle",
      sortable: true,
    },
    {
      headerText: "Collectors",
      key: "collectors_count",
      classes: "vert_middle ",
      html: (data: CollectorCollection) => {
        return data.collectors_count > 0 ? (
          <a
            className="shadowbox"
            onClick={() => {
              navigateToCollectors(data);
            }}
          >
            {data.collectors_count}
          </a>
        ) : (
          <span> {data.collectors_count}</span>
        );
      },
    },
    {
      headerText: "Status",
      key: "last_flow_time",
      sortable: false,
      classes: "align_center vert_middle",
      html: (data: CollectorCollection) => {
        return renderHeartbeatDot(data);
      },
      styles: {
        width: is4K * 50,
      },
    },
    {
      headerText: "Flows",
      key: "flows",
      sortable: true,
      classes: "vert_middle",
      html: (data: CollectorCollection) => {
        return <span>{convertToCommaValue(data.flows)}</span>;
      },
    },
    {
      headerText: "Latest Flow",
      key: "last_flow_time",
      sortable: true,
      classes: "align_left vert_middle",
      html: (data: CollectorCollection) => {
        return renderHeartbeatFlow(data);
      },
      styles: {
        width: is4K * 150,
      },
    },
  ];

  const CollectorActions: ITableAction<CollectorCollection | unknown>[] = [
    {
      actionId: "edit-collector-name",
      actionLabel: "Manage",
      shouldDisplay: (item: any) => {
        return !(
          item.collector_data_source === "Microsoft AD (AuthMind Sensor)" ||
          item.collector_data_source === "Microsoft AD-LDS (AuthMind Sensor)"
        );
      },
    },
    {
      actionId: "view-collector",
      actionLabel: "View Collectors",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "delete-collector",
      actionLabel: "Delete",
      shouldDisplay: (item: any) => {
        return !(
          item.collector_data_source === "Microsoft AD (AuthMind Sensor)" ||
          item.collector_data_source === "Microsoft AD-LDS (AuthMind Sensor)"
        );
      },
    },
  ];

  const CollectorNWActions: ITableAction<CollectorCollection | unknown>[] = [
    {
      actionId: "edit-collector-name",
      actionLabel: "Manage",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "view-collector",
      actionLabel: "View Collectors",
      shouldDisplay: (item: any) => true,
    },
    {
      actionId: "delete-collector",
      actionLabel: "Delete",
      shouldDisplay: (item: any) => true,
    },
  ];

  const proceedDelete = () => {
    const headers = { Operation: "DELETE" };
    Api.post(
      `/collectors/managed/${showPopUp.data._id}`,
      {
        collector_sensor_id: showPopUp.data.collector_sensor_id,
      },
      { headers }
    )
      .then((res: { data: any }) => {
        setQuery({ ...query, hard_refresh: "true" });
        afterDeleteCollectorOperation(showPopUp.data.category);
        handleClose();
        addToast("Collecter has been deleted successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const proceedUpdate = (evt?: any) => {
    const headers = { Operation: "PUT" };
    if (evt.key && evt.key != "Enter") {
      return;
    }

    if (evt.key && evt.key == "Enter") {
      evt.preventDefault();
    }

    Api.post(
      "/sensors/collectors",
      {
        sensor_id: showPopUp.data.collector_sensor_id,
        collector_id: showPopUp.data.collector_id,
        collector_name: inputRef.current?.value,
      },
      { headers }
    )
      .then((res: { data: any }) => {
        handleClose();
        addToast("Collecter has been updated successfully.", {
          appearance: "success",
          autoDismiss: true,
        });
        setQuery({ ...query, hard_refresh: "true" });
        // fetchCollectorList();
      })
      .catch((error: any) => {
        handleError(error);
      });
  };

  const deletePopUpButtons = {
    primary: {
      label: "Yes",
      action: proceedDelete,
    },
    secondary: {
      label: "No",
      action: handleClose,
    },
  };

  const updatePopUpButtons = {
    primary: {
      label: "Save",
      action: proceedUpdate,
    },
    secondary: {
      label: "Cancel",
      action: handleClose,
    },
  };

  const onSubmit = (data: any) => {
    // if (childRef?.current?.onSearch) {
    //   sessionStorage.removeItem("sensor");
    //   childRef?.current?.onSearch(data);
    // }
  };

  const fetchAdCollectorList = () => {
    Api.get(`/collectors/managed?category=ad-cloud-idp`, { params: query })
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;
        setAddata(data);
      })
      .catch(() =>
        adchildRef?.current?.onSetTableError(
          "Error occurred while fetching table data."
        )
      );
  };

  const fetchNetworkCollectorList = () => {
    Api.get(`/collectors/managed?category=network-access`, { params: query })
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;

        if (isAmopsUser(authDetails.email)) {
          setNetworkdata(resp.data);
        } else {
          const filteredResult = resp.data.result.filter(
            (item: any) =>
              item.collector_data_source !== "Evernorth Control Center"
          );
          const filteredData = {
            ...resp.data,
            result: filteredResult,
          };
          setNetworkdata(filteredData);
        }
      })
      .catch(() =>
        networkchildRef?.current?.onSetTableError(
          "Error occurred while fetching table data."
        )
      );
  };

  const fetchOnPremCollectorList = () => {
    Api.get(`/collectors/managed?category=cloud-infra`, { params: query })
      .then((resp: any) => {
        const data = resp.data as ITableResult<CollectorCollection>;
        setOnpremdata(data);
      })
      .catch(() =>
        onpremchildRef?.current?.onSetTableError(
          "Error occurred while fetching table data."
        )
      );
  };

  CollectorActions[2].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    setShowPopUp({ data: i, type: "DeleteCollector" });
  };

  CollectorActions[1].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    sessionStorage.setItem(
      "sensor",
      JSON.stringify({
        id: i?.collector_sensor_id,
        name: i?.collection_method?.label,
        typedata: "manageSensor",
        data_src: i.collector_data_source,
      })
    );
    history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=20`);
  };

  CollectorActions[0].actionCallback = function (
    i: CollectorCollection | any,
    e: any
  ) {
    setShowPopUp({ data: i, type: "UpdateCollector" });
  };

  useEffect(() => {
    if (
      onpremdata?.result?.length === 0 &&
      networkdata?.result?.length === 0 &&
      addata?.result?.length === 0
    ) {
      setHideDiv(false);
    }
  }, [onpremdata, networkdata, addata]);

  CollectorNWActions[2].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    setShowPopUp({ data: i, type: "DeleteCollector" });
  };

  CollectorNWActions[1].actionCallback = function (
    i: CollectorCollection | unknown,
    e: any
  ) {
    sessionStorage.setItem(
      "sensor",
      JSON.stringify({
        id: i?.collector_sensor_id,
        name: i?.collection_method?.label,
        typedata: "manageSensor",
        data_src: i.collector_data_source,
      })
    );
    history.push(`/deploy/collector?order_by=asc&sort_by=name&rpp=20`);
  };

  CollectorNWActions[0].actionCallback = function (
    i: CollectorCollection | any,
    e: any
  ) {
    setShowPopUp({ data: i, type: "UpdateCollector" });
  };

  useEffect(() => {
    fetchAdCollectorList();
    fetchNetworkCollectorList();
    fetchOnPremCollectorList();
  }, [query]);

  const handleError = (error: any) => {
    handleClose();
    if (error?.response?.status === 500) {
      addToast(error.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (error?.response?.status === 419) {
      addToast(
        "We encounted validation problem, please correct and try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (error?.response?.status === 404) {
      addToast(
        "We are not able to find associated email, please check and try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else if (error?.response?.status == 409 || error?.response?.data) {
      addToast(error.response.data, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast("Sorry, something went wrong there, try again.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const SearchTable = () => {
    return (
      <AMAdminSearchTable
        searchTitle=""
        searchPlaceHolder="Name, Sensor Name, Data Source and Type"
        query={query.q}
        ref={searchRef}
        onSearchValue={onSubmit}
      />
    );
  };

  const DeleteCollectorPopUp = ({
    collectorData,
    popUpButtons,
  }: any | null) => {
    return (
      <AMAdminPopUp
        popUpTitle={"Delete Collector"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <span className="font14">
          Are you sure you want to delete the collector{" "}
          <strong>{collectorData?.name}</strong>?{" "}
        </span>
      </AMAdminPopUp>
    );
  };

  const UpdateCollectorPopUp = ({
    collectorData,
    popUpButtons,
  }: any | null) => {
    return (
      <AMAdminPopUp
        popUpTitle={"Edit Collector Name"}
        popUpButtons={popUpButtons}
        handleClose={handleClose}
      >
        <form>
          <label className="font14" htmlFor="collector_name">
            Collector Name:{" "}
          </label>
          <input
            id="collector_name"
            type="text"
            defaultValue={collectorData.name}
            onKeyPress={(e) => proceedUpdate(e)}
            ref={inputRef}
          />
        </form>
      </AMAdminPopUp>
    );
  };

  const handleAddButtonClick = (category: string) => {
    // Logic to handle the button click
    if (category === FLOW_SENSOR) {
      setShowFlowSensor(true);
    } else {
      history.push(
        `/deploy/supportedCollector?category=${encodeURIComponent(category)}`
      );
    }
  };

  const [hide, setHide] = useState(false);
  const [hideDiv, setHideDiv] = useState(true);

  const afterDeleteCollectorOperation = (collectorCategory) => {
    switch (collectorCategory) {
      case "Active Directory & Cloud IdPs":
        fetchAdCollectorList();
        break;
      case "Network Access":
        fetchNetworkCollectorList();
        break;
      case "Cloud & On-Premise Infrastructure":
        fetchOnPremCollectorList();
        break;
    }
  };

  return (
    <>
      <div
        className={
          !hideDiv ? "page_title_area marginbottom20" : "page_title_area"
        }
      >
        {!hideDiv && (
          <div>
            <div
              className="launch_button margintop10"
              style={{ overflow: "hidden" }}
            >
              {hide ? (
                <h4
                  style={{ cursor: "pointer" }}
                  onClick={() => setHide((prev) => !prev)}
                >
                  Show &#9660;
                </h4>
              ) : (
                <h4
                  style={{ cursor: "pointer" }}
                  onClick={() => setHide((prev) => !prev)}
                >
                  Hide &#9650;{" "}
                </h4>
              )}
            </div>

            {!hide && (
              <>
                <Box
                  className="small-button-container banner"
                  style={{ overflow: "hidden", clear: "both" }}
                >
                  <div className="float-left">
                    <div
                      className="status float-left"
                      style={{
                        height: "24px",
                        width: "24px",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    ></div>
                    <div className="float-left font-text">
                      To start your deployment, you must first either setup a
                      Flow Sensor or configure a SIEM Sensor to collect events.
                      <a
                        className="link-text"
                        style={{ paddingLeft: "5px" }}
                        href="https://docs.authmind.com/authmind-deployment-guide/Working-version/deployment-overview"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                  <div className="launch_button" style={{ marginLeft: "auto" }}>
                    <button
                      type="button"
                      className="button_main button_small"
                      id="new_playbook"
                      onClick={() => handleAddButtonClick("SIEM")}
                    >
                      Add SIEM
                    </button>
                  </div>
                  <div className="launch_button">
                    <button
                      type="button"
                      className="button_main button_small"
                      id="new_playbook"
                      onClick={() => handleAddButtonClick(FLOW_SENSOR)}
                    >
                      Add Flow Sensor
                    </button>
                  </div>
                </Box>
              </>
            )}
          </div>
        )}
      </div>

      {addata?.result.length >= 0 && (
        <>
          <div className="flow_table_container sensor-collector-container ">
            <div className="table-distance collector-header">
              {" "}
              <div className="float-left margintop10">
                Active Directory & Cloud IdPs{" "}
              </div>{" "}
              <div className="launch_button marginleft10">
                {authDetails.permissions.Admin.sensors !== "readonly" && (
                  <button
                    type="button"
                    className="button_main"
                    id="new_playbook"
                    onClick={() =>
                      handleAddButtonClick("Active Directory & Cloud IdPs")
                    }
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
            <AMAdminTable
              columns={CollectorTableColumns}
              data={addata}
              ref={adchildRef}
              {...(CollectorActions?.length > 0 &&
              authDetails.permissions.Admin.sensors !== "readonly"
                ? { actions: CollectorActions }
                : null)}
              query={query}
              setQuery={setQuery}
              refresh={fetchAdCollectorList}
              showEmptyTable={true}
              handleButtonClick={() =>
                handleAddButtonClick("Active Directory & Cloud IdPs")
              }
              imageClasses={[
                "microsoft",
                "aws",
                "google",
                "ibm",
                "okta",
                "ping_identity",
              ]}
              title="Active Directory & Cloud IdP"
              subTitle="Collecting Identity System Events is the first step in gaining visibility into Identity Activities."
              showPagination={true}
            />
          </div>
        </>
      )}

      <>
        <div className="flow_table_container sensor-collector-container margintop10">
          <div className="table-distance collector-header">
            <div className="float-left margintop10">Network Access </div>
            <div className="launch_button marginleft10">
              {authDetails.permissions.Admin.sensors !== "readonly" &&
                (addata?.result.length === 0 && !isAmopsUser(authDetails.email) ? (
                  <Tooltip
                  classes={{ tooltip: "generic-tooltip-popper" }}
                    title="To add a Network Access collector, first add Active Directory or Cloud IDP."
                  >
                    <button
                      type="button"
                      id="new_playbook"
                      style={{opacity:0.5 , cursor:'default'}}
                      className="button_main"
                    >
                      Add
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    type="button"
                    id="new_playbook"
                    onClick={() => handleAddButtonClick("Network Access")}
                    className="button_main"
                  >
                    Add
                  </button>
                ))}
            </div>
          </div>
          <AMAdminTable
            columns={NetworkCollectorTableColumns}
            data={networkdata}
            ref={networkchildRef}
            {...(CollectorNWActions?.length > 0 &&
            authDetails.permissions.Admin.sensors !== "readonly"
              ? { actions: CollectorNWActions }
              : null)}
            query={query}
            setQuery={setQuery}
            refresh={fetchNetworkCollectorList}
            showEmptyTable={true}
            handleButtonClick={() => handleAddButtonClick("Network Access")}
            imageClasses={[
              "cisco",
              "zscaler",
              "checkpoint",
              "palo_alto",
              "fortinet",
              "sonicwall",
              "cato",
            ]}
            title="Network Access"
            subTitle="To enable visibility of identity activity to/from the Enterprise, it is essential to collect events from the Network Access Infrastructure."
            showPagination={true}
          />
        </div>
      </>

      {onpremdata?.result.length >= 0 && (
        <>
          <div
            className="flow_table_container sensor-collector-container margintop10"
            style={{ marginBottom: "24px" }}
          >
            <div className="table-distance collector-header">
              <div className="float-left margintop10">
                {" "}
                Cloud & On-Premise Infrastructure{" "}
              </div>{" "}
              <div className="launch_button marginleft10">
               

                {authDetails.permissions.Admin.sensors !== "readonly" &&
                  (networkdata?.result.length === 0 &&  !isAmopsUser(authDetails.email)  ? (
                    <Tooltip
                    classes={{ tooltip: "generic-tooltip-popper" }}
                      title="To add a Cloud & On-Premise collector, first add a Network Access collector"
                    >
                      <button
                        type="button"
                        id="new_playbook"
                        disabled
                        className="button_main"
                        style={{opacity:0.5 , cursor:'default'}}
                      >
                        Add
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      type="button"
                      id="new_playbook"
                      onClick={() =>
                        handleAddButtonClick(
                          "Cloud & On-Premise Infrastructure"
                        )
                      }
                      className="button_main"
                    >
                      Add
                    </button>
                  ))}
              </div>
            </div>
            <AMAdminTable
              columns={NetworkCollectorTableColumns}
              data={onpremdata}
              ref={onpremchildRef}
              {...(CollectorNWActions?.length > 0 &&
              authDetails.permissions.Admin.sensors !== "readonly"
                ? { actions: CollectorNWActions }
                : null)}
              query={query}
              setQuery={setQuery}
              refresh={fetchOnPremCollectorList}
              showEmptyTable={true}
              handleButtonClick={() =>
                handleAddButtonClick("Cloud & On-Premise Infrastructure")
              }
              imageClasses={["aws", "azure", "cisco", "gcp", "vmware"]}
              title="Cloud & On-Premise Infrastructure"
              subTitle="To enable visibility of identity activity across the Enterprise, it is essential to collect events from the Cloud & On-Premise Infrastructure."
              showPagination={true}
            />
          </div>
        </>
      )}

      {showPopUp.type == "DeleteCollector" ? (
        <DeleteCollectorPopUp
          collectorData={showPopUp.data}
          popUpButtons={deletePopUpButtons}
        />
      ) : null}
      {showPopUp.type == "UpdateCollector" ? (
        <SensorPopup
          isOpen={true}
          onCloseHandler={handleClose}
          title={`Manage ${showPopUp?.data?.collector_data_source} collector`}
          classNames="sensor-modal-popup-container"
          logoClass={showPopUp?.data?.vendor_name
            .replace(/\s+/g, "_")
            .toLowerCase()}
        >
          <CollectorConfigurationContainer
            onCloseHandler={handleClose}
            collectorName={showPopUp?.data.collector_data_source}
            id={showPopUp?.data?._id}
            data={showPopUp.data}
            setShowFlowSensor={setShowFlowSensor}
            afterSaveCollectorOperation={afterDeleteCollectorOperation}
          />
        </SensorPopup>
      ) : // <UpdateCollectorPopUp
      //   collectorData={showPopUp.data}
      //   popUpButtons={updatePopUpButtons}
      // />
      null}
      {showFlowSensor && (
        <SensorPopup
          isOpen={showFlowSensor}
          onCloseHandler={() => {
            setShowFlowSensor(false);
          }}
          title={"Add Flow Sensor"}
          subTitle={POPUP_SUB_TITLE_META.FLOW_SENSOR.title}
          guideLink={POPUP_SUB_TITLE_META.FLOW_SENSOR.guideLink}
          classNames="sensor-modal-popup-container"
        >
          <FlowSensorContainer
            sensorType="3"
            onCloseHandler={() => {
              setShowFlowSensor(false);
            }}
          />
        </SensorPopup>
      )}
      {showSiemVendor && (
        <SensorPopup
          isOpen={showSiemVendor}
          onCloseHandler={() => {
            setShowSiemVendor(false);
          }}
          title={"Add SIEM Vendor"}
          classNames="sensor-modal-popup-container"
        >
          <SiemConfiguration
            onCloseHandler={() => {
              setShowSiemVendor(false);
            }}
          />
        </SensorPopup>
      )}
      {/* {showCollectorConfiguration && (
          <SensorPopup isOpen={showCollectorConfiguration} onCloseHandler={() => { setShowCollectorConfiguration(false) }} title={`Add ${configurationData.collector_data_source} collector`}
          classNames='sensor-modal-popup-container' logoClass={configurationData.vendor_name.replace(/\s+/g, "_").toLowerCase()}>
              <CollectorConfigurationContainer onCloseHandler={() => { setShowCollectorConfiguration(false) }} collectorName={configurationData.collector_data_source} id={''} data={configurationData} setShowFlowSensor={setShowFlowSensor} />
          </SensorPopup>
      )} */}
    </>
  );
};

export default withQueryParams(
  {
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    hard_refresh: StringParam,
    sensor_id: StringParam,
  },
  CollectorsList
);