import React from "react";
import usePrevious from "../../core/hooks/usePrevious";
import CountUp from "react-countup";
import moment from "moment";
import { getRoundedUnitWithoutDot } from "../../../utils/util-methods";
import "./Past24HrsIncidentFlows.scss";
import { Link } from "react-router-dom";

const Past24HrsIncidentFlows = ({ past_24_hour_flow }) => {
    const previousFlows = usePrevious(past_24_hour_flow?.access_flows);
    const flows24 = past_24_hour_flow?.past_24_access_flows || 0;
    const allFlows = past_24_hour_flow?.access_flows || 0;
    const flowsPercentage = allFlows ? ((flows24 / allFlows) * 100).toFixed(1) : 0;
    const issuesCount = getRoundedUnitWithoutDot(past_24_hour_flow?.issues || 0);
    return (
        <div className="past-24-hrs-incident-flows-container">
            <div className="label-border">
                <div className="total-issues-percent shadow">
                    <Link
                        to={{
                            pathname: `/issues`,
                            search: `?disable_filter=true&page=1&q=s_time:${moment.utc().subtract(24, "hours").unix()}%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc`,
                            state: {
                                breadcrumbId: "Past24Incidents",
                            },
                        }}
                        className="link-style"
                    >
                        {past_24_hour_flow?.issues === 0 ? (
                            <div className="empty-issues">
                                <div>Incidents</div>
                                <div>0%</div>
                            </div>
                        ) : (
                            <div className="hrs24-issue-label">
                                <div>Incidents</div>
                                <div className="issue-value">{issuesCount}</div>
                                <div className='hrs24-percentage-value'>{`${flowsPercentage}%`}</div>
                            </div>
                        )}
                    </Link>
                </div>
            </div>
            <>
                {past_24_hour_flow?.access_flows === 0 ? (
                    <div className="empty-flows shadow">
                        <div >Flows</div>
                        <div className="">0</div>
                    </div>
                ) : (
                    <div className="hrs24-flows-label shadow">
                        <div className="">Flows</div>
                        <div className="">
                            {/* {(past_24_hour_flow?.access_flows.toLocaleString())} */}
                            <CountUp start={previousFlows} end={allFlows} duration={29} separator={","} />
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default Past24HrsIncidentFlows;
