import React, { useState, useEffect } from 'react'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import {Api} from '../../components/Axios'
import { EditPanel } from './sub'
import { useToasts } from '../../components/core';

type ActionType = {
    type?: string;
    id?: string;
}

type DATAFEED = {
    authentication?: string;
    host?: string;
    password?: string;
    port?: string;
    security?: string;
    user_name?: string;
}

const Datafeed = ({query, setQuery}: any) => {
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [datafeed, setDatafeed] = useState<DATAFEED>()
    const { addToast } = useToasts()

    const LoadDatafeed = () => {
        Api.get('/datafeed')
        .then((res: { data: any }) => {
            setDatafeed(res.data)
        })
        .catch((error: any) => {
        })
    }

    useEffect(() => {
        LoadDatafeed()
    }, [])

    useEffect(() => {
        switch(actionType?.type) {            
            case 'edit':
                setPanelTitle("Dehashed")
                setOpenPanel(true)
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        setQuery( )
    }

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterEditOperation={AfterEditOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const AfterEditOperation = (action: string) => {
        LoadDatafeed()
        setOpenPanel(false)
    }

    return (
        <>
            <RightPanel />
            <div className="page_title_area">
                <h2>Data Breach Feeds</h2>
            </div>
            <div className="box_grid_layout">
                <div className="box_grid_title">
                     <h4>Dehashed</h4>
                </div>
                <div className="clear_both"></div>
                <label className="font_semibold width25per">Base URL :  </label>
                <label className="width75per" >https://api.dehashed.com/search </label>
                
                <label className="font_semibold width25per">Email : </label>
                <label className="width75per" >pankur@gmail.com </label>

                <label className="font_semibold width25per">Key : </label>
                <label className="width75per" >4603eb4690796f77b57e927183d0a885</label>
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>

            <div className="box_grid_layout">
                <div className="box_grid_title">
                     <h4>HaveIBeenPwned (Paid)</h4>
                </div>
                
                <div className="clear_both"></div>
                <label className="font_semibold width25per">Base URL :  </label>
                <label className="width75per" >https://haveibeenpwned.com/api/v3/breachedaccount/ </label>
                
                <label className="font_semibold width25per">Key : </label>
                <label className="width75per" >3bdf3e33dded422dabc45566aa7f1c2b</label>
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>

            <div className="box_grid_layout">
                <div className="box_grid_title">
                     <h4>Hashes.org</h4>
                </div>
               
                <div className="clear_both"></div>
                <label className="font_semibold width25per">Base URL :  </label>
                <label className="width75per" >https://hashes.org/api.php </label>
                
                <label className="font_semibold width25per">Key : </label>
                <label className="width75per" >JrdU9J99pA8L5Kun09hMIlspLKqqW1</label>
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>

            <div className="box_grid_layout">
                <div className="box_grid_title">
                     <h4>Md5decrypt.net</h4>
                </div>
                
                <div className="clear_both"></div>
                <label className="font_semibold width25per">Base URL :  </label>
                <label className="width75per" >https://md5decrypt.net/en/Api/api.php  </label>
                
                <label className="font_semibold width25per">Code : </label>
                <label className="width75per" >1152464b80a61728</label>

                <label className="font_semibold width25per">Email  : </label>
                <label className="width75per">deanna_abshire@proxymail.eu </label>
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default (Datafeed)
