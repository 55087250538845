import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import styles from "../grid/Playbooks.module.css";
import { deTransformPbIdAssetCustomList } from "../helpers/playbook-helper";
import { IIdAssetState } from "../ref/IdPopUp";
import { IPatternConfigItem, IPatternFormValue, PatternFormGenerator } from "../ref/PatternForm/PatternFormGenerator";

interface IdAssetCustomListProps {
    handleCloseSource(): any,
    popUpTitle: string,
    data: IIdAssetState,
    patternFormConfig: Array<IPatternConfigItem>
}

export const IdAssetCustomList = ({ handleCloseSource, popUpTitle, data, patternFormConfig }: IdAssetCustomListProps) => {
    const [customList, setCustomList] = React.useState<Array<IPatternFormValue>>(deTransformPbIdAssetCustomList(data));

    React.useEffect(() => {
        setCustomList(deTransformPbIdAssetCustomList(data));
    }, [data]);

    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
    >
        <div style={{ marginBottom: '5%' }} className={`${styles['modal']}`}>
            <div className={styles.close} onClick={handleCloseSource}></div>
            <div className={styles.header + ' font22'}>{popUpTitle}</div>
            <div className={styles.content + ' id-asset-pop-modal-content'}>
                <PatternFormGenerator config={patternFormConfig}
                    defaultState={{ searchFilter: customList }} formType="view" classes={{ formListContainer: 'pattern-form-view-id-asset' }}
                />
            </div>
        </div>
    </Popup>
}