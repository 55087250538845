import React, { useEffect, useState } from "react"
import Popup from "reactjs-popup";
import styles from '../../../grid/Playbooks.module.css'
import { AutoCompleteInput } from "../../../../../components/core/AutoCompleteInput/AutoCompleteInput";
import { useForm } from "react-hook-form";
import { Api } from "../../../../../components/Axios";
import { WeekSelector as WSelector } from "../../../ref";

interface IUnauthorizedAccessIssueState {
    dest_port: number[],
    src_port: number[],
    access_protocol: string[],
    access_time: WSelector.IWeelkSelectorState
}

interface IUnauthorizedAccessIssue {
    handleClose(): any,
    handleSave(state: any): any,
    state: IUnauthorizedAccessIssueState | any,
    formType?: string
}

const UnauthorizedAssetAccessIssueConfig = ({ handleClose, handleSave, state, formType }: IUnauthorizedAccessIssue) => {
    const { register, getValues, setValue } = useForm();
    const [protocols, setProtocols] = useState([]);
    const [selectedProtocols, setSelectedProtocols] = useState([]);
    const [sohwWeeklyConfig, setShowWeeklyConfig] = useState(false);
    const [weeklyConfig, setWeeklyConfig] = useState<WSelector.IWeelkSelectorState | any>(WSelector.DefaultWeekSelectorState);
    const [error, setError] = useState('');

    useEffect(() => {
        register('access_time');
        register('access_protocol');
    }, []);

    useEffect(() => {
        setValue('dest_ports_check', state?.dest_port?.length > 0);
        setValue('src_ports_check', state?.src_port?.length > 0);
        setValue('access_protocol', state?.access_protocol || []);
        setValue('access_protocols_check', state?.access_protocol?.length > 0);
        setValue('access_time', state?.access_time || {});
        setValue('access_time_check', state.access_time && Object.values(state?.access_time)?.filter((i: WSelector.IWeeklyState | any) => i?.is_selected).length > 0);
        setSelectedProtocols(state?.access_protocol);
        setWeeklyConfig(state?.access_time || WSelector.DefaultWeekSelectorState);
    }, [state]);

    useEffect(() => {
        Api.get('/protocols').then((res: any) => {
            setProtocols(res.data)
        }).catch((res: any) => {
            setProtocols([])
        })
    }, []);

    const handleUnauthAccessIssueSave = () => {
        const result = getValues();
        if (validateIssueConfig(result)) {
            return;
        }
        setError('');
        /* remove duplicate ports */
        result.dest_port = result.dest_port.split(",").map((i: string) => parseInt(i));
        result.src_port = result.src_port.split(",").map((i: string) => parseInt(i));
        result.dest_port = Array.from(new Set(result.dest_port));
        result.src_port = Array.from(new Set(result.src_port));

        if (!result.src_ports_check) {
            delete result.src_port;
        }
        if (!result.dest_ports_check) {
            delete result.dest_port;
        }
        if (!result.access_protocols_check) {
            delete result.access_protocol;
        }
        if (!result.access_time_check) {
            delete result.access_time;
        }
        delete result.access_protocols_check;
        delete result.access_time_check;
        delete result.src_ports_check;
        delete result.dest_ports_check;
        handleSave(result);
    }

    const validateIssueConfig = (result: any) => {
        const pattern = new RegExp('^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$');
        let isInvalid = false;
        if (result.src_ports_check) {
            result?.src_port?.split(",").forEach((item: string) => {
                const invalid = pattern.test(item);
                if (!invalid) {
                    setError('Invalid source port');
                    isInvalid = true;
                }
            })
        }
        if (result.dest_ports_check) {
            result?.dest_port?.split(",").forEach((item: string) => {
                const invalid = pattern.test(item);
                if (!invalid) {
                    setError('Invalid destination port');
                    isInvalid = true;
                }
            });
        }

        if (result.access_protocols_check && (!result.access_protocol || result.access_protocol?.length === 0)) {
            isInvalid = true;
            setError('Protocols list is empty');
        }

        /* if (!result.access_protocols_check && !result.access_time_check &&
            !result.src_ports_check && !result.dest_ports_check) {
            setError('Please select any one of the config.')
            isInvalid = true;
        } */

        return isInvalid;
    }

    const setSelectedCountries = (countries: any) => {
        setError('');
        if (countries && countries.length > 0) {
            setValue('access_protocol', countries)
            setValue('access_protocols_check', true)
        }
        else {
            setValue('access_protocol', null);
            setValue('access_protocols_check', false);
        }
    }

    const handleReset = () => {
        setValue('dest_ports_check', false);
        setValue('dest_ports_check', false);
        setValue('src_port', '');
        setValue('dest_port', '');
        setValue('src_ports_check', false);
        setValue('access_protocol', []);
        setValue('access_protocols_check', false);
        setValue('access_time', WSelector.DefaultWeekSelectorState);
        setValue('access_time_check', false);
        setSelectedProtocols([]);
        setWeeklyConfig(WSelector.DefaultWeekSelectorState);
    }

    const onWeekSelectorSave = (e: WSelector.IWeelkSelectorState) => {
        setError('');
        if (e && Object.values(e)?.filter((i: WSelector.IWeeklyState) => i?.is_selected).length > 0) {
            setValue('access_time_check', true)
            setValue('access_time', e);
            setWeeklyConfig(e);
        } else {
            setValue('access_time_check', false)
            setValue('access_time', {});
            setWeeklyConfig(WSelector.DefaultWeekSelectorState);
        }
        setShowWeeklyConfig(false);
    }

    const handleDestPortChange = () => {
        setError('');
        if (getValues('dest_port')?.length > 0) {
            setValue('dest_ports_check', true);
        } else {
            setValue('dest_ports_check', false);
        }

    }

    const handleSrcPortChange = () => {
        setError('');
        if (getValues('src_port')?.length > 0) {
            setValue('src_ports_check', true);
        } else {
            setValue('src_ports_check', false);
        }
    }

    return <>
        <Popup
            closeOnDocumentClick={false}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnEscape={false}
            modal
        >
            <div style={{ marginBottom: '5%' }} className={`${styles['modal']} unauth-asset-access-issue-container`}>
                <div className={styles.close} onClick={handleClose}></div>
                <div className={styles.header}>{'Configure the Authorized Asset Access Parameters'}</div>
                <div className={styles.content}>
                    <form action="">
                        <div className="clrBoth"></div>
                        <div className="display-flex-baseline">
                            <input type="checkbox" id="dest_ports_check" name="dest_ports_check"
                                className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                ref={register}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        document.getElementsByName('dest_port')[0].focus();
                                    }
                                }}
                            />
                            <label htmlFor="dest_ports_check"
                                className={"marginleft20 float_none width75per " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}>
                                {"Destination ports to which access is allowed: "}</label>
                            <input type="text" className={"margin0 " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                placeholder="Comma-separated list of port numbers"
                                ref={register}
                                name="dest_port"
                                defaultValue={state?.dest_port || ''}
                                onChange={handleDestPortChange}
                            />
                        </div>
                        <div className="display-flex-baseline">
                            <input type="checkbox" id="src_ports_check" name="src_ports_check"
                                className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                value={""}
                                ref={register}
                                onChange={(e) => {
                                    document.getElementsByName('src_port')[0].focus();
                                }}
                            />
                            <label htmlFor="src_ports_check"
                                className={"marginleft20 float_none width75per " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                            >{"Source ports from which access is allowed: "}</label>
                            <input type="text"
                                name="src_port"
                                className={"margin0 " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                defaultValue={state?.src_port || ''}
                                onChange={handleSrcPortChange}
                                ref={register}
                                placeholder="Comma-separated list of port numbers"
                            // pattern="^(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[1-9][0-9]{1,3}|[0-9])(?:,(?1))*$"
                            />
                        </div>
                        <div className="display-flex-baseline unauth-asset-access">
                            <input type="checkbox" id="access_protocols_check" name="access_protocols_check"
                                className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                value={""}
                                ref={register}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        document.getElementById('countries-list')?.click()
                                    }
                                }}
                            />
                            <label htmlFor="access_protocols_check"
                                className={"marginleft20 float_none " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                style={{ width: '49%' }}>{"Access protocols allowed: "}</label>

                            {
                                (formType && ['edit', 'view'].includes(formType)) ? <>
                                    <input
                                        style={{ width: '47%' }}
                                        type="text" id="access_protocol" name="access_protocol"
                                        value={state?.access_protocol}
                                        className={"margin0 disable-config-item"}
                                        placeholder="List of protocols"
                                    />
                                </> :
                                    < AutoCompleteInput
                                        data={
                                            protocols
                                        }
                                        placeHolder={'Comma-separated list of protocols'}
                                        state={selectedProtocols} onChange={setSelectedCountries}
                                    />}
                        </div>
                        <div className="display-flex-baseline">
                            <input type="checkbox" id="access_time_check" name="access_time_check"
                                className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                                value={""}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setShowWeeklyConfig(true)
                                    }
                                }}
                                ref={register}
                            />
                            <label htmlFor="access_time_check"
                                className={"marginleft20 float_none " + (formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                            >{"Access time allowed: "}</label>
                            <label className="link-text" onClick={() => setShowWeeklyConfig(true)}>Choose days of the week and times per day</label>
                        </div>
                        <br />
                    </form>
                </div>
                {error ? <div className="error" style={{ marginLeft: '1%' }}>Error: {error}</div> : null}
                <div className="shadow_footer fl"></div>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? null :
                        < div className="popup_footer fl">
                            <div className="policy_defualt_button" onClick={handleReset}>Reset to default</div>
                            <div className="policy_save_button" onClick={handleUnauthAccessIssueSave}>Save</div>
                        </div>}
            </div>
        </Popup >
        {
            sohwWeeklyConfig ? <WSelector.WeelkSelector
                defaultState={weeklyConfig}
                handleClose={() => setShowWeeklyConfig(false)}
                handleSave={(e) => onWeekSelectorSave(e)}
                formType={formType}
            /> : null
        }
    </>
}

export default React.memo(UnauthorizedAssetAccessIssueConfig);