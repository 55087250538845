import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "../../../components/core";
import { Api } from "../../../components/Axios";
import MultiSelect from "react-multi-select-component";
import "./edit-user.scss";
import { AMModalError } from "../../../components/core/AMModalPopup/AMModalError";
import { UserError } from "./UserError";
import CheckboxAutocomplete from "../../../components/core/AMAutoComplete/AMAutoComplete";

type User = {
    name?: string;
    email?: string;
    role?: string;
    // groups?: [];
};

interface Props {
    id?: string;
    AfterUserOperation?: any;
}

export const EditPanel = ({ id, AfterUserOperation }: Props) => {
    const { register, handleSubmit, errors, setValue, setError, control } = useForm<User>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [user, setUser] = useState<User>();
    const [sErrors, setSErrors] = useState<User>();
    const [roles, setRoles] = useState<string[]>([]);
    // const [ groups, setGroups ] = useState<import("react-multi-select-component/dist/lib/interfaces").Option[]>([])
    // const [ userGroups, setUserGroups ] = useState([])
    const { addToast } = useToasts();

    // let options: import("react-multi-select-component/dist/lib/interfaces").Option[] = []

    const onUpdateUser = (data: any) => {
        setLoading(true);
        //let config={Headers:{"Operation":"PUT"}}
        const headers = {
            Operation: "PUT",
        };
        data["role"] = data['role'] && data['role'].length > 0 ? data['role'][0]['key'] : ""
        Api.post("/user/" + id, data, {
            headers: headers,
        })
            .then((res: { data: any }) => {
                setLoading(false);
                AfterUserOperation("edit");
                addToast("User updated successfully.", {
                    appearance: "success",
                    autoDismiss: true,
                });
            })
            .catch((error: any) => {
                setLoading(false);
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                } else if (error.response.status === 419) {
                    // setSErrors(error.response.data);
                    const o = error.response.data;
                    Object.keys(o).map((key: string) => {
                        setError(key as keyof User, {
                            type: 'custom',
                            message: o[key]
                        });
                    });
                    // addToast("We encounted validation problem, please correct and try again.", {
                    //     appearance: 'error',
                    //     autoDismiss: true,
                    // })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            });
    };

    useEffect(() => {
        // Api.get('/groups?record=all')
        // .then((res: { data: any }) => {
        //     if(res.data.total > 0) {
        //         res.data.result.map((g:any) => {
        //             options.push({label: g.name, value: g._id})
        //         })
        //         setGroups(options)
        //     }
        // })
        // .catch((error: any) => {
        // })

        Api.get("/user/" + id)
            .then((res: { data: any }) => {
                setUser({
                    name: res.data.name,
                    email: res.data.email,
                    role: res.data.role,
                    // groups: res.data.groups
                });
                setTimeout(()=>{
                    setValue("role", [{ key:res.data.role, value: res.data.role }]);
                },100);
                
                // let groups = [] as any
                // options.map((o:any) => {
                // if(res.data.groups.includes(o.value)) {
                // console.log(o.label)
                // groups.push([o.label, o.value])
                // const i = {label: o.label, value: o.value}
                // setUserGroups([i, ...userGroups])
                // }
                // })
            })
            .catch((error: any) => { });
    }, []);

    const fetchRoles = () => {
        Api.get("/roles")
            .then((res) => {
                console.log("Roles", res.data);
                if (res.status === 200) {
                    setRoles(res.data);
                }
            })
            .catch((error: any) => { });
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const getItemLabel = (
        option: { key: string; value: string } | { key: string; value: string }[]
      ) => {
        if (Array.isArray(option)) {
          return option[0]?.value ? option[0]?.value : "";
        } else {
          return option?.value ? option?.value : "";
        }
    };

    return (
        <form onSubmit={handleSubmit(onUpdateUser)}>
            <div className="edit-user-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter your name"
                            name="name"
                            ref={register({
                                required: true,
                            })}
                            className={errors.name ? "error" : ""}
                            defaultValue={user?.name}
                        />
                    </div>


                    <div className="form-control">
                        {/* <label>Role</label>
                        <select
                            name="role"
                            ref={register({
                                required: true,
                            })}
                            className={"form_drodown" + (errors.role ? "error" : "")}
                            defaultValue={user?.role}
                        >
                            {roles?.map((item: any) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select> */}
                        <div className="role-dropdown-container" style={{width: "94%"}}>
                            <Controller
                                name="role"
                                control={control}
                                defaultValue={[]}
                                ref={register({
                                required: true,
                                })}
                                render={({ onChange, value = "" }) => (
                                <CheckboxAutocomplete
                                    label="Select options"
                                    options={roles.map(item => ({key: item, value: item}))}
                                    value={value}
                                    onChange={(event, newValue) => onChange(newValue)}
                                    getItemLabel={getItemLabel}
                                    multiple={false}
                                    name="role"
                                    formType="edit"
                                    className={errors.role ? "error" : ""}
                                />
                                )}
                            />
                        </div>
                    </div>

                </div>
                <div className="form-control-group-right">
                    <div className="form-control">
                        <label>Email</label>
                        <input
                            type="text"
                            placeholder="Enter your email"
                            name="email"
                            ref={register({
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Please enter valid email.",
                                },
                            })}
                            className={errors.email ? "error" : ""}
                            defaultValue={user?.email}
                        />
                    </div>
                </div>
            </div>

            <AMModalError errors={errors} errorMap={UserError} />

            <div className="add-edit-action-buttons dialog-button">
                <button type={loading ? "button" : "submit"} className={`float_right ${loading ? "loader" : "add-edit-submit"}`}>
                    Save
                </button>
            </div>
        </form>
    );
};
