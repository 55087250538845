import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from '../../../components/core';
import Popup from 'reactjs-popup';
import { Api } from '../../../components/Axios';

type Props={
    updateTicketDetails: ()=>void
}

const CloseTicket=({updateTicketDetails}: Props)=>{

    let history = useHistory();
    const [ticketId, setTicketId] = useState<number>(history.location.state as number);
    const { addToast } = useToasts();

    const handleYes=()=>{
        Api.post('/tickets/'+ticketId+"/close")
        .then(response=>{
            if(response.status===200){
                addToast("Ticket closed successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
                updateTicketDetails();
            }
        })
        .catch((error: AxiosError)=>{
            if(error.response?.status===500 || error.response?.status===404){
                addToast("Something went wrong, please try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }else if(error.response?.status===401){
                window.location.href = '/login';
            }
        })
        history.goBack();
    }

    const handleNo=()=>{
        history.goBack();
    }

    return(
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal
                // //lockScroll
            >
                <div className={"modal ticket_modal"}>
                    <div className={"content"}>
                        <div className="popup_title fl" style={{marginBottom:0, width:'100%'}}>
                            Are you sure you want to close this ticket?
                    </div>
                    <div className="clrBoth"></div>
                    </div>
                    <div className="popup_footer fl" style={{borderTop: "none", paddingTop:0}}>
                        <div className="policy_defualt_button cancl_policy_popup" onClick={handleNo}>No</div>
                        <div className="policy_save_button cancl_policy_popup" onClick={handleYes}>Yes</div>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(CloseTicket);