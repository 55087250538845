import React, { useEffect } from 'react';
import { AVAILABLE_PRESETS } from '../Constants';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchStateContext
} from '../useTimeBasedSearch';
import ACTION_TYPES from '../TimeBasedSearchActionTypes';

const PresetsComponent = () => {
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();

    const onPresetSelect = (e, id) => {
        if (store.selectedPreset === id)
            return;
        dispatchAction({ type: ACTION_TYPES.SELECT_PERIOD_PRESET, value: id });
    };

    const getPeriodPresetItemClass = (id) => {
        let classes = `preset-item-btn`;
        if (store.selectedPreset === id) {
            return `${classes} preset-item-btn-selected`;
        }
        return classes;
    };

    return (
        <div className='preset-content-container'>
            {Object.keys(AVAILABLE_PRESETS).map((preset) => (
                <button
                    onClick={(e) =>
                        onPresetSelect(e, AVAILABLE_PRESETS[preset].id)
                    }
                    className={getPeriodPresetItemClass(
                        AVAILABLE_PRESETS[preset].id
                    )}
                    key={AVAILABLE_PRESETS[preset].id}
                >
                    {AVAILABLE_PRESETS[preset].label}
                </button>
            ))}
        </div>
    );
};

export default PresetsComponent;
