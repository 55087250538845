import React from 'react';
import './weak-pass.css';

type Props = {
    config: any;
    rule_type?: string;
};

const WeakPasswordConfigView = ({ config }: Props) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>A password will be considered weak if: </span>
                <ul className='ml-1'>
                    {config?.isTimeChecked && (
                        <li>
                            Password takes less than{' '}
                            <strong>
                                {config?.duration} {config?.unit}
                            </strong>{' '}
                            to crack
                        </li>
                    )}
                    {config?.isDollarChecked && (
                        <li>
                            Password takes less than{' '}
                            <strong>{config?.dollar}</strong> dollars to crack
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
};

export default React.memo(WeakPasswordConfigView);
