import React, { useState } from "react";
import './stepper.scss';

type StepperProps = {
    totalSteps: number;
    currentStep: number;
    stepperLabels?: Array<string>
};

const Stepper = ({ totalSteps = 3, currentStep = 0, stepperLabels }: StepperProps) => {
    return (
        <div className="stepwizard">
            <div className="stepwizard-row setup-panel">
                {
                    Array.from(Array(totalSteps).keys()).map((i, index) => {
                        return <div className="stepwizard-step" key={i}>
                            <button type='button'
                                className={`btn ${currentStep === i ? 'btn-primary' : 'btn-default disabled'} btn-circle`}>
                                {`${i + 1}`}</button>
                            {
                                stepperLabels?.length ? <p>{stepperLabels[index]}</p> :
                                    <p>Step {`${i + 1}`}</p>
                            }

                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Stepper;