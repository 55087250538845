const SuspectedAccessTokenSharingView = ({ config, params }: any) => {
    if (params?.isPlaybookListing) {
        return <li>
            <span>Detect access tokens that were shared out within the token timeout period: Token Timeout Period - {config.session_token_params.assertion_timeout} {
                config.session_token_params.period_timeout_unit == 'hours' && config.session_token_params.assertion_timeout == 1 ? 'hour' :
                    config.session_token_params.period_timeout_unit
            }</span>
        </li>
    } else
        return <li>
            <span>Detect access tokens that were shared out within the token timeout period:</span>
            <ul className='ml-1'><li>Token Timeout Period: {config.session_token_params.assertion_timeout} {
                config.session_token_params.period_timeout_unit == 'hours' && config.session_token_params.assertion_timeout == 1 ? 'hour' :
                    config.session_token_params.period_timeout_unit
            }</li>
            </ul></li>
}

export default SuspectedAccessTokenSharingView;