import React, { useState } from 'react';
import { useEffect } from 'react';

type hashFeed={
    websiteName: string,
    isChecked: boolean
}

type feed={
    name: string,
    isChecked: boolean
}

type Props={
    parentState: State,
    isSavedOnce: boolean,
    getState: (state:State)=>void,
    defaultState: State,
    formType?:string
}

type State={
    commonPasswordFlag: boolean,
    feedPasswordFlag: boolean,
    feeds: feed[],
    hashFlag: boolean,
    hashFeeds: hashFeed[]
}

const CompromisedPasswordConfig=({parentState, isSavedOnce, getState, defaultState,formType}:Props)=>{

    const [state, setState]=useState<State>(defaultState);

    useEffect(()=>{
        setState(defaultState);
    }, [defaultState])

    useEffect(()=>{
        if(isSavedOnce){
            setState(parentState);
        }
    }, [isSavedOnce])

    useEffect(()=>{
        // console.log('CompromisedPasswordConfig STATE', state);
        getState(state);
    })

    const renderApiFeeds=(feeds: feed[], disabledFlag: boolean)=>{
        return feeds?.map((feed: feed)=>{
            if(feed.name != 'Md5decrypt.net')
            return (
                <React.Fragment key={feed.name}>
                    <input type="checkbox" id={feed.name} name={feed.name} checked={feed.isChecked} disabled={!disabledFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                feed.isChecked=true
                            }
                            else{
                                feed.isChecked=false
                            }
                            setState({
                                ...state,
                                feeds: state?.feeds
                            })
                        }}
                    />
                    <label htmlFor={feed.name} style={{float: "none"}}>{feed.name}</label>
                    {/* <div className="info_icon" style={{right:125}}>
                        <div className="infotext">Extra information about this field/option</div>
                    </div> */}
                    <div className="clrBoth"></div>
                </React.Fragment>
            );
        });
    }

    const renderHashFeeds=(hashFeeds: hashFeed[], disabledFlag: boolean)=>{
        return hashFeeds?.map((feed: hashFeed)=>{
            if(feed.websiteName != 'Md5decrypt.net')
            return (
                <React.Fragment key={feed.websiteName}>
                    <input type="checkbox" id={feed.websiteName} name={feed.websiteName} checked={feed.isChecked} disabled={!disabledFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                feed.isChecked=true
                            }
                            else{
                                feed.isChecked=false
                            }
                            setState({
                                ...state,
                                hashFeeds: state?.hashFeeds
                            })
                        }}
                    />
                    <label htmlFor={feed.websiteName} style={{float: "none"}}>{feed.websiteName}</label>
                    {/* <div className="info_icon" style={{left:222}}>
                        <div className="infotext">Extra information about this field/option</div>
                    </div> */}
                    <div className="clrBoth"></div>
                </React.Fragment>
            );
        });
    }

    return (
        <>
            <form action="/">
                  <div className="playbook-config-title">A password will be considered compromised if:</div>                  
                  <div className="">
                    <input type="checkbox" id="comm-pass" name="comm-pass" checked={state?.commonPasswordFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                
                                setState({
                                    ...state,
                                    commonPasswordFlag: true,
                                })
                            }
                            else{
                                
                                setState({
                                    ...state,
                                    commonPasswordFlag: false,
                                })
                            }
                        }}
                    />
                    <label className="" htmlFor="comm-pass" style={{float:"none"}}>Password is found in commonly used passwords</label>
                    {/* <label className="" style={{float: "none"}} htmlFor="">commonly used passwords</label> */}
                  </div>
                <div className="clrBoth"></div>
                <div><label><strong>OR</strong></label></div>
                <div className="clrBoth margintop10">&nbsp;</div>
                    <div className="">
                    <input type="checkbox" id="feeds" name="feeds" checked={state?.feedPasswordFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                setState({
                                    ...state,
                                    feedPasswordFlag: true,
                                    feeds: state?.feeds.map((value:feed) => ({...value, isChecked:true}))
                                })
                            }
                            else{
                                let tempFeed = state?.feeds?.map((f:feed)=>{
                                    return{
                                        name:f.name,
                                        isChecked: false
                                    }
                                })
                                setState({
                                    ...state,
                                    feedPasswordFlag: false,
                                    feeds: tempFeed
                                })
                            }
                        }}
                    />
                    <label className="" htmlFor="feeds" style={{float:"none"}}>Password is found in any of the following feeds:</label>
                    {/* <label className="" style={{float:"none"}} htmlFor="">of the following feeds:</label> */}

                    <div className="sub_options">
                        {renderApiFeeds(state?.feeds, state?.feedPasswordFlag)}
                    </div>
                  </div>
                    <div className="clrBoth"></div>
                    <div><label><strong>OR</strong></label></div>
                    <div className="clrBoth margintop10">&nbsp;</div>
                    <input type="checkbox" id="hash-feeds" name="hash-feeds" checked={state?.hashFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){                                
                                setState({
                                    ...state,
                                    hashFlag: true,
                                    hashFeeds: state?.hashFeeds.map((value: hashFeed) => ({...value, isChecked:true}))
                                })
                            }
                            else{
                                let tempHashFeed = state?.hashFeeds?.map((f:hashFeed)=>{
                                    return{
                                        websiteName:f.websiteName,
                                        isChecked: false
                                    }
                                })
                                setState({
                                    ...state,
                                    hashFlag: false,
                                    hashFeeds: tempHashFeed
                                })
                            }
                        }}
                    />
                    <label className="" htmlFor="" style={{float: "none"}}>Hash of the password is found in any of the following feeds:</label>
                    <div className="sub_options">
                        {renderHashFeeds(state?.hashFeeds, state?.hashFlag)}
                    </div>
                    
                  <div className="clrBoth"></div>
                </form>
        </>
    )
}

export default React.memo(CompromisedPasswordConfig);