import { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params'
import { App } from './App';
import * as dotenv from 'dotenv';
import { ThemeProvider as ThemeP } from '@mui/material/styles';

// import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/Authorization';
import { ThemeProvider } from './theme/theme'
import { theme } from './theme/MUITheme';
import { AMBreadcrumbProvider } from './components/core/AMBreadcrump/AMBreadcrumbContext';
import { PartnerStateContextProvider } from './store/PartnerContextProvider';
import { AppConfig } from './AppConfig';
import { deepCompare } from './utils/util-methods';
const { REACT_APP_HOST_IP } = process.env

dotenv.config();

const BaseURL = REACT_APP_HOST_IP
// const BaseURL = "http://dev.authmind.net/";
const RouteURL = "/static-lib/";
const appConfigKey = 'configKey';
const CONFIG_FILE = 'config.json';
const FAVICON_SEL = 'link[rel="icon"]';
const FAVICON_PATH = "images/favicon.ico";
const META_SEL = 'meta[name="description"]';
const CONFIG_DURATION = 4 * 60 * 60 * 1000;
const FETCH_FAIL_ERR = 'Failed to fetch configuration';

const IndexComp = () => {
  const [config, setConfig] = useState<any>({});
  const [isConfigLoaded, setIsConfigLoaded] = useState({ isconfigLoadDone: false, isRequestSent: false });
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const storedConfig = localStorage.getItem(appConfigKey);
        let data;
        if (storedConfig) {
          data = JSON.parse(storedConfig);
          loadAsyncConfigFetch();
        } else {
          const response = await fetch(BaseURL + RouteURL + CONFIG_FILE);
          if (!response.ok) {
            throw new Error(FETCH_FAIL_ERR);
          }
          data = await response.json();
        }
        setConfigData(data);
        setIsConfigLoaded({ isconfigLoadDone: true, isRequestSent: true });
        refreshCacheInBackground();
      } catch (error) {
        console.error(`${FETCH_FAIL_ERR}:`, error);
        setIsConfigLoaded({ isconfigLoadDone: false, isRequestSent: true });
      }
    };
    fetchConfig();
  }, []);

  const setConfigData = (data) => {
    setConfig((prevData) => {
      if (deepCompare(prevData, data)) {
        return prevData
      }
      localStorage.setItem(appConfigKey, JSON.stringify(data));
      return data;
    });
    document.title = data.product_name;
    const favicon = document.querySelector(FAVICON_SEL) as HTMLLinkElement;
    if (favicon) {
      favicon.href = BaseURL + RouteURL + FAVICON_PATH;
    }
    const metaTag = document.querySelector(META_SEL);
    if (metaTag) {
      metaTag.setAttribute('content', data.product_name + " Console");
    }
  }

  const loadAsyncConfigFetch = () => {
    fetch(BaseURL + RouteURL + CONFIG_FILE).then((response) => {
      if (!response.ok) {
        throw new Error(FETCH_FAIL_ERR);
      }
      response.json().then((data) => {
        if (data) {
          setConfigData(data);
        }
      }).catch((err) => console.log("Error while loading config to store"));
    })
  }

  const refreshCacheInBackground = () => {
    setInterval(() => loadAsyncConfigFetch, CONFIG_DURATION)
  }

  return <>
    <PartnerStateContextProvider initialValue={config}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeP theme={theme}>
            <ThemeProvider>
              <AuthProvider>
                <AMBreadcrumbProvider>
                  {isConfigLoaded.isRequestSent && !isConfigLoaded.isconfigLoadDone ? <AppConfig /> : <App config={config} />}
                </AMBreadcrumbProvider>
              </AuthProvider>
            </ThemeProvider>
          </ThemeP>
        </QueryParamProvider>
      </Router>
    </PartnerStateContextProvider>
  </>
}

ReactDOM.render(<IndexComp />, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
