import { AMToolTipAd } from '../../../../components/core/AMTooltipAd/AMTooltipAd';
import { getCategoryByKey } from '../../../../utils/util-methods';
import './PostureCategory.scss';

interface IPostureCategory {
	categories: Array<number>;
	children: any;
	label?: string;
	title?: string;
	type: string;
}

const TYPE = 'app';

export const PostureCategory = ({
	categories,
	children,
	label,
	title,
	type
}: IPostureCategory) => {
	return (
		<>
			<AMToolTipAd
				classes={{
					popper: 'am-posture-category-tooltip posture-root-column-container'
				}}
				title={
					<>
						<span style={{ fontWeight: 'bold' }}>{title}</span>
						{categories?.length > 0 &&
							categories?.filter((value: number) => value > 0)
								?.length > 0 &&
							(type || '').toLowerCase() == TYPE && (
								<hr
									style={{
										marginBottom: '5px',
										marginTop: '5px'
									}}
								></hr>
							)}
						{(type || '').toLowerCase() == TYPE &&
						categories?.length > 0 &&
						categories?.filter((value: number) => value > 0)
							?.length > 0 ? (
							<>
								{label && (
									<>
										<span
											style={{
												marginTop: 10,
												fontWeight: 'bold'
											}}
										>
											{label}
										</span>
										<hr
											style={{
												marginBottom: '5px',
												marginTop: '5px'
											}}
										/>
									</>
								)}
								<table>
									<thead>
										<tr>
											<th>Category</th>
										</tr>
									</thead>
									<tbody>
										{categories
											?.filter(
												(value: number) => value > 0
											)
											.map((category: number) => {
												return (
													<tr>
														<td>
															{getCategoryByKey(
																category
															)}
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</>
						) : (
							''
						)}
					</>
				}
			>
				{/* {item.type} */}
				{children}
			</AMToolTipAd>
		</>
	);
};
