import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function AMInfiniteScroll(props: any) {
    return (
        <>
            <InfiniteScroll
                dataLength={props.dataLength}
                next={props.next}
                hasMore={props.hasMore}
                scrollableTarget={props.scrollableTarget}
                loader={props.loader}
            >
                {props.children}
            </InfiniteScroll>
        </>
    )
}