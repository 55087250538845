import { RuleType } from "../../playbooks/constants/Constants";

export const ExceptionsList = [
    RuleType.AUTH_HASH_QUALITY,
    RuleType.AUTH_HASH_SECURITY,
    RuleType.AUTH_PROTOCOL_QUALITY,
    RuleType.COMPROMISED_PASSWORD,
    RuleType.COMPROMISED_USER,
    RuleType.EXPOSED_ASSETS,
    RuleType.LACK_OF_MFA,
    RuleType.SHADOW_ACCESS,
    RuleType.SHADOW_ASSETS,
    RuleType.SHADOW_DIRECTORY,
    RuleType.SHADOW_EXTERNAL_ACCESS,
    RuleType.SUSPICIOUS_INBOUND_ACCESS,
    RuleType.SUSPICIOUS_OUTBOUND_ACCESS,
    RuleType.UNKNOWN_ACCESS,
    RuleType.UNKNOWN_SAAS_ACCESS,
    RuleType.WEAK_PASSWORD,
    RuleType.DEVIATION_IN_DAILY_ASSET,
    RuleType.SUSPECTED_ATT_DIS_ACC,
    RuleType.SUSPECTED_ATT_EXP_ACC,
    RuleType.SUSPECTED_ATT_LOCK_ACC,
    RuleType.SUSPECTED_BRUTE_FORCE,
    RuleType.SUSPECTED_DIR_BOT,
    RuleType.SUSPECTED_PASS_SPRAY,
    RuleType.UNAUTH_LOGIN_DEVICE,
    RuleType.UNAUTH_LOGIN_TIME,
    RuleType.ENUM_AD_ADMINS,
    RuleType.ENUM_AD_USERS,
    RuleType.NTLM_RELAY_ATTACK,
    RuleType.ACCESS_FROM_UNAUTH_COUNTRIES,
    RuleType.ACCESS_TO_UNAUTH_COUNTRIES,    
    RuleType.ACCESS_FROM_ANON_IP,
    RuleType.ACCESS_TO_ANON_IP,
    RuleType.UNAUTHORIZED_ASSET_ACCESS,
    RuleType.UNAUTHORIZED_IDENTITY_ACCESS,
    RuleType.ACCESS_FROM_PUBLIC_VPN,
    RuleType.ACCESS_TO_PUBLIC_VPN,
    RuleType.ACCOUNT_TAKEOVER,
    RuleType.SUSPECTED_ACCESS_TOKEN,
    RuleType.ASSETS_WITHOUT_MFA
];

export type Exceptions = {
    _id: string;
    company_id: string;
    user_id: string;
    name: string;
    all_rules_enabled: boolean;
    flow_handling: number;
    rule_names: string[];
    src_ips: string[];
    src_port: number;
    src_hostnames: string[];
    src_type: string;
    dst_ips: string[];
    dst_port: number;
    dst_hostnames: string[];
    dst_type: string;
    protocols: string[];
    sensor_locations: string[];
    dropped_flows: number;
    s_names: string[];
    d_names: string[];
    dir_hostnames: string[];
}

export interface ExceptionProps {
    AfterExceptionsOperation?: any;
    id?: string;
    formType: string,
    actionType?:string
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const ExceptionMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    autoFocus: false
};

