import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { SuspectedPassSprayState } from "../../../../types/playbooks-config";

export const SuspectedPasswordSpray = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SuspectedPassSprayState>) => {
    const [state, setState] = useState<SuspectedPassSprayState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleNumAccWithBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { dir_bad_password_nuser_curhour: parseInt(e.target.value) } })
    }


    useEffect(() => {
        const op = state?.dir_params?.dir_bad_password_nuser_curhour;
        const object = {
            user_input1: op
        }
        if (getExpression) {
            getExpression(object);
        }
    })


    return <>
        <form className="suspected-pass-spray-container" onSubmit={(e) => e.preventDefault()}>
            <div className="suspected-pass-spray-row bold-text">
                <label className="playbook-config-title marginBottom0">
                    A directory/IdP will be marked as under password spray attack when:</label>
            </div>
            <div className="suspected-pass-spray-row">
                <label>
                    Number of accounts with wrong / bad passwords in an hour is more than
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params.dir_bad_password_nuser_curhour}</label> :
                        <input type="number" onChange={handleNumAccWithBadPass} name="num_acc_bad_pass" value={state?.dir_params.dir_bad_password_nuser_curhour} />}
            </div>
        </form>
    </>
}