import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { Api } from "../../components/Axios";
import { useToasts } from '../../components/core';
import './SensorDownload.scss'
import { GlobalConsts } from '../../GlobalConst';
const { REACT_APP_HOST_IP } = process.env

interface Sensor {
    download_type: string;
    versions: string[];
}

interface ApiResponse {
    data: Sensor[];
}

interface ComponentState {
    sensors: Sensor[];
    loading: boolean;
    error: string | null;
    selectedType: string;
    selectedVersion: string;
}

const initialState: ComponentState = {
    sensors: [],
    loading: true,
    error: null,
    selectedType: '',
    selectedVersion: '',
};

interface DeploymentData {
    sensorType: string;
    url: string;
}

const deploymentGuides: DeploymentData[] = [
    {
        sensorType: "AD Sensor",
        url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-active-directory-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
        sensorType: "Flow Sensor",
        url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-a-flow-sensor?${GlobalConsts.DOC_TOKEN}`,
    },
    {
        sensorType: "Enrichment Sensor",
        url: `${GlobalConsts.DEPLOYMENT_LINK}/installing-an-enrichment-sensor?${GlobalConsts.DOC_TOKEN}`,
    }
];

const SensorDownload: React.FC = () => {
    const [listData, setListData] = useState<ComponentState>(initialState);
    const { addToast } = useToasts();

    const handleGuideClick = (downloadType: string) => {
        const selectedGuide = deploymentGuides.find((guide) => guide.sensorType.toLowerCase() === downloadType.toLowerCase());

        if (selectedGuide) {
            window.open(selectedGuide.url, '_blank');
        }
    };

    useEffect(() => {
        Api.get('/download_list')
            .then((res: ApiResponse) => {
                setListData({
                    sensors: res.data,
                    loading: false,
                    error: null,
                    selectedType: '',
                    selectedVersion: '',
                });
            })
            .catch((error: any) => {
                if (error.response && error.response.status === 500) {
                    setListData({
                        sensors: [],
                        loading: false,
                        error: 'Internal Server Error',
                        selectedType: '',
                        selectedVersion: '',
                    });
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    setListData({
                        sensors: [],
                        loading: false,
                        error: 'Error fetching data',
                        selectedType: '',
                        selectedVersion: '',
                    });
                    addToast("Error occurred while fetching download information.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }, []);

    const handleDownload = async (downloadType: string, version: string) => {
        const datas = window.localStorage.getItem('user');
        if (datas !== null) {
            const user = JSON.parse(datas);
            const downloadUrl = `${REACT_APP_HOST_IP}/api/download?download_type=${encodeURIComponent(downloadType)}&version=${encodeURIComponent(version)}&auth_token=${encodeURIComponent(user.token)}`;
            window.open(downloadUrl);

        } else {
            console.error('Please select both Sensor Type and Version before downloading.');
        }
    };

    const renderTable = () => {
        return <>
            <div className="page_title_area">
                <h2 className='font18'>Downloads</h2>
            </div>
            {/* <div className="box_grid_layout_sensor_download"> */}
            <div>
                <table id="table" className="ticket_tb sticky_table_top">
                    <thead>
                        <tr>
                            <th><span className='align_left float_left'>Download Type</span></th>
                            <th><span className='align_left float_left'>Download Links</span></th>
                            <th><span className='align_left float_left'>Deployment Guide</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData && listData.sensors.map((sensor: Sensor, index) => (


                            <tr key={sensor?.download_type}>
                                <td className="align_left">
                                    {sensor?.download_type}
                                </td>
                                <td>
                                    {sensor?.versions.map((version: string, index: number) => (
                                        <React.Fragment key={version}>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDownload(sensor.download_type, version);
                                                }}
                                                style={{ cursor: 'pointer', textDecoration: 'underline'}}
                                                className='link-text'
                                            >
                                                {version}
                                            </a>
                                            {index < sensor.versions.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                </td>

                                <td>
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleGuideClick(sensor.download_type);
                                        }}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        className='link-text'
                                    >
                                        {sensor?.download_type} Deployment Guide
                                    </a>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    }

    return (
        <div className="downloads-container">
            {renderTable()}
        </div>
    )

}

export default withQueryParams({
    filters: withDefault(ArrayParam, []),
    launchApp: StringParam
}, SensorDownload)