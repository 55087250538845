import React, {useState, useRef, useEffect} from 'react';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchStateContext
} from '../useTimeBasedSearch';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import { getResolutionMatch } from '../../../utils/util-methods';
import moment from 'moment';
import ACTION_TYPES from '../TimeBasedSearchActionTypes';
import { dateToEpoch } from '../utils';
import { AlertComponent } from '../../../pages/sensors/AlertComponent';

const TimeBasedSearchChartComponent = ({flowsData, primaryColor = '#9fa1ae'}) => {
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const [chartOptions, setChartOptions] = useState(null);
    const softMin = (flowsData[0][0]) && flowsData.length < 24 ? {softMin: (flowsData[0][0] - 86400 * 1000 * 5) } : {};
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    const updateVal = (min, max) => {
        let startDate = moment(min).set('hour', 0).set('minute', 0).valueOf();
        let endDate = moment(max).set('hour', 23).set('minute', 59)
        if (endDate.isAfter(moment())) {
            endDate = moment().valueOf();
        } else { 
            endDate = moment(endDate) instanceof moment ? endDate.valueOf() : moment(endDate).valueOf();
        }

        dispatchAction({
            type: ACTION_TYPES.SELECT_DATE_RANGE_HISTOGRAM,
            value: [
                startDate,
                endDate
            ]
        });
    }

    Highcharts.setOptions({
        lang: {
            rangeSelectorFrom: '',
            rangeSelectorTo: '-'
        },
        time: {
            useUTC: false,
          }
    });

    useEffect(() => {
        setChartOptions({
            credits: {
                enabled: false
            },
            xAxis: {
                crosshair: false,
                lineWidth: 0,
                tickLength: 0,
                labels: {
                    enabled: false
                },
                events: {
                    afterSetExtremes(e) {
                        const output = `${new Date(e.min)} - ${new Date(e.max)}`
                        updateVal(e.min,e.max);
                    }
                }
            },            
            yAxis: {
                crosshair:false,
                height: 0,
                gridLineWidth: 0,
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            rangeSelector: {
                enabled: true,
                inputEnabled: true,
                inputStyle: {
                    color: 'black',
                    fontWeight: 'normal',
                    fontFamily: 'Metropolis-Regular'
                },
                inputDateFormat: `%b %e %Y`,
                buttons: []
            },
            scrollbar: {
                buttonBackgroundColor: 'transparent',
                buttonArrowColor: 'transparent',
                trackBorderColor: 'transparent',
                trackBackgroundColor: 'transparent',
                buttonBorderWidth: 0,
                height:getResolutionMatch(25,10)
            },
            navigator: {
                useHTML: true,
                tooltip: {
                    enabled: false
                },
                height: getResolutionMatch(100, 55),
                handles: {
                    height:getResolutionMatch(35,20),
                    width:getResolutionMatch(15,7),
                },
                series: {
                    color: primaryColor,
                    marker: {
                        enabled:false,
                            fillColor: '#FFFFFF',
                            lineWidth: 0,
                            lineColor: primaryColor
                        },
                    useHTML:true,
                    type: 'areaspline',
                    fillOpacity: 0.2,
                    dataGrouping: {
                        smoothed: true
                    },
                    lineWidth: 1,
                },
                xAxis: {
                    //    ...softMin,
                    min: moment().subtract(60, 'days').valueOf(), // Example: 30 days before today
                    max: moment().valueOf(), // Example: today
                   labels: {
                       y:getResolutionMatch(50,35),
                       x:getResolutionMatch(200,40),
                        useHTML:true,
                        align:'left',
                        step: getResolutionMatch(4,2),
                        formatter: function () {
                            if(moment(this.value).isSame(moment(),'day')) {
                                return `<span class='flow-x-axis-label-navigator'><b>Today</b><span>`;
                            }
                            return `<span class='flow-x-axis-label-navigator'><b>${moment(this.value).format("MMM DD YYYY")}</b><span>`;
                        }
                    },
                    plotBands: [{
                        from: moment().subtract(60, 'days').valueOf(),
                        to: moment().subtract(60, 'days').valueOf(),
                        label: {
                            text: moment().subtract(60, 'days').format("MMM DD YYYY"),
                            align: 'left',
                            rotation: 0,
                            x: -15,
                            y: 20,
                            useHTML: true,
                            style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize:'1em'
                            }
                        }
                    }, {
                        from: moment().valueOf(),
                        to: moment().valueOf(),
                        label: {
                            text: moment().format("MMM DD YYYY"),
                            align: 'right',
                            rotation: 0,
                            x: 0,
                            y: 20,
                            useHTML: true,
                            style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize:'1em'
                            }
                        }
                    }]
                },
            },
            series: [{
                lineWidth: 0,
                marker: {
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false
                    }
                  }
                },
                data: flowsData || []

              }]
        });
    }, []);

    useEffect(() => {
        setTimeout(() => { 
            if (chartRef.current?.chart) {
                if (String(store.selectedStartDateHistogram).length > 10 && String(store.selectedStartDateHistogram).length > 10) {
                    chartRef.current?.chart?.xAxis[0].setExtremes(store.selectedStartDateHistogram, store.selectedEndDateHistogram);
                } else {
                    chartRef.current?.chart?.xAxis[0].setExtremes(Number(store.selectedStartDateHistogram) * 1000, Number(store.selectedEndDateHistogram) * 1000);
                }
            }
        }, 100)
    }, []);

    return (
        <div className='histogram-container'>
            {store.isApplyDisabled && <AlertComponent messages={[{ type: "error", isSummary: false, text: "Adjust your date range to be less than 15 days." }]} />}
            <div className='histogram-chart-container'>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} constructorType={"stockChart"}  />
            </div>
        </div>
    );
};

export default TimeBasedSearchChartComponent;
