import { RuleType } from "../../constants/Constants";
import { IIdAssetPopUpFormConfig } from "../IdPopUp";
import { IPatternConfigItem, IPatternFormValue } from "../PatternForm/PatternFormGenerator";
import { validateIPAddress } from "./Pattern-Utils";

const OperatorsMap = {
    equals: 'equals', not_equals: 'not equals', matches: 'matches', not_matches: 'not matches'
}

const EQUALS = { id: OperatorsMap.equals, label: 'equals' };
const NOT_EQUALS = { id: OperatorsMap.not_equals, label: 'not equals' };
const MATCHES = { id: OperatorsMap.matches, label: 'matches' };
const NOT_MATCHES = { id: OperatorsMap.not_matches, label: 'not matches' };

const OperatorsArray = [
    { ...EQUALS }, { ...NOT_EQUALS }, { ...MATCHES }, { ...NOT_MATCHES }
]


export const InterPattern = [
    {
        id: 'src_inter_ip',
        type: 'text',
        operators: [
            { ...EQUALS, placeholder: 'Enter comma separated identity ips (Ex. Ips - 17.34.12.12)' },
            { ...NOT_EQUALS, placeholder: 'Enter comma separated identity ips (Ex. Ips - 17.34.12.12)' },
            { ...MATCHES, placeholder: 'Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)' },
            { ...NOT_MATCHES, placeholder: 'Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)' }
        ],
        label: "Access Control IP",
        placeholder: 'Enter comma separated identity ips (ex. Ips - 17.34.12.12)',
        defaultOperator: OperatorsMap.equals,
        validate: function (rowValue: IPatternFormValue) {
            const er = validateIPAddress(rowValue);
            this.error = er;
            return er;
        }
    },
    {
        id: 'dir_name',
        type: 'text',
        operators: [
            ...OperatorsArray
        ],
        label: "Directory/IDP Name",
        placeholder: 'Enter comma separated directory names',
        defaultOperator: OperatorsMap.equals
    },
    {
        id: 'dir_hostname',
        type: 'text',
        operators: [
            ...OperatorsArray
        ],
        label: "Directory/IDP Hostname",
        placeholder: 'Enter comma separated directory hostnames',
        defaultOperator: OperatorsMap.equals
    }
];

export const IdPattern = [{
    id: 'name',
    type: 'text',
    operators: [
        { ...EQUALS }, { ...NOT_EQUALS }, { ...MATCHES, placeholder: 'Enter comma separated identity names (Ex. *@authmind.com ,  s1*@*.authmind.com)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated identity names (Ex. *@authmind.com ,  s1*@*.authmind.com)' }
    ],
    label: "Identity/Source Name",
    placeholder: 'Enter comma separated identity names (Ex. acme\joe, suzi@acme)',
    defaultOperator: OperatorsMap.equals,
    /* validate: function (rowValue: IPatternFormValue) {
        const er = validateEntityName(rowValue);
        this.error = er;
        return er;
    } */
},
{
    id: 'hostname',
    type: 'text',
    operators: [
        {
            ...EQUALS,
            placeholder: 'Enter comma separated identity hostnames (Ex. console.authmind.com)'
        }, {
            ...NOT_EQUALS,
            placeholder: 'Enter comma separated identity hostnames (Ex. console.authmind.com)'
        },
        { ...MATCHES, placeholder: 'Enter comma separated identity hostnames (Ex. console.*.com)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated identity hostnames (Ex. console.*.com)' }
    ],
    label: "Identity/Source Hostname",
    placeholder: 'Enter comma separated identity hostnames',
    defaultOperator: OperatorsMap.equals
},
{
    id: 'ip',
    type: 'text',
    operators: [
        { ...EQUALS, placeholder: 'Enter comma separated identity ips (Ex. Ips - 17.34.12.12)' },
        { ...NOT_EQUALS, placeholder: 'Enter comma separated identity ips (Ex. Ips - 17.34.12.12)' },
        { ...MATCHES, placeholder: 'Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)' }
    ],
    label: "Identity/Source IP",
    placeholder: 'Enter comma separated identity ips (ex. Ips - 17.34.12.12)',
    defaultOperator: OperatorsMap.equals,
    validate: function (rowValue: IPatternFormValue) {
        const er = validateIPAddress(rowValue);
        this.error = er;
        return er;
    }
},
{
    id: 'src_inter_ip',
    type: 'text',
    operators: [
        { ...EQUALS, placeholder: 'Enter comma separated identity ips (Ex. Ips - 17.34.12.12)' },
        { ...NOT_EQUALS, placeholder: 'Enter comma separated identity ips (Ex. Ips - 17.34.12.12)' },
        { ...MATCHES, placeholder: 'Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)' }
    ],
    label: "Access Control IP",
    placeholder: 'Enter comma separated identity ips (ex. Ips - 17.34.12.12)',
    defaultOperator: OperatorsMap.equals,
    validate: function (rowValue: IPatternFormValue) {
        const er = validateIPAddress(rowValue);
        this.error = er;
        return er;
    },
    unsupportedPb: {
        [RuleType.SUSPECTED_ATT_DIS_ACC]: true,
        [RuleType.SUSPECTED_ATT_EXP_ACC]: true,
        [RuleType.SUSPECTED_ATT_LOCK_ACC]: true,
        [RuleType.SUSPECTED_BRUTE_FORCE]: true,
        [RuleType.SUSPECTED_DIR_BOT]: true,
        [RuleType.SUSPECTED_PASS_SPRAY]: true,
        [RuleType.ENUM_AD_ADMINS]: true,
        [RuleType.ENUM_AD_USERS]: true,
        [RuleType.UNAUTH_LOGIN_DEVICE]: true,
        [RuleType.UNAUTH_LOGIN_TIME]: true,
        [RuleType.NTLM_RELAY_ATTACK]: true,
        [RuleType.UNAUTHORIZED_ASSET_ACCESS]: true,
        [RuleType.UNAUTHORIZED_IDENTITY_ACCESS]: true
    }
}
] as Array<IPatternConfigItem>;

export const AssetPattern = [{
    id: 'name',
    type: 'text',
    operators: [
        {
            ...EQUALS,
            placeholder: 'Enter comma separated asset names (Ex. AWS S3)'
        }, {
            ...NOT_EQUALS,
            placeholder: 'Enter comma separated asset name (Ex. AWS S3)'
        },
        { ...MATCHES, placeholder: 'Enter comma separated asset name (Ex. AWS*)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated asset name (Ex. AWS*)' }
    ],
    label: "Asset/Destination Name",
    placeholder: 'Enter comma separated asset names',
    defaultOperator: OperatorsMap.equals,
    /*  validate: function (rowValue: IPatternFormValue) {
         const er = validateEntityName(rowValue);
         this.error = er;
         return er;
     } */
},
{
    id: 'hostname',
    type: 'text',
    operators: [
        {
            ...EQUALS,
            placeholder: 'Enter comma separated asset hostnames (Ex. console.authmind.com)'
        }, {
            ...NOT_EQUALS,
            placeholder: 'Enter comma separated asset hostnames (Ex. console.authmind.com)'
        },
        { ...MATCHES, placeholder: 'Enter comma separated asset hostnames (Ex. console.*.com)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated asset hostnames (Ex. console.*.com)' }
    ],
    label: 'Asset/Destination Hostname',
    placeholder: 'Enter comma separated asset hostnames',
    defaultOperator: OperatorsMap.equals
},
{
    id: 'ip',
    type: 'text',
    operators: [
        { ...EQUALS }, { ...NOT_EQUALS }, { ...MATCHES, placeholder: 'Enter comma separated asset ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24)' },
        { ...NOT_MATCHES, placeholder: 'Enter comma separated asset ips (Ex. Ips - 172.9.*,, CIDR - 192.168.0.1/24)' }
    ],
    label: "Asset/Destination IP",
    placeholder: 'Enter comma separated asset ips (Ex. Ips - 17.34.12.12)',
    defaultOperator: OperatorsMap.equals,
    validate: function (rowValue: IPatternFormValue) {
        const er = validateIPAddress(rowValue);
        this.error = er;
        return er;
    }
},
{
    id: 'dir_name',
    type: 'text',
    operators: [
        ...OperatorsArray
    ],
    label: "Directory/IDP Name",
    placeholder: 'Enter comma separated directory names',
    defaultOperator: OperatorsMap.equals
},
{
    id: 'dir_hostname',
    type: 'text',
    operators: [
        ...OperatorsArray
    ],
    label: "Directory/IDP Hostname",
    placeholder: 'Enter comma separated directory hostnames',
    defaultOperator: OperatorsMap.equals
}
] as Array<IPatternConfigItem>;


export const IdPatternFormConfig = {
    fields: [
        {
            id: 'all_sources',
            name: 'sources',
            value: 'All Identities',
            label: 'All Identities'
        }
    ],
    defaultValue: 'All Identities',
    defaultChecked: "All Identities"
} as IIdAssetPopUpFormConfig;

export const InterPatternFormConfig = {
    fields: [
        {
            id: 'any_intermediary',
            name: 'intermediary',
            value: 'Any Access Control',
            label: 'Any Access Control'
        }
    ],
    defaultValue: 'All Identities',
    defaultChecked: "All Identities"
} as IIdAssetPopUpFormConfig;

export const AssetPatternFormConfig = {
    fields: [
        {
            id: 'all_assets',
            name: 'assets',
            value: 'All Assets',
            label: 'All Assets'
        }
    ],
    defaultValue: 'All Assets',
    defaultChecked: "All Assets"
} as IIdAssetPopUpFormConfig;

export const IdPatternFencingConfig = {
    ...IdPatternFormConfig,
    fields: [...IdPatternFormConfig.fields,
    {
        id: 'no_sources',
        name: 'no_sources',
        value: 'No Identities',
        label: 'No Identities'
    }],
    defaultValue: 'Custom List',
    defaultChecked: "Custom List"
} as IIdAssetPopUpFormConfig;


export const AssetPatternFencingConfig = {
    ...AssetPatternFormConfig,
    fields: [...AssetPatternFormConfig.fields,
    {
        id: 'no_assets',
        name: 'no_assets',
        value: 'No Assets',
        label: 'No Assets'
    }],
    defaultValue: 'Custom List',
    defaultChecked: "Custom List"
} as IIdAssetPopUpFormConfig;

export const loadAssetPatternFormConfig = (rule: string) => {
    const p = [...AssetPattern] as Array<IPatternConfigItem>;
    const ExcludeList = [
        RuleType.ACCESS_FROM_UNAUTH_COUNTRIES,
        RuleType.ACCESS_TO_UNAUTH_COUNTRIES,
        RuleType.ACCESS_FROM_ANON_IP,
        RuleType.ACCESS_TO_ANON_IP,
        RuleType.ACCESS_FROM_PUBLIC_VPN,
        RuleType.ACCESS_TO_PUBLIC_VPN,
        RuleType.AUTH_PROTOCOL_QUALITY,
        RuleType.DEVIATION_IN_DAILY_ASSET,
        RuleType.EXPOSED_ASSETS,
        RuleType.LACK_OF_MFA,
        RuleType.SHADOW_ACCESS,
        RuleType.SHADOW_ASSETS,
        RuleType.SHADOW_EXTERNAL_ACCESS,
        RuleType.SUSPICIOUS_INBOUND_ACCESS,
        RuleType.SUSPICIOUS_OUTBOUND_ACCESS,
        RuleType.UNKNOWN_SAAS_ACCESS
    ]
    if (rule) {
        if (ExcludeList.filter((i: RuleType) => i?.toString() === rule).length > 0) {
            const dir_h_index = p.findIndex((i: IPatternConfigItem) => i?.id == 'dir_hostname');
            p.splice(dir_h_index, 1);
            const dir_index = p.findIndex((i: IPatternConfigItem) => i?.id == 'dir_name');
            p.splice(dir_index, 1);
        }
    }
    return p;
}