import React, { useState, useEffect, FC, ReactElement, useLayoutEffect, useRef } from 'react';
import { Api } from '../Axios';
import './TickerStyle.scss';

const DEFAULT_MESSAGE = `An archive has been started by your Administrator. Please be aware that results displayed in the 'Incidents' and 'Identity Security Posture' sections will be updating during this archive.`;
const POLLING_INTERVAL = {
    SHORT: 30000,
    LONG: 120000
}

const Ticker: React.FC<void> = (): ReactElement => {
    const [showTicker, setShowTicker] = useState<boolean>(false);
    const [pollingInterval, setPollingInterval] = useState<number>(POLLING_INTERVAL.SHORT);
    let tickerStatusInterval: any;

    useEffect(() => {
        try {
            fetchTickerStatus();
        } catch (exception) { 
            console.log(exception);
        }
    }, [])

    const fetchTickerStatus = async (): Promise<void> => { 
        Api.get('/ticker/status')
            .then(res => {
                if (res.status === 200) {
                    const tickerStatus = res.data.posture_archive ? res.data.posture_archive : false;
                    setShowTicker(tickerStatus);
                    tickerStatus && setPollingInterval(POLLING_INTERVAL.SHORT);
                    !tickerStatus && setPollingInterval(POLLING_INTERVAL.LONG);
                }
            })
            .catch(() => {
                setShowTicker(false);
                setPollingInterval(POLLING_INTERVAL.LONG);
            })
    }

    useEffect(() => {
        tickerStatusInterval = setInterval(() => {
            fetchTickerStatus();
        }, pollingInterval);
        return () => clearInterval(tickerStatusInterval);
    }, [pollingInterval]);

    const onClose = (): void => {
        setShowTicker(false);
    }

    const htmlElemExists = (elem: any) => { 
        return typeof elem !== 'undefined' && elem !== null;
    }

    const getHtmlElem = (selector: string) => {
        return htmlElemExists(document.querySelector(selector)) ? document.querySelector(selector) : null; 
    }

    const setStyle = (elem: any, property: string, value: string): void => { 
        elem.style[property] = value; 
    }

    // useLayoutEffect(() => {
    //     let containerElem = getHtmlElem('.container');
    //     let navbarElem = getHtmlElem('.navbar');
    //     let tableHeadElem = getHtmlElem('table.sticky_table_top thead');
    //     let searchModalElem = getHtmlElem('.search-filter-container');
    //     if (showTicker) {
    //         if (window.innerWidth > 2560) {
    //             containerElem && setStyle(containerElem, 'paddingTop', '150px');
    //             navbarElem && setStyle(navbarElem, 'top', '40px');
    //             tableHeadElem && setStyle(tableHeadElem, 'top', '40px');
    //             searchModalElem && setStyle(searchModalElem, 'top', '140px');
    //         } else {
    //             containerElem && setStyle(containerElem, 'paddingTop', '75px');
    //             navbarElem && setStyle(navbarElem, 'top', '20px');
    //             tableHeadElem && setStyle(tableHeadElem, 'top', '20px');
    //             searchModalElem && setStyle(searchModalElem, 'top', '70px');
    //         }
    //     } else {
    //         if (window.innerWidth > 2560) {
    //             containerElem && setStyle(containerElem, 'paddingTop', '110px');
    //             searchModalElem && setStyle(searchModalElem, 'top', '100px');
    //         } else {
    //             containerElem && setStyle(containerElem, 'paddingTop', '55px');
    //             searchModalElem && setStyle(searchModalElem, 'top', '50px');
    //         }
    //         navbarElem && setStyle(navbarElem, 'top', '0px');
    //         tableHeadElem && setStyle(tableHeadElem, 'top', '0px');
    //     }
    // }, [showTicker]);

    const adjustStyles = () => {
        let containerElem = getHtmlElem('.container');
        let navbarElem = getHtmlElem('.navbar');
        let tableHeadElem = getHtmlElem('table.sticky_table_top thead');
        let searchModalElem = getHtmlElem('.search-filter-container');
        if (showTicker) {
            if (window.innerWidth > 2550) {
                containerElem && setStyle(containerElem, 'paddingTop', '150px');
                navbarElem && setStyle(navbarElem, 'top', '40px');
                tableHeadElem && setStyle(tableHeadElem, 'top', '40px');
                searchModalElem && setStyle(searchModalElem, 'top', '140px');
            } else {
                containerElem && setStyle(containerElem, 'paddingTop', '75px');
                navbarElem && setStyle(navbarElem, 'top', '20px');
                tableHeadElem && setStyle(tableHeadElem, 'top', '20px');
                searchModalElem && setStyle(searchModalElem, 'top', '70px');
            }
        } else {
            if (window.innerWidth > 2550) {
                containerElem && setStyle(containerElem, 'paddingTop', '110px');
                searchModalElem && setStyle(searchModalElem, 'top', '100px');
            } else {
                containerElem && setStyle(containerElem, 'paddingTop', '55px');
                searchModalElem && setStyle(searchModalElem, 'top', '50px');
            }
            navbarElem && setStyle(navbarElem, 'top', '0px');
            tableHeadElem && setStyle(tableHeadElem, 'top', '0px');
        }
    };

    useLayoutEffect(() => {
        adjustStyles();
    }, [showTicker]);

 


    if (!showTicker) return <></>;

    return (
        <div className="ticker-container">
            <div className="ticker-item-list">
                <span className="ticker-item">{DEFAULT_MESSAGE}</span>
            </div>
            <div className="ticker-close-btn" onClick={onClose}>X</div>
        </div>
    );
}

export default Ticker;