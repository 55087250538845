import React, { useEffect, useState } from "react"
import { Api } from '../../components/Axios'
import { AxiosResponse } from "axios";
import Popup from "reactjs-popup";
import './ReleaseNotes.scss'
import { usePartnerStateContext } from "../../store/PartnerContextProvider";
import { GlobalConsts } from "../../GlobalConst";
import { useAuthDetails } from "../../components/Authorization";
import { isAmopsUser } from "../../utils/util-methods";

interface IReleaseNote {
    doc_release_viewed: string,
    release_link: string,
    is_released: boolean
}

interface IReleaseNoteProps {
    onClose(): void
}

export const ReleaseNotes = ({ onClose }: IReleaseNoteProps) => {
    const [releaseNotes, setReleaseNotes] = useState<IReleaseNote>();
    const { authDetails } = useAuthDetails()!;

    const { PartnerConfigState } = usePartnerStateContext();

    useEffect(() => {
        Api.get("/userrelease").then((res: AxiosResponse<IReleaseNote>) => {
            if (res.status == 200) {
                if (res.data && Object.entries(res.data).length > 0) {
                    setReleaseNotes(res.data);
                    sessionStorage.setItem('release_notes', res.data.release_link)
                }
            }
        }).catch((err) => console.log(err));
    }, [])

    const onCloseReleaseNotes = () => {
        setReleaseNotes(undefined);
    }

    const clickReleaseNotes = () => {
        if (releaseNotes?.is_released) {
            window.open(`${releaseNotes?.release_link}?${GlobalConsts.DOC_TOKEN}`, '_blank')
        }
    }

    return <>
        {
            releaseNotes?.is_released && !isAmopsUser(authDetails.email) && <Popup
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                open={true}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                onClose={onCloseReleaseNotes}
                modal
            >
                <div className="modal release-notes-modal">
                    <div className={"white-label-logo " + (PartnerConfigState?.PartnerName != 'AuthMind' ? " ibm-white-logo" : '')} style={{
                        background: `url(${PartnerConfigState.PartnerTeaserLogo}) 50% / cover no-repeat`
                    }}></div>
                    <div className="update-title">New Update Available</div>
                    <div className="notes-title">
                        <span className="notes-title-text">To learn more, read the full details here:</span>
                        <span className="notes-title-link" onClick={clickReleaseNotes}><u>Release Notes</u></span>
                    </div>
                    <div className="release-notes-btn">
                        <div className="release-notes-primary-btn" onClick={onCloseReleaseNotes}>OK</div>
                    </div>
                </div>
            </Popup >
        }
    </>
}