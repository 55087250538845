import React from 'react';
import './risky-protocols-skeleton.scss'

export const RiskyProtocolSkeleton = ()=>{
    return <>
     <div className='skeleton-risky-protocol-container animate-pulse'>
                <div className='skeleton-title'></div>
                <div className='skeleton-content'>
                  <div className='left-side'></div>
                  <div className='right-side'></div>
                </div>

            </div>
    </>
}