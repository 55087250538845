import { IAMSelectOption } from "../../../components/core/AMSelect/AMSelect";
import { IPatternConfigItem, IPatternFormValue } from "../ref/PatternForm/PatternFormGenerator";
import { validateIPAddress, validateIpPort, validateMACAddresses } from "../ref/config/Pattern-Utils";

const OperatorsMap = {
	equals: 'equals', not_equals: 'not equals', matches: 'matches', not_matches: 'not matches',
	contains: 'contains'
}

const EQUALS = { id: OperatorsMap.equals, label: 'equals' };
const NOT_EQUALS = { id: OperatorsMap.not_equals, label: 'not equals' };
const MATCHES = { id: OperatorsMap.matches, label: 'matches' };
const NOT_MATCHES = { id: OperatorsMap.not_matches, label: 'not matches' };
const CONTAINS = { id: OperatorsMap.contains, label: 'contains' };


const OperatorsArray = [
	{ ...EQUALS }, { ...NOT_EQUALS }, { ...MATCHES }, { ...NOT_MATCHES }
]

const ListYesNoOptions = [
	{ optionLabel: 'Yes', optionValue: 'true' }, { optionLabel: 'No', optionValue: 'false' }] as Array<IAMSelectOption>;

export const PlaybookAdvanceddSearchConfig = [
	{
		"id": "destination_asset",
		"label": "Asset/Destination",
		"isGroupRoot": true,
		"groupId": "destination_asset",
		"key": "destination_asset",
		"value": "Asset/Destination"
	},
	{
		"id": "dest_countries",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Country",
		"placeholder": "Select countries",
		"defaultOperator": "equals",
		"asyncId": "dest_countries",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "dest_countries",
		"value": "Country",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Hostname",
		"placeholder": "Enter comma separated asset hostnames",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_hostname",
		"value": "Hostname",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "matches",
				"label": "matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)"
			},
			{
				"id": "not matches",
				"label": "not matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)"
			}
		],
		"label": "IP",
		"placeholder": "Enter comma separated Ips",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_ip",
		"value": "IP",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_is_public",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is Public?",
		"placeholder": "Select Yes if asset ip is public",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "d_is_public",
		"value": "IP is Public?",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_is_cdn",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is a CDN?",
		"placeholder": "Select Yes if asset ip is cdn",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "d_is_cdn",
		"value": "IP is a CDN?",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_is_datacenter",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is a Data Center?",
		"placeholder": "Select Yes if asset ip is data center",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "d_is_datacenter",
		"value": "IP is a Data Center?",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_is_icloudrelday",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is an iCloud Relay?",
		"placeholder": "Select Yes if asset ip is an iCloud Relay",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "d_is_icloudrelday",
		"value": "IP is an iCloud Relay?",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_organization",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "IP Owner Organization (aka “Company”)",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_organization",
		"value": "IP Owner Organization (aka “Company”)",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_mac",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "MAC",
		"placeholder": "Enter comma separated asset/destination mac",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_mac",
		"value": "MAC",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Name",
		"placeholder": "Enter comma separated asset/destination name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_name",
		"value": "Name",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_type",
		"type": "list",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"label": "Type",
		"placeholder": "Select asset/destination type",
		"defaultOperator": "equals",
		"asyncId": "d_type",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_type",
		"value": "Type",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_port",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Port",
		"placeholder": "Enter comma separated asset/destination port",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_port",
		"value": "Port",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_is_known",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Hostname/IP is marked as known?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "d_is_known",
		"value": "Hostname/IP is marked as known?",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "d_protocol",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Protocol",
		"placeholder": "Select protocol",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "destination_asset",
		"key": "d_protocol",
		"value": "Protocol",
		"groupBy": "Asset/Destination"
	},
	{
		"id": "t_protocol",
		"type": "list",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Transport Protocol",
		"placeholder": "Enter Transport Protocols",
		"defaultOperator": "equals",
		"valueType": "string",
		"selectOptions": [
			{
				"optionLabel": "TCP",
				"optionValue": "TCP"
			},
			{
				"optionLabel": "UDP",
				"optionValue": "UDP"
			}
		],
		"groupId": "destination_asset",
		"key": "t_protocol",
		"value": "Transport Protocol",
		"groupBy": "Asset/Destination"
	},
	{
		"casing": "camel",
		"label": "Category",
		"defaultOperator": "equals",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"id": "dst_category",
		"valueType": "string-array",
		"type": "multi-select",
		"groupId": "destination_asset",
		"key": "dst_category",
		"value": "Category",
		"groupBy": "Asset/Destination",
		placeholder: "Select asset/destination category"
	},
	{
		"id": "source_identity",
		"label": "Identity/Source",
		"isGroupRoot": true,
		"groupId": "source_identity",
		"key": "source_identity",
		"value": "Identity/Source"
	},
	{
		"id": "src_countries",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Country",
		"placeholder": "Select countries",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "src_countries",
		"value": "Country",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Hostname",
		"placeholder": "Enter comma separated identity/source hostnames",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_hostname",
		"value": "Hostname",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "matches",
				"label": "matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)"
			},
			{
				"id": "not matches",
				"label": "not matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)"
			}
		],
		"label": "IP",
		"placeholder": "Enter comma separated Ips",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_ip",
		"value": "IP",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_is_public",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is Public?",
		"placeholder": "Select Yes if source ip is public",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_is_public",
		"value": "IP is Public?",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_is_cdn",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is a CDN?",
		"placeholder": "Select Yes if asset ip is cdn",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_is_cdn",
		"value": "IP is a CDN?",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_is_datacenter",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is a Data Center",
		"placeholder": "Select Yes if source ip is data center",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_is_datacenter",
		"value": "IP is a Data Center",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_is_icloudrelday",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "IP is an iCloud Relay?",
		"placeholder": "Select Yes if asset ip is an iCloud Relay",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_is_icloudrelday",
		"value": "IP is an iCloud Relay?",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_organization",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "IP Owner Organization",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_organization",
		"value": "IP Owner Organization",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_mac",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "MAC",
		"placeholder": "Enter comma separated source mac",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_mac",
		"value": "MAC",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Username or Name",
		"placeholder": "Enter comma separated source username or name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_name",
		"value": "Username or Name",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_type",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Type",
		"placeholder": "Select identity/source type",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_type",
		"value": "Type",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_port",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Port",
		"placeholder": "Enter comma separated source port",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "s_port",
		"value": "Port",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_is_known",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Hostname/IP is marked as known?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_is_known",
		"value": "Hostname/IP is marked as known?",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_susp_internal_scanner",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "is a Potential Internal Scanner?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_susp_internal_scanner",
		"value": "is a Potential Internal Scanner?",
		"groupBy": "Identity/Source"
	},
	{
		"id": "s_susp_external_scanner",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "is a Suspected External Scanner?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "source_identity",
		"key": "s_susp_external_scanner",
		"value": "is a Suspected External Scanner?",
		"groupBy": "Identity/Source"
	},
	{
		"casing": "camel",
		"label": "Category",
		"defaultOperator": "equals",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"id": "src_category",
		"valueType": "string-array",
		"type": "multi-select",
		"groupId": "source_identity",
		"key": "src_category",
		"value": "Category",
		"groupBy": "Identity/Source",
		placeholder: "Select identity/source category"
	},
	{
		"id": "directory",
		"label": "Directory",
		"isGroupRoot": true,
		"groupId": "directory",
		"key": "directory",
		"value": "Directory"
	},
	{
		"id": "dir_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Hostname",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "directory",
		"key": "dir_hostname",
		"value": "Hostname",
		"groupBy": "Directory"
	},
	{
		"id": "dir_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "directory",
		"key": "dir_name",
		"value": "Name",
		"groupBy": "Directory"
	},
	{
		"id": "dir_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "matches",
				"label": "matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)"
			},
			{
				"id": "not matches",
				"label": "not matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)"
			}
		],
		"label": "IP",
		"placeholder": "Enter comma separated directory/IDP ips",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "directory",
		"key": "dir_ip",
		"value": "IP",
		"groupBy": "Directory"
	},
	{
		"casing": "camel",
		"label": "Category",
		"defaultOperator": "equals",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"id": "dir_category",
		"valueType": "string-array",
		"type": "multi-select",
		"groupId": "directory",
		"key": "dir_category",
		"value": "Category",
		"groupBy": "Directory",
		placeholder: "Select directory category"
	},
	{
		"id": "misc",
		"label": "Access Control",
		"isGroupRoot": true,
		"groupId": "directory",
		"key": "misc",
		"value": "Access Control"
	},
	{
		"id": "inter_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Access Control  Hostname",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "directory",
		"key": "inter_hostname",
		"value": "Access Control  Hostname",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "inter_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Access Control Name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "directory",
		"key": "inter_name",
		"value": "Access Control Name",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "src_inter_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 17.34.12.12)"
			},
			{
				"id": "matches",
				"label": "matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range - 192.168.0.1-192.168.0.24)"
			},
			{
				"id": "not matches",
				"label": "not matches",
				"placeholder": "Enter comma separated identity ips (Ex. Ips - 172.9.*, CIDR - 192.168.0.1/24, Range -192.168.0.1-192.168.0.24)"
			}
		],
		"label": "Access Control IP",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "directory",
		"key": "src_inter_ip",
		"value": "Access Control IP",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "misc",
		"label": "Miscellaneous",
		"isGroupRoot": true,
		"groupId": "source_identity",
		"key": "misc",
		"value": "Miscellaneous"
	},
	{
		"id": "loc",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Sensor Code",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "loc",
		"value": "Sensor Code",
		"groupBy": "Miscellaneous",
		placeholder: "Select sensor code"
	},
	{
		"id": "collector_name",
		"type": "list",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"label": "Collector Name",
		"placeholder": "Enter comma separated collector name",
		"defaultOperator": "equals",
		"asyncId": "collector_name",
		"valueType": "string-array",
		"groupId": "source_identity",
		"key": "collector_name",
		"value": "Collector Name",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "misc",
		"label": "Miscellaneous",
		"isGroupRoot": true,
		"groupId": "destination_asset",
		"key": "misc",
		"value": "Miscellaneous"
	},
	{
		"id": "flow_time",
		"type": "week-selector",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Access Time",
		"placeholder": "Click to select access time",
		"defaultOperator": "equals",
		"groupId": "destination_asset",
		"key": "flow_time",
		"value": "Access Time",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "is_streaming_flow",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Potential Streaming Flow?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "is_streaming_flow",
		"value": "Potential Streaming Flow?",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "is_svc_to_svc_flow",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Potential Service-to-Service Flow?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"groupId": "destination_asset",
		"key": "is_svc_to_svc_flow",
		"value": "Potential Service-to-Service Flow?",
		"groupBy": "Miscellaneous"
	}
] as Array<IPatternConfigItem>

/* const sortedConfig = config.sort((a: IPatternConfigItem, b: IPatternConfigItem) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

export const PlaybookAdvanceddSearchConfig = sortedConfig; */


/* console.log(PlaybookAdvanceddSearchConfig.filter((i: IPatternConfigItem) => i.groupId == 'destination_asset').map((v: IPatternConfigItem) => v.id));
console.log(PlaybookAdvanceddSearchConfig.filter((i: IPatternConfigItem) => i.groupId == 'directory').map((v: IPatternConfigItem) => v.id)); */

console.log(JSON.stringify(PlaybookAdvanceddSearchConfig.map((i: IPatternConfigItem) => ({ ...i, key: i.id, value: i.label }))));