import React from "react";
import { DeepMap, FieldError } from "react-hook-form";

interface IAMModalError {
    errors: DeepMap<any, FieldError>;
    errorMap: Record<string, any>;
}

export const AMModalError = ({ errors, errorMap }: IAMModalError) => {
    return <>{
        (Object.keys(errors || {}).length > 0) && (
            <div className="modal-pop-up-errors-list-container scrollbar-container">
                {
                    Object.keys(errors).map((er: string) => {
                        const e = errors[er];
                        const sensEr = errorMap[er];
                        let typeError = '';
                        if (e?.message) {
                            typeError = e.message;
                        } else if (sensEr) {
                            typeError = sensEr[e.type];
                        }

                        if (typeError)
                            return <div><div className="error dot marginleft40per"><span className='font12 error-message' style={{paddingLeft:'10px'}}>{typeError}</span></div></div>
                        return <span></span>
                    })
                }
            </div>
        )
    }
    </>
}