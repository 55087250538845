import React from 'react';
import { RuleType } from '../../../constants/Constants';

export const AccessToUnauthCountriesView = ({ config, ruleType }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - &nbsp; */}
                {ruleType === RuleType.ACCESS_TO_UNAUTH_COUNTRIES ? (
                    <span>
                        Access should be marked as “access to an unauthorized
                        country” when{' '}
                        {config && config.unauthorized_countries &&
                            config.unauthorized_countries?.countries?.length >
                            0 && (
                                <>
                                    the access's destination country{' '}
                                    {config?.unauthorized_countries?.operator || 'equals'}{' '}
                                    <strong>
                                        {config.unauthorized_countries?.countries.join(
                                            ', '
                                        )}
                                    </strong>
                                </>
                            )}
                    </span>
                ) : (
                    <span>
                        Access should be marked as “access from an unauthorized
                        country” when{' '}
                        {config && config.unauthorized_countries &&
                            config.unauthorized_countries?.countries?.length >
                            0 && (
                                <>
                                    the access's source country{' '}
                                    {config?.unauthorized_countries?.operator || 'equals'}{' '}
                                    <strong>
                                        {config.unauthorized_countries.countries.join(
                                            ', '
                                        )}
                                    </strong>
                                </>
                            )}
                    </span>
                )}
            </li>
        </>
    );
};
