export const PlaybookErrors = {
    actions: {
        noActions: 'No action specified.'
    },
    name: {
        emptyName: 'Please enter playbook name.',
        invalidName: 'Please provide valid playbook name.',
        nameLength: 'Playbook name cannot exceed 50 characters.'
    },
    issueConfig: {

    }
}