import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from '../../../components/core';
import Popup from 'reactjs-popup';
import { Api } from '../../../components/Axios';


type Props={
    handleTicketCreateNew: ()=>void,
    closeMoreDetails?: ()=>void
}

const MoreActionsPopup=({handleTicketCreateNew, closeMoreDetails}: Props)=>{
    let history = useHistory();
    const { addToast } = useToasts();

    const handleYes=()=>{
        handleTicketCreateNew();
    }

    const handleNo=()=>{
        // history.goBack();
        if(closeMoreDetails) closeMoreDetails();
    }

    return(
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal
                // //lockScroll
            >
                <div className={"modal ticket_modal"}>
                    <div className={"content pad_bottom_10"}>
                        <div className="popup_title fl align_center" style={{marginBottom:0, textAlign:"center", width:'100%'}}>
                            This will create/open a ticket and assign it to you. Are you sure?
                        </div>
                        <div className="clrBoth"></div>
                    </div>
                    <div className="popup_footer fl" style={{borderTop: "none", paddingTop:0}}>
                        <div className="policy_defualt_button cancl_policy_popup" onClick={handleNo}>No</div>
                        <div className="policy_save_button cancl_policy_popup" onClick={handleYes}>Yes</div>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(MoreActionsPopup);