import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import { getFilterObjFromTag } from "../../../components/core/PostureFilter/posture-filter-utils";
import "./AdditionalResult.scss";

type IMoreActionsProps = {
    options: string[],
    pageType?: string,
    filterTemplate: any,
    notSupportedKeys: any,
    onFieldSelect?: any,
    handleAddtionalResultColumn?: any
}

const is4K = window.matchMedia("(min-width: 2560px)").matches ? 2 : 1;

const AdditionalResult = ({ options, pageType, filterTemplate, notSupportedKeys, onFieldSelect, handleAddtionalResultColumn }: IMoreActionsProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedField, setSelectedField] = React.useState(null);
    const [fields, setFields] = React.useState([]);

    React.useEffect(() => {
        document.addEventListener('scroll', handleClose)
        return () => document.removeEventListener('scroll', handleClose)
    }, []);

    React.useEffect(() => {
        const textBoxSupportedKeys = notSupportedKeys.map((p) => p.search_key);
        const advSearchSupportedKeys = filterTemplate.map((p) => p.search_key);
        const allSearchKeys =
            advSearchSupportedKeys.concat(textBoxSupportedKeys);
        const filterKeys = options.map(tag => {
            const [key, val, operator] = getFilterObjFromTag(
                tag,
                allSearchKeys
            );
            return key;
        });
        const filters = filterTemplate.filter(item => {
            if (item['dataField'] !== undefined
                && item['dataField'] !== null
                && item['dataField'][pageType]
                && filterKeys.includes(item.search_key)
                && (Object.keys(item['dataField']).includes(pageType)
                    || item['pageType'].includes('All')
                )
            )
                return true;
            return false;
        });
        setFields(filters);
        if (filters.length === 0 && handleAddtionalResultColumn) {
            handleAddtionalResultColumn(false);
        }
        if (filters.length === 1) {
            if (onFieldSelect) {
                onFieldSelect(filters[0], filters.map(item => item['search_key']))
            }
            setSelectedField(filters[0]);
        }
    }, [options]);

    const handleMenuItemClick = (event, field) => {
        event.stopPropagation();
        setSelectedField(field);
        setOpen(false);
        if (onFieldSelect) {
            onFieldSelect(field['dataField'] ? field : null, fields.map(item => item['search_key']))
        }
    };

    const handleToggle = (event) => {
        event.stopPropagation();
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        event.stopPropagation();
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            {fields.length > 0 && ( <React.Fragment>
                <div className="posture-additional-results">
                    {selectedField !== null && <div className="selected-field">{selectedField['label']}</div>}
                </div>
                {fields.length > 1 &&
                <div className="additional-result-container">

                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <div className="selected-field">Additional<br />Results<br /></div>
                            <div onClick={(e) => handleToggle(e)} ref={anchorRef} className="additional_results_icon"></div>
                        </div>
                        <Menu
                        anchorEl={anchorRef.current}
                        id="posture-additional-results-menu"
                        open={open}
                        onClose={handleClose}
                        className={"additional-result-menu"}                    
                        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    >
                        <MenuItem
                            key={'select_field'}
                            disableRipple={true}
                            className={`additional-result-menu-header`}
                        >
                            <div>Select Field</div>
                        </MenuItem>
                        {fields
                            .map((field) => (
                                <MenuItem
                                    key={field['search_key']}
                                    onClick={(event) => handleMenuItemClick(event, field)}
                                    disableRipple={true}
                                    className={selectedField && selectedField['search_key'] === field['search_key'] ? `additional-result-menu-item additional-result-menu-item-selected` : `additional-result-menu-item`}
                                >
                                    <div>{field['label']}</div>
                                </MenuItem>
                            ))}
                        </Menu>

                    
                </div>
            }
                </React.Fragment>
            )}
        </React.Fragment>
    );
}


export default AdditionalResult;