import { useSnackbar } from "notistack";

export const useToasts = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    var key: any;
    const onClick = (evt: any) => {
        closeSnackbar(key);
    }
    const addToast = (message: String, opts?: any) => {
        key = enqueueSnackbar(message, {
            disableWindowBlurListener: true,
            onClick: onClick,
            autoHideDuration: opts?.hideDuration || 3000,
            variant: opts?.appearance, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
    }
    return { addToast }
}