import Handlebars from "handlebars";
import { RuleType } from "../constants/Constants";

const TemplateExpression =
{
    "Access to Unauthorized Countries": '(AM_destination.GeoLocation.CountryName {{operator}} [{{{countries}}}] ? { rule_name: "Access to Unauthorized Countries", has_rule_matched: true, fields_matched: [ "am_destination.GeoLocation.country_name" ], to_be_highlighted: [ "am_destination.GeoLocation.country_name" ], matching_field_value: AM_destination.GeoLocation.CountryName } : { rule_name: "Access to Unauthorized Countries", has_rule_matched: false })',
    "Access from Unauthorized Countries": `(AM_source.GeoLocation.CountryName {{operator}} [{{{countries}}}] ? { rule_name: "Access from Unauthorized Countries", has_rule_matched: true, fields_matched: [ "am_source.GeoLocation.country_name" ], to_be_highlighted: [ "am_source.GeoLocation.country_name" ], matching_field_value: AM_source.GeoLocation.CountryName } : { rule_name: "Access from Unauthorized Countries", has_rule_matched: false })`,
    "Access using Public VPN": `(AM_destination.IpReputation.Threat.IsVpn == true ? 
		{
			rule_name: "Access using Public VPN", 
			has_rule_matched: true,
			fields_matched: [ "am_destination.ip_reputation.threat.is_vpn" ],
			to_be_highlighted: [ "am_destination.DestinationIp" ],
			matching_field_value: AM_destination.IpReputation.Threat.IsVpn
		} : 
		(AM_source.IpReputation.Threat.IsVpn == true ? 
			{
				rule_name: "Access using Public VPN", 
				has_rule_matched: true, 
				fields_matched: [ "am_source.ip_reputation.threat.is_vpn" ], 
				to_be_highlighted: [ "am_source.SourceIp" ], 
				matching_field_value: AM_source.IpReputation.Threat.IsVpn } : 
		{ rule_name: "Access using Public VPN", has_rule_matched: false }))`,
    "Access using Anonymous IP": `(AM_destination.IpReputation.Threat.IsAnonymous == true ? 
		{
			rule_name: "Access using Anonymous IP", 
			has_rule_matched: true,
			fields_matched: [ "am_destination.ip_reputation.threat.is_anonymous" ],
			to_be_highlighted: [ "am_destination.DestinationIp" ],
			matching_field_value: AM_destination.IpReputation.Threat.IsAnonymous
		} : 
		(AM_source.IpReputation.Threat.IsAnonymous == true ? 
			{
				rule_name: "Access using Anonymous IP", 
				has_rule_matched: true, 
				fields_matched: [ "am_source.ip_reputation.threat.is_anonymous" ], 
				to_be_highlighted: [ "am_source.SourceIp" ], 
				matching_field_value: AM_source.IpReputation.Threat.IsAnonymous } : 
		{ rule_name: "Access using Anonymous IP", has_rule_matched: false }))`,
    "Deviation in Daily Asset Activity": `({{#if today_flow_count_7_day_avg.is_checked}}
        AM_destination.Flows7dAvg > 0 &&{{TODAYORYESTERDAY}} > 0 && {{TODAYORYESTERDAY}} {{{today_flow_count_7_day_avg.operator}}} (AM_destination.Flows7dAvg {{PLUSMINUS}} ({{today_flow_count_7_day_avg.percent}}*AM_destination.Flows7dAvg)/100) ? 
    {
        rule_name: "Deviation in Daily Asset Activity", 
        has_rule_matched: true,
        fields_matched: [ "am_destination.flows_7d_avg" ],
        to_be_highlighted: [ {{{TODAYORYESTERDAYHIGHLIGHT}}}, "am_destination.flows_7d_avg" ],
        matching_field_value: AM_destination.Flows7dAvg
    } : {{/if}}
    {{#if today_flow_count_gt_7_day_max.is_checked}}AM_destination.Flows7dMax > 0 && AM_destination.FlowsToday > 0 && AM_destination.FlowsToday > (({{today_flow_count_gt_7_day_max.percent}}*AM_destination.Flows7dMax)/100 + AM_destination.Flows7dMax) ? 
    {
        rule_name: "Deviation in Daily Asset Activity", 
        has_rule_matched: true,
        fields_matched: [ "am_destination.flows_7d_max" ],
        to_be_highlighted: [ "am_destination.flows_today", "am_destination.flows_7d_max" ],
        matching_field_value: AM_destination.Flows7dMax
    } : {{/if}}
    {{#if today_flow_count_lt_7_day_min.is_checked}} AM_destination.Flows7dMin > 0 && AM_destination.FlowsYesterday > 0 && AM_destination.FlowsYesterday < (AM_destination.Flows7dMin - ({{today_flow_count_lt_7_day_min.percent}}*AM_destination.Flows7dMin)/100) ? 
    {
        rule_name: "Deviation in Daily Asset Activity", 
        has_rule_matched: true,
        fields_matched: [ "am_destination.flows_7d_min" ],
        to_be_highlighted: [ "am_destination.flows_yesterday", "am_destination.flows_7d_min" ],
        matching_field_value: AM_destination.Flows7dMin
    } : {{/if}}
    {{#if today_flow_count_gt_yesterday.is_checked}}AM_destination.FlowsYesterday > 0 && AM_destination.FlowsToday > 0  && AM_destination.FlowsToday > ( AM_destination.FlowsYesterday + ({{today_flow_count_gt_yesterday.percent}}*AM_destination.FlowsYesterday)/100) ? 
    {
        rule_name: "Deviation in Daily Asset Activity", 
        has_rule_matched: true,
        fields_matched: [ "am_destination.flows_yesterday" ],
        to_be_highlighted: [ "am_destination.flows_today", "am_destination.flows_yesterday" ],
        matching_field_value: AM_destination.FlowsYesterday
    } : {{/if}}
    {{#if today_flow_count_gt_count.is_checked}}(AM_destination.FlowsToday > {{today_flow_count_gt_count.value}}) ? 
        {
            rule_name: "Deviation in Daily Asset Activity", 
            has_rule_matched: true, 
            fields_matched: [ "am_destination.flows_today" ], 
            to_be_highlighted: [ "am_destination.flows_today" ], 
            matching_field_value: AM_destination.FlowsToday } : {{/if}}
    { rule_name: "Deviation in Daily Asset Activity", has_rule_matched: false })`,
    "Unauthorized Asset Access": `
    ({{#each unauthorized_asset}}
        {{#verifyNoAssetsCustomId}} 
        ({{{this.identity_expr}}})
        &&
        {{#if this.intermediary_expr}} {{{intermediary_expr}}} {{else}} true {{/if}}
    ?
        {
            rule_name: "Unauthorized Asset Access",
            has_rule_matched: true,
            fields_matched: [ "NA"],
            to_be_highlighted: [ "am_destination.DestinationName", "am_destination.DestinationPort", "am_destination.Protocol", "am_source.UserName", "am_source.SourcePort", "gen_timestamp"],
            matching_field_value: false
        }		
        {{else}} 
            {{#verifyAllAssetsCustomId}} 
            ({{{this.identity_expr}}})
            && {{#if this.intermediary_expr}} {{{intermediary_expr}}} {{else}} true {{/if}}
				&&
				true
				&&
				{{#if this.dest_port}}AM_destination.DestinationPort in [{{{this.dest_port}}}] {{else}} true {{/if}}
				&& 
				{{#if this.src_port}}AM_source.SourcePort in [{{{this.src_port}}}] {{else}} true {{/if}} 
				&&
				{{#if this.access_protocol}}AM_destination.Protocol in [{{{this.access_protocol}}}]  {{else}} true {{/if}}
				&& 
				{{#if this.access_time}}(
                    {{#each this.access_time}}
                    GenTimeStampWeeklySeconds in {{this.from}}..{{this.to}}
                    {{/each}}               
                    )
                    {{else}} true {{/if}}
			?
            {
                rule_name: "Unauthorized Asset Access", 
                has_rule_matched: false
            }
            {{else}} 
            {{#ifNotEquals this.source "['No Identities', 'All Identities']"}}
            ({{{this.identity_expr}}}) {{else}} true
        {{/ifNotEquals}} 
		&&
        {{#if this.intermediary_expr}} {{{intermediary_expr}}} {{else}} true {{/if}}&&
		{{#ifNotEquals this.destination "['No Assets', 'All Assets']"}}
		({{{asset_expr}}}) {{else}} true {{/ifNotEquals}}
		&&
		{{#if this.dest_port}}AM_destination.DestinationPort in [{{{this.dest_port}}}] {{else}} true {{/if}}
		&& 
		{{#if this.src_port}}AM_source.SourcePort in [{{{this.src_port}}}] {{else}} true {{/if}}
		&&
		{{#if this.access_protocol}}AM_destination.Protocol in [{{{this.access_protocol}}}]  {{else}} true {{/if}}
		&& 
		{{#if this.access_time}}(
            {{#each this.access_time}}
            GenTimeStampWeeklySeconds in {{this.from}}..{{this.to}}
            {{/each}}               
            )
            {{else}} true {{/if}}
		?
			{
				rule_name: "Unauthorized Asset Access", 
				has_rule_matched: false
			}  	
            {{/verifyAllAssetsCustomId}}
        {{/verifyNoAssetsCustomId}}		           		
            {{addSymbol @index 'unauthorized_asset' ':'}}
            {{/each}}
		: 
        {{#verifyLastNoAsset}}
        {
            rule_name: "Unauthorized Asset Access", 
            has_rule_matched: false
        }  	{{else}}
		{
			rule_name: "Unauthorized Asset Access",
		    has_rule_matched: true,
			fields_matched: [ "NA"],
			to_be_highlighted: [ "am_destination.DestinationName", "am_destination.DestinationPort", "am_destination.Protocol", "am_source.UserName", "am_source.SourcePort", "gen_timestamp"],
			matching_field_value: false
		} {{/verifyLastNoAsset}}
	)`,
    "Suspected Directory/IdP Identity Brute-force Attack": `((AM_analysis.Dir_analysis != nil && AM_source.IsKnownIdentity == true) ? 
    ( {{#if user_input1}} AM_analysis.Dir_analysis.{{var1}} > {{user_input1}} ? 
        {rule_name: "Suspected Directory/IdP Identity Brute-force Attack", has_rule_matched: true, 
        fields_matched: [ "am_analysis.directory_analysis.{{var2}}" ], to_be_highlighted:
         [ "am_analysis.directory_analysis.{{var2}}" ], matching_field_value: AM_analysis.Dir_analysis.{{var1}} } : 
         {{/if}}
         ({{#if dir_bad_password_nuser_curhour}}AM_analysis.Dir_analysis.DirBadPasswordNUserCurHour > {{dir_bad_password_nuser_curhour}} ? {rule_name: "Suspected Directory/IdP Identity Brute-force Attack",
          has_rule_matched: true, fields_matched: [ "am_analysis.directory_analysis.dir_bad_password_nuser_curhour" ], 
          to_be_highlighted: [ "am_analysis.directory_analysis.dir_bad_password_nuser_curhour" ], 
          matching_field_value:AM_analysis.Dir_analysis.DirBadPasswordNUserCurHour } : 
           AM_analysis.Dir_analysis.IDPBadPasswordNUserCurHour > {{dir_bad_password_nuser_curhour}} ? 
            {rule_name: "Suspected Directory/IdP Identity Brute-force Attack", has_rule_matched: true, fields_matched: 
            [ "am_analysis.directory_analysis.idp_bad_password_nuser_curhour" ], to_be_highlighted: 
            [ "am_analysis.directory_analysis.idp_bad_password_nuser_curhour" ], 
            matching_field_value:AM_analysis.Dir_analysis.IDPBadPasswordNUserCurHour } :{{/if}} { rule_name: "Suspected Directory/IdP Identity Brute-force Attack", 
            has_rule_matched: false })) : { rule_name: "Suspected Directory/IdP Identity Brute-force Attack", has_rule_matched: false })`,
    "Shadow Directory": `(AM_destination.IsShadowDirectory == true && RuntimeStateMap["RULE_UNKNOWN_SAAS_ACCESS"] != "true" ? 
    {rule_name: "Shadow Directory", 
    has_rule_matched: true, fields_matched: [ "am_destination.is_shadow_directory" ],
     to_be_highlighted: ["am_destination.is_shadow_directory", "am_destination.DestinationName"], 
     matching_field_value: AM_destination.IsShadowDirectory } : { rule_name: "Shadow Directory", has_rule_matched: false })`,
    'Suspected Directory/IdP Bot Attack': `(AM_analysis.Dir_analysis != nil  {{#if user_auth_fail_nhosts_today}} && 
        AM_analysis.Dir_analysis.UserAuthFailNHostsToday > {{user_auth_fail_nhosts_today}} {{/if}}
        {{#if user_auth_fail_count_today}} && AM_analysis.Dir_analysis.UserAuthFailCountToday > {{user_auth_fail_count_today}} {{/if}}? 
                { rule_name: "Suspected Directory/IdP Bot Attack", has_rule_matched: true, 
                fields_matched: [ "am_analysis.directory_analysis.user_auth_fail_nhosts_today", 
                "am_analysis.directory_analysis.user_auth_fail_count_today" ], 
                to_be_highlighted: [ "am_analysis.directory_analysis.user_auth_fail_nhosts_today", 
                "am_analysis.directory_analysis.user_auth_fail_count_today" ], 
                matching_field_value: AM_analysis.Dir_analysis.UserAuthFailNHostsToday} 
                : { rule_name: "Suspected Directory/IdP Bot Attack", has_rule_matched: false })`,
    [RuleType.SUSPECTED_PASS_SPRAY]: `(AM_analysis.Dir_analysis != nil ? (AM_analysis.Dir_analysis.DirBadPasswordNUserCurHour >  {{user_input1}} 
        ? {rule_name: \"Suspected Directory/IdP Password Spray Attack\", has_rule_matched: true, 
        fields_matched: [ \"am_analysis.directory_analysis.dir_bad_password_nuser_curhour\" ], 
        to_be_highlighted: [ \"am_analysis.directory_analysis.dir_bad_password_nuser_curhour\" ], 
        matching_field_value:AM_analysis.Dir_analysis.DirBadPasswordNUserCurHour } : 
        (AM_analysis.Dir_analysis.IDPBadPasswordNUserCurHour >  {{user_input1}} ? 
            {rule_name: \"Suspected Directory/IdP Password Spray Attack\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.idp_bad_password_nuser_curhour\" ], 
            to_be_highlighted: [ \"am_analysis.directory_analysis.idp_bad_password_nuser_curhour\" ], 
            matching_field_value:AM_analysis.Dir_analysis.IDPBadPasswordNUserCurHour } : 
            { rule_name: \"Suspected Directory/IdP Password Spray Attack\", has_rule_matched: false })) : 
            { rule_name: \"Suspected Directory/IdP Identity Brute-force Attack\", has_rule_matched: false })`,
    [RuleType.NTLM_RELAY_ATTACK]: `(AM_analysis.Dir_analysis != nil ? 
        (AM_analysis.Dir_analysis.ADNTLMRelayCountToday > {{user_input1}} ? 
            {rule_name: \"Suspected AD NTLM Relay Attack\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.dir_ntlm_relay_count_today\" ], 
            to_be_highlighted: [ \"am_analysis.directory_analysis.dir_ntlm_relay_count_today\" ],
             matching_field_value:AM_analysis.Dir_analysis.ADNTLMRelayCountToday } : 
             { rule_name: \"Suspected AD NTLM Relay Attack\", has_rule_matched: false }) : 
             { rule_name: \"Suspected AD NTLM Relay Attack\", has_rule_matched: false })`,
    [RuleType.ENUM_AD_ADMINS]: `
        (AM_analysis.Dir_analysis != nil ? 
            ((AM_analysis.Dir_analysis.ADUserEnumAttemptsCurHour > {{user_input1}}  && 
                AM_analysis.Dir_analysis.ADNAdminEnumAttemptsCurHour > {{user_input2}}) ? 
                {rule_name: \"Enumeration of AD Admins\", has_rule_matched: true,
                 fields_matched: [ \"am_analysis.directory_analysis.dir_bad_username_count_curhour\", 
                 \"am_analysis.directory_analysis.dir_bad_username_nadmin_curhour\" ], 
                 to_be_highlighted: [ \"am_analysis.directory_analysis.dir_bad_username_count_curhour\", \"am_analysis.directory_analysis.dir_bad_username_nadmin_curhour\" ],
                  matching_field_value:AM_analysis.Dir_analysis.ADNAdminEnumAttemptsCurHour } : 
                  { rule_name: \"Enumeration of AD Users\", has_rule_matched: false }) : 
                  { rule_name: \"Enumeration of AD Admins\", has_rule_matched: false })
    `,
    [RuleType.ENUM_AD_USERS]: `(AM_analysis.Dir_analysis != nil ? 
        ((AM_analysis.Dir_analysis.ADUserEnumAttemptsCurHour > {{user_input1}} 
            && AM_analysis.Dir_analysis.ADNUserEnumAttemptsCurHour > {{user_input2}}) ?
             {rule_name: \"Enumeration of AD Users\", has_rule_matched: true, 
             fields_matched: [ \"am_analysis.directory_analysis.dir_bad_username_count_curhour\", \"am_analysis.directory_analysis.dir_bad_username_nuser_curhour\" ], 
             to_be_highlighted: [ \"am_analysis.directory_analysis.dir_bad_username_count_curhour\", \"am_analysis.directory_analysis.dir_bad_username_nuser_curhour\" ], 
             matching_field_value:AM_analysis.Dir_analysis.ADNUserEnumAttemptsCurHour } : 
             { rule_name: \"Enumeration of AD Users\", has_rule_matched: false }) : 
             { rule_name: \"Enumeration of AD Users\", has_rule_matched: false })`,
    [RuleType.UNAUTH_LOGIN_TIME]: `((AM_analysis.Dir_analysis != nil && AM_source.IsKnownIdentity == true) ?
        (AM_analysis.Dir_analysis.ADUserUnauthLoginTimeCountCurHour > {{user_input1}} ? 
            {rule_name: \"Repeated AD Login Attempts at Invalid Time\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.user_unauthorized_time_count_curhour\" ], 
            to_be_highlighted: [ \"am_analysis.directory_analysis.user_unauthorized_time_count_curhour\" ], 
            matching_field_value:AM_analysis.Dir_analysis.ADUserUnauthLoginTimeCountCurHour } : 
            { rule_name: \"Repeated AD Login Attempts at Invalid Time\", has_rule_matched: false }) :
             { rule_name: \"Repeated AD Login Attempts at Invalid Time\", has_rule_matched: false })`,
    [RuleType.UNAUTH_LOGIN_DEVICE]: `((AM_analysis.Dir_analysis != nil && AM_source.IsKnownIdentity == true) ? 
        (AM_analysis.Dir_analysis.ADUserUnauthLoginDeviceCountCurHour > {{user_input1}} ? 
            {rule_name: \"Repeated AD Login Attempts from Invalid Device\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.user_unauthorized_device_count_curhour\" ], 
            to_be_highlighted: [ \"am_analysis.directory_analysis.user_unauthorized_device_count_curhour\" ], 
            matching_field_value:AM_analysis.Dir_analysis.ADUserUnauthLoginDeviceCountCurHour } : 
            { rule_name: \"Repeated AD Login Attempts from Invalid Device\", has_rule_matched: false })  : 
            { rule_name: \"Repeated AD Login Attempts from Invalid Device\", has_rule_matched: false })`,
    [RuleType.SUSPECTED_ATT_EXP_ACC]: `((AM_analysis.Dir_analysis != nil && AM_source.IsKnownIdentity == true) ? 
        (AM_analysis.Dir_analysis.ADUserExpiredAccountLoginCountCurHour > {{user_input1}} ? 
            {rule_name: \"Suspected Attack on Expired AD Account\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.user_expired_logon_count_curhour\" ], 
            to_be_highlighted: [ \"am_analysis.directory_analysis.user_expired_logon_count_curhour\" ], 
            matching_field_value:AM_analysis.Dir_analysis.ADUserExpiredAccountLoginCountCurHour } : 
            { rule_name: \"Suspected Attack on Expired AD Account\", has_rule_matched: false }) : 
            { rule_name: \"Suspected Attack on Expired AD Account\", has_rule_matched: false })`,
    [RuleType.SUSPECTED_ATT_DIS_ACC]: `
    ((AM_analysis.Dir_analysis != nil && AM_source.IsKnownIdentity == true) ? 
        (AM_analysis.Dir_analysis.ADUserDisabledAccountLoginCountCurHour > {{user_input1}} ? 
            {rule_name: \"Suspected Attack on Disabled AD Account\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.user_disabled_logon_count_curhour\" ],
             to_be_highlighted: [ \"am_analysis.directory_analysis.user_disabled_logon_count_curhour\" ], 
             matching_field_value:AM_analysis.Dir_analysis.ADUserDisabledAccountLoginCountCurHour } : 
             { rule_name: \"Suspected Attack on Disabled AD Account\", has_rule_matched: false }) : 
             { rule_name: \"Suspected Attack on Disabled AD Account\", has_rule_matched: false })
    `,
    [RuleType.SUSPECTED_ATT_LOCK_ACC]: `((AM_analysis.Dir_analysis != nil && AM_source.IsKnownIdentity == true) ? 
        (AM_analysis.Dir_analysis.ADUserLockedAccountLoginCountCurHour > {{user_input1}} ? 
            {rule_name: \"Suspected Attack on Locked AD Account\", has_rule_matched: true, 
            fields_matched: [ \"am_analysis.directory_analysis.user_locked_logon_count_curhour\" ], 
            to_be_highlighted: [ \"am_analysis.directory_analysis.user_locked_logon_count_curhour\" ], 
            matching_field_value:AM_analysis.Dir_analysis.ADUserLockedAccountLoginCountCurHour } : 
            { rule_name: \"Suspected Attack on Locked AD Account\", has_rule_matched: false }) : 
            { rule_name: \"Suspected Attack on Locked AD Account\", has_rule_matched: false })`
};

Handlebars.registerHelper('ifNotEquals', function (arg1, arg2, options) {
    const a = eval(arg2);
    return (!a.includes(eval(arg1))) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
    const a = eval(arg2);
    return (a.includes(eval(arg1))) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('addSymbol', (index, keyName, symbol, context) => {
    if (index != context.data.root[keyName].length - 1) {
        return symbol
    } else return '';
})

Handlebars.registerHelper('verifyNoAssetsCustomId', function (options) {
    return this.source != "'No Identities'" && this.destination === "'No Assets'" ?
        options.fn(this) : options.inverse(this)
})

Handlebars.registerHelper('verifyLastNoAsset', function (options) {
    const a = options?.data?.root?.unauthorized_asset || [];
    return a?.length > 0 && a[a.length - 1].destination === "'No Assets'" ? options.fn(this) : options.inverse(this);
})

Handlebars.registerHelper('verifyAllAssetsCustomId', function (options) {
    return this.source != "'No Identities'" && this.destination === "'All Assets'" ?
        options.fn(this) : options.inverse(this)
})

Handlebars.registerHelper('verifyAllAssetsAllId', function (options) {
    return this.source === "'No Identities'" && this.destination === "'All Assets'" ?
        options.fn(this) : options.inverse(this)
})

const removeExtraChars = (template: string) => {
    let temp = template.
        replaceAll(")||)", "))").
        replaceAll(/(\r\n|\n|\r|\t)/g, "")
        .replaceAll('\t', '')
        // .replaceAll(/\s/g, ' ')
        .trim();

    // remove repeated && 

    // const t = temp.replaceAll(/\s/g, ' ');

    return temp;
}

export const executeExpression = (ruleType: string | undefined, data: any) => {
    if (ruleType) {
        const template = TemplateExpression[ruleType as keyof typeof TemplateExpression];
        if (template) {
            const compiledTemplate = Handlebars.compile(template);
            let output = compiledTemplate(data);
            console.log(output)
            output = removeExtraChars(output);
            return output;
        } else {
            console.log('Expression template not defined for ', ruleType)
        }
        return null;
    }
}