import { useState } from 'react';
import { Api } from '../../../../components/Axios';
import * as qs from "qs";

export const useValidatePostureIssuesSearchResult = () => {

    const isValidResultCountThreshold = (data: any): boolean => {
        if (!data)
            return true;
        return data.total > 1000
    }

    const matchingPlaybookFound = async (params: any, getSearchHeaders: any) => { 
        const searchHeaders = await getSearchHeaders(params);
        Api.get('/playbooks/check_posture_pb_exists', {
            params: params,
            paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
            ...searchHeaders
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    return res? res.data : null;
                }
            })
            .catch((err) => {
                return null;
            });
    }

    return {
        isValidResultCountThreshold,
        matchingPlaybookFound
    }
}
