import React from "react"
import Popup from "reactjs-popup"
import styles from '../grid/Playbooks.module.css'

interface IErrorPopUp {
    handleClose(): any,
    error: string,
    contentDescription?: string
}

export const ErrorPopUp = ({ handleClose, error, contentDescription }: IErrorPopUp) => {
    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
    >
        <div style={{ marginBottom: '5%' }} className={`${styles['modal']} unauth-asset-access-issue-container`}>
            <div className={styles.close} onClick={handleClose}></div>
            <div className={styles.header}>
                <div>{'Configuration Error'}</div>
            </div>
            <div className={styles.content}>{
                contentDescription && <div className="font12">{contentDescription}
                <br></br>
                </div>
            }
                <div className="font14" style={{ color: 'red' }}>{
                    error && error.length > 0 && error
                }
                </div>
            </div>
            <div className="shadow_footer fl"></div>
            <div className="popup_footer fl">
                <div className="policy_save_button" onClick={handleClose}>Ok</div>
            </div>
        </div>
    </Popup>
}