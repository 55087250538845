import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { QRadarFieldArrayNew, PROTOCOL_HEADERS } from './Constants';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';
import { Box } from '@mui/material';

const AVAILABLE_TIMEZONE = moment.tz
  .names()
  .map(zone => ({ zone, offset: moment.tz(zone).format('Z') }))
  .sort((a, b) => {
    const offsetA = parseInt(a.offset.replace(':', ''), 10);
    const offsetB = parseInt(b.offset.replace(':', ''), 10);
    return offsetA - offsetB;
  });

export const CollectorConfigurationQRadar = ({
    collectorName,
    register,
    setValue,
    collectorData,
    dynamicCollectors = null,
    clearErrors
}) => {
    const [dataObj, setDataObj] = useState(null);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherSelectedIds, setOtherSelectedIds] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState([]);
    const [selectedHeaderFormat, setSelectedHeaderFormat] = useState([]);
    const [selectedDynamicHeaderFormat, setSelectedDynamicHeaderFormat] =
        useState([]);

    useEffect(() => {
        if (dynamicCollectors && dynamicCollectors.collectors.length > 1) {
            const tempDataObj = QRadarFieldArrayNew.find(
                (item) => item.name === dynamicCollectors['collectors'][0]['id']
            );
            if (tempDataObj) {
                setDataObj(tempDataObj);
            }
            register('source_timezone', {
                required: true,
                shouldUnregister: true
            });
            setSelectedTimezone([
                { key: 'UTC_+00:00', value: 'UTC ( +00:00)' }
            ]);
            setValue('source_timezone', [
                { key: 'UTC_+00:00', value: 'UTC ( +00:00)' }
            ]);

            dynamicCollectors.collectors.forEach((item, idx) => {
                register(`protocol_${idx}`, {
                    required: false,
                    shouldUnregister: true
                });
                setValue(`protocol_${idx}`, []);

                const tempObj = QRadarFieldArrayNew.find(
                    (innerItem) => innerItem.name === item['id']
                );
                setValue(
                    `source_type_${idx}`, tempObj['default_src_type']
                );
            });
        } else {
            const tempDataObj = QRadarFieldArrayNew.find(
                (item) => item.name === collectorName
            );
            if (tempDataObj) setDataObj(tempDataObj);
            register('source_timezone', {
                required: true,
                shouldUnregister: true
            });

            register('protocol', {
                required: true,
                shouldUnregister: true
            });
            setValue('protocol', []);

            if (tempDataObj) {
                setValue('source_type', tempDataObj.default_src_type);
            }

            setSelectedTimezone([
                { key: 'UTC_+00:00', value: 'UTC ( +00:00)' }
            ]);
            setValue('source_timezone', [
                { key: 'UTC_+00:00', value: 'UTC ( +00:00)' }
            ]);
        }
    }, []);

    const handleHeaderSelect = (e, val) => {
        setSelectedHeaderFormat(val);
        setValue('protocol', val);
        clearErrors('protocol');
        if (val[0]['key'] === 'other') {
            setIsOtherSelected(true);
        } else {
            setIsOtherSelected(false);
        }
    };

    const handleHeaderSelectDynamic = (e, idx, val) => {
        const tempSelectedHeaderFormat = [...selectedDynamicHeaderFormat];
        tempSelectedHeaderFormat[idx] = val;
        setSelectedDynamicHeaderFormat(tempSelectedHeaderFormat);
        setValue(`protocol_${idx}`, val);
        if (val[0]['key'] === 'other') {
            if (!otherSelectedIds.includes(idx)) {
                const newIds = [...otherSelectedIds, idx];
                setOtherSelectedIds(newIds);
            }
        } else {
            if (otherSelectedIds.includes(idx)) {
                const newIds = otherSelectedIds.filter((item) => item == idx);
                setOtherSelectedIds(newIds);
            }
        }
    };

    const getGroupIdx = (collectorGroup) => {
        return dynamicCollectors.collectors.findIndex(
            (item) => item.id === collectorGroup
        );
    };

    const getItemLabel = (
        option:
            | { key: string; value: string }
            | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    const onTimezoneSelect = (e, val) => {
        setValue('source_timezone', val);
        setSelectedTimezone(val);
    };

    useEffect(() => {
        if (collectorData) {
            if (dynamicCollectors && dynamicCollectors.collectors.length > 1) {
                let tempHeaderFormat = [];
                Object.keys(collectorData.collector_config).forEach((item) => {
                    const idx = getGroupIdx(item);
                    if (idx == '-1') return;
                    setValue(
                        `source_type_${idx}`,
                        collectorData.collector_config[item]['source_type']
                    );
                    setValue(
                        `source_ids_${idx}`,
                        collectorData.collector_config[item]['source_ids']
                    );
                    const timezone = AVAILABLE_TIMEZONE.filter(
                        (innerItem) =>
                            `${innerItem.zone}_${innerItem.offset}` ===
                            collectorData.collector_config[item][
                                'source_timezone'
                            ]
                    ).map((innerItem) => ({
                        key: `${innerItem.zone}_${innerItem.offset}`,
                        value: `${innerItem.zone} ( ${innerItem.offset})`
                    }));
                    setValue(
                        `source_timezone`,
                        timezone
                    );
                    setSelectedTimezone(timezone);

                    const headerFormat = [
                        {
                            key: collectorData.collector_config[item]
                                ?.protocol_header?.protocol,
                            value: collectorData.collector_config[item]
                                ?.protocol_header?.protocol
                        }
                    ];
                    setValue(`protocol_${idx}`, headerFormat);
                    tempHeaderFormat[parseInt(idx)] = headerFormat;

                    if (
                        collectorData.collector_config[item]?.protocol_header
                            ?.protocol === 'other'
                    ) {
                        const newIds = [...otherSelectedIds, idx];
                        setOtherSelectedIds(newIds);
                        setTimeout(() => {
                            setValue(
                                `name_${idx}`,
                                collectorData.collector_config[item]
                                    ?.protocol_header?.name
                            );
                            setValue(
                                `pattern_${idx}`,
                                collectorData.collector_config[item]
                                    ?.protocol_header?.pattern
                            );
                        }, 100);
                    }
                });
                setSelectedDynamicHeaderFormat([...tempHeaderFormat]);
            } else {
                setValue(
                    'source_type',
                    collectorData.collector_config?.source_type
                );
                setValue(
                    'source_ids',
                    collectorData.collector_config?.source_ids
                );
                const timezone = AVAILABLE_TIMEZONE.filter(
                    (item) =>
                        `${item.zone}_${item.offset}` ===
                        collectorData.collector_config?.source_timezone
                ).map((item) => ({
                    key: `${item.zone}_${item.offset}`,
                    value: `${item.zone} ( ${item.offset})`
                }));
                setValue('source_timezone', timezone);
                setSelectedTimezone(timezone);
                const headerFormat = [
                    {
                        key: collectorData.collector_config?.protocol_header
                            ?.protocol,
                        value: collectorData.collector_config?.protocol_header
                            ?.protocol
                    }
                ];
                setValue('protocol', headerFormat);
                setSelectedHeaderFormat(headerFormat);
                if (
                    collectorData.collector_config?.protocol_header
                        ?.protocol === 'other'
                ) {
                    setIsOtherSelected(true);
                    setTimeout(() => {
                        setValue(
                            'name',
                            collectorData.collector_config?.protocol_header
                                ?.name
                        );
                        setValue(
                            'pattern',
                            collectorData.collector_config?.protocol_header
                                ?.pattern
                        );
                    }, 100);
                }
            }
        }
    }, [collectorData]);

    const getCommonFields = () => {
        return (
            <div className='sensor-flex-container'>
                <div className='sensor-flex-container-item'>
                    <label>QRadar Source Timezone*</label>
                    <div
                        style={{ width: '69.5%', marginTop: '6%' }}
                        className='autocom'
                    >
                        <CheckboxAutocomplete
                            label='Select Timezone'
                            className={'flex-basis-50 form_drodown'}
                            options={AVAILABLE_TIMEZONE.map((item) => ({
                                key: `${item.zone}_${item.offset}`,
                                value: `${item.zone} ( ${item.offset})`
                            }))}
                            value={selectedTimezone}
                            onChange={(e, val) => {
                                onTimezoneSelect(e, val);
                            }}
                            getItemLabel={getItemLabel}
                            multiple={false}
                            disabled={
                                dataObj ? dataObj.disabledTimezone : false
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    const getDynamicFields = (metaData, idx) => {
        return (
            <div className={`form_sub_section`} style={{ maxHeight: 'unset' }}>
                <div className='form_sub_section_header'>
                    {metaData.label} Configuration
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>QRadar Source Type</label>
                            <input
                                type='text'
                                name={`source_type_${idx}`}
                                ref={register({
                                    required: false,
                                    shouldUnregister: true
                                })}
                                placeholder={'QRadar Source Type'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>QRadar Source Ids</label>
                            <input
                                type='text'
                                name={`source_ids_${idx}`}
                                ref={register({
                                    required: false,
                                    shouldUnregister: true
                                })}
                                placeholder={'QRadar Source Ids'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>QRadar Header Format</label>
                            <div
                                style={{
                                    width: '69.5%',
                                    marginTop: '6%'
                                }}
                                className='autocom'
                            >
                                <CheckboxAutocomplete
                                    label='Select Header Format'
                                    className={'flex-basis-50 form_drodown'}
                                    options={PROTOCOL_HEADERS.map((item) => ({
                                        key: item.value,
                                        value: item.label
                                    }))}
                                    value={
                                        selectedDynamicHeaderFormat[idx]
                                            ? selectedDynamicHeaderFormat[idx]
                                            : []
                                    }
                                    onChange={(e, val) => {
                                        handleHeaderSelectDynamic(e, idx, val);
                                    }}
                                    getItemLabel={getItemLabel}
                                    multiple={false}
                                />
                            </div>
                        </div>
                    </div>

                    {otherSelectedIds.includes(idx) && (
                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>Header Key</label>
                                <input
                                    type='text'
                                    name={`name_${idx}`}
                                    ref={register({
                                        required: isOtherSelected,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Header Key'}
                                    style={{ width: '95%' }}
                                />
                            </div>
                            <div className='sensor-flex-container-item'>
                                <label>Header Value</label>
                                <input
                                    type='text'
                                    name={`pattern_${idx}`}
                                    ref={register({
                                        required: isOtherSelected,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Header Value'}
                                    style={{ width: '95%' }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {dynamicCollectors && dynamicCollectors.collectors.length > 1 ? (
                <div className='collector-configuration-vendor-container'>
                    <div className='form_sub_section dynamic-form-sub-section'>
                        <Box className={'collector-configuration-info'}>
                            <div className='float-left'>
                                <div
                                    className='status float-left'
                                ></div>
                                <div className='float-left font-text'>
                                    This collector can query events from
                                    multiple SIEM indexes. At least one is
                                    required to setup this collector.
                                </div>
                            </div>
                        </Box>
                        {dynamicCollectors.collectors.map((item, idx) =>
                            getDynamicFields(item, idx)
                        )}
                        {getCommonFields()}
                    </div>
                </div>
            ) : (
                <div className='collector-configuration-vendor-container'>
                    <div className={`form_sub_section`}>
                        <div className='form_sub_section_content'>
                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>QRadar Source Type*</label>
                                    <input
                                        type='text'
                                        name='source_type'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'QRadar Source Type'}
                                    />
                                </div>
                            </div>

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>QRadar Source Ids*</label>
                                    <input
                                        type='text'
                                        name='source_ids'
                                        ref={register({
                                            required: true,
                                            shouldUnregister: true
                                        })}
                                        placeholder={'QRadar Source Ids'}
                                    />
                                </div>
                            </div>

                            {getCommonFields()}

                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>QRadar Header Format*</label>
                                    <div
                                        style={{
                                            width: '69.5%',
                                            marginTop: '6%'
                                        }}
                                        className='autocom'
                                    >
                                        <CheckboxAutocomplete
                                            label='Select Header Format'
                                            className={
                                                'flex-basis-50 form_drodown'
                                            }
                                            options={PROTOCOL_HEADERS.map(
                                                (item) => ({
                                                    key: item.value,
                                                    value: item.label
                                                })
                                            )}
                                            value={selectedHeaderFormat}
                                            onChange={(e, val) => {
                                                handleHeaderSelect(e, val);
                                            }}
                                            getItemLabel={getItemLabel}
                                            multiple={false}
                                        />
                                    </div>
                                </div>
                            </div>

                            {isOtherSelected && (
                                <div className='sensor-flex-container'>
                                    <div className='sensor-flex-container-item'>
                                        <label>Header Key*</label>
                                        <input
                                            type='text'
                                            name='name'
                                            ref={register({
                                                required: isOtherSelected,
                                                shouldUnregister: true
                                            })}
                                            placeholder={'Header Key'}
                                            style={{ width: '95%' }}
                                        />
                                    </div>
                                    <div className='sensor-flex-container-item'>
                                        <label>Header Value*</label>
                                        <input
                                            type='text'
                                            name='pattern'
                                            ref={register({
                                                required: isOtherSelected,
                                                shouldUnregister: true
                                            })}
                                            placeholder={'Header Value'}
                                            style={{ width: '95%' }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
