import {
    Autocomplete, Tooltip
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

/* TO-DO separate out css */

const SingleTag = ({ tags, showMore, show }: any) => {
    const showMoreHandler = () => showMore(!show);
    const ref = React.useRef<any>(null);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref?.current && !ref.current?.contains(event.target)) {
                showMore(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [showMore]);

    return (
        <div className="single-tag">
            <span className="adv-tags" title={tags[0]?.props?.label || ''}>{tags[0]}</span>
            <Tooltip
                classes={{ tooltip: 'pattern-form-generator generic-tooltip-popper scrollbar-container' }}
                PopperProps={{
                    className: 'pattern-form-generator popper-class', style: { zIndex: 30000, maxWidth: '28%' }
                }}
                open={show}
                title={
                    <div ref={ref}>
                        <div className="autocomplete-close-btn" onClick={() => showMore(false)}>x</div>
                        {tags.map((tag: any, index: any) => (
                            <span key={`${index + 1}`} className={`adv-tags`} title={tag?.props?.label || ''}>
                                {tag}
                            </span>
                        ))}
                    </div>
                }
            ><span>
                    {tags.length > 1 && (
                        <span onClick={showMoreHandler} className="more">
                            {`(+${tags.length - 1}) ${show ? "less" : "more"}...`}{" "}
                        </span>
                    )}
                </span>
            </Tooltip>
        </div>
    );
}

const ExcessTags = ({ tags, show, showMore }: any) => {
    const excessTags = tags?.slice(1);
    const ref = React.useRef<any>(null);
    const showMoreHandler = () => showMore(false);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref?.current && !ref.current?.contains(event.target)) {
                showMore(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [showMore]);

    return excessTags ? (
        <Tooltip
            classes={{ tooltip: 'pattern-form-generator generic-tooltip-popper scrollbar-container' }}
            PopperProps={{
                className: 'pattern-form-generator popper-class', style: { zIndex: 30000, maxWidth: '28%' }
            }}
            open={show}
            title={excessTags.map((tag: any, index: any) => (
                <span key={`${index + 1}`} className={`adv-tags`} title={tag?.props?.label || ''}>
                    {tag}
                </span>
            ))}
        >
            <div ref={ref} className={`adv-excess-tags-container ${show && "active"}`}>
                <span className="adv-excess-close" onClick={showMoreHandler}>
                    x
                </span>
            </div>
        </Tooltip>
    ) : null;
}

const CustomAutoPopulateTagContainer = ({
    count,
    tags,
    placeholderText,
    inputProps,
    className,
    formType
}: any) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <div className={`adv-search-input ${count > 0 && "selected"}`}>
                <input
                    style={{ flexBasis: "109px !important", flexGrow: "0", width: '100%' }}
                    placeholder={placeholderText}
                    type="text"
                    {...inputProps}
                    className={className + ' ' + inputProps.className}
                    disabled={formType == 'view'}
                />
            </div>
            {count && (
                <>
                    <SingleTag tags={tags} showMore={setShow} show={show} />
                </>
            )}
        </>
    );
}

export const AutoCompleteInput = ({ state, onChange, placeHolder, data, className, formType }: any, ref: any) => {
    const [selectedCountries, setSelectedCountries] = useState<any>(state);
    const [countries, setCountries] = useState<string[]>([]);
    const spanRef = useRef(null);
    const [autoCompleteValue, setAutoCompleteValue] = useState('');

    useEffect(() => {
        if (data && data.length)
            setCountries(data);
    }, [data])

    const handleChange = (event: any, value: any) => {
        setSelectedCountries(
            (typeof value === 'string' ? value.split(',').filter((i: string) => i.length) : value.filter((i: string) => i.length))
        );
        onChange(typeof value === 'string' ? value.split(',').filter((i: string) => i.length) : value.filter((i: string) => i.length), spanRef.current);
    }

    useEffect(() => setSelectedCountries(state), [state])

    return <span title={selectedCountries?.filter((i: string) => i?.length > 0).join(', ') || ''}
        style={{ width: 'auto', display: 'inline-block' }}
        className="auto-complete-input-container"
        ref={spanRef}
    >
        <Autocomplete
            onChange={handleChange}
            multiple
            ChipProps={{
                size: "small",
                className: "chips",
                disabled: formType == 'view',
                deleteIcon: (
                    <span style={{ cursor: "pointer", color: "white" }}>x</span>
                ),
            }}
            classes={{
                popper: 'unauth-countries-popper',
                root: 'unauth-countries-auto-root'
            }}
            blurOnSelect={false}
            id={'countries-list'}
            // defaultValue={value}
            value={selectedCountries || state || []}
            options={countries}
            getOptionLabel={(option) => option}
            renderInput={(params: any) => {
                return (
                    <div
                        // className={`auto-populate-container ${highlightErrorInput ? "error" : ""}`}
                        className="auto-populate-container"
                        ref={params.InputProps.ref}
                    >
                        <CustomAutoPopulateTagContainer
                            ref={params.InputProps.ref}
                            count={params.InputProps?.startAdornment?.length}
                            tags={params.InputProps.startAdornment}
                            placeholderText={placeHolder}
                            inputProps={params.inputProps}
                            className={className}
                            formType={formType}
                        />
                    </div>
                );
            }}
            sx={{ width: "500px" }}
            disableCloseOnSelect={true}
            clearOnBlur={false}
            clearOnEscape={false}
            inputValue={autoCompleteValue}
            onInputChange={(event: React.SyntheticEvent, newInputValue: string, reason: string) => {
                if (reason != 'reset')
                    setAutoCompleteValue(newInputValue);
            }}
        />
    </span>
}