import React from 'react'
import ReactDOM from 'react-dom'

type Props = {
    children: React.ReactNode;
};

export const ActionMenu = ({children} : Props) => {
    const btn = React.createRef<HTMLDivElement>()

    const ButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        // console.log('I am in click')
        const n = btn.current
        if(n) {
            let ele = ReactDOM.findDOMNode(btn.current)
            if (ele instanceof HTMLElement) {
                ele.classList.toggle('open')
            }
        }
        
        
    }

    const MouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        const n = btn.current
        if(n) {
            let ele = ReactDOM.findDOMNode(btn.current)
            if (ele instanceof HTMLElement) {
                ele.classList.remove('open')
            }
        }
    }

    return (
        <div className="action_dropdown" ref={btn} onMouseLeave={MouseLeave}>
            <button className="action_button" onClick={ButtonClick}></button>
            <div className="action_dropdown_content">
                {children}
            </div>
        </div>
    )
}