import React from 'react';

const HashQualityConfigView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>Authentication quality will be considered poor if:</span>
                <ul className='ml-1'>
                    {config.hashFlag && (
                        <li>
                            Password hash length is less than{' '}
                            <strong>{config.hashValue}</strong> hex characters
                        </li>
                    )}
                    {config.saltFlag && (
                        <li>
                            Password salt length is less than{' '}
                            <strong>{config.saltValue}</strong> bits
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
};

export default React.memo(HashQualityConfigView);
