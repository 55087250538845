import { IPatternConfigItem, IPatternFormOptions, IPatternFormValue } from "./PatternFormGenerator";

export const usePatternFormUtil = (config: Array<IPatternConfigItem>, watch: any) => {
    const values = watch(); // To reload the state

    const validateIfEmpty = (val: string | Array<any>) => {
        if (!val) {
            return false;
        } else if (val && Array.isArray(val) && val.length === 0) {
            return false;
        } else if ((typeof val === 'string' &&
            (val.trim().length === 0 ||
                val.split(',').filter((i: string) => (!i || i.trim().length === 0)).length > 0))) {
            return false;
        }
        return true;
    }

    const getConfigItemByIndex = (index: number): IPatternConfigItem | any => {
        if (values?.searchFilter?.[index]) {
            const search = values?.searchFilter?.[index]?.searchKey;
            const configItem = config.find((i: IPatternConfigItem) => (i.id === search));
            return configItem;
        }
        return {};
    }

    const getConfigItemByName = (id: string): IPatternConfigItem | any => {
        if (values?.searchFilter?.length) {
            const configItem = config.find((i: IPatternConfigItem) => (i.id === id));
            return configItem;
        }
        return {};
    }

    const getPlaceHolderDetails = (index: number) => {
        if (values?.searchFilter?.[index]) {
            const conf = getConfigItemByName(values?.searchFilter?.[index].searchKey) as IPatternConfigItem;
            if (conf && conf?.id) {
                const o = conf.operators?.find((i: IPatternFormOptions) => i.id === values?.searchFilter?.[index].operator) as IPatternFormOptions;
                return (values?.searchFilter?.[index].value && conf.error) || o?.placeholder || conf?.placeholder ||
                    'Enter comma separated values';
            }
        }
        return '';
    }
    const validateControl = (value: string | Array<any>, index: number) => {
        const ctrl = getConfigItemByIndex(index) as IPatternConfigItem;
        const row = { ...values.searchFilter[index], value } as IPatternFormValue;
        const isVal = validateIfEmpty(value) && (ctrl?.validate ? !ctrl.validate(row) : true);
        return isVal;
    }

    return {
        validateControl, getConfigItemByName, getConfigItemByIndex, getPlaceHolderDetails
    }
}