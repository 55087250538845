import React, { useEffect, useState } from 'react';
import './comp-user-config.css';

type Props={
    parentState: State,
    isSavedOnce: boolean,
    getState: (state:State)=>void,
    defaultState: State,
    formType?: string
}

type Feed={
    name: string,
    isChecked: boolean
}

type State={
    user_breach_threshold_enabled: boolean,
    user_breach_threshold: number,
    feeds: Feed[],
}


const CompromisedUserConfig=({parentState, isSavedOnce, getState, defaultState, formType}: Props)=>{


    const [state, setState]=useState<State>(defaultState);
    const [dataBreach, setDataBreach] = useState<number|undefined>(90);

    useEffect(()=>{
        setState(defaultState);
    }, [defaultState])

    useEffect(()=>{
        if(isSavedOnce){
            setState(parentState);
        }
    }, [isSavedOnce])

    useEffect(()=>{
        // console.log('CompromisedUSERConfig STATE', state);
        getState(state);
        console.log(state?.user_breach_threshold_enabled)
    })

    const renderUsers=(feeds: Feed[])=>{
        return feeds?.map((f: Feed)=>{
            return (
                <React.Fragment key={f.name}>
                    <input type="checkbox" id={f.name} name={f.name} checked={f.isChecked}
                        className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}                        
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                f.isChecked=true
                            }
                            else{
                                f.isChecked=false
                            }
                            setState({
                                ...state,
                                feeds: state?.feeds
                            });
                        }}
                    />
                    <label htmlFor={f.name} className="float_none">{f.name}</label>
                    {/* <div className="info_icon">
                        <div className="infotext">Extra information about this field/option</div>
                    </div> */}
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }
    return(
        <>
            <form action="/">
                  <div className="playbook-config-title">A user will be considered compromised if his/her identity information is found in:</div>                  
                  {renderUsers(state?.feeds)}
                  
                  <input type="checkbox" id="breach-flag" name="breach-flag" checked={state?.user_breach_threshold_enabled || false}
                        className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                
                                setState({
                                    ...state,
                                    user_breach_threshold_enabled: true,
                                })
                            }
                            else{
                                
                                setState({
                                    ...state,
                                    user_breach_threshold_enabled: false,
                                })
                            }
                        }}
                    />
                    <label htmlFor="breach-flag" className="float_none">User will not be considered compromised if data breach is less than
                        {
                            formType && ['edit', 'view'].includes(formType) ? 
                            <span  className='margin10 width10per' 
                                    id="user_breach_threshold">{state?.user_breach_threshold}</span> :
                            <input className='margin10 width10per' 
                            id="user_breach_threshold" 
                            name="user_breach_threshold" 
                            type="number" 
                            value={state?.user_breach_threshold}
                            onChange={(e)=>{
                                setState({
                                    ...state,
                                    user_breach_threshold: parseInt(e.target.value),
                                })
                            }}
                    />                           
                        }
                       
                    </label>
                    <label className='float_none'>days</label>

                </form>
        </>
    )
}

export default React.memo(CompromisedUserConfig);