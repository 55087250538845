import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthDetails } from '../components/Authorization'
import {Api} from '../components/Axios'

const Logout = () => {
  const {setAuthDetails} = useAuthDetails()!
  const defaultUserDetails = {
    name: "",
    email: "",
    token: "",
    authenticated: false
  };

  const data:any = window.localStorage.getItem('user')
  if(JSON.parse(data).sessionindex!==undefined && JSON.parse(data).sessionindex!==null)
  Api.post('/logout?sessionindex='+JSON.parse(data).sessionindex)
  .then((res: { data: any, headers: any }) => {
    //Return Logic
    //window.location.href = res.headers.location
  })
  .catch((error:any)=>{})
  else
  Api.post('/logout');
  
  if(data !== null) {
    const user = JSON.parse(data);
    if(typeof(user.token) != 'undefined') {
      setAuthDetails(defaultUserDetails);
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('TIME_BASED_SEARCH_FEATURE');
      if(user.role === "Root"){
        return <Redirect to='/root-user' />  
      }
      return <Redirect to='/login' />
    }
  }
  
  return <Redirect to='/login' />
}

export default Logout