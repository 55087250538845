import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './CSVOverridePopUp.scss';
import Popup from 'reactjs-popup';
import styles from '../grid/Playbooks.module.css';
import { RuleType } from '../constants/Constants';

export const csvSampleData = [{
    "i_name": "jdoe@gmail.com",
    "i_hostname": "example.org",
    "i_ip": "128.0.1.1,90.*",
    "source_port": "8888, 10278",
    "a_name": "azure",
    "a_hostname": "azure.org, onemail.*",
    "a_ip": "89.12.23.231",
    "dir_name": "am-host",
    "dir_hostname": "example.azure.org",
    "dest_port": "1209, 8080",
    "access_protocol": "Telnet",
    "inter_ip": "128.0.1.1,90.*"
}, {
    "i_name": "All Identities",
    "i_hostname": "",
    "i_ip": "128.0.1.1,90.*",
    "source_port": "1026, 9384",
    "a_name": "azure",
    "a_hostname": "azure.org, onemail.*",
    "a_ip": "12.132.23.23",
    "dir_name": "am-host",
    "dir_hostname": "am.google.org",
    "dest_port": "8119",
    "access_protocol": "NTLM, HTTP",
    "inter_ip": "128.0.1.1,90.*"
}, {
    "i_name": "jdoe, testuser",
    "i_hostname": "",
    "i_ip": "128.0.1.1,90.*",
    "source_port": "",
    "a_name": "No Assets",
    "a_hostname": "azure.org, onemail.*",
    "a_ip": "66.23.23.23",
    "dir_name": "am-host",
    "dir_hostname": "example.azure.org",
    "dest_port": "",
    "access_protocol": ""
},
{
    "i_name": "amuser, developer",
    "i_hostname": "",
    "i_ip": "176.2.1.1, 90.*",
    "source_port": "",
    "a_name": "All Assets",
    "a_hostname": "aze.com, live.*",
    "a_ip": "67.12.23.23",
    "dir_name": "am-host",
    "dir_hostname": "am.azure.cloud",
    "dest_port": "",
    "access_protocol": "",
    "inter_ip": "176.0.1.1,90.*"
},
{
    "i_name": "smith, john",
    "i_hostname": "",
    "i_ip": "128.0.1.1/10,90.*",
    "source_port": "8080",
    "a_name": "gmail, kibana",
    "a_hostname": "azure.org, onemail.*",
    "a_ip": "45.12.293.34",
    "dir_name": "am-host",
    "dir_hostname": "example.azure.org",
    "dest_port": "",
    "access_protocol": "",
    "inter_ip": "176.0.1.1,90.*"
},
{
    "i_name": "jdoe, testuser1",
    "i_hostname": "",
    "i_ip": "128.0.1.1, 9.1.2*",
    "source_port": "",
    "a_name": "google cloud",
    "a_hostname": "aws-1.org, aws.*",
    "a_ip": "90.121.223.124",
    "dir_name": "am-host",
    "dir_hostname": "am.azure.org",
    "dest_port": "77",
    "access_protocol": "",
    "inter_ip": "176.0.1.1, 90.1.*"
}
]

export const getCSVSampleData = (ruleType) => {
    return csvSampleData.map(item => {
        if (ruleType === RuleType.UNAUTHORIZED_ASSET_ACCESS && ["No Assets", "All Assets"].includes(item.a_name)) {
            item.a_name = "google cloud";
        }
        if (ruleType === RuleType.UNAUTHORIZED_IDENTITY_ACCESS && ["No Identities", "All Identities"].includes(item.i_name)) {
            item.i_name = "jdoe";
        }
        if (ruleType === RuleType.SUSPECTED_ACCESS_TOKEN) {
            delete item.dir_name;
            delete item.dir_hostname;
            delete item.inter_ip;
        }
        return item;
    })
}

export const CsvFields: Array<ICsvFieldsSchema> = [
    { key: "i_name", label: "Identity/Source Name", isOptional: true },
    { key: "i_hostname", label: "Identity/Source Hostname", isOptional: true },
    { key: "i_ip", label: "Identity/Source IP", isOptional: true },
    { key: "source_port", label: "Source Ports", isOptional: true },
    { key: "inter_ip", label: "Access Control IP", isOptional: true },
    { key: "dir_name", label: "Directory/IDP Name", isOptional: true },
    { key: "dir_hostname", label: "Directory/IDP Hostname", isOptional: true },
    { key: "a_name", label: "Asset/Destination Name", isOptional: true },
    { key: "a_hostname", label: "Asset/Destination Hostname", isOptional: true },
    { key: "a_ip", label: "Asset/Destination IP", isOptional: true },
    { key: "dest_port", label: "Destination Ports", isOptional: true },
    { key: "access_protocol", label: "Protocols", isOptional: true }
]

export const getCSVFields = (ruleType) => { 
    return CsvFields.filter(item => { 
        if ([RuleType.SUSPECTED_ACCESS_TOKEN].includes(ruleType) && ['dir_name', 'dir_hostname', 'inter_ip'].includes(item.key)) {
            return false;
        }
        return true;
    })
}

export interface ICsvActionButton {
    class: string,
    handler(): any,
    label: string
}

export interface ICsvFieldsSchema {
    key: string,
    label: string,
    isOptional?: boolean
}

interface IDragDropFile {
    onFileDrop(e: File): any,
    errors?: string,
    onFileReject?(): any
}
export const DragDropFile = ({ onFileDrop, errors, onFileReject }: IDragDropFile) => {
    const onDrop = (e: any) => {
        onFileDrop(e[0])
    }

    const onDropRejected = () => {
        if (onFileReject)
            onFileReject()
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'text/csv': ['.csv'],
        },
        maxFiles: 1,
        onDrop,
        onDropRejected
    });

    return (<>
        <section className="file-selection-container" style={{ marginTop: '14%' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag and drop CSV file here or click to select</p>
            </div>

        </section>
        <div className='error'>{errors && errors?.length > 0 && errors}</div>
    </>
    );
}


interface IPreviewTabCsv {
    parsedWithoutHeader: Array<any>,
    parsedWithHeader: Array<any>,
    previewText: string,
    headers: string[],
    errors?: string,
    headerSelected: boolean,
    ruleType?: string
}

const PreviewTabCsv = forwardRef(({ parsedWithoutHeader, parsedWithHeader, previewText, headers, errors, headerSelected, ruleType }: IPreviewTabCsv, ref: any) => {
    const [isHeader, setIsHeader] = useState<boolean>(headerSelected);
    const [er, setEr] = useState(errors);

    const changeIsHeader = () => {
        setIsHeader(!isHeader);
        setEr('');
    }

    useEffect(() => {
        setEr(errors)
    }, [errors])

    useImperativeHandle(ref, () => ({
        getHeaderValue() {
            return isHeader;
        }
    }));


    return <>
        <span style={{ marginRight: '5%', marginTop: '3%', display: 'block' }}>Raw File Content</span>
        <code className="preview-code-container">{previewText}</code>
        <div><span style={{ marginRight: '5%' }}>Preview Import</span>
            <input type="checkbox" defaultChecked={isHeader}
                onChange={changeIsHeader}
                id="is-data-header"
            ></input><label htmlFor="is-data-header"
                style={{ display: 'inline', width: 'auto', float: 'unset' }}
                className="font12"
            >CSV has a header</label></div>
        <div className="preview-table-container scrollbar-container">
            <table>
                {
                    isHeader ? <>
                        <thead>
                            {
                                getCSVFields(ruleType).map((i: any) => {
                                    return <th key={'head-' + i.label}>{i.label}{!i.isOptional ? '*' : ''} </th>
                                })
                            }
                        </thead>
                        <tbody>
                            {
                                parsedWithHeader?.map((i: any, index: number) => {
                                    return <tr key={'preview-' + index}>
                                        {
                                            Object.values(i).map((it: any, ind: number) => {
                                                return <td key={'data-value-' + ind + '-' + Date.now().toString()}>{it}</td>
                                            })
                                        }
                                    </tr>
                                })
                            }
                        </tbody></> :
                        <>
                            <thead>
                                {
                                     getCSVFields(ruleType).map((i: any) => {
                                        return <th key={'head-' + i.label}>{i.label}{!i.isOptional ? '*' : ''} </th>
                                    })
                                }
                            </thead>
                            <tbody>
                                {
                                    parsedWithoutHeader?.map((i: any, index: number) => {
                                        return <tr key={'preview-' + Date.now().toString() + '-' + index}>
                                            {
                                                Object.values(i).map((it: any, ind: number) => {
                                                    return <td key={'data-value-' + Date.now().toString() + '-' + ind}>{it}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </>
                }
            </table>
        </div>
        <div className='error' style={{ marginTop: '2%' }}>{er && er?.length > 0 && er}</div>
    </>

});
export default React.memo(PreviewTabCsv);


interface IErrorPopUp {
    handleClose(): any,
    error: string[]
}

export const ImportFailErrorPopUp = ({ handleClose, error }: IErrorPopUp) => {
    return <Popup
        closeOnDocumentClick={false}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnEscape={false}
        modal
    >
        <div style={{ marginBottom: '5%' }} className={`${styles['modal']}`}
        >
            <div className={styles.close} onClick={handleClose}></div>
            <div className={styles.header}>
                <div>{'CSV Import Failed'}</div>
            </div>
            <div className={styles.content}>
                <div className='font14 marginbottom10'>CSV cannot be imported due to the validation errors below: </div>
                <ul key={'error-list'} className='font14 csv-error-content-container'
                    style={{ marginLeft: '2%', width: '100%', margin: '2%' }}>{
                        error?.length > 0 && error.map((i: string, index: number) => {
                            return <li key={`error-at-${index}`}
                                style={{ marginTop: '2%' }}
                            >{i}</li>
                        })
                    }
                </ul>
                <div className='font14'>Please retry after correcting the errors above.</div>
            </div>
            <div className="shadow_footer fl"></div>
            <div className="popup_footer fl">
                <div className="policy_save_button" onClick={handleClose}>Ok</div>
            </div>
        </div>
    </Popup>
}