export const getValueOnType = (type, value, removeQuotes = false) => {
  if (value) {
    let val = null,
      list = null;
    switch (type) {
      case "text":
      case "datetime":
        return value;
      case 'array-autocomplete':
      case "multi-select":
        if (Array.isArray(value)) {
          return value;
        }
        val = String(value);
        list = val.split(",");
        return list;
      case "number-array":
        return value;
      case "number-range":
        if (Array.isArray(value)) {
          return value;
        }
        val = String(value);
        list = val.split(",");
        return list;
      case "datetime-range":
        if (Array.isArray(value)) {
          return value;
        }
        val = String(value);
        list = val.split(",");
        return list;
      case "text-array":
        if (Array.isArray(value)) {
          return value;
        }
        val = String(value);
        list = parseFilterInput(val, removeQuotes);
        return list;
      default:
        return value;
    }
    // return value;
  }
  switch (type) {
    case "text":
    case "text-array":
    case "number-array":
    case "datetime":
      return "";
    case "number-range":
    case "multi-select":
    case "array-autocomplete":
      return [];

    default:
      return [];
  }
};

export const getSearchFieldObject = (type, value, removeQuotes) => {
  try {
    if (value) {
      let val = null,
        list = null;
      switch (type) {
        case "number-array":
        case "number-range":
          if (Array.isArray(value)) {
            return value;
          }
          val = String(value);
          list = val.split(",");
          return list.map((p) => parseInt(p));
        case "text":
          return String(value);
        case "number":
        case "datetime":
          return parseInt(value);
        case 'array-autocomplete':
        case "multi-select":
          if (Array.isArray(value)) {
            return value;
          }
          val = String(value);
          list = val.split(",");
          return list;
        case "datetime-range":
          if (Array.isArray(value)) {
            return value;
          }
          val = String(value);
          list = val.split(",");
          return list.map((p) => parseInt(p));
        case "text-array":
          if (Array.isArray(value)) {
            return value;
          }
          val = String(value);
          list = parseFilterInput(val, removeQuotes);
          return list;
        case "bool":
          try {
            if (value?.toLowerCase()?.trim() === 'true' || value?.toLowerCase()?.trim() === '1') {
              return true;
            }
            return false;
          } catch (error) {
            return false;
          }
        default:
          return value;
      }
      // return value;
    }
  } catch (error) {
    console.log(error);
    switch (type) {
      case "text":
        return "";
      case "datetime":
        return 0;
      case "number-array":
      case "number-range":
      case "multi-select":
      case "date-range":
        return [];
      default:
        return [];
    }
  }
};

export const getControlType = (options, search_key, operatorId = '') => {
  if (search_key === "") {
    return "";
  }
  const opt = options.find((p) => p?.search_key === search_key);
  if (operatorId === '') {
    return opt?.operators ? opt?.operators[0]?.valueType : undefined;
  }
  const operator = opt?.operators?.find(p => p?.id === operatorId);
  return operator?.valueType || 'text';
};

export const getControlTypeforTextBox = (options, search_key) => {
  if (search_key === "") {
    return null;
  }
  return options.find((p) => p.search_key === search_key)?.valueType || null;
};

export const getControlValues = (options, search_key) => {
  if (search_key === "") {
    return [];
  }
  return options.find((p) => p.search_key === search_key)?.optionValues || [];
};
export const getCasingForControl = (options, search_key) => {
  if (search_key === "") {
    return [];
  }
  return options.find((p) => p.search_key === search_key)?.casing || '';
};

export const getControlValidations = (options, search_key) => {
  if (search_key === "") {
    return [];
  }
  const opt = options.find((p) => p.search_key === search_key);
  return opt?.validations || [];
};

export const getControlOperators = (options, search_key) => {
  if (search_key === "") {
    return [];
  }
  const opt = options.find((p) => p.search_key === search_key);
  return opt?.operators || [];
};

export const getControlPlaceholderText = (options, search_key) => {
  if (search_key === "") {
    return "";
  }
  const opt = options.find((p) => p.search_key === search_key);
  return opt?.placeholder || "";
};

export const getFilterObjFromTag = (tag, searchKeys = []) => {
  try {
    let [key, operator, ...value] = tag?.split(/(?=(?:(?:[^"]*"){2})*[^"]*$)([:~|^=<>])/);;
    if (Array.isArray(value)) {
      value = value.join('');
    }
    // commented older code below, validating supported keys.
    // keys i.e: status:Open (adv supported)
    //  is_external: true (textbox supported)
    if (searchKeys.indexOf(key) > -1) {
      if (operator === "~") {
        return [key, value, "contains"];
      } else if (operator === "|") {
        return [key, value, "between"];
      } else if (operator === "^") {
        return [key, value, "not_equals"];
      } else {
        // default operator will be equals (for textbox supported keys i.e: isExternal)
        return [key, value, "equals"];
      }
    }
    else {
      // any other random key. i.e tag = test:http://xyz.com
      return ["any_field", String(tag), "contains"];
    }

    // if (operator === "~") {
    //   return [key, value, "contains"];
    // } else if (operator === ":") {
    //   return [key, value, "equals"];
    // } else if (operator === "|") {
    //   return [key, value, "between"];
    // } else {
    //   return ["any_field", String(tag), "contains"];
    // }
  }
  catch (error) {
    console.log(error);
    return ["any_field", String(tag), "contains"];
  }
};

export function camelCase (text) {
  if (text !== null && text !== undefined && text !== '') {
    const txt = text.split(' ');
    return txt.map(([p, ...rest]) => p.toUpperCase() + rest.join('')).join(' ');
  }
  return text;
}

export const parseFilterInput = (str, removeQuotes) => {
  const regex = /("[^"]*"|[^,]+)/g;
  const matches = str.match(regex);
  const result = [];

  if (!matches) return [];

  for (let i = 0; i < matches.length; i++) {
    if (matches[i].startsWith('"') && matches[i].endsWith('"')) {
      // Preserve quoted substring intact
      if (removeQuotes) {
        result.push(removeExtraQuotes(matches[i]));
      } else {
        result.push(matches[i]);
      }
    } else {
      // Split non-quoted segments by comma
      const splitSegment = matches[i].split(/,/).filter(Boolean);
      result.push(...splitSegment);
    }
  }

  return result;
};

const removeExtraQuotes = (str) => {
  // Check if the string starts and ends with a double quote
  if (str.startsWith('"') && str.endsWith('"')) {
    // Remove the double quotes
    return str.slice(1, -1);
  }
  return str;
};
