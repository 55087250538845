import React, { useState, useEffect } from 'react'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import {Api} from '../../components/Axios'
import { EditPanel } from './sub'
import { useToasts } from '../../components/core';

type ActionType = {
    type?: string;
    id?: string;
}

type DNS = {
    authentication?: string;
    host?: string;
    password?: string;
    port?: string;
    security?: string;
    user_name?: string;
}

const Dns = ({query, setQuery}: any) => {
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [dns, setDns] = useState<DNS>()
    const { addToast } = useToasts()

    const LoadDns = () => {
        Api.get('/dns')
        .then((res: { data: any }) => {
            setDns(res.data)
        })
        .catch((error: any) => {
        })
    }

    useEffect(() => {
        LoadDns()
    }, [])

    useEffect(() => {
        switch(actionType?.type) {            
            case 'edit':
                setPanelTitle("Edit List")
                setOpenPanel(true)
                return
            
            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        setQuery( )
    }

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterEditOperation={AfterEditOperation} />
                        } 
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const AfterEditOperation = (action: string) => {
        LoadDns()
        setOpenPanel(false)
    }

    return (
        <>
            <RightPanel />
            <div className="page_title_area">
                <h2>DNS Servers</h2>
                <div className="launch_button">
                    <button type="button" className="button_main float_right margin10" onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                    <button type="button" className="button_main float_right margin10" onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                </div>
            </div>
            <div className="box_grid_layout margintop20">
                <div className="box_grid_title">
                    <h4>List of Internal or External DNS Servers: </h4>
                </div>
                
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default (Dns)