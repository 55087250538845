import { Api } from '../../components/Axios';
import { getCategories } from '../../utils/util-methods';
export const DIRECTORY_CATEGORY = [680, 660, 700, 640, 580, 600, 560, 620];
export const GLOBAL_SERVICE = {
	Category: {
		GET: async () => {
			let arr = {} as any;
			/* const mock = Object.values(MockIssueCategories);
			arr = {
				src_category: mock,
				dst_category: mock,
				dir_category: mock
			}; */
			try {
				const d = getCategories();
				if (d) {
					const data = JSON.parse(d);
					arr = await new Promise((res, rej) => {
						setTimeout(() => res({ data }));
					});
				} else {
					arr = await Api.get('/sensor/cat-info/category_list');
				}
			} catch (e) {
				arr = {};
			}

			const data = arr.data;
			if (data) {
				const categories = Object.values(data);
				arr = {
					src_category: categories,
					dst_category: categories,
					dir_category: Object.keys(data)
						.map((value: string) => {
							if (DIRECTORY_CATEGORY.includes(parseInt(value))) {
								return data[value];
							}
							return '';
						})
						.filter((value: string) => value)
				};
				sessionStorage.setItem('categories', JSON.stringify(data));
				return arr;
			}
			return {
				src_category: [],
				dst_category: [],
				dir_category: []
			};
		}
	}
};
