import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from "use-query-params";
import { useAuthDetails } from "../../components/Authorization";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";
import AMAdminTable, { ITableAction, ITableColumn, ITableReference, ITableResult } from "../../components/core/AMTable/AMAdminTable";
import { AMAdminPopUp, AMAdminRightPanel, AMAdminSearchTable, IAMAdminSearchTableRef } from "../ref/AMAdminCommonComponents";
import { KnownPublicIpForm } from "./KnownPublicIpForm";
import { KnownPublicIpDeletePopUpProps, KnownPublicIpsCollection, PanelData } from "./KnownPublicIpTypes";
import ModalPopup from "../../components/core/AMModalPopup/ModalPopup";

const CollectorTableColumns: ITableColumn<KnownPublicIpsCollection>[] = [
    {
        headerText: 'Comment',
        key: 'comment',
        classes: "align_left ellipsis",
        sortable: true
    },
    {
        headerText: 'Public IPs/IP Ranges/FQDNs/Patterns',
        key: 'ips',
        sortable: true,
        classes: "align_left",
        html: (data: KnownPublicIpsCollection) => {
            if (data?.ips?.length) {
                return <div className="exception_table_scroll scrollbar-container">
                    {data.ips.map((i: string) => <li>{i}</li>)}
                </div>
            } else {
                return <li>No data available.</li>
            }
        }
    }
];

const KnownPublicIpActions: ITableAction<KnownPublicIpsCollection | unknown>[] = [
    {
        actionId: 'edit-known-ip',
        actionLabel: 'Edit',
    },
    {
        actionId: 'delete-known-ip',
        actionLabel: 'Delete'
    }
]

const KnownPublicIps = ({ query, setQuery }: any) => {
    const [data, setData] = useState<ITableResult<KnownPublicIpsCollection | any> | any>();
    const childRef = useRef<ITableReference>();
    const { authDetails } = useAuthDetails()!;
    const [panelData, setPanelData] = useState<PanelData>({ panelTitle: '', showPanel: false, panelData: undefined });
    const [showPopUp, setShowPopUp] = useState<KnownPublicIpsCollection | undefined>(undefined);
    const searchRef = useRef<IAMAdminSearchTableRef>();
    const { addToast } = useToasts();
    const history = useHistory();


    useEffect(() => {
        if (history.location.pathname == '/known_public_ip/add') {
            const ipData = JSON.parse(sessionStorage.getItem('known_public_ip') || '');
            history.replace({
                pathname: '/known_public_ip'
            });
            setPanelData({
                panelTitle: 'Add Known Internal Public IPs', showPanel: true, panelData: {
                    ...ipData,
                    _id: ''
                }
            })
            sessionStorage.removeItem('known_public_ip');
        } else {
            sessionStorage.removeItem('known_public_ip');
        }
    }, [])

    KnownPublicIpActions[0].actionCallback = (data?: KnownPublicIpsCollection | any) => {
        if (data) {
            setPanelData({ panelTitle: 'Edit Known Internal Public IPs', showPanel: true, panelData: data })
        }
    }

    KnownPublicIpActions[1].actionCallback = (data?: KnownPublicIpsCollection | any) => {
        if (data) {
            setShowPopUp(data)
        }
    }

    const handleOnClosed = () => {
        setPanelData({ ...panelData, showPanel: false });
        setShowPopUp(undefined);
    }

    const proceedDelete = () => {
        const headers = { 'Operation': 'DELETE' };
        Api.post(`/knownpublicip/${showPopUp?._id}`, "", { headers }).then((res: { data: any }) => {
            handleOnClosed();
            addToast("Known internal public ips have been deleted successfully.", {
                appearance: 'success',
                autoDismiss: true,
            });
            setQuery({ ...query, hard_refresh: 'true' });
            fetchKnownPublicIps();
        })
            .catch((error: any) => {
                handleOnClosed();
                handleError(error);
            })
    }

    const handleError = (error: any) => {
        if (error?.response?.data) {
            addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 500) {
            addToast("Sorry, something went wrong there, try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 419) {
            addToast("We encounted validation problem, please correct and try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status === 404) {
            addToast("We are not able to find associated email, please check and try again.", {
                appearance: 'error',
                autoDismiss: true,
            })
        } else if (error?.response?.status == 409) {
            addToast(error.response.data, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    const deletePopUpButtons = {
        primary: {
            label: 'Yes',
            action: proceedDelete
        },
        secondary: {
            label: 'No',
            action: handleOnClosed
        }
    }

    const fetchKnownPublicIps = () => {
        Api.get('/knownpublicip', { params: { ...query, rpp: query?.rpp || 100 } })
            .then((resp: any) => {
                const data = resp.data as ITableResult<KnownPublicIpsCollection>;
                setData(data)
            })
            .catch((error: any) => {
                setData({ result: [] })
                childRef?.current?.onSetTableError('Error while fetching data');
            })
    }

    useEffect(() => {
        fetchKnownPublicIps();
    }, [query]);

    const onSubmit = (data: any) => {
        if (childRef?.current?.onSearch) {
            childRef?.current?.onSearch(data);
        }
    }

    const onAddKnownPublicPage = () => {
        setPanelData({ panelTitle: 'Add Known Internal Public IPs', showPanel: true })
    }

    const getActions = () => {
        const action = {
            action: {
                label: 'Add',
                onClickHandler: onAddKnownPublicPage
            }
        };

        return authDetails?.permissions.Admin.known_public_ip != "readonly" ?
            action : null;
    }
    const SearchTable = () => {
        return <AMAdminSearchTable
            searchTitle="Known Internal Public IPs"
            searchPlaceHolder="Comment, Public IPs, IP Ranges, FQDNs"
            query={query.q} ref={searchRef}
            onSearchValue={onSubmit}
            {...getActions()}
        />;
    }

    const onFormActionSuccess = () => {
        setPanelData({ panelTitle: '', showPanel: false, panelData: undefined });
        fetchKnownPublicIps();
    }

    const KnownPanel = () => {
        return <AMAdminRightPanel panelTitle={panelData.panelTitle}
            handleOnClosed={handleOnClosed}
            openPanel={panelData.showPanel}>
            <KnownPublicIpForm knownPublicData={panelData?.panelData} onFormActionSuccess={onFormActionSuccess} />
        </AMAdminRightPanel>
    }

    const KnownPopup = ()=>{
        return (
            <ModalPopup
                onCloseHandler={handleOnClosed}
                title={panelData.panelTitle}
                isOpen={panelData.showPanel}
            >
                <KnownPublicIpForm knownPublicData={panelData?.panelData} onFormActionSuccess={onFormActionSuccess} />
    
            </ModalPopup>
        )
    }

    const DeleteKnownIpPopUp = ({ knownPublicData, popUpButtons }: KnownPublicIpDeletePopUpProps) => {
        return <AMAdminPopUp popUpTitle={'Delete Known Internal Public Ips'} popUpButtons={popUpButtons} handleClose={handleOnClosed}>
            {
                knownPublicData?.comment ?
                    <span className="font14">Are you sure you want to delete the known internal public ips <strong>{knownPublicData?.comment}</strong> ? </span>
                    : <span className="font14">Are you sure you want to delete the selected known internal public ips ? </span>
            }
        </AMAdminPopUp>
    }

    return <>
        <SearchTable />
        <AMAdminTable query={query} setQuery={setQuery}
            columns={CollectorTableColumns} data={data} ref={childRef}
            /* TODO//known_public_ip replace with this */
            {...(KnownPublicIpActions?.length > 0 && authDetails.permissions.Admin.known_public_ip != "readonly"
                ? { actions: KnownPublicIpActions } : null)}
        />
        {KnownPopup()}
        {showPopUp ? <DeleteKnownIpPopUp knownPublicData={showPopUp} popUpButtons={deletePopUpButtons} /> : null}
    </>
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    hard_refresh: StringParam,
    sensor_id: StringParam
}, KnownPublicIps);