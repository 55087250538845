import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Api } from '../components/Axios';
import { useToasts } from '../components/core';
import { AMPasswordInput } from '../components/core/AMPasswordInput/AMPasswordInput';
import { PasswordStrengthMeter } from '../components/PasswordStrengthMeter';
import { parseJwt } from '../utils/util-methods';
import BeforeLoginContainer from './BeforeLoginContainer';
import './ResetPassword.css';


type Inputs = {
    password: string,
    confirm_password: string
}

interface Params {
    auth_token: string
}

const ResetPassword = () => {
    const [auth_token, setAuthToken] = useState<any>()
    const [loading, setLoading] = useState({ loading: false, setData: false, error: false })
    const { addToast } = useToasts()
    const { register, handleSubmit, errors } = useForm<Inputs>()
    const [passWd, setPassWd] = useState("")
    const [newErrors, setNewErrors] = useState<any>();
    const [confirmErrors, setConfirmErrors] = useState<any>();
    const [confirmPassWd, setConfirmPassWd] = useState<any>();
    const [email, setEmail] = useState<any>();
    let query = useQuery();
    const history = useHistory();
    useEffect(() => {
        setAuthToken(query.get('auth_token'));
        const userDetails = parseJwt(query.get('auth_token'));
        if (userDetails && userDetails.email) {
            setEmail(userDetails.email);
        } else {
            addToast('Link you are trying to access is invalid.', {
                appearance: 'error',
                autoDismiss: true,
                hideDuration: 3000
            });
            history.push('/login');
        }
    }, [])

    useEffect(() => {
        /* Api.get('/reset_password')
        .then((res: {data: any}) => {
        })
        .catch((error: any) => {
            setLoading({loading: false, setData: false, error: true})
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 401) {
                addToast("Please try resetting your password again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }  else if(error.response.status === 404) {
                addToast("A password reset link is expired.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        }) */
    }, [])

    const onSubmit = (data: any) => {
        const dataValue = {
            email: email,
            password: passWd,
            confirm_password: confirmPassWd
        }
        if (validateForm()) {
            setLoading({ loading: true, setData: false, error: false })
            Api.post('/reset-password', dataValue, { headers: { 'Authorization': `Bearer ${auth_token}` } })
                .then((res: { data: any }) => {
                    addToast("Your password has been reset successfully, please try login.", {
                        appearance: 'success',
                        autoDismiss: true,
                        hideDuration: 3000
                    })
                    setLoading({ loading: false, setData: true, error: false })
                })
                .catch((error: any) => {
                    setLoading({ loading: false, setData: false, error: false })
                    if (error.response.status === 500) {
                        addToast("Sorry, something went wrong there, try again.", {
                            appearance: 'error',
                            autoDismiss: true,
                            hideDuration: 3000
                        })
                    } else if (error.response.status === 419) {
                        addToast("We encounted validation problem, please correct and try again.", {
                            appearance: 'error',
                            autoDismiss: true,
                            hideDuration: 3000
                        })
                    } else if (error.response.status === 404) {
                        addToast("Link you are trying to access is either expired or already used.", {
                            appearance: 'error',
                            autoDismiss: true,
                            hideDuration: 3000
                        })
                    } else if (error.response.status === 406) {
                        addToast("Password entered does not match with criteria, please enter a strong password.", {
                            appearance: 'error',
                            autoDismiss: true,
                            hideDuration: 3000
                        })
                    }
                })
        }
    }

    const passwordChanged = (e: any) => {
        setPassWd(e.target.value);
        setNewErrors({});
    }

    const newPasswordChanged = (e: any) => {
        setConfirmPassWd(e.target.value);
        setConfirmErrors({});
    }

    const validateForm = () => {
        return !validateNewPassword() && !validateConfirmPassword()
            && !validateNewConfirmPassword();
    }
    const validateNewPassword = () => {
        if (!passWd || (passWd && passWd.length < 0)) {
            setNewErrors({ required: 'required' });
            return 'error';
        } else {
            setNewErrors({});
        }
        return null;
    }

    const validateConfirmPassword = () => {
        if (!confirmPassWd || (confirmPassWd && confirmPassWd.length < 0)) {
            setConfirmErrors({ required: 'required' });
            return 'error';
        } else {
            setConfirmErrors({});
        }
        return null;
    }

    const validateNewConfirmPassword = () => {
        if (confirmPassWd && passWd && confirmPassWd != passWd) {
            setConfirmErrors({ custom: 'New password and confirm password do not match.' });
            return 'error';
        } else {
            setNewErrors({});
        }
        return null;
    }

    if (loading.setData || loading.error) return <Redirect to="/login" />
    return (
        <BeforeLoginContainer><form onSubmit={handleSubmit(onSubmit)}>
            <div className="form_header">Reset Password</div>
            <label>Username/Email</label>
            <input type='text' disabled value={email} />
            <label>New Password</label>
            <AMPasswordInput onChange={(e: any) => { passwordChanged(e) }} errors={newErrors} />

            <PasswordStrengthMeter password={passWd} />

            <label>Confirm Password</label>
            <AMPasswordInput onChange={(e: any) => { newPasswordChanged(e) }} errors={confirmErrors} />
            <div className="password_message"></div>

            <button type={(loading.loading ? 'button' : 'submit')} className={"float_right " + (loading.loading ? 'loader' : 'button_styled')}>Reset</button>
            <div className="clrBoth"></div>
        </form>
        </BeforeLoginContainer>
    )
}

export default ResetPassword;

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}