import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import 'react-sliding-side-panel/lib/index.css'
import { Api } from '../../components/Axios'
import { useToasts } from '../../components/core'
import { useAuthDetails } from '../../components/Authorization'
import 'react-tagsinput/react-tagsinput.css'
import './sso.css'
import { SSOSaveActionType, SSOSavePopUp } from './reference/SSOSavePopUp'
import { useHistory } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import { AMToolTipAd } from '../../components/core/AMTooltipAd/AMTooltipAd'
import { usePartnerStateContext } from '../../store/PartnerContextProvider'
import CheckboxAutocomplete from '../../components/core/AMAutoComplete/AMAutoComplete'

const options = [
    { "key": "Verify", "value": "IBM Verify SAMLv2" },
    { "key": "OneLogin", "value": "OneLogin SAMLv2" },
    { "key": "Azure AD", "value": "Azure AD SAMLv2" },
    { "key": "Okta", "value": "Okta SAMLv2" },
    { "key": "F5", "value": "F5 SSO SAMLv2" },
    { "key": "Google Workspace SSO", "value": "Google Workspace SSO" }
]


type SSOList = {
    enable_sso?: boolean;
    name?: string;
    portal_fqdn?: [];
    entity_id?: string;
    metadata_url?: string;
    metadata_xml?: string;
    sso_login_url?: string;
    certificate?: string;
    private_key?: string;
    company_id?: string;
    root_sso?: boolean
}

const validateIpAddress = (ipaddress: string) => {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return (true)
    }
    return (false)
}

const AM_OPS_PTRN = /^amops@[a-zA-Z0-9.-]+\.authmind$/;

const Sso = ({ query, setQuery }: any) => {

    const [vendorType, setVendorType] = useState<string | undefined>();
    const [metaType, setMetaType] = useState<string>('url');
    const { register, handleSubmit, errors, setValue, control, setError, clearErrors } = useForm<SSOList>({ reValidateMode: 'onBlur' });
    const [loading, setLoading] = useState<Boolean>(false)
    const [ssoData, setSsoData] = useState<SSOList>()
    const [showenabled, setShowEnabled] = useState<Boolean>(false);
    const [ssoCheck, setSsoCheck] = useState<boolean>();
    const [portalFQDN, setPortalFQDN] = useState([])
    const { addToast } = useToasts()
    const [showSavePop, setShowPopUp] = useState(false);
    const [postData, setPostData] = useState();
    const [enableSSO, setEnableSSO] = useState<Boolean>(false);
    const { authDetails } = useAuthDetails()!;
    const userDetails = { role: authDetails?.role, email: authDetails?.email };
    const history = useHistory();
    const { PartnerConfigState } = usePartnerStateContext()
    const updateSSOData = (res: { data: SSOList }) => {
        if (!res) return;
        setValue("enable_sso", res.data.enable_sso)
        //setValue("portal_fqdn", res.data.portal_fqdn)
        if (res.data.enable_sso === true) {
            setSsoCheck(true);
            setShowEnabled(true);
            control.register('name', { required: 'Please select vendor.', });
        }
        setVendorType(res.data.name)
        // if(res.data.portal_fqdn) {
        //     setPortalFQDN(res.data.portal_fqdn)
        // }
        let root_sso_flag = false;
        if (res.data.enable_sso) {
            root_sso_flag = res.data.root_sso || false;
        }
        setSsoData({
            "entity_id": res.data.entity_id,
            "portal_fqdn": res.data.portal_fqdn,
            "sso_login_url": res.data.sso_login_url,
            "metadata_url": res.data.metadata_url,
            "certificate": res.data.certificate,
            "private_key": res.data.private_key,
            "metadata_xml": res.data?.metadata_xml,
            "root_sso": root_sso_flag,
            "company_id": res.data.company_id
        });
        setValue("root_sso", root_sso_flag)
        setEnableSSO(res?.data?.enable_sso || false);
        if (res?.data?.metadata_xml) {
            setMetaType('xml')
        }
        if (!res.data.portal_fqdn) {
            updateFQDN();
        }

    }

    useEffect(() => {
        Api.get('/sso')
            .then((res: { data: SSOList }) => {
                /* res.data = { "enable_sso": true, "portal_fqdn": "localhost:3000", "root_sso": true, "name": "OneLogin", "entity_id": "test", "metadata_url": "testID", "metadata_xml": null } */
                updateSSOData(res);
            })
            .catch((error: any) => {
                // console.log("GET - console error ***", error.response.data)
            })

        control.register('name');
    }, [])

    const onUpdateSSO = (data: any) => {
        if (!vendorType && ssoCheck) {
            setError('name', { type: 'required', message: 'Please select vendor type' })
            return
        }
        clearErrors()
        data.name = vendorType
        setPostData(data);
        if (enableSSO != showenabled) {
            setShowPopUp(true);
        } else {
            postSSOData(data);
        }
    }

    useEffect(
        () => {
            setVendorType(vendorType);
            setValue('name', vendorType, { shouldValidate: true });
            /* if (authDetails.permissions.Admin.sso == "invisible") {
                history.push('not-found');
            } */
        }, [vendorType]
    )

    const updateFQDN = () => {
        const host = window.location.host;
        if (!validateIpAddress(host)) {
            setValue('portal_fqdn', host);
        }
    }

    const updateVendor = () => {
        setVendorType('');
        setValue('name', null)
    }

    const resetFields = () => {
        updateFQDN();
        updateVendor();
        control.register('name', { required: 'Please select vendor.', });
    }

    const handleSsoFlagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.target.checked) {
            setSsoCheck(true);
            setShowEnabled(true);
        }
        else {
            setSsoCheck(false);
            setShowEnabled(false);
            control.unregister('name');
            clearErrors()
            setVendorType('')
        }        
    }

    const AddTags = (tag: any) => {
        setPortalFQDN(tag)
    }

    const handleSSOCloseConfirm = () => {
        setShowPopUp(false);
    }

    const handleSSOOkConfirm = () => {
        postSSOData(postData || {});
    }

    const postSSOData = (data: any) => {
        if (metaType === 'xml') {
            data.metadata_url = null;
        }
        if (metaType === 'url') {
            data.metadata_xml = null;
        }
        data.enable_sso = showenabled;
        setLoading(true);
        setShowPopUp(false);
        Api.post('/sso', data)
            .then((res: { data: any }) => {
                setLoading(false)
                addToast("SSO updated successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                setEnableSSO(showenabled);
                if (ssoCheck === false) {
                    resetFields()
                }
                updateSSOData(res)
            })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    // console.log("404***", error.response.data)
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const getItemLabel = (op) => {        
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    const getValue = () => {
        const arr = options;
        const i = arr.filter((it) => it.key == vendorType || '')[0];
        if (i) {
            return i.value;
        }
        return i;
    }

    return (
        <>
            <div className="page_title_area">
                <h2>SSO Configuration</h2>
            </div>
            <form onSubmit={handleSubmit(onUpdateSSO)}>
                <div className="width50per">
                    <div className="clear_both"></div>
                    <div className='enable-sso-container'>
                        <input
                            name="enable_sso"
                            type="checkbox"
                            onChange={handleSsoFlagChange}
                            checked={ssoCheck ? ssoCheck : false}
                            ref={register}
                            className={("margintop20")}
                        /><label className='enable-sso' style={{ float: 'right' }}>Enable SSO</label>
                    </div>
                    <div className="clear_both"></div>

                    {/* { showenabled ?
                    <div>
                        <label>FQDN</label>
                        <TagsInput
                            value={portalFQDN}
                            onChange={AddTags}
                            inputProps={{placeholder: "Add FQDN"}}
                        />
                        <div className="marginleft40per error">Note: Hit 'Enter' or 'Tab' key after each FQDN</div>
                    </div> : null
                } */}

                    {/* {showenabled ?
                        <div>
                            <label>FQDN of your AuthMind console</label>
                            <input
                                type="text"
                                placeholder="Enter FQDN"
                                name="portal_fqdn"
                                ref={register({
                                    required: true,
                                })}
                                defaultValue={ssoData?.portal_fqdn}
                            />
                        </div> : null
                    } */}


                    <div className={"sso-form form_sub_section width100per" + (showenabled ? '' : ' disableItems')}>
                        <label>Id : {ssoData?.company_id} </label>
                        <div>
                            <label>FQDN of {`${PartnerConfigState.PartnerShortProduct}`} Console</label>
                            <input
                                type="text"
                                placeholder="Enter FQDN"
                                name="portal_fqdn"
                                ref={register({
                                    required: true,
                                })}
                                // value={ssoData?.portal_fqdn}
                                defaultValue={ssoData?.portal_fqdn}
                            />
                        </div>
                        <label className="font_semibold margintop0 id-details">Identity Provider (IdP) Details</label>
                        <div className="clear_both"></div>
                        <label>Vendor*</label>

                        <CheckboxAutocomplete
                            options={options}
                            getItemLabel={getItemLabel}
                            value={{ key: vendorType, value: getValue() }}
                            onChange={(e, config) => {
                                setVendorType(config[0].key);
                                setValue('name', config[0].key)
                                clearErrors()
                                setTimeout(() => {
                                    setValue('entity_id', '');
                                    setValue('metadata_url', '');
                                    setValue('metadata_xml', '');
                                });
                            }}
                            multiple={false}
                            hideClearBtn={true}
                        />
                        {/*    <select style={{ marginBottom: 0 }} className="form_drodown" name="name" ref={register} onChange={(e) => {
                            setVendorType(e.currentTarget.value);
                            setTimeout(() => {
                                setValue('entity_id', '');
                                setValue('metadata_url', '');
                                setValue('metadata_xml', '');
                            });
                        }} value={vendorType}
                        >
                            <option value="">Select Vendor</option>
                            <option value="Verify">IBM Verify SAMLv2</option>
                            <option value="OneLogin">OneLogin SAMLv2</option>
                            <option value="Azure AD">Azure AD SAMLv2</option>
                            <option value="Okta">Okta SAMLv2</option>
                            <option value="F5">F5 SSO SAMLv2</option>
                            <option value="Google Workspace SSO">Google Workspace SSO</option>
                        </select> */}
                        {errors.name && <div className="error marginleft40per">{errors.name.message}</div>}
                        <div className="clear_both"></div>
                        {vendorType === "Verify" ?
                            <div>
                                <label>Provider ID*</label>
                                <input
                                    type="text"
                                    placeholder="IBM Verify Application Provider ID"
                                    name="entity_id"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.entity_id}
                                />

                                <label>IdP Metadata*</label>
                                <textarea
                                    name="metadata_url"
                                    rows={6}
                                    className="float_left"
                                    placeholder="Enter Metadata URL"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.metadata_url}
                                />
                            </div> : null
                        }
                        {vendorType === "OneLogin" ?
                            <div>
                                <label>Audience (Entity ID)*</label>
                                <input
                                    type="text"
                                    placeholder="Enter Audience (Entity ID)"
                                    name="entity_id"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.entity_id}
                                />
                                <label>Issuer URL*</label>
                                <textarea
                                    name="metadata_url"
                                    rows={8}
                                    style={{ resize: 'none' }}
                                    className="float_left"
                                    placeholder="Enter Issuer URL"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.metadata_url}
                                />
                                {/* <label>SSO Login URL</label>
                        <input
                            type="text"
                            placeholder="Enter SSO Login URL"
                            name="sso_login_url"
                            ref={register({
                                required: false,
                            })}
                            defaultValue={ssoData?.sso_login_url}
                        />
                        <label>Certificate</label>
                        <textarea
                            name="certificate"
                            rows={6}
                            className="float_left"
                            placeholder="Enter Certificate in PEM-encoded format"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={ssoData?.certificate}
                        /> */}

                                {/* <label>Private Key</label>
                        <textarea
                            name="private_key"
                            rows={6}
                            className="float_left"
                            placeholder="Enter Private Key in PEM-encoded format"
                            ref={register({
                                required: false,
                            })}
                            defaultValue={ssoData?.private_key}
                        /> */}
                            </div> : null
                        }

                        {vendorType === "Azure AD" || vendorType == 'F5' ?
                            <div>
                                <label>Identifier (Entity ID)*</label>
                                <input
                                    type="text"
                                    placeholder="Enter Identifier (Entity ID)"
                                    name="entity_id"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.entity_id}
                                />

                                <label>Metadata URL*</label>
                                <textarea
                                    name="metadata_url"
                                    rows={6}
                                    className="float_left"
                                    placeholder="Enter Metadata URL"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.metadata_url}
                                />

                                {/* <label>SSO Login URL</label>
                        <input
                            type="text"
                            placeholder="Enter SSO Login URL"
                            name="sso_login_url"
                            ref={register({
                                required: false,
                            })}
                            defaultValue={ssoData?.sso_login_url}
                        />

                        <label>Certificate</label>
                        <textarea
                            name="certificate"
                            rows={6}
                            className="float_left"
                            placeholder="Enter Private Key in PEM-encoded format"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={ssoData?.certificate}
                        /> */}

                                {/* <label>Private Key</label>
                        <textarea
                            name="private_key"
                            rows={6}
                            className="float_left"
                            placeholder="Enter Private Key in PEM-encoded format"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={ssoData?.private_key}
                        /> */}

                            </div> : null
                        }
                        {vendorType === "Okta" ?
                            <div>
                                <label>Audience URI (SP Entity ID)*</label>
                                <input
                                    type="text"
                                    placeholder="Enter Identifier (Entity ID)"
                                    name="entity_id"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.entity_id}
                                />

                                <label>IdP Metadata*</label>
                                <textarea
                                    name="metadata_url"
                                    rows={6}
                                    className="float_left"
                                    placeholder="Enter Metadata URL"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.metadata_url}
                                />
                            </div> : null
                        }
                        {
                            vendorType === "Google Workspace SSO" && (<div>
                                <label>Audience URI (SP Entity ID)*</label>
                                <input
                                    type="text"
                                    placeholder="Enter Identifier (Entity ID)"
                                    name="entity_id"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.entity_id}
                                />

                                <label>IdP Metadata*</label>
                                <div className='metaType-container' onChange={(e) => {
                                    setMetaType(e.target.value)
                                    setValue('metadata_url', '');
                                    setValue('metadata_xml', '');
                                }}>
                                    <input type="radio" value="url" name="metaType" checked={metaType === 'url'} /> URL
                                    <input type="radio" value="xml" name="metaType" checked={metaType === 'xml'} /> XML

                                </div>
                                {metaType === 'url' ? <textarea
                                    name="metadata_url"
                                    rows={6}
                                    className="float_left meta-type"
                                    placeholder="Enter Metadata URL"
                                    ref={register({
                                        required: true,
                                    })}
                                    defaultValue={ssoData?.metadata_url}
                                /> :
                                    <textarea

                                        name="metadata_xml"
                                        rows={6}
                                        className="float_left meta-type"
                                        placeholder="Enter Metadata XML"
                                        ref={register({
                                            required: true,
                                        })}
                                        defaultValue={ssoData?.metadata_xml}
                                    />}
                            </div>)
                        }
                        <div className='enable-sso-root-container margintop20'>
                            <div className={'enable-sso-div-container ' + (
                                !AM_OPS_PTRN.test(userDetails.email) && ssoData?.root_sso && showenabled ? ' disalbeItemPointerAllow' : ' '
                            )}>
                                <AMToolTipAd
                                    title={!AM_OPS_PTRN.test(userDetails.email) && ssoData?.root_sso && showenabled ?
                                        `To disable, please contact ${PartnerConfigState.PartnerShortProduct} Support` : ''}
                                >
                                    <input
                                        name="root_sso"
                                        type="checkbox"
                                        ref={register}
                                        className={("margintop20")}
                                    />
                                </AMToolTipAd>
                                <label className='enable-sso' style={{ float: 'right' }}>Enable SSO for Root User</label>
                            </div>
                            {(
                                (!AM_OPS_PTRN.test(userDetails.email) && !ssoData?.root_sso)) &&
                                <div className='enable-sso-banner' style={{ display: 'flex', gap: '12px', gap: '10px' }}>
                                    <InfoIcon style={{ fontSize: '1.5em', color: 'orange', marginBottom: '0.2em' }} />
                                    <span className='banner-text'>Note: Root user should only enable SSO for their account once they have confirmed that SSO
                                        is configured correctly to avoid getting locked  out of the {`${PartnerConfigState.PartnerShortProduct}`} console.</span>
                                </div>}
                        </div>
                    </div>
                    <div className="clear_both"></div>
                    <div className="launch_button float_left">
                        <button type={(loading ? 'button' : 'submit')} className={" " + (loading ? 'loader' : 'float_right button_styled')}>Save</button>
                    </div>
                </div>
            </form >
            {
                showSavePop ? <SSOSavePopUp
                    actionType={!showenabled ? SSOSaveActionType.DISABLE : SSOSaveActionType.ENABLE
                    }
                    closeConfirm={handleSSOCloseConfirm}
                    okConfirm={handleSSOOkConfirm}
                /> : null
            }
        </>
    )
}

export default (Sso)
