import { IAMSelectOption } from "../../../components/core/AMSelect/AMSelect";
import { IPatternConfigItem, IPatternFormValue } from "../../playbooks/ref/PatternForm/PatternFormGenerator";
import { validateIPAddress, validateIpPort, validateMACAddresses } from "../../playbooks/ref/config/Pattern-Utils";

const OperatorsMap = {
	equals: 'equals', not_equals: 'not equals', matches: 'matches', not_matches: 'not matches',
	contains: 'contains'
}

const EQUALS = { id: OperatorsMap.equals, label: 'equals' };
const NOT_EQUALS = { id: OperatorsMap.not_equals, label: 'not equals' };
const MATCHES = { id: OperatorsMap.matches, label: 'matches' };
const NOT_MATCHES = { id: OperatorsMap.not_matches, label: 'not matches' };
const CONTAINS = { id: OperatorsMap.contains, label: 'contains' };


const OperatorsArray = [
	{ ...EQUALS }, { ...NOT_EQUALS }, { ...MATCHES }, { ...NOT_MATCHES }
]

const ListYesNoOptions = [
	{ optionLabel: 'Yes', optionValue: 'true' }, { optionLabel: 'No', optionValue: 'false' }] as Array<IAMSelectOption>;


export const ExceptionAdvancedSearchConfig = [
	{
		"id": "dest_countries",
		"type": "array-autocomplete",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Asset/Destination Country",
		"placeholder": "Select country",
		"defaultOperator": "equals",
		"asyncId": "dest_countries",
		"valueType": "string-array",
		"key": "dest_countries",
		"value": "Asset/Destination Country",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Asset/Destination Hostname",
		"placeholder": "Enter comma separated asset hostnames",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_hostname",
		"value": "Asset/Destination Hostname",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Asset/Destination IP",
		"placeholder": "Enter comma separated Ips",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_ip",
		"value": "Asset/Destination IP",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_is_public",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Asset/Destination IP is Public?",
		"placeholder": "Select Yes if asset ip is public",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "d_is_public",
		"value": "Asset/Destination IP is Public?",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_is_cdn",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Asset/Destination IP is a CDN?",
		"placeholder": "Select Yes if asset ip is cdn",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "d_is_cdn",
		"value": "Asset/Destination IP is a CDN?",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_is_datacenter",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Asset/Destination IP is a Data Center?",
		"placeholder": "Select Yes if asset ip is data center",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "d_is_datacenter",
		"value": "Asset/Destination IP is a Data Center?",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_is_icloudrelday",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Asset/Destination IP is an iCloud Relay?",
		"placeholder": "Select Yes if asset ip is an iCloud Relay",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "d_is_icloudrelday",
		"value": "Asset/Destination IP is an iCloud Relay?",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_organization",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Asset/Destination IP Owner Organization (aka “Company”)",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_organization",
		"value": "Asset/Destination IP Owner Organization (aka “Company”)",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_mac",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Asset/Destination MAC",
		"placeholder": "Enter comma separated asset/destination mac",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_mac",
		"value": "Asset/Destination MAC",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Asset/Destination Name",
		"placeholder": "Enter comma separated asset/destination name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_name",
		"value": "Asset/Destination Name",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_type",
		"type": "list",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"label": "Asset/Destination Type",
		"placeholder": "Select asset/destination type",
		"defaultOperator": "equals",
		"asyncId": "d_type",
		"valueType": "string-array",
		"key": "d_type",
		"value": "Asset/Destination Type",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_port",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Asset/Destination Port",
		"placeholder": "Enter comma separated asset/destination port",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_port",
		"value": "Asset/Destination Port",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_is_known",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Asset/Destination Hostname/IP is marked as known?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "d_is_known",
		"value": "Asset/Destination Hostname/IP is marked as known?",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "d_protocol",
		"type": "array-autocomplete",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Protocol",
		"placeholder": "Select protocol",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "d_protocol",
		"value": "Protocol",
		"groupBy": "Destination/Asset"
	},
	{
		"id": "t_protocol",
		"type": "list",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Transport Protocol",
		"placeholder": "Enter Transport Protocols",
		"defaultOperator": "equals",
		"valueType": "string",
		"selectOptions": [
			{
				"optionLabel": "TCP",
				"optionValue": "TCP"
			},
			{
				"optionLabel": "UDP",
				"optionValue": "UDP"
			}
		],
		"key": "t_protocol",
		"value": "Transport Protocol",
		"groupBy": "Destination/Asset"
	},
	{
		"casing": "camel",
		"label": "Asset/Destination Category",
		"defaultOperator": "equals",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"id": "dst_category",
		"valueType": "string-array",
		"type": "multi-select",
		"key": "dst_category",
		"value": "Asset/Destination Category",
		"groupBy": "Destination/Asset",
		placeholder: "Select asset/destination category"
	},
	{
		"id": "source/identity",
		"label": "Source/Identity",
		"isGroupRoot": true,
		"key": "source/identity",
		"value": "Source/Identity",
		"groupBy": "Source/Identity"
	},
	{
		"id": "src_countries",
		"type": "array-autocomplete",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Identity/Source Country",
		"placeholder": "Select country",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "src_countries",
		"value": "Identity/Source Country",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Identity/Source Hostname",
		"placeholder": "Enter comma separated identity/source hostnames",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_hostname",
		"value": "Identity/Source Hostname",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Identity/Source IP",
		"placeholder": "Enter comma separated Ips",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_ip",
		"value": "Identity/Source IP",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_is_public",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Identity/Source IP is Public?",
		"placeholder": "Select Yes if source ip is public",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_is_public",
		"value": "Identity/Source IP is Public?",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_is_cdn",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Identity/Source IP is a CDN?",
		"placeholder": "Select Yes if asset ip is cdn",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_is_cdn",
		"value": "Identity/Source IP is a CDN?",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_is_datacenter",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Identity/Source IP is a Data Center",
		"placeholder": "Select Yes if source ip is data center",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_is_datacenter",
		"value": "Identity/Source IP is a Data Center",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_is_icloudrelday",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Identity/Source IP is an iCloud Relay?",
		"placeholder": "Select Yes if asset ip is an iCloud Relay",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_is_icloudrelday",
		"value": "Identity/Source IP is an iCloud Relay?",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_organization",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Identity/Source IP Owner Organization",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_organization",
		"value": "Identity/Source IP Owner Organization",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_mac",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Identity/Source MAC",
		"placeholder": "Enter comma separated source mac",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_mac",
		"value": "Identity/Source MAC",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Identity/Source Username or Name",
		"placeholder": "Enter comma separated source username or name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_name",
		"value": "Identity/Source Username or Name",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_type",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Identity/Source Type",
		"placeholder": "Select identity/source type",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_type",
		"value": "Identity/Source Type",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_port",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			}
		],
		"label": "Identity/Source Port",
		"placeholder": "Enter comma separated source port",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "s_port",
		"value": "Identity/Source Port",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_is_known",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Identity/Source Hostname/IP is marked as known?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_is_known",
		"value": "Identity/Source Hostname/IP is marked as known?",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_susp_internal_scanner",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Source is a Potential Internal Scanner?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_susp_internal_scanner",
		"value": "Source is a Potential Internal Scanner?",
		"groupBy": "Source/Identity"
	},
	{
		"id": "s_susp_external_scanner",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Source is a Suspected External Scanner?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "s_susp_external_scanner",
		"value": "Source is a Suspected External Scanner?",
		"groupBy": "Source/Identity"
	},
	{
		"casing": "camel",
		"label": "Identity/Source Category",
		"defaultOperator": "equals",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"id": "src_category",
		"valueType": "string-array",
		"type": "multi-select",
		"key": "src_category",
		"value": "Identity/Source Category",
		"groupBy": "Source/Identity",
		placeholder: "Select identity/source category"
	}
	,
	{
		"id": "directory",
		"label": "Directory",
		"isGroupRoot": true,
		"key": "directory",
		"value": "Directory",
		"groupBy": "Directory"
	},
	{
		"id": "dir_hostname",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Directory/IDP Hostname",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "dir_hostname",
		"value": "Directory/IDP Hostname",
		"groupBy": "Directory"
	},
	{
		"id": "dir_name",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Directory/IDP Name",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "dir_name",
		"value": "Directory/IDP Name",
		"groupBy": "Directory"
	},
	{
		"id": "dir_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Directory/IDP IP",
		"placeholder": "Enter comma separated directory/IDP Ips",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "dir_ip",
		"value": "Directory/IDP IP",
		"groupBy": "Directory"
	},
	{
		"casing": "camel",
		"label": "Directory/IDP Category",
		"defaultOperator": "equals",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"id": "dir_category",
		"valueType": "string-array",
		"type": "multi-select",
		"key": "dir_category",
		"value": "Directory/IDP Category",
		"groupBy": "Directory",
		placeholder: "Select directory/IDP category"
	},
	{
		"id": "misc",
		"label": "Miscellaneous",
		"isGroupRoot": true,
		"key": "misc",
		"value": "Miscellaneous",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "flow_time",
		"type": "week-selector",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Access Time",
		"placeholder": "Click to select access time",
		"defaultOperator": "equals",
		"key": "flow_time",
		"value": "Access Time",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "src_inter_ip",
		"type": "text",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Access Control IP",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "src_inter_ip",
		"value": "Access Control IP",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "loc",
		"type": "multi-select",
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			},
			{
				"id": "not equals",
				"label": "not equals"
			},
			{
				"id": "matches",
				"label": "matches"
			},
			{
				"id": "not matches",
				"label": "not matches"
			}
		],
		"label": "Sensor Code",
		"defaultOperator": "equals",
		"valueType": "string-array",
		"key": "loc",
		"value": "Sensor Code",
		"groupBy": "Miscellaneous",
		placeholder: "Select sensor code"
	},
	{
		"id": "is_streaming_flow",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Potential Streaming Flow?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "is_streaming_flow",
		"value": "Potential Streaming Flow?",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "is_svc_to_svc_flow",
		"type": "list",
		"selectOptions": [
			{
				"optionLabel": "Yes",
				"optionValue": "true"
			},
			{
				"optionLabel": "No",
				"optionValue": "false"
			}
		],
		"operators": [
			{
				"id": "equals",
				"label": "equals"
			}
		],
		"label": "Potential Service-to-Service Flow?",
		"defaultOperator": "equals",
		"valueType": "boolean",
		"key": "is_svc_to_svc_flow",
		"value": "Potential Service-to-Service Flow?",
		"groupBy": "Miscellaneous"
	},
	{
		"id": "collector_name",
		"type": "list",
		"operators": [
			{
				"id": "equals",
				"label": "equals",
				"valueType": "multi-select"
			},
			{
				"id": "not equals",
				"label": "not equals",
				"valueType": "multi-select"
			}
		],
		"label": "Collector Name",
		"placeholder": "Enter comma separated collector name",
		"defaultOperator": "equals",
		"asyncId": "collector_name",
		"valueType": "string-array",
		"key": "collector_name",
		"value": "Collector Name",
		"groupBy": "Miscellaneous"
	}
] as Array<IPatternConfigItem>;
