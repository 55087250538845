import React from "react"
import ModalPopup from "../AMModalPopup/ModalPopup"

interface AMGenericConfirmPopUp {
    onCloseHandler(): void,
    onProceedHandler(): void,
    onNoHandler?(): void
    title: string,
    showConfirm: boolean,
    children: React.ReactNode,
    buttons: {
        yes: {
            label: string,
            id: string,
            isDisabled?: boolean
        }
        , no?: {
            label: string,
            id: string
        }
    }
}
export const AMGenericConfirmPopUp = ({ onCloseHandler, onProceedHandler, title, children, buttons, showConfirm, onNoHandler }: AMGenericConfirmPopUp) => {
    return <ModalPopup title={title} isOpen={showConfirm} onCloseHandler={onCloseHandler}>
        {children}
        <div className="popup-container-button-container dialog-button">
            {
                buttons?.no &&
                <button type="button" onClick={onNoHandler || onCloseHandler}
                    id={buttons?.no?.id || 'no'}
                    className={"button_styled button_gray "}>
                    {buttons?.no?.label || 'No'}
                </button>
            }

            <button type={"button"}
                id={buttons?.yes?.id || 'yes'}
                className={"float_right button_styled " + (buttons.yes.isDisabled ? " disabled grayscaleOne " : '')} onClick={onProceedHandler}>
                {buttons?.yes?.label || 'Yes'}
            </button>
        </div>
    </ModalPopup >
}
