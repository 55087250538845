import React, { useEffect, useState } from 'react';

export const Perimeter81Collector = ({ register, setValue, collectorData }) => {
    useEffect(() => {
        if (collectorData) {
            setValue(
                'sqsurl',
                collectorData.collector_config?.sqsurl
            );
            setValue(
                'accesskey',
                collectorData.collector_config?.accesskey
            );
            setValue(
                'secretkey',
                collectorData.collector_config?.secretkey
            );
        }
    }, [collectorData]);

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                 Check Point Perimeter 81 Collector Configurations
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS SQS URL*</label>
                            <input
                                type='text'
                                name='sqsurl'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS SQS URL'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Access Key ID*</label>
                            <input
                                type='text'
                                name='accesskey'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS Access Key ID'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Access Secret*</label>
                            <input
                                type='text'
                                name='secretkey'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter AWS Access Secret'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
