import { MarkKnownOption } from "../../../common/MarkKnownPopUp/PostureMarkKnownPopUp";
import { PostureMenuItem, PostureMenuItemAction } from "../ref/PostureActionMenus/PostureActionMenu";

export const IdentitiesActionMenu: PostureMenuItem[] = [
    /*  {
         id: 'add-exception-comp-pwd-usr ',
         action: PostureMenuItemAction.ADD_EX_COPWD_USR,
         label: 'Do not search for this identity in external risk feeds (Add Exception)'
     }, */
    {
        id: 'add-exception-for-identity',
        action: PostureMenuItemAction.ADD_EXCEPTION,
        label: 'Ignore this identity’s existing issues (Add Exception)'
    },
    {
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        id: 'close-all-incs',
        label: 'Close all existing incidents for this identity'
    },
    {
        id: 'add-known-public',
        label: 'Add this identity’s IPs/hostnames to Known Public IPs',
        action: PostureMenuItemAction.ADD_KNOWN_PUBLIC
    }
];

export const IdentitiesColumnActionMenu: PostureMenuItem[] = [
    {
        id: 'add-exception-for-identity',
        action: PostureMenuItemAction.ADD_EXCEPTION,
        label: 'Add Exception for existing issues for this Identity'
    },
    {
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        id: 'close-all-incs',
        label: 'Close all existing incidents for this identity'
    }
];


export const AssetsActionMenu: PostureMenuItem[] = [
    {
        id: 'add-exception-for-asset',
        action: PostureMenuItemAction.ADD_EXCEPTION,
        label: `Ignore this asset's existing issues (Add Exception)`
    },
    /* {
        id: 'mark-known-asset',
        action: PostureMenuItemAction.ADD_EX_SHDW_ACC_ASS,
        label: 'Authorize - this asset is not managed by a directory'
    }, */
    {
        id: 'mark-known-asset',
        action: PostureMenuItemAction.MARK_KNOWN,
        label: 'Classify this asset as…'
    },
    {
        id: 'close-all-incs',
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        label: 'Close all existing incidents for this asset'
    },

    {
        id: 'add-known-public',
        label: 'Add this asset’s IPs/hostnames to Known Internal Public IPs',
        action: PostureMenuItemAction.ADD_KNOWN_PUBLIC
    }

];

export const AssetsActionColumnMenu: PostureMenuItem[] = [
    {
        id: 'add-exception-for-asset',
        action: PostureMenuItemAction.ADD_EXCEPTION,
        label: 'Ignore existing issues for this identity → asset combination (Add Exception)'
    },
    {
        id: 'close-all-incs',
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        label: 'Close all existing incidents for this asset'
    }
];

export const DirectoriesActionMenu: PostureMenuItem[] = [
    {
        id: 'mark-known-directory',
        action: PostureMenuItemAction.MARK_KNOWN,
        label: 'Add this system to known Identity Systems'
    },
    {
        id: 'close-all-incs',
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        label: 'Close all existing incidents for this Identity Systems'
    }
];

export const DirectoriesColumnActionMenu: PostureMenuItem[] = [
    {
        id: 'mark-known-access',
        action: PostureMenuItemAction.MARK_KNOWN,
        label: 'Classify this asset as…'
    },
    {
        id: 'add-exception-for-accesses',
        action: PostureMenuItemAction.ADD_EXCEPTION,
        label: `Ignore existing issues for this identity → asset combination`
    },
    {
        id: 'close-all-incs',
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        label: 'Close existing incidents for this Identity Systems'
    },
];

export const AccessesActionMenu: PostureMenuItem[] = [
    {
        id: 'mark-known-access',
        action: PostureMenuItemAction.MARK_KNOWN,
        label: 'Classify this asset as…'
    },
    {
        id: 'add-exception-for-accesses',
        action: PostureMenuItemAction.ADD_EXCEPTION,
        label: `Ignore existing issues for this identity → asset combination (Add Exception)`
    },
    {
        id: 'close-all-incs-for-accesses',
        action: PostureMenuItemAction.CLOSE_INCIDENT,
        label: 'Close all existing incidents for this identity → asset combination'
    }
]

export const AssetColumnMarkKnownOptions: MarkKnownOption[] = [{
    optionValue: PostureMenuItemAction.KNOWN_APP,
    optionLabel: 'Known App; it’s not Unknown SaaS and not Exposed'
},
{ optionValue: PostureMenuItemAction.KNOWN_DIR, optionLabel: 'Known Directory' },
{ optionValue: PostureMenuItemAction.KNOWN_MFA, optionLabel: 'Known MFA Server' },
{ optionValue: PostureMenuItemAction.KNOWN_INTER, optionLabel: 'Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)' }
];

export const ExposedAssetColumnMarkKnownOptions: MarkKnownOption[] = [
    { optionValue: PostureMenuItemAction.KNOWN_APP, optionLabel: 'Asset is a Known App' },
    { optionValue: PostureMenuItemAction.KNOWN_DIR, optionLabel: 'Asset is a Known Directory' },
    { optionValue: PostureMenuItemAction.KNOWN_MFA, optionLabel: 'Asset is a Known MFA Server' },
    { optionValue: PostureMenuItemAction.KNOWN_INTER, optionLabel: 'Asset is a Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)' }
];

export const AccessColumnMarkKnownOptions: MarkKnownOption[] = [
    { optionValue: PostureMenuItemAction.KNOWN_APP, optionLabel: 'Classify this asset as a Known App' },
    { optionValue: PostureMenuItemAction.KNOWN_DIR, optionLabel: 'Classify this asset as a Known Directory' },
    { optionValue: PostureMenuItemAction.KNOWN_MFA, optionLabel: 'Classify this asset as a Known MFA Server' },
    { optionValue: PostureMenuItemAction.KNOWN_INTER, optionLabel: 'Classify this asset as a Known Access Control (VPN/gateway/proxy/NAT/firewall/etc.)' }
];

