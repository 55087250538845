import React, { useState, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import { Api } from '../../../components/Axios'
import { CATALOG_LIST} from '../Constants';
import { Autocomplete, TextField } from '@mui/material';
import { isValidIp } from '../../../utils/util-methods';
import './edit-directory.scss';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';

type Directory = {
    name?: string;
    fqdn?: string;
    ips?: [];
    type?: string;
}

interface Props {
    id?: string;
    AfterSensorOperation?: any;
    onPanelClose: any;
}

export const EditPanel = ({ id, AfterSensorOperation, onPanelClose }: Props) => {
    const { register, handleSubmit, errors, setValue, control } = useForm<Directory>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [directory, setDirectory] = useState<Directory>()
    const [ips, setIps] = useState<any>([])
    const { addToast } = useToasts()
    const [mfaEnabledCheck, setMfaEnabledCheck] = useState<boolean>();
    const [error, setError] = useState<any>();
    const [prevName, setPrevName] = useState<any>();
    const [selectedCatalog, setSelectedCatalog] = useState<any>(null);
    const rulesRef = useRef<HTMLDivElement>(null);
    const onUpdateGroup = (data: any) => {
        if (!ips || ips.length == 0) {
            setError('Please enter IP Addresses/Host Name/FQDNs');
            return;
        } else if (prevName != data.name) {
            Api.get(`/directories?q=${data.name}`).then((resp: any) => {
                const name = resp.data.result.find((item: any) => item.name == data.name);
                if (name) {
                    addToast('Identity System with same name exists.', { appearance: 'error', autoDismiss: true, autoHideDuration: 3000 });
                } else {
                    updateGroup(data);
                }
            });
        } else {
            updateGroup(data);
        }
    }

    const updateGroup = (data: any) => {
        setError(null)
        setLoading(true)
        data['ips'] = ips
        data['catalog_entry_name'] = selectedCatalog ? selectedCatalog : "NA";
        console.log(data);
        const headers = { 'Operation': 'PUT' }
        Api.post('/directories/' + id, data, { headers: headers })
            .then((res: any) => {
                setLoading(false)
                AfterSensorOperation('edit');
                if (res.status == 207) {
                    addToast(res.data, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                } else
                    addToast("Identity System updated successfully, all related Shadow Directory incidents resolved.", {
                        appearance: 'success',
                        autoDismiss: true,
                    })
            })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.data) {
                    addToast(error.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status !== 200) {
                    addToast("One of entered IP/FQDN is already used in other configured Identity System", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    useEffect(() => {
        Api.get('/directories/' + id)
            .then((res: { data: any }) => {
                setValue("name", res.data.name)
                setValue("fqdn", res.data.fqdn)
                setValue("type", res.data.type);
                setValue('mfa_enabled', res.data.mfa_enabled);
                if (res.data.ips) {
                    setIps(res.data.ips)
                }
                if (res.data.catalog_entry_name && res.data.catalog_entry_name !== "NA") { 
                    setValue("catalog", CATALOG_LIST.filter(item => item.key === res.data.catalog_entry_name).map(item => ({key: item.key, value: item.label})))
                    setSelectedCatalog(res.data.catalog_entry_name);
                }
                setMfaEnabledCheck(res.data.mfa_enabled);
                setPrevName(res.data.name);
            })
            .catch((error: any) => {
            })
    }, [])

    const AddTags = (tag: any) => {
        deselectCatalogBasedOnIps(tag);
        if (!isValidFQDN(tag)) {
            setError("Invalid IP address");
            return;
        }
        setIps(removeDuplicateIps(tag));
        if (tag.length > 0) {
            setError(null);
        } else {
            setError('Please enter IP Addresses/Host Name/FQDNs');
        }
    }

    useEffect(() => {
        if (rulesRef?.current) {
            rulesRef.current.scrollTop = rulesRef.current?.scrollHeight;
        }
    }, [ips])

    const handleMfaEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.target.checked) {
            setMfaEnabledCheck(true);
            // setMfaShowInput(true);
        }
        else {
            setMfaEnabledCheck(false);
            // setMfaShowInput(false);
        }
    }

    const handleSelectChange = (newValue) => {
        if (newValue && newValue.length > 0) {
            const catalogObj = CATALOG_LIST.find(item => item.key === newValue[0].key);
            if (catalogObj && catalogObj.fqdns) {
                let newTags = [...ips];
                if (selectedCatalog) {
                    newTags = removeIpsBasedOnCatalogChange(newTags);
                }
                newTags.push(...catalogObj.fqdns);
                setIps(removeDuplicateIps(newTags));
            }
            setSelectedCatalog(newValue[0].key);
        } else {
            if (selectedCatalog) {
                let newTags = [...ips];
                newTags = removeIpsBasedOnCatalogChange(newTags);
                setIps(removeDuplicateIps(newTags));
            }
            setSelectedCatalog('');
        }
    };

    const removeDuplicateIps = (arr) => { 
        let res = [];
        if (arr.length === 0) { 
            return res;
        }
        arr.forEach(item => { 
            if (!res.includes(item)) res.push(item);
        })
        return res;
    }

    const removeIpsBasedOnCatalogChange = (newTags) => {
        const tempObj = CATALOG_LIST.find(item => item.key === selectedCatalog)
        return newTags.filter(item => !tempObj.fqdns.includes(item));
    }

    const deselectCatalogBasedOnIps = (tag) => {
        if (selectedCatalog && fromCatalog(selectedCatalog)) {
            const catalogObj = CATALOG_LIST.find(item => item.key === selectedCatalog);
            const newTag = tag.filter(item => catalogObj.fqdns.includes(item));
            const matchFound = catalogObj.fqdns.every(item => newTag.includes(item));
            !matchFound && setSelectedCatalog('');
        }
    }

    const fromCatalog = (item) => CATALOG_LIST.some(item => item.key === selectedCatalog);

    const getOptionLabelWithSpacing = (option: any) => {
        return `  ${option.label}`;
    };

    const isValidFQDN = (tag) => {
        let res = true;
        tag.forEach(item => {
            if (!res) { 
                return;
            }
            if (!Number.isNaN(parseInt(item, 10)) && !isValidIp(item)) {
                res = false;
            }
        })
        return res;
    }
    const getItemLabel = (
        option: { key: string; value: string } | { key: string; value: string }[]
      ) => {
        if (Array.isArray(option)) {
          return option[0]?.value ? option[0]?.value : "";
        } else {
          return option?.value ? option?.value : "";
        }
    };
    
    return (
        <form onSubmit={handleSubmit(onUpdateGroup)}>
            <div className="edit-directory-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label className='font-bold'>Name</label>
                        <input
                            type="text"
                            placeholder="Enter Identity System name"
                            name="name"
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/
                            })}
                            className={(errors.name ? "error" : "")}
                        />
                    </div>
                    <div className='form-control'>
                        <div className='label-div'>
                            <label className='margin-10 font-bold'>
                                IP Addresses/Host Name/FQDNs*
                            </label>
                        </div>
                        <div className='group-container'>
                                <label
                                    htmlFor='selection_catalog_identity'
                                    className='float_none'
                                >
                                {'Select from list of known Catalogs (optional)'}
                            </label>
                            <div className="select-catalog-dropdown-container" style={{marginTop: "1%", width: "40%"}}>
                                <Controller
                                    name="catalog"
                                    control={control}
                                    defaultValue={[]}
                                    ref={register({
                                    required: true,
                                    })}
                                    render={({ onChange, value = "" }) => (
                                    <CheckboxAutocomplete
                                        label="Select options"
                                        options={CATALOG_LIST.map(item => ({key: item.key, value: item.label}))}
                                        value={value}
                                        onChange={(event, newValue) => {
                                            onChange(newValue)
                                            handleSelectChange(newValue)
                                        }}
                                        getItemLabel={getItemLabel}
                                        multiple={false}
                                        name="catalog"
                                        formType="edit"
                                    />
                                    )}
                                />
                            </div>
                                {/* <div>
                            <Autocomplete
                                    size='small'
                                    className='identity-systems-catalog-container font12'
                                    value={
                                        CATALOG_LIST.find(
                                            (item) =>
                                                item.key === selectedCatalog
                                        ) || null
                                    }
                                    options={CATALOG_LIST}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            placeholder=' --Select an option--'
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        option.key === (value ? value.key : '')
                                    }
                                    onChange={(_, newValue) =>
                                        handleSelectChange(newValue)
                                    }
                                    noOptionsText='No options'
                                    getOptionLabel={getOptionLabelWithSpacing}
                                />
                            </div> */}
                                <div>
                                <TagsInput
                                    renderLayout={(tagElements, inputElement) => {
                                        return (
                                            <span>
                                                <div
                                                     ref={rulesRef}
                                                    className="scrollbar-container directory-tag-container">
                                                    {tagElements}
                                                </div>
                                                <div>
                                                {inputElement}
                                                </div>
                                            </span>
                                        )
                                    }}

                                        value={ips}
                                        onChange={AddTags}
                                        inputProps={{
                                            placeholder: 'Add IP address, etc.'
                                        }}
                                        validationRegex={
                                            /^(([\*]|[a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-][^'"]*[a-zA-Z0-9]*[^'"])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])*([/0-9]*)$/
                                        }
                                    />
                                    <div className='error'>
                                        Note: Hit 'Enter' or 'Tab' key after
                                        each IP address, etc.
                                    </div>
                                </div>
                        </div>
                        <div className='form-control flex margintop20'>
                        <input
                            name='mfa_enabled'
                            type='checkbox'
                            className="mt-1"
                            checked={mfaEnabledCheck ? mfaEnabledCheck : false}
                            onChange={handleMfaEnabledChange}
                            ref={register}
                        />
                        <label className='auto_width paddingright20 mt-1'>
                            Performs MFA?
                        </label>
                    </div>
                    </div>
                </div>
            </div>

        
            
            { (Object.keys(errors || {}).length > 0 || error?.length >0) && (
                <div className="edit-directory-errors-list-container">
                    {errors.name && errors.name.type === "required" && <div className="error dot marginleft40per">Please enter Identity System name.</div>}
                    {errors.name && errors.name.type !== "required" && <div className={`error marginleft40per ${errors.name.message && "dot"}`}>{errors.name.message}</div>}
                    {errors.name && errors.name.type === "pattern" && <div className="error dot marginleft40per">Invalid Identity System name.</div>}
                    {error && <div className={`error marginleft40per ${error && "dot"}`}>{error}</div>}
                </div>
            )}

            <div className="add-edit-action-buttons directory-action-buttons dialog-button">
            <button
                    type={'button'}
                    onClick={onPanelClose}
                    className='button_styled ghost'
                >
                    Cancel
                </button>
            <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'add-edit-submit')}>Save</button>
            </div>

        </form>
    )
}