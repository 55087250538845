import React, { ReactNode, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, DialogProps, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./modal-popup.scss";
import Button from "@material-ui/core/Button";

interface Props extends DialogProps {
    classNames?: string;
    title: string;
    onCloseHandler: (state: boolean) => void;
    children: ReactNode;
    isOpen: boolean;
    zIndexCustom?: any;
    subTitle?:any;
}

const ModalPopup = ({ classNames, title, onCloseHandler, children, isOpen, zIndexCustom  , subTitle}: Props) => {
 


    return (
        <Dialog
            open={isOpen}
            onClose={() => onCloseHandler(false)}
            style={{ zIndex: zIndexCustom ? zIndexCustom : 1001 }}
            aria-labelledby="modal-title"
            slotProps={{
                backdrop: {
                  onClick: (event) => event.stopPropagation(),
                },
            }}
            
        >
            <DialogTitle id="modal-title">
                <span title={title} className="text-ellipsis" style={{display:'inline-block'}}>{title}</span>  <Box
                sx={{ float: "right", marginTop: "20px", right: 0 }}
                onClick={() => onCloseHandler(false)}
                className={subTitle ? "dialog-container-close subtitle-close" :"dialog-container-close"}
            >
            </Box>
            {subTitle && <div className="header-subtitle text-ellipsis" style={{fontWeight:'bold'}}>{subTitle}</div>}
            </DialogTitle>
            {/* <IconButton
                aria-label="close"
                onClick={() => onCloseHandler(false)}
                className="dialog-container-close"
                style={{ position: 'absolute', right: 8, top: 8 }}
            >
                
            </IconButton> */}

           
            <DialogContent className={`popup-container-body dialog-body ${classNames} `}>
                {children}
            </DialogContent>
            {/* <DialogActions>
                {actionButtons.length > 0 ? actionButtons : null}
            </DialogActions> */}
        </Dialog>
      
    );
};

export default ModalPopup;