import React, { useState } from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { TicketPerAssignee } from '../../../types/response-types';
import { useEffect } from 'react';

type Props={
	assigneeTicket: TicketPerAssignee | undefined
}

export const ReportByAssignee = ({assigneeTicket}:Props) => {

	const options: Highcharts.Options = {
		chart: {
			height: window.matchMedia("(min-width: 2560px)").matches ? 460 : 230,
			marginTop: window.matchMedia("(min-width: 2560px)").matches ? 70 : 35,
			marginBottom: window.matchMedia("(min-width: 2560px)").matches ? 60 : 30,
			type: "bar",
            style:{
                fontFamily: 'Metropolis-Regular'
            },
		},
		title: undefined,
		tooltip: {
			useHTML: true,
			backgroundColor: undefined,
			borderWidth: 0,
			shadow: false,
			formatter: function(){
				if(this.y){
					return (
					  '<div style="background-color:' +
					  this.color +
					  `;color: ${this.point.name==='open' ?'#fff':'#4b4547'};`
					  +' z-index: 12000" class="hc-tooltip"> ' +
					  "<b>"
					//   + `${this.y>=0 ? 'Open' : 'Closed'}`
					//   +"<br/>"
					//   +"# :  "
					  + Math.abs(this.y) +
					//   "<br/>"+
					//   "% : "+
					//   + Math.abs(this.y) +
					  "</b>" +
					//   "%" +
					  "</div>"
					);
				  }
				  return '';
			}
		},
		plotOptions: {
			bar:{
                states:{
                    inactive:{
                        enabled:false
                    }
                },
                showInLegend: false,
                cursor: 'pointer'
            }
		},
		credits: {
			enabled: false
		}
	};

	const [ticketData, setTicketData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [openTicket, setOpenTicket] = useState<Highcharts.PointOptionsObject[]>([]);
	const [closedTicket, setClosedTicket] = useState<Highcharts.PointOptionsObject[]>([]);
	const [assigneeName, setAssigneeName] = useState<string[]>([]);
	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);


	useEffect(()=>{
		if(assigneeTicket){
			calcData();
		}
	},[assigneeTicket])

	const calcData=()=>{
		let index=0;
		let data = [];
		let openData:Highcharts.PointOptionsObject[] = [];
		let closedData:Highcharts.PointOptionsObject[] = [];
		let name = [];
		for(let assignee in assigneeTicket){
			let totalTickets = assigneeTicket[assignee].opened + assigneeTicket[assignee].closed;
			let openPer = totalTickets>0 ? Math.round(((assigneeTicket[assignee].opened)/totalTickets)*100) : 0;
			let closedPer = totalTickets>0 ? (100 - openPer) : 0;
			// data.push(
			// 	{
			// 		x: index,
			// 		// y: ticketStatus[ob].opened,
			// 		y: openPer,
			// 		color: '#fd045e'
			// 	},
			// 	{
			// 		x: index,
			// 		// y: ticketStatus[ob].closed>0 ?  ticketStatus[ob].closed*-1 : ticketStatus[ob].closed,
			// 		y: assigneeTicket[assignee].closed>0 ?  closedPer*-1 : closedPer,
			// 		color: '#d5d9e2'
			// 	}
			// );
			openData.push({
				x: index,
				y: assigneeTicket[assignee].opened===0 ? null : assigneeTicket[assignee].opened,
				color: '#fd045e',
				name: 'open'
			})
			closedData.push({
				x: index,
				y: assigneeTicket[assignee].closed===0 ? null : assigneeTicket[assignee].closed,
				color: '#d5d9e2',
				name: 'closed'
			})
			name.push(assignee);
			index++;
		}
		// setTicketData(data);
		setOpenTicket(openData);
		setClosedTicket(closedData);
		setAssigneeName(name);
	}

	useEffect(()=>{
		setChartOptions(
			{
				...chartOptions,
				yAxis:{
					gridLineWidth: 0,
					lineWidth:1,
					lineColor:'#fff',
					plotLines: [{
						color: '#ccc',
						width: 1,
						value: 0,
						acrossPanes: undefined
					}],
					labels: {
						enabled: false
					},
					title: {
						text: undefined
					},
				},
				xAxis:{
					categories: assigneeName,
					gridLineWidth: 0,
					lineColor: '#f2f2f2',
					lineWidth: 1,
					labels: {
						style:{
							fontSize: window.matchMedia("(min-width: 2560px)").matches ? '22px' : '11px'
						}
					},
					className : 'hc-x-axis',
				},
				series: [
					{
						type: "bar",
						data: openTicket,
						name: 'Open',
						minPointLength:3
					},
					{
						type: "bar",
						data: closedTicket,
						name: 'Closed',
						minPointLength:3
					}
				]
			}
		)
	}, [openTicket, closedTicket, assigneeName])

    return (
	<>	
		{ assigneeTicket === undefined ?
			<div className="empty_assignee_chart">&nbsp;</div>
		:
        <div className="shadow_box tickets_assignee_chart">
			<div className="ticket_chart_title">Tickets by Assignee</div>
			<HighchartsReact highcharts={Highcharts} options={chartOptions} />
		    <div className="asignee_legend">
		    	<ul>
		    		<li>Open Tickets</li>
		    		<li>Closed in last 24 hours</li>
		    	</ul>
		    </div>
        </div>
		}	
	</>	
    )
} 