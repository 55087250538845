import Popup from "reactjs-popup";
import ModalPopup from "../../../components/core/AMModalPopup/ModalPopup";

export const ActivatePopUp = (props: any) => {
    const closeConfirm = (evt: any) => {
        if (props.closeConfirm)
            props.closeConfirm(evt);
    }

    const okConfirm = (evt: any) => {
        if (props.okConfirm)
            props.okConfirm(evt);
    }

    return <>
        <Popup
            overlayStyle={{ zIndex: 1000, background: 'rgba(227, 242, 253, .6)' }}
            open={true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={closeConfirm}
            modal
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header">{props.isActive !== 'Active' ? 'Activate' : 'Deactivate'} Exception</div>
                <div className="content">
                    <div className="font14 margintop20">
                        {
                            props.isActive !== 'Active' ?
                                <span>This will <strong>resume</strong> the execution of selected exception.
                                    Any open incidents matching the exception rules will be closed.</span>
                                : <span>This will <strong>pause</strong> the execution of selected exception.</span>
                        }<br></br><br></br>
                        Do you want to continue?
                    </div>
                </div>
                <div className="popup_footer">
                    <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button>
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={okConfirm}>Yes</button>
                </div>
            </div>
        </Popup>
    </>
}

interface IDeletePopUpProps {
    data: any,
    handleYes: any,
    handleNo: any
}

export const DeletePopUp = ({ data, handleYes, handleNo }: IDeletePopUpProps) => {
    return <ModalPopup
        isOpen={true} title="Delete Exception" onCloseHandler={handleNo}
        classNames="width750"
    >
        <div className="margintop10">Are you sure you want to delete exception: {data?.name} ?</div>
        <div className="margintop10 popup-container-button-container dialog-button">
            <button type="button" onClick={handleNo} className={"button_gray marginbottom10imp margintop20"}
                style={{ display: 'inline-block', margin: 0, width: '20%' }}
            >
                No
            </button>
            <button
                style={{ display: 'inline-block', margin: 0, width: '20%' }}
                onClick={handleYes}
                type="button"
                className={'float_right button_styled marginbottom10imp margintop20'}>Yes</button>
        </div>
    </ModalPopup >
}