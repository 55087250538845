import React, {useState} from 'react'
import { useForm } from 'react-hook-form'
import {Api} from '../../../components/Axios'
import { useToasts } from '../../../components/core';
import Popup from 'reactjs-popup'
import { useHistory } from 'react-router-dom'

type AddComment = {
    description: string;
}

interface Props {
    id?: string;
    updateTicketDetails?: any;
}

export const Comment = ({id, updateTicketDetails}: Props) => {
    let history = useHistory();
    const [ticketId, setTicketId] = useState<number>(history.location.state as number);
	const { register, handleSubmit, errors, setValue } = useForm<AddComment>()
	const [loading, setLoading] = useState<Boolean>(false)
	const { addToast } = useToasts()

	const onSubmit = (data: any) => {
        setLoading(true)
        Api.post('/tickets/'+ticketId+"/comment", data)
        .then((res: { data: any }) => {
            setValue("description", "")
            setLoading(false)
            addToast("Comment added successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
            updateTicketDetails();
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
        history.goBack();
    }

   	return (
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal
                // //lockScroll
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={"modal ticket_modal"}>
                        <div className={"close"} onClick={()=>{history.goBack()}}></div>
                        <div className={"header"} style={{width: '90%'}}>Comment</div>
                        <div className={"content"}>
                            <textarea
                                name="description"
                                className="float_left"
                                placeholder="Type your comments here"
                                ref={register({
                                    required: true
                                })}
                            />
                            {errors.description && errors.description.type === 'required' && <div className="error">Please enter comment.</div>}   
                        </div>
                        <div className="clrBoth"> </div>
                        <div className="popup_footer"> 
                            <div className="launch_button">
                                <button className="button_main">Submit</button>         
                            </div>
                        </div>  
                    </div>
                </form>
            </Popup>
            
        </>
  	)
} 