import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core'

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import { Api } from '../../../components/Axios'
import { ProxyPopUp } from '../ref/ProxyPopUp'
import { ProxyConstants } from '../ProxyConstants'
import { AMPasswordInput } from '../../../components/core/AMPasswordInput/AMPasswordInput'
import './edit-proxy.scss';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete'

type Proxies = {
    user_id?: string;
    company_id?: string;
    _id?: string;
    name?: string;
    ip?: string;
    port?: string;
    ssl?: string;
    authtype?: string
    basic_userid?: string;
    basic_pwd?: string;
    ntlm_domain?: string;
    ntlm_userid?: string;
    ntlm_pwd?: string;
    is_ssl?: boolean;
    is_basic?: boolean;
    is_ntlm?: boolean;
    selected_options?: [];
}


interface Props {
    id?: string;
    AfterProxiesOperation?: any;
}

export const EditPanel = ({ id, AfterProxiesOperation }: Props) => {
    const { register, handleSubmit, errors, setValue, control } = useForm<Proxies>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [Proxies, setProxies] = useState<Proxies>()
    const { addToast } = useToasts()
    const [sslCheck, setSslCheck] = useState<boolean>();
    const [showssldetails, setShowSslDetails] = useState<Boolean>(false)
    const [authType, setAuthType] = useState<string | undefined>();
    const [showProxyPopUp, setShowProxyPopUp] = useState<boolean>(false);
    const [portLabel, setPortLabel] = useState<string>('Port');
    const [name, setName] = useState<string>();
    const [ipLabel, setIpLabel] = useState<string>('IP/Host Name/FQDN');

    const onUpdateProxies = (data: any) => {
        if (data.name != name) {
            Api.get(`/proxies?q=${data.name}`).then((res: any) => {
                if (res?.data?.result) {
                    for (let i = 0; i < res.data.result.length; i++) {
                        const item = res.data.result[i];
                        if (item.name.toLowerCase() == data.name.toLowerCase()) {
                            addToast("Proxy with same name already exist, please try different name.", {
                                appearance: 'error',
                                autoDismiss: true,
                                autoHideDuration: 4000
                            });
                            return;
                        }
                    }
                }
                updateProxy(data);
            })
        } else {
            updateProxy(data);
        }
    }

    const updateProxy = (data: any) => {
        if (!validateProxySubmit(data)) {
            return;
        }
        let request = {
            name: data.name,
            ip: data.ip,
            port: data.port,
            ssl: data.ssl,
            basic_userid: data.basic_userid,
            basic_pwd: data.basic_pwd,
            ntlm_domain: data.ntlm_domain,
            ntlm_userid: data.ntlm_userid,
            ntlm_pwd: data.ntlm_pwd,
            is_ssl: sslCheck,
            is_basic: authType === "basic" ? true : false,
            is_ntlm: authType === "ntlm" ? true : false
        }

        setLoading(true)
        const headers = { 'Operation': 'PUT' }
        Api.post('/proxies/' + id, request, { headers: headers })
            .then((res: { data: any }) => {
                setLoading(false)
                AfterProxiesOperation('edit', res.data)
                addToast("Proxy updated successfully.", {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    useEffect(() => {
        Api.get('/proxies/' + id)
            .then((res: { data: any }) => {
                console.log(res.data)
                setValue("name", res.data.name)
                setValue("ip", res.data.ip)
                setValue("port", res.data.port)
                setSslCheck(res.data.is_ssl)
                setShowSslDetails(res.data.is_ssl)
                setValue("ssl", res.data.ssl)
                setValue("type", res.data.is_basic ? [{ key: "basic", value: "Basic" }] : res.data.is_ntlm ? [{ key: "ntlm", value: "NTLM/Negotiate" }]  : [])
                setAuthType(res.data.is_basic ? "basic" : res.data.is_ntlm ? "ntlm" : "")
                setValue("basic_userid", res.data.basic_userid)
                setValue("basic_pwd", res.data.basic_pwd)
                setValue("ntlm_domain", res.data.ntlm_domain)
                setValue("ntlm_userid", res.data.ntlm_userid)
                setValue("ntlm_pwd", res.data.ntlm_pwd)
                setName(res.data.name);
            })
            .catch((error: any) => {
            })
    }, [])



    const handleSslChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.target.checked) {
            setSslCheck(true);
            setShowSslDetails(true)
        }
        else {
            setSslCheck(false);
            setShowSslDetails(false)
        }
    }


    const validateProxySubmit = (data: any) => {
        if (!data.ip && !sslCheck) {
            setShowProxyPopUp(true);
            return false;
        }
        return true;
    }

    const closePropxyPopUp = () => {
        setShowProxyPopUp(false);
    }

    const handleIpChange = (evt: any) => {
        if (evt.target.value && evt.target.value.length > 0) {
            control.register('port', { required: true });
            setPortLabel('Port *');
        } else {
            control.unregister('port');
            setPortLabel('Port');
        }
    }

    const handlePortChange = (evt: any) => {
        if (evt.target.value && evt.target.value.length > 0) {
            control.register('ip', { required: true });
            setIpLabel('IP/Host Name/FQDN*');
        } else {
            control.unregister('ip');
            setIpLabel('IP/Host Name/FQDN');
        }
    }
    const typeList = [{ key: "basic", value: "Basic" }, { key: "ntlm", value: "NTLM/Negotiate" }]
    
    const getItemLabel = (
        option: { key: string; value: string } | { key: string; value: string }[]
      ) => {
        if (Array.isArray(option)) {
          return option[0]?.value ? option[0]?.value : "";
        } else {
          return option?.value ? option?.value : "";
        }
    };
    
    return (
        <>
            <form onSubmit={handleSubmit(onUpdateProxies)} className='proxy-form'>
                <div className='edit-proxy-container'>
                    <div className="edit-proxy-form-container">
                        <div className="form-control-item span-2">
                            <label>Name</label>
                            <input
                                type="text"
                                placeholder=""
                                name="name"
                                ref={register({
                                    required: true,
                                })}
                                className={(errors.name ? "error" : "")}
                            />
                           
                        </div>
                        <div className="form-control-item">
                            <label>{ipLabel}</label>
                            <input
                                type="text"
                                placeholder="Enter IP/Host Name/FQDN"
                                name="ip"
                                ref={register({
                                    // required: true,
                                })}
                                className={(errors.ip ? "error" : "")}
                                onChange={handleIpChange}
                            />
                            
                        </div>
                        <div className="form-control-item">
                            <label>{portLabel}</label>
                            <input
                                type="number"
                                placeholder="Enter port"
                                name="port"
                                ref={register({
                                    // required: true,
                                    min: { value: 0, message: 'Please enter value greater than or equal to 0' }
                                })}
                                className={(errors.port ? "error" : "")}
                                onChange={handlePortChange}
                            />
                            
                        </div>
                        <div className="form-control-item span-2 flex-row-reverse">
                            <label className="auto_width paddingright20">{ProxyConstants.sslInspect}</label>
                            <input
                                style={{ marginBottom: 0 }}
                                name="is_provider"
                                type="checkbox"
                                className={("marginTop18")}
                                checked={sslCheck ? sslCheck : false}
                                onChange={handleSslChange}
                                ref={register({
                                    required: false,
                                })}                    
                            />
                        </div>
                        {showssldetails === true && <div className="form-control-item span-2">
                            <label>{ProxyConstants.sslCertificate}*</label>
                            <textarea
                                style={{ "resize": "none", width: "100%", marginBottom: 0 }}
                                rows={10}
                                placeholder={ProxyConstants.enterSSLCertificate}
                                name="ssl"
                                ref={register({
                                    required: sslCheck,
                                    minLength: { value: 50, message: ProxyConstants.enterValidCertificate }
                                })}
                                className={(errors.ssl ? "error" : "")}
                            />
                            
                        </div>
                        }
                        <div className="form-control-item">
                            <label className='marginbottom10'>Use Authentication</label>
                            <div className="type-dropdown-container" style={{width: "94%"}}>
                                <Controller
                                    name="type"
                                    control={control}
                                    defaultValue={[]}
                                    ref={register({
                                        required: false,
                                    })}
                                    render={({ onChange, value = "" }) => (
                                    <CheckboxAutocomplete
                                        label="Select options"
                                        options={typeList}
                                        value={value}
                                            onChange={(event, newValue) => {
                                                onChange(newValue)
                                                setAuthType(newValue[0].key)
                                            }}
                                        getItemLabel={getItemLabel}
                                        multiple={false}
                                        name="type"
                                        formType="edit"
                                    />
                                    )}
                                />
                            </div>
                            {/* <select id="type"
                                style={{ marginBottom: window.matchMedia("(min-width: 2560px)").matches ? '10px' : '5px' }}
                                name="type"
                                className={"form_drodown" + (errors.authtype ? "error" : " ")}
                                ref={register({
                                    required: false,
                                })}
                                value={authType}
                                onChange={(e) => { setAuthType(e.currentTarget.value); }}
                            >
                                <option value="" selected>None</option>
                                <option value="basic">Basic</option>
                                <option value="ntlm">NTLM/Negotiate</option>
                            </select> */}
                           
                        </div>
                        <div className="form-control-item span-2">
                            {authType === 'basic' && <div className="edit-proxy-sub-form-container">
                                    <div className="sub-form-control-item">
                                        <label style={{ marginTop: 0 }}>{ProxyConstants.username}</label>
                                        <input
                                            type="text"
                                            placeholder={ProxyConstants.enterBasicUser}
                                            name="basic_userid"
                                            ref={register({
                                                required: true,
                                            })}
                                            className={(errors.basic_userid ? "error" : "")}
                                        />

                                    </div>
                                    <div className="sub-form-control-item">
                                        <label style={{ marginTop: 0 }}>{ProxyConstants.password}</label>
                                        <AMPasswordInput
                                            type="password"
                                            placeholder={ProxyConstants.enterBasicPass}
                                            name="basic_pwd"
                                            ref={register({
                                                required: true,
                                            })}
                                            className={(errors.basic_pwd ? "error" : "")}
                                        />
                                    </div>
                                </div>
                                }
                                 {authType === 'ntlm' && <div className="edit-proxy-sub-form-container">
                                <div className="sub-form-control-item">
                                    <label style={{ marginTop: 0 }}>{ProxyConstants.domain}</label>
                                    <input
                                        type="text"
                                        placeholder={ProxyConstants.enterDomain}
                                        name="ntlm_domain"
                                        ref={register({
                                            required: true,
                                        })}
                                        className={(errors.ntlm_domain ? "error" : "")}
                                    />
                                </div>
                                <div className="sub-form-control-item">
                                    <label style={{ marginTop: 0 }}>{ProxyConstants.username}</label>
                                    <input
                                        type="text"
                                        placeholder={ProxyConstants.enterNtlmUser}
                                        name="ntlm_userid"
                                        ref={register({
                                            required: true,
                                        })}
                                        className={(errors.ntlm_userid ? "error" : "")}
                                    />

                                </div>
                                <div className='sub-form-control-item'>
                                    <label style={{ marginTop: 0 }}>{ProxyConstants.password}</label>
                                    <AMPasswordInput
                                        type="password"
                                        placeholder={ProxyConstants.enterNtlmPass}
                                        name="ntlm_pwd"
                                        ref={register({
                                            required: true,
                                        })}
                                        className={(errors.ntlm_pwd ? "error" : "")}
                                    />

                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {Object.keys(errors || {}).length > 0 && (
                    <div className="edit-proxy-errors-list-container">
                        {errors.name && errors.name.type === 'required' && <div className="error dot marginleft40per">Please enter Proxy name</div>}
                        {errors.name && errors.name.type !== 'required' && <div className={`error marginleft40per ${errors.name.message && "dot"}`}>{errors.name.message}</div>}

                        {errors.ip && errors.ip.type === 'required' && <div className="error dot marginleft40per">Please enter IP Addresses/Host Names/FQDNs</div>}
                        {errors.ip && errors.ip.type !== 'required' && <div className={`error marginleft40per ${errors.ip.message && "dot"}`}>{errors.ip.message}</div>}

                        {errors.port && errors.port.type === 'required' && <div className="error dot marginleft40per">Please enter port</div>}
                        {errors.port && <div className={`error marginleft40per ${errors.port.message && "dot"}`}>{errors.port.message}</div>}

                        {showssldetails === true && errors.ssl && errors.ssl.type === 'required' && <div className="error dot marginleft40per">Please enter SSL Certificate</div>}
                        {showssldetails === true && errors.ssl && <div className={`error marginleft40per ${errors.ssl.message && "dot"}`}>{errors.ssl.message}</div>}

                        {errors.authtype && errors.authtype.type === 'required' && <div className="error dot marginleft40per">Please select type.</div>}
                        {errors.authtype && errors.authtype.type !== 'required' && <div className={`error marginleft40per ${errors.authtype.message && "dot"}`}>{errors.authtype.message}</div>}

                        {authType === 'basic' && errors.basic_userid && errors.basic_userid.type !== 'required' && <div className={`error marginleft40per ${errors.basic_userid.message && "dot"}`}>{errors.basic_userid.message}</div>}
                        {authType === 'basic' && errors.basic_userid && errors.basic_userid.type == 'required' && <div className={`error dot marginleft40per`}>{ProxyConstants.errorBasicUser}</div>}

                        {authType === 'basic' && errors.basic_pwd && errors.basic_pwd.type !== 'required' && <div className={`error marginleft40per ${errors.basic_pwd.message && "dot"}`}>{errors.basic_pwd.message}</div>}
                        {authType === 'basic' && errors.basic_pwd && errors.basic_pwd.type == 'required' && <div className="error dot marginleft40per">{ProxyConstants.errorBasicPassword}</div>}

                        {authType === 'ntlm' && errors.ntlm_userid && errors.ntlm_userid.type !== 'required' && <div className={`error marginleft40per ${errors.ntlm_userid.message && "dot"}`}>{errors.ntlm_userid.message}</div>}
                        {authType === 'ntlm' && errors.ntlm_domain && errors.ntlm_domain.type == 'required' && <div className="error dot marginleft40per">{ProxyConstants.errorNTLMDomain}</div>}

                        {authType === 'ntlm' && errors.ntlm_userid && errors.ntlm_userid.type !== 'required' && <div className={`error marginleft40per ${errors.ntlm_userid.message && "dot"}`}>{errors.ntlm_userid.message}</div>}
                        {authType === 'ntlm' && errors.ntlm_userid && errors.ntlm_userid.type == 'required' && <div className="error dot marginleft40per">{ProxyConstants.errorNTLMUser}</div>}

                        {authType === 'ntlm' && errors.ntlm_pwd && errors.ntlm_pwd.type !== 'required' && <div className={`error marginleft40per ${errors.ntlm_pwd.message && "dot"}`}>{errors.ntlm_pwd.message}</div>}
                        {authType === 'ntlm' && errors.ntlm_pwd && errors.ntlm_pwd.type == 'required' && <div className="error dot marginleft40per">{ProxyConstants.errorNTLMPass}</div>}

                    </div>
                )}
                </div>
                <div className="add-edit-action-buttons dialog-button">
                    <button type={loading ? "button" : "submit"} className={"float_right " + (loading ? "loader" : "add-edit-submit")}>
                        Save
                    </button>
                </div>
            </form>
            <ProxyPopUp showProxyPopUp={showProxyPopUp} closePropxyPopUp={closePropxyPopUp} />
        </>
    )
}