import React from 'react';
import './comp-user-config.css';

type Props = {
    config: any;
};

type Feed = {
    name: string;
    isChecked: boolean;
};

const CompromisedUserConfigView = ({ config }: Props) => {
    const renderUsers = (feeds: Feed[]) => {
        let res = [];
        feeds?.forEach((feed: Feed, idx: number) => {
            if (feed.isChecked && feed.name !== 'Md5decrypt.net') {
                res.push(feed.name);
            }
        });
        return res.join(' or ');
    };

    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>A user will be considered compromised:</span>
                <ul className='ml-1'>
                    {config.feeds && config.feeds.length > 0 && (
                        <li>
                            if his/her identity information is found in:{' '}
                            <strong>{renderUsers(config?.feeds)}</strong>
                        </li>
                    )}
                    {config.user_breach_threshold_enabled && (
                        <li>
                            if data breach is less than{' '}
                            <strong>{config.user_breach_threshold} </strong>days
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
};

export default React.memo(CompromisedUserConfigView);
