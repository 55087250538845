import { ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Api } from "../../../../components/Axios";
import { AMCheckbox } from '../../../../components/core/AMCheckbox/AMCheckbox';
import './mfa-config.css';
import { LackOfMFAConfigState } from '../../../../types/playbooks-config';
import { BaseProps } from '../../constants/types';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MfaServerList {
  key: string,
  value: string
}

const MfaConfig = ({ getState, defaultState, isSavedOnce, parentState, formType }: BaseProps<LackOfMFAConfigState>) => {
  const [serversList, setServersList] = useState<Array<MfaServerList>>([]);
  const [state, setState] = useState<LackOfMFAConfigState>(defaultState);
  const isAllSelected =
    serversList && state?.mfa_servers && serversList?.length > 0 && state?.mfa_servers?.length === serversList?.length;
  const checkRef = useRef(null);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(30);

  useEffect(() => {
    getState(state);
  })

  useEffect(() => {
    const defaultDays = Math.trunc(parseInt(defaultState?.mfa_timeout?.toString()) / 24);
    const defaultHours = parseInt(defaultState?.mfa_timeout?.toString()) % 24;
    setState(defaultState);
    setDays(defaultDays);
    setHours(defaultHours);
  }, [defaultState])

  useEffect(() => {
    const defaultDays = Math.trunc(parseInt(parentState?.mfa_timeout?.toString()) / 24);
    const defaultHours = parseInt(parentState?.mfa_timeout?.toString()) % 24;
    setState(parentState);
    setDays(defaultDays);
    setHours(defaultHours);
  }, [isSavedOnce]);

  useEffect(() => {
    loadMFAServers();
  }, []);

  useEffect(() => {
    console.log(state)
  }, [state])

  const loadMFAServers = () => {
    Api.get('/mfa').then((res: any) => {
      setServersList(res.data.result.map((i) => ({ key: i._id, value: i.name })));
      if (state?.mfa_servers.length == 0 && formType != 'view') {
        const mfaServers = res.data.result.map((i: any) => i._id);
        setState({ ...state, mfa_servers: mfaServers });
      }
    });
  }

  const renderValue = (selected: any): any => {
    let selectedString = '';
    if (state?.mfa_servers && state?.mfa_servers.length > 0) {
      state?.mfa_servers?.forEach((i: any) => {
        const o = serversList.find((item: any) => item._id == i) as any;
        if (o) {
          selectedString += o.name + '; ';
        }
      })
    }
    return isAllSelected ? 'All Servers Selected' : selectedString.length > 0 ? selectedString : '';
  }

  const handleChange = (e: SelectChangeEvent<any>) => {
    const value = e.target.value;
    let selectedServersList = [];
    if (value[value.length - 1] === "All Servers") {
      selectedServersList = (state?.mfa_servers.length === serversList.length ? [] : serversList.map((i: any) => i._id));
      setState({ ...state, mfa_servers: selectedServersList });
      return;
    }
    setState({ ...state, mfa_servers: value });
  }

  const handleMFATimeoutHoursChange = (evt: any) => {
    let val = parseInt(evt.target.value) || 0;
    if (val > 23 || val < 0) {
      if ((!days || days == 0) && (!hours || hours == 0)) {
        setHours(1);
        val = 1;
      } else if (!hours) {
        setHours(0);
        val = 0;
      }
    } else {
      setHours(evt.target.value);
    }
    let value = (parseInt(days.toString()) * 24) + parseInt(val.toString());
    setState({ ...state, mfa_timeout: value });
  }

  const handleMFATimeoutDaysChange = (evt: any) => {
    let day;
    day = parseInt(evt.target.value);
    let val = (parseInt(day.toString()) * 24) + parseInt(hours.toString());
    setState({ ...state, mfa_timeout: val });
    setDays(day);
  }

  const onTimeoutDaysBlur = () => {
    if ((!days || days == 0) && (!hours || hours == 0)) {
      setDays(1);
    } else if (!days) {
      setDays(0);
    }
  }

  const onTimeoutHoursBlur = () => {
    if ((!days || days == 0) && (!hours || hours == 0)) {
      setHours(1);
    } else if (!hours) {
      setHours(0);
    }
  }

  const loadOptions = () => {
    return [...serversList];
  }

  const getIssueLabel = (o) => {
    console.log(o);

    if (typeof o == "string") {
      return o
    } else if (o?.key) {
      return o?.value;
    }
    console.log("Empty")
    return ""
  }

  const getServerName = (i) => {
    console.log(i)
    if (i) {
      const item = serversList.filter((it) => it.key == i)[0];
      if (item?.key) {
        return item.value
      }
    }
    return ""
  }

  return (
    <>
      {serversList?.length > 0 ?
        <form action="/" className='mfa-config-form-container'>
          <div style={{ width: '100%' }} className='notify-admin-label mfa-server-list display_flex_center margintop10'>
            <span style={{ width: '80%' }}>MFA servers to be checked for Lack of MFA*:</span>
            <CheckboxAutocomplete
              multiple={true}
              options={loadOptions()}
              getItemLabel={getIssueLabel}
              onChange={(e, config) => { setState({ ...state, mfa_servers: config.map((i) => i.key) }) }}
              value={state?.mfa_servers?.map((i) => ({ key: i, value: getServerName(i) }))}
            />
            <div className="clrBoth"></div>
          </div>
          <div className='notify-admin-type margintop10'>MFA session is valid for:
            {
              !formType || !['view', 'edit'].includes(formType) ?
                <><input type='number' onChange={handleMFATimeoutDaysChange} onBlur={onTimeoutDaysBlur} id='mfa-session-timeout-days' min={1} size={4} value={days} /> days
                  <input type='number' onChange={handleMFATimeoutHoursChange} onBlur={onTimeoutHoursBlur} id='mfa-session-timeout-hours' min={0} max={23} size={4} value={hours} /> hours</>
                : <><span id='mfa-session-timeout-days'><strong>{days}</strong> days</span>
                  <span id='mfa-session-timeout-hours'><strong>{hours}</strong> hours</span>
                </>
            }
          </div>
        </form> : <div className=''><div className='loading' style={{ margin: '0 auto' }}></div></div>}
    </>
  )
}

export default React.memo(MfaConfig);