import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

export const AWSVPCCollector = ({ register, setValue, collectorData, collectionMethodData }) => {
    const [showCollectionTypes, setShowcollectionTypes] = useState(true);
    const [managedVia, setManagedvia] = useState('vpc_managed');    
    useEffect(() => {
        if (collectorData) {
            setValue(
                'aws_query_url',
                collectorData.collector_config?.aws_query_url
            );
            setValue(
                'aws_access_key_id',
                collectorData.collector_config?.aws_access_key_id
            );
            setValue(
                'aws_access_key_secret',
                collectorData.collector_config?.aws_access_key_secret
            );
            setValue(
                'aws_vpc_flow_log_format',
                collectorData.collector_config?.aws_vpc_flow_log_format
            );
            if (!collectorData?.collector_config?.aws_access_key_secret && !collectorData?.collector_config?.aws_access_key_id) {
                setManagedvia('assumed_managed')
            }
        }
    }, [collectorData]);

    useEffect(() => {
        if (collectionMethodData?.is_direct === true && collectionMethodData?.label === 'Cloud Direct') {
            setShowcollectionTypes(false);
        } else {
            setShowcollectionTypes(true);
        }
    }, [collectionMethodData])
    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    AWS VPC Flow Logs Configurations
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        {showCollectionTypes && <div className='sensor-flex-container-item'>
                            <div className='assumed-role-box-item'>
                                <input
                                    type='radio'
                                    name='managed_bucket'
                                    checked={managedVia === 'vpc_managed'}
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    value={'vpc_managed'}
                                    onChange={() =>
                                        setManagedvia('vpc_managed')
                                    }
                                />{' '}
                                <label>
                                    Access Key and Secret
                                </label>
                            </div>
                            <div className='assumed-role-box-item'>
                                <input
                                    type='radio'
                                    name='managed_bucket'
                                    checked={managedVia === 'assumed_managed'}
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    value={'assumed_managed'}
                                    onChange={() =>
                                        setManagedvia('assumed_managed')
                                    }
                                />{' '}
                                <label>
                                    AssumeRole
                                </label></div>
                        </div>}
                    </div>

                    {
                        managedVia === 'assumed_managed' && <div className='banner'>
                            Note: The IAM role associated with the Flow Sensor instance will be used to access VPC flow logs. The attached IAM role must have the necessary permissions for the AssumeRole action.
                        </div>
                    }

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>SQS URL*</label>
                            <input
                                type='text'
                                name='aws_query_url'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter SQS URL'}
                            />
                        </div>
                    </div>
                    {managedVia === 'vpc_managed' && <><div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Access Key ID*</label>
                            <input
                                type='text'
                                name='aws_access_key_id'
                                ref={register({
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Access Key ID'}
                            />
                        </div>
                    </div>

                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label>Secret Access Key*</label>
                                <input
                                    type='text'
                                    name='aws_access_key_secret'
                                    ref={register({
                                        required: true,
                                        shouldUnregister: true
                                    })}
                                    placeholder={'Enter Secret Access Key'}
                                />
                            </div>
                        </div></>}

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>
                                VPC Flow Log Format*
                                <Tooltip
                                    classes={{
                                        tooltip: 'generic-tooltip-popper'
                                    }}
                                    PopperProps={{
                                        style: { zIndex: 999999 }
                                    }}
                                    title={
                                        <div>
                                            Minimum version 3 of AWS VPC flow
                                            logs is recommended, version 5 works
                                            the best.
                                        </div>
                                    }
                                >
                                    <span style={{ marginLeft: '0.2em' }}>
                                        <InfoIcon
                                            style={{
                                                fontSize: 15,
                                                color: '#d4d8e1',
                                                transform: 'skewX(-10deg)'
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            </label>
                            <input
                                type='text'
                                defaultValue='${version} ${vpc-id} ${subnet-id} ${instance-id} ${interface-id} ${account-id} ${type} ${srcaddr} ${dstaddr} ${srcport} ${dstport} ${pkt-srcaddr} ${pkt-dstaddr} ${protocol} ${bytes} ${packets} ${start} ${end} ${action} ${tcp-flags} ${log-status}'
                                placeholder='Enter VPC flow log fields format'
                                name='aws_vpc_flow_log_format'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
