import { MenuItem, Select } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import React, {
  useEffect,
  useReducer,
  useRef,
} from "react";
import { TrashIcon } from "../../../Icons";
import FilterInput from "./PostureFilterInput";
import "./PostureFilterItem.css";
import { getCasingForControl, getControlOperators, getControlPlaceholderText, getControlType, getControlValidations, getControlValues, getValueOnType } from "./posture-filter-utils";
import useValidate from "../hooks/useValidate";
import { useLocation } from "react-router-dom";
import { ENTITIES } from '../../../pages/identity_asset/constants/Constants';
import CheckboxAutocomplete from "../AMAutoComplete/AMAutoComplete";

const required = ['required'];
const useStyles = makeStyles({
  select: {
    "& ul": {
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    "& li": {
      '&:hover': {
        backgroundColor: 'rgb(104 104 104 / 14%)',
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&:hover': {

          backgroundColor: 'rgb(104 104 104 / 14%)',
        }
      }
    },
  },
});


const filterReducer = (state, action) => {
  switch (action.type) {
    case "FILTER_SELECTED":
      return {
        search_key: action.filter,
        operator: action.operator,
        value: getValueOnType(action.controlType),
        controlType: action.controlType || "text",
        controlValues: action.controlValues,
        validations: action?.validations || required,
        index: state.index,
        isValid: false,
        placeholderText: action.placeholderText,
        previousFilter: state?.search_key || '',
        isPrestine: false,
        isFilterChanged: true,
        isOperatorChanged: true
      };
    case "OPERATOR_SELECTED":
      return {
        search_key: state.search_key,
        operator: action.operator,
        value: getValueOnType(action.controlType),
        controlType: action.controlType,
        controlValues: state.controlValues || "",
        validations: action.validations,
        index: state.index,
        isValid: false, // check this
        placeholderText: action.placeholderText,
        previousFilter: state?.previousFilter || '',
        isPrestine: false,
        isFilterChanged: false,
        isOperatorChanged: true
      };
    case "VALUE_UPDATED":
      return {
        search_key: state.search_key,
        operator: state.operator,
        value: getValueOnType(state.controlType, action.value),
        controlType: state.controlType || "text",
        controlValues: state.controlValues || "",
        validations: state.validations,
        index: state.index,
        isValid: action.isValid, // check this
        placeholderText: state.placeholderText,
        previousFilter: state?.previousFilter || '',
        isPrestine: false,
        isFilterChanged: false,
        isOperatorChanged: false
      };
    case 'POPULATE_ALL_FIELDS':
      return {
        search_key: action.filter,
        operator: action.operator,
        value: getValueOnType(action.controlType, action.value),
        controlType: action.controlType,
        controlValues: action.controlValues,
        validations: action.validations,
        index: state.index,
        isValid: action.isValid,
        placeholderText: state.placeholderText,
        previousFilter: state?.previousFilter || '',
        isPrestine: true,
        isFilterChanged: false,
        isOperatorChanged: false
      };
    case "SET_ISVALID":
      return {
        ...state,
        isValid: action.isValid,
        isFilterChanged: false,
        isOperatorChanged: false
      };
    default:
      return {
        search_key: '',
        operator: '',
        value: '',
        controlType: "text",
        controlValues: "",
        validations: [],
        index: -1,
        isValid: false,
        previousFilter: '',
        isPrestine: false,
        isFilterChanged: true,
        isOperatorChanged: true
      };
  }
};

export const FilterItem = ({
  value,
  options,
  onChange,
  controlKey = '',
  index,
  addedFiltersList,
  updateFilter,
  id,
  onRemoveFilter,
  entityType,
  onlyFields,
  showAllFields
}) => {

  const [filterState, dispatchFilter] = useReducer(filterReducer, {
    search_key: "",
    operator: "",
    value: "",
    type: "text",
    index: index,
    validations: [],
    isValid: false,
  });

  const filterInputRef = useRef();
  const { validate } = useValidate();
  const classes = useStyles();
  const location = useLocation();
  const handleFilterChange = (event, config) => {
    const conf = config[0];    
    event.preventDefault();
    if ((addedFiltersList.indexOf(conf?.search_key || '') > -1) || !conf) {
      return;
    }
    // const isFormValid =filterInputRef.current.isValid(); // not required
    // dispatchFilter({type:'SET_ISVALID',isValid: isFormValid});
    const availableOperators = getControlOperators(options, conf?.search_key || '').filter(i => i);
    const controlTypeValue = getControlType(options, conf?.search_key || '', availableOperators[0].id);
    const controlValues = getControlValues(options, conf?.search_key || '');
    const validations = getControlValidations(options, availableOperators[0].id, conf?.search_key || '');
    const placeholderText = getControlPlaceholderText(options, availableOperators[0].id, conf?.search_key || '');

    dispatchFilter({
      type: "FILTER_SELECTED",
      filter: conf?.search_key || '',
      controlType: controlTypeValue,
      controlValues: controlValues,
      validations: validations,
      placeholderText: placeholderText,
      operator: availableOperators[0]?.id
    });
    // select default first operator
    // if(availableOperators?.length > 0) {
    //   //const isFormValid =filterInputRef.current.isValid();
    //   //isValid will always be false as it reset inputValue component.
    //   dispatchFilter({ type: "OPERATOR_SELECTED", operator: availableOperators[0].id, isValid: false});
    // }
    handleOnMenuOpen()
  };

  const handleOperatorChange = (e, event) => {
    e.preventDefault();
    const key = event[0].key;
    const controlTypeValue = getControlType(options, filterState?.search_key, key);
    const controlValues = getControlValues(options, key);
    const validations = getControlValidations(options, key, filterState?.search_key);
    const placeholderText = getControlPlaceholderText(options, key, filterState?.search_key);
    dispatchFilter({ type: "OPERATOR_SELECTED", operator: key, controlType: controlTypeValue, controlValues: controlValues, validations: validations, placeholderText: placeholderText });  
  };

  const handleValueChange = (inputValue, isValid) => {
    const isFormValid = filterInputRef.current.isValid();
    dispatchFilter({ type: "VALUE_UPDATED", value: inputValue, isValid: isFormValid });
  };

  const removeThisFilter = (event) => {
    onRemoveFilter(index);
  };

  useEffect(() => {

    if (value && value?.search_key !== "") {
      const controlTypeValue = getControlType(options, value?.search_key, value?.operator);
      const controlValues = getControlValues(options, value?.search_key);
      const validations = getControlValidations(options, value?.operator, value?.search_key);
      const parsedFilterValue = getValueOnType(controlTypeValue, value?.value);
      const isInputValid = validate(validations, parsedFilterValue);
      const placeholderText = getControlPlaceholderText(options, value?.operator, value?.search_key);
      dispatchFilter({
        type: "POPULATE_ALL_FIELDS",
        filter: value.search_key,
        operator: value.operator,
        value: parsedFilterValue,
        controlType: controlTypeValue,
        controlValues: controlValues,
        validations: validations,
        isValid: isInputValid,
        placeholderText: placeholderText
      });
    }
  }, []);

  useEffect(() => {
    updateFilter(filterState);

    let id = setTimeout(() => {
      onChange(filterState);
    }, 100);

    return () => clearTimeout(id)

  }, [filterState]);

  const availableOperators = getControlOperators(options, filterState?.search_key).filter(i => i);
  const controlValues = getControlValues(options, filterState?.search_key);
  const casing = getCasingForControl(options, filterState?.search_key);

  const isFieldApplicable = (item) => {
    let loc;
    if (showAllFields) {
      return 
    } else
    if (onlyFields) {
      loc = ENTITIES[entityType]?.pageType;
    } else { 
      loc = location.pathname.replace('/', '');
    }
    if (item.pageType[0] == 'All' || item.pageType.filter((i) => loc.toLowerCase().includes(i.toLowerCase())).length > 0) {
      return true;
    }
    return false;
  }

  const handleOnMenuOpen = () => {
    setTimeout(() => {
      const _grpArray = Array.from(document.querySelectorAll('.checkbox-autocomplete-listbox li'));
      const o = {};
      if (_grpArray && _grpArray?.length > 0) {
        _grpArray.forEach((i, index) => {
          if (i?.classList.contains('group-root')) {
            o[index] = index;
          }
        })
      }
      const res = {};
      let currentIndex = 0;
      for (let i = 0; i < (_grpArray?.length || 0); i++) {
        if (i === o[i]) {
          res[i] = true;
          currentIndex = i;
        } else {
          const el = _grpArray[i];
          if (res[currentIndex] === true && !el?.classList.contains('already-selected')) {
            res[currentIndex] = false;
          }
        }
      }
      for (const k in res) {
        if (res[k] === true) {
          _grpArray[k]?.classList?.add('already-selected');
        }
      }
    }, 30)
  }

  const getItemLabel = (
    option
  ) => {    
   if (Array.isArray(option)) {
      return option[0]?.value ? option[0]?.value : "";
   } else if (typeof option == 'string') {
      const o = options.find((opt) => opt.search_key == option);
      return o?.label || ''      
   } else {
      return option?.value ? option?.value : "";
    }        
  };

  const getOperatorLabel = option => {        
    if (typeof option == 'string') {
      const val = availableOperators.find((op) => op.id == option);
      return val?.label || option
    }
    return  option.value;
  }

  const renderOptionsFn = (props, item) => {         
    handleOnMenuOpen()
        if (item.isGroupRoot) {
          return <span></span>
        } else if (isFieldApplicable(item) || showAllFields) {
          const classes = ((addedFiltersList.indexOf(item.search_key) > -1) ? 'already-selected' : '') + ' font14_imp';
          return <MenuItem
          {...props}
            classes={classes + ' font14_imp adv-search-menu-item'}
            title={item.label}
            className={classes + ' adv-search-menu-item adv-search-menu-item-child'} value={item.search_key}            
          >{item.label}</MenuItem>;
        }
        return <span></span>      
  }

  const renderGroup = (params) => {
    const count = params?.children?.filter(i => i.key).length;  
    return (
      <>
        {count > 0 && <li className="adv-search-menu-group-root font14">{params.group}</li>}
        <div className="adv-search-menu-item-child">{params.children}</div>
        </>        
    )
  }

  return (
    <>
      
      <div className="search-field-wrapper">
        <div className="search-filter">
        <CheckboxAutocomplete
              label="Select Field"
              options={options}
              value={filterState.search_key}
              onChange={handleFilterChange}
              getItemLabel={getItemLabel}
              multiple={false}
              showSeparator={true}              
            onMenuOpen={handleOnMenuOpen}
            renderOptionsFn={renderOptionsFn}
            hideClearBtn={true}
            groupOptions={
              {renderGroup}
            }
            classes={{popper:'posture-filter-item-popper field-list'}}
            />         
        </div>
        {<div className="search-operator">
          <CheckboxAutocomplete
              label="Select Operator"
              options={availableOperators?.map((item) => ({...item, key:item.id, value:item.label})).filter(i=>i)}
              value={filterState.operator}
              onChange={handleOperatorChange}
              getItemLabel={getOperatorLabel}
              multiple={false}
            showSeparator={true}    
            hideClearBtn={true}
            classes={{popper:'posture-filter-item-operator-popper'}}
            />
      </div>}
        <div className="search-input">
          <FilterInput
            id={`${filterState?.search_key}`}
            value={filterState?.value}
            onChange={handleValueChange}
            controlType={filterState?.controlType}
            controlValues={controlValues}
            validations={filterState?.validations}
            ref={filterInputRef}
            selectedOperator={filterState?.operator}
            placeholderText={filterState?.placeholderText}
            casing={casing}
            filterChanged={filterState?.isFilterChanged}
            operatorChanged={filterState?.isOperatorChanged}
          />
        </div>
        <div onClick={removeThisFilter} className="delete-filter">
          <TrashIcon />
        </div>
        { }
      </div>
      {/* {JSON.stringify(filterState)} */}
    </>
  );
};
