import React, { useState, useEffect } from 'react'
import {Api} from '../../../components/Axios'
import { useToasts } from '../../../components/core';
import Popup from 'reactjs-popup'
import { useHistory } from 'react-router'
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';

type Listing = {
    _id: string;
    name: string;
    email: string;
    role: string;
    last_login: string;
    last_ip: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp?: number;
    sort_by?: string;
    order_by?: string;
}

interface Props {
    updateTicketDetails: ()=>void;
}

type LocationState={
    ticket_id: number | undefined;
    assignee_id: string | undefined;
    assignee_name: string | undefined;
}

export const AssignedUser = ({updateTicketDetails}: Props) => {
    let history = useHistory();
	const [loading, setLoading] = useState<Boolean>(false)	
    const { addToast } = useToasts()
    const [result, setResult] = useState<Result>();
    let locationState = history.location.state as LocationState;
    const [assigneeId, setAssigneeId] = useState<string|undefined>(locationState.assignee_id);
    const [assigneeName, setAssigneeName] = useState<string|undefined>(locationState.assignee_name);

    

    const Listing = () => {
        Api.get('/users?rpp=100&sort_by=name&order_by=asc&read_users_allowed=true')
        .then((res: { data: any }) => {
            setResult(res.data)
        })
        .catch((error: any) => {
        })
    }

    useEffect(() => {
        Listing();
    }, [])

    const AssignTicketUser = () => {
        Api.post('/tickets/'+locationState.ticket_id+"/assign", {
            assignee_id: assigneeId
        })
        .then((res: { data: any }) => {
            setLoading(false)
            addToast("Assignee updated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
            updateTicketDetails();
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, options) => {
        const option = options[0];
        setAssigneeId(option.key);
        setAssigneeName(option.value);
    }

    const handleAssign=()=>{
        AssignTicketUser();
        history.goBack();
    }

    const handleCancel=()=>{
        history.goBack();
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key || op.key == 0) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    return (
        <>
             <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal
                // //lockScroll
            >
                <div className={"modal ticket_modal"}>
                    <div className={"content"}>
                        <div className="popup_title fl display_flex_center " style={{marginBottom:0, width:'100%'}}>
                            <span className='width50per'>Assign Ticket: </span>
                            <CheckboxAutocomplete
                                options={result?.result?.map((user:Listing) =>  ({key:user._id, value: user.name})) || []}
                                value={{ value: assigneeName, key: assigneeId }}
                                getItemLabel={getItemLabel}
                                onChange={handleChange}
                                multiple={false}
                                hideClearBtn={true}                                
                            />                           
                    </div>
                    <div className="clrBoth"></div>
                    </div>
                    <div className="popup_footer fl" style={{borderTop: "none", paddingTop:0}}>
                        <div className="policy_defualt_button cancl_policy_popup" onClick={handleCancel}>Cancel</div>
                        <div className="policy_save_button cancl_policy_popup" onClick={handleAssign}>Assign</div>
                    </div>
                </div>
            </Popup>
        </>
    )
} 