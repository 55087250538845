import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import './issue-trend-chart.css';
import { RiskLevelDataTrends } from '../../../types/response-types';
import moment from 'moment';
import { useHistory } from 'react-router';
import { getRiskLevelFromColor } from '../../../utils/risk-level';
import { getRoundedUnit } from '../../../utils/util-methods';
import { DashboardRuleMap } from '../../../constants/dashboard-rule';
import { useDashboardStateContext } from '../../../store/DashboardStateContextProvider';
import { getIssueNameById } from '../../../pages/playbooks/helpers/playbook-helper';

type Props = {
    last_24_hours_issues: {
        [time: string]: RiskLevelDataTrends
    } | undefined
}

let timeStamp: string[] = [];
let totalIssues = 0;


const IssuesTrendChart = ({ last_24_hours_issues }: Props) => {

    let scrollElmCollection;
    let scrollElement: Element | null | undefined;
    let elmCollection;
    let leftBtn: Element | null | undefined;
    let rightBtn: Element | null | undefined;
    let history = useHistory();

    const getIssueType = (issueType) => {
        issueType = issueType.replace('Suspected Attack on Expired AD Account', 'Suspected Attack on Expired AD Account/Password')
        return getIssueNameById(issueType)
    }

    const options: Highcharts.Options = {
        chart: {
            type: "column",
            renderTo: 'chart',
            margin: window.matchMedia("(min-width: 2560px)").matches ? 60 : 30,
            style: {
                fontFamily: 'Metropolis-Regular'
            },
            height: window.matchMedia("(min-width: 2560px)").matches ? 260 : 130,
            scrollablePlotArea: {
                minWidth: window.matchMedia("(min-width: 2560px)").matches ? 3200 : 1650,
                scrollPositionX: 1
            },
            // spacingTop: 40
        },
        plotOptions: {
            line: {
                enableMouseTracking: false,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    fillColor: undefined,
                    fillOpacity: 12,
                    lineWidth: 1,
                    radius: 2,

                },
                lineWidth: 1
            },
            column: {
                states: {
                    inactive: {
                        enabled: false
                    }
                },
                stacking: 'normal',
                showInLegend: false,
                events: {
                    click: (e) => {
                        let riskLevel = e.point.color ? getRiskLevelFromColor(e.point.color.toString()) : 0;
                        let startTime = +timeStamp[e.point.x];
                        let endTime = startTime + 3600;
                        history.push(`/issues?disable_filter=true&page=1&q=status:Open%2Brisk:${riskLevel}%2Bs_m_time:${startTime}%2Be_m_time:${endTime}&sort_by=issue_flows_count&order_by=desc`, { breadcrumbId: 'TrendIncidents' });
                    }
                },
                cursor: 'pointer'
            }
        },
        title: {
            align: 'left',
            useHTML: true,
            text: '<span class="issue-trend-title"></span>',
        },
        credits: undefined,
        tooltip: {
            useHTML: true,
            backgroundColor: undefined,
            //followPointer: true,
            hideDelay: 0,
            borderWidth: 0,
            distance: 2,
            shadow: false,
            formatter: function () {
                try {
                    let thisPoint = this.point;
                    let allSeries = this.series.chart.series;
                    let thisIndex = thisPoint.index;

                    //Get matching object from last_24_hours based on the index.
                    const match_data: any = Object.values(last_24_hours_issues!)[thisIndex];
                    //Total Count
                    const sumTotalMatchData: any = Object.values(match_data["total"]).reduce((a: any, b: any) => a + b)
                    //Top 3 
                    const firstRule: any = Object.entries(match_data["total"]).sort((x: any, y: any) => y[1] - x[1])[0];
                    const secondRule: any = Object.entries(match_data["total"]).sort((x: any, y: any) => y[1] - x[1])[1];
                    const thirdRule: any = Object.entries(match_data["total"]).sort((x: any, y: any) => y[1] - x[1])[2];
                    //Others Total = Total - Top 3
                    const othersTotal = sumTotalMatchData - (firstRule[1] || 0 + secondRule[1] || 0 + thirdRule[1] || 0);

                    //Caculate Percent of Top 3 alongwith risk level and Others Color and Total 
                    const firstRulePer = Math.round((firstRule[1] / sumTotalMatchData!) * 100).toString().length == 1 ?
                        '0' + Math.round((firstRule[1] / sumTotalMatchData!) * 100) :
                        Math.round((firstRule[1] / sumTotalMatchData!) * 100)
                    const secondRulePer = Math.round((secondRule[1] / sumTotalMatchData!) * 100).toString().length == 1 ?
                        '0' + Math.round((secondRule[1] / sumTotalMatchData!) * 100) :
                        Math.round((secondRule[1] / sumTotalMatchData!) * 100)
                    const thirdRulePer = Math.round((thirdRule[1] / sumTotalMatchData!) * 100).toString().length == 1 ?
                        '0' + Math.round((thirdRule[1] / sumTotalMatchData!) * 100) :
                        Math.round((thirdRule[1] / sumTotalMatchData!) * 100)
                    const otherRulePer = Math.round((othersTotal / sumTotalMatchData!) * 100).toString().length == 1 ?
                        '0' + Math.round((othersTotal / sumTotalMatchData!) * 100) :
                        Math.round((othersTotal / sumTotalMatchData!) * 100)

                    //Get Rule Colors
                    /*
                        {
                            "1" : 98,
                            "2" : 0,
                            "3" : 45,
                            "4" : 65
                        }
                    */
                    let colorData = ["#cccccc", "#fcbf2e", "#fd7122", "#fa1262"];
                    let firstRuleMaxObj: any = {
                        "1": match_data[1][firstRule[0]],
                        "2": match_data[2][firstRule[0]],
                        "3": match_data[3][firstRule[0]],
                        "4": match_data[4][firstRule[0]]
                    }
                    firstRuleMaxObj = Object.entries(firstRuleMaxObj).sort((x: any, y: any) => y[1] || 0 - x[1] || 0)[0];
                    let secondRuleMaxObj: any = {
                        "1": match_data[1][secondRule[0]],
                        "2": match_data[2][secondRule[0]],
                        "3": match_data[3][secondRule[0]],
                        "4": match_data[4][secondRule[0]]
                    }
                    secondRuleMaxObj = Object.entries(secondRuleMaxObj).sort((x: any, y: any) => y[1] || 0 - x[1] || 0)[0];
                    let thirdRuleMaxObj: any = {
                        "1": match_data[1][thirdRule[0]],
                        "2": match_data[2][thirdRule[0]],
                        "3": match_data[3][thirdRule[0]],
                        "4": match_data[4][thirdRule[0]]
                    }
                    thirdRuleMaxObj = Object.entries(thirdRuleMaxObj).sort((x: any, y: any) => y[1] || 0 - x[1] || 0)[0];

                    const firstRuleRiskLevel = colorData[parseInt(firstRuleMaxObj[0]) - 1];
                    const secondRuleRiskLevel = colorData[parseInt(secondRuleMaxObj[0]) - 1];
                    const thirdRuleRiskLevel = colorData[parseInt(thirdRuleMaxObj[0]) - 1];

                    let returnString = '<div class="hc-tooltip-wrapper"><span class="hc-tooltip-total"><b>' + getRoundedUnit(sumTotalMatchData) + '</b> &nbsp;Incidents</span>';
                    returnString += '<div class="hc-tooltip-list"><span style="background-color:' +
                        firstRuleRiskLevel +
                        `;color: ${getRiskLevelFromColor(firstRuleRiskLevel) === 'low' || getRiskLevelFromColor(firstRuleRiskLevel) === 'medium' ? '#555555' : '#fff'};` +
                        'z-index: 12000" class="hc-tooltip-value hc-tooltip-value-first"> ' +
                        "<b>" +
                        `${firstRulePer === "00" ? "< 1" : firstRulePer}` +
                        "</b>" +
                        "%" +
                        "</span>" + `<span class='hc-tooltip-label hc-tooltip-label-first'>${getIssueType(firstRule[0])}</span></div>`;

                    returnString += '<div class="hc-tooltip-list"><span style="background-color:' +
                        secondRuleRiskLevel +
                        `;color: ${getRiskLevelFromColor(secondRuleRiskLevel) === 'low' || getRiskLevelFromColor(secondRuleRiskLevel) === 'medium' ? '#555555' : '#fff'};` +
                        'z-index: 12000" class="hc-tooltip-value"> ' +
                        "<b>" +
                        `${secondRulePer === "00" ? "< 1" : secondRulePer}` +
                        "</b>" +
                        "%" +
                        "</span>" + `<span class='hc-tooltip-label'>${getIssueType(secondRule[0])}</span></div>`;

                    returnString += '<div class="hc-tooltip-list"><span style="background-color:' +
                        thirdRuleRiskLevel +
                        `;color: ${getRiskLevelFromColor(thirdRuleRiskLevel) === 'low' || getRiskLevelFromColor(thirdRuleRiskLevel) === 'medium' ? '#555555' : '#fff'};` +
                        'z-index: 12000" class="hc-tooltip-value"> ' +
                        "<b>" +
                        `${thirdRulePer === "00" ? "< 1" : thirdRulePer}` +
                        "</b>" +
                        "%" +
                        "</span>" + `<span class='hc-tooltip-label'>${getIssueType(thirdRule[0])}</span></div>`;

                    returnString += '<div class="hc-tooltip-list"><span style="background-color:#aaaaaa;color:#555555;z-index: 12000" class="hc-tooltip-value"> ' +
                        "<b>" +
                        `${otherRulePer}` +
                        "</b>" +
                        "%" +
                        "</span>" + `<span class='hc-tooltip-label'>Others</span></div>`;

                    // allSeries.forEach((ser)=>{
                    //     if(ser.type==="column"){
                    //         let n = ser.points[thisIndex].y === null ? 0 : ser.points[thisIndex].y;
                    //         let t = ser.points[thisIndex].total === undefined ? 0 : ser.points[thisIndex].total;
                    //         returnString+='<div class="hc-tooltip-list"><span style="background-color:' +
                    //         ser.points[thisIndex].color +
                    //         `;color: ${ser.name==='low' || ser.name==='medium' ?'#555555':'#fff'};` +'z-index: 12000" class="hc-tooltip-value"> ' +
                    //         "<b>" +
                    //         `${Math.round((n!/t!)*100).toString().length==1?'0'+Math.round((n!/t!)*100):Math.round((n!/t!)*100)}`+
                    //         "</b>" +
                    //         "%" +
                    //         "</span>" + `<span class='hc-tooltip-label'>${Math.round((n!))}</span></div>`
                    //         // + '<span class="hc-tooltip-label">Rule Type</span><br/>'
                    //     }
                    // })
                    returnString += '</div>';
                    return returnString;
                }
                catch (error) {
                    console.log(error);
                    return '';
                }
            }
        },
        legend: {
            enabled: false
        }
    }

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

    const [timeData, setTimeData] = useState<string[] | undefined>();
    const [lowData, setLowData] = useState<number[] | undefined>();
    const [lowPointData, setLowPointData] = useState<number[] | undefined>();
    const [mediumData, setMediumData] = useState<number[] | undefined>();
    const [mediumPointData, setMediumPointData] = useState<number[] | undefined>();
    const [highData, setHighData] = useState<number[] | undefined>();
    const [highPointData, setHighPointData] = useState<number[] | undefined>();
    const [criticalData, setCriticalData] = useState<number[] | undefined>();
    const [criticalPointData, setCriticalPointData] = useState<number[] | undefined>();

    const [hideButtonLeft, setHideButtonLeft] = React.useState(false);
    const [hideButtonRight, setHideButtonRight] = React.useState(true);

    const { IncidentTrendState: { scrollValue, setScrollValue } } = useDashboardStateContext();

    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    let time: string[] = [];
    let lData: number[] = [];
    let mData: number[] = [];
    let hData: number[] = [];
    let cData: number[] = [];

    useEffect(() => {
        if (last_24_hours_issues) {
            timeStamp = [];
            totalIssues = 0;
            for (let issueTime in last_24_hours_issues) {
                timeStamp.push(issueTime);
                time.push(moment.unix(+issueTime).format('hh:mm a'));
                //Add Logic Low, Medium, High and Critical Total

                //Low
                const lowObj = last_24_hours_issues[issueTime][1];
                let sumLowData = 0;
                if (Object.values(lowObj).length > 0) {
                    sumLowData = Object.values(lowObj).reduce((a: any, b: any) => a + b);
                }

                //Medium
                const medObj = last_24_hours_issues[issueTime][2]
                let sumMedData = 0;
                if (Object.values(medObj).length > 0) {
                    sumMedData = Object.values(medObj).reduce((a: any, b: any) => a + b);
                }

                //High
                const highObj = last_24_hours_issues[issueTime][3]
                let sumHighData = 0;
                if (Object.values(highObj).length > 0) {
                    sumHighData = Object.values(highObj).reduce((a: any, b: any) => a + b);
                }

                //Critical
                const criticalObj = last_24_hours_issues[issueTime][4]
                let sumCriticalData = 0;
                if (Object.values(criticalObj).length > 0) {
                    sumCriticalData = Object.values(criticalObj).reduce((a: any, b: any) => a + b);
                }
                //Total
                const totalObj = last_24_hours_issues[issueTime]["total"];
                let sumTotalData = 0;
                if (Object.values(totalObj).length > 0) {
                    sumTotalData = Object.values(totalObj).reduce((a: any, b: any) => a + b);
                }

                lData.push(sumLowData);
                mData.push(sumMedData);
                hData.push(sumHighData);
                cData.push(sumCriticalData);
                totalIssues += sumTotalData;
            }
            setTimeData(time);
            setLowData(lData);
            setMediumData(mData);
            setHighData(hData);
            setCriticalData(cData);
            setTimeout(function () {
                // highchart lib auto resize bug timing fix.
                let containers = document.getElementsByClassName('issue-trend-chart');
                if (containers?.length > 0) {
                    chartRef.current?.chart.setSize(containers[0].clientWidth, containers[0].clientHeight, false);
                }

                // scroll to current time data.
                if (chartRef && chartRef.current) {
                    scrollElmCollection = chartRef.current.container.current?.getElementsByClassName('highcharts-scrolling');
                    scrollElement = scrollElmCollection?.item(0);
                    if (scrollElement) {

                        if (scrollValue !== 0) {
                            scrollElement.scrollLeft = scrollValue;

                            const scrollSize = scrollElement!.scrollWidth - scrollElement!.clientWidth;
                            const leftDisable = (scrollElement.scrollLeft <= 0);
                            const rightDisable = (scrollElement.scrollLeft >= scrollSize);
                            setHideButtonLeft(leftDisable);
                            setHideButtonRight(rightDisable);
                        }
                        else {
                            scrollElement.scrollLeft = 1000;
                        }
                    }
                }

            }, 1);
        }
    }, [last_24_hours_issues]);

    useEffect(() => {
        if (lowData && mediumData && highData && criticalData) {
            // lowPointData
            let newLowPointData = lowData.map((item: number, i: number) => {
                let l = lowData[i] === null ? 0 : lowData[i];
                let m = mediumData[i] === null ? 0 : mediumData[i];
                let h = highData[i] === null ? 0 : highData[i];
                let c = criticalData[i] === null ? 0 : criticalData[i];
                return l + m + h + c;
            })
            setLowPointData(newLowPointData);

            // newMediumPointData
            let newMediumPointData = mediumData.map((item: number, i: number) => {
                let m = mediumData[i] === null ? 0 : mediumData[i];
                let h = highData[i] === null ? 0 : highData[i];
                let c = criticalData[i] === null ? 0 : criticalData[i];
                return m + h + c;
            })
            setMediumPointData(newMediumPointData);

            // newMediumPointData
            let newHighPointData = highData.map((item: number, i: number) => {
                let h = highData[i] === null ? 0 : highData[i];
                let c = criticalData[i] === null ? 0 : criticalData[i];
                return h + c;
            })
            setHighPointData(newHighPointData);

            //criticalPointData
            let newCriticalPointData = criticalData.map((item: number, i: number) => {
                return criticalData[i] === null ? 0 : criticalData[i];
            })
            setCriticalPointData(newCriticalPointData)
        }
    }, [timeData, lowData, mediumData, highData, criticalData])

    useEffect(() => {
        if (lowPointData && mediumPointData && highPointData && criticalPointData) {
            let prevYAxis = chartOptions.yAxis!
            setChartOptions({
                ...chartOptions,
                series: [
                    {
                        type: "column",
                        name: 'low',
                        color: "#cccccc",
                        borderWidth: 0,
                        data: lowData,
                    },
                    {
                        type: "column",
                        name: 'medium',
                        color: "#fcbf2e",
                        borderWidth: 0,
                        data: mediumData,
                    },
                    {
                        type: "column",
                        name: 'high',
                        color: "#fd7122",
                        borderWidth: 0,
                        data: highData,
                    },
                    {
                        type: "column",
                        name: 'critical',
                        color: "#fa1262",
                        borderWidth: 0,
                        data: criticalData,
                    },
                    {
                        type: "line",
                        name: 'low',
                        color: "#cccccc",
                        borderWidth: 0,
                        data: lowPointData,
                    },
                    {
                        type: "line",
                        name: 'medium',
                        color: "#fcbf2e",
                        borderWidth: 0,
                        data: mediumPointData,
                    },
                    {
                        type: "line",
                        name: 'high',
                        color: "#fd7122",
                        borderWidth: 0,
                        data: highPointData,
                    },
                    {
                        type: "line",
                        name: 'critical',
                        color: "#fa1262",
                        borderWidth: 0,
                        data: criticalPointData,
                    },
                ],
                yAxis: [
                    {
                        title: {
                            useHTML: true,
                            text: `<span id="left-btn-issue-trend" aria-disabled={${hideButtonLeft}} class=${hideButtonLeft ? "iss-left-scroll-arrow-disabled" : "iss-left-scroll-arrow"}></span>`,
                            rotation: 0
                        },
                        gridLineWidth: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    {
                        linkedTo: 0,
                        title: {
                            useHTML: true,
                            text: `<span id="right-btn-issue-trend" aria-disabled={${hideButtonRight}} class=${hideButtonRight ? "iss-right-scroll-arrow-disabled" : "iss-right-scroll-arrow"}><span class='hidden'>.</span></span>`,
                            rotation: 0
                        },
                        opposite: true,
                        gridLineWidth: 0,
                        labels: {
                            enabled: false
                        }
                    }
                ],
                xAxis: {
                    className: 'hc-x-axis',
                    title: {
                        text: undefined
                    },
                    labels: {
                        style: {
                            fontSize: window.matchMedia("(min-width: 2560px)").matches ? '22px' : '11px'
                        }
                    },
                    categories: timeData,
                },
            });
        }


    }, [lowPointData, mediumPointData, highPointData, criticalPointData, hideButtonLeft, hideButtonRight])




    function sideScroll(element: Element, direction: string, speed: number, distance: number, step: number) {
        var scrollAmount = 0;
        var slideTimer = setInterval(function () {
            if (direction == 'left') {
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            let sliderWidth = scrollElement!.scrollWidth - scrollElement!.clientWidth;

            if (scrollElement!.scrollLeft > 0) {
                setHideButtonLeft(false);
            }
            else {
                setHideButtonLeft(true);
            }
            if (scrollElement!.scrollLeft < sliderWidth) {
                setHideButtonRight(false);
            }
            else {
                setHideButtonRight(true);
            }
            if (scrollAmount >= distance) {
                window.clearInterval(slideTimer);
                setScrollValue(element.scrollLeft);
                // setCurrentScrollState
            }
        }, speed);

    }

    const handleClick = (e: React.MouseEvent) => {
        if (chartRef && chartRef.current) {
            scrollElmCollection = chartRef.current.container.current?.getElementsByClassName('highcharts-scrolling');
            scrollElement = scrollElmCollection?.item(0);
            elmCollection = chartRef.current.container.current?.getElementsByClassName('highcharts-axis-title');
            leftBtn = elmCollection?.item(0);
            rightBtn = elmCollection?.item(1);
        }

        let el = e.target as HTMLElement;
        if (el.textContent == leftBtn?.textContent) {
            leftBtn?.addEventListener('click', e => {
                sideScroll(scrollElement!, 'left', 25, 100, 10);
            }, { once: true, capture: true }
            );
            leftBtn?.dispatchEvent(new Event('click'));
        }
        else if (el.textContent == rightBtn?.textContent) {
            rightBtn?.addEventListener('click', e => {
                sideScroll(scrollElement!, 'right', 25, 100, 10);
            }, { once: true, capture: true }
            )
            rightBtn?.dispatchEvent(new Event('click'));
        }
    }

    return (
        <>
            {totalIssues === 0 ?
                <div className="empty_trend_widget">&nbsp;</div>
                :
                <div className="issue-trend-chart" onClick={handleClick}>
                    <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </div>
            }
        </>
    )
}


export default React.memo(IssuesTrendChart);