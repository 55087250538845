import React, { useEffect, useRef, useState } from 'react';
import { CredentialQualityConfigAlgo, HashQualityConfigProps, CredentialQualityConfigProtocol, HashQualityConfigState } from '../../../../types/hash-quality-config-types';


const HashQualityConfig = ({ parentState, isSavedOnce, getState, defaultState, formType }: HashQualityConfigProps) => {

    const [state, setState] = useState(defaultState);

    const hashRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const saltRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            if (!parentState?.hashFlag || (parentState.hashFlag && parentState.hashValue === undefined)) {
                hashRef.current.value = "";
            }
            if (!parentState?.saltFlag || (parentState.saltFlag && parentState.saltValue === undefined)) {
                saltRef.current.value = "";
            }
        }
    }, [isSavedOnce])

    useEffect(() => {
        console.log('CredQUALITYConfig STATE', state);
        getState(state);
    })

    return (
        <>
            <form action="/">
                <div className="playbook-config-title">Authentication quality will be considered poor if:</div>                
                <input type="checkbox" id="hash-flag" name="hash-flag" checked={state?.hashFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                    onChange={(e) => {
                        if(formType && ['edit', 'view'].includes(formType) )
                            return;
                        if (e.target.checked) {
                            setState({
                                ...state,
                                hashFlag: true
                            })
                        }
                        else {
                            hashRef.current.value = "";
                            setState({
                                ...state,
                                hashFlag: false,
                                hashValue: undefined
                            })
                        }
                    }}
                />
                <label className="float_none" htmlFor="hash-flag"><b>Password hash length</b> is less than
                    {(formType && ['edit', 'view'].includes(formType)) ? <span> {state?.hashValue} hex characters</span> :
                        <>
                            <div className="inline"><input ref={hashRef} type="number" min={1} id="hash-value" name="hash-value" value={state?.hashValue}
                                disabled={!state?.hashFlag}
                                onChange={(e) => {
                                    if(formType && ['edit', 'view'].includes(formType) )
                                        return;
                                    setState({
                                        ...state,
                                        hashValue: parseInt(e.target.value)
                                    })
                                }}
                            /></div>
                            hex characters
                        </>
                    }

                </label>
                <div className="clrBoth">&nbsp;</div>

                <input type="checkbox" id="salt-flag" name="salt-flag" checked={state?.saltFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                    onChange={(e) => {
                        if(formType && ['edit', 'view'].includes(formType) )
                            return;
                        if (e.target.checked) {
                            setState({
                                ...state,
                                saltFlag: true
                            })
                        }
                        else {
                            saltRef.current.value = "";
                            setState({
                                ...state,
                                saltFlag: false,
                                saltValue: undefined
                            })
                        }
                    }}
                />
                <label className="float_none" htmlFor="salt-flag"><b>Password salt length</b> is less than
                    {
                        (formType && ['edit', 'view'].includes(formType)) ?
                            <span> {state?.saltValue} bits</span>
                            :
                            <>
                                <div className="inline"><input ref={saltRef} type="number" min={1} id="salt-value" name="salt-value" value={state?.saltValue}
                                    disabled={!state?.saltFlag}
                                    onChange={(e) => {
                                        if(formType && ['edit', 'view'].includes(formType) )
                                            return;
                                        setState({
                                            ...state,
                                            saltValue: parseInt(e.target.value)
                                        })
                                    }}
                                /></div>
                                bits
                            </>
                    }
                </label>
                <div className="clrBoth"></div>
            </form>
        </>
    )
}

export default React.memo(HashQualityConfig);