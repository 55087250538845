import React from 'react'
type Props = {
  title: string;
  children: React.ReactNode;
}

const SubHeader = ({title, children}: Props) => {
  return (
    <div className="title">
      <div className="header">{title}</div>
      {children}
    </div>
  )
}

export default SubHeader