export const UserError = {
    name: {
        required: 'Please enter name.',
    },
    email: {
        required: 'Please enter email.'
    },
    password: {
        required: 'Please enter password.'
    },
    confirm_password: {
        required: 'Please enter confirm password.',
        custom: 'Password do not match.',
        validate: 'Password do not match.'
    },
    role: {
        required: 'Please select role.'
    }
}