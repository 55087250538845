import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useToasts } from '../components/core';
import { useForm } from 'react-hook-form'
import { Api } from '../components/Axios'
import { useAuthDetails } from '../components/Authorization'
import './Login.css'
import BeforeLoginContainer from './BeforeLoginContainer';
import RedirectionContainer from './RedirectionContainer';
import { useLogin } from './eula/useLogin';

type Inputs = {
    password: string
}
type LoginType = {
    isRootUser?: Boolean
};

const Login = ({ location: { state: isRootUser } }: LoginType) => {
    const { authURLInvoked, authURLResponse } = useAuthDetails();
    const { sso_redirect_url } = authURLResponse || {};

    if (sso_redirect_url !== '' && !isRootUser) {
        window.location.replace(sso_redirect_url);
    }

    const [valid, setValidated] = useState(true)
    const [loading, setLoading] = useState({ loading: false, setData: false })
    const { addToast } = useToasts()
    const { register, handleSubmit, errors } = useForm<Inputs>()
    const { setAuthDetails } = useAuthDetails()!
    const [sErrors, setSErrors] = useState<Inputs>()
    const [eyeManager, setEyeManager] = useState(false)
    const history = useHistory();
    const { postLogin } = useLogin();
    useEffect(() => {
        const data = window.localStorage.getItem('user_auth')
        if (data !== null) {
        } else {
            setValidated(false)
        }
    }, [])


    useEffect(() => {
        /* EULA test case */
        const u = localStorage.getItem('eula_user');
        const user = u ? JSON.parse(u) : null;
        if (user) {
            postLogin(user);
        }
        localStorage.removeItem('eula_user');
    }, [])

    const onSubmit = (data: any) => {
        setLoading({ loading: true, setData: false })
        const userToken = window.localStorage.getItem('user_auth')
        if (userToken !== null) {
            Api.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
        }
        Api.post('/login', data)
            .then((res: { data: any }) => {
                postLogin(res);
                setLoading({ loading: false, setData: true })
            })
            .catch((error: any) => {
                setLoading({ loading: false, setData: false });
                if (error.response.status === 412) {
                    history.push('/eula-reject')
                } else if (error.response.status === 428) {
                    window.localStorage.setItem('user', JSON.stringify(error.response.data))
                    history.push('/eula');
                } else if (error.response.status === 500) {
                    addToast("Invalid Credentials, please try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    setSErrors(error.response.data)
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("Invalid Credentials, please try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 410) {
                    addToast("Session expired, please check and try again.", {
                        appearance: 'warning',
                        autoDismiss: true,
                    })
                    window.localStorage.removeItem('user_auth')
                    setValidated(false)
                }
            })

    }

    const ManageEye = () => {
        let eye = eyeManager ? false : true
        // console.log(eye)
        setEyeManager(eye)
    }

    const renderContent = (sso_redirect_url === '' || isRootUser) ? <BeforeLoginContainer><form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_header">Login</div>
        <label>Password</label>
        <input
            type={eyeManager ? 'text' : 'password'}
            name="password"
            placeholder="Enter your password"
            ref={register({
                required: true
            })}
            className={(errors.password ? "error width100per" : "width100per")}
        />
        <i className={eyeManager ? 'eye_open' : 'eye_close'} id="togglePassword" onClick={() => ManageEye()}></i>
        {errors.password && errors.password.type === 'required' && <div className="error">Please enter password.</div>}
        {!errors.password && sErrors?.password !== "" && <div className="error">{sErrors?.password}</div>}


        <Link to='/forgot-password' className="forgot_link">Forgot Password?</Link><br />
        <Link to='/login' className="forgot_link">Not You?</Link>
        <div className="clrBoth"></div>
        <button type={(loading.loading ? 'button' : 'submit')} className={" " + (loading.loading ? 'loader' : 'button_login')}>Login</button>
        <div className="clrBoth"></div>
    </form></BeforeLoginContainer> : <RedirectionContainer />


    if (!valid) return <Redirect to='/login' />

    return renderContent;
}

export default Login