import React, { useEffect, useState } from "react"
import './directory-config.css';
import { BaseProps } from "../../constants/types";
import { NTLMRelayAttackState } from "../../../../types/playbooks-config";

export const NTLMRelayAttack = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<NTLMRelayAttackState>) => {
    const [state, setState] = useState<NTLMRelayAttackState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleNumAccWithBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { dir_ntlm_relay_count_today: parseInt(e.target.value) } })
    }

    useEffect(() => {
        const op = state?.dir_params?.dir_ntlm_relay_count_today;
        const object = {
            user_input1: op
        }
        if (getExpression) {
            getExpression(object);
        }
    })

    return <>
        <form className="suspected-pass-spray-container">
            <div className="suspected-pass-spray-row bold-text">
                <label className="playbook-config-title marginBottom0">
                    An Active Directory will be marked as under NTLM relay attack when:</label>
            </div>
            <div className="suspected-pass-spray-row">
                <label>
                    Number of attempts within a day is more than
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params.dir_ntlm_relay_count_today}</label> :
                        <input type="number" onChange={handleNumAccWithBadPass} name="num_acc_bad_pass" value={state?.dir_params.dir_ntlm_relay_count_today} />}
            </div>
        </form>
    </>
}