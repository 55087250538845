import React from "react";
import "./recent-access-new-skeleton.scss";

export const RecentAccessNewSkeleton = () => {
    return (
        <>
            <div className="skeleton-recent-access-container animate-pulse"></div>
        </>
    );
};
