import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';
import {Api} from '../../../components/Axios'

type Group = {
    name?: string;
}

interface Props {
    id?: string;
    AfterGroupOperation?: any;
}

export const EditPanel = ({id, AfterGroupOperation}: Props) => {
    const { register, handleSubmit, errors } = useForm<Group>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [group, setGroup] = useState<Group>()
    const { addToast } = useToasts()

    const onUpdateGroup = (data: any) => {
        setLoading(true)
        const headers = {'Operation':'PUT'}
        Api.post('/groups/'+id, data, {headers: headers})
        .then((res: { data: any }) => {
            setLoading(false)
            AfterGroupOperation('edit')
            addToast("Group updated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    useEffect(() => {
        Api.get('/groups/'+id)
        .then((res: { data: any }) => {
            setGroup({
                name: res.data.name
            })
        })
        .catch((error: any) => {
        })
    }, [])

    return (
        <form onSubmit={handleSubmit(onUpdateGroup)}>
            <label>Name</label>
            <input
                type="text"
                placeholder=""
                name="name"
                ref={register({
                    required: true,
                })}
                className={(errors.name ? "error" : "")}
                defaultValue={group?.name}
            />
            {errors.name && errors.name.type === 'required' && <div className="error">Please enter name.</div>}
            {errors.name && errors.name.type !== 'required' && <div className="error">{errors.name.message}</div>}

            <div className="footer_line"></div>
            <div className="pane_footer">
                <div className="launch_button">
                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                </div>
            </div>
        </form>
    )
}