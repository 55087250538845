import React from "react";
import "./past-24hrs-incident-skeleton.scss";

export const Past24HoursIncidentSkeleton = () => {
    return (
        <>
            <div className="skeleton-past24-incident-container">
                <div className="skeleton-content" style={{ overflow: "visible", left: "0px", padding: "0px" }}>
                    <div className="skeleton-content-container">
                        <div className="label-border">
                            <div className="total-issues-percent shadow">
                                <div className="total-issue-content animate-pulse"></div>
                            </div>
                        </div>
                        <div className="hrs24-flows-label shadow">
                        <div className="hrs24-flows-label-content animate-pulse"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
