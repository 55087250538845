import React, { useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { isJsonString } from '../../../utils/util-methods';

export const GCPVPCCollector = ({
    register,
    setValue,
    collectorData,
    getValues,
    clearErrors,
    setError
}) => {
    useEffect(() => {
        if (collectorData) {
            setValue(
                'project_id',
                collectorData.collector_config?.project_id
            );
            setValue(
                'topic_name',
                collectorData.collector_config?.topic_name
            );
            setValue(
                'subscription_name',
                collectorData.collector_config?.subscription_name
            );
            setValue(
                'service_acc_key',
                collectorData.collector_config?.service_acc_key
            );
        }
    }, [collectorData]);

    const updateJSONFormatGcp = () => {
        const val = getValues('service_acc_key') || '';
        if (isJsonString(val)) {
            const obj = JSON.parse(val);
            const pretty = JSON.stringify(obj, undefined, 4);
            setValue('service_acc_key', pretty);
            clearErrors('service_acc_key');
        } else {
            setError('service_acc_key', {
                type: 'custom',
                message: 'Please enter valid JSON.'
            });
        }
    };
    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    GCP Flow Logs Configuration
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Project ID*</label>
                            <input
                                type='text'
                                name='project_id'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Project ID'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Topic Name*</label>
                            <input
                                type='text'
                                name='topic_name'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Topic Name'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Subscription Name*</label>
                            <input
                                type='text'
                                name='subscription_name'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Subscription Name'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Service Account Key*</label>
                            <textarea
                                wrap='off'
                                style={{
                                    width: '100%',
                                    margin: 0,
                                    fontFamily: 'monospace',
                                    resize: 'none'
                                }}
                                rows={8}
                                placeholder='Enter Service Account Key (JSON format)'
                                name='service_acc_key'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                onBlur={updateJSONFormatGcp}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
