import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Api } from '../Axios';

const defaultUserDetails = {
  name: "",
  email: "",
  token: "",
  role: "",
  permissions: {},
  authenticated: false
};

type AuthContextType = {
  authDetails: any,
  setAuthDetails: Function,
  authURLInvoked?: Boolean,
  authURLResponse?: any,
};

const AuthContext = React.createContext<AuthContextType | undefined>(
  undefined
);

type Props = {
  children: React.ReactNode;
};
interface AuthURLResponseType {
  message?: string;
  sso_redirect_url?: string;
}

const isLoggedIn = () => {
  const userDetails = localStorage.getItem('user') as string;
  const user = JSON.parse(userDetails);
  const location = window.location.pathname.includes('login') ||
    window.location.pathname == "/" || window.location.pathname.includes('forgot-password') ||
    window.location.pathname.includes('reset') || window.location.pathname.includes('root-user') || window.location.pathname === '/eula' ||
    window.location.pathname === '/eula-reject' || window.location.pathname === '/health';
  return user?.authenticated || location;
}


const isLoginPageAccess = () => {
  const userDetails = localStorage.getItem('user') as string;
  const user = JSON.parse(userDetails);
  const location = window.location.pathname == '/login' || window.location.pathname == '/forgot-password' || window.location.pathname.includes('/root-user')
    || window.location.pathname === '/eula' || window.location.pathname === '/eula-reject' || window.location.pathname === '/health';
  return (user && user.authenticated && location)
}

export const AuthProvider = ({ children }: Props) => {
  const [authDetails, setAuthDetails] = React.useState(defaultUserDetails);
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [authURLInvoked, setAuthURLInvoked] = React.useState(false);
  const [authURLResponse, setAuthURLResponse] = React.useState<AuthURLResponseType | null>(null);
  const history = useHistory();
  useEffect(() => {
    history.listen((listener: any) => {
      if (!isLoggedIn()) {
        history.push('/login');
      }
    });
  }, []);


  useEffect(() => {

    const callAuthUrl = async () => {
      try {
        const response = await Api.get("/sso/get_sso_redirect_url");
        let { message = '', sso_redirect_url = '' } = response?.data || {};
        //sso_redirect_url = 'https://google.com';
        setAuthURLInvoked(true);
        setAuthURLResponse({ message, sso_redirect_url });
        console.log(message);
      }
      catch (error) {
        setAuthURLInvoked(true);
        setAuthURLResponse({ message: 'Error occured in calling authURL api', sso_redirect_url: '' });
        console.log(error);
      }

    };

    try {
      callAuthUrl();
    } catch (err) {
      setAuthURLInvoked(true);
      setAuthURLResponse({ message: 'Error occured in calling authURL api', sso_redirect_url: '' });
      console.log(err);
    }


    const data = window.localStorage.getItem('user')
    if (data !== null) {

      const user = JSON.parse(data)
      if (typeof (user.token) != 'undefined') {
        Api.get('/authorize')
          .then((res: { data: any }) => {
            const user = {
              name: res.data.name,
              email: res.data.email,
              token: res.data.token,
              role: res.data.role,
              permissions: res.data.permissions,
              authenticated: true,
              sessionindex: res.data.sessionindex,
              tenantName:res.data.tenant_name
            }
            setAuthDetails(user)
            setLoading(false);
          })
          .catch((error: any) => {
            window.localStorage.removeItem('user')
            const user = {
              name: "",
              email: "",
              token: "",
              role: "",
              permissions: {},
              authenticated: false,
              sessionindex: false,
              tenantName:''
            }
            setAuthDetails(user)
            setLoading(false)
            window.location.href = '/login'
            // setRedirect(true)
          })
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  if (redirect) return <Redirect to='/login' />

  if (!isLoggedIn()) {
    const redirectURL = window.location.pathname + window.location.search;
    localStorage.setItem('redirectURL', redirectURL.toString());
    return <Redirect to='/login' />
  } else if (isLoginPageAccess()) {
    return <Redirect to='/' />
  }
  else if (loading || !authURLInvoked || authURLResponse === null) return null;
  else {
    return (
      <AuthContext.Provider value={{ authDetails, setAuthDetails, authURLInvoked, authURLResponse }}>
        {children}
      </AuthContext.Provider>
    );
  }
};

export const useAuthDetails = () => React.useContext(AuthContext);