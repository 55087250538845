import React from 'react';

export const SuspectedPasswordSprayView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    A directory/IdP will be marked as under password spray
                    attack when number of accounts with wrong / bad passwords in
                    an hour is more than{' '}
                    <strong>
                        {config?.dir_params.dir_bad_password_nuser_curhour}
                    </strong>
                </span>
            </li>
        </>
    );
};
