export const GlobalConsts = {
    DOC_LINK: `https://docs.authmind.com`,
    REMEDIATION_LINK: 'https://docs.authmind.com/remediation-best-practices/Working-version',
    DOC_TOKEN: `t=ugHs-iLNlb_WjZs4yBJDyg`,
    PLAYBOOK_LINK: `https://docs.authmind.com/authmind-playbooks-guide/Working-version`,
    DEPLOYMENT_LINK: `https://docs.authmind.com/authmind-deployment-guide/Working-version`,
    RELEASE_LINK: `https://docs.authmind.com/release-notes/Working-version`,
    ADMIN_GUIDE: `https://docs.authmind.com/authmind-administrators-guide/Working-version`,
    RISK: {
        'critical': 'Critical',
        'high': 'High',
        'low': 'Low',
        'medium': 'Medium'
    }
}