import React from "react";
import Popup from "reactjs-popup";
import purify from 'dompurify';

export enum IAMConfirmPopUpButtonType {
    PRIMARY,
    SECONDARY
}

export interface IAMConfirmPopUpButton {
    id: string,
    label: string,
    type: IAMConfirmPopUpButtonType
}

export interface IAMConfirmPopUp {
    confirmClose?(): any,
    title: string,
    content: string,
    buttons: IAMConfirmPopUpButton[],
    onButtonClick(...args: any): any
}

export const PostureExceptionConfirmPopUp = ({ content, title, confirmClose, buttons, onButtonClick }: IAMConfirmPopUp) => {

    return <>
        <Popup
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            onClose={confirmClose}
            modal
        >
            <div className="modal">
                <div className="close" onClick={confirmClose}></div>
                <div className="header">{title}</div>
                <div className="content">
                    <div className="font16 margintop20 marginbottom20" dangerouslySetInnerHTML={{ __html: purify.sanitize(content) }} />
                </div>
                <div className="popup_footer">
                    {
                        buttons.map(({ id, label, type }: IAMConfirmPopUpButton) => {
                            return <button style={{ float: 'right', marginLeft: '5px' }} key={id} id={id} type="button"
                                data-buttontype={type.toString()}
                                className={(type == IAMConfirmPopUpButtonType.SECONDARY ? 'button_gray ' : 'button_main ') + " width25per margintop10"}
                                onClick={onButtonClick}>{label}</button>
                        })
                    }
                </div>
            </div>
        </Popup>
    </>
}