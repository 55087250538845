import moment from 'moment';
import { CategoriesFields } from '../../../constants/Categories';
import { notSupportedKeys, supportedOperators } from '../../../pages/issue_prev/issues/constant/issueTemplate';
import { convertToCommaValue, parseFilterInput } from '../../../utils/util-methods';
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from '../../TimeBasedSearch/Constants';

export const getValueOnType = (type, value, removeQuotes = false) => {
	if (value) {
		let val = null,
			list = null;
		switch (type) {
			case 'text':
			case 'select':
			case 'datetime':
				return value;
			case 'array-autocomplete':
			case 'multi-select':
				if (Array.isArray(value)) {
					return value;
				}
				val = String(value);
				list = val.split(',');
				return list;
			case 'number':
			case 'number-array':
				return value;
			case 'number-range':
			case 'time-number':
				if (Array.isArray(value)) {
					return value;
				}
				val = String(value);
				list = val.split(',');
				return list;
			case 'datetime-range':
				if (Array.isArray(value)) {
					return value;
				}
				val = String(value);
				list = val.split(',');
				return list;
			case 'text-array':
				if (Array.isArray(value)) {
					return value;
				}
				val = String(value);
				list = parseFilterInput(value, removeQuotes);
				return list;
			default:
				return value;
		}
		// return value;
	}
	switch (type) {
		case 'text':
		case 'text-array':
		case 'number':
		case 'number-array':
		case 'datetime':
		case 'select':
			return '';
		case 'number-range':
		case 'multi-select':
		case 'array-autocomplete':
		case 'time-number':
			return [];
		default:
			return [];
	}
};

export const getSearchFieldObject = (type, value, removeQuotes) => {
	try {
		if (value) {
			let val = null,
				list = null;
			switch (type) {
				case 'number-array':
				case 'number-range':
					if (Array.isArray(value)) {
						return value;
					}
					val = String(value);
					list = val.split(',');
					return list.map((p) => {
						if (p.includes('-'))
							return p;
						return parseInt(p);
					});
				case 'time-number':
					if (Array.isArray(value)) {
						return value;
					}
					val = String(value);
					list = val.split(',');
					return list.map((p) => {
						if (parseInt(p))
							return parseInt(p);
						return p;
					});
				case 'text':
					return String(value);
				case 'number':
					if (value.includes('-')) {
						return value;
					}
					return parseInt(value);
				case 'datetime':
					return parseInt(value);
				case 'array-autocomplete':
				case 'multi-select':
					if (Array.isArray(value)) {
						return value;
					}
					val = String(value);
					list = val.split(',');
					return list;
				case 'datetime-range':
					if (Array.isArray(value)) {
						return value;
					}
					val = String(value);
					list = val.split(',');
					return list.map((p) => parseInt(p));
				case 'text-array':
					if (Array.isArray(value)) {
						return value;
					}
					val = String(value);
					list = parseFilterInput(val, removeQuotes);
					return list;
				case 'bool':
					try {
						if (
							value?.toLowerCase()?.trim() === 'true' ||
							value?.toLowerCase()?.trim() === '1'
						) {
							return true;
						}
						return false;
					} catch (error) {
						return false;
					}
				default:
					return value;
			}
			// return value;
		}
	} catch (error) {
		console.log(error);
		switch (type) {
			case 'text':
			case 'select':
			case 'number':
				return '';
			case 'datetime':
				return 0;
			case 'number-array':
			case 'number-range':
			case 'multi-select':
			case 'date-range':
				return [];
			default:
				return [];
		}
	}
};

export const getControlType = (options, search_key, operatorId = '') => {
	if (search_key === '') {
		return '';
	}
	const opt = options.find((p) => p?.search_key === search_key);
	if (operatorId === '') {
		return opt?.operators ? opt?.operators[0]?.valueType : undefined;
	}
	const operator = opt?.operators?.find((p) => p?.id === operatorId);
	return operator?.valueType || 'text';
};

export const getControlTypeforTextBox = (options, search_key) => {
	if (search_key === '') {
		return null;
	}
	return options.find((p) => p.search_key === search_key)?.valueType || null;
};

export const getControlValues = (options, search_key) => {
	if (search_key === '') {
		return [];
	}
	return options.find((p) => p.search_key === search_key)?.optionValues || [];
};
export const getCasingForControl = (options, search_key) => {
	if (search_key === '') {
		return [];
	}
	return options.find((p) => p.search_key === search_key)?.casing || '';
};

export const getControlValidations = (options, search_key, filterSearchKey) => {
	if (search_key === '') {
		return [];
	}
	const opt = options.find((p) => p.search_key === filterSearchKey);
	if (opt && opt['operators']) {
		const operatorObj = opt['operators'].find((p) => p.id === search_key);
		if (operatorObj && operatorObj['validations']) {
			return operatorObj['validations'] || opt?.validations || [];
		}
	}
	return opt?.validations || [];
};

export const getControlOperators = (options, search_key) => {
	if (search_key === '') {
		return [];
	}
	const opt = options.find((p) => p.search_key === search_key);
	return opt?.operators || [];
};

export const getControlPlaceholderText = (options, search_key, filterSearchKey) => {
	if (search_key === '') {
		return '';
	}
	const opt = options.find((p) => p.search_key === filterSearchKey);
	if (opt && opt['placeholder']) {
		const operatorObj = opt['operators'].find((p) => p.id === search_key);
		if (operatorObj && operatorObj['placeholder']) {
			return operatorObj['placeholder'] || opt?.placeholder || '';
		}
	}
	return opt?.placeholder || '';
};

export const getFilterObjFromTag = (tag, searchKeys = []) => {
	try {
		let key, operator, value;
		if (tag.includes("<=")) {
			let [k, o, ...v] = tag?.split(/(<=)/);
			key = k;
			operator = o;
			value = v;
		} else if (tag.includes(">=")) {
			let [k, o, ...v] = tag?.split(/(>=)/);
			key = k;
			operator = o;
			value = v;
		} else if (tag.includes("!=")) {
			let [k, o, ...v] = tag?.split(/(!=)/);
			key = k;
			operator = o;
			value = v;
		} else if (tag.includes("last_in")) {
			let [k, o, ...v] = tag?.split(/(last_in)/);
			key = k;
			operator = o;
			value = v;
		} else if (tag.includes("!~")) {
			let [k, o, ...v] = tag?.split(/(!~)/);
			key = k;
			operator = o;
			value = v;
		} else {
			let [k, o, ...v] = tag?.split(/(?=(?:(?:[^"]*"){2})*[^"]*$)([:~|^=<>])/);
			key = k;
			operator = o;
			value = v;
		}

		if (Array.isArray(value)) {
			value = value.join('');
		}
		// commented older code below, validating supported keys.
		// keys i.e: status:Open (adv supported)
		//  is_external: true (textbox supported)
		// console.log({searchKeys, key, value, operator})
		if (searchKeys.indexOf(key) > -1) {
			switch (operator) {
				case '~':
					return [key, value, 'matches'];
				case '!~':
					return [key, value, 'not_matches'];
				case '|':
					return [key, value, 'between'];
				case '^':
					return [key, value, 'not_equals'];
				case '=':
					return [key, value, '='];
				case '!=':
					return [key, value, '!='];
				case '<':
					return [key, value, '<'];
				case '<=':
					return [key, value, '<='];
				case '>':
					return [key, value, '>'];
				case '>=':
					return [key, value, '>='];
				case 'last_in':
					return [key, value, 'last_in'];
				default:
					return [key, value, 'equals'];
			}
		} else {
			// any other random key. i.e tag = test:http://xyz.com
			return ['any_field', String(tag), 'matches'];
		}
	} catch (error) {
		console.log(error);
		return ['any_field', String(tag), 'matches'];
	}
};

export function camelCase (text) {
	if (text !== null && text !== undefined && text !== '') {
		const txt = text.split(' ');
		return txt
			.map(([p, ...rest]) => p.toUpperCase() + rest.join(''))
			.join(' ');
	}
	return text;
}

export const getSearchFilterObjectFromTags = (template, tags, removeQuotes) => {
	try {
		const textBoxSupportedKeys = notSupportedKeys.map((p) => p.search_key);
		const advSearchSupportedKeys = template.map(
			(p) => p.search_key
		);
		const allSearchKeys =
			advSearchSupportedKeys.concat(textBoxSupportedKeys);

		const filterObj = {};
		// console.log({tags})
		for (let tag of tags) {
			const [key, val, operator] = getFilterObjFromTag(
				tag,
				allSearchKeys
			);
			const controlType = getControlType(
				template,
				key,
				operator
			);
			const textBoxKeyValueType = getControlTypeforTextBox(
				notSupportedKeys,
				key
			);
			let controlValue = null;
			if (textBoxKeyValueType !== null) {
				controlValue = getSearchFieldObject(textBoxKeyValueType, val, removeQuotes);
			} else {
				controlValue = getSearchFieldObject(controlType, val, removeQuotes);
			}

			if (CategoriesFields.includes(key) && !isNaN(controlValue[0])) {
				filterObj[key] = {
					value: controlValue.map((i) => parseInt(i)),
					type: operator
				};
			} else if (key === 'any_field') {
				const anyFieldValue = filterObj[key]?.value;
				if (anyFieldValue && anyFieldValue?.length > 0) {
					filterObj[key]?.value?.push(...controlValue);
				} else {
					filterObj[key] = { value: controlValue, type: operator };
				}
			} else if (key === 'any_activity_time' && !AVAILABLE_PRESETS[controlValue]) {
				console.log({key, val, operator, controlValue})
				if (controlValue) {
					if (controlValue.indexOf('_H') > -1 && controlValue.indexOf(',') > -1) {
						const tempVal = controlValue.split(',');
						const tempVal1 = (tempVal[0].split('_'))[0]
						const tempVal2 = (tempVal[1].split('_'))[0]
						filterObj[key] = { value: [Number(tempVal1), Number(tempVal2)], type: operator };
					} else if (operator === "=") {
						filterObj[key] = { value: controlValue.split(',').map(Number), type: 'between' };
					} else if (operator === ">") {
							filterObj[key] = { value: controlValue.split(',').map(Number), type: 'between' };
					} else {
						filterObj[key] = { value: controlValue.split(',').map(Number), type: operator };
					}
					// Sagar to revisit
				}
			} else {
				filterObj[key] = { value: controlValue, type: operator };
			}
		}
		if (Object.keys(filterObj || {}).length > 0) {
			return filterObj;
		}
	} catch (error) {
		console.log(error);
		return {};
	}
	return {};
};

export const getFilterOperatorFromApiOperator = (operator) => {
	switch (operator) {
		case 'matches':
			return '~';
		case 'not_matches':
			return '!~';
		case 'between':
			return '|';
		case 'not_equals':
			return '^';
		case '=':
			return '=';
		case '!=':
			return '!=';
		case '<':
			return '<';
		case '<=':
			return '<=';
		case '>':
			return '>';
		case '>=':
			return '>=';
		case 'last_in':
			return 'last_in';
		default:
			return ':';
	}
};

export const getFilterTemplateBySearchKey = (template, searchKey) => {
	return template.find(item => item.search_key === searchKey);
};

export const getAdditionalResultCellValue = (record, filter, pageType) => {
	if (filter !== null && typeof record[filter['dataField'][pageType]['id']] !== 'undefined') {
		const { id: key, type: valType } = filter['dataField'][pageType];
		let innerContent;
		if (valType === 'DATE') {
			innerContent = <span>{moment(record[key]).format('MMM DD YYYY, hh:mm A')}</span>;
		} else if (valType === 'NUMBER') {
			innerContent = <span>{convertToCommaValue(record[key])}</span>;
		} else if (valType === 'PERCENT') {
			if (record[key] > 3) {
				innerContent = <span>{record[key].toFixed(1)}%</span>;
			} else if (record[key] > 0 && record[key] < 0.5) {
				innerContent = <span>{0.1}%</span>;
			} else if (record[key] <= 0) {
				innerContent = <span>0%</span>;
			} else {
				innerContent = <span>{Math.round(record[key]).toFixed(1) + '%'}</span>;
			}
		}

		return <div style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 180 : 90, margin: "0 auto" }}>
			{innerContent}
		</div>;
	} else {
		return <div style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 180 : 90, margin: "0 auto" }}>
			NA
		</div>;
	}
};


export const getTagsForSearchInput = (tags) => {
	let res = [];
	tags.forEach(tag => {
		let isSupported = true;
		notSupportedKeys.forEach(ns => {
			if (tag.startsWith(ns['search_key'])) {
				isSupported = false;
			}
		});
		if (isSupported)
			res.push(tag);
	});
	return res;
};

export const getTagsForTimeBasedSearch = (tags) => {
	let res = [];
	tags.forEach(tag => {
		if (tag.startsWith(TIME_BASED_SEARCH_FIELD)) {
			res.push(getFilterObjFromTag(tag, [TIME_BASED_SEARCH_FIELD]));
		}
	});
	return res;
};

export const getTimeBasedSearchQueryParam = (searchTemplate, tags) => {
	let result = ``;
	let data = getSearchFilterObjectFromTags(searchTemplate, tags);
	if (data.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
		let filterValue = data[TIME_BASED_SEARCH_FIELD]?.value;
		let opr =
			supportedOperators.get(String(data[TIME_BASED_SEARCH_FIELD]?.type)) ||
			'';
		result = `${TIME_BASED_SEARCH_FIELD}${opr}${filterValue}`;
	}
	return result;
};
