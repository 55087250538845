import React from "react";
import './PostureActionMenu.css';
import { usePostureAction } from "../Hooks/PostureAction";

export interface PostureMenuItem {
    action: PostureMenuItemAction,
    clickHandler?(item: any, callbackFn?: any): any,
    label: string,
    id: string
}

export enum PostureMenuItemAction {
    ADD_EXCEPTION = 'ADD_EXCEPTION',
    CLOSE_INCIDENT = 'CLOSE_INCIDENT',
    MARK_KNOWN = 'MARK_KNOWN',
    ADD_EX_COPWD_USR = 'ADD_EX_COPWD_USR',   // Identities page main column action
    ADD_EX_SHDW_ACC_ASS = 'ADD_EX_SHDW_ACC_ASS', //this should add an exception for the asset and Shadow Asset & Shadow Access rules.
    ADD_KNOWN_PUBLIC = 'ADD_KNOWN_PUBLIC',
    KNOWN_APP = 'known_app',
    KNOWN_DIR = 'known_dir',
    KNOWN_INTER = 'known_inter',
    KNOWN_MFA = 'known_mfa'
}

export interface IPostureActionMenu {
    menuItems: PostureMenuItem[],
    parentClickHandler?(...args: any): any,
    actionData: any,
    keyName?: string,
    showId?: string,
    reference?: any
}

export const PostureActionMenu = ({ showId = '', menuItems, parentClickHandler, actionData, keyName = '', reference }: IPostureActionMenu) => {
    const { isAuthorizedAction } = usePostureAction();
    const onParentClick = (e: any) => {
        if (parentClickHandler) {
            parentClickHandler(e);
        }
    }

    const getDisabled = (item: PostureMenuItem) => {
        let actions = sessionStorage.getItem('actions') as any;
        if (actions) {
            actions = JSON.parse(actions);
            const key = keyName + '-' + item.label;
            if (actions[key as keyof typeof actions]) {
                const time = actions[key as keyof typeof actions];
                if (time > Date.now()) {
                    console.log(time + 600000)
                    return 'disableItems';
                } else
                    return '';
            }
        }
        return '';
    }

    const onHover = (e: any) => {
        if (reference && reference.current && reference.current.style) {
            reference.current.style.visibility = 'visible';
        }
    }

    const onLeave = (e: any) => {
        if (reference && reference.current && reference.current.style) {
            reference.current.style.visibility = '';
        }
    }

    const renderMenuItems = () => {
        return <ul onClick={onParentClick} onMouseOver={onHover} data-show-id={showId} onMouseLeave={onLeave}>
            {
                menuItems.map((item: PostureMenuItem) => {
                    const isAllowed = isAuthorizedAction(item?.action?.toString());
                    if (isAllowed) {
                        return <li key={item.label} data-action={item.action.toString()}
                            data-action-id={item.id}
                            data-action-label={item.label}
                            onClick={() => item.clickHandler ? item?.clickHandler(actionData) : null} className={getDisabled(item)}>{item.label}</li>
                    }
                    return undefined
                }).filter((i: any) => i)
            }
        </ul>
    }

    return <>
        <div>
            {
                renderMenuItems()
            }

        </div>
    </>
}