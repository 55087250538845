import React, { useState, useEffect } from 'react'
import {
  withQueryParams,
  StringParam,
  NumberParam,
  ArrayParam,
  withDefault,
} from 'use-query-params'
import {Api} from '../components/Axios'
import ReactPaginate from 'react-paginate'
import Moment from 'moment'
import SubHeader from '../components/Layout/SubHeader'
import purify from 'dompurify';

// import './FlowsIssues.css'

type Search = {
  query?: string;
}

interface Params {
  rpp?: number;
  sort_by?: string;
  order_by?: string;
  q?: string;
  page?: number;
}

type Source = {
  'created_date'?:Date;
  params2: {
    SourceIp: [];
    SourcePort: number;
    UserEmail: string;
    UserFullName: string;
    UserName: string;
  },
  param5: {
    issues: [];
    issues_severity: [];
  }
}

type Listing = {
  _id: string;
  _source: Source;
}

type Hits = {
  Hits: Listing[];
}

type Total = {
  value: number;
}

type Result = {
  hits: Listing[];
  total: Total;
}

const FlowsIssues = ({query, setQuery}: any) => {
  const {q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
  const [result, setResult] = useState<Result>()

  let p: Params = {
    q: q,
    page: page_number,
    rpp: record_per_page,
    order_by: order,
    sort_by: sort
  }

  useEffect(() => {
      Listing()
  }, [query])

  const Listing = () => {
    Api.get('/l-flows', {
        params: p
    })
    .then((res: { data: any }) => {
        setResult(res.data.hits)
    })
    .catch((error: any) => {
    })
  }

  const Severity = (severity: number) => {
    if(severity === 4) {
        return "issue_pink"
    } else if(severity === 3) {
        return "issue_orange"
    } else if(severity === 2) {
        return "issue_yellow"
    } else if(severity === 1) {
        return "issue_darkgray"
    }
}

  const FormatDate = (dte:any) => {
    Moment.locale('en')
    return (
        <>
            {Moment(dte).format('DD MMM, YYYY (hh:mm A)')}
        </>
    )
  }

  const Paginate = (selectedPage: any) => {
    p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
    setQuery(p)
  }

  return (
    <>
      <SubHeader title="Flows">
      <form>
          <input type="text" name="query" className="page_search" placeholder="" />
      </form>
      </SubHeader>

      <div className="clrBoth"></div>
        
      <div className="margintop20">
          <table className="flow_table">
              <thead>
              <tr>
                    <th colSpan={0} className="empty_cell">&nbsp;</th>
                    <th colSpan={0} className="empty_cell">&nbsp;</th>
                    <th colSpan={4} className="empty_cell border_bt_white right_border">Identity</th>
                    <th colSpan={4} className="empty_cell border_bt_white right_border">Asset</th>
                    <th colSpan={0} className="empty_cell">&nbsp;</th>
                    <th colSpan={0} className="empty_cell">&nbsp;</th>
                </tr>

                <tr>
                  <th className="" style={{width: '150px'}}>Date</th>
                  <th className="">Flow</th>
                  <th className="">Type</th>
                  <th className="">Name</th>
                  <th className="">IP</th>
                  <th className="right_border">Port</th>
                  <th className="">Type</th>
                  <th className="">Name</th>
                  <th className="">IP</th>
                  <th className="right_border">Port</th>
                  <th className="">Protocol</th>
                  <th className="">Incidents</th>
                </tr>
              </thead>

              <tbody>
              {result && result?.hits && result?.hits.length > 0 && result.hits.map((f:any) => (
                <tr>
                  <td>{FormatDate(f._source["created_date"])}</td>
                  <td>32762</td>

                  <td>{f._source.param2.SourceType}</td>
                  <td>{f._source.param2.UserFullName}</td>
                  <td>
                    <div dangerouslySetInnerHTML={{__html: purify.sanitize(f._source.param2.SourceIp[0])}} />
                  </td>
                  <td>{f._source.param2.SourcePort}</td>

                  <td>{f._source.param3.DestinationType}</td>
                  <td>{f._source.param3.DestinationName}</td>
                  <td>
                    <div dangerouslySetInnerHTML={{__html: purify.sanitize(f._source.param3.DestinationIp[0])}} />
                  </td>
                  <td>{f._source.param3.DestinationPort}</td>
                  
                  <td>{f._source.param3.Protocol}</td>
                  <td>
                    {f._source.param5.issues.map((j:string, i: number) => (
                      <div className={Severity(f._source.param5.issues_severity[i])}>{j}</div>
                    ))}

                    {f._source.param5.issues.length === 0 &&
                      <div className="issue_white">None</div>
                    }
                  </td>
                </tr>
              ))}

              

              {result && result?.total.value === 0 &&
                <tr>
                  <td colSpan={12}>No record found.</td>
                </tr>
              }

              {!result &&
                <tr>
                  <td colSpan={12}>Processing.</td>
                </tr>
              }
              </tbody>
            </table>
            {result && result.total.value > 0 &&
                <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={(Math.ceil(result.total.value / 20))}
                    onPageChange={Paginate}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
              }
      </div>
    
    </>
  )
}

export default withQueryParams({
  q: StringParam,
  page: StringParam,
  rpp: NumberParam,
  sort_by: StringParam,
  order_by: StringParam,
  filters: withDefault(ArrayParam, [])
}, FlowsIssues)