import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import { TabContext, TabPanel } from "@mui/lab";
import './AMTab.scss';

export interface TabData {
  label: string;
  Component: React.ReactNode;
  route?: string;
  selected?: boolean; // Add selected property
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: number;
}

function TabPanelContainer(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabContext value={value}>
      <TabPanel
        role="tabpanel"
        value={index}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </TabPanel>
    </TabContext>
  );
}

interface BasicTabsProps {
  tabs: TabData[];
  onTabClick: (val: string) => void;
  isPopupBased?: boolean;
}

const AMTabs: React.FC<BasicTabsProps> = ({ tabs, onTabClick, isPopupBased}) => {
  const [value, setValue] = useState(0);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!isPopupBased) {
      const index = tabs.findIndex((tab) => tab.route === location.pathname);
      if (index !== -1) {
        setValue(index);
      }
    }
  }, [tabs, location.pathname]);

  useEffect(() => {
    // Find the index of the tab with selected property true
    const selectedIndex = tabs.findIndex((tab) => tab.selected);
    if (selectedIndex !== -1) {
      setValue(selectedIndex); // Set the selected tab based on the selected property
    }
  }, []);

  const handleChangeLocal = (event: React.SyntheticEvent, newValue: number) => {
    if (onTabClick)
      onTabClick(newValue);
    setValue(newValue);
    !isPopupBased && history.push(tabs[newValue].route);
  };

  return (
    <Box sx={{ width: "100%" }} className="am-tab">
        <Tabs value={value} onChange={handleChangeLocal}>
          {tabs.map(({ label }, i) => (
            <Tab disableRipple label={label} key={i} />
          ))}
        </Tabs>
      {tabs.map(({ Component }, i) => (
        <TabPanelContainer value={value} index={i} key={i}>
          {Component}
        </TabPanelContainer>
      ))}
    </Box>
  );
};

export default AMTabs;
