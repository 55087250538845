import Popup from "reactjs-popup";
import { useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import './eula.scss';
import { Api } from "../../components/Axios";
import { usePartnerStateContext } from "../../store/PartnerContextProvider";

export const Eula = () => {
    const [isDone, setIsDone] = useState(false);
    const user = JSON.parse(localStorage.getItem('user') || '');
    const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
    const history = useHistory();
    const { PartnerConfigState } = usePartnerStateContext();
    console.log("Config in eula", PartnerConfigState)
    const onProceedClick = async () => {
        if (user && user?.eula?.token) {
            Api.defaults.headers.common['Authorization'] = 'Bearer ' + user?.eula?.token;
            const res = await Api.post(`/tenant/eula?eula_accepted=true`);
            localStorage.setItem('eula_user', JSON.stringify(res));
            setIsDone(true);
        }
    }

    const onClose = () => {
        setShowConfirmPopUp(true);
    }

    const handleConfirmClose = () => {
        setShowConfirmPopUp(false);
    }

    return <>
        {!isDone ?
            <Popup
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                open={true}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal
            >
                <div className="modal eula-modal-container">
                    <div className="close" onClick={onClose}></div>
                    <div className="header">{'Terms of Service'}</div>
                    <div className="content">
                        <div className="font16 margintop20 marginbottom20 scrollbar-container eula-content-container">
                            <span>{user?.eula?.eula_text || ''}</span>
                        </div>

                    </div>
                    <div className="popup_footer">
                        <div>
                            <span>You must accept the terms to continue using {`${PartnerConfigState.PartnerProductName}`}. You’ll be logged out if you Decline.</span>
                        </div>
                        <button style={{ float: 'right' }} type="button"
                            className={"button_main width25per "} onClick={onProceedClick}
                        >Accept</button>
                        <button style={{ display: 'inline', float: 'left', marginRight: '2%' }}
                            type="button" className="button_gray width25per margintop10"
                            onClick={onClose}>Decline</button>
                    </div>
                </div>
            </Popup> :
            <Redirect to='/' />
        }
        {showConfirmPopUp && <EulaDeclineConfirm onClose={handleConfirmClose} />}
    </>
}

export const EulaDeclinePopUp = () => {
    const history = useHistory();
    const { PartnerConfigState } = usePartnerStateContext();
    const onClose = () => {
        history.push('/login')
    }

    return <Popup
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        onClose={onClose}
        modal
    >
        <div className="modal">
            <div className="close" onClick={onClose}></div>
            <div className="header">{'Terms of Service'}</div>
            <div className="content font14">
                You'll not be able to continue because your {`${PartnerConfigState.PartnerProductName}`} administrator has not accepted the terms of service.
            </div>
            <div className="popup_footer">
                <button style={{ float: 'right' }} type="button"
                    className={"button_main width25per "} onClick={onClose}
                >Ok</button>
            </div>
        </div>
    </Popup>
}

export const EulaDeclineConfirm = ({ onClose }: any) => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user') || '');

    const onProceedClick = () => {
        if (user && user?.eula?.token) {
            Api.defaults.headers.common['Authorization'] = 'Bearer ' + user?.eula?.token;
            Api.post(`/tenant/eula?eula_accepted=false`).then((resp: any) => {
                history.push('/login');
            }).catch(() => {
                history.push('/login');
            });
        }
    }

    return <Popup
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        onClose={onClose}
        modal
    >
        <div className="modal">
            <div className="close" onClick={onClose}></div>
            <div className="header">{'Decline Terms of Service'}</div>
            <div className="content font14">
                Are you sure ?
            </div>
            <div className="popup_footer">
                <button style={{ float: 'right' }} type="button"
                    className={"button_main width25per "} onClick={onProceedClick}
                >Yes</button>
                <button style={{ display: 'inline', float: 'left', marginRight: '2%' }}
                    type="button" className="button_gray width25per margintop10"
                    onClick={onClose}>No</button>
            </div>
        </div>
    </Popup>
}