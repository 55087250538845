import { Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AddExceptionPopUp } from "../../../../common/AddExceptionPopUp/AddExceptionPopUp";
import { PostureMarkKnownPopUp } from "../../../../common/MarkKnownPopUp/PostureMarkKnownPopUp";
import { useAuthDetails } from "../../../../components/Authorization";
import { IDPostureCloseIncident } from "./IDPostureCloseIncident";
import { IPostureActionMenu, PostureActionMenu, PostureMenuItemAction } from "./PostureActionMenu";
import { IAMConfirmPopUpButtonType } from "./PostureExceptionConfirmPopUp";
import { usePostureAction } from "../Hooks/PostureAction";

export interface IBasePostureActionMenu extends IPostureActionMenu {
    getContent(): string[],
    exceptionData: any,
    classes: string,
    incContent?(): string[],
    closeIncQuery?: string,
    issueCount?: number,
    reloadList?(): any,
    config?: {
        hideMarkKnown?: boolean,
        markKnownCallback?(...args: any): any,
        isSaaSApp?: boolean
    },
    markKnownOptions?: {
        title?: string,
        options?: any
    } | undefined | null,
    keyName: string,
    showId?: string,
    isTimeBasedSearchApplied?: boolean
}

export const BasePostureActionMenu = ({ actionData, menuItems, getContent, exceptionData, classes,
    incContent, closeIncQuery, issueCount, reloadList, config, markKnownOptions, keyName, showId = '', isTimeBasedSearchApplied }: IBasePostureActionMenu) => {
    const { isAnyActionsAllowed } = usePostureAction();
    const [showExceptionConfirm, setShowExceptionConfirm] = useState(false);
    const history = useHistory();
    const [showCloseIncident, setShowCloseIncident] = useState(false);
    const [showMarkKnown, setShowMarkKnown] = useState(false);
    const [additionalContentData, setAdditionalContentData] = useState<any>(undefined);
    const [actionId, setActionId] = useState('');
    const ref = useRef(null);

    const onParentClick = (e: any) => {
        const action = e.target.dataset?.action;
        setActionId(keyName + '-' + e.target.dataset?.actionLabel);
        switch (action) {
            case PostureMenuItemAction.ADD_EXCEPTION.toString(): {
                setShowExceptionConfirm(true);
            }
                break;
            case PostureMenuItemAction.CLOSE_INCIDENT.toString(): {
                setShowCloseIncident(true);
            }
                break;
            case PostureMenuItemAction.MARK_KNOWN.toString(): {
                if (config && config.hideMarkKnown && config.markKnownCallback) {
                    config.markKnownCallback();
                } else {
                    setShowMarkKnown(true);
                }
            }
                break;
            case PostureMenuItemAction.ADD_EX_COPWD_USR.toString(): {
                const data = getContent();
                const exceptionD = exceptionData;
                if (data && data.length > 0 && exceptionData && exceptionData.length > 0) {
                    const index = data.findIndex((item: string) => item.includes('rule_name'));
                    data[index] = `rule_name:Compromised User,Compromised Password`;
                    const exceptionIndex = exceptionD.findIndex((item: string) => item.includes('rule_name'))
                    exceptionD[exceptionIndex] = `rule_name:Compromised User,Compromised Password`;
                    setAdditionalContentData(data);
                    setShowExceptionConfirm(true);
                }
            }
                break;
            case PostureMenuItemAction.ADD_EX_SHDW_ACC_ASS.toString(): {
                const data = getContent();
                const exceptionD = exceptionData;
                if (data && data.length > 0 && exceptionData && exceptionData.length > 0) {
                    const index = data.findIndex((item: string) => item.includes('rule_name'));
                    data[index] = `rule_name:Shadow Assets,Shadow Access`;
                    const exceptionIndex = exceptionD.findIndex((item: string) => item.includes('rule_name'))
                    exceptionD[exceptionIndex] = `rule_name:Shadow Assets,Shadow Access`;
                    setAdditionalContentData(data);
                    setShowExceptionConfirm(true);
                }
            }
                break;
            case PostureMenuItemAction.ADD_KNOWN_PUBLIC.toString(): {
                const result = [] as any;
                if (actionData?.host_detail) {
                    actionData?.host_detail.forEach((item: any) => {
                        if (item.ip && item.ip.length > 0 && validateIp(item.ip)) {
                            result.push(item.ip);
                        }
                        if (item.hostname && item.hostname.length > 0 && validateIp(item.hostname)) {
                            result.push(item.hostname);
                        }
                    });
                    const resultSet = Array.from(new Set(result));
                    const ob = {
                        comment: actionData._id,
                        ips: resultSet
                    }
                    sessionStorage.setItem('known_public_ip', JSON.stringify(ob));
                    history.push('/known_public_ip/add');
                }
            }
        }
    }

    const validateIp = (item: string) => {
        return !(item.split('.').length == 4 && item.slice(0, item.indexOf('.')) == '10');
    }

    const onExceptionPopUpClose = () => {
        setShowExceptionConfirm(false);
    }

    const onExceptionButtonClick = (e: any) => {
        const btnType = e.target.dataset?.buttontype;
        switch (btnType) {
            case IAMConfirmPopUpButtonType.PRIMARY.toString(): {

                localStorage.setItem('addException', JSON.stringify(exceptionData));
                history.push('/exceptions/add')
            }
                break;
            case IAMConfirmPopUpButtonType.SECONDARY.toString(): {
                setShowExceptionConfirm(false);
            }
                break;
        }
    }

    return <>
        {
            isAnyActionsAllowed(menuItems) && !isTimeBasedSearchApplied &&
            <>
                <Tooltip classes={{ tooltip: 'posture-action-menu-container' }}
                    title={<PostureActionMenu showId={'btn-' + keyName + showId} menuItems={menuItems} actionData={actionData} parentClickHandler={onParentClick} keyName={keyName} reference={ref} />}
                >
                    <button style={{ marginLeft: '300' }} className={classes} id={'btn-' + keyName + showId} ref={ref}></button>
                </Tooltip >
                {showExceptionConfirm ? <AddExceptionPopUp criteria={additionalContentData || (getContent ? getContent() : '')}
                    handleClose={onExceptionPopUpClose} handleConfirmAddException={onExceptionButtonClick}
                /> : null
                }
                {
                    showCloseIncident ?
                        <IDPostureCloseIncident content={incContent ? incContent() : []} handleCloseIDPosture={() => setShowCloseIncident(false)}
                            query={closeIncQuery || ''} issueCount={issueCount} reloadList={reloadList}
                            actionId={actionId}
                        /> : null
                }
                {
                    showMarkKnown ?
                        <PostureMarkKnownPopUp onClose={() => setShowMarkKnown(false)} data={actionData} markKnownOptions={markKnownOptions} actionId={actionId} isSaaSApp={config?.isSaaSApp} /> : null
                }
            </>
        }

    </>
}