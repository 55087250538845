import Popup from "reactjs-popup";
import React, { useState } from "react";

export const ProxyPopUp = (props: any) => {

    const closeConfirm = (evt: any) => {
        if (props.closePropxyPopUp) {
            props.closePropxyPopUp(evt);
        }
    }

    return <>
        <Popup
            {...props}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            open={props.showProxyPopUp}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            modal
            className="am-confirm-password"
        >
            <div className="modal">
                <div className="close" onClick={closeConfirm}></div>
                <div className="header">Select Proxy Configuration</div>
                <div className="content">
                    <div className="font16 margintop20 marginbottom20">{props.content}</div>
                    <span className="font16">Either IP/HostName/FQDN or SSL Configuration is necessary to set up proxy.</span>
                </div>
                <div className="popup_footer">
                    {/* <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>Cancel</button> */}
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={props.closePropxyPopUp}>Ok</button>
                </div>
            </div>
        </Popup>
    </>
}