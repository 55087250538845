import React, { useEffect, useState } from 'react';
import { isJsonString } from '../../../utils/util-methods';

export const GoogleWorkspaceCollector = ({
    register,
    setValue,
    collectorData,
    getValues,
    clearErrors,
    setError
}) => {
    const [mfaEnabledCheck, setMfaEnabledCheck] = useState(false);
    useEffect(() => {
        if (collectorData) {
            setValue(
                'customer_id',
                collectorData.collector_config?.customer_id
            );
            setValue(
                'delegate_email',
                collectorData.collector_config?.delegate_email
            );
            setValue(
                'service_acc_key',
                collectorData.collector_config?.service_acc_key
            );
            setValue(
                'mfa_enabled',
                collectorData.collector_config?.mfa_enabled
            );
            setMfaEnabledCheck(collectorData.collector_config?.mfa_enabled);
        }
    }, [collectorData]);

    const handleMfaEnabledChange = (e) => {
        e.stopPropagation();
        if (e.target.checked) {
            setMfaEnabledCheck(true);
        } else {
            setMfaEnabledCheck(false);
        }
    };

    const updateJSONFormat = () => {
		const val = getValues('service_acc_key') || '';
		if (isJsonString(val)) {
			const obj = JSON.parse(val);
			const pretty = JSON.stringify(obj, undefined, 4);
			setValue('service_acc_key', pretty);
			clearErrors('service_acc_key');
		} else {
			setError('service_acc_key', {
				type: 'custom',
				message: 'Please enter valid JSON.'
			});
		}
	};

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Google Workspace Collector Configurations
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Customer ID*</label>
                            <input
                                type='text'
                                name='customer_id'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Customer ID'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Delegate E-mail*</label>
                            <input
                                type='text'
                                name='delegate_email'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true,
                                    pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Please enter valid email.'
									}
                                })}
                                placeholder={'Enter Delegate E-mail'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Service Account Key*</label>
                            <textarea
								wrap='off'
                                style={{
                                    margin: '10px 0px',
                                    fontFamily: 'monospace',
                                    resize: 'none'
                                }}
								rows={8}
								placeholder='Enter Service Account Key (JSON format)'
								name='service_acc_key'
								ref={register({
									required: true
								})}
                                onBlur={updateJSONFormat}
                                className='flex-basis-100'
							/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sensor-flex-container'>
                <div className='sensor-flex-container-item'>
                    <input
                        name='mfa_enabled'
                        type='checkbox'
                        className={'margintop20'}
                        checked={mfaEnabledCheck}
                        onChange={handleMfaEnabledChange}
                        ref={register}
                    />
                    <label
                        className='auto_width paddingright20'
                        style={{ float: 'unset' }}
                    >
                        Performs MFA?
                    </label>
                </div>
            </div>
        </div>
    );
};
