import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useToasts } from "../../../components/core";
import { Api } from "../../../components/Axios";

import { PasswordStrengthMeter } from "../../../components/PasswordStrengthMeter";
import "./reset-password.scss";

type User = {
    password?: string;
    confirm_password?: string;
};

interface Props {
    id?: string;
    AfterUserOperation?: any;
}

export const ResetPassword = ({ id, AfterUserOperation }: Props) => {
    const { register, handleSubmit, errors, watch, setError } = useForm<User>();
    const [sErrors, setSErrors] = useState<User>();
    const [loading, setLoading] = useState<Boolean>(false);
    const [passWd, setPassWd] = useState("");
    const { addToast } = useToasts();

    const updatePassword = (data: any) => {
        if (data.password !== data.confirm_password) {
            setError('confirm_password', { type: 'custom', message: "Password do not match" } )
            return;
        }
        setLoading(true);
        const headers = { Operation: "PUT" };
        Api.post("/user/" + id + "?type=s", data, { headers: headers })
            .then((res: { data: any }) => {
                setLoading(false);
                AfterUserOperation("edit");
                addToast("Password updated successfully.", {
                    appearance: "success",
                    autoDismiss: true,
                });
            })
            .catch((error: any) => {
                setLoading(false);
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                } else if (error.response.status === 419) {
                    addToast(error.response.data, {
                        appearance: "error",
                        autoDismiss: true,
                        hideDuration: 3000,
                    });
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                } else if (error.response.status === 403) {
                    addToast(error.response.data, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            });
    };

    useEffect(() => {
    }, [setError])

    const passwordChanged = (e: any) => {
        setPassWd(e.target.value);
    };

    return (
        <form onSubmit={handleSubmit(updatePassword)}>
            <div className="user-reset-password-form-container">
                <div className="form-control-group-left">
                    <div className="form-control">
                        <label>New Password</label>
                        <input
                            type="password"
                            placeholder="Enter new password"
                            name="password"
                            ref={register({
                                required: true,
                                minLength: { value: 12, message: 'Passwords minimum length is 12' }
                            })}
                            className={errors.password ? "error" : ""}
                            onChange={(e) => {
                                passwordChanged(e);
                            }}
                        />
                        <PasswordStrengthMeter password={passWd} />
                    </div>
                </div>
                <div className="form-control-group-right">
                    <div className="form-control">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            placeholder="Confirm your password"
                            name="confirm_password"
                            ref={register({
                                required: true,
                                validate: (value) => value === watch('password'),
                            })}
                            className={errors.confirm_password ? "error" : ""}
                        />
                    </div>
                </div>
            </div>

            {Object.keys(errors || {}).length > 0 && (
                <div className="user-reset-password-errors-list-container">
                    {errors.password && errors.password.type === "required" && <div className="error dot marginleft40per">Please enter password.</div>}
                    {errors.password && errors.password.type !== "required" && <div className={`error marginleft40per ${errors.password.message && "dot"}`}>{errors.password.message}</div>}
                    {!errors.password && sErrors?.password !== "" && <div className={`error marginleft40per ${sErrors?.password && "dot"}`}>{sErrors?.password}</div>}

                    {errors.confirm_password && errors.confirm_password.type === "required" && <div className="error dot marginleft40per">Please repeat password.</div>}
                    {errors.confirm_password && errors.confirm_password.type !== "required" && <div className={`error marginleft40per ${errors.confirm_password.message && "dot"}`}>{errors.confirm_password.message}</div>}
                    {errors.confirm_password && errors.confirm_password.type !== "required" && <div className={`error dot marginleft40per ${errors.confirm_password.message && 'dot'}`}>Passwords do not match.</div>}
                    {!errors.confirm_password && sErrors?.confirm_password !== "" && <div className={`error marginleft40per ${sErrors?.confirm_password && "dot"}`}>{sErrors?.confirm_password}</div>}
                </div>
            )}

            <div className="user-reset-password-action-buttons">
                <button type={loading ? "button" : "submit"} className={`float_right ${loading ? "loader" : "reset-password-submit"}`}>
                    Save
                </button>
            </div>
        </form>
    );
};
