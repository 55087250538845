import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { IdAssetWidget } from '../../../types/response-types';
import { getRoundedUnit } from '../../../utils/util-methods';

type Props={
	issues: IdAssetWidget | undefined
}

export const SummaryWidget = ({issues}:Props) => {
    let history = useHistory();
	
	return (
        <>
            <div className='summary_widget'>
                <div className='summary_widget_box'>
                    <label>IDENTITIES</label>
                    <div className='summary_details'>
                        <div className='gray_color'>
                            <div className='summary_count_header'>
                                {getRoundedUnit(issues?.identity_count)}
                            </div>
                        </div>
                        <div className='red_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.identity_rules_summary.rules_count
                                )}
                            </h4>
                            <p>Issues</p>
                        </div>

                        <div className='gray_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.identity_count_with_issues
                                )}
                            </h4>
                            <p>with incidents</p>
                        </div>
                    </div>
                </div>
                <div className='summary_widget_box'>
                    <label>ASSETS</label>
                    <div className='summary_details'>
                        <div className='gray_color'>
                            <div className='summary_count_header'>
                                {getRoundedUnit(issues?.asset_count)}
                            </div>
                        </div>
                        <div className='red_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.asset_rules_summary.rules_count
                                )}
                            </h4>
                            <p>Issues</p>
                        </div>
                        <div className='gray_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.asset_count_with_issues
                                )}
                            </h4>
                            <p>with incidents</p>
                        </div>
                    </div>
                </div>
                <div className='summary_widget_box'>
                    <label>ACCESSES</label>
                    <div className='summary_details'>
                        <div className='gray_color'>
                            <div className='summary_count_header'>
                                {getRoundedUnit(issues?.access_count)}
                            </div>
                        </div>
                        <div className='gray_color'>
                            <h4>
                                {issues?.access_rules_summary !== undefined
                                    ? getRoundedUnit(
                                          issues?.access_rules_summary
                                              .rules_count
                                      )
                                    : 0}
                            </h4>
                            <p>Issues</p>
                        </div>
                        <div className='gray_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.access_count_with_issues
                                )}
                            </h4>
                            <p>with incidents</p>
                        </div>
                    </div>
                </div>
                <div className='summary_widget_box'>
                    <label>IDENTITY SYSTEMS</label>
                    <div className='summary_details'>
                        <div className='gray_color'>
                            <div className='summary_count_header'>
                                {getRoundedUnit(issues?.directory_count)}
                            </div>
                        </div>
                        <div className='red_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.directory_rules_summary.rules_count
                                )}
                            </h4>
                            <p>Issues</p>
                        </div>
                        <div className='gray_color'>
                            <h4>
                                {getRoundedUnit(
                                    issues?.directory_count_with_issues
                                )}
                            </h4>
                            <p>with incidents</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}