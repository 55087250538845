import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import RCG from 'react-captcha-generator';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import './AMCaptcha.css'
export const AMCaptcha = forwardRef((props: any, ref: any) => {

    useImperativeHandle(ref, () => ({
        onValidateCaptcha() {
            return validateCaptcha(enteredText);
        }
    }));

    const [t, setT] = useState(true);
    const [captchaText, setCaptchaText] = useState();
    const [enteredText, setEnteredText] = useState<any>();
    const [errors, setErrors] = useState<any>({ message: 'invalid' });
    const result = (txt: any) => {
        setCaptchaText(txt);
    };

    const toggle = () => {
        setT(false);
        setTimeout(() => setT(true));
        setEnteredText('');
        setErrors({});
    };

    const onInputBlur = () => {
        validateCaptcha(enteredText);
    }

    const onInputChange = (evt: any) => {
        setEnteredText(evt.target.value);
        validateCaptcha(evt.target.value)
    }

    const validateCaptcha = (text: any) => {
        if (!text || text.length == 0) {
            setErrors({ message: 'Please enter captcha.' });
            return false;
        } else if (text && text != captchaText) {
            setErrors({ message: 'Entered text and captcha text does not match.' });
            return false;
        } else {
            setErrors({});
            return true;
        }
    }

    return (
        <>
            {
                t ? (
                    <div className='rcg-container'>
                        <RCG
                            result={result} // Callback function with code
                        />
                        <div>
                            <input type='text' placeholder='Enter captcha here' value={enteredText} onBlur={onInputBlur} onChange={onInputChange} />
                            <span title='Reset Captcha'><RestartAltIcon fontSize='large' onClick={toggle} /></span>
                        </div>
                        {errors && errors?.message && errors?.message != 'invalid' && <div className="error">{errors.message}</div>}
                    </div>
                ) : <div className='rcg-container empty'></div>
            }
        </>
    );
});