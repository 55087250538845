import React, { createContext, useState, useContext } from "react";

interface DashboardStateValues {
    UnknownAccessState: {
        selectedOption: number | undefined;
        setSelectedOption: (id: number | undefined) => void;
    };
    TopRiskyEntitiesState:{
        selectedOption: string;
        setSelectedOption: (value: string) => void;
    };
    IncidentTrendState:{
        scrollValue: number;
        setScrollValue: (id: number) => void;
    }
}

const DashboardStateContext = createContext<DashboardStateValues | undefined>(undefined);

export const DashboardStateContextProvider: React.FC = ({ children }) => {
    //Unknown Access State
    const [selectedUnknownAccessOptionState, setSelectedUnknownAccessOptionState] = useState<number | undefined>(undefined);
    const setUnknownAccessOption = (id: number | undefined) => {
        setSelectedUnknownAccessOptionState(id);
    };

    //Top Risky Entities State
    const [selectedRiskyEntitiesOptionState, setSelectedRiskyEntitiesOptionState] = useState('top new risky entities (30d)');
    const setRiskyEntitiesOption = (value: string) => {
        setSelectedRiskyEntitiesOptionState(value);
    };

    //Incident Trend State
    const [incidentTrendScrollState, setIncidentTrendScrollState] = useState(0);
    const setIncidentTrendScroll = (id: number) => {
        console.log(id);
        setIncidentTrendScrollState(id);
    };
    
    const contextValue: DashboardStateValues = {
        UnknownAccessState: { selectedOption: selectedUnknownAccessOptionState, setSelectedOption: setUnknownAccessOption },
        TopRiskyEntitiesState: { selectedOption: selectedRiskyEntitiesOptionState, setSelectedOption: setRiskyEntitiesOption },
        IncidentTrendState: {scrollValue:incidentTrendScrollState, setScrollValue:setIncidentTrendScroll}
    };

    return <DashboardStateContext.Provider value={contextValue}>{children}</DashboardStateContext.Provider>;
};


export const useDashboardStateContext = (): DashboardStateValues => {
    const context = useContext(DashboardStateContext);
    if (!context) {
        throw new Error("useDashboardStateContext must be used within a DashboardStateContextProvider");
    }
    return context;
};
