import React from "react";
import { convertToCommaValue, getRoundedUnit } from "../../../utils/util-methods";
import './chart-trend.css';

type Props = {
  iconClass: string | '';
  textClass: string | '';
  markerValue: number | 0;
  last_7days_created: number | 0;
  trendClickHandler: Function | undefined;
  trendClass?: string | '';
};

const ChartTrend = ({ iconClass, textClass, markerValue, last_7days_created, trendClickHandler, trendClass }: Props) => {
  const clickHandler = () => (trendClickHandler) ? trendClickHandler() : null;
  return (
    <div onClick={clickHandler} className={"chart_trend " + trendClass}>
      <span>
        <span className={`major-arrow ${iconClass}`}></span>
        <span className={`risk-content-text ${textClass}`} title={`${convertToCommaValue(markerValue)}`}>
          {getRoundedUnit(markerValue)}
        </span>
      </span>
      <span className="risk-content-text grey-text" title={`${convertToCommaValue(last_7days_created)}`}>
        {" "}
        {getRoundedUnit(last_7days_created)} new
      </span>
    </div>
  );
};

export default React.memo(ChartTrend);
