import React from 'react'
import './Login.css'
const BeforeLoginContainer = (props:any) => {
    return (
        <div className="login_bg">
            <div className="form_container">
             {props.children}
            </div>
        </div>
    )
}

export default BeforeLoginContainer