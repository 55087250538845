import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';
import {Api} from '../../../components/Axios'

type SslCertificate = {
    name?: string;
    private_key?: any;
    public_key?: any;
    chain?: any;
}

interface Props {
    id?: string;
    AfterEditOperation?: any;
}

export const EditPanel = ({id, AfterEditOperation}: Props) => {
    const { register, handleSubmit, errors, setValue } = useForm<SslCertificate>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [sslCertificate, setsslCertificate] = useState<SslCertificate>()
    const { addToast } = useToasts()

    useEffect(() => {
        Api.get('/cert')
        .then((res: { data: any }) => {
            setValue("private_key", res.data.private_key)
            setValue("public_key", res.data.public_key)
            setValue("chain", res.data.chain)
        })
        .catch((error: any) => {
        })
    }, [])

    const onUpdateSsl = (data: any) => {
        setLoading(true)
        Api.post('/cert', data)
        .then((res: { data: any }) => {
            setLoading(false)
            AfterEditOperation('edit')
            addToast("Certificate updated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onUpdateSsl)}>
            <label>Private Key : </label>
            <textarea
                placeholder="Enter Private Key in PEM-encoded format"
                rows={6}
                name="private_key"
                ref={register({
                    required: true,
                })}
                className={(errors.private_key ? "error" : "")}
                defaultValue={sslCertificate?.private_key}
            />
            {errors.private_key && errors.private_key.type === 'required' && <div className="error marginleft40per">Please enter Private Key.</div>}
            {errors.private_key && errors.private_key.type !== 'required' && <div className="error marginleft40per">{errors.private_key.message}</div>}

            <label>Public Key : </label>
            <textarea
                placeholder="Enter Public Key in PEM-encoded format"
                rows={6}
                name="public_key"
                ref={register({
                    required: true,
                })}
                className={(errors.public_key ? "error" : "")}
                defaultValue={sslCertificate?.public_key}
            />
            {errors.public_key && errors.public_key.type === 'required' && <div className="error marginleft40per">Please enter public_key.</div>}
            {errors.public_key && errors.public_key.type !== 'required' && <div className="error marginleft40per">{errors.public_key.message}</div>}

            <label>Certificate Chain : </label>
            <textarea
                placeholder="Enter Certificate Chain in PEM-encoded format"
                rows={6}
                name="chain"
                ref={register}
                className={(errors.chain ? "error" : "")}
                defaultValue={sslCertificate?.chain}
            />
            {errors.chain && errors.chain.type === 'required' && <div className="error marginleft40per">Please enter Certificate Chain.</div>}
            {errors.chain && errors.chain.type !== 'required' && <div className="error marginleft40per">{errors.chain.message}</div>}
            
            <div className="footer_line"></div>
            <div className="pane_footer">
                <div className="launch_button">
                    <button type={(loading ? 'button' : 'submit')} className={"button_main " + (loading ? 'loader' : 'button_styled')}>Save</button>
                </div>
            </div>
        </form>
    )
}