import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useToasts } from '../../../components/core';
import Popup from 'reactjs-popup';
import { Api } from '../../../components/Axios';

type Props = {
    updatePlaybooksData: () => void
}

const DeletePlaybook = ({ updatePlaybooksData }: Props) => {

    let history = useHistory();
    let { id } = useParams<any>();
    let { pb_name } = useParams<any>();
    let { addToast } = useToasts();
    const [closeTicket, setCloseTicket] = useState(false);

    const handleClose = () => {
        history.goBack();
    }

    const handleYes = () => {
        const headers = { 'Operation': 'DELETE' }
        Api.post(`/playbooks/${id}`, {
            close_ticket: closeTicket
        }, { headers: headers })
            .then(res => {
                if (res.status === 200) {
                    addToast('Playbook deleted successfully', {
                        autoDismiss: true,
                        appearance: 'success'
                    });
                    updatePlaybooksData();
                }
                else {
                    addToast('Sorry, something went wrong there, try again.', {
                        autoDismiss: true,
                        appearance: 'error'
                    })
                }
            })
            .catch(err => {
                console.log(err);
                addToast('Sorry, something went wrong there, try again.', {
                    autoDismiss: true,
                    appearance: 'error'
                })
            })
        history.goBack();
    }

    const handleCloseTicket = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCloseTicket(flag => !flag)
    }

    return (
        <>
            <Popup
                closeOnDocumentClick={false}
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                open={true}
                closeOnEscape={false}
                modal
            //lockScroll
            >
                <div className={"modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"}><div className="pbheader_ellipisis" title={pb_name}>Delete Playbook - {pb_name}</div></div>
                    <div className={"content"}>
                        <div className="font14 margintop20">
                            Are you sure you want to delete this Playbook? (Note: All associated incidents will be closed.)
                        </div>
                        <div>
                            <input className="margintop10 font14" type="checkbox" checked={closeTicket} onChange={handleCloseTicket} /> <span className="font14">Close All Tickets for the associated incidents</span>
                        </div>
                    </div>
                    <div className="shadow_footer fl" style={{ marginTop: 0 }}></div>
                    <div className="popup_footer fl">
                        <div className="policy_defualt_button" onClick={handleClose}>No</div>
                        <div className="policy_save_button" onClick={handleYes}>Yes</div>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default React.memo(DeletePlaybook);