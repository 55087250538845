import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

type AMBreadcrumbItem = {
    title: string,
    url?: string,
    isLink: boolean,
    state?: any,
    search?: string,
    id?: string,
    linkId?: number
}

type AMBreadcrumbType = {
    breadcrumb?: AMBreadcrumbItem[];
    setBreadcrumb?: (value: AMBreadcrumbItem[]) => void;
    loadedConfig?: any;
    setLoadedConfig?: (value: any) => void;
};

export type BreadcrumbConfig = {
    id: string;
    customData?: any;
    getBreadcrumb: () => void;
    breadcrumbArray?: AMBreadcrumbItem[],
    transform: (history: any, customData?: any) => AMBreadcrumbItem[];
    clearOldCrumbs?: boolean;
}


const AMBreadcrumbContext = React.createContext<AMBreadcrumbType | any | undefined>(undefined);

export const AMBreadcrumbProvider = ({ children }: any) => {
    const [breadcrumb, setBreadcrumb] = useState<any>([]);
    const [loadedConfig, setLoadedConfig] = useState<any>({});

    return (<AMBreadcrumbContext.Provider value={{ breadcrumb, setBreadcrumb, loadedConfig, setLoadedConfig }}>
        {children}
    </AMBreadcrumbContext.Provider>)
}

export const useAMBreadcrumb = (config: BreadcrumbConfig) => {
    const { breadcrumb, setBreadcrumb } = React.useContext(AMBreadcrumbContext);
    const [hideBreadcrumbs, setHideBreadcrumbs] = useState(false);
    const history = useHistory();
    const state = history.location.state as any;

    const isBreadExist = (breadcrumbs: AMBreadcrumbItem[]) => {
        const breadcrumbMap = breadcrumb.map((item: AMBreadcrumbItem) => item.title);
        const newBreadcrumbMap = breadcrumbs.map((item: AMBreadcrumbItem) => item.title);
        return newBreadcrumbMap.every((item: string) => breadcrumbMap.includes(item));
    }

    if (config) {
        const transformedBreadcrumb = config.transform(history, state.customData);
        if (transformedBreadcrumb && transformedBreadcrumb.length > 0) {
            let breadcrumbs = JSON.parse(JSON.stringify(transformedBreadcrumb));
            if (breadcrumbs?.length > 0) {
                breadcrumbs = breadcrumbs.map((item: any) => {
                    if (!item.id)
                        item.id = config.id;
                    return item;
                });
                if (!isBreadExist(breadcrumbs)) {
                    if (config.clearOldCrumbs) {
                        setBreadcrumb([...breadcrumbs]);
                    } else {
                        setBreadcrumb([...breadcrumb, ...breadcrumbs]);
                    }
                }
                if (hideBreadcrumbs)
                    setHideBreadcrumbs(false);
                if (history.action == 'POP') {
                    const index = breadcrumb.findLastIndex((item: any) => item.id == config.id);
                    if (index > -1 && breadcrumb.length > 0 && breadcrumb.slice(index + 1).length > 0) {
                        setBreadcrumb(breadcrumb.slice(0, index + 1));
                    }
                }
            }
        } else {
            if (!hideBreadcrumbs) {
                setBreadcrumb([]);
                setHideBreadcrumbs(true);
                history.replace(history.location.pathname + history.location.search, { breadcrumbId: undefined });
            }

        }
    } else {
        if (breadcrumb?.length) {
            setBreadcrumb([]);
        }
    }

    const removeBreadcrumb = (id: string, breadcrumbId: string, e: any) => {
        e.preventDefault();
        let breadcrumbTemp = JSON.parse(JSON.stringify(breadcrumb));
        let numLinks = breadcrumbTemp.filter((item: AMBreadcrumbItem) => item.isLink == true).length;
        breadcrumbTemp = breadcrumbTemp.map((item: AMBreadcrumbItem) => {
            if (item.isLink) {
                item.linkId = numLinks * -1;
                numLinks--;
            }
            return item;
        });
        const index = breadcrumbTemp.findIndex((item: AMBreadcrumbItem) => item.title == id);
        const breadcrumbItem = breadcrumbTemp.find((item: AMBreadcrumbItem) => item.title == id) as AMBreadcrumbItem;
        breadcrumbTemp.splice(index);
        setBreadcrumb(breadcrumbTemp);
        if (breadcrumb?.length > 0 && history.location.pathname == breadcrumb[0].url) {
            setBreadcrumb([]);
            history.replace(history.location.pathname, { breadcrumbId: undefined });
        } else {
            history.go(Math.abs(breadcrumbItem?.linkId || -1) * -1);
        }
    }

    const clearBreadcrumbs = () => {
        setBreadcrumb([]);
    }

    return {
        breadcrumbList: breadcrumb,
        removeBreadcrumb,
        clearBreadcrumbs
    }
};