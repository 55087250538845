import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { IdentitiesMappingObj, IssueParams } from '../../../../types/response-types';
import ReactPaginate from 'react-paginate';
import { Api } from '../../../../components/Axios';
import * as qs from "qs";
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';


type Props={
    data: IdentitiesMappingObj[] | undefined,
    prevURL: string | undefined,
    aname: string | undefined,
    asset_url: any | undefined
}

const AssetAccess=({query, setQuery, data, prevURL, aname, asset_url, handleAssetAccessClose}: any)=>{
    let history = useHistory();
    const [idResult, setIdResult] = useState<String[]|undefined>(data?.result);
    
    console.log(asset_url);
    const highlightSortPopup=()=>{
        if(query.sort1_by==="identity" && query.order1_by==="asc"){
            setNameSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="identity" && query.order1_by==="desc"){
            setNameSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="flow_count" && query.order1_by==="asc"){
            setFlowCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="flow_count" && query.order1_by==="desc"){
            setFlowCountSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="issue_count" && query.order1_by==="asc"){
            setIssuesCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="issue_count" && query.order1_by==="desc"){
            setIssuesCountSortPopup("tablesort_down_selected")
        }  else {
            setNameSortPopup("tablesort_down")
            setFlowCountSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
        }
    }

    useEffect(()=>{
        highlightSortPopup();
    },[query])

    const {
        // order_by: order,
        // sort_by: sort,
        order1_by: order1,
        sort1_by: sort1,
        page2: page_number,
    } = query;

    const [nameSortPopup, setNameSortPopup] = useState<string | undefined>("tablesort_down");
    const [flowCountSortPopup, setFlowCountSortPopup] = useState<string | undefined>("tablesort_down");
    const [issuesCountSortPopup, setIssuesCountSortPopup] = useState<string | undefined>("tablesort_down");
    
    let param: IssueParams = {
        page2: page_number,
        // rpp: record_per_page,
        // order_by: order,
        // sort_by: sort,
        order1_by: order1,
        sort1_by: sort1,
    };

    const LoadSorting = (field: string) => {
        if (param.sort1_by === field) {
          param.order1_by = param.order1_by === "desc" ? "asc" : "desc";
          setQuery(param);
        } else {
          param.sort1_by = field;
          param.order1_by = "asc";
          setQuery(param);
        }
      };
    
    const SortBy = (name: string) => {
        
        if (param.sort1_by === name) {
            return param.order1_by === "desc"
            ? "tablesort_down"
            : "tablesort_up";
        }
    };

    const handleClose=()=>{
        // history.push(prevURL);
        handleAssetAccessClose();
    }

    useEffect(()=>{
        param.page2=1;
        setQuery(param);  
    },[])

    const handlePaginate=(selectedPage: any)=>{
        param.page2 = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(param)

        Api.get(`/directoryassetsmapping?_id=${encodeURIComponent(asset_url._id)}&hostdetail=${encodeURIComponent(asset_url.host_detail)}&domain=${encodeURIComponent(asset_url.domain)}&is_asset=${encodeURIComponent(asset_url.isAsset)}&page=${param.page2}&rpp=10`)
        .then(res=>{
            if(res.status===200){
                setIdResult(res.data.result);
            }
        })
        .catch(err=>console.log)
    }


    return(
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                modal={true}
                closeOnEscape={false}
                // //lockScroll
            >
                <div className={"modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"}>
                        <span className="rule_table_header" title={aname}>Directory that have assets authenticated: "{aname}"<br/></span>
                    </div>
                    <div className={"content"}>
                        <div className="margintop10">
                            <table id="table" className="tablesorter">
                                <thead>
                                    <tr>
                                        <th> <span className='align_left float_left'>Asset Name</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {idResult?.map((item: any, index: any) => {
                                        return (
                                        <tr>
                                            <td><span key={index} className="ellipsis_idassetname" title={item.asset_name.toString()}>{item.asset_name}</span></td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="clrBoth"></div>
                        </div>

                        <ReactPaginate
                            previousLabel={"← Previous"}
                            nextLabel={"Next →"}
                            pageCount={data && data.result.length>0 ? (Math.ceil(data.total / data.rpp)) : 1}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            onPageChange={handlePaginate}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(withQueryParams({
    page2: StringParam,
    sort1_by: StringParam,
    order1_by: StringParam,
}, AssetAccess));