import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { IdentitiesMappingObj, IssueParams } from '../../../../types/response-types';
import ReactPaginate from 'react-paginate';
import { Api } from '../../../../components/Axios';
import * as qs from "qs";
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { convertToCommaValue } from '../../../../utils/util-methods';
import { useLocation } from "react-router-dom";

type Props={
    data: IdentitiesMappingObj[] | undefined,
    prevURL: string | undefined,
    aname: string | undefined
}

const IdAccess=({query, setQuery, data, prevURL, aname, handleIDAccessClose}: any)=>{
    let history = useHistory();
    if(data === undefined) {
        history.push("/assets")
    }
    const [idResult, setIdResult] = useState<IdentitiesMappingObj[]|undefined>(data?.result);
    let location = useLocation();

    const removeQuery = () => {        
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('page2')) {
            queryParams.delete('page2')
        }
        if(queryParams.has('order1_by')) {
            queryParams.delete('order1_by');
        }

        if(queryParams.has('sort1_by')){
            queryParams.delete('sort1_by');
        }
        history.replace({
            search: queryParams.toString()
        })
      };


    const highlightSortPopup=()=>{
        if(query.sort1_by==="identity" && query.order1_by==="asc"){
            setNameSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="identity" && query.order1_by==="desc"){
            setNameSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="flow_count" && query.order1_by==="asc"){
            setFlowCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="flow_count" && query.order1_by==="desc"){
            setFlowCountSortPopup("tablesort_down_selected")
        } else if(query.sort1_by==="issue_count" && query.order1_by==="asc"){
            setIssuesCountSortPopup("tablesort_up_selected")
        } else if(query.sort1_by==="issue_count" && query.order1_by==="desc"){
            setIssuesCountSortPopup("tablesort_down_selected")
        }  else {
            setNameSortPopup("tablesort_down")
            setFlowCountSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
        }
    }

    useEffect(()=>{
        highlightSortPopup();
        fetchIdentitiesMapping();
    },[query])

    const fetchIdentitiesMapping =()=>{
        Api.get(`/identitiesmapping?_id=${encodeURIComponent(aname)}&page=${param.page2}&rpp=10&sort_by=${param.sort1_by !== undefined? param.sort1_by : ""}&order_by=${param.order1_by !== undefined? param.order1_by:""}`)
        .then(res=>{
            if(res.status===200){
                setIdResult(res.data.result);
            }
        })
        .catch(err=>console.log)
    }


    const {
        // order_by: order,
        // sort_by: sort,
        order1_by: order1,
        sort1_by: sort1,
        page2: page_number,
    } = query;

    const [nameSortPopup, setNameSortPopup] = useState<string | undefined>("tablesort_down");
    const [flowCountSortPopup, setFlowCountSortPopup] = useState<string | undefined>("tablesort_down");
    const [issuesCountSortPopup, setIssuesCountSortPopup] = useState<string | undefined>("tablesort_down");
    
    let param: IssueParams = {
        page2: page_number,
        // rpp: record_per_page,
        // order_by: order,
        // sort_by: sort,
        order1_by: order1,
        sort1_by: sort1,
    };

    const LoadSorting = (field: string) => {
        if (param.sort1_by === field) {
          param.order1_by = param.order1_by === "desc" ? "asc" : "desc";
          setQuery(param);
        } else {
          param.sort1_by = field;
          param.order1_by = "asc";
          setQuery(param);
        }
      };
    
    const SortBy = (name: string) => {
        
        if (param.sort1_by === name) {
            return param.order1_by === "desc"
            ? "tablesort_down"
            : "tablesort_up";
        }
    };

    const handleSort=(e: React.MouseEvent)=>{

        if(e.currentTarget.textContent==="Identity Name"){
            LoadSorting("identity");
            setNameSortPopup(SortBy("identity"));
            setFlowCountSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
        }
        else if(e.currentTarget.textContent==="Flows #"){
            LoadSorting("flow_count");
            setFlowCountSortPopup(SortBy("flow_count"));
            setNameSortPopup("tablesort_down")
            setIssuesCountSortPopup("tablesort_down")
        }
        else if(e.currentTarget.textContent==="Incidents #"){
            LoadSorting("issue_count");
            setIssuesCountSortPopup(SortBy("issue_count"));
            setNameSortPopup("tablesort_down")
            setFlowCountSortPopup("tablesort_down")
        }
    }
    const handleClose=()=>{
        // history.push(prevURL);
        removeQuery()
        handleIDAccessClose();
    }

    const handleAccessedRedirectClick=(identity_name: any)=>{
        if(idResult!==undefined) {
            let hostDetails:any =  idResult.find((item: any)=>item._id===identity_name);
            let currHostDetails = hostDetails?.asset_host_detail;
            let assetsRef = "";
            currHostDetails.map((item:any, index:any)=>{
                if(index<100)
                assetsRef += item.ip+"-"+item.port+",";
            })
            history.push("/issues?disable_filter=true&order_by=desc&page=1&q=is_external:false%2Bd_name:"+aname+"%2Bstatus:Open%2Bs_name:"+identity_name)
        }
    }

    useEffect(()=>{
        param.page2=1;
        setQuery(param);  
    },[])

    const handlePaginate=(selectedPage: any)=>{
        param.page2 = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(param)

        Api.get(`/identitiesmapping?_id=${encodeURIComponent(aname)}&page=${param.page2}&rpp=10&sort_by=${param.sort1_by !== undefined? param.sort1_by : ""}&order_by=${param.order1_by !== undefined? param.order1_by:""}`).
        then(res=>{
            if(res.status===200){
                setIdResult(res.data.result);
            }
        })
        .catch(err=>console.log)
    }


    return(
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                modal={true}
                closeOnEscape={false}
                // //lockScroll
            >
                <div className={"modal id_asset_modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"}>
                        <span className="rule_table_header" title={aname}>Identities that have accessed: "{aname.split(" on ")[0]}{aname.split(" on ")[1]!==undefined ? " on " : ""}<i>{aname.split(" on ")[1]!==undefined ? " "+aname.split(" on ")[1] : ""}</i>"<br/></span>
                    </div>
                    <div className={"content"}>
                        <div className="margintop10">
                            <table id="table" className="tablesorter">
                                <thead>
                                    <tr>
                                        <th className={nameSortPopup} onClick={handleSort} style={{width:"60%"}}><span className='align_left float_left'>Identity Name</span></th>
                                        <th className={flowCountSortPopup} onClick={handleSort}>Flows #</th>
                                        <th className={issuesCountSortPopup} onClick={handleSort}>Incidents #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {idResult?.map((item: any, index: any) => {
                                        return (
                                        <tr>
                                            <td><span key={index} className="ellipsis_idassetname" title={item.identity.toString()}>{item.identity}</span></td>
                                            <td><span key={index}>{convertToCommaValue(item.flow_count)}</span></td>
                                            <td>
                                                {item.issue_count !== 0 ? 
                                                    <div onClick={()=>handleAccessedRedirectClick(item?.identity)} className="shadowbox num_flows">
                                                        <span key={index}>{convertToCommaValue(item.issue_count)}</span>
                                                    </div>
                                                :
                                                    <div>
                                                        <span key={index}>{convertToCommaValue(item.issue_count)}</span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="clrBoth"></div>
                        </div>

                        <ReactPaginate
                            previousLabel={"← Previous"}
                            nextLabel={"Next →"}
                            pageCount={data && data.result.length>0 ? (Math.ceil(data.total / data.rpp)) : 1}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            onPageChange={handlePaginate}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(withQueryParams({
    page2: StringParam,
    sort1_by: StringParam,
    order1_by: StringParam,
}, IdAccess));