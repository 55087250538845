import React from 'react';

const SuspectedAttackExpiredAccountView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An expired AD account will be marked as under attack when
                    number of login attempts in an hour is more than{' '}
                    <strong>
                        {config?.dir_params.user_expired_logon_count_curhour}
                    </strong>
                </span>
            </li>
        </>
    );
};

export default React.memo(SuspectedAttackExpiredAccountView);
