import React from 'react';

export const DeviationInDailyAssetView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    Access to an asset should be marked as a deviation when:
                </span>
                <ul className='ml-1'>
                    {config?.today_flow_count_7_day_avg?.is_checked && (
                        <li>
                            The current day’s flow count for the asset goes{' '}
                            <strong>
                                {config?.today_flow_count_7_day_avg?.percent}%
                            </strong>{' '}
                            {config?.today_flow_count_7_day_avg?.operator} than
                            the 7-day average
                        </li>
                    )}
                    {config?.today_flow_count_gt_7_day_max?.is_checked && (
                        <li>
                            The current day’s flow count for the asset goes{' '}
                            <strong>
                                {config?.today_flow_count_gt_7_day_max?.percent}
                                %
                            </strong>{' '}
                            higher than the 7-day maximum
                        </li>
                    )}
                    {config?.today_flow_count_lt_7_day_min?.is_checked && (
                        <li>
                            The current day’s flow count for the asset goes{' '}
                            <strong>
                                {config?.today_flow_count_lt_7_day_min?.percent}
                                %
                            </strong>{' '}
                            lower than the 7-day minimum
                        </li>
                    )}
                    {config?.today_flow_count_gt_yesterday?.is_checked && (
                        <li>
                            The current day’s flow count for the asset goes{' '}
                            <strong>
                                {config?.today_flow_count_gt_yesterday?.percent}
                                %
                            </strong>{' '}
                            higher than the previous day’s count
                        </li>
                    )}
                    {config?.today_flow_count_gt_count?.is_checked && (
                        <li>
                            The current day’s flow count for the asset goes
                            higher than{' '}
                            <strong>
                                {config?.today_flow_count_gt_count?.value}
                            </strong>
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
};
