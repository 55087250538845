import React from "react"
import Popup from "reactjs-popup"

interface IRegenerateAPIKeyConfirm {
    closeRegenerate: any;
    sensorName: string;
    confirmGenerate: any;
}

export const RegenerateAPIKeyConfirm = ({ closeRegenerate, sensorName, confirmGenerate }: IRegenerateAPIKeyConfirm) => {
    return (
        <Popup
            open={true}
            closeOnDocumentClick
            closeOnEscape={false}
            onClose={closeRegenerate}
            overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
            modal
        >
            <div className="modal">
                <div className="close" onClick={closeRegenerate}></div>
                <div className="header">Regenerate API Key</div>
                <div className="content">
                    <div className="font16 margintop20 marginbottom20">Are you sure you want to regenerate API Key for <strong>{sensorName}</strong>?</div>
                </div>
                <div className="popup_footer">
                    <button style={{ display: 'inline' }} type="button" className="button_gray width25per margintop10" onClick={closeRegenerate}>No</button>
                    <button style={{ float: 'right' }} type="button" className="button_main width25per" onClick={confirmGenerate}>Yes</button>
                </div>
            </div >
        </Popup >)
}