import { AccountTakeoverState } from "../../../../types/playbooks-config"

const AccountTakeoverConfigview = ({ config: { account_takeover_params }, params }: any) => {
    if (params?.isPlaybookListing) {
        return <li><span>Detect account takeovers where the latest access is suspicious based on the following criteria :
            {account_takeover_params?.displacementFlag && <> User’s displacement (distance/time) is greater than {account_takeover_params.displacementValue} {account_takeover_params.displacementUnit}</>
                || account_takeover_params?.distanceTraveledFlag && <> Distance traveled since last access is greater than {account_takeover_params.distanceTraveledValue} {account_takeover_params.distanceTraveledUnit}</>
                || account_takeover_params?.newIspFlag && <> Access from a new ISP was identified</>
                || account_takeover_params?.newIpRangeFlag && <> Access from a new IP range was identified</>}
        </span></li>
    } else {
        return <li>
            <span>Detect account takeovers where the latest access is suspicious based on the following criteria:</span>
            <ul className='ml-1'>
                {
                    account_takeover_params?.displacementFlag &&
                    <li>User’s displacement (distance/time) is greater than {account_takeover_params.displacementValue} {account_takeover_params.displacementUnit} </li>}
                {
                    account_takeover_params?.distanceTraveledFlag &&
                    <li>Distance traveled since last access is greater than {account_takeover_params.distanceTraveledValue} {account_takeover_params.distanceTraveledUnit}</li>}
                {
                    account_takeover_params?.newIspFlag &&
                    <li>Access from a new ISP was identified</li>}
                {
                    account_takeover_params?.newIpRangeFlag &&
                    <li>Access from a new IP range was identified</li>}
            </ul>
        </li>
    }

}
export default AccountTakeoverConfigview;