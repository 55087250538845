import { useHistory } from "react-router-dom";
import { useAuthDetails } from "../../components/Authorization";
import { Api } from "../../components/Axios";
import { useToasts } from "../../components/core";

export const useLogin = () => {
    const { setAuthDetails } = useAuthDetails()!;
    const { addToast } = useToasts()
    const history = useHistory();
    const postLogin = (res: any) => {
        const user = {
            name: res.data.name,
            email: res.data.email,
            token: res.data.token,
            authenticated: true,
            role: res.data.role,
            permissions: res.data.permissions,
            tenantName:res.data.tenant_name
        }
        
        Api.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
        window.localStorage.setItem('user', JSON.stringify(user))
        window.localStorage.removeItem('user_auth')

        setAuthDetails(user);

        const redirectURL = localStorage.getItem('redirectURL');
        
        if (redirectURL) {
            localStorage.removeItem('redirectURL');
            history.push(`${redirectURL}`);
        }

        addToast("You have logged in successfully.", {
            appearance: 'success',
            autoDismiss: true,
        });

    }

    return { postLogin }
}