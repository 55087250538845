import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import { Link } from "react-router-dom"
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import {Api} from '../../components/Axios'
import { AddPanel, EditPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core';
import {ActionMenu} from '../../theme/ActionMenu'

// import './listing.css'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    name: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp?: number;
    sort_by?: string;
    order_by?: string;
}

type ActionType = {
    type?: string;
    id?: string;
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}

const Groups = ({query, setQuery}: any) => {
    const { register, handleSubmit } = useForm<Search>()
    const {page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType] = React.useState<PanelType>('right')
    const [panelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [result, setResult] = useState<Result>()
    const { addToast } = useToasts()

    let p: Params = {
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort
    }

    useEffect(() => {
        Listing()
    }, [query])

    useEffect(() => {
        switch(actionType?.type) {
            case 'add':
                setPanelTitle("Add Group")
                setOpenPanel(true)
                return
            
            case 'edit':
                setPanelTitle("Update Group")
                setOpenPanel(true)
                return

            case 'delete':
                const headers = {'Operation':'DELETE'}
                Api.post('/groups/'+actionType.id, "", {headers: headers})
                .then((res: { data: any }) => {
                    addToast("Group deleted successfully.", {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    p.page = 1
                    setQuery(p)
                })
                .catch((error: any) => {
                })
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "add" &&
                            <AddPanel AfterGroupOperation={AfterGroupOperation} />
                        }

                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterGroupOperation={AfterGroupOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const Listing = () => {
        Api.get('/groups', {
            params: p
        })
        .then((res: { data: any }) => {
            setResult(res.data)
        })
        .catch((error: any) => {
        })
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setActionType({
            id: e.currentTarget.id,
            type: e.currentTarget.dataset.action
        })
        e.preventDefault()
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const AfterGroupOperation = (action: string) => {
        setOpenPanel(false)
        if(action === 'add') {
            p.page = 1
            p.q = ""
            setQuery(p)
            Listing()
        } else {
            Listing()
        }        
    }

    const LoadSorting = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if(p.sort_by === e.currentTarget.dataset.field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = e.currentTarget.dataset.field
            p.order_by = 'desc'
            setQuery(p)
        }
        e.preventDefault()
    }

    const SortBy = (name: string) => {
        if(p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    const Sorting = (name: string, field: string) => {
        const fl = name.toLowerCase()
        return (
            <Link to="#" data-field={field} onClick={LoadSorting}>
                <div className={SortBy(field)}>{name}</div>
            </Link>
        )
    }

    return (
        <>
            <RightPanel />
            <div className="page_title_area">
                <h2>Registered Groups</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="text" name="query" ref={register} defaultValue={p.q} className="page_search" placeholder="Search by Group Name" />
                </form>

                <div className="launch_button">
                    <button type="button" className="button_main" onClick={() => setActionType({id: "", type: "add"})}>Create Group</button>
                </div>
            </div>

            <table id="table" className="tablesorter">
                <thead>
                    <tr>
                        <th>{Sorting("Name", "name")}</th>
                        <th>{Sorting("Owner", "owner")}</th>
                        <th>{Sorting("Number of Members", "members")}</th>
                        <th><div className="">Action</div></th>
                    </tr>
                </thead>
                <tbody>
                    {result && result.result.length > 0 && result.result.map((u:any) => (
                        <tr key={u._id}>
                            <td>{u.name}</td>
                            <td></td>
                            <td></td>
                            <td>
                                <ActionMenu>
                                    <ul>
                                        <li>
                                            <Link to="#" onClick={Action} data-action="edit" id={u._id}>Edit</Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={Action} data-action="delete" id={u._id}>Delete</Link>
                                        </li>
                                    </ul>
                                </ActionMenu>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {result &&
                <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={(Math.ceil(result.total / 2))}
                    onPageChange={Paginate}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            }
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Groups)