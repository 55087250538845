import { useEffect, useRef, useState } from "react";
import { TrashIcon } from "../../../../../Icons";
import * as Ref from '../../../ref';
import UnauthorizedAssetAccessIssueConfig from "./UnauthorizedAssetAccessIssueConfig";
import { RuleType } from "../../../constants/Constants";
import { validate } from "../../../helpers/Validators";
import { IWeelkSelectorState } from "../../../ref/WeekSelector";
import { IIdAssetState, IdPopUp } from "../../../ref/IdPopUp";
import { AssetPattern, AssetPatternFencingConfig, IdPattern, IdPatternFencingConfig, InterPattern, InterPatternFormConfig } from "../../../ref/config/IdPatternConfig";
import { IPatternPayload } from "../../../ref/PatternForm/PatternFormGenerator";

interface IUnauthAssetAccessConfigProps {
    getState(configState: Array<IUnauthPayloadRuleConfig>): any;
    parentState: any,
    formType?: string,
    getExpression?(exprData: any): any
}

const OperatorMap = {
    "equals": "in",
    "not equals": "not in",
    "matches": "matches",
    "not matches": "not matches"
} as Record<string, string>;

export interface IUnauthConfigState {
    params: {
        dest_port?: Array<number>,
        src_port?: Array<number>,
        access_protocol?: Array<string>,
        access_time?: IWeelkSelectorState
    },
    asset: IIdAssetState,
    identity: IIdAssetState,
    intermediary: IIdAssetState
}

export interface IUnauthPayloadRuleConfig {
    dest_port?: Array<number>,
    src_port?: Array<number>,
    access_protocol?: Array<string>,
    access_time?: IWeelkSelectorState,
    source_pattern: Record<string, IPatternPayload>,
    destination_pattern: Record<string, IPatternPayload>,
    intermediary_pattern: Record<string, IPatternPayload>,
    source: Array<string>,
    destination: Array<string>,
    intermediary: Array<string>
}

interface ShowPopUpConfig {
    showConfigure: boolean, showAsset: boolean, showId: boolean, currentEdit: number, customData: IIdAssetState | any,
    showIntermediary: boolean
}

const DefaultUnauthConfigState: IUnauthConfigState = {
    params: {
        dest_port: [],
        src_port: [],
        access_protocol: [],
        access_time: Ref.WeekSelector.DefaultWeekSelectorState
    },
    asset: {
        type: ['All Assets'],
        pattern: {}
    },
    identity: {
        type: ['All Identities'],
        pattern: {}
    },
    intermediary: {
        type: ['Any Access Control'],
        pattern: {}
    }
}

const UnauthAssetAccessConfig = ({ getState, parentState, formType, getExpression }: IUnauthAssetAccessConfigProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [showErrorPopup, setShowErrorPopUp] = useState<any>({});
    const [dirFields, setDirFields] = useState<Array<Record<string, boolean>>>([]);
    useEffect(() => {
        if (parentState?.length && parentState[0]?.identity && parentState[0]?.params && parentState[0]?.asset) {
            setConfigState(parentState);
        }
    }, [parentState]);

    useEffect(() => {
        if (getExpression) {
            let state = JSON.parse(JSON.stringify(configState)) as Array<IUnauthConfigState>;
            if (state?.length) {
                const result = state.map((item: IUnauthConfigState) => {
                    const i = JSON.parse(JSON.stringify(item)) as IUnauthConfigState;
                    const c = {} as any;
                    c.fields_matched = [];
                    c.to_be_highlighted = [];
                    c.matching_field_value = ''
                    handleConfigExprConversion(c, i);
                    handleAssetExprConversion(c, i);
                    handleIdenntityExprConversion(c, i);
                    handleIntermediaryConversion(c, i);
                    c.fields_matched = "'" + c.fields_matched.join("','") + "'";
                    c.to_be_highlighted = "'" + c.to_be_highlighted.join("','") + "'";
                    return c;
                })
                getExpression({
                    unauthorized_asset: result
                })
            }
        }
    });

    const handleConfigExprConversion = (c: any, i: IUnauthConfigState) => {
        const utcOffset = new Date().getTimezoneOffset();
        if (i?.params?.dest_port) {
            c.dest_port = i?.params?.dest_port;
            c.fields_matched.push("am_destination.DestinationPort");
            c.to_be_highlighted.push("am_destination.DestinationPort");
            c.to_be_highlighted.push("am_destination.DestinationName");
            c.matching_field_value = "AM_destination.DestinationPort"
        }
        if (i?.params?.src_port) {
            c.src_port = i?.params?.src_port;
            c.fields_matched.push("am_source.SourcePort");
            c.to_be_highlighted.push("am_source.SourcePort");
            if (!c.matching_field_value) {
                c.matching_field_value = "AM_source.SourcePort"
            }
        }
        if (i?.params?.access_protocol && i?.params?.access_protocol?.length > 0) {
            c.access_protocol = "'" + i?.params?.access_protocol.join("','") + "'";
            c.fields_matched.push("am_destination.Protocol");
            c.to_be_highlighted.push("am_destination.Protocol");
            if (!c.matching_field_value) {
                c.matching_field_value = "AM_destination.Protocol"
            }
        }
        if (i?.params?.access_time) {
            const res = Object.keys(i?.params?.access_time).map((item: string) => {
                if (i?.params?.access_time) {
                    const it = i?.params?.access_time[item] as Ref.WeekSelector.IWeeklyState;
                    if (it.is_selected) {
                        return {
                            from: parseInt(item) * 24 * 60 * 60 + (parseInt(it.from.toString()) * 3600) + (utcOffset * 60),
                            to: parseInt(item) * 24 * 60 * 60 + (parseInt(it.to.toString()) * 3600) + (utcOffset * 60)
                        }
                    }
                }
                return undefined;
            }).filter((i) => i);
            c.access_time = res.map((item: any, index: number) => {
                if (index !== res.length - 1) {
                    item.to = item.to + ' || ';
                }
                return item;
            });
            c.fields_matched.push("gen_timestamp");
            c.to_be_highlighted.push("gen_timestamp");
            if (!c.matching_field_value) {
                c.matching_field_value = "GenTimeStamp"
            }
        }
    }

    const handleAssetExprConversion = (c: any, i: IUnauthConfigState) => {
        if (i?.asset) {
            c.destination = "'" + (i.asset.type[0] || '') + "'";
            c.asset = JSON.parse(JSON.stringify(i.asset.pattern));
            c.asset_expr = '';
            Object.keys(c.asset).forEach((key: string, index: number) => {
                const id_len = c?.asset?.[key]?.value?.length - 1;
                if (c.asset[key]) {
                    let exprString = index > 0 ? ' or ' : '';
                    exprString += '('
                    switch (key) {
                        case 'name': {
                            if (c.asset[key].type === 'matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    const id = i.toLowerCase();
                                    if (isPattern(id)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_destination.DestinationName, '${id}') ${ind != id_len ? ' || ' : ''}`
                                    } else
                                        exprString += `AM_destination.DestinationName ${OperatorMap[c.asset[key].type]} '${id}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c.asset[key].type === 'not matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    const id = i.toLowerCase();
                                    if (isPattern(id)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_destination.DestinationName, '${id}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_destination.DestinationName ${OperatorMap[c.asset[key].type]} '${id}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.asset?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_destination.DestinationName ${OperatorMap[c.asset[key].type]} [${val}]`;
                            }
                        }
                            break;
                        case 'ip': {
                            if (c.asset[key].type === 'matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase()
                                    if (isCIDR(i)) {
                                        exprString += `IsInsideCIDR(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                                    } else if (isIPRange(i)) {
                                        exprString += `IsInsideIpRange(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                                    } else if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                                    } else
                                        exprString += `AM_destination.DestinationIp ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c.asset[key].type === 'not matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase()
                                    if (isCIDR(i)) {
                                        exprString += `!IsInsideCIDR(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                                    } else if (isIPRange(i)) {
                                        exprString += `!IsInsideIpRange(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                                    } else if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                                    } else
                                        exprString += `AM_destination.DestinationIp ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.asset?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_destination.DestinationIp ${OperatorMap[c.asset[key].type]} [${val}]`
                            }
                        } break;
                        case 'hostname': {
                            if (c.asset[key].type === 'matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase()
                                    if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_destination.Hostname, '${i}') ${ind != id_len ? ' || ' : ''}`
                                    } else
                                        exprString += `AM_destination.Hostname  ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c.asset[key].type === 'not matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase()
                                    if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_destination.Hostname, '${i}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_destination.Hostname  ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.asset?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_destination.Hostname  ${OperatorMap[c.asset[key].type]} [${val}]`;
                            }
                            break;
                        }
                        case 'dir_hostname': {
                            if (c.asset[key].type === 'matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_directory.Hostname, '${i}') ${ind != id_len ? ' || ' : ''}`
                                    } else
                                        exprString += `AM_directory.Hostname  ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c.asset[key].type === 'not matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_directory.Hostname, '${i}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_directory.Hostname  ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.asset?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_directory.Hostname ${OperatorMap[c.asset[key].type]} [${val}]`;
                            }
                            break;
                        }
                        case 'dir_name': {
                            if (c.asset[key].type === 'matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_directory.DirectoryName, '${i}') ${ind != id_len ? ' || ' : ''}`
                                    } else
                                        exprString += `AM_directory.DirectoryName  ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c.asset[key].type === 'not matches') {
                                c?.asset?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_directory.DirectoryName, '${i}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_directory.DirectoryName  ${OperatorMap[c.asset[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.asset?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_directory.DirectoryName  ${OperatorMap[c.asset[key].type]} [${val}]`;
                            }
                        }
                            break;
                    }
                    exprString += ')'
                    c.asset_expr += exprString;
                }
            });
        }
    }

    const convertIdDomain = (i: string) => {
        if (i.indexOf('\\') > 0) {
            const s1 = i.split("\\")[0];
            const s2 = i.split("\\")[1];
            return `${s2}@${s1}`.trim();
        }
        return i.trim();
    }

    const isCIDR = (i: string) => {
        if (i.length > 0 && i.indexOf('/') > -1) {
            return true;
        }
        return false;
    }

    const isIPRange = (i: string) => {
        if (i?.length > 0 && i?.indexOf('-') > - 1) {
            return true;
        }
        return false;
    }

    const isPattern = (i: string) => {
        if (i?.length > 0 && i?.indexOf('*') > - 1) {
            return true;
        }
        return false;
    }

    const handleIntermediaryConversion = (c: any, i: IUnauthConfigState) => {
        if (i?.intermediary) {
            c.source = "'" + (i.identity.type[0] || '') + "'";
            c.intermediary = JSON.parse(JSON.stringify(i.intermediary.pattern));
            c.intermediary_expr = '';
            Object.keys(c.intermediary).forEach((key: string, index: number) => {
                const id_len = c?.intermediary?.[key]?.value?.length - 1;
                if (c.intermediary[key]) {
                    let exprString = index > 0 ? ' or ' : '';
                    exprString += '(';
                    switch (key) {
                        case 'src_inter_ip':
                            exprString += generateExpressionForIntermediary(c, key, id_len);
                            break;
                        case 'dir_hostname': {
                            if (c?.intermediary?.[key].type === 'matches') {
                                c?.intermediary?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_directory.Hostname, '${i}') ${ind != id_len ? ' || ' : ''}`
                                    } else
                                        exprString += `AM_directory.Hostname  ${OperatorMap[c?.intermediary?.[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c?.intermediary?.[key].type === 'not matches') {
                                c?.intermediary?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_directory.Hostname, '${i}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_directory.Hostname  ${OperatorMap[c?.intermediary?.[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.intermediary?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_directory.Hostname ${OperatorMap[c?.intermediary?.[key].type]} [${val}]`;
                            }
                            break;
                        }
                        case 'dir_name': {
                            if (c?.intermediary?.[key].type === 'matches') {
                                c?.intermediary?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_directory.DirectoryName, '${i}') ${ind != id_len ? ' || ' : ''}`
                                    } else
                                        exprString += `AM_directory.DirectoryName  ${OperatorMap[c?.intermediary?.[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c?.intermediary?.[key].type === 'not matches') {
                                c?.intermediary?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_directory.DirectoryName, '${i}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_directory.DirectoryName  ${OperatorMap[c?.intermediary?.[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.intermediary?.[key]?.value?.map((i: string) => ((i.toLowerCase()))).join("','") + "'";
                                exprString += `AM_directory.DirectoryName  ${OperatorMap[c?.intermediary?.[key].type]} [${val}]`;
                            }
                        }
                            break;
                    }
                    exprString += ')'
                    c.intermediary_expr += exprString;
                }
            })
        }
        c.intermediary_expr = c.intermediary_expr || true;
    }

    const generateExpressionForIntermediary = (c: any, key: string, id_len: number) => {
        let exprSrcIp = '', exprSrcInterIp = '', exprDestIp = '', exprDestInterIp = '';
        if (c.intermediary[key].type === 'matches') {
            c?.intermediary?.[key]?.value?.forEach((i: string, ind: number) => {
                i = i.toLowerCase();
                if (isCIDR(i)) {
                    exprSrcIp += `IsInsideCIDR(AM_common.CompanyId,AM_raw.SrcIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprSrcInterIp += `IsInsideCIDR(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && IsInsideCIDR(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprDestInterIp += `AM_destination.IsIntermediary == true && IsInsideCIDR(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                } else if (isIPRange(i)) {
                    exprSrcIp += `IsInsideIpRange(AM_common.CompanyId,AM_raw.SrcIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprSrcInterIp += `IsInsideIpRange(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && IsInsideIpRange(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprDestInterIp += `AM_destination.IsIntermediary == true && IsInsideIpRange(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                } else if (isPattern(i)) {
                    exprSrcIp += `IsMatchesPattern(AM_common.CompanyId,AM_raw.SrcIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprSrcInterIp += `IsMatchesPattern(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                    exprDestIp += `(AM_source.IsIntermediary == true && IsMatchesPattern(AM_common.CompanyId,AM_source.SourceIp, '${i}'))${ind != id_len ? ' || ' : ''}`;
                    exprDestInterIp += `(AM_destination.IsIntermediary == true && IsMatchesPattern(AM_common.CompanyId,AM_destination.DestinationIp, '${i}'))${ind != id_len ? ' || ' : ''}`;
                } else {
                    exprSrcIp += `AM_raw.SrcIntermediaryIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                    exprSrcInterIp += `AM_raw.DestIntermediaryIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && AM_source.SourceIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                    exprDestInterIp += ` AM_destination.IsIntermediary == true && AM_destination.DestinationIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                }
            });
        } else if (c.intermediary[key].type === 'not matches') {
            c?.intermediary?.[key]?.value?.forEach((i: string, ind: number) => {
                i = i.toLowerCase();
                if (isCIDR(i)) {
                    exprSrcIp += `!IsInsideCIDR(AM_common.CompanyId,AM_raw.SrcIntermediaryIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprSrcInterIp += `!IsInsideCIDR(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && !IsInsideCIDR(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprDestInterIp += `AM_destination.IsIntermediary == true && !IsInsideCIDR(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                } else if (isIPRange(i)) {
                    exprSrcIp += `!IsInsideIpRange(AM_common.CompanyId,AM_raw.SrcIntermediaryIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprSrcInterIp += `!IsInsideIpRange(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && !IsInsideIpRange(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprDestInterIp += `AM_destination.IsIntermediary == true && !IsInsideIpRange(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                } else if (isPattern(i)) {
                    exprSrcIp += `!IsMatchesPattern(AM_common.CompanyId,AM_raw.SrcIntermediaryIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprSrcInterIp += `!IsMatchesPattern(AM_common.CompanyId,AM_raw.DestIntermediaryIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && !IsMatchesPattern(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                    exprDestInterIp += `AM_destination.IsIntermediary == true && !IsMatchesPattern(AM_common.CompanyId,AM_destination.DestinationIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                } else {
                    exprSrcIp += `AM_raw.SrcIntermediaryIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                    exprSrcInterIp += `AM_raw.DestIntermediaryIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                    exprDestIp += `AM_source.IsIntermediary == true && AM_source.SourceIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                    exprDestInterIp += `AM_destination.IsIntermediary == true && AM_destination.DestinationIp ${OperatorMap[c.intermediary[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                }
            });
        } else {
            const val = "'" + c?.intermediary?.[key]?.value?.map((i: string) => (i.toLowerCase())).join("','") + "'";
            exprSrcIp += `AM_raw.SrcIntermediaryIp ${OperatorMap[c.intermediary[key].type]} [${val}]`
            exprSrcInterIp += `AM_raw.DestIntermediaryIp ${OperatorMap[c.intermediary[key].type]} [${val}]`
            exprDestIp += `AM_source.IsIntermediary == true && AM_source.SourceIp ${OperatorMap[c.intermediary[key].type]} [${val}]`
            exprDestInterIp += `AM_destination.IsIntermediary == true && AM_destination.DestinationIp ${OperatorMap[c.intermediary[key].type]} [${val}]`
        }
        return exprSrcIp + '||' + exprSrcInterIp + '||' + exprDestIp + '||' + exprDestInterIp;
    }

    const handleIdenntityExprConversion = (c: any, i: IUnauthConfigState) => {
        if (i?.identity) {
            c.source = "'" + (i.identity.type[0] || '') + "'";
            c.identity = JSON.parse(JSON.stringify(i.identity.pattern));
            c.identity_expr = '';
            Object.keys(c.identity).forEach((key: string, index: number) => {
                const id_len = c?.identity?.[key]?.value?.length - 1;
                if (c.identity[key]) {
                    let exprString = index > 0 ? ' or ' : '';
                    exprString += '('
                    switch (key) {
                        case 'name': {
                            if (c.identity[key].type === 'matches') {
                                c?.identity?.[key]?.value?.forEach((i: string, ind: number) => {
                                    const id = convertIdDomain(i).toLowerCase();
                                    if (isPattern(id)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId, AM_source.UserName, '${id}') ${ind != id_len ? ' || ' : ''}`
                                    } else {
                                        exprString += `AM_source.UserName ${OperatorMap[c.identity[key].type]} '${id}'${ind != id_len ? ' or ' : ''}`;
                                    }
                                });
                            } else if (c.identity[key].type === 'not matches') {
                                c?.identity?.[key]?.value?.forEach((i: string, ind: number) => {
                                    const id = convertIdDomain(i).toLowerCase();
                                    if (isPattern(id)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId, AM_source.UserName, '${id}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_source.UserName ${OperatorMap[c.identity[key].type]} '${id}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.identity?.[key]?.value?.map((i: string) => (convertIdDomain(i).toLowerCase())).join("','") + "'";
                                exprString += `AM_source.UserName ${OperatorMap[c.identity[key].type]} [${val}]`;
                            }
                        }
                            break;
                        case 'src_inter_ip':
                            exprString += generateExpressionForIntermediary(c, key, id_len);
                            break;
                        case 'ip': {
                            if (c.identity[key].type === 'matches') {
                                c?.identity?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isCIDR(i)) {
                                        exprString += `IsInsideCIDR(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                                    } else if (isIPRange(i)) {
                                        exprString += `IsInsideIpRange(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                                    } else if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' || ' : ''}`;
                                    } else {
                                        exprString += `AM_source.SourceIp ${OperatorMap[c.identity[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                    }
                                });
                            } else if (c.identity[key].type === 'not matches') {
                                c?.identity?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase();
                                    if (isCIDR(i)) {
                                        exprString += `!IsInsideCIDR(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                                    } else if (isIPRange(i)) {
                                        exprString += `!IsInsideIpRange(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                                    } else if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_source.SourceIp, '${i}')${ind != id_len ? ' && ' : ''}`;
                                    } else {
                                        exprString += `AM_source.SourceIp ${OperatorMap[c.identity[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                    }
                                });
                            } else {
                                const val = "'" + c?.identity?.[key]?.value?.map((i: string) => (i.toLowerCase())).join("','") + "'";
                                exprString += `AM_source.SourceIp ${OperatorMap[c.identity[key].type]} [${val}]`
                            }
                        } break;
                        case 'hostname': {
                            if (c.identity[key].type === 'matches') {
                                c?.identity?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase()
                                    if (isPattern(i)) {
                                        exprString += `IsMatchesPattern(AM_common.CompanyId,AM_source.HostName, '${i}') ${ind != id_len ? ' || ' : ''}`
                                    } else exprString += `AM_source.HostName ${OperatorMap[c.identity[key].type]} '${i}'${ind != id_len ? ' or ' : ''}`;
                                });
                            } else if (c.identity[key].type === 'not matches') {
                                c?.identity?.[key]?.value?.forEach((i: string, ind: number) => {
                                    i = i.toLowerCase()
                                    if (isPattern(i)) {
                                        exprString += `!IsMatchesPattern(AM_common.CompanyId,AM_source.HostName, '${i}') ${ind != id_len ? ' && ' : ''}`
                                    } else
                                        exprString += `AM_source.HostName ${OperatorMap[c.identity[key].type]} '${i}'${ind != id_len ? ' and ' : ''}`;
                                });
                            } else {
                                const val = "'" + c?.identity?.[key]?.value?.map((i: string) => (i.toLowerCase())).join("','") + "'";
                                exprString += `AM_source.HostName ${OperatorMap[c.identity[key].type]} [${val}]`;
                            }
                        }
                    }
                    exprString += ')'
                    c.identity_expr += exprString;
                }
            });
            c.identity_expr = c.identity_expr || 'true'
        }
    }

    const [configState, setConfigState] = useState<Array<IUnauthConfigState>>([DefaultUnauthConfigState]);
    const [showPopUp, setShowPopUp] = useState<ShowPopUpConfig>({
        showConfigure: false, showAsset: false,
        showId: false, currentEdit: -1, customData: {}, showIntermediary: false
    });

    const loadIdentitiesPopUp = (currentEdit: number) => {
        const c = JSON.parse(JSON.stringify(configState[currentEdit].identity)) as IIdAssetState;
        if (c.type[0] === 'No Identities') {
            c.pattern = {};
        }
        setShowPopUp({
            currentEdit, showId: true, showAsset: false,
            customData: c,
            showConfigure: false, showIntermediary: false
        });
    }

    const loadIntermediaryPopUp = (currentEdit: number) => {
        const c = JSON.parse(JSON.stringify(configState[currentEdit].intermediary)) as IIdAssetState;
        if (c.type[0] === 'Any Access Control') {
            c.pattern = {};
        }
        setShowPopUp({
            currentEdit, showId: false, showAsset: false,
            customData: c,
            showConfigure: false, showIntermediary: true
        });
    }

    const loadAssetsPopUp = (currentEdit: number) => {
        const c = JSON.parse(JSON.stringify(configState[currentEdit].asset)) as IIdAssetState;
        if (c.type[0] === 'No Assets') {
            c.pattern = {};
        }
        setShowPopUp({
            currentEdit, showId: false, showAsset: true,
            customData: c,
            showConfigure: false, showIntermediary: false
        });
    }

    const loadConfigurePopUp = (currentEdit: number) => {
        setShowPopUp({ currentEdit, showId: false, showAsset: false, showConfigure: true, customData: configState[currentEdit].params, showIntermediary: false })
    }

    const saveIdentities = (e: IIdAssetState) => {
        const state = JSON.parse(JSON.stringify(configState)) as Array<IUnauthConfigState>;
        const item = state[showPopUp.currentEdit];
        if (e.type[0] === 'No Identities') {
            e.pattern = {
                name: {
                    type: 'not equals',
                    value: ["*"]
                }
            }
        }
        item.identity = e;
        setConfigState(state);
        resetShowState();
    }

    const isDirSelected = (data: Array<string>, entityOne: string, entityTwo: string, index: number) => {
        const isSelected = (['dir_hostname', 'dir_name'].some(item => data.includes(item)));
        if (isSelected) {
            const s = [
                ...dirFields
            ];
            s[index] = {
                [entityOne]: true, [entityTwo]: false
            };
            setDirFields(s)
        } else {
            const s = [...dirFields];
            s[index] = { [entityOne]: false };
            setDirFields(s);
        }
    }

    const saveIntermediary = (e: IIdAssetState) => {
        const state = JSON.parse(JSON.stringify(configState)) as Array<IUnauthConfigState>;
        const item = state[showPopUp.currentEdit];
        item.intermediary = e;

        const keys = Object.keys(e.pattern);
        isDirSelected(keys, 'intermediary', 'asset', showPopUp.currentEdit)

        setConfigState(state);
        resetShowState();
    }

    const saveAssets = (e: IIdAssetState) => {
        const state = JSON.parse(JSON.stringify(configState)) as Array<IUnauthConfigState>;
        const item = state[showPopUp.currentEdit];
        if (e && e.type[0] === 'No Assets') {
            containerRef?.current?.querySelector(`#param-${showPopUp.currentEdit}`)?.classList.add("disable-config-item");
            containerRef?.current?.querySelector(`#param-${showPopUp.currentEdit}`)?.classList.remove("editable_icon");
            e.pattern = {
                name: {
                    type: 'not equals',
                    value: ["*"]
                }
            }
        } else {
            containerRef?.current?.querySelector(`#param-${showPopUp.currentEdit}`)?.classList.remove("disable-config-item");
            containerRef?.current?.querySelector(`#param-${showPopUp.currentEdit}`)?.classList.add("editable_icon");
        }

        item.asset = e;
        const keys = Object.keys(e.pattern);
        isDirSelected(keys, 'asset', 'intermediary', showPopUp.currentEdit);

        setConfigState(state);
        resetShowState();
    }

    const saveParams = (e: any) => {
        const state = JSON.parse(JSON.stringify(configState)) as Array<IUnauthConfigState>;
        const item = state[showPopUp.currentEdit];
        item.params = e;
        setConfigState(state);
        resetShowState();
    }

    useEffect(() => {
        const tempData = serializeData(JSON.parse(JSON.stringify(configState))) as Array<IUnauthPayloadRuleConfig>;
        if (tempData?.length > 0)
            getState(tempData)
    }, [configState])

    /* TO DO */
    const serializeData = (transformState: any[]) => {
        const result = [] as Array<IUnauthPayloadRuleConfig>;
        transformState?.forEach((item: IUnauthConfigState, index: number) => {
            if (item && item?.identity) {
                result[index] = {
                    ...item.params,
                    source: [...item?.identity?.type],
                    source_pattern: item.identity.pattern,
                    destination: [...item?.asset?.type],
                    destination_pattern: item.asset.pattern,
                    intermediary_pattern: item.intermediary.pattern,
                    intermediary: [...item.intermediary.type]
                }
            }
        })
        return result;
    }

    const onAddHandler = () => {
        const tr = serializeData(configState);
        const er = validate(RuleType.UNAUTHORIZED_ASSET_ACCESS, tr);
        if (er) {
            setShowErrorPopUp({
                show: true,
                error: er
            })
        } else {
            setConfigState([...configState,
                DefaultUnauthConfigState
            ]
            )
        }
    }

    const resetShowState = () => {
        setShowPopUp({ currentEdit: -1, showId: false, showAsset: false, customData: {}, showConfigure: false, showIntermediary: false })
    }

    const deleteConfig = (index: number) => {
        const tempState = JSON.parse(JSON.stringify(configState));
        tempState.splice(index, 1);

        const tempDirFields = JSON.parse(JSON.stringify(dirFields));
        tempDirFields.splice(index, 1);

        setConfigState(tempState);
        setDirFields(tempDirFields);
    }

    return <>
        <>
            <td colSpan={4} className="unauth-asset-access" >
                <div className="scrollbar-container unauth-access-scroll-container" ref={containerRef}>
                    {
                        configState && configState?.map((i: IUnauthConfigState, index: number) => {
                            return <span className="unauth-asset-access"><div
                                className="unauth-asset-access-flex-container"
                            >
                                <div
                                    id={`param-${index}`}
                                    className={"selection_box editable_icon" +
                                        (formType && ['view', 'edit'].includes(formType) ? ' eye-open flex-item-row-1-view' : ' flex-item-row-1')
                                        + (i.asset.type[0] === 'No Assets' ? ' disable-config-item ' : '')
                                    }
                                    onClick={() => loadConfigurePopUp(index)}>Parameters</div>
                                <div
                                    id={`source-${index}`}
                                    className={"selection_box editable_icon" +
                                        (formType && ['view', 'edit'].includes(formType) ? ' eye-open flex-item-row-2-view' : ' flex-item-row-2')}
                                    onClick={() => loadIdentitiesPopUp(index)}>{
                                        i?.identity?.type[0] === 'No Identities' ? 'No Identities' :
                                            i.identity?.pattern && Object.keys(i.identity?.pattern).length ? 'Custom List' : i.identity.type[0]
                                    }</div>
                                <div
                                    id={`intermediary-${index}`}
                                    className={"selection_box editable_icon" +
                                        (formType && ['view', 'edit'].includes(formType) ? ' eye-open flex-item-row-2-view' : ' flex-item-row-2')}
                                    onClick={() => loadIntermediaryPopUp(index)}>{
                                        i?.intermediary?.type[0] === 'Any Access Control' ? 'Any Access Control' :
                                            i.intermediary?.pattern && Object.keys(i.intermediary?.pattern).length ? 'Custom List' : i.intermediary.type[0]
                                    }</div>
                                <div
                                    id={`destination-${index}`}
                                    className={"selection_box editable_icon" +
                                        (formType && ['view', 'edit'].includes(formType) ? ' eye-open flex-item-row-3-view' : ' flex-item-row-3')
                                    }
                                    onClick={() => loadAssetsPopUp(index)}>{
                                        i?.asset?.type[0] === 'No Assets' ? 'No Assets' :
                                            i.asset?.pattern && Object.keys(i.asset?.pattern).length ? 'Custom List' : i.asset.type[0]
                                    }</div>
                                {
                                    (formType && ['view', 'edit'].includes(formType)) ? null :
                                        < div
                                            onClick={() => deleteConfig(index)}
                                            className={"delete-icon" + (configState?.length === 1 ? ' disable-item disable-config-item' : ' ')}>
                                            <TrashIcon />
                                        </div>}
                            </div>
                            </span >
                        })
                    }
                </div>
                {
                    (formType && ['view', 'edit'].includes(formType)) ? null :
                        < div className={`add-filter-item-unauth`}>
                            <span className="add-filter-unauth" onClick={onAddHandler}>+</span>
                        </div>}
            </td>
        </>
        {
            showPopUp.showConfigure ? <UnauthorizedAssetAccessIssueConfig
                handleClose={resetShowState}
                handleSave={saveParams}
                state={showPopUp.customData}
                formType={formType}
            /> : null
        }
        {
            showPopUp.showId && <IdPopUp
                formType={formType || 'create'}
                handleCloseIdAssetPopUp={resetShowState}
                handleSaveIdAssetPopUp={(e: IIdAssetState) => saveIdentities(e)}
                patternFormConfig={IdPattern}
                popFormConfig={IdPatternFencingConfig}
                popUpTitle="Authorized Identities & Hosts"
                state={showPopUp.customData}
                issueType={RuleType.UNAUTHORIZED_ASSET_ACCESS}
            />
        }

        {
            showPopUp.showIntermediary && <IdPopUp
                formType={formType || 'create'}
                handleCloseIdAssetPopUp={resetShowState}
                handleSaveIdAssetPopUp={(e: IIdAssetState) => saveIntermediary(e)}
                patternFormConfig={dirFields[showPopUp.currentEdit]?.asset ? InterPattern.filter(i => !['dir_name', 'dir_hostname'].includes(i.id)) : InterPattern}
                popFormConfig={InterPatternFormConfig}
                popUpTitle="Authorized Access Control"
                state={showPopUp.customData}
                issueType={RuleType.UNAUTHORIZED_ASSET_ACCESS}
            />
        }


        {
            showPopUp.showAsset && <IdPopUp
                formType={formType || 'create'}
                handleCloseIdAssetPopUp={resetShowState}
                handleSaveIdAssetPopUp={(e: IIdAssetState) => saveAssets(e)}
                patternFormConfig={dirFields[showPopUp.currentEdit]?.intermediary ? AssetPattern.filter(i => !['dir_name', 'dir_hostname'].includes(i.id)) : AssetPattern}
                popFormConfig={AssetPatternFencingConfig}
                popUpTitle="Authorized Assets"
                state={showPopUp.customData}
            />
        }

        {
            showErrorPopup?.show && <Ref.ErrorPopUp error={showErrorPopup.error}
                contentDescription="New config row cannot be added because of the following errors:"
                handleClose={() => setShowErrorPopUp({
                    show: false, error: ''
                })} />
        }

    </>
}

export default UnauthAssetAccessConfig;