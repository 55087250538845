import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import purify from 'dompurify';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import ReactPaginate from 'react-paginate';
import { Switch, useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import Popup from 'reactjs-popup';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { AddExceptionPopUp } from '../../../common/AddExceptionPopUp/AddExceptionPopUp';
import { ExceptionSearchFields } from '../../../common/AddExceptionPopUp/constant/ExceptionSearchField';
import { PostureMarkKnownPopUp } from '../../../common/MarkKnownPopUp/PostureMarkKnownPopUp';
import { useAuthDetails } from '../../../components/Authorization';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AMBreadcrumb } from '../../../components/core/AMBreadcrump/AMBreadcrumb';
import { AMCheckbox } from '../../../components/core/AMCheckbox/AMCheckbox';
import { AMConfirmPassword } from '../../../components/core/AMConfirmPassword/AMConfirmPassword';
import { AMCountryFlag } from '../../../components/core/AMCountryFlag/AMCountryFlag';
import { IssueFilters, IssueFlows, IssueObj, IssueParams, MatchedRuleData, ServerResponse } from '../../../types/response-types';
import { getRisk } from '../../../utils/risk-level';
import { getCategories, getRoundedUnit, getTimeDiffString, isPrivateIp, isValidIp } from '../../../utils/util-methods';
import FlowTrend from '../flow-trend/flow-trend';
import '../issue_prev.css';
import MatchruleDetails from '../matchrule-popup/matchrule';
import MoreActionsPopup from '../more-actions-popup/more-actions';
import { ReportByIssueFlows } from '../sub';
import { MarkKnownIPPopUp } from '../sub/MarkKnownIPPopUp';
import { SuspectedList } from '../sub/SuspectedList';
import { issueTemplate, notSupportedKeys, supportedOperators } from './constant/issueTemplate';
import Tags from './tags/tags';
import { PlaybookMap, RuleType } from '../../playbooks/constants/Constants';
import { usePostureAction } from '../../identity_asset/ref/Hooks/PostureAction';
import { PostureMenuItemAction } from '../../identity_asset/ref/PostureActionMenus/PostureActionMenu';
import AMMultiCheckbox, { IAMMultiCheckboxMenuItem } from '../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import { ActionMenu } from '../../../theme/ActionMenu';
import { CategoriesFields } from '../../../constants/Categories';
import { PostureCategory } from '../../identity_asset/ref/PostureCategories/PostureCategory';
import { DirectoryCategory } from '../../identity_asset/ref/PostureCategories/DirectoryCategory';
import { GLOBAL_SERVICE } from '../../services/GlobalService';
import { usePartnerStateContext } from '../../../store/PartnerContextProvider';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch } from '../../../components/core/PostureFilter/posture-filter-utils';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import { useTimeBasedSearchActions } from '../../../components/TimeBasedSearch/TimeBasedSearchActions';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';
import { PlaybookModal } from '../../playbooks/ref/AdvancedPlaybook/PlaybookModal';
import { GlobalConsts } from '../../../GlobalConst';

const classArray = ['navbar_inner', 'container', 'page_title_area',
    'widget_summary_box', 'inc-ruler', 'flow_table_container'];
const SuspectedPbList = [
    RuleType.SUSPECTED_ATT_DIS_ACC.toString(), RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_ATT_EXP_ACC.toString(),
    RuleType.SUSPECTED_ATT_LOCK_ACC.toString(), RuleType.SUSPECTED_DIR_BOT.toString(), RuleType.SUSPECTED_PASS_SPRAY.toString(),
    RuleType.ENUM_AD_ADMINS.toString(), RuleType.ENUM_AD_USERS.toString(), RuleType.UNAUTH_LOGIN_DEVICE.toString(),
    RuleType.UNAUTH_LOGIN_TIME.toString(), RuleType.NTLM_RELAY_ATTACK.toString(), RuleType.SUSPECTED_BRUTE_FORCE.toString()
]

const dir_names = ["onelogin", "one login", "one-login", "okta", "ping", "pingone", "ping identity", "ping-identity",
    "workspace", "google workspace", "google-workspace", "azure", "azure ad", "azure-ad", "microsoft"]

interface IssueObjProps {
    item: IssueObj
}

const Issues = ({ query, setQuery }: any) => {

    const search = useLocation().search;
    const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
    const [responseData, setResponseData] = useState<ServerResponse>();
    const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectRow, setSelectRow] = useState<any>({ '0': false });
    const [selectCount, setSelectCount] = useState(0);
    const [selectAllIndeterminate, setSelectAllIndeterminate] = useState(false);
    const [closeTicket, setCloseTicket] = useState(true);
    const [closeIncPopUp, setCloseIncPopUp] = useState(false);
    const [openConfirmPassword, setOpenConfirmPassword] = useState(false);
    const { authDetails } = useAuthDetails()!;
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false);
    const [opPassword, setOpPassword] = useState<any>('');
    const [openIssuesCount, setOpenIssuesCount] = useState<any>(0);
    const [openMatchRule, setOpenMatchRule] = useState(false);
    const [openFlow, setOpenFlow] = useState(false);
    const [openMoreDetails, setOpenMoreDetails] = useState(false);
    const [tableFetchError, setTableFetchError] = useState('No records found.');
    const [status_comment, setStatusComment] = useState();
    const [enableFilter, setEnableFilter] = useState(false);
    const [showAddException, setShowAddException] = useState(false);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [isClearAll, setIsClearAll] = useState(false);
    const [selectedIncidents, setSelectedIncidents] = useState<any>({});
    const [showMarkKnown, setShowMarkKnown] = useState(false);
    const [markKnownData, setMarkKnownData] = useState({});
    const [isMarkKnownQuery, setIsMarkKnownQuery] = useState<any>(undefined);
    const [excludeIncidents, sertExcludeIncidents] = useState<any>([]);
    const [knownIPData, setKnownIPData] = useState<any>(undefined);
    const [exceptionCriteria, setExceptionCriteria] = useState<string[]>([]);
    const [isCloseAll, setIsCloseAll] = useState(undefined);
    const [disableYes, setDisableYes] = useState(false);
    const [showSuspectedPopUp, setShowSuspectedPopUp] = useState<any>({
        showPopUp: false,
        response: []
    });

    const { isMarkKnownOptionAllowed, isAuthorizedAction } = usePostureAction();
    const [closeFromAction, setCloseFromAction] = useState(false);
    const [incidentToClose, setInidentToclose] = useState('');
    const { PartnerConfigState } = usePartnerStateContext()
    const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);

    const { getPresetActualTimeValue } = useTimeBasedSearchActions();

    const highlightSortDir = () => {
        if (query.sort_by === "gen_timestamp" && query.order_by === "asc") {
            setDateSortDir("tablesort_up_selected")
        } else if (query.sort_by === "gen_timestamp" && query.order_by === "desc") {
            setDateSortDir("tablesort_down_selected")
        } else if (query.sort_by === "issue_flows_count" && query.order_by === "asc") {
            setFlowSortDir("tablesort_up_selected")
        } else if (query.sort_by === "issue_flows_count" && query.order_by === "desc") {
            setFlowSortDir("tablesort_down_selected")
        } else if (query.sort_by === "playbook_name" && query.order_by === "asc") {
            setRuleSortDir("tablesort_up_selected")
        } else if (query.sort_by === "playbook_name" && query.order_by === "desc") {
            setRuleSortDir("tablesort_down_selected")
        } else if (query.sort_by === "_id" && query.order_by === "asc") {
            setIssueSortDir("tablesort_up_selected")
        } else if (query.sort_by === "_id" && query.order_by === "desc") {
            setIssueSortDir("tablesort_down_selected")
        } else if (query.sort_by === "sensor_location" && query.order_by === "asc") {
            setLocationSortDir("tablesort_up_selected")
        } else if (query.sort_by === "sensor_location" && query.order_by === "desc") {
            setLocationSortDir("tablesort_down_selected")
        } else if (query.sort_by === "issue" && query.order_by === "asc") {
            setPlaybookSortDir("tablesort_up_selected")
        } else if (query.sort_by === "issue" && query.order_by === "desc") {
            setPlaybookSortDir("tablesort_down_selected")
        } else if (query.sort_by === "source_type" && query.order_by === "asc") {
            setSrcTypeSortDir("tablesort_up_selected")
        } else if (query.sort_by === "source_type" && query.order_by === "desc") {
            setSrcTypeSortDir("tablesort_down_selected")
        } else if (query.sort_by === "destination_type" && query.order_by === "asc") {
            setDesTypeSortDir("tablesort_up_selected")
        } else if (query.sort_by === "destination_type" && query.order_by === "desc") {
            setDesTypeSortDir("tablesort_down_selected")
        } else if (query.sort_by === "source_name" && query.order_by === "asc") {
            setSrcNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "source_name" && query.order_by === "desc") {
            setSrcNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "destination_name" && query.order_by === "asc") {
            setDesNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "destination_name" && query.order_by === "desc") {
            setDesNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "source_hostname" && query.order_by === "asc") {
            setSrcIpSortDir("tablesort_up_selected")
        } else if (query.sort_by === "source_hostname" && query.order_by === "desc") {
            setSrcIpSortDir("tablesort_down_selected")
        } else if (query.sort_by === "destination_hostname" && query.order_by === "asc") {
            setDesIpSortDir("tablesort_up_selected")
        } else if (query.sort_by === "destination_hostname" && query.order_by === "desc") {
            setDesIpSortDir("tablesort_down_selected")
        } else if (query.sort_by === "destination_protocol" && query.order_by === "asc") {
            setProtocolSortDir("tablesort_up_selected")
        } else if (query.sort_by === "destination_protocol" && query.order_by === "desc") {
            setProtocolSortDir("tablesort_down_selected")
        } else if (query.sort_by === "status" && query.order_by === "asc") {
            setStatusSortDir("tablesort_up_selected")
        } else if (query.sort_by === "status" && query.order_by === "desc") {
            setStatusSortDir("tablesort_down_selected")
        } else if (query.sort_by === "risk" && query.order_by === "asc") {
            setRiskSortDir("tablesort_up_selected")
        } else if (query.sort_by === "risk" && query.order_by === "desc") {
            setRiskSortDir("tablesort_down_selected")
        } else if (query.sort_by === "first_flow_time" && query.order_by === "desc") {
            setDaysOpen("tablesort_down_selected")
        } else if (query.sort_by === "first_flow_time" && query.order_by === "asc") {
            setDaysOpen("tablesort_up_selected")
        } else {
            setDateSortDir("tablesort_down")
            setFlowSortDir("tablesort_down")
            setIssueSortDir("tablesort_down")
            setLocationSortDir("tablesort_down")
            setPlaybookSortDir("tablesort_down")
            setSrcTypeSortDir("tablesort_down")
            setDesTypeSortDir("tablesort_down")
            setSrcNameSortDir("tablesort_down")
            setDesNameSortDir("tablesort_down")
            setSrcIpSortDir("tablesort_down")
            setDesIpSortDir("tablesort_down")
            setProtocolSortDir("tablesort_down")
            setStatusSortDir("tablesort_down")
            setRiskSortDir("tablesort_down")
        }
    }

    useEffect(() => {
        const signal: CancelTokenSource = axios.CancelToken.source();
        let tempTags: string[] = []
        if (param.q) {
            // tempTags = [...param.q];
            tempTags = q.split("+");
        }
        if (param.rule_name && !tempTags.includes(param.rule_name)) {
            tempTags.push(param.rule_name);
        }
        if (param.risk && !tempTags.includes(param.risk.toString())) {
            tempTags.push(param.risk.toString());
        }
        if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
            tempTags.push(param.d_protocol);
        }
        if (param.d_name && !tempTags.includes(param.d_name)) {
            tempTags.push(param.d_name);
        }
        if (param.hash && !tempTags.includes(param.hash)) {
            tempTags.push(param.hash);
        }
        if (param.s_time && param.e_time && !tempTags.includes(param.s_time.toString()) && !tempTags.includes(param.e_time.toString())) {
            tempTags.push(param.s_time.toString(), param.e_time.toString());
        }
        // console.log('TEMP TAGS : ', tempTags);
        console.log('Params are ', param);
        if (tempTags.length > 0) {
            setTags(tempTags);
            setTagFlag(false);
            let isExceptioField = false;
            let isAssetName = false;
            if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
                setTimeout(() => {
                    tempTags.forEach((item: string) => {
                        if ((ExceptionSearchFields.includes(item.split(':')[0])
                            || ExceptionSearchFields.includes(item.split('~')[0])
                            || ExceptionSearchFields.includes(item.split('|')[0]))
                            && item.split(':')[0] != 'rule_name'
                            && item.split('~')[0] != 'rule_name'
                            && item.split('|')[0] != 'rule_name') {
                            const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
                            const el = document.getElementsByClassName(`tag-${item.substring(0, index)}`)[0];
                            if (el) {
                                el?.classList.add('yellow-border');
                                isExceptioField = true;
                            }
                            if ((item.split(':')[0] == `d_name` || item.split('~')[0] == `d_name` || item.split('|')[0] == `d_name`) && el) {
                                el?.classList.add('asset-name-border');
                                isAssetName = true;
                            }
                        }
                    });

                    const btn = document.getElementById('issue-more-action-btn-grp');
                    if (btn) {
                        if (isExceptioField) {
                            btn?.classList.add('exception-border');
                        } else {
                            btn?.classList.remove('exception-border');
                        }
                    }
                    if (document.getElementById('issue-more-action-btn-grp')) {
                        isAssetName ? document.getElementById('issue-more-action-btn-grp')?.classList.add("asset-border") :
                            document.getElementById('issue-more-action-btn-grp')?.classList.remove("asset-border")
                    }

                }, 500)
            }

        }
        highlightSortDir();
        if (!history.location.pathname.includes("matchrule") && !history.location.pathname.includes("flow")) {
            if (!isInitialLoad) {
                fetchIssues(signal.token);
            }
        }
        return () => {
            signal.cancel('Api call cancelled...');
        }
    }, [query])

    useEffect(() => {
        // console.log('ISS FIRST RENDER q: ',query);
        // param.q=undefined;
        // param.order_by=undefined;
        // param.page=undefined;
        // param.sort_by=undefined;
        // param.rpp=undefined;
        // setQuery(param);
        // fetchIssues();
        fetchWidget(10, "rules", false);
        fetchWidget(10, "playbooks", false);
        fetchWidget(10, "identities", false);
        fetchWidget(10, "assets", false);
        fetchWidget(10, "protocols", false);
        /* fetchFilters(); */

    }, [])


    //Incident Filters Declarations
    const [filterData, setFilterData] = useState<IssueFilters | undefined>();
    const [filterStatus, setFilterStatus] = useState<Boolean>(false);
    const handleFilterWidget = (widgetState: any) => {
        if (filterData) {
            setopenPanelFilterWidgets(widgetState)
        } else {
            addToast('Unable to apply filters, please retry in few minutes or contact administrator.', { appearance: 'error' });
        }
    }

    const handleFilterDisabled = (widgetState: any) => {
        setDisabledFilter(widgetState)
        reloadIssuesDisabled(widgetState);
    }

    const fetchFilters = () => {
        const queryData = query?.q?.split('+') || [];
        const searchHeaders = {
            headers: {
                search_fields: {}
            }
        } as any;
        if ((queryData || []).length > 0) {
            const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, queryData);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }
        Api.get("/issues/filter", {
            params: param,
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            headers: searchHeaders.headers
        })
            .then(res => {
                setEnableFilter(true);
                // setLoader(false);
                if (res.status === 200) {

                    if (Object.keys(res.data.ifp.dst_filter).length ||
                        res.data.ifp.excluded_protocols !== null ||
                        res.data.ifp.excluded_risk !== undefined ||
                        res.data.ifp.excluded_rules !== undefined ||
                        res.data.ifp.excluded_status !== null ||
                        res.data.ifp.excluded_sensor_locations !== undefined ||
                        Object.keys(res.data.ifp.src_filter).length) {
                        setFilterStatus(true)
                    }
                    else setFilterStatus(false)
                    setFilterData(res.data);

                }
            })
            .catch(err => {
                console.log(err);
                setEnableFilter(true);
            })
    }

    const validateLocalIp = (data: Array<IssueObj>) => {
        const res = data.map((i: IssueObj) => {
            if (i?.source_hostnames?.length > 0) {
                const d = i?.source_hostnames?.filter((s: any) => !s?.sourceIp || !s?.source_ip.startsWith('127.')) as [];
                i.source_hostnames = d;
            }
            if (i.directory_ip?.startsWith('127.')) {
                i.directory_ip = 'N.A.'
            }

            if (i?.destination_hostname?.startsWith('127.')) {
                i.destination_hostname = 'N.A.'
            }

            /* if (i?.latest_source_hostname?.startsWith('127.')) {
                i.latest_source_hostname = 'N.A.'
            } */
            return i;
        })
        return res;
    }

    const getCategoryKeyFromValue = (categoriesList: Array<string>) => {
        let categories = getCategories() as any;
        if (categories) {
            const result = []
            categories = JSON.parse(categories);
            for (const key in categories) {
                if (categoriesList.includes(categories[key])) {
                    result.push(parseInt(key));
                }
            }
            return result;
        }
        return [];
    }

    const generateTagsFromSearch = async (data: any) => {
        await GLOBAL_SERVICE.Category.GET();
        const searchTextList = [];
        if (Object.keys(data).length > 0) {
            // update tags array on Apply AdvanceSearch (Merge only)			
            for (let filter in data) {
                let filterValue: any = data[filter]?.value;
                let opr =
                    supportedOperators.get(String(data[filter]?.type)) || '';
                if (['first_flow_time', 'last_flow_time'].includes(filter) && opr == 'last_in') {
                    filterValue[1] = filterValue[1] == 'day(s)' ? 'day' : 'hour'
                }
                if ([TIME_BASED_SEARCH_FIELD].includes(filter)) {
                    filterValue = getPresetActualTimeValue(filterValue);
                }
                if (filter === 'any_field') {
                    filterValue.forEach((p: String) => {
                        if (p) {
                            //addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
                            searchTextList.push(p);
                        }
                    });
                    continue;
                } else if (CategoriesFields.includes(filter)) {
                    filterValue = getCategoryKeyFromValue(filterValue);
                }
                //addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
                searchTextList.push(`${filter}${opr}${filterValue}`);
            }
            if (searchTextList.length > 0) {
                // const merged = Array.from(addedTags.values());// Change Requirement reverting logic
                return searchTextList;
            }
        }
    };

    const fetchIssues = async (cancelToken?: CancelToken) => {
        const searchHeaders = { headers: { search_fields: '' } };
        let searchTags: String[] = [];
        setLoader(true);
        if (param.q && param.q?.split('+').length > 0) {
            const tags = param.q?.split('+');
            searchTags = tags;
            // if (tags.findIndex((item: string) => item.includes('Shadow Assets/Directory')) > -1) {
            //     const tagIndex = tags.findIndex((item: string) => item.includes('Shadow Assets/Directory'));
            //     let tagString = tags[tagIndex];
            //     tagString = tagString.replace('Shadow Assets/Directory', 'Shadow Assets');
            //     tags[tagIndex] = tagString;
            //     param.q = tags.join('+');
            // }
        }

        const fields = getSearchFilterObjectFromTags(issueTemplate, searchTags);
        const que = await generateTagsFromSearch(fields);
        if ((searchTags || []).length > 0) {
            const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, que || [], true);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
            // const que = generateTagsFromSearch(searchFieldsList);
            param.q = que?.join('+');
        }
        Api.get("/issues", {
            params: param,
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            cancelToken: cancelToken,
            ...searchHeaders
        })
            .then(res => {
                setIsInitialLoad(false);
                setLoader(false);
                if (res.status === 200) {
                    res.data.result.forEach((i: any, index: number) => {
                        if (i.source_hostnames && i.source_hostnames.length > 0) {
                            i.source_hostnames = i.source_hostnames.reverse();
                        }
                    });
                    res.data.result = validateLocalIp(res.data.result);
                    setTotalCount(res.data.total);
                    setResponseData(res.data);

                    setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'))
                    if (res.data.open_issue_count == 0) {
                        // setTotalCount(0);
                    }
                    setOpenIssuesCount(res.data.open_issue_count);


                    if (param?.disable_filter == false && res.data.result.length == 0) {
                        setTableFetchError('No incidents found. This might be due to the exclude filters.')
                    } else if (res.data.result.length == 0) {
                        setTableFetchError('No records found.')
                    }
                }
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    return;
                }
                setIsInitialLoad(false);
                console.log(err);
                setResponseData({ result: 'error' } as ServerResponse);
                setLoader(false);
                addToast("Error while fetching data.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
                setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
            })
    }

    const fetchWidget = (count: any, summaryBy: any, hardRefresh: boolean) => {
        Api.get("/issues/summary?count=" + count + "&summary_by=" + summaryBy + `${hardRefresh ? "&hard_refresh=true" : ""}`)
            .then(res => {
                if (res.status === 200 && res.data !== undefined && Object.keys(res.data).length !== 0) {
                    //Set Highcharts data for widgets
                    if (summaryBy === "rules") setIssueRules(res.data)
                    else if (summaryBy === "playbooks") setIssuePlaybooks(res.data)
                    else if (summaryBy === "assets") setIssueAssets(res.data)
                    else if (summaryBy === "identities") setIssueIdentities(res.data)
                    else if (summaryBy === "protocols") setIssueProtocols(res.data)

                }
            })
            .catch(err => console.log)
    }

    const reloadIssues = () => {
        reloadRef.current.classList.add('reload_spin');
        param.hard_refresh = true;
        setQuery(param)
        if (!isInitialLoad) { fetchIssues(); }
        setIsInitialLoad(true);
        fetchWidget(10, "rules", true);
        fetchWidget(10, "playbooks", true);
        fetchWidget(10, "identities", true);
        fetchWidget(10, "assets", true);
        fetchWidget(10, "protocols", true);
        /* fetchFilters(); */
        setTimeout(() => {
            if (reloadRef?.current?.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin')
            }
        }, 750)
        resetSelectionState();
    }

    const reloadIssuesDisabled = (disablSt: boolean) => {
        reloadRef?.current?.classList.add('reload_spin');
        param.hard_refresh = true;
        param.disable_filter = disablSt;
        setQuery(param)
        fetchIssues();
        fetchWidget(10, "rules", true);
        fetchWidget(10, "playbooks", true);
        fetchWidget(10, "identities", true);
        fetchWidget(10, "assets", true);
        fetchWidget(10, "protocols", true);
        /* fetchFilters(); */
        setTimeout(() => {
            if (reloadRef?.current?.classList.contains('reload_spin')) {
                reloadRef.current.classList.remove('reload_spin')
            }
        }, 750)
    }

    const {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh,
        summary: summary,
        disable_filter: disable_filter,
    } = query;

    const [dateSortDir, setDateSortDir] = useState<string | undefined>("tablesort_down");
    const [flowSortDir, setFlowSortDir] = useState<string | undefined>("tablesort_down");
    const [issueSortDir, setIssueSortDir] = useState<string | undefined>("tablesort_down");
    const [locationSortDir, setLocationSortDir] = useState<string | undefined>("tablesort_down");
    const [ruleSortDir, setRuleSortDir] = useState<string | undefined>("tablesort_down");
    const [playbookSortDir, setPlaybookSortDir] = useState<string | undefined>("tablesort_down");
    const [srcTypeSortDir, setSrcTypeSortDir] = useState<string | undefined>("tablesort_down");
    const [desTypeSortDir, setDesTypeSortDir] = useState<string | undefined>("tablesort_down");
    const [srcNameSortDir, setSrcNameSortDir] = useState<string | undefined>("tablesort_down");
    const [desNameSortDir, setDesNameSortDir] = useState<string | undefined>("tablesort_down");
    const [srcIpSortDir, setSrcIpSortDir] = useState<string | undefined>("tablesort_down");
    const [desIpSortDir, setDesIpSortDir] = useState<string | undefined>("tablesort_down");
    const [protocolSortDir, setProtocolSortDir] = useState<string | undefined>("tablesort_down");
    const [actionSortDir, setActionSortDir] = useState<string | undefined>("tablesort_down");
    const [statusSortDir, setStatusSortDir] = useState<string | undefined>("tablesort_down");
    const [riskSortDir, setRiskSortDir] = useState<string | undefined>("tablesort_down");
    const [daysOpen, setDaysOpen] = useState<string | undefined>("tablesort_down");
    const [open, setOpen] = useState<boolean | undefined>();
    const [report, setReport] = useState<boolean | undefined>();

    const [searchInput, setSearchInput] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [tagFlag, setTagFlag] = useState<boolean | undefined>();
    const [flowsTrendResponseData, setFlowsTrendResponseData] = useState<any>(undefined);
    const [flowsChartDetails, setFlowsChartDetails] = useState<any>({ issueId: 0, risk: '', createdDate: null });
    const [totalCount, setTotalCount] = useState<number>();
    const [lastUpdate, setLastUpdate] = useState<string>();
    const [matchedRuleData, setMatchedRuleData] = useState<MatchedRuleData>();
    const [issueRules, setIssueRules] = useState<IssueFlows | undefined>();
    const [issuePlaybooks, setIssuePlaybooks] = useState<IssueFlows | undefined>();
    const [issueIdentities, setIssueIdentities] = useState<IssueFlows | undefined>();
    const [issueAssets, setIssueAssets] = useState<IssueFlows | undefined>();
    const [issueProtocols, setIssueProtocols] = useState<IssueFlows | undefined>();

    const [ticketId, setTicketId] = useState<number>();

    const [openPanelFilterWidgets, setopenPanelFilterWidgets] = React.useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false);

    let history = useHistory();
    const { addToast } = useToasts();
    const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    let param: IssueParams = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort,
        rule_name: rule_name,
        d_protocol: d_protocol,
        risk: risk,
        hash: hash,
        d_name: d_name,
        s_time: s_time,
        e_time: e_time,
        hard_refresh: hard_refresh,
        summary: summary,
        disable_filter: disable_filter,
    };

    moment.updateLocale('en', { relativeTime: { d: '1 day' } })

    const LoadSorting = (field: string) => {
        if (param.sort_by === field) {
            param.order_by = param.order_by === "asc" ? "desc" : "asc";
            setQuery(param);
        } else {
            param.sort_by = field;
            param.order_by = "desc";
            setQuery(param);
        }
    };

    const SortBy = (name: string) => {
        if (param.sort_by === name) {
            return param.order_by === "desc"
                ? "tablesort_down"
                : "tablesort_up";
        }
    };

    const SortBySrc = (name: string) => {
        if (param.sort_by === name) {
            return param.order_by === "desc"
                ? "sort_src_up"
                : "sort_src_up";
        }
    };

    const SortByDes = (name: string) => {
        if (param.sort_by === name) {
            return param.order_by === "desc"
                ? "sort_des_up"
                : "sort_des_up";
        }
    };

    const handleSort = (e: React.MouseEvent) => {

        if (e.currentTarget.textContent === "Last Access Time") {
            LoadSorting("gen_timestamp");
            setDateSortDir(SortBy("gen_timestamp"));
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "# Flows") {
            LoadSorting("issue_flows_count");
            setDateSortDir("tablesort_down");
            setFlowSortDir(SortBy("issue_flows_count"));
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Incident #") {
            LoadSorting("_id");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir(SortBy("_id"));
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Sensor Site Code") {
            LoadSorting("sensor_location");
            setLocationSortDir(SortBy("sensor_location"));
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Matching Playbook") {
            LoadSorting("playbook_name");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir(SortBy("playbook_name"));
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Issue Highlights") {
            LoadSorting("issue");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir(SortBy("issue"));
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.id?.includes("src_type")) {
            LoadSorting("source_type");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir(SortBySrc("source_type"));
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.id?.includes("des_type")) {
            LoadSorting("destination_type");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir(SortByDes("destination_type"));
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.id === "src_name") {
            LoadSorting("source_name");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir(SortBySrc("source_name"));
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.id === "des_name") {
            LoadSorting("destination_name");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir(SortByDes("destination_name"));
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.id === "src_ip") {
            LoadSorting("source_hostname");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir(SortBySrc("source_hostname"));
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.id === "des_ip") {
            LoadSorting("destination_hostname");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir(SortByDes("destination_hostname"));
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Protocol") {
            LoadSorting("destination_protocol");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir(SortBy("destination_protocol"));
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Actions") {
            LoadSorting("action");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir(SortBy("action"));
            setStatusSortDir("tablesort_down");
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Status") {
            LoadSorting("status");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir(SortBy("status"));
            setRiskSortDir("tablesort_down");
            setDaysOpen("tablesort_down");
        }
        else if (e.currentTarget.textContent === "Risk") {
            LoadSorting("risk");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setRiskSortDir(SortBy("risk"));
            setDaysOpen("tablesort_down");
        } else if (e.currentTarget.textContent = 'Open Since') {
            LoadSorting("first_flow_time");
            setDateSortDir("tablesort_down");
            setFlowSortDir("tablesort_down");
            setIssueSortDir("tablesort_down");
            setLocationSortDir("tablesort_down");
            setRuleSortDir("tablesort_down");
            setPlaybookSortDir("tablesort_down");
            setSrcTypeSortDir("tablesort_down");
            setDesTypeSortDir("tablesort_down");
            setSrcNameSortDir("tablesort_down");
            setDesNameSortDir("tablesort_down");
            setSrcIpSortDir("tablesort_down");
            setDesIpSortDir("tablesort_down");
            setProtocolSortDir("tablesort_down");
            setActionSortDir("tablesort_down");
            setStatusSortDir("tablesort_down");
            setDaysOpen(SortBy("first_flow_time"));
        }
    }

    const [summaryFlag, setSummaryFlag] = useState((param?.summary === undefined && param.q !== undefined && param.q !== "") ? true : false);

    const handleSummary = () => {
        setSummaryFlag(flag => !flag);
    }

    const handleFlowClick = (event: any, data: any) => {
        setOpen(true);
        setOpenFlow(true);
        let { id: issueId, risk } = data;
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(`flow-trend-${issueId}`);
        const flowTrendURL = `/issues/issueflowcounts/${issueId}`;


        if (!cachedFlowData || ((JSON.parse(cachedFlowData)?.expiry_time) < (currentTime))) {
            Api.get(flowTrendURL).then(((res: any) => {
                if (!res?.data?.length || res?.data?.length <= 0) {
                    setFlowsTrendResponseData({ flows: [], expiry_time: currentTime + 600000 });

                    // Chart header and color
                    const chartTitle = <>{`Incident ${issueId} - Flows Trend`}</>;
                    setFlowsChartDetails({ chartTitle, risk, itemData: { ...data, pageType: 'issue' } });

                    return;
                }

                let result = JSON.parse(JSON.stringify(res?.data)) || [];
                result.sort((a: any, b: any) => (a?.time < b?.time) ? -1 : 1);
                const cachedFlows = { flows: result, expiry_time: currentTime + 600000 };
                setFlowsTrendResponseData(cachedFlows);

                // Chart header and color
                const startFromDate = Math.max(moment(result?.[0]?.time).valueOf(), moment().subtract(90, 'days').valueOf());
                const chartTitle = <>{`Incident ${issueId} - Flows Trend (${getTimeDiffString(startFromDate)})`}</>;
                setFlowsChartDetails({ chartTitle, risk, itemData: { ...data, pageType: 'issue' } });

                sessionStorage.setItem(`flow-trend-${issueId}`, JSON.stringify(cachedFlows));
            }
            )).catch(er => {
                console.log(er);
            });
        }
        else {
            const parsedData = JSON.parse(cachedFlowData);
            setFlowsTrendResponseData(parsedData);

            const { flows } = parsedData || [];
            // Chart header and color
            const startFromDate = Math.max(moment(flows[0]?.time).valueOf(), moment().subtract(90, 'days').valueOf());
            const chartTitle = <>{`Incident ${issueId} - Flows Trend (${getTimeDiffString(startFromDate)})`}</>;
            setFlowsChartDetails({ chartTitle, risk, itemData: { ...data, pageType: 'issue' } });
        }
    }

    useEffect(() => {
        if (!openFlow) {
            setFlowsTrendResponseData(undefined);
        }
    }, [openFlow])

    const handleMatchruleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
        let issueId = e.currentTarget.id;
        let issue = responseData?.result.find((issue: IssueObj) => issue._id === +issueId);
        const flows = responseData?.result.find((issue: IssueObj) => issue._id === +issueId)?.issue_flows;
        let flowId = null;
        if (issue.latest_flow_id) {
            flowId = issue.latest_flow_id;
        } else if (flows && flows.length > 0) {
            flowId = flows[flows.length - 1];
            if (flowId) {
                flowId = flowId.flow_id;
            }
        }
        if (issue) {
            setMatchedRuleData({
                matching_rule_details: issue.matching_rule_details,
                matching_rule_name: issue.matching_rule_name,
                matched_flow: issue.matched_flow,
                issueId: issueId.toString(),
                incident_summary: issue?.incident_summary,
                flowId,
                dest_is_directory: issue?.dest_is_directory,
                playbook: issue.playbook_name,
                status: issue?.status,
                genTimestamp: issue?.gen_timestamp,
                suspected_source_info: issue?.suspected_source_info,
                sourceName: issue?.source_name,
                misc: issue,
                playbook_id: issue.playbook_id,
                closure_reason: issue.closure_reason
            })
        }
        // history.push("/issues/matchrule")
        setOpenMatchRule(true);
    }

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>) => {
        history.push("/tickets/" + e.currentTarget.id);
    }

    const handleCreate = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
        setTicketId(parseInt(e.currentTarget.id));
        // history.push("/issues/createissue");
        setOpenMoreDetails(true);
    }

    const handleTicketCreate = () => {
        Api.post("/tickets", {
            issue_id: ticketId
        })
            .then(res => {
                if (res.status === 200) {
                    addToast("Ticket created successfully.", {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    history.push("/tickets/" + ticketId);
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (err.response?.status === 401) {
                    window.location.href = '/login';
                }
            });
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const onSelectAllClick = (evt: any) => {

        if (!selectAll && !selectAllIndeterminate) {
            selectDeSelectAll(true);
            setSelectCount(openIssuesCount);
            const resultSet: any = {};
            if (responseData?.result) {
                Object.values(responseData?.result).forEach((item: any) => resultSet[item._id] = item);
                setSelectedIncidents(resultSet);
            }

        } else {
            selectDeSelectAll(false);
            setSelectCount(0);
            setSelectRow({ '0': false });
            setSelectedIncidents({});
        }
        setSelectAll(selectAllIndeterminate ? !selectAllIndeterminate : !selectAll);
        setSelectAllIndeterminate(false);
    }

    const onSelectRowClick = (evt: any, status: any, item: any, e: any) => {
        if (selectAll) {
            setSelectAll(!selectAll);
            setSelectAllIndeterminate(true);
        }
        let selectionArray = { ...selectRow };
        selectionArray[evt] = !getSelectRow(evt, status);
        setSelectRow(selectionArray);
        const incident = selectionArray[evt] ? { [evt]: item } : { [evt]: null };
        setSelectedIncidents({ ...selectedIncidents, ...incident })

        if (selectAll || selectAllIndeterminate) {
            const negativeCount = Object.values(selectionArray).filter(el => !el).length - 1;
            const selection = Object.values(selectionArray).filter(el => el).length;
            const count = openIssuesCount - negativeCount;
            setSelectCount(count);
            if (selectAllIndeterminate && count == openIssuesCount) {
                /* Part of https://authmind.atlassian.net/browse/A1Y-1511 */
                // setSelectAll(true);
                // setSelectAllIndeterminate(false);
            } else if (selectAllIndeterminate && count == 0 || selection == 0) {
                setSelectAllIndeterminate(false);
                setSelectAll(false);
                selectDeSelectAll(false);
            } else if (count < 0) {
                setSelectCount(selection);
            }
        } else {
            const select = Object.values(selectionArray).filter(el => el).length;
            setSelectCount(select);
            if (select == openIssuesCount) {
                setSelectAll(true);
            }
        }
        borderMarkKnown();
    }

    const getSelectRow = (id: any, status: any) => {
        if (selectAll) {
            return true;
        } else if (selectAll && status.toLowerCase() == 'open') {
            return true;
        } else if (selectRow.hasOwnProperty(id) && status.toLowerCase() != 'open' && selectAllIndeterminate) {
            return true;
        } else if (selectRow.hasOwnProperty(id) && status.toLowerCase() == 'open') {
            return selectRow[id];
        } else if (selectAllIndeterminate && status.toLowerCase() == 'open') {
            return selectRow[id] || true;
        }
        return false;
        // return (selectAll && status.toLowerCase() != 'open') || (selectRow[id] && status.toLowerCase() != 'open') || false;
    }

    const selectDeSelectAll = (flag: boolean) => {
        if (responseData?.result) {
            let selectionArray = { ...selectRow };
            responseData.result.forEach((item: any) => {
                selectionArray[item._id] = flag
            });
            setSelectRow(selectionArray);
        }
    }

    const isCloseIncEnabled = (): boolean => {
        return (selectCount > 0);
    }

    const handleCloseIncPopUp = () => {
        setCloseIncPopUp(false);
        setCloseFromAction(false);
        setIsCloseAll(undefined)
    }

    const confirmPasswordOperation = (e: any) => {
        if (!closeFromAction) {
            if (selectAll || selectAllIndeterminate) {
                handleAllSelection();
            } else {
                handleManualSelection();
                setCloseIncPopUp(false);
            }
        } else {
            handleIncidentCloseSelection();
            setCloseFromAction(false);
            setCloseIncPopUp(false);
        }
    }

    const handlOkIncPopUp = () => {
        setOpenConfirmPassword(true);
        setCloseIncPopUp(false);
    }

    const handleCloseTicket = () => {
        setCloseTicket(!closeTicket);
    }

    const closeTickets = () => {
        setCloseIncPopUp(true);
        setCloseTicket(true);
    }

    const closeTicketsFromAction = (id: string) => {
        setCloseFromAction(true);
        setInidentToclose(id)
        closeTickets();

    }

    const getSelectRows = () => {
        const selectedRows = [];
        for (const key in selectRow) {
            if (selectRow[key]) {
                selectedRows.push(parseInt(key));
            }
        }
        return selectedRows;
    }

    const getUnSelectRow = () => {
        const unselectedRow = [];
        for (const key in selectRow) {
            if (selectRow[key] == false && key != '0') {
                unselectedRow.push(parseInt(key));
            }
        }
        return unselectedRow;
    }

    const handleAllSelection = () => {
        const queryData = query?.q?.split('+') || [];
        let searchFieldsList = {};
        const searchHeaders = {
            headers: {
                search_fields: {}
            }
        }
        if ((queryData.filter(i => i) || []).length > 0) {
            searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, queryData);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }

        if (searchFieldsList &&
            Object.keys(searchFieldsList).filter((i) => i != 'status').length == 0
            && isCloseAll == undefined && getUnSelectRow().length == 0) {
            setDisableYes(true);
            return
        }

        if (searchFieldsList &&
            Object.keys(searchFieldsList).filter((i) => i != 'status').length == 0
            && isCloseAll && getUnSelectRow().length == 0) {
            searchFieldsList = {};
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }

        setDisableYes(false);

        const data = {
            'close_option': 2,
            'search_query': searchFieldsList,
            'disable_filter': query?.disable_filter == 'true' || false,
            'issues_id_list': [],
            'unchecked_issues_id_list': getUnSelectRow(),
            'close_ticket': closeTicket,
            status_comment,
            is_close_all: isCloseAll ? true : false
            // 'password': opPassword
        }
        postCloseInc(data, searchHeaders);
        setCloseIncPopUp(false);
        setIsCloseAll(undefined)
    }

    const handleManualSelection = () => {
        setCloseIncPopUp(false);
        const data = {
            'close_option': 1,
            'search_query': {},
            'disable_filter': query?.disable_filter == 'true' || false,
            'issues_id_list': getSelectRows(),
            'unchecked_issues_id_list': [],
            'close_ticket': closeTicket,
            // 'password': opPassword
            status_comment
        }
        postCloseInc(data);

    }

    const handleIncidentCloseSelection = () => {
        setCloseIncPopUp(false);
        const data = {
            'close_option': 1,
            'search_query': {},
            'disable_filter': query?.disable_filter == 'true' || false,
            'issues_id_list': [parseInt(incidentToClose)],
            'unchecked_issues_id_list': [],
            'close_ticket': closeTicket,
            // 'password': opPassword
            status_comment
        }
        postCloseInc(data);

    }

    const postCloseInc = (data: any, headers?: any) => {
        const tableEl = document.getElementsByClassName('tab-panel')[0];
        tableEl?.classList.add('disableItems');
        setOpPassword('');
        Api.post("/issues/bulkclose", data, headers)
            .then(res => {
                if (res.status === 200) {
                    /* addToast("Bulk close incident request sent successfully.",{
                        appearance:'success',
                        autoDismiss: true
                    }); */
                    if (!closeFromAction) {
                        addToast(`Closing of ${selectCount} incident` + (selectCount == 1 ? '' : 's') + ` is in progress and might take up to a few minutes.`, {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    } else {
                        addToast(`Closing of 1 incident is in progress and might take up to a few minutes.`, {
                            appearance: 'success',
                            autoDismiss: true
                        });

                    }
                    param.page = 1;
                    resetSelectionState();
                    reloadIssues();
                    tableEl?.classList.remove('disableItems');
                }
            })
            .catch((err: AxiosError) => {
                tableEl?.classList.remove('disableItems');
                if (err.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (err.response?.status === 401) {
                    window.location.href = '/login';
                } else if (err.response?.status === 403) {
                    addToast("Bulk close incident can not be performed due to invalid credentials.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
        setOpenConfirmPassword(false)
    }

    const closeConfirmPassword = () => {
        setOpenConfirmPassword(false)
    }

    useEffect(() => {
        updateScreenWidths(true);
    });

    useEffect(() => {
        return () => {
            // Anything in here is fired on component un-mount.
            updateScreenWidths(false);
        }
    }, [])

    const updateScreenWidths = (remove: any) => {
        // if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
        //     for (let i = 0; i < classArray.length; i++) {
        //         const item = document.getElementsByClassName(classArray[i])[0];
        //         remove ? item?.classList.add('width1300') : item?.classList.remove('width1300');
        //     }
        // }
    }

    const resetSelectionState = () => {
        setSelectAllIndeterminate(false);
        setSelectAll(false);
        setSelectRow({ '0': false });
        setSelectCount(0);
        setCloseTicket(false);
        setOpPassword('');
        setSelectedIncidents({});
    }

    const isSelected = (inc: string) => {
        if (selectRow && selectRow.hasOwnProperty(inc)) {
            return selectRow[inc];
        }
        return selectAll || selectAllIndeterminate;
    }

    const handleMouseEnterHost = (index: number, hostname: string, e: any) => {
        const ele = e.target.parentElement.parentElement.getElementsByClassName(`host-name-list-${index}-${hostname}`)[0];
        if (ele && ele?.classList) {
            ele.classList.add('show-block');
            ele.classList.remove('hidden-block');
        }
    }

    const handleMounseLeaveHost = (index: number | null, hostname: string | null, e: any) => {
        if (!index && !hostname) {
            console.log('Scrolling')
            const ele = e.target.getElementsByClassName(`host-details-tooltip`);
            if (ele && ele.length)
                Array.from(ele).forEach((it: any) => {
                    it?.classList.add('hidden-block');
                    it?.classList.remove('show-block');
                })
        } else {
            const ele = e.target.getElementsByClassName(`host-name-list-${index}-${hostname}`)[0];
            if (ele && ele?.classList) {
                ele?.classList.add('hidden-block');
                ele?.classList.remove('show-block');
            }
        }
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const action = e.currentTarget.dataset.action?.toString();
        if (action) {
            switch (action) {
                case 'go-to-ticket':
                    history.push("/tickets/" + e.currentTarget.id);
                    break;
                case 'create-ticket':
                    setOpen(true);
                    setTicketId(parseInt(e.currentTarget.id));
                    setOpenMoreDetails(true);
                    break;
                default: break;

            }
        }
        e.preventDefault()
    }

    useEffect(() => {
        let filterObj = getSearchFilterObjectFromTags(issueTemplate, tags);
        if (filterObj[TIME_BASED_SEARCH_FIELD]) {
            setIsTimeBasedSearchApplied(true);
        } else {
            setIsTimeBasedSearchApplied(false);
        }
    }, [tags])


    const renderData = (response: any) => {
        return response.map((item: IssueObj, index: number) => {
            return (
                <React.Fragment key={item.gen_timestamp + index}>
                    <tr className={(item.status === "Closed" ? 'gray_bg sd_row_sep' : item.status === "Resolved" ? 'light_gray_bg sd_row_sep' : isSelected(item._id.toString()) ? 'sd_row_sep selected-row' : 'sd_row_sep')}>
                        {
                            authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 60 : 30 }} rowSpan={2}><AMCheckbox onClick={onSelectRowClick.bind(this, item._id, item.status, item)} checked={getSelectRow(item._id, item.status)} disabled={item?.status.toLowerCase() != 'open'} /></td>
                                : null
                        }
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 328 : 164 }} colSpan={2} className="bd_dashed">{moment(item.gen_timestamp).format('MMM DD YYYY,')}&nbsp;{moment(item.gen_timestamp).format('h:mm A')}</td>
                        {/* <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 136 : 68 }} rowSpan={2}>{item._id}</td> */}
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 124 : 62 }} rowSpan={2} className="align_center">{item?.sensor_location}</td>
                        <td className='pb_name_td width150' rowSpan={2}>
                            <div className='pb_name_width125'>
                                <div className='marginBottom5'>
                                    {item?.playbook_name}
                                </div>
                                <div>[{item?.matching_rule_details.matching_field_name === 'am_destination.Protocol' ? (
                                    <span>Auth Protocol Quality</span>
                                ) : item?.matching_rule_name === 'Authentication Quality' ? (
                                    <span>Auth Hash Quality</span>
                                ) : item?.matching_rule_name === 'Suspected Attack on Expired AD Account' ? (
                                    <span>Suspected Attack on Expired AD Account/Password</span>
                                ) : <span>{getIssueNameById(item?.matching_rule_name)}</span>
                                }]</div>
                            </div>

                        </td>
                        <td rowSpan={2} className='width100'>
                            <span className="pb_name">
                                <div dangerouslySetInnerHTML={{ __html: purify.sanitize(item.issue || '') }} style={{ textWrap: 'pretty' }} />
                                <div className="shadowbox width100"
                                    id={item._id.toString()} onClick={handleMatchruleClick}>More Details
                                </div>
                            </span>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 114 : 50 }} className="type_col_width">
                            {/* {item.src_ip_reputation === 2 ? 
                                <div className="reputation_icon_gray">
                                    <div className="titletip">
                                        {item.src_ip_fraud_score ?
                                            <span>IP Fraud Score: {item.src_ip_fraud_score} (out of 100)</span> : null
                                        }
                                    </div>
                                </div> 
                            : null 
                            } */}

                            {item.src_ip_reputation === 3 ?
                                <div className="reputation_icon_orange">
                                    <div className="titletip">
                                        {item.src_ip_fraud_score ?
                                            <span>IP Fraud Score: {item.src_ip_fraud_score} (out of 100)</span> : null
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {item.src_ip_reputation === 4 ?
                                <div className="reputation_icon_red">
                                    <div className="titletip">
                                        {item.src_ip_fraud_score ?
                                            <span>IP Fraud Score: {item.src_ip_fraud_score} (out of 100)</span> : null
                                        }
                                    </div>
                                </div>
                                : null
                            }
                            <PostureCategory
                                type={item.source_type}
                                title={item.source_type}
                                categories={item.src_cat_ids || []}
                            >
                                <div className={item?.source_type === "Service" ? "gear_icn_issue" : item?.source_type === "Device" ? "device_icon" :
                                    item?.source_type === "App" ?
                                        (item?.src_cat_ids && item?.src_cat_ids?.length > 0 ? "settings_icon settings_icon_cloud" : 'settings_icon ') : "user_icon"} ></div>
                            </PostureCategory>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 260 : 130 }}>
                            <div className="copy_field issue-page" style={{ display: 'flex' }}>
                                <Tooltip
                                    onMouseOver={() => {
                                        const el = document.getElementsByClassName('issue-parent-tooltip');
                                        if (el && el[0])
                                            el[0].classList.remove('visibility_hidden');
                                    }}
                                    classes={{ tooltip: 'matching-rule-tooltip issue-parent-tooltip' }} title={
                                        <>
                                            <div>{item?.source_full_name || item?.userdetails_cn || ''}</div>
                                            <div style={{ paddingTop: '2%' }}>{item.source_name}</div>
                                        </>
                                    }>
                                    <span>
                                        <div className="ellipsis_name issue-page">
                                            <span>
                                                <span>{item.source_name.toString()}</span>
                                            </span>
                                        </div>
                                        {
                                            (((item.matching_rule_name == RuleType.SUSPECTED_DIR_BOT.toString() &&
                                                (!dir_names.filter((i: string) => item?.directory_hostname?.toLowerCase().includes(i)).length ||
                                                    !dir_names.filter((i: string) => item?.directory_name?.toLowerCase().includes(i)).length)) ||
                                                (SuspectedPbList.includes(item?.matching_rule_name) && item.matching_rule_name != RuleType.SUSPECTED_DIR_BOT.toString())
                                            )) &&
                                            (item?.suspected_source_info ? <div className={'suspected_icon ' + (`${getRisk(item?.risk)?.toLowerCase()}-risk-sus`)}
                                                onClick={() => handleSuspectedClick(item._id.toString(), item.source_name, item.suspected_source_info, item.matching_rule_name)}></div>
                                                :
                                                item?.status === 'Open' &&
                                                    moment.duration(moment(new Date()).diff(moment(item.first_flow_time))).asHours() < 12
                                                    ? <>
                                                        <Tooltip classes={{ tooltip: 'matching-rule-tooltip' }}
                                                            title={"We're still computing the possible sources of attack."}
                                                            onMouseOver={(e: any) => {
                                                                setTimeout(() => {
                                                                    const el = document.getElementsByClassName('issue-parent-tooltip');
                                                                    if (el && el[0])
                                                                        el[0].classList.add('visibility_hidden');
                                                                }, 100)
                                                            }
                                                            }
                                                        >
                                                            <div className={'suspected_icon_invert low-risk-sus'} style={{ cursor: 'default' }}></div>
                                                        </Tooltip> </> : <></>
                                            )
                                        }
                                        <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                                    </span>
                                </Tooltip>
                                <div className="copy_name" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.source_name)}></div>
                            </div>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 108 : 50 }} className="type_col_width">
                            {/* {item.dst_ip_reputation === 2 ? 
                            <div className="reputation_icon_gray">
                                <div className="titletip">
                                    {item.dst_ip_fraud_score ?
                                        <span>IP Fraud Score: {item.dst_ip_fraud_score} (out of 100)</span> : null
                                    }
                                </div>
                            </div> 
                        : null 
                        } */}

                            {item.dst_ip_reputation === 3 ?
                                <div className="reputation_icon_orange">
                                    <div className="titletip">
                                        {item.dst_ip_fraud_score ?
                                            <span>IP Fraud Score: {item.dst_ip_fraud_score} (out of 100)</span> : null
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {item.dst_ip_reputation === 4 ?
                                <div className="reputation_icon_red">
                                    <div className="titletip">
                                        {item.dst_ip_fraud_score ?
                                            <span>IP Fraud Score: {item.dst_ip_fraud_score} (out of 100)</span> : null
                                        }
                                    </div>
                                </div>
                                : null
                            }
                            <PostureCategory
                                type={item.destination_type}
                                title={(item.destination_type?.toLowerCase() == 'app' && item?.dst_cat_ids && item?.dst_cat_ids?.length > 0) ?
                                    'Cloud App' : (item.destination_type?.toLowerCase() == 'service' && item?.dst_cat_ids && item?.dst_cat_ids?.length > 0) ? 'Cloud Service' : item.destination_type}
                                categories={item.dst_cat_ids || []}>
                                <div className={item?.destination_type === "Service" ? (
                                    item?.dst_cat_ids && item.dst_cat_ids?.length > 0 ? "gear_icn_cloud" : "gear_icn_issue") : item?.destination_type === "Device" ? "device_icon" : (
                                        item?.dst_cat_ids && item.dst_cat_ids?.length > 0 ? "settings_icon_cloud settings_icon issue" : 'settings_icon')} ></div>
                            </PostureCategory>
                        </td>

                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 232 : 116 }}>
                            <div className="copy_field" style={{ display: 'flex' }}>
                                <div className="ellipsis_ip">
                                    {item?.directory_name ?
                                        <div className={"dir_icon " + (item.dir_cat_ids && item.dir_cat_ids?.length > 0 ? ' dir_icon_cloud ' : '')}>
                                            <div className="titletip">
                                                Name: {item?.directory_name} <br />
                                                {item?.directory_ip ?
                                                    <span>IP: {item?.directory_ip} <br /></span>
                                                    : null
                                                }

                                                {item?.directory_port ?
                                                    <span>Port: {item?.directory_port} <br /></span>
                                                    : null
                                                }
                                                Protocol: {item?.directory_protocol} <br />
                                                Hostname: {item?.directory_hostname ? item?.directory_hostname : item?.directory_hostname}<br />
                                                <DirectoryCategory categories={item.dir_cat_ids || []} title={'Directory Category'} />
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <span title={item.destination_name.toString()}>{item.destination_name}</span>
                                </div>
                                <InfoIcon titleAccess={item.destination_name.toString()} style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                                <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.destination_name)}></div>
                            </div>
                        </td>
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 136 : 68 }} rowSpan={2}><span className='proto_name'>{
                            item.transport_protocol ? <><Tooltip classes={{ tooltip: 'matching-rule-tooltip' }} title={<span>{item.transport_protocol}</span>}><span>{item.destination_protocol}</span></Tooltip></> :
                                item.destination_protocol}</span></td>
                        <td colSpan={2}><span className=''>{item._id}</span></td>
                        {/* <td rowSpan={2}><div data-tip={item.issue_status} data-for={item.playbook+item.issue_status+index} className="eye_icon"></div></td> */}
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 60 : 30 }} rowSpan={2}><span
                            title={getRisk(item.risk)}
                            className={getRisk(item.risk)?.toLowerCase()}>&nbsp;</span></td>
                        {/* <td style={{visibility:"hidden", padding: 0}}><ReactTooltip className="tooltip" id={item.playbook+item.issue_status+index}/></td> */}
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 160 : 30 }} rowSpan={2} className="issue-action-column-td">
                            <ActionMenu>
                                <ul>
                                    {item?.actions?.filter((i: string) => i != 'Create Ticket').map((i: string) => {
                                        return <li key={i} className="disableItems" title={i.toString()} >
                                            <Link to="#" onClick={Action} data-action={i.toString()} data-status={item.status}
                                                id={item._id.toString()}>{i}</Link>
                                        </li>

                                    })}
                                    {item.is_ticket_exist ?
                                        <li className="" >
                                            <Link to='#' onClick={Action}
                                                data-action='go-to-ticket'
                                                id={item._id.toString()}
                                            >Go To Ticket</Link>
                                        </li> //EDIT TICKET
                                        : <li className={`${/(Resolved|Closed)/.test(item?.status) || isTimeBasedSearchApplied && 'disableItems'}`}>
                                            <Link to="#"
                                                data-action='create-ticket'
                                                onClick={Action}
                                                id={item._id.toString()}>Create Ticket</Link></li> // CREATE TICKET
                                    }
                                    {item.closure_reason !== 'Playbook deleted' &&
                                        <li>   <a onClick={() => {
                                            setOpenPlayBookModal(true);
                                            setPlaybookId(item.playbook_id.toString());
                                        }}


                                        >View Playbook</a> </li>
                                    }
                                    {authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' && item.status !== "Closed" && <li className={`${isTimeBasedSearchApplied && 'disableItems'}`} onClick={() => closeTicketsFromAction(item._id.toString())}
                                        data-action='go-to-ticket'
                                        id={item._id.toString()}
                                    ><a>Close Incident </a></li>}
                                </ul>
                            </ActionMenu>
                        </td>
                    </tr>

                    <tr className={(item.status === "Closed" ? 'gray_bg sd_row_sep' : item.status === "Resolved" ? 'light_gray_bg sd_row_sep' : isSelected(item._id.toString()) ? 'sd_row_sep selected-row' : 'sd_row_sep')}>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 114 : 57 }} className="type_col_width">
                            <div>{item.status?.toLowerCase() != 'open' ? 'N.A.' : moment(item.first_flow_time).fromNow(true)}</div>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 114 : 57 }} className="type_col_width">
                            <div className={`${item.issue_flows_count > 1 && 'shadowbox'} num_flows`} id={item._id.toString()} onClick={(event) => { if (item.issue_flows_count > 1) { handleFlowClick(event, { ...item, id: item._id.toString(), risk: getRisk(item.risk)?.toLowerCase() }) } }}>{getRoundedUnit(item.issue_flows_count)}</div>
                            {/* <div className="num_flows" id={item._id.toString()}>{getRoundedUnit(item.issue_flows_count)}</div> */}
                        </td>
                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 328 : 164 }} colSpan={2} className="bd_dashed copy_field">
                            {item?.source_countrycode2 ?
                                <span>
                                    {item?.source_countryname ?
                                        <div className="flag_icon">
                                            <AMCountryFlag countryCode={item?.source_countrycode2} />
                                            <div className="titletip">
                                                {item?.source_cityname ?
                                                    <span>{item?.source_cityname}, </span> : null
                                                }
                                                <span>{item?.source_countryname}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="flag_icon">
                                            <div className='no_flag' title='Country details not available'></div>
                                        </div>
                                    }
                                </span>
                                :
                                <span>
                                    {item?.source_countryname ?
                                        <div className="location_icon">
                                            <div className="titletip">
                                                {item?.source_cityname ?
                                                    <span>{item?.source_cityname}, </span> : null
                                                }
                                                <span>{item?.source_countryname}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="flag_icon">
                                            <div className='no_flag' title='Country details not available'></div>
                                        </div>
                                    }
                                </span>
                            }
                            <div className="copy_field ellips-hostname-issue">
                                <div className="ellipsis_hostname droplist issue-page"
                                    onScrollCapture={(e) => handleMounseLeaveHost(null, null, e)}
                                >
                                    <Tooltip
                                        classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                                        title={
                                            <div>
                                                <h2 className='marginBottom5'>
                                                    <span className="ellipsis_idassetname-ip"><strong>IP:Port</strong>{
                                                        item.source_ip.startsWith('127.') ? ' - N.A.' :
                                                            ` - ${item.source_ip.toString()}:${item.source_port.toString()}`}</span>
                                                    <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.source_ip.toString()}:${item.source_port.toString()}`)}></div>
                                                </h2>
                                                {
                                                    item?.src_company_name ?
                                                        <h2>
                                                            <span className="ellipsis_idassetname-ip"><strong>Organization: </strong>{`${item.src_company_name.toString()}`}</span>
                                                            <div className="copy_idassetname" style={{ marginLeft: '100%', marginTop: '0.1875em' }} title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item?.src_company_name?.toString() || ''}`)}></div>
                                                        </h2> : null
                                                }
                                                {
                                                    item?.src_mac ?
                                                        <h2
                                                            className='marginBottom5'
                                                            style={{ paddingTop: 0 }}
                                                            title={item.src_mac.toString()}>
                                                            <span className="ellipsis_idassetname"><strong>MAC</strong>{` - ${item.src_mac.toString()}`}</span>
                                                            <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.src_mac ? item?.src_mac.toString() : ''}`)}></div>
                                                        </h2> : null
                                                }
                                                {item?.user_agent ?
                                                    <h2
                                                        style={{ paddingTop: 0 }} title={item.user_agent.toString()}>
                                                        <span className="ellipsis_idassetname"><strong>User Agent</strong>{` - ${decodeURIComponent(item.user_agent.toString())}`}</span>
                                                        <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.user_agent ? item.user_agent.toString() : ''}`)}></div>
                                                    </h2> : null
                                                }
                                                {item.source_hostnames && item.source_hostnames.length && item.source_hostnames.length > 0 ?
                                                    <table>
                                                        <tr className='show_copy_btn'>
                                                            <th className="align_left">Recent source hostnames
                                                                <div className="copy_btn" title="Copy all IPs to clipboard"
                                                                    onClick={() => navigator.clipboard.writeText(`${Array.from(new Set([item.source_ip.toString(),
                                                                    ...item.source_hostnames.map((el: any) => el.ip).filter(el => el)]))}`)}></div>
                                                            </th>
                                                        </tr>
                                                        {item.source_hostnames.map((el: any, index: any) => {
                                                            return (<tr
                                                                key={el.hostname + '-' + index}
                                                                onMouseLeave={(e) => handleMounseLeaveHost(index, el.name, e)}
                                                                onScroll={(e) => handleMounseLeaveHost(index, el.name, e)}
                                                            >
                                                                <td className="hostname_td align_left display-flex-column">
                                                                    <div style={{ display: 'flex' }}>
                                                                        <span className="ellipsis_idassetname"
                                                                            onMouseOver={(e) => handleMouseEnterHost(index, el.name, e)}>
                                                                            <div className="flag_icon" style={{ position: 'relative' }}>{
                                                                                el.location ?
                                                                                    <AMCountryFlag countryCode={el?.location} />
                                                                                    : <div className='no_flag'></div>
                                                                            }
                                                                            </div>
                                                                            <span style={{
                                                                                marginLeft: !el.location ? '' : el.location && window.matchMedia("(min-width: 2560px)").matches ? 40 : 10
                                                                            }}>{el.name}</span></span>
                                                                        <div style={{
                                                                            flex: 1
                                                                        }}>
                                                                            <div
                                                                                style={{ position: 'relative' }}
                                                                                className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(el.name)}></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`hidden-block host-details-tooltip paddingLeft20 host-name-list-${index}-${el.name}`} >
                                                                        {el.name ? <li>
                                                                            <strong>Hostname</strong>: {el.name}</li> : null}
                                                                        {el.country ? <li><strong>Country</strong>: {el.country}</li> : null}
                                                                        {el.city ? <li><strong>City</strong>: {el.city}</li> : null}
                                                                        {el.ip ? <li><strong>Ip</strong>: {el.ip}</li> : null}
                                                                    </div>

                                                                </td>
                                                            </tr>)
                                                        })}
                                                    </table>
                                                    : null
                                                }
                                            </div>
                                        }
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ display: 'inline-block' }} className='ellipsis'>{item.latest_source_hostname ? item.latest_source_hostname : ''}
                                            </span>

                                            {
                                                item.source_hostnames?.length > 1 ?
                                                    <span><strong>&nbsp;({'+'.concat((item.source_hostnames?.length - 1).toString())})</strong></span> : null
                                            }

                                        </div>
                                    </Tooltip>


                                </div>
                                <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                                <span style={{ marginLeft: '2px' }} className="copy_hostname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.latest_source_hostname ? item.latest_source_hostname : '')}></span>
                            </div>
                        </td>

                        <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 340 : 170 }} colSpan={2} className="bd_dashed copy_field">
                            {item?.destination_countrycode2 ?
                                <span>
                                    {item?.destination_countryname ?
                                        <div className="flag_icon">
                                            <AMCountryFlag countryCode={item?.destination_countrycode2} />
                                            <div className="titletip">
                                                {item?.destination_cityname ?
                                                    <span>{item?.destination_cityname}, </span> : null
                                                }
                                                <span>{item?.destination_countryname}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="flag_icon">
                                            <div className='no_flag' title='Country details not available'></div>
                                        </div>
                                    }
                                </span>
                                :
                                <span>
                                    {item?.destination_countryname ?
                                        <div className="location_icon">
                                            <div className="titletip">
                                                {item?.destination_cityname ?
                                                    <span>{item?.destination_cityname}, </span> : null
                                                }
                                                <span>{item?.destination_countryname}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="flag_icon">
                                            <div className='no_flag' title='Country details not available'></div>
                                        </div>
                                    }
                                </span>
                            }
                            <span className='ellips-hostname-issue'>
                                <div className="copy_field">
                                    <div className="ellipsis_hostname droplist issue-page">
                                        {item.destination_hostname ? item.destination_hostname : item.destination_hostname}
                                        <div className="service_list issue-hostname-list-dest">
                                            {
                                                item.destination_hostname != 'N.A.' && item.destination_hostname != item.destination_ip ?
                                                    <h2><strong>Hostname - </strong>{item.destination_hostname}</h2> : null
                                            }

                                            {<h2>
                                                <span className="ellipsis_idassetname-ip"><strong>IP:Port</strong>{
                                                    item.destination_ip.startsWith('127.') ? ' -  N.A.' :
                                                        ` - ${item.destination_ip.toString()}:${item.destination_port.toString()}`}</span>
                                                <div className="copy_idassetname" style={{ marginLeft: '100%' }} title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.destination_ip.toString()}:${item.destination_port.toString()}`)}></div>
                                            </h2>
                                            }
                                            {item.dest_mac ?
                                                <h2 title={item.dest_mac.toString()}>
                                                    <span className="ellipsis_idassetname"><strong>MAC</strong>{` - ${item.dest_mac.toString()}`}</span>
                                                    <div className="copy_idassetname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(`${item.dest_mac.toString()}`)}></div>
                                                </h2> : null
                                            }
                                        </div></div>
                                    {/* <div className="ellipsis_hostname" title={(item.destination_hostname ? item.destination_hostname : item.destination_hostname) + '\rIP:Port ' + item.destination_ip.toString() + ":" + item.destination_port.toString()}>
                                    {item.destination_hostname ? item.destination_hostname : item.destination_hostname}
                                </div>
                                 */}
                                </div>
                                <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginTop: 2, transform: 'skewX(-10deg)' }} />
                            </span>
                            <span style={{ marginLeft: '2px' }} className="copy_hostname" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(item.destination_hostname ? item.destination_hostname : '')}></span>
                        </td>
                        <td style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 142 : 71 }} colSpan={2}>
                            {
                                item?.status_comment ?
                                    <span><Tooltip classes={{ tooltip: 'matching-rule-tooltip' }} title={<span>{item.status_comment} </span>}>
                                        <span>{item.status} {item.closure_reason.length > 0 ? '(' + item.closure_reason + ')' : null}
                                        </span>
                                    </Tooltip></span>
                                    : <span>{item.status} {item.closure_reason.length > 0 ? '(' + item.closure_reason + ')' : null}
                                    </span>
                            } </td>
                    </tr>

                </React.Fragment >
            );
        });
    }

    const handlePaginate = (selectedPage: any) => {
        param.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1;
        param.hard_refresh = false;
        setQuery(param)
    }

    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // param.q=e.currentTarget.value;
        // param.page=1;
        // if(e.key==="Enter"){
        //     setQuery(param);
        // }

        const { key } = e;
        const trimmedInput = searchInput?.trim();


        if ((key === 'Enter') && trimmedInput?.length && !tags?.includes(trimmedInput) && trimmedInput !== "") {
            setTags(prevState => [...prevState, trimmedInput]);
            setSearchInput('');
            setTagFlag(true);
            setSummaryFlag(trimmedInput === "" ? false : true);
            resetSelectionState();
        }

        else if (key === "Backspace" && tags.length > 0 && trimmedInput.length === 0) {
            let tempTags = [...tags]
            tempTags.pop();
            setTags(tempTags);
            setTagFlag(true);
        }

    }

    const handleLensSearch = () => {
        const trimmedInput = searchInput?.trim();

        if (trimmedInput?.length && !tags?.includes(trimmedInput) && trimmedInput !== "") {
            setTags(prevState => [...prevState, trimmedInput]);
            setSearchInput('');
            setTagFlag(true);
            setSummaryFlag(trimmedInput === "" ? false : true);
            resetSelectionState();
        }
    }

    useEffect(() => {
        console.log({ tagFlag, tags })
        if ((tagFlag && tags.length >= 0) || isClearAll) {
            param.page = 1;
            param.q = tags.join("+");
            param.d_name = undefined;
            param.risk = undefined;
            param.d_protocol = undefined;
            param.hash = undefined;
            param.e_time = undefined;
            param.s_time = undefined;
            param.rule_name = undefined;
            setQuery(param);
            setSummaryFlag(param.q === "" ? false : true)
            setIsClearAll(false); // reset clearall status.
        }
    }, [tags, tagFlag])

    const applySearchHandler = useCallback((data: any, clearAll: boolean = false, deletedKeys = []) => {
        resetSelectionState();
        let tempFilterObj = getSearchFilterObjectFromTags(issueTemplate, tags);
        let filterObj = {};
        for (let i in tempFilterObj) {
            if (deletedKeys.indexOf(i) == -1)
                filterObj[i] = tempFilterObj[i];
        }

        filterObj = { ...filterObj, ...data }

        if (clearAll) {
            if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
                clearAll = false;
                filterObj = { [TIME_BASED_SEARCH_FIELD]: filterObj[TIME_BASED_SEARCH_FIELD] };
            }
        }
        if (clearAll) {
            setIsClearAll(clearAll);
            setTags([]);
            return;
        }

        if (filterObj) {
            /* // Changed requirement merge not needed now removing merging logic.
            const addedTags = new Map();
            const textBoxSupportedKeys = notSupportedKeys.map(p => p.search_key);

            const allRetainableKeys =
                issueTemplate.map(p => p.search_key)
                    .filter(p => !deletedKeys.some(d => p === d))
                    .concat(textBoxSupportedKeys);

            const incidentTags = (tags.length > 0) ? tags : q?.split('+') || [];
            incidentTags?.forEach(p => {
                const [tagName] = p?.split(/([:~|])/) || [];
                if (tagName && allRetainableKeys.indexOf(tagName) > -1) {
                    addedTags.set(tagName, p);
                }
            });*/
            if (Object.keys(filterObj).length > 0) {
                // update tags array on Apply AdvanceSearch (Merge only)
                const searchTextList = [];
                for (let filter in filterObj) {
                    let filterValue: any = filterObj[filter]?.value;
                    let opr = supportedOperators.get(String(filterObj[filter]?.type)) || '';
                    if (filter === 'any_field') {
                        filterValue.forEach((p: String) => {
                            if (p) {
                                //addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
                                searchTextList.push(p)
                            }
                        })
                        continue;
                    }
                    //addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
                    searchTextList.push(`${filter}${opr}${filterValue}`);
                }
                if (searchTextList.length > 0) {
                    // const merged = Array.from(addedTags.values());// Change Requirement reverting logic
                    console.log({ searchTextList })
                    setTags(searchTextList);
                    setTagFlag(true);
                }
            }
        }
    }, [tags, q]);

    const applyTimeBasedSearchHandler = useCallback((data, clear) => {
        let filterObj = getSearchFilterObjectFromTags(issueTemplate, tags);
        if (clear) {
            if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
                delete filterObj[TIME_BASED_SEARCH_FIELD];
                clear = false;
            }
            clear = Object.keys(filterObj).length === 0 ? true : false;
        } else {
            filterObj = { ...filterObj, ...data }
        }

        if (clear) {
            setIsClearAll(clear);
            setTags([]);
            return;
        }

        if (filterObj) {
            /* // Changed requirement merge not needed now removing merging logic.
            const addedTags = new Map();
            const textBoxSupportedKeys = notSupportedKeys.map(p => p.search_key);

            const allRetainableKeys =
                issueTemplate.map(p => p.search_key)
                    .filter(p => !deletedKeys.some(d => p === d))
                    .concat(textBoxSupportedKeys);

            const incidentTags = (tags.length > 0) ? tags : q?.split('+') || [];
            incidentTags?.forEach(p => {
                const [tagName] = p?.split(/([:~|])/) || [];
                if (tagName && allRetainableKeys.indexOf(tagName) > -1) {
                    addedTags.set(tagName, p);
                }
            });*/

            if (Object.keys(filterObj).length > 0) {
                // update tags array on Apply AdvanceSearch (Merge only)
                const searchTextList = [];
                for (let filter in filterObj) {
                    let filterValue: any = filterObj[filter]?.value;
                    let opr = supportedOperators.get(String(filterObj[filter]?.type)) || '';
                    if (filter === 'any_field') {
                        filterValue.forEach((p: String) => {
                            if (p) {
                                //addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
                                searchTextList.push(p)
                            }
                        })
                        continue;
                    }
                    //addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
                    searchTextList.push(`${filter}${opr}${filterValue}`);
                }
                if (searchTextList.length > 0) {
                    // const merged = Array.from(addedTags.values());// Change Requirement reverting logic
                    setTags(searchTextList);
                    setTagFlag(true);
                }
            }
        }
    },
        [tags, q]
    )

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    }

    const deleteTag = (index: number) => {
        const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
        let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
        if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
            newTags = [...newTags, ...anyActivityTimeTag];
        }

        setTags(newTags);
        setTagFlag(true);
        resetSelectionState();
    }

    const headers = [
        { label: "Open Since", key: "open_since" },
        { label: "Last Access Time", key: "gen_timestamp" },
        { label: "# Flows", key: "issue_flows_count" },
        { label: "Incident #", key: "_id" },
        { label: "Matching Playbook", key: "playbook_name" },
        { label: "Matching Issue", key: "matching_rule_name" },
        { label: "Issue Highlights", key: "root_cause_data" },
        { label: "Identity Type", key: "source_type" },
        { label: "Identity Name", key: "source_name" },
        { label: "Identity Hostname", key: "latest_source_hostname" },
        { label: "Identity IP", key: "source_ip" },
        { label: "Identity Port", key: "source_port" },
        { label: "Asset Type", key: "destination_type" },
        { label: "Asset Name", key: "destination_name" },
        { label: "Asset Hostname", key: "destination_hostname" },
        { label: "Asset IP", key: "destination_ip" },
        { label: "Asset Port", key: "destination_port" },
        { label: "Protocol", key: "destination_protocol" },
        { label: "Actions", key: "actions" },
        { label: "Status", key: "status" },
        { label: "Risk", key: "risk" },
        { label: "Sensor Site Code", key: "sensor_location" },
    ];

    const [downData, setDownData] = useState<string | Data>([]);
    const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(0)
    const [loading, setLoading] = useState({ loading: false, setData: false })
    const currDownData: any = [];
    const csvLinkEl = useRef<any>();

    const getUserList = async (selectedPage?: any) => {
        const searchHeaders = { headers: { search_fields: '' } };
        let searchTags: String[] = tags || [];
        param.page = selectedPage === 0 ? 1 : selectedPage + 1;
        param.rpp = 50;
        if (param.q && param.q?.split('+').length > 0) {
            const tags = param.q?.split('+');
            searchTags = tags;
        }
        const fields = getSearchFilterObjectFromTags(issueTemplate, searchTags);
        const que = await generateTagsFromSearch(fields);
        if ((searchTags || []).length > 0) {
            const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, que || [], true);
            searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        }
        // if ((searchTags || []).length > 0) {
        //     const searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, searchTags, true);
        //     searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
        // }
        return Api.get("/issues", {
            params: param,
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
            ...searchHeaders
        })
            .then(res => {
                res.data.result.map((item: any, index: any) => {
                    item.open_since = moment(item.first_flow_time).fromNow(true);
                    const wrapper = document.createElement('div');
                    wrapper.innerHTML = item.issue;
                    item.root_cause_data = wrapper.textContent;
                    item.destination_ip = item.destination_ip.startsWith('127.') ? 'N.A.' : item.destination_ip
                    item.source_ip = item.source_ip.startsWith('127.') ? 'N.A.' : item.source_ip
                    item.matching_rule_name = getIssueNameById(item?.matching_rule_name)
                    currDownData.push(item)
                });
                console.log(currDownData);
                return currDownData;
            }).catch((error: any) => {
                setLoading({ loading: false, setData: false })
                if (error.response.status === 500 || error.response.status === 524) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to find associated email, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            });
    }

    const downloadCSV = async () => {

        setLoading({ loading: true, setData: false })
        let i = 0;
        do {
            const downData = await getUserList(i);
            setTotalDownloaded(i * 50);
            if (i > Math.floor(totalCount! / 50)) {
                setDownData(downData);
                setTimeout(() => {
                    setLoading({ loading: false, setData: true })
                    if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
                        csvLinkEl?.current?.link?.click();
                    }
                });
            }
            i = i + 1;
        } while (i <= Math.floor(totalCount! / 50) + 1)

    }

    const handleGotoPage = () => {
        let pageValue = (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value;
        pageValue = (pageValue !== "") ? pageValue : "1";
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('page', pageValue);
        (document.getElementsByName("page_no")[0] as HTMLTextAreaElement).value = "";
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const isAddExceptionEnabled = () => {
        if (!isAuthorizedAction(PostureMenuItemAction.ADD_EXCEPTION.toString())) return false;
        let isExceptionSearchFieldExist = false;
        let isFreeKeyword = false;
        let criteriaCount = 0;
        let isRuleName = false;
        tags.forEach((item: any) => {
            const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
            let key = undefined;
            if (index > -1) {
                key = item.substr(0, index);
            } else {
                isFreeKeyword = true;
            }
            if (key && ExceptionSearchFields.includes(key)) {
                isExceptionSearchFieldExist = true;
                criteriaCount++;
                if (key == 'rule_name') {
                    isRuleName = true;
                }
            }
        });
        if (isRuleName && criteriaCount == 1) {
            return false;
        }

        if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
            setTimeout(() => {
                if (document.getElementById('issue-more-action-btn-grp')) {
                    const btn = document.getElementById('issue-more-action-btn-grp');
                    if (isExceptionSearchFieldExist) {
                        btn?.classList.add('exception-border');
                    } else {
                        btn?.classList.remove('exception-border');
                    }
                }
            }, 500)

            return selectAll && isExceptionSearchFieldExist && !isFreeKeyword;
        }
    }

    const addException = () => {
        setShowAddException(true);
    }

    const getTagName = (item: string) => {
        return item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
    }

    const getTagValue = (item: string) => {
        const index = item.indexOf('~') > -1 ? item.indexOf('~') : item.indexOf('|') > -1 ? item.indexOf('|') : item.indexOf(':');
        return item.slice(index + 1);
    }

    useEffect(() => {
        getExceptionCriteria();
    }, [selectedIncidents, selectAll])

    const getExceptionCriteria = () => {
        let isDirExist = false, index = -1;
        const t = tags.map((i: string, ind: number) => {
            if (getTagName(i) === 'dir_hostname') {
                isDirExist = true;
                index = ind;
            }
            return i;
        });
        if (isDirExist) {
            const i = Object.values(selectedIncidents)[0] as IssueObj;
            if (i && !isPrivateIp(i.directory_ip)) {
                t[index] = `dir_names:` + i?.directory_name;
            }
        }
        setExceptionCriteria(t || []);
    }

    const handleConfirmAddException = () => {
        setShowAddException(false);
        let isDirExist = false, index = -1;
        const t = tags.map((i: string, ind: number) => {
            if (getTagName(i) === 'dir_hostname') {
                isDirExist = true;
                index = ind;
            }
            return i;
        });
        if (isDirExist) {
            const i = Object.values(selectedIncidents)[0] as IssueObj;
            if (!isPrivateIp(i.directory_ip)) {
                t[index] = `dir_names:` + i?.directory_name;
            }
        }
        localStorage.setItem('addException', JSON.stringify(t));
        history.push('/exceptions/add');
    }

    const onAdvanceSearchClick = () => {
        setShowAdvanceSearch(true);
    }

    const onCloseAdvanceSearch = useCallback(() => {
        setShowAdvanceSearch(false);
    }, [])

    const onIssueFlowClick = () => {
        setSelectAll(false);
        setSelectCount(0);
        setSelectRow({});
        setSelectAllIndeterminate(false);
        setSelectedIncidents({});
    }

    const isMarkKnownEnabled = () => {
        if (!isMarkKnownOptionAllowed()) return false;
        const selectedIssues = selectedIncidents;
        let isAssetNameExists = false;
        tags.forEach((item: string) => {
            const i = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
            if (i == 'd_name') {
                isAssetNameExists = true;
            }
        });

        borderMarkKnown();

        if ((selectAll && isAssetNameExists) || (selectCount > 0 && isAssetNameExists)) {
            return true;
        }
        return false;
    }

    const borderMarkKnown = () => {
        let isAssetNameExists = false;
        tags.forEach((item: string) => {
            const i = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
            if (i == 'd_name') {
                isAssetNameExists = true;
            }
        });

        if (authDetails.permissions.Issues_and_Prevention?.bulk_close_issues == 'readwrite') {
            setTimeout(() => {
                if (document.getElementById('issue-more-action-btn-grp')) {
                    const btn = document.getElementById('issue-more-action-btn-grp');
                    if (isAssetNameExists) {
                        btn?.classList.add("asset-border");
                    } else {
                        btn?.classList.remove('asset-border');
                    }
                }
            })
        }
    }

    const markKnown = () => {
        if (selectAll || selectAllIndeterminate) {
            let _id = '';
            tags.forEach((item: string) => {
                const i = item.indexOf('~') > -1 ? item.split('~')[0] : item.indexOf('|') > -1 ? item.split('|')[0] : item.split(':')[0];
                if (i == 'd_name') {
                    let a = item.indexOf('~') > -1 ? item.split('~') : item.indexOf('|') > -1 ? item.split('|') : item.split(':');
                    if (a.length > 2) {
                        a.splice(0, 1);
                        _id = a.join(':');
                    } else {
                        _id = a[1];
                    }
                }


            });

            const queryData = query?.q?.split('+') || [];
            let searchFieldsList = {};
            if ((queryData || []).length > 0) {
                searchFieldsList = getSearchFilterObjectFromTags(issueTemplate, queryData);
            }
            setMarkKnownData({ _id });
            setIsMarkKnownQuery(searchFieldsList);
            sertExcludeIncidents(getUnSelectRow());
        } else {
            const markKnownObject = { _id: '', host_detail: [] };
            const host_detail: any = [];
            if (Object.values(selectedIncidents).length > 0) {
                Object.values(selectedIncidents).forEach((item: any) => {
                    if (item) {
                        host_detail.push({ ip: item.destination_ip, hostname: item.destination_hostname });
                        markKnownObject._id = item.destination_name;
                    }
                });
                markKnownObject.host_detail = host_detail || [];
                setMarkKnownData(markKnownObject);
            }
        }
        setShowMarkKnown(true);
    }

    const isKnownIPEnabled = (): any => {
        if (!isAuthorizedAction(PostureMenuItemAction.ADD_KNOWN_PUBLIC.toString())) return false;
        const src_ips: any[] = [];
        const dst_ips: any[] = [];
        const src_host: any[] = [];
        const dst_host: any[] = [];
        Object.keys(selectedIncidents).map((i: String) => {
            const o = selectedIncidents[i as keyof typeof selectedIncidents];
            if (o) {
                src_ips.push(o.source_ip);
                if (o?.source_hostnames) {
                    src_host.push(o.source_hostnames[o?.source_hostnames?.length - 1]?.name || '');
                }
                dst_ips.push(o.destination_ip);
                dst_host.push(o.destination_hostname);
            }
        });
        const src_ip_pre = calculatePrefix(src_ips);
        const dst_ip_pre = calculatePrefix(dst_ips);
        const src_host_pre = calculatePrefix(src_host);
        const dst_host_pre = calculatePrefix(dst_host);
        const prefixData = [{ id: src_ip_pre, label: 'Source Ips', ips: Array.from(new Set(src_ips)) },
        { id: dst_ip_pre, ips: Array.from(new Set(dst_ips)), label: 'Destination Ips' },
            // { id: dst_host_pre, label: 'Destination Hostnames', ips: Array.from(new Set(dst_host)) },
            // { id: src_host_pre, label: 'Source Hostnames', ips: Array.from(new Set(src_host)) }
        ];
        const isEnabled = src_ip_pre || dst_ip_pre || src_host_pre || dst_host_pre;
        return { isEnabled, prefixData };
    }

    const calculatePrefix = (ips: string[]): string | undefined => {
        let prefixIndex: any = undefined;
        if (ips.length > 1) {
            const f = ips[0] as string;
            const isAllSame = ips.every((el: string) => el == f);
            if (isAllSame) {
                /* Check if all same */
                const len = Array.from(new Set(ips)).length;
                if (len > 1) {
                    prefixIndex = f;
                }
            } else if (f.indexOf('.') >= 0) {
                let ind = f.indexOf('.');
                for (let i = 1; i < 4; i++) {
                    const isTrue = ips.slice(1).every((el: string) => el.slice(0, ind) == f.slice(0, ind));
                    if (i == 1 && f.slice(0, ind) == '10') {
                        /* if private ip */
                        prefixIndex = undefined;
                        break;
                    }
                    if (isTrue) {
                        prefixIndex = f.slice(0, ind) + '.*';
                        ind = f.indexOf('.', ind + 1);
                    }
                }
            }
        }
        return prefixIndex;
    }

    const MoreActionsOptions: IPlaybookActions<Array<any>> = [{
        actionCallback: closeTickets,
        actionId: 'close-incident',
        isDisabled: ((!isCloseIncEnabled() || showAdvanceSearch || isTimeBasedSearchApplied)),
        actionLabel: "Close " + (selectCount == 1 ? '1 Incident' : (selectCount > 1 ? `${selectCount} Incidents` : 'Incidents')),
        isApplicable: authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite'
    },

    {
        actionCallback: markKnown,
        actionId: 'mark-knwon',
        isDisabled: !isMarkKnownEnabled(),
        actionLabel: 'Mark Asset as Known',
        classes: document.getElementById('issue-more-action-btn-grp')?.classList.contains('asset-border') ? 'asset-name-border-menu' : '',
        isApplicable: authDetails.permissions.Admin.knownapps == 'readwrite'
    },
    {
        actionCallback: addException,
        actionId: 'add-exception',
        isDisabled: !isAddExceptionEnabled(),
        actionLabel: 'Add Exception',
        classes: document.getElementById('issue-more-action-btn-grp')?.classList.contains('exception-border') ? 'yellow-border' : '',
        isApplicable: authDetails.permissions.Admin.exception == 'readwrite'
    },
    {
        actionCallback: function () {
            setKnownIPData(MoreActionsOptions[3].customData);
        },
        actionId: 'add-known',
        isDisabled: !isKnownIPEnabled().isEnabled,
        actionLabel: 'Add to Known Internal Public IPs',
        customData: isKnownIPEnabled().prefixData,
        isApplicable: authDetails.permissions.Admin.known_public_ip == 'readwrite'
    }
    ];

    const onKnownIPDataProceed = (data: any) => {
        const ob = {
            comment: data.id,
            ips: data.ips
        }
        setKnownIPData(undefined);
        sessionStorage.setItem('known_public_ip', JSON.stringify(ob));
        history.push('/known_public_ip/add');
    }

    const handleSuspectedClick = (issue_id: string, source_name: string, suspected_source_info: any, matching_rule_name: string) => {
        setShowSuspectedPopUp({
            showPopUp: true,
            response: suspected_source_info,
            sourceName: source_name,
            matching_rule_name
        });
    }

    const IssueMultiSelectCheckboxItems = [
        {
            id: 'current-page', callback(evt) {
                const res = responseData?.result as Array<IssueObj>;
                resetSelectionState();
                const selectedIncs = {};
                for (let i = 0; i < res.length; i++) {
                    if (res[i].status?.toLowerCase() == 'open') {
                        const key = res[i]._id.toString();
                        selectedIncs[key as keyof typeof selectedIncs] = true;
                    }
                }
                setSelectRow(selectedIncs);
                setSelectCount(Object.keys(selectedIncs).length)
                // setSelectAllIndeterminate(true);
            }, label: 'Current Page'
        },
        {
            id: 'all-pages',
            callback(evt) {
                resetSelectionState();
                setSelectAll(true);
                setSelectCount(openIssuesCount)
            },
            label: 'All Pages'
        }
    ] as Array<IAMMultiCheckboxMenuItem>

    const preSetMetaDataHandler = res => {
        // Sort playbook names
        if (res.data.pb_name) {
            res.data.pb_name = res.data.pb_name.sort((a, b) => a.localeCompare(b));
        }
        res.data.dir_type = ['On-premise', 'Cloud IDP'];
        res.data.identity_is_public = ['true', 'false'];
        res.data.asset_is_directory = ['true', 'false'];
        res.data.asset_is_public = ['true', 'false'];
        res.data.asset_is_saas = ['true', 'false'];
        res.data.asset_is_known = ['true', 'false'];
        res.data.identity_is_known = ['true', 'false'];
        res.data.first_flow_time = ['hour(s)', 'day(s)'];
        res.data.last_flow_time = ['hour(s)', 'day(s)'];
        res.data.rule_name = res.data.rule_name.map((i) => ({ key: i, value: getIssueNameById(i) || i }))
        res.data.risk = res.data.risk.map((i) => ({ key: i, value: GlobalConsts.RISK[i] }))
    }

    const preMetaDataHandler = (setMetaData) => {
        const fetchFilterMetaData = () => {
            return Api.get('/issues/uniquefields');
        };
        fetchFilterMetaData()
            .then(async (res) => {
                preSetMetaDataHandler(res);
                const arr = await GLOBAL_SERVICE.Category.GET();
                const data = { ...res.data, ...arr };
                setMetaData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [openPlayBookModal, setOpenPlayBookModal] = useState(false);
    const [playbookId, setPlaybookId] = useState('');

    return (
        <>
            <Switch>
                {/* <Route path="/issues/createissue" render={() => <MoreActionsPopup handleTicketCreateNew={handleTicketCreate} />} /> */}
                {/* <Route path="/issues/flow" render={() => <FlowDetails data={issueFlow} />} /> */}
                {/* <Route path="/issues/matchrule" render={() => <MatchruleDetails data={matchedRuleData} />} /> */}
            </Switch>

            <PlaybookModal
                isOpen={openPlayBookModal}
                onCloseHandler={() => {
                    // history.replace(`/playbooks${history.location.search}`);
                    // fetchPlaybooks();
                    setOpenPlayBookModal(false);
                }}
                playbookId={playbookId}
                title="View Playbook"
                formType='view' />
            {/* {showAdvanceSearch && <Filter filterTemplate={issueTemplate} notSupportedKeys={notSupportedKeys} filters={tags} isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler} onClose={onCloseAdvanceSearch} />} */}
            {showAdvanceSearch && <PostureFilter
                filterTemplate={issueTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
                notSupportedKeys={notSupportedKeys}
                filters={tags} isOpen={showAdvanceSearch}
                onApplyFilter={applySearchHandler} onClose={onCloseAdvanceSearch}
                disableCreatePlaybookBtn={true}
                entityType={''}
                matchingPlaybookFoundFn={() => { }}
                query={query}
                showCreatePlaybookBtn={() => { }}
                showAllFields={true}
                preMetaDataHandler={preMetaDataHandler}
                isTimeBasedSearchApplied={isTimeBasedSearchApplied}
            />}

            {/*   {
                openPanelFilterWidgets ?
                    <FilterWidget toggleWidget={handleFilterWidget} disabledState={handleFilterDisabled} updateAfterFilter={reloadIssues} issueFiltersData={filterData} /> : ""
            }
            */}
            {/* {
                <>
                    <div className="page_title_area">
                        {
                            (!summaryFlag || showAdvanceSearch) ?
                                <div className="page_title fl">SUMMARY OF INCIDENTS (by number of incidents)</div>
                                : null
                        }
                        <div className={`launch_button margintop20 inc-summary ${showAdvanceSearch && 'disabled'}`}>
                            {(summaryFlag && !showAdvanceSearch) ? <h4 className='btn-show-summary' onClick={handleSummary}>SHOW SUMMARY</h4>
                                : <h4 className='btn-show-summary active' onClick={handleSummary}>HIDE SUMMARY</h4>}
                        </div>
                    </div>
                    {!summaryFlag || showAdvanceSearch ?
                        <div className={`widget_summary_box ${showAdvanceSearch && 'disabled'} `}>
                            {(issueRules !== undefined &&
                                issuePlaybooks !== undefined &&
                                issueIdentities !== undefined &&
                                issueAssets !== undefined &&
                                issueProtocols !== undefined) ?
                                <>
                                    <ReportByIssueFlows onIssueFlowClick={onIssueFlowClick} customData={{ title: "Rules", issueColor: "#000000", flowColor: "#ED4B5A" }} issueFlows={issueRules} />
                                    <ReportByIssueFlows onIssueFlowClick={onIssueFlowClick} customData={{ title: "Playbooks", issueColor: "#2F2F49", flowColor: "#ED4B5A" }} issueFlows={issuePlaybooks} />
                                    <ReportByIssueFlows onIssueFlowClick={onIssueFlowClick} customData={{ title: "Identities", issueColor: "#454563", flowColor: "#ED4B5A" }} issueFlows={issueIdentities} />
                                    <ReportByIssueFlows onIssueFlowClick={onIssueFlowClick} customData={{ title: "Assets", issueColor: "#636383", flowColor: "#ED4B5A" }} issueFlows={issueAssets} />
                                    <ReportByIssueFlows onIssueFlowClick={onIssueFlowClick} customData={{ title: "Protocols", issueColor: "#8887A0", flowColor: "#ED4B5A" }} issueFlows={issueProtocols} />
                                    {/* <ReportByPlaybooks issueFlows={issuePlaybooks}/>
                            <ReportByIdentities issueFlows={issueIdentities}/>
                            <ReportByAssets issueFlows={issueAssets}/> 
                            <ReportByProtocols issueFlows={issueProtocols}/> }
                                </>
                                : null}
                        </div>
                        : null
                    }
                    <div className={"hr_ruler margintop20 inc-ruler"}></div>
                </>
            } */}
            <div className='marginBottom35'>
                <AMBreadcrumb classes={{ containerClass: ' bradcrumb-animation' }} />
            </div>
            <div className="margintop20">
                <div className="flow_table_container incidents-container">
                    <div>
                        <div className="search_section" style={{ float: 'left' }}>
                            <div data-id="tab1" style={{ marginRight: 5 }} className={"tab selected_tab"}>INCIDENTS {totalCount != undefined ? "(" + totalCount + ")" : ""}</div>
                            {/* <div data-id="tab2" className={"tab"} onClick={() => history.push("/prevention?order_by=desc&sort_by=created_date")}>ACTIONS</div> */}

                        </div>
                        <div style={{ float: 'right' }}>
                            <TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/issues/createdperday'} pageType={'incidents'}></TimeBasedSearchContainer>
                        </div>
                        {/*  {
                            authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                <button disabled={!isMarkKnownEnabled() || showAdvanceSearch} onClick={markKnown} className={'button_styled close-inc-btn margin-right-10 mark-known-btn' + (!isMarkKnownEnabled() ? ' disableItems grayscale ' : '')}>Mark Asset as Known</button>
                                : null
                        }
                        {
                            authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                <button disabled={!isAddExceptionEnabled() || showAdvanceSearch} onClick={addException} className={'button_styled close-inc-btn margin-right-10 add-exce-btn' + (!isAddExceptionEnabled() ? ' disableItems grayscale ' : '')}>Add Exception</button>
                                : null
                        } */}
                        {/* {
                            authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                <button disabled={!isCloseIncEnabled() || showAdvanceSearch} onClick={closeTickets}
                                    className={'button_styled close-inc-btn' + ((!isCloseIncEnabled() || showAdvanceSearch) ? ' disableItems grayscale ' : '')}>
                                    Close {selectCount == 1 ? `1 Incident` : selectCount > 1 ? `${selectCount} Incidents` : 'Incidents'}</button>
                                : null
                        }
 */}
                        {/* {
                            authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                <MoreActionBtn options={MoreActionsOptions} groupContainerId={'issue-more-action-btn-grp'} classes={{
                                    container: showAdvanceSearch ? ' disableItems' : '',
                                    buttonGroup: (isAddExceptionEnabled() || isMarkKnownEnabled() || isKnownIPEnabled().isEnabled) ? ' ' : ' disabled '
                                }} /> : null
                        } */}

                    </div>

                    <div id="tab1" className="tab-panel">
                        <table id="table" className="issues_tb box_shadow sticky_table_top issues-tab">
                            <thead>
                                <tr>
                                    <th colSpan={16} className="rounded_topheader" style={{ background: "#ffffff", color: "#4e5267" }} >
                                        <div className="reload_container">
                                            <div ref={reloadRef} className="reload_color_icon" onClick={reloadIssues}></div>
                                        </div>

                                        <div className="table_title">Last Updated: {lastUpdate}</div>
                                        {/* <div className="search_white_icon">&nbsp;</div> */}
                                        {/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
                                        <div className="search_flex">
                                            {/* <div className={(disabledFilter ? "filter_disabled" : filterStatus ? "filter_button" : "filter_button_grey") + (!enableFilter ? ' disableItems' : '')} onClick={() => handleFilterWidget(true)}></div> */}
                                            <PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
                                                isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied} id='issue-more-action-btn-grp'
                                            />
                                            <div className='issue-advance-search-btn-con'><button onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search'} >Advanced Search</button></div>
                                            <div className="search_container issue_page scrollbar-container">
                                                <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                                    onClick={handleLensSearch}
                                                >&nbsp;</div>&nbsp;
                                                <Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
                                                <input
                                                    className="search_input flex_none"
                                                    value={searchInput}
                                                    placeholder="Search..."
                                                    onKeyDown={handleSearch}
                                                    onChange={handleSearchChange}
                                                />
                                                {getTagsForSearchInput(tags).length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
                                                    setTagFlag(true);
                                                    if (isTimeBasedSearchApplied) {
                                                        const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
                                                        setTags(tempTags);
                                                    } else {
                                                        setTags([])
                                                    }
                                                }}>&nbsp;X</span> : null}
                                            </div>
                                        </div>

                                    </th>
                                </tr>

                                <tr>
                                    {
                                        authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                            <th style={{
                                                //minWidth: window.matchMedia("(min-width: 2560px)").matches ? 60 : ,
                                                padding: '0px'
                                            }} rowSpan={2}>
                                                <AMMultiCheckbox
                                                    indeterminate={selectAllIndeterminate}
                                                    menuItems={IssueMultiSelectCheckboxItems}
                                                    onClick={onSelectAllClick} checked={selectAll || false} disabled={!openIssuesCount || openIssuesCount < 1}
                                                />
                                                {/* <AMCheckbox indeterminate={selectAllIndeterminate} onClick={onSelectAllClick} checked={selectAll || false} disabled={!openIssuesCount || openIssuesCount < 1} /> */}
                                            </th>
                                            : null
                                    }

                                    <th colSpan={2} style={{ padding: '0', textAlign: 'center' }}>
                                        <table style={{ border: 'none', margin: '0', textAlign: 'center', boxShadow: 'none' }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2} className="empty_cell no_sort" style={{ borderRight: 'none', borderBottom: '1px solid #ffffff', textAlign: 'center' }}>Activity</th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={2} className={dateSortDir} onClick={handleSort} style={{ borderRight: 'none', borderTop: '1px solid #ffffff', textAlign: 'center' }} id="src_ip">Last Access Time</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </th>
                                    {/* <th style={{minWidth:window.matchMedia("(min-width: 2560px)").matches ? 84 : 42}} rowSpan={2} className={dateSortDir}  onClick={handleSort}>Last Access Time</th> */}
                                    {/* <th style={{width:window.matchMedia("(min-width: 2560px)").matches ? 100 : 50}} rowSpan={2} className={flowSortDir} onClick={handleSort}># Flows</th> */}
                                    {/* <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 100 : 50 }} rowSpan={2} className={issueSortDir} onClick={handleSort}>Incident #</th> */}
                                    <th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 86 : 43 }} rowSpan={2} className={locationSortDir} onClick={handleSort}>Sensor Site Code</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 190 : 100 }} rowSpan={2} className={ruleSortDir + (" align_left")} onClick={handleSort}>Matching Playbook</th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 235 : 130 }} className={playbookSortDir + (" align_left")} onClick={handleSort} rowSpan={2}>Issue Highlights</th>
                                    <th colSpan={2} style={{ padding: '0', textAlign: 'center' }}>
                                        <table style={{ border: 'none', margin: '0', textAlign: 'center', boxShadow: 'none' }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2} className="empty_cell no_sort" style={{ borderRight: 'none', borderBottom: '1px solid #ffffff', textAlign: 'center' }}>Identity / Source</th>
                                                </tr>
                                                <tr>
                                                    <th onClick={handleSort} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 84 : 42, textAlign: 'center' }} id="src_type" className={srcTypeSortDir}>Type</th>
                                                    <th onClick={handleSort} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 182 : 120, textAlign: 'center' }} id="src_name" className={srcNameSortDir}>Name</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </th>
                                    <th colSpan={2} style={{ padding: '0', textAlign: 'center' }}>
                                        <table style={{ border: 'none', margin: '0', textAlign: 'center', boxShadow: 'none' }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2} className="empty_cell no_sort" style={{ borderRight: 'none', borderBottom: '1px solid #ffffff', textAlign: 'center' }}>Asset / Destination</th>
                                                </tr>
                                                <tr>
                                                    <th onClick={handleSort} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 88 : 42, textAlign: 'center' }} id="des_type" className={desTypeSortDir}>Type </th>
                                                    <th onClick={handleSort} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 190 : 120, textAlign: 'center', borderRight: 'none' }} id="des_name" className={desNameSortDir}>Name </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </th>
                                    <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 136 : 68 }} rowSpan={2} className={protocolSortDir} onClick={handleSort}>Protocol</th>


                                    <th colSpan={2} style={{ borderTop: '1px solid #ffffff', textAlign: 'center' }} className='padding-right-5' >Incident#</th>
                                    <th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 50 : 25 }} rowSpan={2} className={riskSortDir} onClick={handleSort}>Risk</th>
                                    {/*  {
                                        authDetails.permissions.Issues_and_Prevention.bulk_close_issues == 'readwrite' ?
                                            <th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 50 : 25, padding: '0px' }} rowSpan={2}><AMCheckbox indeterminate={selectAllIndeterminate} onClick={onSelectAllClick} checked={selectAll || false} disabled={!openIssuesCount || openIssuesCount < 1} /></th>
                                            : null
                                    } */}
                                    <th rowSpan={2} className="no_sort issue-action-column-td padding-right-15" >Actions</th>
                                </tr>

                                <tr>
                                    <th onClick={handleSort} style={{ borderTop: '1px solid #ffffff', minWidth: window.matchMedia("(min-width: 2560px)").matches ? 182 : 65, textAlign: 'center' }} id="first_flow_time" className={daysOpen}>Open Since</th>
                                    <th onClick={handleSort} style={{ borderTop: '1px solid #ffffff', minWidth: window.matchMedia("(min-width: 2560px)").matches ? 95 : 55, textAlign: 'center' }} id="src_name" className={flowSortDir}># Flows</th>
                                    <th colSpan={2} className={srcIpSortDir} onClick={handleSort} style={{ borderTop: '1px solid #ffffff', textAlign: 'center' }} id="src_ip">Hostname</th>
                                    <th colSpan={2} className={desIpSortDir} onClick={handleSort} style={{ borderTop: '1px solid #ffffff', textAlign: 'center' }} id="des_ip">Hostname</th>
                                    <th style={{ borderTop: '1px solid #ffffff', minWidth: window.matchMedia("(min-width: 2560px)").matches ? 112 : 56 }} colSpan={2} className={statusSortDir} onClick={handleSort}>Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    loader ?
                                        <tr>
                                            <td colSpan={16} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                                        </tr> :
                                        responseData?.result == 'error' || responseData?.result.length == 0 ?
                                            <tr>
                                                <td colSpan={16} style={{ textAlign: 'center' }}>{tableFetchError}</td>
                                            </tr>
                                            :
                                            responseData && responseData.result !== null && responseData.result.length > 0 ? renderData(responseData.result) :
                                                <tr>
                                                    <td colSpan={16} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
                                                </tr>
                                }
                            </tbody>

                        </table>
                        <div className="clrBoth"></div>
                    </div>
                    <div className="download_section">
                        <button type={('button')} onClick={downloadCSV} className={"width180 " + (loading.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>

                        {loading.loading ?
                            <div className='loading_info'>
                                Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Issues
                            </div>
                            : null}
                        {PartnerConfigState?.PartnerShortProduct && <CSVLink
                            headers={headers}
                            filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")} _Issues.csv`}
                            data={downData!}
                            ref={csvLinkEl}
                        />}
                        <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                            <Link to={'/archivedata'} className='link-text font12'>Incidents inactive for more than 60 days are archived here.</Link>
                        </div>
                    </div>
                    <div className="float_right goto_page">Go to page:
                        <input
                            type="text"
                            name="page_no"
                            className=""
                            defaultValue=""
                            placeholder="No."
                        />
                        <button type="button" onClick={handleGotoPage} className="button_gray float_right">Go</button>
                    </div>
                    <div className="float_right">
                        <ReactPaginate
                            previousLabel={"← Previous"}
                            nextLabel={"Next →"}
                            forcePage={param.page !== undefined ? (param.page - 1) : 0}
                            pageCount={responseData && responseData.result.length > 0 ? (Math.ceil(responseData.total / responseData.rpp)) : 1}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            onPageChange={handlePaginate}
                        />
                    </div>
                </div>
            </div>
            <Popup
                closeOnDocumentClick={false}
                overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                open={closeIncPopUp}
                closeOnEscape={false}
                modal
            >
                <div className={"modal"}>
                    <div className={"close"} onClick={handleCloseIncPopUp}></div>
                    <div className={"header"}><div className="pbheader_ellipisis" title={'Close Incidents'}>Close Incident{(selectCount && selectCount == 1) || (closeFromAction) ? '?' : 's?'}</div></div>
                    <div className={"content"}>
                        <div className="font14 margintop20">
                            Selected incidents will be closed.
                            Are you sure you want to close <strong>{selectCount && selectCount > 0 ? selectCount : closeFromAction ? 1 : ''}</strong> incident{(selectCount && selectCount == 1) || (closeFromAction) ? '?' : 's?'}
                        </div>
                        {selectAll && tags.filter(i => !i.includes('status:Open')).length == 0 &&
                            <>
                                {/* <label>Note: This action will close all {selectCount} incidents.</label> */}
                                <div
                                    className='width100per'
                                    style={{ display: 'flex', alignItems: 'center' }}
                                ><label style={{ width: "35%" }}>To confirm, type "Close": </label><input

                                        style={{ width: '40%' }}
                                        className={"height35 " + (disableYes ? ' borderError' : ' ')}
                                        type='text' onChange={(e) => {
                                            if (e.target.value && e.target.value.trim().toLowerCase() == 'close') {
                                                setIsCloseAll(e.target.value)
                                            } else {
                                                setIsCloseAll(undefined)
                                            }
                                        }} /></div>
                            </>
                        }
                        <div>
                            <label>Comment (optional)</label>
                            <textarea style={{ resize: 'none' }} onChange={(e: any) => setStatusComment(e.target.value)}></textarea>
                        </div>
                        <div>
                            <input className="margintop10 font14 close-ticket-check" type="checkbox" checked={closeTicket} onChange={handleCloseTicket} />
                            <span className="font14">Close tickets? In addition to closing selected incidents, close any related tickets.</span>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="shadow_footer fl" style={{ marginTop: 0 }}></div>
                    <div className="popup_footer fl">
                        <div className="policy_defualt_button" onClick={handleCloseIncPopUp}>No</div>
                        <div className={"policy_save_button " +
                            (selectAll && tags.filter(i => !i.includes('status:Open')).length == 0 && !isCloseAll ? ' disabled grayscaleOne' : '')
                        } onClick={confirmPasswordOperation}
                        >Yes</div>
                    </div>
                </div>
            </Popup>
            <AMConfirmPassword
                title="Confirm Close Incidents"
                content="Enter your password to confirm this operation"
                openConfirmPassword={openConfirmPassword}
                confirmPasswordOperation={confirmPasswordOperation}
                closeConfirmPassword={closeConfirmPassword}
                onPasswordEnter={(evt: any) => setOpPassword(evt)}
            />
            {openMatchRule ? <MatchruleDetails data={matchedRuleData} closeMatchRule={() => setOpenMatchRule(false)} /> : ''}
            {openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpenFlow(false)} /> : ''}
            {openMoreDetails ? <MoreActionsPopup handleTicketCreateNew={handleTicketCreate} closeMoreDetails={() => setOpenMoreDetails(false)} /> : ''}
            {showAddException ? <AddExceptionPopUp handleClose={() => setShowAddException(false)} handleConfirmAddException={handleConfirmAddException} criteria={exceptionCriteria} /> : null}
            {showMarkKnown ? <PostureMarkKnownPopUp onClose={() => {
                setShowMarkKnown(false);
                setIsMarkKnownQuery(undefined);
            }} data={markKnownData} query={isMarkKnownQuery} exclude_incidents_list={excludeIncidents} /> : null
            }
            {knownIPData ? <MarkKnownIPPopUp knownIPData={knownIPData} onClose={() => setKnownIPData(undefined)} onProceed={onKnownIPDataProceed} /> : null}
            {showSuspectedPopUp?.showPopUp && <SuspectedList
                handleClose={() => setShowSuspectedPopUp(false)}
                resultArray={showSuspectedPopUp?.response}
                source={showSuspectedPopUp?.sourceName}
                matchingRule={showSuspectedPopUp?.matching_rule_name}
            />}
        </>
    )
}

export default React.memo(withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    rule_name: StringParam,
    d_protocol: StringParam,
    risk: NumberParam,
    hash: StringParam,
    d_name: StringParam,
    s_time: NumberParam,
    e_time: NumberParam,
    disable_filter: StringParam,
    hard_refresh: StringParam,
    summary: StringParam,
    filters: withDefault(ArrayParam, [])
}, Issues));
