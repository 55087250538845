import React, { SyntheticEvent, useEffect, useImperativeHandle, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import './PatternForm.scss';
import { TrashIcon } from "../../../../Icons";
import { usePatternFormUtil } from "./usePatternFormUtil";
import { PatternFormInput } from "./PatternFormInput";
import { usePatternForm } from "./usePatternForm";
import { IAMSelectOption } from "../../../../components/core/AMSelect/AMSelect";
import CheckboxAutocomplete from "../../../../components/core/AMAutoComplete/AMAutoComplete";

interface IPatternFormGeneratorProps {
    config: Array<IPatternConfigItem>,
    defaultState?: IPatternFormData | undefined,
    formType?: string,
    validateForm?(val: boolean): any,
    classes?: Partial<{
        patternFormContainer: string,
        formListContainer: string,
        groupedListClass?: string
    }>,
    showOperatorBetween?: boolean,
    propsConfig?: {
        isGroupedList?: boolean
    },
    onControlRemove?(index: number): void,
    onFieldAdd?(): void
}

export interface IPatternFormOptions {
    id: string,
    label: string,
    placeholder?: string,
    valueType?: string,
    isGroupRoot?: boolean,
    key?: string,
    value?: string
}

export interface IPatternConfigItem {
    id: string,
    type: string,
    operators: Array<IPatternFormOptions>,
    label: string,
    placeholder?: string,
    defaultOperator?: string,
    validate?(rowVal: IPatternFormValue | undefined): string | null,
    error?: string | null,
    unsupportedPb?: Record<string, boolean>,
    selectOptions?: Array<IAMSelectOption>,
    asyncId?: string,
    valueType?: string,
    isGroupRoot?: boolean,
    groupId?: string,
    key?: string,
    value?: string
}

export interface IPatternFormValue {
    searchKey: string,
    operator: string,
    value: string
}

export interface IPatternFormData {
    searchFilter: IPatternFormValue[];
}

interface IPatternFormState {
    selectedSearchKeys: Array<string>;
    allSearchKeys: Array<IPatternFormOptions>;
}

export interface IPatternPayload {
    type: string,
    value: Array<string> | boolean | string | any
}

export interface IPatterFormRef {
    handlePatternFormSave(data?: any): IPatternFormValue[];
    isFormValid: boolean
}

export const PatternFormGenerator = React.forwardRef(({
    config, defaultState, formType = 'create', classes,
    validateForm, showOperatorBetween = true, propsConfig, onControlRemove, onFieldAdd }: IPatternFormGeneratorProps, ref?: any) => {

    useImperativeHandle(ref, () => ({
        handlePatternFormSave() {
            return handlePatterSave();
        },
        isFormValid: isValid
    } as IPatterFormRef));
    const { control, getValues, setValue, watch } = usePatternForm(defaultState);
    const { fields, append, remove, } = useFieldArray({ control, name: "searchFilter" });
    const [isValid, setIsValid] = useState(false);
    const [patternFormState, setPatternFormState] = useState<IPatternFormState>({
        allSearchKeys: [],
        selectedSearchKeys: []
    });
    const formRef = React.useRef<HTMLFormElement>(null)
    const { getConfigItemByIndex, getConfigItemByName, validateControl } = usePatternFormUtil(config, watch);

    const values = watch(); // To reload the state

    useEffect(() => {
        setIsValid(true);
        defaultState?.searchFilter?.forEach((i: IPatternFormValue, ind: number) => {
            const c = validateControl(i.value, ind);
            if (!c) {
                setIsValid(c);
            }
        });
    }, [defaultState])

    useEffect(() => {
        if (validateForm) {
            if (values.searchFilter?.length === 0) {
                validateForm(false)
            } else {
                validateForm(isValid);
            }
        }
    }, [isValid])

    const handlePatterSave = () => {
        return values.searchFilter;
    }

    const getSupportedOperaters = (index: number) => {
        const searchFilter = getValues();
        if (searchFilter?.searchFilter) {
            const val = searchFilter?.searchFilter[index];
            if (val) {
                const c = config.find((i: IPatternConfigItem) => i.key === val.searchKey);
                if (c) {
                    return c.operators;
                }
            }
        }
        return [];
    }

    const onRemoveControl = (index: number) => {
        if (index === fields?.length - 1) {
            setIsValid(true)
        }
        remove(index);
        if (onControlRemove) {
            onControlRemove(index);
        }
    }

    const onAddControl = () => {
        append({ searchKey: "", value: "", operator: "" });
        setIsValid(false)
        if (onFieldAdd) {
            onFieldAdd();
        }
    }

    useEffect(() => {
        if (patternFormState?.allSearchKeys?.length === 0) {
            const search_keys = config.map((i: IPatternConfigItem) => ({ label: i.label, id: i.key, isGroupRoot: i.isGroupRoot }));
            setPatternFormState({ ...patternFormState, allSearchKeys: search_keys });
        }
        const search = values?.searchFilter?.map((i: IPatternFormValue) => i.searchKey)
        const isEqual = search && search?.every(item => patternFormState?.selectedSearchKeys.includes(item))
            && patternFormState?.selectedSearchKeys.every(item => search.includes(item));
        if (search && !isEqual) {
            setPatternFormState({ ...patternFormState, selectedSearchKeys: search })
        }
    }, [values])

    useEffect(() => {
        validateAllControls();
    }, [values])

    const validateAllControls = () => {
        let isVal = true;
        values.searchFilter.forEach((i: IPatternFormValue, index: number) => {
            if (!i.searchKey) {
                isVal = false;
                return;
            };
            let isValidControl = validateControl(i.value, index);
            /* if (i.searchKey === '' && i.value === '' && i.operator === '') {
                isValidControl = true;
            } */
            isVal = isVal && isValidControl;
            if (formRef.current) {
                if (!isValidControl) {
                    formRef?.current?.getElementsByClassName(`searchFilter${index}.value`)[0]?.classList.add('error-input');
                } else {
                    formRef?.current?.getElementsByClassName(`searchFilter${index}.value`)[0]?.classList.remove('error-input');
                }
            }
        });
        //values.searchFilter.length === condition can be removed if validation of form not working fine
        if (!isVal || values.searchFilter.length === 0) {
            setIsValid(false);
        } else {
            setIsValid(true)
        }
        return isVal;
    }

    const handleOnMenuOpen = () => {
        setTimeout(() => {
            const _grpArray = Array.from(document.querySelectorAll('.pattern-form-control-popper-root .grouped-list li'));
            const o = {} as any;
            if (_grpArray && _grpArray?.length > 0) {
                _grpArray.forEach((i: Element, index: number) => {
                    if (i.classList.contains('group-root')) {
                        o[index] = index;
                    }
                })
            }
            const res = {} as any;
            let currentIndex = 0;
            for (let i = 0; i < (_grpArray?.length || 0); i++) {
                if (i === o[i as keyof typeof o]) {
                    res[i] = true;
                    currentIndex = i;
                } else {
                    const el = _grpArray[i];
                    if (res[currentIndex] === true && !el.classList.contains('already-selected')) {
                        res[currentIndex] = false;
                    }
                }
            }
            for (const k in res) {
                if (res[k] === true) {
                    _grpArray[k]?.classList?.add('already-selected');
                }
            }
        }, 100)
    }


    const getItemLabel = (
        option
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : "";
        } else if (!isNaN(parseInt(option))) {
            console.log(getValues())
            const o = config[option];
            return o?.label || ''
        } else if (typeof option == 'string') {
            const o = config.find((opt: IPatternConfigItem) => opt.key == option);
            return o?.label || ''
        } else {
            return option?.value ? option?.value : "";
        }
    };

    const renderOptionsFn = (props, item: IPatternConfigItem) => {
        handleOnMenuOpen()
        if (item.isGroupRoot) {
            return <span></span>
        } else {
            const classes = (item.key && (patternFormState?.selectedSearchKeys?.indexOf(item?.key) > -1) ? 'already-selected' : '') + ' font14_imp';
            return <MenuItem
                {...props}
                classes={classes + ' font14_imp adv-search-menu-item'}
                className={classes + ' adv-search-menu-item adv-search-menu-item-child'} value={item?.key}
            >{item.label}</MenuItem>;
        }
        return <span></span>
    }

    const renderGroup = (params) => {
        const count = params?.children?.filter(i => i?.key).length;
        return (
            <>
                {count > 0 && <li className="adv-search-menu-group-root">{params?.group}</li>}
                <div className="adv-search-menu-item-child">{params?.children}</div>
            </>
        )
    }

    const handleFilterChange = (e: SyntheticEvent, config: Array<IPatternConfigItem>, onChange, index: number) => {
        const c = config[0] as IPatternConfigItem
        if (c) {
            if (c.isGroupRoot) {
                return;
            }
            onChange(c.key);
            setValue(`searchFilter[${index}].operator`, c?.defaultOperator || '');
            setValue(`searchFilter[${index}].value`, '');
        }
    }

    const renderOperatorOptionsFn = (item: IPatternFormOptions, onChange) => {
        const { id, label } = item as IPatternFormOptions
        return <MenuItem value={id}>{label}</MenuItem>
    }

    const getOperatorLabel = (option: IPatternFormOptions | string, index: number) => {
        let o: any = option;
        if (typeof option == 'string') {
            o = { id: option };
        }
        let operator;
        if (typeof option == 'number') {
            operator = getSupportedOperaters(index)[option];
        } else {
            operator = getSupportedOperaters(index).filter((i: IPatternFormOptions) => i.id == o.id)[0];
        }
        if (operator) {
            return operator.label || '';
        }
        return '';
    }


    return <>
        <form className="pattern-form-container" ref={formRef}>
            {
                fields.length === 0 && <div className="align_center">No search criteria specified, Click (+) to add one</div>
            }
            <ul className={"scrollbar-container " + classes?.formListContainer}>
                {fields.map((item, index) => {
                    const configItem = getConfigItemByIndex(index) as IPatternConfigItem || {};
                    return <div key={item.key} className={"pattern-form-row" + (!['view', 'edit'].includes(formType) ? ' ' : ' disable-config-item-view')} >
                        {index > 0 && index < config?.length && showOperatorBetween &&
                            <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '0.13em', flexBasis: '100%' }} className="font14">OR</div>}
                        <Controller control={control} name={`searchFilter[${index}].searchKey`} render={({ ref, onChange, value = "" }) => {
                            return <CheckboxAutocomplete
                                label="Select Field"
                                options={config}
                                value={value}
                                onChange={(e, config) => handleFilterChange(e, config, onChange, index)}
                                getItemLabel={getItemLabel}
                                multiple={false}
                                showSeparator={true}
                                onMenuOpen={handleOnMenuOpen}
                                renderOptionsFn={renderOptionsFn}
                                hideClearBtn={true}
                                groupOptions={{ renderGroup }}
                                classes={{
                                    root: 'pattern-form-control-operand pattern-form-control-select',
                                    popper: 'pattern-form-list-popper'
                                }}
                                formType={formType}
                                name={`searchFilter[${index}].searchKey`}
                            />
                        }}></Controller>
                        <Controller control={control} name={`searchFilter[${index}].operator`} render={({ ref, onChange, value = "" }) => {
                            return <CheckboxAutocomplete
                                label="Select Operator"
                                options={getSupportedOperaters(index)?.map((item) =>
                                    ({ ...item, key: item.id, value: item.label })).filter(i => i)}
                                value={value}
                                onChange={(e, config) => {
                                    console.log(e, config)
                                    onChange(config[0].key);
                                    /* validateAllControls(); */
                                }}
                                getItemLabel={(option) => getOperatorLabel(option, index)}
                                multiple={false}
                                showSeparator={true}
                                hideClearBtn={true}
                                name={`searchFilter[${index}].operator`}
                                classes={{
                                    root: 'pattern-form-control-operator pattern-form-control-select',
                                    popper: "pattern-form-control-operator-popper"
                                }}
                                formType={formType}
                            />
                        }}></Controller>
                        <Controller control={control} name={`searchFilter[${index}].value`} render={({ ref, onChange, value = "" }) => {
                            return <PatternFormInput config={config}
                                watch={watch} index={index} onChange={onChange} value={value} ref={ref}
                                setIsValid={setIsValid} formType={formType} isValid={isValid}
                                setValue={setValue}
                            />
                        }}></Controller>

                        {/* <button type="button" onClick={() => remove(index)}>Delete</button> */}
                        {
                            !['view', 'edit'].includes(formType) &&
                            < span style={{ marginRight: '0.2em', marginTop: '0.5em' }} onClick={() => onRemoveControl(index)}>
                                <TrashIcon
                                />
                            </span>
                        }
                    </div>
                }
                )}
                {
                    !['view', 'edit'].includes(formType) && fields?.length !== config.length &&
                    <div className={`pattern-form-add-filter ` + (((fields?.length === (config.length - config.filter((i: IPatternConfigItem) => i.isGroupRoot)?.length)
                        || !isValid) && fields?.length !== 0) ? ' disable-config-item' : '')}>
                        <span className="pattern-form-add-filter span" onClick={() => onAddControl()}>+</span>
                    </div>
                }

            </ul>

        </form >
    </>
});