import React from "react"
import { useHistory } from "react-router-dom";
import { PlaybookDocLinks, RuleType } from "./Constants";
import { usePartnerStateContext } from "../../../store/PartnerContextProvider";
export const RuleDescription = (props: any) => {
    const PbADDirNote = () => {
        const { PartnerConfigState } = usePartnerStateContext();
        return <><br></br><strong>
            {/* <div className="margintop5"><i className="font12 ">Note: This playbook requires an {`${PartnerConfigState.PartnerProductName}`} Active Directory sensor to be deployed. */}
            <div className="margintop5"><i className="font12 ">Note: This playbook requires an AuthMind Active Directory sensor to be deployed.
            </i></div></strong></>
    };

    const PbADNote = () => {
        const { PartnerConfigState } = usePartnerStateContext();
        return <><br></br><strong>
            {/* <div className="margintop5"><i className="font12">Note: This playbook requires an {`${PartnerConfigState.PartnerProductName}`} Active Directory sensor to be deployed in the domain. */}
            <div className="margintop5"><i className="font12">Note: This playbook requires an AuthMind Active Directory sensor to be deployed in the domain.
            </i></div> </strong></>;
    }

    const LearnMore = () => {
        const handleDoc = () => {
            const playBookName = props.ruleType;
            PlaybookDocLinks[playBookName as keyof typeof PlaybookDocLinks]();
        }
        return <>
            {props?.showLink && <>&nbsp;<span onClick={handleDoc} className="link-text">Learn More</span></>}</>
    }

    const history = useHistory();

    const handleKnownAppLink = (evt: any) => {
        history.push('/known_applications');
    }

    const handleMFALink = () => {
        history.push('/mfa');
    }

    const handleDirectory = () => {
        history.push('/directories');
    }

    const RuleDescMap = new Map<String, JSX.Element>();
    RuleDescMap.set(RuleType.UNKNOWN_SAAS_ACCESS, <span>
        Discover accesses to SaaS apps unknown to your org. SaaS apps known to you can be defined&nbsp;<strong className="link-text" onClick={handleKnownAppLink}>here</strong>.
        <LearnMore />
    </span>);
    RuleDescMap.set(RuleType.LACK_OF_MFA, <span>
        Discover accesses where MFA should have happened using a <strong className="link-text" onClick={handleMFALink}>known MFA server</strong> but did not.
        <LearnMore />
    </span>);
    RuleDescMap.set(RuleType.WEAK_PASSWORD, <span>
        Discover identities with weak passwords. Weakness is based on the cost or time required to
        crack the password and not based just on a combination of letters, numbers, symbols, etc.
        <LearnMore />
        <PbADNote />
    </span>);
    RuleDescMap.set(RuleType.COMPROMISED_PASSWORD, <span>
        Discover identities whose password is in compromised password databases or risk feeds.<LearnMore />
        <PbADNote />
    </span>);
    RuleDescMap.set(RuleType.COMPROMISED_USER, <span>
        Discover identities whose corporate email address is found in recent data breaches and might
        include sensitive information like passwords, password hashes, and personal or family data.<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.AUTH_HASH_QUALITY, <span>
        Identify directories where the length of the password hash or the password salt may not be
        big enough to be secure.<LearnMore /><PbADNote />
    </span>);
    RuleDescMap.set(RuleType.AUTH_HASH_SECURITY, <span>
        Identify directories where the algorithm used for hashing the passwords is weak.<LearnMore />
        <PbADNote />
    </span>);
    RuleDescMap.set(RuleType.AUTH_PROTOCOL_QUALITY, <span>
        Identify assets (apps, services, or devices) and directories that are accessed using unencrypted
        protocols or protocols that are not secure.<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.SHADOW_ASSETS, <span>
        Discover assets (apps, services, or devices) where none of the accesses is authenticated against a <strong className="link-text" onClick={handleDirectory}>known directory or IDP</strong>.
        <LearnMore />
    </span>);
    RuleDescMap.set(RuleType.SHADOW_DIRECTORY, <span>
        Discover identity systems that are not marked as a <strong className="link-text" onClick={handleDirectory}>known identity systems or IDP</strong>.<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.SHADOW_ACCESS, <span>
        Discover accesses without authentication against a <strong className="link-text" onClick={handleDirectory}>known directory or IDP</strong>, whereas other accesses to the
        same asset (app, service, or device) have authentication.<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.SHADOW_EXTERNAL_ACCESS, <span>
        Identify accesses from inside your network to unknown assets outside your network (like external websites,
        services, and devices). Your network and known public apps can be defined <strong className="link-text" onClick={handleKnownAppLink}>here</strong>.<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.UNKNOWN_ACCESS, <span>
        Detect accesses between 2 public IPs that are not known to your org because both are not defined in any of the&nbsp;<strong className="link-text" onClick={handleKnownAppLink}>Known Public Apps</strong>, <strong className="link-text" onClick={handleDirectory}>Known Directories</strong>, and other <strong className="link-text" onClick={handleKnownAppLink}>“known”</strong> pages.
        <LearnMore />
    </span>);
    RuleDescMap.set(RuleType.EXPOSED_ASSETS, <span>
        Discover assets (apps, services, or devices) being accessed from outside your network but not marked to be externally
        accessible. Such assets known to you can be defined <strong className="link-text" onClick={handleKnownAppLink}>here</strong>.
        <LearnMore />
    </span>);
    RuleDescMap.set(RuleType.SUSPICIOUS_INBOUND_ACCESS, <span>
        Identify accesses from external IPs with a bad reputation (based on 3rd-party threat intelligence) to
        assets (apps, services, or devices) within your network.<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.SUSPICIOUS_OUTBOUND_ACCESS, <span>
        Identify accesses from within your network to external IPs with a bad reputation
        (based on 3rd-party threat intelligence).<LearnMore />
    </span>);
    RuleDescMap.set(RuleType.ACCESS_TO_UNAUTH_COUNTRIES, <span>
        Detect accesses to countries that are not approved or authorized.<LearnMore />
    </span>);

    RuleDescMap.set(RuleType.ACCESS_FROM_UNAUTH_COUNTRIES, <span>Detect accesses from countries that are not approved or authorized.<LearnMore /></span>);
    RuleDescMap.set(RuleType.ACCESS_FROM_PUBLIC_VPN, <span>Detect accesses from IPs belonging to public VPN services.<LearnMore /></span>);
    RuleDescMap.set(RuleType.ACCESS_TO_PUBLIC_VPN, <span>Detect accesses to IPs belonging to public VPN services.<LearnMore /></span>);
    RuleDescMap.set(RuleType.ACCESS_FROM_ANON_IP, <span>Detect accesses from IPs that are Tor nodes or anonymizing proxies.<LearnMore /></span>);
    RuleDescMap.set(RuleType.ACCESS_TO_ANON_IP, <span>Detect accesses to IPs that are Tor nodes or anonymizing proxies.<LearnMore /></span>);
    RuleDescMap.set(RuleType.DEVIATION_IN_DAILY_ASSET, <span>Detect deviation in the number of daily flows on an asset based on criteria like total, 7-day maximum, 7-day average, or 7-day minimum.<LearnMore /></span>);
    RuleDescMap.set(RuleType.UNAUTHORIZED_ASSET_ACCESS, <span>Detect access exceptions to the selected asset(s) that do not comply with their configured access policy.<LearnMore /></span>);
    RuleDescMap.set(RuleType.UNAUTHORIZED_IDENTITY_ACCESS, <span>Detect suspicious accesses by the selected identity(s) that do not comply with their configured access policy.<LearnMore /></span>);
    RuleDescMap.set(RuleType.SUSPECTED_DIR_BOT, <span>Detect when a directory or identity provider( IdP) is under attack by a bot or botnet.<LearnMore /></span>)
    RuleDescMap.set(RuleType.SUSPECTED_BRUTE_FORCE, <span>Detect when an account and/or its password is under a suspected brute-force attack. This could also be a credential-stuffing attack.<LearnMore /></span>)
    RuleDescMap.set(RuleType.SUSPECTED_PASS_SPRAY, <span>Detect when a directory or identity provider (IdP) is under a suspected password spray attack.<LearnMore /></span>)
    RuleDescMap.set(RuleType.NTLM_RELAY_ATTACK, <span>Detect when an Active Directory is under a suspected NTLM relay attack.<LearnMore />
        <PbADDirNote /></span>)
    RuleDescMap.set(RuleType.ENUM_AD_ADMINS, <span>Detect when there is an attempt to enumerate admin accounts in an Active Directory.<LearnMore />
        <PbADDirNote /></span>);
    RuleDescMap.set(RuleType.ENUM_AD_USERS, <span>Detect when there is an attempt to enumerate user accounts in an Active Directory.<LearnMore /><PbADDirNote /></span>);
    RuleDescMap.set(RuleType.UNAUTH_LOGIN_TIME, <span>Detect when there are repeated attempts to log in to an Active Directory at an unauthorized time.<LearnMore /><PbADDirNote /></span>);
    RuleDescMap.set(RuleType.UNAUTH_LOGIN_DEVICE, <span>Detect when there are repeated attempts to log in to an Active Directory from an unauthorized device.<LearnMore /><PbADDirNote /></span>);
    RuleDescMap.set(RuleType.SUSPECTED_ATT_EXP_ACC, <span>Detect when there are repeated attempts to log in to an Active Directory using an expired account/password.<LearnMore /><PbADDirNote /></span>);
    RuleDescMap.set(RuleType.SUSPECTED_ATT_DIS_ACC, <span>Detect when there are repeated attempts to log in to an Active Directory using a disabled account.<LearnMore /><PbADDirNote /></span>);
    RuleDescMap.set(RuleType.SUSPECTED_ATT_LOCK_ACC, <span>Detect when there are repeated attempts to log in to an Active Directory using a locked  account.<LearnMore /><PbADDirNote /></span>);
    RuleDescMap.set(RuleType.ACCOUNT_TAKEOVER, <span>
        Detect suspected account takeovers where the source of the latest access is suspicious compared to past accesses due to an Impossible Travel situation.<LearnMore />
    </span>)
    RuleDescMap.set(RuleType.SUSPECTED_ACCESS_TOKEN, <span>
        Detect suspected access token sharing with other users.<LearnMore />
    </span>)
    RuleDescMap.set(RuleType.ASSETS_WITHOUT_MFA, <span>Discover assets (apps, services, or devices) where some or all identities are authenticating against a known directory/IDP but none of the identities are doing MFA.<LearnMore /></span>)
    
    const getRuleEle = (ruleType: string) => {
        const el = RuleDescMap.get(ruleType);
        return el;
    }

    return <span className="rule-type-description">
        {getRuleEle(props.ruleType)}
    </span>
}