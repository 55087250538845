import React, { useState, useEffect } from "react";
import { BaseProps } from "../../constants/types";
import { SusAttackDisabledState } from "../../../../types/playbooks-config";


const SuspectedDisabledAccount = ({ parentState, isSavedOnce, getState, defaultState, formType, getExpression, ruleType }: BaseProps<SusAttackDisabledState>) => {
    const [state, setState] = useState<SusAttackDisabledState>(defaultState);

    useEffect(() => {
        getState(state);
    }, [state])

    useEffect(() => {
        setState(defaultState);
    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
        }
    }, [isSavedOnce])

    const handleNumAccWithBadPass = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ dir_params: { user_disabled_logon_count_curhour: parseInt(e.target.value) } })
    }

    useEffect(() => {
        const user_input1 = state?.dir_params?.user_disabled_logon_count_curhour;
        const object = {
            user_input1
        }
        if (getExpression) {
            getExpression(object);
        }
    })


    return <>
        <div className="dir-main-container">
            <div className="dir-container-row bold-text">
            <label className="playbook-config-title marginBottom0">
                    A disabled AD account will be marked as under attack when:</label>
            </div>
            <div className="dir-container-row">
                <label>
                    Number of login attempts in an hour is more than
                </label>
                {
                    (formType && ['edit', 'view'].includes(formType)) ? <label className="view-label">{state?.dir_params?.user_disabled_logon_count_curhour}</label> :
                        <input type="number" onChange={handleNumAccWithBadPass} name="num_unauth_attempts" value={state?.dir_params?.user_disabled_logon_count_curhour} />}
            </div>
        </div></>
}

export default React.memo(SuspectedDisabledAccount);