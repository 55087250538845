import React, { useState, useEffect } from 'react'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import {Api} from '../../components/Axios'
import { EditPanel } from './sub'
import { useToasts } from '../../components/core';

// import './listing.css'

type ActionType = {
    type?: string;
    id?: string;
}

type Certificate = {
    _id?: string;
    chain?: string;
    private_key?: string;
    public_key?: string; 
}

const Ssl = ({query, setQuery}: any) => {
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [cert, setCert] = useState<Certificate>()
    const { addToast } = useToasts()

    const LoadCert = () => {
        Api.get('/cert')
        .then((res: { data: any }) => {
            setCert(res.data)
        })
        .catch((error: any) => {
        })
    }

    useEffect(() => {
        LoadCert()
    }, [])

    useEffect(() => {
        switch(actionType?.type) {            
            case 'edit':
                setPanelTitle("Update Certificate")
                setOpenPanel(true)
                return

            case 'delete':
                const headers = {'Operation':'DELETE'}
                Api.post('/cert', "", {headers: headers})
                .then((res: { data: any }) => {
                    LoadCert()
                    addToast("Certificate deleted successfully.", {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                })
                .catch((error: any) => {
                })
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        setQuery( )
    }

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterEditOperation={AfterEditOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const AfterEditOperation = (action: string) => {
        LoadCert()
        setOpenPanel(false)
    }

    return (
        <>
            <RightPanel />
            <div className="page_title_area">
                <h2>TLS/SSL Certificate</h2>
                
            </div>
            <div className="box_grid_layout">
                <label className="font_semibold margin0 width25per" >Public Key :</label>
                <label className="auto_height font12 width100per">
                    {cert?.public_key}
                </label>

                <div className="clrBoth"></div>
                
                <label className="font_semibold width25per" >Certificate Chain : </label>
                <label className="auto_height font12 width100per">
                    {cert?.chain}
                </label>
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default (Ssl)