import { MenuItem, Select } from '@mui/material';
import './account-takeover.scss';
import { BaseProps } from '../../constants/types';
import { AccountTakeoverState } from '../../../../types/playbooks-config';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';

const options = ['miles', 'km'].map((i) => ({ key: i, value: i }))

const AccountTakerConfig = ({ parentState, isSavedOnce, getState, defaultState,
    formType, ruleType }: BaseProps<AccountTakeoverState>) => {
    const [state, setState] = useState<AccountTakeoverState>(defaultState);
    const { register, errors, control, getValues, setValue, reset } = useForm<AccountTakeoverState>();

    useEffect(() => {
        getState(state)
    })

    useEffect(() => {
        setState(defaultState);
        reset(defaultState)
    }, [defaultState]);

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            reset(parentState);
        }
    }, [isSavedOnce]);

    const handleFormChange = () => {
        const s = getValues() as any;
        if (s.account_takeover_params) {
            s.account_takeover_params.displacementValue = s?.account_takeover_params?.displacementValue ?
                parseInt(s?.account_takeover_params?.displacementValue?.toString()) : null;
            s.account_takeover_params.distanceTraveledValue = s?.account_takeover_params?.distanceTraveledValue ?
                parseInt(s?.account_takeover_params?.distanceTraveledValue?.toString()) : null
        }

        setState(s)
    }

    const getItemLabel = (op) => {
        console.log(op)
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    return <>
        <form action="/" onChange={handleFormChange}>
            <div className={"account-take-over-container " + (formType == 'view' ? ' disable-config-item' : '')}>
                <span className='playbook-config-title'
                >Detect account takeovers where the latest access is suspicious based on the following criteria:
                </span>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.account_takeover_params?.displacementFlag}
                        ref={register}
                        name='account_takeover_params.displacementFlag'
                    ></input>
                    <label className='top15 label-flex'>User’s displacement (distance/time) is greater than<input type='number'
                        value={state?.account_takeover_params?.displacementValue}
                        name='account_takeover_params.displacementValue' ref={register}
                    ></input>
                        <Controller
                            render={({ ref, onChange, value = "" }) => (
                                <CheckboxAutocomplete
                                    classes={{ root: 'acc-take-over-sel' }}
                                    options={options}
                                    value={{ key: state?.account_takeover_params?.displacementUnit, value: state?.account_takeover_params?.displacementUnit }}
                                    getItemLabel={getItemLabel}
                                    hideClearBtn={true}
                                    multiple={false}
                                    onChange={(e, config) => {
                                        setValue('account_takeover_params.displacementUnit', config[0].key)
                                        onChange(config[0].key);
                                        handleFormChange();
                                    }}
                                />
                            )}
                            control={control}
                            name='account_takeover_params.displacementUnit'
                        />
                        &nbsp;
                        per hour</label>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox'
                        checked={state?.account_takeover_params?.distanceTraveledFlag}
                        name='account_takeover_params.distanceTraveledFlag' ref={register}></input>
                    <label className='top15 label-flex'>Distance traveled since last access is greater than<input type='number'
                        value={state?.account_takeover_params?.distanceTraveledValue}
                        name='account_takeover_params.distanceTraveledValue' ref={register}
                    ></input>
                        <Controller
                            render={({ ref, onChange, value = "" }) => (
                                <CheckboxAutocomplete
                                    classes={{ root: 'acc-take-over-sel' }}
                                    options={options}
                                    value={{ key: state?.account_takeover_params?.distanceTraveledUnit, value: state?.account_takeover_params?.distanceTraveledUnit }}
                                    getItemLabel={getItemLabel}
                                    hideClearBtn={true}
                                    multiple={false}
                                    onChange={(e, config) => {
                                        setValue('account_takeover_params.distanceTraveledUnit', config[0].key)
                                        onChange(config[0].key);
                                        handleFormChange();
                                    }}
                                />
                            )}
                            control={control}
                            name='account_takeover_params.distanceTraveledUnit'
                        /></label>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='account_takeover_params.newIspFlag'
                        checked={state?.account_takeover_params?.newIspFlag}
                        ref={register}></input>
                    <label className='top6'>Access from a new ISP was identified</label>
                </div>
                <div className='and-text-at'>AND</div>
                <div className="account-take-over-flex-item">
                    <input type='checkbox' name='account_takeover_params.newIpRangeFlag' ref={register}
                        checked={state?.account_takeover_params?.newIpRangeFlag}
                    ></input>
                    <label className='top6'>Access from a new IP range was identified</label>
                </div>
            </div>
        </form>
    </>
}

export default AccountTakerConfig;