import React, { useState, useEffect } from 'react'
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import {Api} from '../../components/Axios'
import { EditPanel } from './sub'
import { useToasts } from '../../components/core';

// import './listing.css'


type ActionType = {
    type?: string;
    id?: string;
}

type SMTP = {
    authentication?: string;
    host?: string;
    password?: string;
    port?: string;
    security?: string;
    user_name?: string;
}

const Smtp = ({query, setQuery}: any) => {
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [smtp, setSmtp] = useState<SMTP>()
    const { addToast } = useToasts()

    const LoadSmtp = () => {
        Api.get('/smtp')
        .then((res: { data: any }) => {
            setSmtp(res.data)
        })
        .catch((error: any) => {
        })
    }

    useEffect(() => {
        LoadSmtp()
    }, [])

    useEffect(() => {
        switch(actionType?.type) {            
            case 'edit':
                setPanelTitle("Update SMTP")
                setOpenPanel(true)
                return

            case 'delete':
                const headers = {'Operation':'DELETE'}
                Api.post('/smtp', "", {headers: headers})
                .then((res: { data: any }) => {
                    LoadSmtp()
                    addToast("SMTP deleted successfully.", {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                })
                .catch((error: any) => {
                })
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        setQuery( )
    }

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterEditOperation={AfterEditOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const AfterEditOperation = (action: string) => {
        LoadSmtp()
        setOpenPanel(false)
    }

    return (
        <>
            <RightPanel />
            <div className="page_title_area">
                <h2>Sending emails (SMTP)</h2>
                <div className="launch_button">
                    <button type="button" className="button_main float_right margin10" onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                    <button type="button" className="button_main float_right margin10" onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                </div>
            </div>
            <div className="box_grid_layout margintop20">
                <label className="font_semibold margin0" >Server Name/IP : </label>
                <label className="margin0" >{smtp?.host}</label>
                
                <label className="font_semibold" >Server Port : </label>
                <label >{smtp?.port}</label>

                <label className="font_semibold" >Connection Security : </label>
                <label >{smtp?.security}</label>

                <label className="font_semibold" >Authentication : </label>
                <label >{smtp?.authentication}</label>

                <label className="font_semibold" >Username : </label>
                <label >{smtp?.user_name}</label>
                <div className="box_grid_footer_line"></div>
                <div className="box_grid_footer">
                    <button type="button" className="button_gray float_left width " onClick={() => setActionType({id: "", type: "delete"})}>Clear</button>
                    <button type="button" className="button_gray float_right " onClick={() => setActionType({id: "", type: "edit"})}>Edit</button>
                </div>
            </div>
        </>
    )
}

export default (Smtp)