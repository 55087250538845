import InfoIcon from '@mui/icons-material/Info'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import 'react-sliding-side-panel/lib/index.css'
import {
    ArrayParam,
    NumberParam,
    StringParam,
    withDefault,
    withQueryParams,
} from 'use-query-params'
import { Api } from '../../components/Axios'
import { useToasts } from '../../components/core'
import './Settings.scss'
import { AMToolTipAd } from '../../components/core/AMTooltipAd/AMTooltipAd'
import { AMModalError } from '../../components/core/AMModalPopup/AMModalError'
import { SettingsErrors } from './SettingsErrors'
import { useLocation } from 'react-router-dom';
import qs from 'qs'

type Search = {
    query?: string;
}

type PostureSettings = {
    data_archive_time: number,
    posture_view_time: number
}

type SensorSettings = {
    third_party_calls_via_am?: boolean;
}

type GeneralSetting = {
    sensors?: SensorSettings,
    posture?: PostureSettings
}

type GeneralSettings = {
    tab_view: number,
    general_settings: GeneralSetting
}

const Groups = ({ query, setQuery }: any) => {

    const [tabValue, setTabValue] = React.useState('2');
    const [loading, setLoading] = useState<Boolean>(false)
    const { register, handleSubmit, errors, setValue, watch } = useForm<GeneralSettings>({ mode: 'onChange' })
    const [sensorSettings, setSensorSettings] = useState<GeneralSettings>({ general_settings: {}, tab_view: 1 });
    const { addToast } = useToasts();
    const location = useLocation();

    const values = watch();
    useEffect(() => {
        Listing()
        const query = new URLSearchParams(location.search);
        if (query && query.get('type') == 'posture') {
            setTabValue('2');
            handleTabs({} as SyntheticEvent, '2')
        }
    }, [])

    useEffect(() => {
        console.log(tabValue)
    }, [tabValue])

    const Listing = () => {
        Api.get('/generalsettings')
            .then((res: { data: any }) => {
                const responseData = res.data as GeneralSettings;
                if (res.data) {
                    setValue('general_settings.posture.posture_view_time', responseData?.general_settings?.posture?.posture_view_time || 60);
                    setValue('general_settings.posture.data_archive_time', responseData?.general_settings?.posture?.data_archive_time);
                    setValue('general_settings.sensor.third_party_calls_via_am', responseData.general_settings.sensors?.third_party_calls_via_am)
                }
                setSensorSettings(res.data);
                // 
            })
            .catch((error: any) => {
            })
    }

    const onSetSensors = (data: GeneralSettings) => {
        setLoading(true)
        let request = {
            general_settings: { sensors: data.general_settings.sensors }
        } as GeneralSettings;
        const headers = { 'Operation': 'PUT' }
        processRequest(request, headers, 1);
    }

    const processRequest = (request: GeneralSettings, headers: any, tabView: number) => {
        const req = tabView == 1 ? Api.post('/generalsettings', request, { headers: headers }) :
            Api.post('/generalsettings/posture', request, { headers: headers })

        req.then((res: { data: any }) => {
            setLoading(false)
            addToast("Settings Updated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to proccess the request, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        if (newValue == '2') {
            setTimeout(() => {
                setValue('general_settings.posture.posture_view_time', sensorSettings?.general_settings?.posture?.posture_view_time || 60);
                setValue('general_settings.posture.data_archive_time', sensorSettings?.general_settings?.posture?.data_archive_time);
            });
        } else {
            setTimeout(() => {
                setValue('general_settings.sensor.third_party_calls_via_am', sensorSettings.general_settings.sensors?.third_party_calls_via_am)
            })
        }

    };

    const handlePostureSettingSubmt = (data: GeneralSettings) => {
        setLoading(true)
        let request = {
            general_settings: {
                posture: {
                    // data_archive_time: parseInt(data?.general_settings?.posture?.data_archive_time?.toString() || '0'),
                    data_archive_time: sensorSettings.general_settings.posture?.data_archive_time,
                    posture_view_time: parseInt(data?.general_settings?.posture?.posture_view_time?.toString() || '0')
                }
            }
        } as GeneralSettings;
        const headers = { 'Operation': 'PUT' }
        processRequest(request, headers, 2)
    }

    useEffect(() => {
        console.log(errors?.general_settings)
        console.log(values)
    }, [errors, values])



    return (
        <>
            <div className="page_title_area">
                <h2>General Settings</h2>
            </div>
            <div className="page_title_area">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabValue}>
                        <Box>
                            <TabList TabIndicatorProps={{ style: { background: 'transparent' } }} onChange={handleTabs} aria-label="lab API tabs example"
                            >
                                {/* <Tab className={tabValue === "1" ? "active_tab" : "tablist"} label="Sensors" value="1" /> */}
                                <Tab className={tabValue === "2" ? "active_tab posture-archieve-settings" : "tablist posture-archieve-settings"} label="Posture Archive Settings" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel className="am_tabpanel" value="1">
                            <form onSubmit={handleSubmit(onSetSensors)}>
                                <input
                                    name="general_settings.sensors.third_party_calls_via_am"
                                    type="checkbox"
                                    className={("margintop20")}
                                    defaultChecked={sensorSettings.general_settings?.sensors?.third_party_calls_via_am}
                                    onChange={(e) => {
                                        const ob = { ...sensorSettings };
                                        if (ob.general_settings.sensors) {
                                            ob.general_settings.sensors.third_party_calls_via_am = !sensorSettings.general_settings?.sensors?.third_party_calls_via_am;
                                            setSensorSettings(ob);
                                        }
                                    }}
                                    ref={register({
                                        required: false,
                                    })}
                                />
                                <label className="no_float">Route all 3rd-party risk feeds traffic through the AuthMind cloud</label>

                                <div className="launch_button no_float">
                                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel className="am_tabpanel" value="2">
                            <form className='posture_setting_form_container' onSubmit={handleSubmit(handlePostureSettingSubmt)}>
                                <div className='posture_flex_container'>
                                    <div className='flex-div-item-'>
                                        <span>
                                            Archive incident and posture data after {sensorSettings.general_settings.posture?.data_archive_time}
                                            {/* <AMToolTipAd
                                                title={'Allowed Archived Data time period is between 15-90 days'}
                                                classes={{
                                                    tooltip: 'posture-setting-tootlip'
                                                }}

                                            ><span> <input type='number'
                                                ref={register({
                                                    required: true,
                                                    max: 90,
                                                    min: 15
                                                })}
                                                onChange={(e) => {
                                                    const ob = { ...sensorSettings };
                                                    if (ob.general_settings?.posture) {
                                                        ob.general_settings.posture.data_archive_time = parseInt(e?.target?.value) || 0;
                                                        setSensorSettings(ob);
                                                    }
                                                }}
                                                name='general_settings.posture.data_archive_time'
                                            // defaultValue={sensorSettings?.posture?.data_archive_time}
                                            />
                                                </span></AMToolTipAd> */}
                                        </span> days of inactivity
                                    </div>
                                </div>
                                <div className='posture_flex_container'>
                                    <div className='flex-div-item-'><span>
                                        Do <strong><i>not</i></strong> show posture data if there is no activity for <span>
                                            <input type='number'
                                                // defaultValue={sensorSettings?.posture?.posture_view_time}
                                                name='general_settings.posture.posture_view_time'
                                                ref={register({
                                                    required: true,
                                                    min: 15,
                                                    max: 60
                                                })}
                                                onChange={(e) => {
                                                    const ob = { ...sensorSettings };
                                                    if (ob.general_settings?.posture) {
                                                        ob.general_settings.posture.posture_view_time = parseInt(e?.target?.value) || 0;
                                                        setSensorSettings(ob);
                                                    }
                                                }}
                                            />
                                        </span>
                                    </span> days
                                    </div>
                                </div>
                                <AMModalError errors={errors?.general_settings?.posture || {}} errorMap={SettingsErrors.posture} />
                                <div className="launch_button no_float">
                                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                                </div>
                            </form>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div >
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Groups)