import React, { useEffect } from 'react';

export const AzureNSGFlowCollector = ({
    register,
    setValue,
    collectorData
}) => {
    useEffect(() => {
        if (collectorData) {
            setValue(
                'storage_account_name',
                collectorData.collector_config?.storage_account_name
            );
            setValue(
                'storage_access_key',
                collectorData.collector_config?.storage_access_key
            );
            setValue(
                'container',
                collectorData.collector_config?.container
            );

            setValue(
                'tenant_id',
                collectorData.collector_config?.tenant_id
            );
            setValue(
                'client_id',
                collectorData.collector_config?.client_id
            );
            setValue(
                'client_secret',
                collectorData.collector_config?.client_secret
            );
        }
    }, [collectorData]);

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Azure Storage Account Configuration (For reading Network
                    Watcher logs)
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Storage Account Name*</label>
                            <input
                                type='text'
                                name='storage_account_name'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Storage Account'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Storage Access Key*</label>
                            <input
                                type='text'
                                name='storage_access_key'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Storage Access'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Container*</label>
                            <input
                                type='text'
                                name='container'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Storage Container Name'}
                            />
                        </div>
                    </div>
                </div>

                <div className='form_sub_section_header'>
                    Azure Service Principal Credentials (For querying metadata
                    of resources)
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Tenant ID*</label>
                            <input
                                type='text'
                                name='tenant_id'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Tenant Id'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Client ID*</label>
                            <input
                                type='text'
                                name='client_id'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Client Id'}
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>Client Secret*</label>
                            <input
                                type='text'
                                name='client_secret'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                placeholder={'Enter Client Secret'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
