import React from 'react';

type hashFeed = {
    websiteName: string;
    isChecked: boolean;
};

type feed = {
    name: string;
    isChecked: boolean;
};

type Props = {
    config: any;
};

const CompromisedPasswordConfigView = ({ config }: Props) => {
    const renderApiFeeds = (feeds: feed[]) => {
        let res = [];
        feeds?.forEach((feed: feed, idx: number) => {
            if (feed.isChecked && feed.name !== 'Md5decrypt.net') {
                res.push(feed.name);
            }
        });
        return res.join(' or ');
    };

    const renderHashFeeds = (hashFeeds: hashFeed[]) => {
        let res = [];
        hashFeeds?.forEach((feed: hashFeed, idx: number) => {
            if (feed.isChecked && feed.websiteName != 'Md5decrypt.net') {
                res.push(feed.websiteName);
            }
        });
        return res.join(' or ');
    };

    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>A password will be considered compromised if:</span>
                <ul className='ml-1'>
                    {config.commonPasswordFlag && (
                        <li>Password is found in commonly used passwords</li>
                    )}
                    {config.feeds && renderApiFeeds(config?.feeds).length > 0 && (
                        <li>
                            Password is found in any of the following feeds:{' '}
                            <strong> {renderApiFeeds(config?.feeds)}</strong>
                        </li>
                    )}

                    {config.hashFeeds && renderHashFeeds(config.hashFeeds).length > 0 && (
                        <li>
                            Hash of the password is found in any of the
                            following feeds:{' '}
                            <strong>{renderHashFeeds(config?.hashFeeds)}</strong>
                        </li>
                    )}
                </ul>
            </li>
        </>
    );
};

export default React.memo(CompromisedPasswordConfigView);
