import { Switch, Route } from 'react-router-dom';
import { useAuthDetails } from './components/Authorization';
import { Layout } from './components/Layout';
import Home from './pages/Home';
import BeforeLogin from './pages/BeforeLogin';
import { GlobalStyles } from '@mui/material';
import './App.css';
import { DashboardStateContextProvider } from './store/DashboardStateContextProvider';
import { PartnerStateContextProvider } from './store/PartnerContextProvider';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { LicenseInfo } from '@mui/x-license';


export const App = ({ config }: any) => {
  LicenseInfo.setLicenseKey('819cea3fd8cf7b883744821e79802bceTz05NDI4MSxFPTE3NTI2NTMwMzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')
  const FrontPage = () => {
    const { authDetails } = useAuthDetails()!;
    if (authDetails.authenticated) {
      return (
        <>
          <PartnerStateContextProvider initialValue={config}>
            <GlobalStyles styles={{ body: { paddingRight: '0px !important', overflow: 'visible !important' } }} />
            <DashboardStateContextProvider>
              <Home />
            </DashboardStateContextProvider>
          </PartnerStateContextProvider>
        </>
      );
    } else {
      return (
        <PartnerStateContextProvider initialValue={config}>
          <BeforeLogin />
        </PartnerStateContextProvider>
      );
    }
  };

  return (
    <ErrorBoundary>
      <PartnerStateContextProvider initialValue={config} value={config}>
        <Layout>
          <Switch>
            <Route exact path='/*' component={FrontPage} />
          </Switch>
        </Layout>
      </PartnerStateContextProvider>
    </ErrorBoundary>
  );
};
