
const _storeName = 'stepper_sensor';
export const useSensor = () => {
    const fetchSensorDataFromStore = () => {
        const _cache = localStorage.getItem(_storeName) || undefined;
        if (_cache) {
            return JSON.parse(_cache);
        }
        return undefined;
    }

   
    const loadSensorDataToStore = (data: any) => {

        if(data&& data.qradar_data_sources && data.qradar_data_sources.length>0){

            data.qradar_data_sources =  data.qradar_data_sources.filter((obj:any) => obj.source !== undefined);
        }
        

        if(data&& data.splunk_data_sources && data.splunk_data_sources.length>0){

            data.splunk_data_sources =  data.splunk_data_sources.filter((obj:any) => obj.source !== undefined);
        }
       
        const _d = localStorage.getItem(_storeName);
        if (_d) {
            const _t = JSON.parse(_d);
            const _data = { ..._t, ...data };
            
            localStorage.setItem(_storeName, JSON.stringify(_data));
        } else {
            localStorage.setItem(_storeName, JSON.stringify(data));
        }
    }

    const clearSensorDataStore = () => {
        localStorage.removeItem(_storeName);
    }

    return {
        fetchSensorDataFromStore, loadSensorDataToStore, clearSensorDataStore
    }
}