import React from 'react';

const EnumADAdminView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An Active Directory will be marked as under Admin
                    Enumeration when:
                </span>
                <ul className='ml-1'>
                    <li>
                        Number of attempts made in an hour is more than{' '}
                        <strong>
                            {config.dir_params.dir_bad_username_count_curhour}
                        </strong>
                    </li>

                    <li>
                        Number of admin accounts attempted in an hour crosses{' '}
                        <strong>
                            {
                                config?.dir_params
                                    ?.dir_bad_username_nadmin_curhour
                            }
                        </strong>
                    </li>
                </ul>
            </li>
        </>
    );
};

export default React.memo(EnumADAdminView);
