import { isValidIp, isValidPort, isValidMACList, isValidPartialMac } from "../../../../utils/util-methods";
import { IPatternFormValue } from "../PatternForm/PatternFormGenerator";

export function checkIsIPV4(entry: any) {
    var blocks = entry.split(".");
    if (blocks.length === 4) {
        return blocks.every(function (block: any) {
            return parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255;
        });
    }
    return false;
}

const validateIPInput = (val: string) => {
    if (val.includes('/')) {
        if (!/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(val)) {
            return `Invalid CIDR range.`
        }
    } else if (val.includes('-')) {
        if (val.split('-').length !== 2) {
            return `Invalid IP range.`
        } else {
            const p1 = val.split('-')[0];
            const p2 = val.split('-')[1];
            if (!p1 || !p2 || !isValidIp(p1)) {
                return `Invalid IP range.`;
            } else if ((p2.indexOf('.') > -1 && !isValidIp(p2))) {
                return `Invalid IP range.`
            } else if ((p2.indexOf('.') == -1 && isNaN(p2)) || (parseInt(p2) < 0 || parseInt(p2) > 255)) {
                return `Invalid IP range.`
            }
        }
    } else if (val.includes('*')) {
        const ip = val.split('.');
        if (ip.length > 4) {
            return `Invalid IP pattern provided.`
        }
        /* const c = ip.map((it: string, index: number) => {
            if (!it) {
                return false;
            } else if (index === 0 && (isNaN(it) || (parseInt(it) < 0 || parseInt(it) > 255))) {
                return false
            } else if ((it != '*' && (isNaN(it) || parseInt(it) < 0 || parseInt(it) > 255)) && index != 0) {
                return false;
            }
            return true;
        }).filter((i: boolean) => i).length; */
        let isAstExist = false;
        const c = ip.map((it: string, index: number) => {
            if (!it) {
                return false;
            } else if (index === 0 && (isNaN(it) || (parseInt(it) < 0 || parseInt(it) > 255))) {
                return false
            } else if (it === '*' && index != 0) {
                isAstExist = true;
                return true;
            } else if (isAstExist) {
                return false;
            } else if ((it != '*' && (isNaN(it) || parseInt(it) < 0 || parseInt(it) > 255)) && index != 0
            ) {
                return false;
            }
            return true;
        }).filter((i: boolean) => i).length;
        if (ip.length != c)
            return `Invalid IP pattern provided.`
        return null;
    } else if (!isValidIp(val)) {
        return `Invalid IP provided`;
    }
    return null;
}

export const validateIPAddress = (rowValue: IPatternFormValue) => {
    const values = rowValue?.value && Array.isArray(rowValue?.value) ? rowValue?.value : rowValue?.value?.split(',');
    if (!['equals', 'not equals'].includes(rowValue.operator)) {
        for (let k = 0; k < values?.length; k++) {
            const val = values[k];
            const result = validateIPInput(val);
            if (result) {
                return result;
            }
        }
    } else if (['equals', 'not equals'].includes(rowValue.operator)) {
        for (let k = 0; k < values?.length; k++) {
            const val = values[k];
            if (['/', '-', '*'].some((i) => val.includes(i))) {
                return `Pattern is not supported for equals/not equals operator.`
            } else if (!isValidIp(val)) {
                return `Please enter valid IP address.`
            }
        }
    }
    return null;
}


export const validateIpPort = (rowValue: IPatternFormValue) => {
    const values = rowValue?.value && Array.isArray(rowValue?.value) ? rowValue?.value : rowValue?.value?.split(',');
    for (let i = 0; i < values?.length; i++) {
        const val = values[i].toString().trim();
        if (val.includes('-')) {
            const isValid = isValidPort(val.split('-')[0]) && isValidPort(val.split('-')[1]);
            if (isValid) continue;
            else return `Please enter valid port range.`
        } else {
            const v = values[i].toString().trim();

            if (v?.length > 0 && isValidPort(v)) {
                continue;
            } else {
                return `Please enter valid port number.`
            }
        }
    }
    return null;
}

export const validateEntityName = (rowValue: IPatternFormValue) => {
    if (rowValue.value.indexOf('/') > -1) {
        return `Please enter valid name.`
    }
    return null;
}

export const validateMACAddresses = (rowValue: IPatternFormValue): string | null => {
    const macList = rowValue.value;
    if (['matches', 'not matches'].includes(rowValue.operator)) {
        if (!isValidPartialMac(macList)) {
            return 'Invalid MAC address.';
        }
    } else if (!isValidMACList(macList)) {
        return 'Invalid MAC address.';
    }
    return null;
}