import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Popup from 'reactjs-popup';
import { AssetsObj, AssetMatchedRuleObj, IssueParams } from '../../../../types/response-types';
import { ArrayParam, NumberParam, QueryParams, StringParam, withDefault, withQueryParams } from 'use-query-params';
import * as qs from "qs";
import { Api } from '../../../../components/Axios';
import { getRuleName } from '../../../../utils/util-methods';

type Props={
    data: AssetMatchedRuleObj | undefined, 
    assetId: string | undefined,
    ruleId: string | undefined,
}

const RuleMatchId=({query, setQuery, data, assetId, ruleId, handleRuleMatchClose}: any)=>{
    let history = useHistory();
    let location = useLocation();
    const [ruleMatchId, setRuleMatchId] = useState<String[]|undefined>(data?.identities);
    console.log(data.identities)

    const removeQuery = () => {        
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.has('page2')) {
            queryParams.delete('page2')
        }
        if(queryParams.has('order2_by')) {
            queryParams.delete('order2_by');
        }

        if(queryParams.has('sort2_by')){
            queryParams.delete('sort2_by');
        }
        history.replace({
            search: queryParams.toString()
        })
      };

    const handleClose=()=>{
        // history.push("/assets/rulematch");
        removeQuery();
        handleRuleMatchClose();
    }    

    const {
        page2: page_number,
    } = query;

    let param: IssueParams = {
        page2: page_number
    };

    useEffect(()=>{
        param.page2=1;
        setQuery(param);  
    },[])


    const handlePaginate=(selectedPage: any)=>{
        param.page2 = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(param)

        Api.get("/assets?_id="+assetId +'&rule_name='+ruleId+'&rpp=10&page='+param.page2 )
        .then(res=>{
            if(res.status===200){
                setRuleMatchId(res.data.identities);
            }
        })
        .catch(err=>console.log)
    }

    return(
        <>
            <Popup overlayStyle={{zIndex:15001, background: 'rgba(227, 242, 253, .6)'}} 
                open={true} 
                closeOnDocumentClick={false}
                modal={true}
                closeOnEscape={false}
            >
                <div className={"modal"}>
                    <div className={"close"} onClick={handleClose}></div>
                    <div className={"header"} style={{height: 'auto', paddingBottom: '0'}}>
                        <span className="font16 " style={{wordBreak :'break-word'}}>{assetId}' identities that have '{getRuleName(ruleId)}' incidents: </span>
                    </div>
                    <div className={"content"}>
                        <div className="margintop10">
                            <table id="table" className="tablesorter">
                                <tbody>
                                    {ruleMatchId?.map((item: any) => {
                                    return  <tr>
                                                <td>{item.identity_name}</td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>     
                            <div className="clrBoth"></div>    
                        </div>

                        <ReactPaginate
                            previousLabel={"← Previous"}
                            nextLabel={"Next →"}
                            pageCount={data && data.identities.length>0 ? (Math.ceil(data.total / data.rpp)) : 1}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            onPageChange={handlePaginate}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(withQueryParams({
    page2: StringParam,
    sort2_by: StringParam,
    order2_by: StringParam
}, RuleMatchId));