export const CategoriesFields = ['src_category', 'dst_category', 'dir_category'];

export const MockIssueCategories = {
	"520": "AI Applications",
	"540": "Business Apps & Services",
	"560": "Cloud Storage and File Sharing",
	"580": "E-Commerce",
	"600": "General Business",
	"620": "Cloud IAM and IGA",
	"640": "IT Services and Hosting",
	"660": "Cloud Identity Providers",
	"680": "Cloud Identity Security",
	"700": "Malicious",
	"720": "Messaging, and Online Meetings",
	"740": "Cloud Multi-factor Authentication",
	"760": "Other",
	"780": "Cloud Identity Systems (Other)",
	"800": "Cloud Password Management",
	"820": "Personal Interests",
	"840": "Cloud Privileged Access Management",
	"860": "Cloud Secrets Management",
	"880": "Sensitive",
	"900": "Social Media",
	"920": "Streaming Media and Advertising"
  }
