import React from 'react';

export const SumoLogicSiemConfiguration = ({ register }: any) => {
    return (
        <div className='siem-vendor-configuration-container'>
            <div className={`form_sub_section`}>
                <div className='form_sub_section_header'>
                    Collect Sumologic Logs
                </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS S3 Bucket*</label>
                            <input
                                type='text'
                                placeholder='AWS S3 Bucket'
                                name='sumologic_s3_bucket'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Region*</label>
                            <input
                                type='text'
                                placeholder='AWS Region'
                                name='sumologic_aws_region'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Access Key ID*</label>
                            <input
                                type='text'
                                placeholder='AWS Access Key ID'
                                name='sumologic_aws_access_key'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>

                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <label>AWS Secret Access Key*</label>
                            <input
                                type='text'
                                placeholder='AWS Secret Access Key'
                                name='sumologic_aws_access_secret'
                                ref={register({
                                    required: true,
                                    shouldUnregister: true
                                })}
                                className='flex-basis-100'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
