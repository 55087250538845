import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import { useAuthDetails } from '../../components/Authorization'
import { useHistory, Link } from "react-router-dom"
import SlidingPanel, { PanelType } from 'react-sliding-side-panel'
import 'react-sliding-side-panel/lib/index.css'
import {Api} from '../../components/Axios'
import Moment from 'moment'
import { AddPanel, EditPanel, ResetPassword, GroupPanel } from './sub'
import ReactPaginate from 'react-paginate'
import { useToasts } from '../../components/core';
import {ActionMenu} from '../../theme/ActionMenu'
import Popup from 'reactjs-popup';
import InfoIcon from '@mui/icons-material/Info';
import { UserRoleDisplay } from './sub/UserRoleDisplay';

import './listing.css'
import ModalPopup from '../../components/core/AMModalPopup/ModalPopup'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    name: string;
    email: string;
    role: string;
    last_login: string;
    ip: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

type ActionType = {
    type?: string;
    id?: string;
}

export interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
    type?: string;
}

type SSOList = {
    enable_sso?: boolean;
    name?: string;
    portal_fqdn?: [];
    entity_id?: string;
    metadata_url?: string;
    metadata_xml?: string;
    sso_login_url?: string;
    certificate?: string;
    private_key?: string;
    company_id?: string;
    root_sso?: boolean
}

const Users = ({query, setQuery}: any) => {
    const {authDetails} = useAuthDetails()!;
    const { register, handleSubmit } = useForm<Search>()
    const {q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const [actionType, setActionType] = useState<ActionType>()
    const [openPanel, setOpenPanel] = React.useState<boolean>(false)
    const [panelType, setPanelType] = React.useState<PanelType>('right')
    const [panelSize, setPanelSize] = React.useState<number>(30)
    const [panelTitle, setPanelTitle] = useState<string>("")
    const [result, setResult] = useState<Result>()
    const { addToast } = useToasts()
    const [openModal, setOpenModal] = useState(false);
    const [ssoData, setSsoData] = useState(null);
    const closeModal = () => setOpenModal(false);

    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page || 100,
        order_by: order,
        sort_by: sort
    }

    const [nameSortDir, setNameSortDir] = useState<string | undefined>("tablesort_down");
    const [emailSortDir, setEmailSortDir] = useState<string | undefined>("tablesort_down");
    const [roleSortDir, setRoleSortDir] = useState<string | undefined>("tablesort_down");
    const [lastLoginSortDir, setLastLoginSortDir] = useState<string | undefined>("tablesort_down");
    const [lastIpSortDir, setLastIpSortDir] = useState<string | undefined>("tablesort_down");

    const highlightSortDir=()=>{
        if(query.sort_by==="name" && query.order_by==="asc"){
            setNameSortDir("tablesort_up_selected")
        } else if(query.sort_by==="name" && query.order_by==="desc"){
            setNameSortDir("tablesort_down_selected")
        } else if(query.sort_by==="email" && query.order_by==="asc"){
            setEmailSortDir("tablesort_up_selected")
        } else if(query.sort_by==="email" && query.order_by==="desc"){
            setEmailSortDir("tablesort_down_selected")
        } else if(query.sort_by==="role" && query.order_by==="asc"){
            setRoleSortDir("tablesort_up_selected")
        } else if(query.sort_by==="role" && query.order_by==="desc"){
            setRoleSortDir("tablesort_down_selected")
        } else if(query.sort_by==="last_login" && query.order_by==="asc"){
            setLastLoginSortDir("tablesort_up_selected")
        } else if(query.sort_by==="last_login" && query.order_by==="desc"){
            setLastLoginSortDir("tablesort_down_selected")
        } else if(query.sort_by==="ip" && query.order_by==="asc"){
            setLastIpSortDir("tablesort_up_selected")
        } else if(query.sort_by==="ip" && query.order_by==="desc"){
            setLastIpSortDir("tablesort_down_selected")
        }
    }

    const deleteConfirm = () => {
        const headers = {'Operation':'DELETE'}
        Api.post('/user/'+actionType?.id, "", {headers: headers})
        .then((res: { data: any }) => {
            addToast("User deleted successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
            p.page = 1
            setQuery(p)
            Listing()
            closeConfirm()
        })
        .catch((error: any) => {
            if(error.response.status === 401) {
                window.location.href = '/login'
            }if(error.response.status === 403) {
                addToast("You are not allowed to perform this action.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    } 

    useEffect(() => {
        highlightSortDir();
    }, [query])

    useEffect(() => {
        Listing()
    }, [query])

    useEffect(() => {
        switch(actionType?.type) {
            case 'add':
                setPanelTitle("Add User")
                setOpenPanel(true)
                return
            
            case 'edit':
                setPanelTitle("Edit User")
                setOpenPanel(true)
                return

            case 'groups':
                setPanelTitle("Add Groups")
                setOpenPanel(true)
                return

            case 'reset':
                setPanelTitle("Reset Password")
                setOpenPanel(true)
                return

            case 'delete':
                setOpenConfirm(true)
                return

            default:
                return
        }

    }, [actionType])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }
    

    const RightPanel = () => {
        return (
            <SlidingPanel
                type={panelType}
                isOpen={openPanel}
                size={panelSize}
                panelClassName="sidepane fix_width"
            >
                <div className="pane_container">
                    <div className="pane_heading">{panelTitle}</div>
                    <div className="pane_close" onClick={() => setOpenPanel(false)}></div>
                    <div className="hr_ruler"></div>
                    <div className="panel_body">
                        {actionType?.type === "add" &&
                            <AddPanel AfterUserOperation={AfterUserOperation} />
                        }

                        {actionType?.type === "edit" &&
                            <EditPanel id={actionType.id} AfterUserOperation={AfterUserOperation} />
                        }

                        {/* {actionType?.type === "groups" &&
                            <GroupPanel AfterUserOperation={AfterUserOperation} />
                        } */}

                        {actionType?.type === "reset" &&
                            <ResetPassword id={actionType.id} AfterUserOperation={AfterUserOperation} />
                        }
                    </div>
                </div>
            </SlidingPanel>
        )
    }

    const renderPopup = () => {
        return (
            <ModalPopup
                onCloseHandler={() => setOpenPanel(false)}
                title={panelTitle}
                isOpen={openPanel}
            >
                {actionType?.type === "add" &&
                    <AddPanel AfterUserOperation={AfterUserOperation} hidePasswordFields={ssoData?.enable_sso} />
                }

                {actionType?.type === "edit" &&
                    <EditPanel id={actionType.id} AfterUserOperation={AfterUserOperation} />
                }

                {actionType?.type === "reset" &&
                    <ResetPassword id={actionType.id} AfterUserOperation={AfterUserOperation} />
                }

            </ModalPopup>
        )
    }

    const Listing = () => {
        Api.get('/users', {
            params: p
        })
        .then((res: { data: any }) => {
            setResult(res.data)
        })
        .catch((error: any) => {
            if(error.response.status === 401) {
                window.location.href = '/login'
            } else {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    const Action = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setActionType({
            id: e.currentTarget.id,
            type: e.currentTarget.dataset.action
        })
        e.preventDefault()
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const AfterUserOperation = (action: string) => {
        setOpenPanel(false)
        if(action === 'add') {
            p.page = 1
            p.q = ""
            setQuery(p)
            Listing()
        } else {
            Listing()
        }
        
    }

    const FormatDate = (dte:any) => {
        Moment.locale('en')
        return (
            <>
                {Moment(dte).format('MMM DD, YYYY h:mm A')}
            </>
        )
    }

    const LoadSorting = (field: string) => {
        if(p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if(p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    const [openConfirm, setOpenConfirm] = useState(false);
    const closeConfirm = () => setOpenConfirm(false);

    // const Sorting = (name: string, field: string) => {
    //     const fl = name.toLowerCase()
    //     return (
    //         <Link to="#" data-field={field} onClick={LoadSorting}>
    //             <div className={SortBy(field)}>{name}</div>
    //         </Link>
    //     )
    // }

    const handleClick = () => {
        setOpenModal(true)
    }

    const isAmopsUser = (userEmail: string): boolean => {
        if (userEmail === "" || userEmail === undefined || userEmail === null) return false;
        return userEmail.toString().startsWith("amops@") && userEmail.toString().endsWith(".authmind");
    }

    const fetchSso = () => {
        Api.get('/sso')
            .then((res: { data: SSOList }) => {
                setSsoData(res.data);
          })
          .catch((error: any) => {
            console.log(error);
          })
    }

    useEffect(() => {
        fetchSso();
    }, []);

    const isResetPasswordVisible = (u) => {
        if (u.role.toLowerCase() === 'root') {
            return ((authDetails.sessionindex === "" || authDetails.sessionindex === undefined) && authDetails.role.toLowerCase() === 'root' && !ssoData?.root_sso);
        } else {
            return ((authDetails.sessionindex === "" || authDetails.sessionindex === undefined) && !ssoData?.enable_sso);
        }
    }

    const isActionsMenuVisible = (u) => {
        if (u.role.toLowerCase() === 'root') {
            return !ssoData?.root_sso && authDetails.role.toLowerCase() === 'root';
        } else {
            return (u.role.toLowerCase() != 'root' || authDetails.role.toLowerCase() == 'root') && !isAmopsUser(u.email);
        }
    }

    return (
        <>
            {renderPopup()}
            <div className="page_title_area">                
                <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='font18'>Registered Users</div>
                        <div className="search_container issue_page scrollbar-container marginleft20 admin-page-search-container">
                            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                onClick={handleSubmit(onSubmit)}
                            >&nbsp;</div>&nbsp;
                            <input
                                className="search_input flex_none admin-page"
                                defaultValue={p.q}
                                placeholder="Search by Name, Email"
                                ref={register}
                                name='query'
                            />
                        </div>                        
                        <div className="launch_button admin_page_launch_btn">
                            <button type="button" className="button_main" onClick={() => setActionType({id: "", type: "add"})}>Add</button>
                        </div>                        
                    </div>
                </form>
            </div>
 
            <table id="table" className="tablesorter sticky_table_top admin-page">
                <thead>
                    <tr>
                        <th className={nameSortDir}
                            onClick={() => {
                            LoadSorting("name");
                            setNameSortDir(SortBy("name"));
                            setEmailSortDir("tablesort_down");
                            setRoleSortDir("tablesort_down");
                            setLastIpSortDir("tablesort_down");
                            setLastLoginSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Name</span></th>
                        <th className={emailSortDir}
                            onClick={() => {
                            LoadSorting("email");
                            setEmailSortDir(SortBy("email"));
                            setRoleSortDir("tablesort_down");
                            setNameSortDir("tablesort_down");
                            setLastIpSortDir("tablesort_down");
                            setLastLoginSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Email</span></th>
                        <th className={roleSortDir}
                            onClick={() => {
                            LoadSorting("role");
                            setRoleSortDir(SortBy("role"));
                            setEmailSortDir("tablesort_down");
                            setNameSortDir("tablesort_down");
                            setLastIpSortDir("tablesort_down");
                            setLastLoginSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Role<span className='span-with-info-icon'><InfoIcon style={{ fontSize: 14, color: '#FFF', transform: 'skewX(-10deg)' }} onClick={handleClick} /></span></span></th>
                        <th className={lastLoginSortDir}
                            onClick={() => {
                            LoadSorting("last_login");
                            setLastLoginSortDir(SortBy("last_login"));
                            setNameSortDir("tablesort_down");
                            setEmailSortDir("tablesort_down");
                            setRoleSortDir("tablesort_down");
                            setLastIpSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Last Login</span></th>
                        <th className={lastIpSortDir}
                            onClick={() => {
                            LoadSorting("ip");
                            setLastIpSortDir(SortBy("ip"));
                            setNameSortDir("tablesort_down");
                            setEmailSortDir("tablesort_down");
                            setRoleSortDir("tablesort_down");
                            setLastLoginSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Last IP</span></th>
                        <th><div className="">Action</div></th>
                    </tr>
                </thead>
                <tbody>
                    {ssoData !== null && result && result.result.length > 0 && result.result.map((u:any) => (
                        <tr key={u._id}>
                            <td className="align_left">{u.name}</td>
                            <td className="align_left">{u.email}</td>
                            <td className="align_left">{u.role ? u.role : '-'}</td>
                            <td className="align_left">{u.last_login ? FormatDate(u.last_login) : '-'}</td>
                            <td className="align_left">{u.ip ? u.ip : '-'}</td>
                            { isActionsMenuVisible(u) ?
                                <td>
                                <ActionMenu>
                                    <ul>
                                    {u.role !== "Root" ?
                                        <li>
                                            <Link to="#" onClick={Action} data-action="edit" id={u._id}>Edit</Link>
                                        </li>
                                    : null
                                    }
                                    {isResetPasswordVisible(u) ? (
                                        <li>
                                            <Link to="#" onClick={Action} data-action="reset" id={u._id}>Reset Password</Link>
                                        </li>) : null
                                    }
                                        {/* <li>
                                            <Link to="#" onClick={Action} data-action="groups" id={u._id}>Add Groups</Link>
                                        </li> */}
                                    {u.role !== "Root" ?
                                        <li>
                                            <Link to="#" onClick={Action} data-action="delete" id={u._id}>Delete</Link>
                                        </li>
                                    : null
                                    }
                                    </ul>
                                </ActionMenu>
                            </td> : <td />
                            }
                            
                        </tr>
                    ))}

                    {result && result.result.length == 0 &&
                        <tr>
                        <td colSpan={6}>No record found.</td>
                        </tr>
                    }

                    {!result &&
                        <tr>
                        <td colSpan={6}>Processing.</td>
                        </tr>
                    }
                </tbody>
            </table>

            {/* Delete confirmation Popup */}
            <Popup 
                open={openConfirm} 
                closeOnDocumentClick 
                closeOnEscape={false} 
                onClose={closeConfirm}
                modal
            >
                <div className="modal">
                    <div className="close" onClick={closeConfirm}></div>                              
                    <div className="header">Delete User</div>
                    <div className="content">
                        <div className="font16 margintop20 marginbottom20">Are you sure you want to Delete this User?</div>
                    </div>
                    <div className="popup_footer"> 
                        <button style={{display:'inline'}} type="button" className="button_gray width25per margintop10" onClick={closeConfirm}>No</button>
                        <button style={{float:'right'}} type="button" className="button_main width25per" onClick={deleteConfirm}>Yes</button>
                    </div>
                </div>
            </Popup>


            {result &&
                <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={(Math.ceil(result.total / result.rpp))}
                    onPageChange={Paginate}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            }

            {openModal && <UserRoleDisplay closeConfirm={closeModal} />}
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Users)
