import moment from 'moment';
import { PERIOD_OPTIONS, TIME_RANGE_OPERATORS } from './Constants';

const makeResolver = () => {
    let _resolve;
    const promise = new Promise((resolve) => (_resolve = resolve));
    return [promise, _resolve];
};

export const dispatchWithResolve = async (dispatch, type, value) => {
    const [promise, resolve] = makeResolver();
    dispatch({ type, value, resolve });
    return await promise;
};

export const epochToDate = function (EPOC) {
    try {
        if (EPOC) {
            const date = new Date(EPOC * 1000); //received timestamp in seconds, convert it to miliseconds.
            if (moment(date).isValid()) {
                return date;
            } else {
                return '';
            }
        }
        return '';
    } catch (error) {
        console.log(error);
        return '';
    }
};

export const dateToEpoch = function (dateString) {
    try {
        const dateVal = new Date(new Date(dateString).setSeconds(0, 0));
        // console.log(`resetting datetime seconds to 0....${dateVal}`);
        if (moment(dateVal.toUTCString()).isValid()) {
            const epochVal = new Date(dateVal.toUTCString()).getTime();
            return epochVal / 1000; // send timestamp in seconds.
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const validate = (data) => { 
  let result = true;
  if (data['selectedPeriod'] === PERIOD_OPTIONS.PRESETS.id) {
      result = data['selectedPreset'] !== '' ? true : false;
  }  else {
      if (data['selectedTimeRange'] === TIME_RANGE_OPERATORS.BETWEEN.id) {
          result = data['selectedStartDate'] !== '' && data['selectedEndDate'] !== '' ? true : false;
      } else if (data['selectedTimeRange'] === TIME_RANGE_OPERATORS.EQUALS.id) {
          result = data['selectedStartDate'] !== '' ? true : false;
      } else if (data['selectedTimeRange'] === TIME_RANGE_OPERATORS.AFTER.id) {
          result = data['selectedStartDate'] !== '' ? true : false;
      }
  }
  return result;
}
