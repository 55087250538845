import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    withQueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    withDefault,
} from 'use-query-params'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import { getRisk } from './../../utils/risk-level';
import { Api } from '../../components/Axios'
import { ReportByRuleType, ReportByStatus, ReportByAssignee } from './sub'
import './index.css'
import { Link } from 'react-router-dom'
import { TicketWidgetResponse } from '../../types/response-types'
import { getIssueNameById } from '../playbooks/helpers/playbook-helper'

type Search = {
    query?: string;
}

type Listing = {
    _id: string;
    rule: string;
    policy_applied: string;
    src_name: string;
    dest_name: string;
    created_date: string;
    modified_date: string
    severity: number;
    assignee_name: string;
    status: string;
    ticket_status: string;
    matching_rule_name: string;
    playbook_name: string;
    risk: string;
}

type Result = {
    total: number;
    result: Listing[];
    rpp: number;
    sort_by: string;
    order_by: string;
}

interface Params {
    rpp?: number;
    sort_by?: string;
    order_by?: string;
    q?: string;
    page?: number;
}

const Index = ({ query, setQuery }: any) => {
    const { q: q, page: page_number, rpp: record_per_page, order_by: order, sort_by: sort } = query
    const { register, handleSubmit } = useForm<Search>()
    const [result, setResult] = useState<Result>()

    let p: Params = {
        q: q,
        page: page_number,
        rpp: record_per_page,
        order_by: order,
        sort_by: sort
    }

    const [ticketIdSortDir, setTicketIdSortDir] = useState<string | undefined>("tablesort_down");
    const [lastUpdateSortDir, setLastUpdateSortDir] = useState<string | undefined>("tablesort_down");
    const [createdDateSortDir, setCreatedDateSortDir] = useState<string | undefined>("tablesort_down");
    const [statusSortDir, setStatusSortDir] = useState<string | undefined>("tablesort_down");
    const [matchingRuleSortDir, setMatchingRuleSortDir] = useState<string | undefined>("tablesort_down");
    const [playbookNameSortDir, setPlaybookNameSortDir] = useState<string | undefined>("tablesort_down");
    const [sourceNameSortDir, setSourceNameSortDir] = useState<string | undefined>("tablesort_down");
    const [destinationNameSortDir, setDestinationNameSortDir] = useState<string | undefined>("tablesort_down");
    const [riskSortDir, setRiskSortDir] = useState<string | undefined>("tablesort_down");
    const [assigneeSortDir, setAssigneeSortDir] = useState<string | undefined>("tablesort_down");
    const [summaryFlag, setSummaryFlag] = useState(false);
    const [widgetResponse, setWidgetResponse] = useState<TicketWidgetResponse | undefined>();

    moment.updateLocale('en', { relativeTime: { d: '1 day' } })

    const highlightSortDir = () => {
        if (query.sort_by === "_id" && query.order_by === "asc") {
            setTicketIdSortDir("tablesort_up_selected")
        } else if (query.sort_by === "_id" && query.order_by === "desc") {
            setTicketIdSortDir("tablesort_down_selected")
        } else if (query.sort_by === "modified_date" && query.order_by === "asc") {
            setLastUpdateSortDir("tablesort_up_selected")
        } else if (query.sort_by === "modified_date" && query.order_by === "desc") {
            setLastUpdateSortDir("tablesort_down_selected")
        } else if (query.sort_by === "created_date" && query.order_by === "asc") {
            setCreatedDateSortDir("tablesort_up_selected")
        } else if (query.sort_by === "created_date" && query.order_by === "desc") {
            setCreatedDateSortDir("tablesort_down_selected")
        } else if (query.sort_by === "ticket_status" && query.order_by === "asc") {
            setStatusSortDir("tablesort_up_selected")
        } else if (query.sort_by === "ticket_status" && query.order_by === "desc") {
            setStatusSortDir("tablesort_down_selected")
        } else if (query.sort_by === "matching_rule_name" && query.order_by === "asc") {
            setMatchingRuleSortDir("tablesort_up_selected")
        } else if (query.sort_by === "matching_rule_name" && query.order_by === "desc") {
            setMatchingRuleSortDir("tablesort_down_selected")
        } else if (query.sort_by === "playbook_name" && query.order_by === "asc") {
            setPlaybookNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "playbook_name" && query.order_by === "desc") {
            setPlaybookNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "src_name" && query.order_by === "asc") {
            setSourceNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "src_name" && query.order_by === "desc") {
            setSourceNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "dest_name" && query.order_by === "asc") {
            setDestinationNameSortDir("tablesort_up_selected")
        } else if (query.sort_by === "dest_name" && query.order_by === "desc") {
            setDestinationNameSortDir("tablesort_down_selected")
        } else if (query.sort_by === "risk" && query.order_by === "asc") {
            setRiskSortDir("tablesort_up_selected")
        } else if (query.sort_by === "risk" && query.order_by === "desc") {
            setRiskSortDir("tablesort_down_selected")
        } else if (query.sort_by === "assignee_name" && query.order_by === "asc") {
            setAssigneeSortDir("tablesort_up_selected")
        } else if (query.sort_by === "assignee_name" && query.order_by === "desc") {
            setAssigneeSortDir("tablesort_down_selected")
        }
    }

    useEffect(() => {
        highlightSortDir();
    }, [query])

    const onSubmit = (data: any) => {
        p.q = data.query;
        p.page = 1
        setQuery(p)
    }

    const Paginate = (selectedPage: any) => {
        p.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
        setQuery(p)
    }

    const Listing = () => {
        Api.get('/tickets', {
            params: p
        })
            .then((res: { data: any }) => {
                setResult(res.data)
            })
            .catch((error: any) => {
            })
    }

    const FormatDate = (dte: any) => {
        moment.locale('en')
        return (
            <>
                {moment(dte).format('MMM DD YYYY, h:mm A')}
            </>
        )
    }

    const Severity = (severity: number) => {
        if (severity === 4) {
            return "severity_pink"
        } else if (severity === 3) {
            return "severity_orange"
        } else if (severity === 2) {
            return "severity_yellow"
        }
    }

    useEffect(() => {
        Listing();
    }, [query]);

    useEffect(() => {
        if (!summaryFlag) {
            fetchTicketSummaryData();
        }
    }, [summaryFlag])

    const LoadSorting = (field: string) => {
        if (p.sort_by === field) {
            p.order_by = p.order_by === 'asc' ? 'desc' : 'asc'
            setQuery(p)
        } else {
            p.sort_by = field
            p.order_by = 'desc'
            setQuery(p)
        }
    };

    const SortBy = (name: string) => {
        if (p.sort_by === name) {
            return p.order_by === 'desc' ? 'tablesort_down' : 'tablesort_up'
        }
    }

    const handleSummary = () => {
        setSummaryFlag(flag => !flag);
    }

    const fetchTicketSummaryData = () => {
        Api.get('/ticket/widget')
            .then((res) => {
                if (res.status === 200) {
                    setWidgetResponse(res.data)
                }
            })
            .catch(err => console.log);
    }

    return (
        <>
            <div className="page_title_area">
                <div className="page_title fl">Summary of Tickets</div>
                <div className="launch_button margintop20">
                    {summaryFlag ? <h4 style={{ cursor: 'pointer' }} onClick={handleSummary}>SHOW SUMMARY &#9660;</h4>
                        : <h4 style={{ cursor: 'pointer' }} onClick={handleSummary}>HIDE SUMMARY &#9650;</h4>}
                </div>
            </div>
            {!summaryFlag ?
                <div className="summary_box">
                    <ReportByRuleType ticketRuleType={widgetResponse?.by_rule_type} />

                    <ReportByStatus ticketStatus={widgetResponse?.past_10_days} />

                    <ReportByAssignee assigneeTicket={widgetResponse?.by_assignee} />
                </div>
                : null
            }
            <div className="hr_ruler margintop20"></div>

            <div className="page_title_area">
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '1%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='font18'>TICKETS</div>
                        <div className="search_container issue_page scrollbar-container marginleft20">
                            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
                                onClick={handleSubmit(onSubmit)}
                            >&nbsp;</div>&nbsp;
                            <input
                                className="search_input flex_none admin-page"
                                defaultValue={p.q}
                                placeholder="Search"
                                ref={register}
                                name='query'
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="clrBoth margintop20"></div>

            <table id="table" className="ticket_tb">
                <thead>
                    <tr>
                        <th className={ticketIdSortDir} style={{ width: "7%" }}
                            onClick={() => {
                                LoadSorting("_id");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}>Ticket #</th>

                        <th className={lastUpdateSortDir} style={{ width: "14%" }}
                            onClick={() => {
                                LoadSorting("modified_date");
                                setLastUpdateSortDir(SortBy("modified_date"));
                                setTicketIdSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Last Update</span></th>

                        <th className={createdDateSortDir} style={{ width: "7%" }}
                            onClick={() => {
                                LoadSorting("created_date");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Days Open</span></th>

                        <th className={statusSortDir} style={{ width: "5%" }}
                            onClick={() => {
                                LoadSorting("ticket_status");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Status</span></th>

                        <th className={matchingRuleSortDir} style={{ width: "15%" }}
                            onClick={() => {
                                LoadSorting("matching_rule_name");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Matching Issue</span></th>

                        <th className={playbookNameSortDir} style={{ width: "17%" }}
                            onClick={() => {
                                LoadSorting("playbook_name");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Playbook Name</span></th>

                        <th className={sourceNameSortDir} style={{ width: "11%" }}
                            onClick={() => {
                                LoadSorting("src_name");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Identity</span></th>

                        <th className={destinationNameSortDir} style={{ width: "9%" }}
                            onClick={() => {
                                LoadSorting("dest_name");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Asset</span></th>

                        <th className={riskSortDir} style={{ width: "5%" }}
                            onClick={() => {
                                LoadSorting("risk");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setAssigneeSortDir("tablesort_down");
                            }}>Risk</th>


                        <th className={assigneeSortDir} style={{ width: "8%" }}
                            onClick={() => {
                                LoadSorting("assignee_name");
                                setTicketIdSortDir("tablesort_down");
                                setLastUpdateSortDir("tablesort_down");
                                setCreatedDateSortDir("tablesort_down");
                                setStatusSortDir("tablesort_down");
                                setMatchingRuleSortDir("tablesort_down");
                                setPlaybookNameSortDir("tablesort_down");
                                setSourceNameSortDir("tablesort_down");
                                setDestinationNameSortDir("tablesort_down");
                                setRiskSortDir("tablesort_down");
                            }}><span className='align_left float_left'>Assignee</span></th>
                    </tr>
                </thead>
                <tbody>
                    {result && result.result.length > 0 && result.result.map((i: any) => (
                        <tr key={i._id}>
                            <td><Link className="shadowbox" to={`/tickets/${i._id}`}>{i._id}</Link></td>
                            <td>{FormatDate(i.modified_date)}</td>
                            <td>{moment(i.created_date).startOf('days').fromNow(true)}</td>
                            {/* <td>{moment("DD/MM/YYYY").diff(moment(i.created_date,"DD/MM/YYYY"))}</td> */}
                            <td>{i.ticket_status}</td>
                            <td>{getIssueNameById(i.matching_rule_name.replace('Suspected Attack on Expired Account', 'Suspected Attack on Expired Account/Password'))}</td>
                            <td><div className="copy_field">
                                <span className="ticket_ellipsis" title={i.playbook_name.toString()}>{i.playbook_name}</span>
                                <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(i.playbook_name)}></div>
                            </div>
                            </td>
                            <td>
                                <div className="copy_field">
                                    <span className="ticket_ellipsis" title={i.src_name.toString()}>{i.src_name}</span>
                                    <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(i.src_name)}></div>
                                </div>
                            </td>
                            <td>
                                <div className="copy_field">
                                    <span className="ticket_ellipsis" title={i.dest_name.toString()}>{i.dest_name}</span>
                                    <div className="copy_icon" title="Copy this text to Clipboard" onClick={() => navigator.clipboard.writeText(i.dest_name)}></div>
                                </div>
                            </td>
                            <td><span className={getRisk(i.risk)?.toLowerCase()}>&nbsp;</span> </td>
                            <td>{i.assignee_name ? i.assignee_name : "N/A"}</td>
                        </tr>
                    ))}

                    {!result &&
                        <tr>
                            <td colSpan={10}>Processing</td>
                        </tr>
                    }

                    {result && result.result.length == 0 &&
                        <tr>
                            <td colSpan={10}>No tickets found.</td>
                        </tr>
                    }
                </tbody>
            </table>
            {result && result.result.length > 0 &&
                <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={(Math.ceil(result.total / result.rpp))}
                    onPageChange={Paginate}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            }
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Index)