import React, { useEffect, useState } from 'react';
import CheckboxAutocomplete from '../../../../components/core/AMAutoComplete/AMAutoComplete';

const ShadowIdentitySystemConfig = ({ parentState, isSavedOnce, getState, defaultState, formType }: any) => {
    const categories: any = {
        0: "Local Identity Systems",
        560: "Cloud IAM and IGA",
        580: "Cloud Identity Providers",
        640: "Cloud Multi-factor Authentication",
        660: "Cloud Password Management",
        680: "Cloud Privileged Access Management",
        700: "Cloud Secrets Management",
    };


    const [state, setState] = useState<{ IdentitySystemCategories: [] }>(defaultState || { IdentitySystemCategories: [] });
    const [value, setValue] = useState<string[]>(Object.values(categories));

    useEffect(() => {
        if (state?.IdentitySystemCategories?.length > 0 && parentState?.IdentitySystemCategories?.length > 0) {
            const isSame = arraysAreEqual(state?.IdentitySystemCategories, parentState?.IdentitySystemCategories);
            if (!isSame) {
                setState(parentState)
            }
        } else if (state?.IdentitySystemCategories?.length == 0) {
            setState(parentState);
        }

        const valArr: any = []
        if (parentState && parentState?.['IdentitySystemCategories'] && parentState?.['IdentitySystemCategories']?.length > 0) {
            parentState['IdentitySystemCategories'].forEach((item: any) => {
                if (item === 620) {
                    valArr.push('Cloud Identity Services (Other)');
                } else {
                    valArr.push(categories[item]);
                }
            })

            setValue(valArr);
        }

    }, [parentState]);

    useEffect(() => {
        if (defaultState) {
            setState(defaultState);
            const valArr: any = []
            defaultState['IdentitySystemCategories'].forEach((item: any) => {

                if (item === 620) {
                    valArr.push('Cloud Identity Services (Other)');
                } else {
                    valArr.push(categories[item]);
                }
            })
            setValue(valArr);
        }

    }, [defaultState])

    useEffect(() => {
        if (isSavedOnce) {
            setState(parentState);
            // setValue(state['IdentitySystemCategories']);
        }
    }, [isSavedOnce])

    useEffect(() => {
        getState(state);
    }, [state]);

    const loadOptions = () => {
        const options = Object.values(categories);
        options.push('Cloud Identity Services (Other)'); // Add 'Cloud Identity Services (Other)' as the last option
        return options;
    };

    const getKeyByValue = (object: any, value: any) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    const arraysAreEqual = (arr1, arr2) => {
        // Sort arrays
        const sortedArr1 = arr1.sort((a, b) => a - b);
        const sortedArr2 = arr2.sort((a, b) => a - b);

        // Compare lengths
        if (sortedArr1.length !== sortedArr2.length) {
            return false;
        }

        // Compare each element
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }

    const updateStateWithSelectedCategories = (selectedCategories: any) => {
        const selectedKeys = selectedCategories.map((category: any) => {
            if (category === 'Cloud Identity Services (Other)') {
                return 620; // Handle 'Cloud Identity Services (Other)' differently
            }
            return parseInt(getKeyByValue(categories, category));
        });
        setState({ IdentitySystemCategories: selectedKeys });
    };

    const getItemLabel = (op) => {
        if (op?.hasOwnProperty('key')) {
            if (op?.key) {
                return op.value
            } else {
                return ''
            }
        }
        return op || ''
    }

    return (
        <form action="/">
            <div>
                <div className="" style={{ marginBottom: '50px', marginLeft: '5px', padding: '5px 0px' }}>
                    <div className="select-title" style={{ float: 'left', margin: "5px 0px", overflow: 'hidden' }}>
                        <label style={{ float: 'left', width: '100%', margin: '0', marginTop: '5px' }}>Select Identity System Categories : </label>
                    </div>
                    <div className="notify-admin-label select-admin-label" style={{ float: 'left' }}>
                        <CheckboxAutocomplete
                            multiple={true}
                            options={loadOptions().map((i) => ({ key: i, value: i }))}
                            getItemLabel={getItemLabel}
                            value={value.map((i) => ({ key: i, value: i })) || []}
                            onChange={(e: any, evt: any) => {
                                const selectedValues = evt.map((i) => i.value);
                                setValue(selectedValues); // Update the value state with the new array of values
                                updateStateWithSelectedCategories(selectedValues);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="clrBoth margintop30"></div>
        </form>
    );
};

export default React.memo(ShadowIdentitySystemConfig);