import './App.css'
import WarningIcon from '@mui/icons-material/Warning';
export const AppConfig = () => {
    document.title = "Error!"
    return <div style={{
        display: 'flex', flexDirection: 'column'
    }}>
        <div className="navbar">
        </div>
        <div style={{
            height: '40vh', textAlign: 'center', marginTop: '30vh'
        }}>
            <WarningIcon
                color='error'
                className='warning-icon'
                classes={{
                    root: 'warning-icon-app-config'
                }}
            />
            <span className='font14'>
                <h3 style={{ fontWeight: '100' }}>Something went wrong !</h3>
                <p>There was an error loading the app configuration.</p>
                <p>Please contact your administrator for assistance.</p>
            </span>
        </div>
    </div>
}