import React, { useEffect, useState } from "react"
import { AMAdminPopUp } from "../ref/AMAdminCommonComponents"
import ReactDatePicker from "react-datepicker";
import CheckboxAutocomplete from "../../components/core/AMAutoComplete/AMAutoComplete";
import moment from "moment";
import { Api } from "../../components/Axios";
import { HttpStatusCode } from "axios";
import { useToasts } from "../../components/core";
import { IApiToken } from "./ApiTokenListing";
import { AMModalError } from "../../components/core/AMModalPopup/AMModalError";
import { ApiTokenErrors } from "./ApiTokenErrors";
import { useForm } from 'react-hook-form';
import { ApiTokenClientFields } from "./ApiTokenSummary";
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@material-ui/core';
import { AMToolTipAd } from "../../components/core/AMTooltipAd/AMTooltipAd";

const SearchSubHeader = <div className="api-token-list-search-subheader">Lorem ipsum dolor sit amet, consectetur adipiscing elit. <span className="link-text">Read More</span></div>

interface IApiTokenProps {
    afterSubmitFn(...args): void,
    closeForm(): void,
    formType?: string
    defaultvalue?: IApiToken,
    listOfClients: string[]
}

export const ApiToken = ({ afterSubmitFn, closeForm, formType, defaultvalue, listOfClients }: IApiTokenProps) => {
    const [clientName, setClientName] = useState<string>("");
    const { addToast } = useToasts();
    const { errors, setError, clearErrors } = useForm();
    const [disableSave, seetDisableSave] = useState(false);
    const [apiToken, setApiToken] = useState<IApiToken>();

    const [showSummary, setShowSummary] = useState(false);
    let today = new Date();
    let nextYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);

    const fiveYear = new Date();
    fiveYear.setFullYear(today.getFullYear() + 5);


    const [expirationDate, setExpirationDate] = useState<Date>(nextYear);

    const tomorrow = new Date(today); // Clone today's date
    tomorrow.setDate(today.getDate() + 1); // Add one day

    useEffect(() => {
        if (defaultvalue) {
            setClientName(defaultvalue.client_name);
            setExpirationDate(new Date(defaultvalue.expiration_date))
            setApiToken(defaultvalue)
        }
    }, [defaultvalue])

    const ApiTokenButtons = {
        primary: {
            label: formType == 'edit' ? 'Update' : "Submit",
            className: (formType == 'edit' && clientName == defaultvalue?.client_name ||
                (errors && Object.keys(errors).length > 0)) ? ' disabled grayscaleOne' : '',
            action: () => {
                const count = listOfClients.filter((i: string) => i.toLocaleLowerCase() == clientName.toLocaleLowerCase()).length
                if (formType == 'add' &&
                    count > 0) {
                    setError('name', { type: 'duplicate' })
                    return
                }
                if (expirationDate && clientName) {
                    let api;
                    const date = moment(expirationDate);
                    const endOfDayUTC = date.utc().endOf('day').unix();
                    if (formType == 'edit') {
                        if (
                            count > 0) {
                            setError('name', { type: 'duplicate' })
                            return
                        }
                        if (clientName == defaultvalue?.client_name) {
                            return
                        }
                        const headers = { 'Operation': 'PUT' }
                        api = Api.post(`/apitoken/${defaultvalue?._id}`, {
                            "client_name": clientName,
                            "expiration_epoc": endOfDayUTC
                        }, { headers: headers });
                    } else {
                        api = Api.post('/apitoken', {
                            "client_name": clientName,
                            "expiration_epoc": endOfDayUTC,
                            status: 1
                        });
                    }
                    api.then((res) => {
                        if (res.status == HttpStatusCode.Ok || res.status == HttpStatusCode.Created) {
                            const msg = formType == 'edit' ? 'Token updated successfully' : 'Token created successfully';
                            addToast(msg, {
                                appearance: 'success',
                                autoDismiss: true,
                            })
                            setShowSummary(true)
                            afterSubmitFn(res.data);
                        }
                    }).catch((er) => {
                        const msg = formType == 'edit' ? 'Error ocurrred while token update' : 'Error ocurrred while adding token';
                        addToast(msg, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    })
                }
            },
        },
        secondary: {
            label: "Cancel",
            action: closeForm,
        },
    };

    const proceedRegenerateForm = () => {
        const headers = { 'Operation': 'PATCH' }
        Api.post(`/apitoken/${defaultvalue?._id}`, "", { headers }).then((res) => {
            addToast("Token re-generated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
            if (apiToken) {
                setApiToken({ ...apiToken, api_token: res.data.token });
            }


        }).catch((er) => {
            addToast("Error occurred while re-generating token.", {
                appearance: 'error',
                autoDismiss: true,
            })
        })
    }


    const setTimeToEndOfDay = (date) => {
        // Ensure date is a Date object
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
        return date;
    };

    return <>
        <AMAdminPopUp
            popUpTitle={formType == 'edit' ? "Manage API Client" : "Add API Client"}
            popUpButtons={ApiTokenButtons}
            handleClose={closeForm}
            rootClass="api-token-form-pop-up-container"
        /* subTitle={SearchSubHeader} */
        >
            <form className="api-token-form-container">
                <div>
                    <div className="api-token-form-container-flex">
                        <div className="api-token-form-container-flex-item">
                            <label>Client Name</label>
                            <input type="text" onChange={(e) => {
                                if (!e.target.value || e.target.value.length == 0) {
                                    setClientName('')
                                    setError('name', { type: 'required' });
                                    return
                                } else if (e.target.value && (e.target.value.length < 3 || e.target.value.length > 50)) {
                                    setError('name', { type: 'length' });
                                } else {
                                    clearErrors()
                                }
                                setClientName(e.target.value)
                            }}
                                value={clientName}
                                placeholder="Enter Client Name"
                            />
                        </div>
                        <div className="api-token-form-container-flex-item">
                            <label style={{ display: 'flex' }}>Expiration Date
                                <AMToolTipAd title="Tokens expire at the end of the selected day in GMT." >
                                    <InfoIcon style={{ fontSize: 14, color: '#d4d8e1', marginLeft: '0.5em' }} />
                                </AMToolTipAd>
                            </label>
                            <ReactDatePicker
                                readOnly={formType == 'edit'}
                                dateFormat="yyyy-MM-dd"
                                selected={expirationDate}
                                onChange={(date: Date) => {
                                    if (!date) {
                                        setError(date, { type: "required" })
                                    } else {
                                        setExpirationDate(setTimeToEndOfDay(date));
                                    }
                                }}
                                value={expirationDate}
                                minDate={tomorrow}
                                maxDate={fiveYear}
                                placeholderText="Select expiration date"
                            />
                        </div>
                    </div>
                    {apiToken?._id &&
                        <div className="api-token-summary-fields-container">
                            <div className="api-token-summary-container-root-content">
                                <ApiTokenClientFields apitToken={apiToken?.api_token} clientId={apiToken?._id}
                                    proceedRegenerateForm={proceedRegenerateForm} />
                            </div>
                        </div>
                    }
                </div>
            </form>
            <AMModalError errors={errors} errorMap={ApiTokenErrors} />
        </AMAdminPopUp >
    </>
}