import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from '../../../components/core';
import {Api} from '../../../components/Axios'

type SmtpServer = {
    host?: string;
    port?: string;
    security?: string;
    authentication?: string;
    user_name?: string;
    password?: string;
    confirm_password?: string;
}

interface Props {
    id?: string;
    AfterEditOperation?: any;
}

export const EditPanel = ({id, AfterEditOperation}: Props) => {
    const { register, handleSubmit, errors, setValue, getValues } = useForm<SmtpServer>()
    const [loading, setLoading] = useState<Boolean>(false)
    const [smtpServer, setsmtpServer] = useState<SmtpServer>()
    const { addToast } = useToasts()

    useEffect(() => {
        Api.get('/smtp')
        .then((res: { data: any }) => {
            setValue("host", res.data.host)
            setValue("port", res.data.port)
            setValue("security", res.data.security)
            setValue("authentication", res.data.authentication)
            setValue("user_name", res.data.user_name)
        })
        .catch((error: any) => {
        })
    }, [])

    const onUpdateSmtp = (data: any) => {
        setLoading(true)
        Api.post('/smtp', data)
        .then((res: { data: any }) => {
            setLoading(false)
            AfterEditOperation('edit')
            addToast("SMTP server updated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    const TestSmtp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const data = getValues()
        setLoading(true)
        const headers = {'Operation':'PUT'}
        Api.post('/smtp', data, {headers: headers})
        .then((res: { data: any }) => {
            addToast("Test email send successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
        .catch((error: any) => {
            setLoading(false)
            if(error.response.status === 500) {
                addToast("Sorry, something went wrong there, try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 419) {
                addToast("We encounted validation problem, please correct and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            } else if(error.response.status === 404) {
                addToast("We are not able to find associated email, please check and try again.", {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onUpdateSmtp)}>
            <label>Server Name/IP : </label>
            <input
                type="text"
                placeholder="192.168.789.123"
                name="host"
                ref={register({
                    required: true,
                })}
                className={(errors.host ? "error" : "")}
                defaultValue={smtpServer?.host}
            />
            {errors.host && errors.host.type === 'required' && <div className="error marginleft40per">Please enter IP server name.</div>}
            {errors.host && errors.host.type !== 'required' && <div className="error marginleft40per">{errors.host.message}</div>}

            <label>Server Port : </label>
            <input
                type="text"
                placeholder="587"
                name="port"
                ref={register({
                    required: true,
                })}
                className={(errors.port ? "error" : "")}
                defaultValue={smtpServer?.port}
            />
            {errors.port && errors.port.type === 'required' && <div className="error marginleft40per">Please enter port.</div>}
            {errors.port && errors.port.type !== 'required' && <div className="error marginleft40per">{errors.port.message}</div>}

            <label>Connection Security : </label>
            <select name="security" className="form_drodown" ref={register}>
                <option value="None">None</option>
                <option value="tls">STARTTLS</option>
                <option value="ssl">SSL/TLS</option>
            </select>
            {errors.security && errors.security.type === 'required' && <div className="error marginleft40per">Please select connection security.</div>}
            {errors.security && errors.security.type !== 'required' && <div className="error marginleft40per">{errors.security.message}</div>}

            <label>Authentication : </label>
            <select className="form_drodown" name="authentication" ref={register}>
                <option value="None">None</option>
                <option value="Username/Password">Username/Password</option>
            </select>
            {errors.authentication && errors.authentication.type === 'required' && <div className="error marginleft40per">Please select Authentication.</div>}
            {errors.authentication && errors.authentication.type !== 'required' && <div className="error marginleft40per">{errors.authentication.message}</div>}

            <label>Username : </label>
            <input
                type="text"
                placeholder="admin@authmind.com"
                name="user_name"
                ref={register({
                    required: true,
                })}
                className={(errors.user_name ? "error" : "")}
                defaultValue={smtpServer?.user_name}
            />
            {errors.user_name && errors.user_name.type === 'required' && <div className="error marginleft40per">Please enter user name.</div>}
            {errors.user_name && errors.user_name.type !== 'required' && <div className="error marginleft40per">{errors.user_name.message}</div>}

            <label>Password : </label>
            <input
                type="password"
                placeholder=" "
                name="password"
                ref={register({
                    required: true,
                })}
                className={(errors.password ? "error" : "")}
                defaultValue={smtpServer?.password}
            />
            {errors.password && errors.password.type === 'required' && <div className="error marginleft40per">Please enter password.</div>}
            {errors.password && errors.password.type !== 'required' && <div className="error marginleft40per">{errors.password.message}</div>}

            <label>Confirm Password : </label>
            <input
                type="password"
                placeholder=" "
                name="confirm_password"
                ref={register({
                    required: true,
                })}
                className={(errors.confirm_password ? "error" : "")}
                defaultValue={smtpServer?.confirm_password}
            />
            {errors.confirm_password && errors.confirm_password.type === 'required' && <div className="error marginleft40per">Please re-enter password.</div>}
            {errors.confirm_password && errors.confirm_password.type !== 'required' && <div className="error marginleft40per">{errors.confirm_password.message}</div>}

            <div className="footer_line"></div>
            <div className="pane_footer">
                <div className="launch_button">
                    <button type="button" className={"marginright20 float_left " + (loading ? 'loader' : 'button_styled')} onClick={TestSmtp}>Test SMTP</button>
                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                </div>
            </div>
        </form>
    )
}