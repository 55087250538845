import './App.css';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Layout } from './components/Layout';
import { useAuthDetails } from './components/Authorization';
import { isAbsolute } from 'path';
import { isAmopsUser } from './utils/util-methods';


export const AppError = ({ children }) => {
    document.title = "Error!";
    const { authDetails } = useAuthDetails()!;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Layout>
            <div style={{ height: '40vh', textAlign: 'center', marginTop: '30vh' }}>
                <WarningIcon
                    color='error'
                    className='warning-icon'
                    classes={{ root: 'warning-icon-app-config' }}
                />
                <div className='font14' style={{lineHeight:'20px'}}>
                    <div style={{ fontWeight: '100',margin:'20px 0px'  }} className='font24'>Something Went Wrong!</div>
                    <p style={{margin:'1em 0px'}}>There was a unexpected issue loading this page.</p>
                    <p style={{margin:'1em 0px'}}>Most of the time, this can be fixed by reloading the page and trying again. If the issue persists, please contact support so we can resolve the issue.</p>
                    {/* <button  style={{width:'200px' , cursor:'pointer', margin:'15px auto' , height:'30px', fontSize:'14px', background:"#FFF",
                     border: '1px solid #CCC' , borderRadius:'5px'}} onClick={()=>{window.location.reload();}}> <RefreshIcon ></RefreshIcon> <span style={{display:'inline-block' ,verticalAlign:'super' }}>  Try again</span> </button> */}
                </div>
               {isAmopsUser(authDetails.email)  && {children} } 
            </div>
            </Layout>
           
        </div>
    );
};
