import React, { useState, useEffect } from 'react';
import {
    useTimeBasedSearchDispatchContext,
    useTimeBasedSearchPropsContext,
    useTimeBasedSearchStateContext
} from '../useTimeBasedSearch';
import TimeBasedSearchChartComponent from './TimeBasedSearchChartComponent';
import { Api } from '../../Axios';
import moment from 'moment';

const HistogramComponent = () => {
    const props = useTimeBasedSearchPropsContext();
    const store = useTimeBasedSearchStateContext();
    const dispatchAction = useTimeBasedSearchDispatchContext();
    const [histogramData, setHistogramData] = useState([]);

    useEffect(() => {
        fetchHistogramData();
    }, []);

    const fetchHistogramData = async () => {
        const currentTime = +new Date();
        const cachedFlowData: any = sessionStorage.getItem(
            `histogram-chart-data-${props.pageType}`
        );

        if (
            !cachedFlowData ||
            JSON.parse(cachedFlowData)?.expiry_time < currentTime
        ) {
            Api.get(props.apiUrl)
                .then((res: any) => {
                    if (!res?.data?.length || res?.data?.length <= 0) {
                        setHistogramData({
                            flows: [],
                            expiry_time: currentTime + 600000
                        });

                        return;
                    }

                    let result = JSON.parse(JSON.stringify(res?.data)) || [];
                    result.sort((a: any, b: any) =>
                        a?.time < b?.time ? -1 : 1
                    );
                    const cachedFlows = {
                        flows: result,
                        expiry_time: currentTime + 600000
                    };

                    setHistogramData(prepareHistogramData(cachedFlows));

                    sessionStorage.setItem(
                        `histogram-chart-data-${props.pageType}`,
                        JSON.stringify(cachedFlows)
                    );
                })
                .catch((er) => {
                    console.log(er);
                });
        } else {
            const parsedData = JSON.parse(cachedFlowData);
            setHistogramData(prepareHistogramData(parsedData));
        }
    };

    const prepareHistogramData = (data) => {
        let chartData = [];
        if (data?.flows && data?.flows?.length > 0) {
            const latestFlowsAvailableOn =
                data?.flows[data.flows.length - 1]?.time;
            let minutesInterval = moment(data?.flows[0].time).minutes();

            const startFromDate = Math.max(
                moment(data?.flows[0]?.time).valueOf(),
                moment().subtract(90, 'days').valueOf()
            );
            let startDate = moment(startFromDate).minutes(minutesInterval);
            while (startDate.isSameOrBefore(moment(latestFlowsAvailableOn))) {
                const foundFlow = data?.flows?.find((p) =>
                    moment(startDate).isSame(moment(p.time), 'hour')
                );
                // found data on api
                if (foundFlow) {
                    if (props.pageType === 'incidents') {
                        chartData.push([foundFlow.time, foundFlow.incident_count || 0]);
                    } else { 
                        chartData.push([foundFlow.time, foundFlow.flow_count || 0]);
                    }
                }
                // 0 flows filler data
                else {
                    // chartData.push([startDate.valueOf(), 0]);
                }
                startDate.add(1, 'hour');
            }

            // stuff 0 for showing line on 1 data point response.
            if (chartData.length === 1) {
                chartData.splice(0, 0, [chartData[0][0] - 3600000, 0]);
            }
        }
        return chartData;
    };

    return (
        <>
            {histogramData.length > 0 && (
                <TimeBasedSearchChartComponent
                    flowsData={histogramData}
                ></TimeBasedSearchChartComponent>
            )}
        </>
    );
};

export default HistogramComponent;
