import React, { useEffect, useRef, useState } from 'react';
import { CredentialQualityConfigAlgo, ProtocolQualityConfigProps, CredentialQualityConfigProtocol, ProtocolQualityConfigState } from '../../../../types/protocol-quality-config-types';


const ProtocolQualityConfig=({parentState, isSavedOnce, getState, defaultState, formType}:ProtocolQualityConfigProps)=>{

    const [state, setState]=useState(defaultState);
    const [ignorePrivateCheck, setIgnorePrivateCheck] = useState<boolean>();

    const hashRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const saltRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(()=>{
        setState(defaultState);
    }, [defaultState])

    useEffect(()=>{
        if(isSavedOnce){
            setState(parentState);
            if(!parentState?.hashFlag || (parentState.hashFlag && parentState.hashValue===undefined)){
                hashRef.current.value="";
            }
            if(!parentState?.saltFlag || (parentState.saltFlag && parentState.saltValue===undefined)){
                saltRef.current.value="";
            }
        }
    }, [isSavedOnce])

    useEffect(()=>{
        // console.log('CredQUALITYConfig STATE', state);
        getState(state);
        console.log(formType)
    })

    const renderAlgoValue=(algos: CredentialQualityConfigAlgo[] | undefined, algoFlag: boolean | undefined)=>{
        return algos?.map((algo: CredentialQualityConfigAlgo)=>{
            return (
                <React.Fragment key={algo.name}>
                    <input type="checkbox" id={algo.name} name={algo.name} checked={algo.isChecked}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        disabled={!algoFlag}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                algo.isChecked=true;
                            }
                            else{
                                algo.isChecked=false;
                            }
                            setState({
                                ...state,
                                algoValue: state?.algoValue
                            })
                        }}
                    />
                    <label className="float_none" htmlFor={algo.name}>{algo.name}</label>
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }

    const renderLegacyProcValue=(legacyProcs: CredentialQualityConfigProtocol[]|undefined, legFlag: boolean|undefined)=>{
        return legacyProcs?.map((proc: CredentialQualityConfigProtocol)=>{
            return (
                <React.Fragment key={proc.name}>
                    <input type="checkbox" id={proc.name} name={proc.name} checked={proc.isChecked}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        disabled={!legFlag}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                proc.isChecked=true;
                            }
                            else{
                                proc.isChecked=false;
                            }
                            setState({
                                ...state,
                                legacyProcValue: state?.legacyProcValue
                            })
                        }}
                    />
                    <label className="float_none" htmlFor={proc.name}>{proc.name}</label>
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }

    const renderCommonProcValue=(commonProcs: CredentialQualityConfigProtocol[]|undefined, commonFlag: boolean|undefined)=>{
        return commonProcs?.map((proc: CredentialQualityConfigProtocol)=>{
            return (
                <React.Fragment key={proc.name}>
                    <input type="checkbox" id={proc.name} name={proc.name} checked={proc.isChecked}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        disabled={!commonFlag}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                proc.isChecked=true;
                            }
                            else{
                                proc.isChecked=false;
                            }
                            setState({
                                ...state,
                                commonProcValue: state?.commonProcValue
                            })
                        }}
                    />
                    <label className="float_none" htmlFor={proc.name}>{proc.name}</label>
                    <div className="clrBoth"></div>
                </React.Fragment>
            )
        })
    }

    return (
        <>
            <form action="/">
                    <div className="playbook-config-title">Authentication quality will be considered poor if:</div>
                    
                    {/* Legacy Protocols */}                    
                    <input type="checkbox" id="legacy-protocol" name="legacy-protocol" checked={state?.legacyProcFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            let temp = state?.legacyProcValue?.map((item: CredentialQualityConfigProtocol)=>{
                                return{
                                    name: item.name,
                                    isChecked: e.target.checked
                                }
                            })                   
                        setState({
                                ...state,
                                legacyProcFlag: e.target.checked,
                                legacyProcValue: temp
                            })                            
                        }}
                    />
                    <label className="float_none" htmlFor="legacy-protocol"><b>Legacy (less secure) protocols</b></label>
                    <div className="clrBoth"></div>
                    <div className="sub_options fl">
                        {renderLegacyProcValue(state?.legacyProcValue?.slice(0,3), state?.legacyProcFlag)}
                    </div>
                    <div className="sub_options fl">
                        {renderLegacyProcValue(state?.legacyProcValue?.slice(3,6), state?.legacyProcFlag)}
                    </div>
        
                    <div className="sub_options fl">
                        {renderLegacyProcValue(state?.legacyProcValue?.slice(6), state?.legacyProcFlag)}
                    </div>
                  
                  <div className="clrBoth"></div>


                  {/* Common Protocols */}

                    <div className="clrBoth margintop10">&nbsp;</div>

                    <input type="checkbox" id="common-protocol" name="common-protocol" checked={state?.commonProcFlag}
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            let temp = state?.commonProcValue?.map((item: CredentialQualityConfigProtocol)=>{
                                return{
                                    name: item.name,
                                    isChecked: e.target.checked
                                }
                            })
                            setState({
                                    ...state,
                                    commonProcFlag: e.target.checked,
                                    commonProcValue: temp
                                })
                        }}
                    />
                    <label className="float_none" htmlFor="common-protocol"><b>Common protocols used without channel encryption</b></label>
                    <div className="clrBoth"></div>
                    <div className="sub_options fl">
                        {renderCommonProcValue(state?.commonProcValue?.slice(0,3), state?.commonProcFlag)}
                    </div>
                    <div className="sub_options fl">

                        {renderCommonProcValue(state?.commonProcValue?.slice(3,8), state?.commonProcFlag)}
                    </div>
        
                    <div className="sub_options fl">
                        {renderCommonProcValue(state?.commonProcValue?.slice(8), state?.commonProcFlag)}
                    </div>
                    <div className="clrBoth"></div>
                    <div className="clrBoth margintop20"></div>

                    <input type="checkbox" id="apq_ignore_private_ip" name="apq_ignore_private_ip"
                    className={(formType && ['edit', 'view'].includes(formType) ? 'disable-config-item' : '')}
                        checked={state?.apq_ignore_private_ip}
                        onChange={(e)=>{
                            if(formType && ['edit', 'view'].includes(formType) )
                                return;
                            if(e.target.checked){
                                setState({
                                    ...state,
                                    apq_ignore_private_ip: true,
                                })
                            }
                            else {
                                setState({
                                    ...state,
                                    apq_ignore_private_ip: false,
                                })
                            }
                        }}
                    />
                    <label htmlFor="apq_ignore_private_ip" className="float_none">Do not apply if source and destination have private IP addresses</label>
                    <div className="clrBoth"></div>
                  
                </form>
        </>
    )
}

export default React.memo(ProtocolQualityConfig);