import React from 'react';

const IP_REP_THRESHOLD = [
    { value: 1, label: 'LOW_RISK_IP' },
    { value: 2, label: 'MEDIUM_RISK_IP' },
    { value: 3, label: 'HIGH_RISK_IP' },
    { value: 4, label: 'CRITICAL_RISK_IP' }
];

const SpOutboundAccessConfigView = ({ config }) => {
    const getIpRepThresholdLabel = (ipRepThreshold) => {
        let res = IP_REP_THRESHOLD.find(
            (item) => item.value === ipRepThreshold
        );
        return res.label;
    };
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    Suspicious Outbound access if Destination IP Reputation
                    minimum threshold{' '}
                    <strong>
                        {getIpRepThresholdLabel(config.ip_rep_threshold)}
                    </strong>
                </span>
            </li>
        </>
    );
};

export default React.memo(SpOutboundAccessConfigView);
