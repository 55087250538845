import React from 'react';

const UnauthorizedLoginDeviceView = ({ config }) => {
    return (
        <>
            <li>
                {/* <strong>Parameters</strong> - */}
                <span>
                    An AD account will be marked as having repeated login
                    attempts from an invalid device when number of login
                    attempts in an hour exceeds{' '}
                    <strong>
                        {
                            config?.dir_params
                                .user_unauthorized_device_count_curhour
                        }
                    </strong>
                </span>
            </li>
        </>
    );
};

export default React.memo(UnauthorizedLoginDeviceView);
