import React from 'react'
import SubHeader from '../components/Layout/SubHeader'
const Reports = () => {
  return (
    <SubHeader title="Reports">
      
    </SubHeader>
  )
}

export default Reports