import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "./FlowChart.css";
import moment from "moment";
import { addDaysToEpoc, addHoursToEpoc, getResolutionMatch } from "../../../utils/util-methods";
import { useTimeBasedSearchActions } from "../../TimeBasedSearch/TimeBasedSearchActions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isApplyBtnDisabled } from "../../TimeBasedSearch/TimeBasedutils";
import { Tooltip } from "@mui/material";
type Props = {
  flowsData:
        | any
        | undefined;
    primaryColor: String | undefined;
    data: any
};

function getElementByClass(selector: string) {
    let elem = document.getElementsByClassName(selector);
    return elem?.length > 0 ? elem[0] : null;
}

let inlineChartRange = [];
const FlowChart = ({ flowsData, primaryColor = '#9fa1ae', data }: Props) => {
    const [chartOptions90Days, setChartOptions90Days] = useState<any>(null);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const currentDate = useRef(+new Date());
    const softMin = (flowsData[0][0]) && flowsData.length < 24 ? {softMin: (flowsData[0][0] - 86400 * 1000 * 5) } : {};
    const maxFlows = Math.max(...flowsData.map(p => p[1]));
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);
    const { appliedFilterTimestamp } = useTimeBasedSearchActions();
    const params = useLocation();
    const history = useHistory();
    const [isViewAccessDisabled, setIsViewAccessDisabled] = useState(false);

    const isEnabledRaw = localStorage.getItem("TIME_BASED_SEARCH_FEATURE");
    let isEnabled = false;
    if (isEnabledRaw) {
        isEnabled = JSON.parse(isEnabledRaw);
    }

    const getQueryParam = () => {
        if (!isEnabled) { 
            return '';
        }
        if (inlineChartRange.length > 0) { 
            let startDate = moment(inlineChartRange[0]).set('hour', 0).set('minute', 0).unix();
            let endDate = moment(inlineChartRange[1]).set('hour', 23).set('minute', 59)
            if (endDate.isAfter(moment())) {
                endDate = `${moment().unix()}_H`;
            } else { 
                endDate = moment(endDate) instanceof moment ? `${endDate.unix()}_H` : `${moment(endDate).unix()}_H`;
            }
    
            return `any_activity_time|${startDate},${endDate}`
        }
        const paramsArr = params.search.split("&");
        const qParam = paramsArr.filter(item => item.indexOf("any_activity_time") > -1);
        if (qParam && qParam.length > 0) {
            let decodedStr = qParam[0].split("="); 
            let firstDecode = decodeURIComponent(decodedStr[1]);
            let secondDecodeArr = firstDecode.split("+");
            let foundItem = secondDecodeArr.filter(item => item.indexOf("any_activity_time") > -1);
            return foundItem && foundItem[0] ? foundItem[0] : "";
        } else { 
            return '';
        }
    }
    Highcharts.setOptions({
        lang: {
            rangeSelectorFrom: '',
            rangeSelectorTo: '-'
        }
    });
    useEffect(() => {
        setChartOptions90Days({
            chart: {
                type: "spline",
                renderTo: "chart",
                margin: getResolutionMatch(100,50),
                style: {
                    fontFamily: "Metropolis-Regular"
                },
                height: getResolutionMatch(900, 450),
                events: {
                    load: () => {
                        setTimeout(() => {
                            if (chartRef?.current?.chart) {
                                const chrt = chartRef.current?.chart;

                                // set y axis max value
                                let { dataMax:yDataMax } = chrt?.yAxis[0].getExtremes();
                                chrt?.yAxis[0].setExtremes(0,Math.max(6, yDataMax * 1.4));

                                // set initial scroll to end.
                                let { dataMax,dataMin } = chrt?.xAxis[0].getExtremes();
                                let start_ms = addDaysToEpoc(dataMax, -5);
                                chrt?.xAxis[0].setExtremes(start_ms, dataMax+43200000, true, false);

                                // add scrolling buttons
                                // chrt.renderer.text(`<span class="flow-left-scroll-arrow"></span>`, 150, 80, true).addClass("leftScroll").add();
                                // chrt.renderer.text(`<span class="flow-right-scroll-arrow"></span>`, 150, 80, true).addClass("rightScroll disabled").add();

                                // // scroll button handlers
                                // getElementByClass("leftScroll")?.addEventListener("click", () => {
                                //     const { dataMin, userMin,userMax } = chartRef.current?.chart?.xAxis[0].getExtremes();
                                //     if (userMin >= dataMin) {
                                //         chartRef.current?.chart?.xAxis[0].setExtremes(addDaysToEpoc(userMin, -1), addDaysToEpoc(userMax,-1), true, false);
                                //     }
                                //     getElementByClass("leftScroll").setAttribute("class", `leftScroll ${addDaysToEpoc(userMin, -1) <= dataMin && "disabled"}`);
                                //     getElementByClass("rightScroll").setAttribute("class", `rightScroll`);
                                // });
                                // getElementByClass("rightScroll")?.addEventListener("click", () => {
                                //     const { dataMax, userMax,userMin } = chartRef.current?.chart?.xAxis[0].getExtremes();
                                //     if (userMax < dataMax) {
                                //         chartRef.current?.chart?.xAxis[0].setExtremes(addDaysToEpoc(userMin,1), addDaysToEpoc(userMax, 1), true, false);
                                //     }
                                //     getElementByClass("rightScroll").setAttribute("class", `rightScroll ${addDaysToEpoc(userMax, 1) >= dataMax && "disabled"}`);
                                //     getElementByClass("leftScroll").setAttribute("class", `leftScroll`);
                                // });
                            }
                        }, 100);
                    }
                }
            },

            title: {
                align: "left",
                useHTML: true,
                text: ""
            },
            subtitle: {
                text: ""
            },
            credits: undefined,
            xAxis: [{
                crosshair: false,
                type: "datetime",
                id: 'main-x-axis',
                allowOverlap:false,
                // offset: 20,
                labels: {
                    useHTML:true,
                    margin:50,
                    align:'left',
                    allowOverlap:false,
                    step: getResolutionMatch( 3,2),
                    formatter: function () {
                        const chrt = chartRef.current?.chart;
                        let { userMax,userMin } = chrt?.xAxis[0].getExtremes() || {userMax:0,userMin:0 };
                        const daysDiff = moment(userMax).diff(userMin,'hours');
                        
                        if(moment(this.value).isAfter(moment(),'day')){
                            return `<span class='flow-x-axis-label'><span>`;
                        }
                        // if(moment(this.value).isSame(moment(),'day')){
                        //     return `<span class='flow-x-axis-label'><b>Today</b><span>`;
                        // }
                        return  daysDiff > 30 ? `<span class='flow-x-axis-label'><b>${moment(this.value).format("MMM DD YYYY")}</b><span>`: `<span class='flow-x-axis-label' style="margin-left:-30px"><b>${moment(this.value).format("MMM DD YYYY,hh:mm A")}</b><span>`;
                    },
                    overflow: "justify",
                    style:{
                        paddingTop: getResolutionMatch( '70px','35px')
                    }
                },
                events: {
                    afterSetExtremes(e) {
                        const output = `${new Date(e.min)} - ${new Date(e.max)}`
                        updateInlineChartVal(e.min,e.max);
                    }
                }
            }],
            
            yAxis: {
                opposite:false,
                softMax:60,
                title: {
                    text: ""
                },
                labels: {
                    style: {
                        // color: 'red',
                        fontSize: getResolutionMatch(20,10)
                    }
                }
            },
            tooltip: {
                shape: "rect",
                // hideDelay: 50000,
                positioner: function (w, h, p) {
                    return {
                        x: Math.min(getResolutionMatch(1400,705), Math.max(getResolutionMatch(290,110), p.plotX)),
                        y: -10
                    };
                },
                useHTML: true,
                padding: 10,
                shadow: false,
                borderWidth: 0,
                backgroundColor: "#ffffff00",
                formatter: function () {
                    const header = `<span class='flow-chart-tooltip' style='box-shadow: 2px 2px 4px -2px ${primaryColor}; border: 1px solid ${primaryColor};'><p>${moment(addHoursToEpoc(+this.x,-1)).format("MMM DD YYYY")}${','}${moment(this.x).format("hh:mm A")} - ${moment(addHoursToEpoc(+this.x, +1)).format("hh:mm A")}</p><br><b> Flows: ${parseFloat(
                        this.y
                    ).toFixed()}</b></span>`;
                    return header;
                },
                style: {
                    zIndex: 100000
                }
            },
            plotOptions: {
                spline: {
                     color: primaryColor,
                    //color: "#fdbf06",
                    lineWidth: getResolutionMatch( 3,1.5),
                    states: {
                        hover: {
                            lineWidth: getResolutionMatch( 3,1.5)
                        }
                    }
                },
                series: {
                    dataGrouping: {
                        enabled:false
                    }, 
                    marker: {
                        enabled: false,
                        radius: getResolutionMatch( 5,1.5),
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    },
                }
            },
            legend: {
                enabled: false
            },
            rangeSelector : {
                enabled: true,
                inputEnabled: true,
                inputStyle: {
                    color: 'black',
                    fontWeight: 'normal',
                    fontFamily: 'Metropolis-Regular'
                },
                inputDateFormat: `%b %e %Y`,
                buttons: []
            },
            scrollbar: {
                buttonBackgroundColor: 'transparent',
                buttonArrowColor: 'transparent',
                trackBorderColor: 'transparent',
                trackBackgroundColor: 'transparent',
                buttonBorderWidth: 0,
                height:getResolutionMatch(25,10)
            },
            navigator:{
                useHTML:true,
                height:getResolutionMatch( 100,55),
                handles: {
                    height:getResolutionMatch(35,20),
                    width:getResolutionMatch(15,7),
                },
                plotOptions: {
                    spline: {
                         color: primaryColor,
                        //color: "#fdbf06",
                        lineWidth: getResolutionMatch( 3,1.5),
                        states: {
                            hover: {
                                lineWidth: getResolutionMatch( 3,1.5)
                            }
                        }
                    },
                    series: {
                        dataGrouping: {
                            enabled:false
                        }, 
                        marker: {
                            enabled: false,
                            radius: getResolutionMatch( 5,1.5),
                            states: {
                                hover: {
                                    enabled: true
                                }
                            }
                        },
                    }
                },
                series: {
                    marker: {
                        enabled:false,
                            fillColor: '#FFFFFF',
                            lineWidth: 0,
                            lineColor: null // inherit from series
                        },
                    useHTML:true,
                    type: 'areaspline',
                    fillOpacity: 0.2,
                    dataGrouping: {
                        smoothed: true
                    },
                    lineWidth: 1,
                },
                xAxis: {
                    // ...softMin,
                    min: moment().subtract(60, 'days').valueOf(),
                    max: moment().valueOf(), 
                    // tickInterval: 32 * 24 * 3600 * 1000,
                    allowOverlap:false,
                    labels: {
                        allowOverlap:false,
                        y: getResolutionMatch(50, 25),
                        x: getResolutionMatch(200, 40),
                        useHTML: true,
                        align: 'left',
                        step: getResolutionMatch(4, 1),
                        formatter: function () {
                            if (moment(this.value).isSame(moment(), 'day')) {
                                return `<span class='flow-x-axis-label-navigator'><b>Today</b></span>`;
                            }
                             return `<span></span>`
                            //return `<span class='flow-x-axis-label-navigator'><b>${moment(this.value).format("MMM DD YYYY")}</b></span>`;
                        }
                    },
                    plotBands: [{
                        from: moment().subtract(60, 'days').valueOf(),
                        to: moment().subtract(60, 'days').valueOf(),
                        label: {
                            text: moment().subtract(60, 'days').format("MMM DD YYYY"),
                            align: 'left',
                            rotation: 0,
                            x: -20,
                            y: 20,
                            useHTML: true,
                            style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize:'1em'
                            }
                        }
                    }, {
                        from: moment().valueOf(),
                        to: moment().valueOf(),
                        label: {
                            text: moment().format("MMM DD YYYY"),
                            align: 'right',
                            rotation: 0,
                            x: 20,
                            y: 20,
                            useHTML: true,
                            style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize:'1em'
                            }
                        }
                    }]
                },
                
               
            },
            series: [
                {
                    name: "timestamp",
                    data: flowsData || [],
                    // data: getdata(currentDate.current) || []
                }
            ]
        });
    }, []);

    const updateInlineChartVal = (min, max) => { 
        inlineChartRange = [];
        inlineChartRange.push(min)
        inlineChartRange.push(max)
        if (moment(max).isAfter(moment())) { 
            max = moment().valueOf();
        }
        setIsViewAccessDisabled(isApplyBtnDisabled(min, max))
    }

    const onView = () => {
        if (data && data.pageType && data.pageType === 'issue') {            
            history.push(`accesses?order_by=desc&sort_by=score&q=${issuePageType()}`)
        } else { 
            history.push(`accesses?order_by=desc&sort_by=score&q=${posturePageType()}`)
        }
    }

    const posturePageType = () => { 
        let res = `${getQueryParam()}`;
        res = res?.length > 0 ? (res+ '+' ): ''
        res += decodeURIComponent(data?.queryData);

        return `${encodeURIComponent(res)}`;        
    }

    const issuePageType = () => {
        let res = `${getQueryParam()}`
        res = res?.length > 0 ? (res+ '+' ): ''
        res += `identity_name:${decodeURIComponent(data['source_name'])}+identity_type:${data['source_type']}+asset_name:${decodeURIComponent(data['destination_name'])}+asset_type:${data['destination_type']}`
        if (data['directory_name'] && data['directory_name'] !== "") { 
            res += `+dir_name:${data['directory_name']}`
        }

        return `${encodeURIComponent(res)}`;
    }

    useEffect(() => {
        if (!isEnabled) return;
        setTimeout(() => {
            let timeFilter = appliedFilterTimestamp();
            if (timeFilter.length > 0) {
                if (chartRef.current?.chart) {
                    chartRef.current?.chart?.xAxis[0].setExtremes(timeFilter[0], timeFilter[1]);
                    inlineChartRange = [];
                }
            }
        }, 300)
    }, [])

    return (
        <>
            <div className='trend-90days'>
                <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={chartOptions90Days}
                    constructorType={'stockChart'}
                />
                {data && (<>
                {isEnabled && getQueryParam() !== '' ? (
                    <div>
                        {isViewAccessDisabled ? (
                            <Tooltip
                                classes={{
                                    tooltip: 'playbook-level-widget-tooltip'
                                }}
                                title='Adjust your date range to be less than 15 days.'
                            >
                                <button
                                    className='chart-action-btn inline-navigator-chart-btn marginbottom10 disabled-btn'
                                    disabled={true}
                                >
                                    View Accesses
                                </button>
                            </Tooltip>
                        ) : (
                            <button
                                className='chart-action-btn inline-navigator-chart-btn marginbottom10'
                                onClick={onView}
                            >
                                View Accesses
                            </button>
                        )}
                    </div>
                ) : (
                    <div>
                        <button
                            className='chart-action-btn inline-navigator-chart-btn marginbottom10'
                            onClick={onView}
                        >
                            View Accesses
                        </button>
                    </div>
                    )}
                </>)}
            </div>
        </>
    );
};

export default React.memo(FlowChart);
