import { GlobalConsts } from "../../../GlobalConst";

export const SensorLabels = {
    gc_query: 'Directory Query Parameters'
};

export const SENSOR = [
    { label: 'AD Sensor', value: 1 },
    { label: 'Azure AD Sensor', value: 4 },
    { label: 'Enrichment Sensor', value: 10 },
    { label: 'Flow Sensor/SIEM', value: 3 },
    { label: 'Google Workspace Sensor', value: 8 },
    { label: 'Okta Sensor', value: 7 },
    { label: 'OneLogin Sensor', value: 6 },
    { label: 'Ping Identity Sensor', value: 9 }
];

export const AVAILABLE_SIEMS = [
    { label: 'QRadar', value: 13 },
    { label: 'Splunk', value: 12 },
    { label: 'Sumologic', value: 14 }
];

export const FLOW_SENSOR = 'FLOW_SENSOR';
export const SIEM_VENDOR = 'SIEM_VENDOR';
export const DIRECT = 'DIRECT';

export const MESSAGES = [
    `No issues identified. Last event received on `,
    `Issues identified. Last event received `,
    `Connection Successful`,
    `Connection Unsuccessful`,
    `Events have not been received in > 2 hour. Last event received was at `,
    `Events have not been received yet.`,
    `No collection methods are available. Add a SIEM Sensor or Flow Sensor first`,
    `No Flow sensors are available. Add a Flow Sensor first`,
    `Issues with Flow sensor`,
    `This Collector is already configured via `,
    `No Flow Sensors are available! Configure an on-premise flow sensor first and then configure your SIEM connection.`,
    `Issues identified.`,
    `Events have not been received in > 2 hour.`,
    `Logs will flow in < 15 minutes.`
];

export const DYNAMIC_FIELDS_COLLECTOR_MAPPING = [
    {
        id: 'Google Workspace',
        collectors: [
            { id: 'google_workspace_login', label: 'Google Workspace Login' },
            { id: 'google_workspace_oauth', label: 'Google Workspace OAuth' },
            { id: 'google_workspace_saml', label: 'Google Workspace SAML' }
        ]
    },
    {
        id: 'Cisco Umbrella Secure Internet Gateway (SIG)',
        collectors: [
            { id: 'cisco_umbrella_ip', label: 'Cisco Umbrella IP' },
            { id: 'cisco_umbrella_proxy', label: 'Cisco Umbrella Proxy' }
        ]
    },
    {
        id: 'Ping Identity PingOne IdP',
        collectors: [
            { id: 'ping_activity', label: 'Ping Activity' },
            { id: 'ping_audit', label: 'Ping Audit' },
            { id: 'ping_authentication', label: 'Ping Authentication' }
        ]
    },
    {
        id: 'Fortinet FortiSASE',
        collectors: [
            { id: 'fortinet_event', label: 'Fortinet Event' },
            { id: 'fortinet_traffic', label: 'Fortinet Traffic' },
            { id: 'fortinet_utm', label: 'Fortinet UTM' }
        ]
    },
    {
        id: 'Fortinet Firewall & VPN',
        collectors: [
            { id: 'fortinet_event', label: 'Fortinet Event' },
            { id: 'fortinet_traffic', label: 'Fortinet Traffic' },
            { id: 'fortinet_utm', label: 'Fortinet UTM' }
        ]
    }
];

export const COLLECTOR_ID_MAPPING = {
    AWS_S3: 'AWS S3',
    AWS_Managed_AD: 'AWS Managed AD',
    Cato_SASE: 'Cato Networks SASE',
    Crowdstrike_Falcon_EDR: 'Crowdstrike Falcon EDR',
    Azure_NSG_Logs: 'Azure NSG Flow Logs',
    AWS_VPC_Logs: 'AWS VPC Flow Logs',
    GCP_VPC_Logs: 'GCP VPC Flow Logs',
    SYSLOG: 'Syslog',
    Cisco_Umberlla_SIG: 'Cisco Umbrella Secure Internet Gateway (SIG)',
    Ping_identity_PingOne_IdP: 'Ping Identity PingOne IdP',
    Okta_IdP: 'Okta IdP',
    OneLogin_IdP: 'OneLogin IdP',
    IBM_Verify_SaaS: 'IBM Verify',
    Google_Workspace: 'Google Workspace',
    Microsoft_Entra_ID: 'Microsoft Entra ID (Azure AD)',
    Checkpoint_Perimeter_81: 'Check Point Perimeter 81',
    Cisco_Umbrella_DNS_Security: 'Cisco Umbrella DNS Security'
};

export const POPUP_SUB_TITLE_META = {
    FLOW_SENSOR: {
        title: 'Leverage a Flow Sensor to collect from one or more local data sources and/or local SIEMs.',
        guideLink: '#'
    },
    QRADAR_SAAS: {
        title: 'Leverage this Cloud SIEM to collect events from a variety of data sources (no Flow Sensor req`d)',
        guideLink: `${GlobalConsts.DEPLOYMENT_LINK}/ibm-security-qradar-siem-collector?${GlobalConsts.DOC_TOKEN}`
    },
    QRADAR: {
        title: 'Leverage this SIEM to collect logs from a variety of data sources (req on-premise Flow Sensor)',
        guideLink: `${GlobalConsts.DEPLOYMENT_LINK}/ibm-security-qradar-siem-collector?${GlobalConsts.DOC_TOKEN}`
    },
    SPLUNK: {
        title: 'Leverage this SIEM to collect logs from a variety of data sources (req on-premise Flow Sensor)',
        guideLink: `${GlobalConsts.DEPLOYMENT_LINK}/splunk-data-collector?${GlobalConsts.DOC_TOKEN}`
    },
    SPLUNK_CLOUD: {
        title: "Leverage this Cloud SIEM to collect events from a variety of data sources (no Flow Sensor req'd)",
        guideLink: `${GlobalConsts.DEPLOYMENT_LINK}/splunk-data-collector?${GlobalConsts.DOC_TOKEN}`
    },
    SUMOLOGIC: {
        title: "Leverage this Cloud SIEM to collect events from a variety of data sources (no Flow Sensor req'd)",
        guideLink: `${GlobalConsts.DEPLOYMENT_LINK}/sumo-logic-events-collector?${GlobalConsts.DOC_TOKEN}`
    },
    SIEM_SENSOR: {
        title: 'Integrate with an existing SIEM to collect one or more data sources to streamline deployments.',
        guideLink: '#'
    },
    ENRICHMENT_SENSOR: {
        title: 'Enrichment Sensors are an alternative to a local Flow Sensor to enrich host details with local DNS and Directory Catalog details.',
        guideLink: '#'
    },
    AD_LDS_SENSOR: {
        title: 'Leverage a Microsoft AD Sensor to collect natively collect events and insights from either a AD or AD-LDS Directory.',
        guideLink: '#'
    }
};

export const SENSOR_SETTINGS_KEYS = ['User-Inputs', 'gc_query', 'Flow Sensor', 'Risk API Call', 'Directory Query Parameters']
