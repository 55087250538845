import React, { createContext, useState, useContext } from "react";

interface IActiveTabs { identities: boolean, assets: boolean, accesses: boolean, directory: boolean }

interface PostureStateValues {
    PostureSearchState: {
        activeTabs: IActiveTabs;
        setActiveTabs: (o: IActiveTabs) => void;
        isAdSearchApplied: boolean,
        setIsAdSearchApplied: (i: boolean) => void,
        isAdvancedSearchOpen: boolean,
        setIsAdvancedSearchOpen: (i: boolean) => void
    }
}

export const DefaultActiveTabs: IActiveTabs = {
    accesses: true, assets: true, directory: true, identities: true
}

const PostureStateContext = createContext<PostureStateValues | undefined>(undefined);

export const PostureStateContextProvider: React.FC = ({ children }) => {
    //Unknown Access State

    // activeTabs, isPostureAdSearchApplied, setActiveTabs, setIsPostureAdvancedSearchApplied;

    const [isPostureAdSearchApplied, setIsPostureAdvancedSearchApplied] = useState<boolean>(false);
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [activeTabs, setActiveTabs] = useState<IActiveTabs>(DefaultActiveTabs);

    const setPostureActiveTabs = (id: IActiveTabs) => {
        setActiveTabs(id);
    };

    const setPostureAdvancedSearchApplied = (i: boolean) => {
        if (!i) {
            setActiveTabs(DefaultActiveTabs);
        }
        setIsPostureAdvancedSearchApplied(i);
    }

    const setIsAdSearchOpen = (i: boolean) => setIsSearchOpen(i)

    const contextValue: PostureStateValues = {
        PostureSearchState: {
            activeTabs: activeTabs, isAdSearchApplied: isPostureAdSearchApplied,
            setActiveTabs: setPostureActiveTabs, setIsAdSearchApplied: setPostureAdvancedSearchApplied,
            isAdvancedSearchOpen: isSearchOpen, setIsAdvancedSearchOpen: setIsAdSearchOpen
        }
    };

    return <PostureStateContext.Provider value={contextValue}>{children}</PostureStateContext.Provider>;
};


export const usePostureStateContext = (): PostureStateValues => {
    const context = useContext(PostureStateContext);
    if (!context) {
        throw new Error("usePostureStateContext must be used within a PostureStateContextProvider");
    }
    return context;
};
