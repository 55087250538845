import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import * as qs from "qs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router';
import { Link, useLocation } from "react-router-dom";
import SwitchButton from "react-switch";
import ReactTooltip from 'react-tooltip';
import { ArrayParam, NumberParam, StringParam, withDefault, withQueryParams } from 'use-query-params';
import { IDScore } from '../../../common/IDScore/IDScore';
import { Api } from '../../../components/Axios';
import { useToasts } from '../../../components/core';
import { AMBreadcrumb } from '../../../components/core/AMBreadcrump/AMBreadcrumb';
import { AssetsObj, IdAssetWidget, IdentitiesMappingObj, IssueFilters, IssueParams, RulesDetailObj, ServerResponse } from '../../../types/response-types';
import { convertToCommaValue, findDifferenceInDays, deepCompare, findNameById, getTimeDiffString, isIssueType, trimAfterSecondWord } from '../../../utils/util-methods';
import FlowTrend from '../../issue_prev/flow-trend/flow-trend';
import { AssetColumnMarkKnownOptions, AssetsActionColumnMenu, AssetsActionMenu, ExposedAssetColumnMarkKnownOptions } from '../constants/IDPostureMenu';
import '../identity_asset.scss';
import '../posture_root_tooltip.css';
import { BasePostureActionMenu } from '../ref/PostureActionMenus/BasePostureActionMenu';
import { PostureMenuItem, PostureMenuItemAction } from '../ref/PostureActionMenus/PostureActionMenu';
import { ScrollIssueData, ScrollIssueHeader, ScrollIssueHeaderRef } from '../ref/ScrollIssue/ScrollIssue';
import { ReportByIdAsset, RuleRootWidget, SummaryWidget } from '../sub';
import IdAccess from './id-access-popup/idaccess';
import RuleMatch from './rule-match/rulematch';
import PostureFilter from '../../../components/core/PostureFilter/PostureFilter';
import { FilterWidget } from '../../../components/core/PostureFilter/PostureFilterWidget';
import { getAdditionalResultCellValue, getSearchFilterObjectFromTags, getTagsForSearchInput, getTagsForTimeBasedSearch, getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';
import {
	formatCSVData, getHeaders, useScrollIssue, useLensSearch,
	usePostureAdvanceSearch, validateLocalIps, usePostureArchival
} from '../ref/Hooks/Posture';
import { CancelToken } from 'axios';
import { usePostureStateContext } from '../../../store/PostureStateContextProvider';
import Tags from '../../issue_prev/issues/tags/tags';
import { PostureTabTooltip } from '../ref/PostureTabTooltip';
import { AMCheckbox } from '../../../components/core/AMCheckbox/AMCheckbox';
import AMMultiCheckbox, { IAMMultiCheckboxMenuItem } from '../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import { IPostureActionButtonsRef, PostureActionButtons, PostureArchiveTooltip } from '../ref/PostureArchive/PostureArchive';
import { IApiResponse, PostureService } from '../ref/Hooks/PostureService';
import { useAuthDetails } from '../../../components/Authorization';
import { PostureCategory } from '../ref/PostureCategories/PostureCategory';
import { PlayBookView } from '../sub/PlayBookView';
import { ENTITIES, POSTURE_ISSUE } from '../constants/Constants';
import { useValidatePostureIssuesSearchResult } from '../ref/Hooks/PostureIssues';
import AdditionalResult from '../sub/AdditionalResult';
import { IPlaybookActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTypes';
import { PlaybookTableActions } from '../../playbooks/ref/AdvancedPlaybook/PlaybookTableActions';
import { PostureSearchTemplate, notSupportedKeys } from '../constants/PostureSearchTemplate';
import { TIME_BASED_SEARCH_FIELD } from '../../../components/TimeBasedSearch/Constants';
import TimeBasedSearchContainer from '../../../components/TimeBasedSearch/TimeBasedSearchContainer';
import { getIssueNameById } from '../../playbooks/helpers/playbook-helper';

const Assets = ({ query, setQuery }: any) => {

	const [checkFlag, setCheckFlag] = useState(false);
	const [prevUrl, setPrevUrl] = useState<string>(window.location.pathname + window.location.search);
	const search = useLocation().search;
	const location = useLocation();
	const hard_refresh_state: boolean = new URLSearchParams(search).get('hard_refresh') === "true" ? true : false;
	const [tableFetchError, setTableFetchError] = useState('No records found.');
	const [showRuleMatch, setShowRuleMatch] = useState(false);
	const [showIDAccess, setShowIDAccess] = useState(false);
	const [issueHeader, setIssueHeaders] = useState<Array<string>>([]);
	const [searchInput, setSearchInput] = useState<string>('');
	const [tags, setTags] = useState<string[]>([]);
	const [tagFlag, setTagFlag] = useState<boolean | undefined>();
	const scrollIssueRef = useRef<ScrollIssueHeaderRef>(null);
	const [showAnimation, setShowAnimation] = useState(false);
	const { updateColumnEffect, scrollToIssueColumnFn } = useScrollIssue();
	const { handleLensSearchFn, handleSearchFn } = useLensSearch(searchInput, setTags, setSearchInput, setTagFlag);
	const [selectedColumn, setSelectedColumn] = useState(query.sort_by || '');
	const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
		' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
	const [openPanelFilterWidgets, setOpenPanelFilterWidgets] = useState(false);
	const [filterData, setFilterData] = useState<IssueFilters | undefined>();
	const disabled_filter_state: boolean = new URLSearchParams(search).get('disable_filter') === "true" ? true : false;
	const [disabledFilter, setDisabledFilter] = useState<boolean>(disabled_filter_state);
	const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
	const { handleApplyAdvancedSearch, handleManualSearch, getSearchHeaders, PartnerConfigState, preMetaDataHandler

	} = usePostureAdvanceSearch();
	const { PostureSearchState } = usePostureStateContext();
	const [isClearAll, setIsClearAll] = useState(false);
	const [additionalResultHighlightClass, setAdditionalResultHighlightClass] = useState('');
	const {
		selectAll,
		onSetSelectRow,
		getSelectRow,
		onSelectAllClick,
		selectAllIndeterminate,
		setArchivalData,
		setOpenIssuesCount,
		onArchive,
		selectCount,
		isIncidentsOpen,
		PostureMultiSelectCheckboxItems,
		resetSelectionState,
		onArchiveEstimate,
		setCurrentPage,
		currentPage
	} = usePostureArchival('Asset');
	const { PostureSummary } = PostureService();
	const { authDetails } = useAuthDetails()!;
	const { isValidResultCountThreshold, matchingPlaybookFound } = useValidatePostureIssuesSearchResult();
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedField, setSelectedField] = useState(null);
	const [showAdditionalResult, setShowAdditionalResult] = useState(false);
	const [additionalFields, setAdditionalFields] = useState([]);
	const archiveBtnRef = useRef<IPostureActionButtonsRef>(null);
	const MoreActionsOptions: IPlaybookActions<Array<any>> = [{
		actionCallback: () => {
			if (archiveBtnRef?.current?.onArchiveBtnClick)
				archiveBtnRef?.current?.onArchiveBtnClick()
		},
		actionId: 'mark-archive',
		actionLabel: 'Archive',
		isDisabled: selectCount == 0,
		isApplicable: authDetails?.permissions?.Posture?.manual_archive == 'readwrite'
	}
	];
	const [isTimeBasedSearchApplied, setIsTimeBasedSearchApplied] = useState(false);
	const highlightSortDir = () => {
		if (query.order_by === 'asc') {
			setCurrentSort(' tablesort_up tablesort_up_selected ');
		} else if (query.order_by === 'desc') {
			setCurrentSort(' tablesort_down tablesort_down_selected ');
		}
	};

	useEffect(() => {
		setShowAdditionalResult(true)
	}, [query.q]);

	useEffect(() => {
		let tempTags: string[] = [];
		if (param.q) {
			// tempTags = [...param.q];
			tempTags = q.split('+');
		}
		if (param.rule_name && !tempTags.includes(param.rule_name)) {
			tempTags.push(param.rule_name);
		}
		if (param.risk && !tempTags.includes(param.risk.toString())) {
			tempTags.push(param.risk.toString());
		}
		if (param.d_protocol && !tempTags.includes(param.d_protocol)) {
			tempTags.push(param.d_protocol);
		}
		if (param.d_name && !tempTags.includes(param.d_name)) {
			tempTags.push(param.d_name);
		}
		if (param.hash && !tempTags.includes(param.hash)) {
			tempTags.push(param.hash);
		}
		if (
			param.s_time &&
			param.e_time &&
			!tempTags.includes(param.s_time.toString()) &&
			!tempTags.includes(param.e_time.toString())
		) {
			tempTags.push(param.s_time.toString(), param.e_time.toString());
		}

		if (tempTags.length > 0) {
			setTags(tempTags);
			setTagFlag(false);
		} else {
			setTags([]);
		}

		highlightSortDir();
		if (
			!history.location.pathname.includes('matchrule') &&
			!history.location.pathname.includes('flow')
		) {
			fetchAssets();
		}
	}, [query, checkFlag]);

	// useEffect(() => {
	// 	fetchWidget();
	// }, []);

	const fetchAssets = async (cancelToken?: CancelToken) => {
		setLoading(true);
		setTableFetchError('No records found.');
		const searchHeaders = await getSearchHeaders(param);
		Api.get(`/assets?is_external_not_allowed=${checkFlag}`, {
			params: param,
			paramsSerializer: (params) =>
				qs.stringify(params, { arrayFormat: 'repeat' }),
			cancelToken: cancelToken,
			...searchHeaders
		})
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					setTotalCount(res.data.total);
					const d = validateLocalIps(res.data, 'host_detail');
					setResponseData(d);
					setArchivalData(d);
					setOpenIssuesCount(res.data.total);
					setLastUpdate(moment().format('MMM DD YYYY, hh:mm A'));
					scrollToIssueColumn();
					if (currentPage) {
						resetSelectionState();
					}
					setCurrentPage(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setResponseData({ result: 'error' } as ServerResponse);
				addToast('Error while fetching data.', {
					appearance: 'error',
					autoDismiss: true
				});
				setTableFetchError(`An error occurred while processing your request. Please check your input and try again. If the error persists, please reach out to ${PartnerConfigState?.PartnerShortProduct} support.`);
			});
	};

	const reloadAssets = () => {
		reloadRef.current.classList.add('reload_spin');
		param.hard_refresh = true;
		setQuery(param);

		fetchAssets();

		const fetchData = async () => {
			try {
				const b = await fetchWidget();
				//   const c =   await setTimeout(()=>{fetchUserSelection()},300);


			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
		setTimeout(() => {
			if (reloadRef.current.classList.contains('reload_spin')) {
				reloadRef.current.classList.remove('reload_spin');
			}
		}, 750);
	};

	const {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort,
		rule_name: rule_name,
		d_protocol: d_protocol,
		risk: risk,
		hash: hash,
		d_name: d_name,
		s_time: s_time,
		e_time: e_time,
		hard_refresh: hard_refresh
	} = query;

	const [ruleMatch, setRuleMatch] = useState<AssetsObj | undefined>();
	const [idAccess, setIdAccess] = useState<
		IdentitiesMappingObj[] | undefined
	>();
	const [asset_name, setAname] = useState<string>('');
	const [responseData, setResponseData] = useState<ServerResponse>();
	const [lastUpdate, setLastUpdate] = useState<string>();
	const [totalCount, setTotalCount] = useState<number>();
	const [openFlow, setOpenFlow] = useState<boolean>(false);
	const [flowsTrendResponseData, setFlowsTrendResponseData] =
		useState<any>(undefined);
	const [flowsChartDetails, setFlowsChartDetails] = useState<any>({
		chartTitle: ''
	});

	let history = useHistory();
	const { addToast } = useToasts();
	const reloadRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	let param: IssueParams = {
		q: q,
		page: page_number,
		rpp: record_per_page,
		order_by: order,
		sort_by: sort,
		rule_name: rule_name,
		d_protocol: d_protocol,
		risk: risk,
		hash: hash,
		d_name: d_name,
		s_time: s_time,
		e_time: e_time,
		hard_refresh: hard_refresh
	};

	const LoadSorting = (field: string) => {
		if (param.sort_by === field) {
			param.order_by = param.order_by === 'asc' ? 'desc' : 'asc';
			setQuery(param);
		} else {
			param.sort_by = field;
			param.order_by = 'desc';
			setQuery(param);
		}
	};

	const handleSort = (e: React.MouseEvent) => {
		const col = e.currentTarget.getAttribute('data-headerName');
		setSelectedColumn(col);
		LoadSorting(col || '');
	};


	const handleRuleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
		let idx = e.currentTarget.id.toString();
		let ruleName = e.currentTarget.getAttribute('data-rulename');
		const isIssue = isIssueType(items, ruleName);
		ruleName = findNameById(items, ruleName);
		let hostDetails = responseData?.result.find(
			(item: AssetsObj) => item._id === idx
		);
		let currHostDetails = hostDetails.host_detail;
		let type = hostDetails?.type;
		let assetsRef = '';
		currHostDetails.map((item: any, index: any) => {
			if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			isIssue ? history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Brule_name:' +
				ruleName +
				'%2Bd_type:' +
				type +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			) : history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Bpb_name:' +
				ruleName +
				'%2Bd_type:' +
				type +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		} else {
			isIssue ? history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Brule_name:' +
				ruleName +
				'%2Bd_type:' +
				type + '%2B' + encodeURIComponent(timeSearchParam) +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			) : history.push(
				'/issues?disable_filter=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bstatus:Open%2Bpb_name:' +
				ruleName +
				'%2Bd_type:' +
				type + '%2B' + encodeURIComponent(timeSearchParam) +
				'&sort_by=issue_flows_count',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		}
	};

	const getChartTitle = (identityName: string, startDate: any = null) => {
		if (startDate)
			return (
				<span
					title={`Hourly flows trend (${getTimeDiffString(
						startDate
					)}) for Asset: ${identityName}`}
				>{`Hourly flows trend (${getTimeDiffString(
					startDate
				)}) for Asset: ${trimAfterSecondWord(identityName, 35)}`}</span>
			);
		else
			return (
				<span
					title={`Hourly flows trend for Asset: ${identityName}`}
				>{`Hourly flows trend for Asset: ${trimAfterSecondWord(
					identityName,
					35
				)}`}</span>
			);
	};

	const handleFlowClick = (event: any, data: any) => {
		setOpenFlow(true);
		let { id: identityName, asset_type } = data;
		const currentTime = +new Date();
		const cachedFlowData: any = sessionStorage.getItem(
			`assets-flow-trend-${identityName}-${asset_type}`
		);

		const encodedIdentityName = encodeURIComponent(identityName);
        const encodedAssetType = encodeURIComponent(asset_type);

		const flowTrendURL = `/assets/flowcounts?search_type=asset&search_value1=${encodedIdentityName}&asset_type=${encodedAssetType}`;

		if (
			!cachedFlowData ||
			JSON.parse(cachedFlowData)?.expiry_time < currentTime
		) {
			Api.get(flowTrendURL)
				.then((res: any) => {
					if (!res?.data?.length || res?.data?.length <= 0) {
						setFlowsTrendResponseData({
							flows: [],
							expiry_time: currentTime + 600000
						});

						// Chart header and color
						const chartTitle = getChartTitle(identityName);
						setFlowsChartDetails({
							chartTitle, itemData: {
								queryData: `asset_name:` + encodedIdentityName
							}
						});

						return;
					}

					let result = JSON.parse(JSON.stringify(res?.data)) || [];
					result.sort((a: any, b: any) =>
						a?.time < b?.time ? -1 : 1
					);
					const cachedFlows = {
						flows: result,
						identityName,
						risk,
						expiry_time: currentTime + 600000
					};
					setFlowsTrendResponseData(cachedFlows);

					// Chart header and color
					const startFromDate = Math.max(
						moment(result?.[0]?.time).valueOf(),
						moment().subtract(90, 'days').valueOf()
					);
					const chartTitle = getChartTitle(
						identityName,
						startFromDate
					);
					setFlowsChartDetails({
						chartTitle, itemData: {
							queryData: `asset_name:` + identityName
						}
					});

					sessionStorage.setItem(
						`assets-flow-trend-${identityName}-${asset_type}`,
						JSON.stringify(cachedFlows)
					);
				})
				.catch((er) => {
					console.log(er);
				});
		} else {
			const parsedData = JSON.parse(cachedFlowData);
			setFlowsTrendResponseData(parsedData);

			// Chart header and color
			const { flows } = parsedData;
			const startFromDate = Math.max(
				moment(flows?.[0]?.time).valueOf(),
				moment().subtract(90, 'days').valueOf()
			);
			const chartTitle = getChartTitle(identityName, startFromDate);
			setFlowsChartDetails({
				chartTitle, itemData: {
					queryData: `asset_name:` + identityName
				}
			});
		}
	};

	useEffect(() => {
		if (!openFlow) {
			setFlowsTrendResponseData(undefined);
		}
	}, [openFlow]);

	const handleIssueFilterClick = (idx: any, type: string): void => {
		let hostDetails = responseData?.result.find(
			(item: AssetsObj) => item._id === idx
		);
		let currHostDetails = hostDetails.host_detail;
		let assetsRef = '';
		currHostDetails.map((item: any, index: any) => {
			if (index < 100) assetsRef += item.ip + '-' + item.port + ',';
		});
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		if (idx.includes(',')) {
			idx = `"${idx}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			history.push(
				'/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bd_type:' +
				type +
				'%2Bstatus:Open&sort_by=gen_timestamp',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		} else {
			history.push(
				'/issues?disable_filter=true&hard_refresh=true&order_by=desc&page=1&q=d_name:' +
				encodeURIComponent(idx) +
				'%2Bd_type:' +
				type +
				'%2Bstatus:Open%2B' + encodeURIComponent(timeSearchParam) + '&sort_by=gen_timestamp',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'AssetsIncidents',
					prevUrl,
					prevSearch
				}
			);
		}
	};

	const handleIdAccess = (identitiesid: any, asset_type: string) => {
		// fetchIdentitiesMapping(identitiesid);
		const prevState = history?.location?.state as any;
		const prevUrl = history.location.pathname;
		const prevSearch = history.location.search;
		PostureSearchState.setIsAdSearchApplied(false);
		if (identitiesid.includes(',')) {
			identitiesid = `"${identitiesid}"`;
		}

		const timeSearchParam = getTimeBasedSearchQueryParam(PostureSearchTemplate, tags);

		if (timeSearchParam === "") {
			history.push(
				'/accesses?page=1&q=asset_name%3A' +
				encodeURIComponent(identitiesid) +
				'%2Basset_type:' +
				asset_type +
				'&sort_by=flow_count&order_by=desc',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'PostureAccesses',
					prevUrl,
					prevSearch
				}
			);
		} else {
			history.push(
				'/accesses?page=1&q=asset_name%3A' +
				encodeURIComponent(identitiesid) +
				'%2Basset_type:' +
				asset_type + "%2B" + encodeURIComponent(timeSearchParam) +
				'&sort_by=flow_count&order_by=desc',
				{
					prevWidget: prevState?.breadcrumbId,
					breadcrumbId: 'PostureAccesses',
					prevUrl,
					prevSearch
				}
			);
		}
	};

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const getExceptionData = (item: AssetsObj, rule_name: string) => {
		return [
			`d_name: ${item._id}`,
			`dst_hostnames: ` +
			item.host_detail?.map((item: any) => item.hostname).join(',') ||
			'',
			`dst_ips: ` +
			item.host_detail?.map((item: any) => item.ip).join(',') || '',
			`rule_name: ` + rule_name
		];
	};

	const loadBasePostureActionProps = (
		item: AssetsObj,
		rule_name: string,
		issueCount: number
	) => {
		const basePostureObject = {
			actionData: item,
			menuItems: loadColumnActionLabels(rule_name),
			getContent: () => [`d_name: ${item._id}`, `rule_name: ${rule_name}`],
			exceptionData: getExceptionData(item, rule_name),
			classes: 'posture-column action_button',
			incContent: () => [
				`inc_count: ${issueCount}`,
				`rule_name: ${rule_name}`,
				`d_name: ${item._id}`
			],
			closeIncQuery: `{ "is_external": "false", "d_name": "${item._id}", "status": "Open", "rule_name": "${rule_name}" }`,
			issueCount: issueCount,
			reloadList: fetchAssets,
			markKnownOptions: undefined,
			keyName: `asset - ${item._id}`,
			showId: rule_name,
			config: {
				isSaaSApp: rule_name == 'Unknown SaaS Access' ? true : false
			}
		} as any;

		if (rule_name == 'Exposed Assets') {
			try {
				basePostureObject.markKnownOptions = {
					options: ExposedAssetColumnMarkKnownOptions
				};
			} catch (exception: any) {
				console.log(exception);
			}
		} else if (rule_name == 'Unknown SaaS Access') {
			basePostureObject.markKnownOptions = {
				options: ExposedAssetColumnMarkKnownOptions,
				hidePopUp: true,
				defaultAction: 'known_app'
			};
		}

		return basePostureObject;
	};

	const getRules = (item: AssetsObj, actionType?: string) => {
		let rule = '';
		Object.values(item.rules).map((i: RulesDetailObj) => {
			const isIssue = isIssueType(items, i.name);
			if (isIssue) {
				if (actionType == 'exception') {
					if (i.issue_count > 0 && i.entity_type === 'Asset') {
						rule += i.name + ',';
					}
				} else {
					if (i.issue_count > 0 && i.entity_type === 'Asset') {
						rule += i.name + ',';
					}
				}
			}
		});
		return rule.substring(0, rule.length - 1);
	};

	const getIssueCount = (item: AssetsObj) => {
		return item.rules
			? Object.values(item.rules).reduce(
				(prev: RulesDetailObj, i: RulesDetailObj) => ({
					...prev,
					issue_count: prev.issue_count + i.issue_count
				}),
				{
					issue_count: 0,
					issue_flow_count: 0,
					issue_risk: 4,
					name: ''
				}
			).issue_count
			: 0;
	};

	const loadColumnActionLabels = (rule_name: string) => {
		const IDCopy = JSON.parse(
			JSON.stringify(AssetsActionColumnMenu)
		) as any;
		const exceptionAction = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.ADD_EXCEPTION
		);
		if (exceptionAction) {
			switch (rule_name) {
				case 'Suspicious Inbound Access':
					exceptionAction.label = `Ignore all suspicious inbound accesses to this asset(Add Exception)`;
					break;
				case 'Exposed Assets':
					exceptionAction.label = `Ignore all exposed asset issues to this asset(Add Exception)`;
					IDCopy.splice(1, 0, {
						id: 'mark-known-asset',
						action: PostureMenuItemAction.MARK_KNOWN,
						label: 'Mark asset as known to be exposed…'
					});
					break;
				case 'Shadow Assets':
					exceptionAction.label = `Authorize - this asset can be unmanaged / shadow(Add Exception)`;
					break;
				case 'Shadow Access':
					exceptionAction.label = `Authorize - this asset can have unmanaged / shadow accesses(Add Exception)`;
					break;
				case 'Lack of MFA':
					exceptionAction.label = `Authorize - this asset can lack MFA(Add Exception)`;
					break;
				case 'Auth Protocol Quality':
					exceptionAction.label = `Ignore protocol quality issues for this asset(Add Exception)`;
					break;
				case 'Unknown SaaS Access':
					exceptionAction.label = `Ignore Unknown SaaS issues for this asset(Add Exception)`;
					IDCopy.splice(1, 0, {
						id: 'mark-known-asset',
						action: PostureMenuItemAction.MARK_KNOWN,
						label: 'Mark this asset as a Known (Corporate) SaaS App'
					});
					break;
			}
		}

		const closeIncident = IDCopy.find(
			(item: PostureMenuItem) =>
				item.action == PostureMenuItemAction.CLOSE_INCIDENT
		);
		if (closeIncident) {
			const ruleLabel = getIssueNameById(findNameById(items, rule_name))
			closeIncident.label = `Close existing '${ruleLabel}' issues for this asset`;
		}

		return IDCopy;
	};

	const handleAddtionalResultColumn = (val) => {
		setShowAdditionalResult(val);
		if (additionalResultHighlightClass === "")
			setAdditionalResultHighlightClass("glowing-animation");
	}

	useEffect(() => {
		if (additionalResultHighlightClass !== "" && showAdditionalResult) {
			setTimeout(() => {
				setAdditionalResultHighlightClass("");
			}, 15000)
		}
	}, [additionalResultHighlightClass])

	useEffect(() => {
		let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
		if (filterObj[TIME_BASED_SEARCH_FIELD]) {
			setIsTimeBasedSearchApplied(true);
		} else {
			setIsTimeBasedSearchApplied(false);
		}
	}, [tags])

	const renderData = (response: any) => {
		return response.map((item: AssetsObj) => {
			const itemName: any = item._id.split(' on ')[1];
			let asset_hostsList: any = [];

			{
				item.host_detail &&
					item.host_detail.map((group: any) => {
						if (
							group.hostname !== '' &&
							group.hostname !== undefined &&
							group.hostname !== null &&
							group.ip_port !== '' &&
							group.ip_port !== undefined &&
							group.ip_port !== null
						) {
							if (
								item.host_detail.length === 1 &&
								group.hostname.toLowerCase() === 'unknown'
							) {
								asset_hostsList.push({
									hostname: group.hostname,
									ip_port: group.ip_port,
									city_name: group.city_name,
									country_code2: group.country_code2,
									country_name: group.country_name
								});
							} else {
								if (group.hostname.toLowerCase() !== 'unknown')
									asset_hostsList.push({
										hostname: group.hostname,
										ip_port: group.ip_port,
										city_name: group.city_name,
										country_code2: group.country_code2,
										country_name: group.country_name
									});
							}
						}
					});
			}
			return (
				<React.Fragment>
					<tr>
						{authDetails?.permissions?.Posture?.manual_archive ==
							'readwrite' && (
								<td
									style={{
										width: window.matchMedia(
											'(min-width: 2560px)'
										).matches
											? 50
											: 25,
										padding: '0px'
									}}
								>
									<AMCheckbox
										indeterminate={false}
										onClick={onSetSelectRow.bind(this, item)}
										checked={getSelectRow(item)}
										disabled={false}
									/>
								</td>
							)}
						<td
							style={{ width: '350' }}
							className='action-btn-cell'
						>
							<div className='copy_field copy_host_count'>
								<span className='droplist'>
									<Tooltip
										classes={{
											tooltip:
												'posture-root-column-container scrollbar-container'
										}}
										title={
											<div
												className={
													'service_list2 ' +
													(asset_hostsList?.length ===
														0
														? 'no_border_bottom'
														: '')
												}
											>
												<h2>
													<span
														className='ellipsis_idassetname tooltip_ellipses'
														title={item._id.toString()}
													>
														{item._id.toString()}
													</span>
													<div
														className='copy_idassetname tooltip_copy_title'
														title='Copy this text to Clipboard'
														onClick={() =>
															navigator.clipboard.writeText(
																item._id
															)
														}
													></div>
												</h2>
												{asset_hostsList.length > 0 ? (
													<table>
														<tr>
															<th className='align_left'>
																Hostname
															</th>
															<th className='align_left'>
																IP:Port
															</th>
														</tr>
														{asset_hostsList.map(
															(
																group: any,
																index: any
															) => {
																if (index < 5) {
																	return (
																		<tr>
																			<td
																				title={
																					group.hostname
																				}
																				className='hostname_td align_left'
																			>
																				<span className='ellipsis_idassetname'>
																					{
																						group.hostname
																					}
																				</span>
																				<div
																					className='copy_idassetname float-right-margin-none'
																					title='Copy this text to Clipboard'
																					onClick={() =>
																						navigator.clipboard.writeText(
																							group.hostname
																						)
																					}
																				></div>
																			</td>
																			<td className='align_left'>
																				{
																					group.ip_port
																				}
																			</td>
																		</tr>
																	);
																}
															}
														)}
														{asset_hostsList.length >
															5 ? (
															<tr>
																<td
																	colSpan={2}
																	className='hostname_td align_left'
																>
																	<span className='ellipsis_idassetname'>
																		...
																	</span>
																</td>
															</tr>
														) : null}
													</table>
												) : null}
											</div>
										}
									>
										<div>
											<span className='ellipsis_idassetname droplist'>
												{item._id.split(' on ')[0]}{' '}
												{itemName !== undefined
													? ' on '
													: ''}
												<i style={{ padding: '0px 3px' }}>
													{itemName !== undefined
														? itemName
														: ''}
												</i>
											</span>
											<InfoIcon
												style={{
													fontSize: 14,
													color: '#d4d8e1',
													marginTop: 2,
													marginLeft: 2,
													transform: 'skewX(-10deg)'
												}}
											/>
										</div>
									</Tooltip>
								</span>
								<div
									className='assets copy_idassetname'
									title='Copy this text to Clipboard'
									onClick={() =>
										navigator.clipboard.writeText(item._id)
									}
								></div>
								{
									<BasePostureActionMenu
										actionData={item}
										menuItems={
											getRules(item).length > 0
												? AssetsActionMenu
												: AssetsActionMenu.filter(
													(
														item: PostureMenuItem
													) =>
														item.action ==
														PostureMenuItemAction.MARK_KNOWN
												)
										}
										exceptionData={[
											`d_name:${item._id} `,
											`rule_name: ` +
											getRules(item, 'exception')
										]}
										classes={'assets action_button'}
										getContent={() => [
											`d_name:${item._id} `,
											`rule_name: ` +
											getRules(item, 'exception')
										]}
										incContent={() => [
											`inc_count:${getIssueCount(item)} `,
											`d_name:${item._id} `,
											`rule_name: ` + getRules(item)
										]}
										closeIncQuery={
											`{
				"status": "Open", "is_external": "false", "d_name": "${item._id}"` +
											`, "rule_name": "` +
											getRules(item) +
											`"}`
										}
										issueCount={getIssueCount(item)}
										reloadList={fetchAssets}
										markKnownOptions={{
											options:
												AssetColumnMarkKnownOptions,
											title: 'Classify this asset as…'
										}
										}
										config={{
											isSaaSApp:
												item.rules[
													'Unknown SaaS Access'
												]?.issue_count > 0
													? true
													: false
										}
										}
										keyName={`asset-${item._id}`
										}
									/>
								}
							</div >
						</td >
						<td
							className='asset_type_icons'
							style={{
								width: window.matchMedia('(min-width: 2560px)')
									.matches
									? 112
									: 50
							}}
						>
							{/* <td>{item.type} */}
							<PostureCategory
								type={item.type}
								categories={item?.asset_category_list || []}
								title={item.type.toLowerCase() == 'app' &&
									(item?.asset_category_list && item.asset_category_list?.length > 0)
									? 'Cloud App' : item.type.toLowerCase() == 'service' &&
										(item?.asset_category_list && item.asset_category_list?.length > 0) ? 'Cloud Service' : item.type}
							>
								<div
									className={
										item.type === 'Service'
											? (item?.asset_category_list && item.asset_category_list?.length > 0 ? 'gear_icon_cloud' : 'gear_icon_issue')
											: item?.type === 'Device'
												? 'device_icon'
												: item?.type === 'User'
													? 'user_icon'
													: (item?.asset_category_list && item.asset_category_list?.length > 0 ? 'settings_icon_cloud settings_icon' : 'settings_icon')
									}
								// title={item.is_saas_access ? "SaaS App" :item.type}>								
								></div>
								{/* {item.is_saas_access ?
                                <div className='asset_cloud_icon'>

                                </div>
                                : null
                            } */}

							</PostureCategory>

						</td>

						<td
							style={{
								padding: '0',
								border: 'none!important',
								width: '15%'
							}}
						>
							<tr className='dot_outer'>
								<ScrollIssueData
									data={item.rules}
									handleRuleFilterClick={
										handleRuleFilterClick
									}
									loadBasePostureActionProps={
										loadBasePostureActionProps
									}
									issueList={issueHeader}
									postureObject={item}
									classes={{ containerClass: 'asset-page' }}
									selectedItems={items}
									isAdditionalResultColVisible={showAdditionalResult}
									tableWidth={"307px"}
									isTimeBasedSearchApplied={isTimeBasedSearchApplied}
								/>
							</tr>
						</td>
						<td
							style={{
								width: window.matchMedia('(min-width: 2560px)')
									.matches
									? 128
									: 58
							}}
						>
							{item.flow_count === 0 ? (
								<div>1</div>
							) : (
								(findDifferenceInDays(item.latest_time) <= (widgetData?.posture_view_date_range || 60)) ?
									<div
										className={`${item.flow_count > 1 && 'shadowbox'
											}`}
										id={item._id.toString()}
										onClick={(event) => {
											if (item.flow_count > 1) {
												handleFlowClick(event, {
													id: item._id.toString(),
													risk: undefined,
													asset_type: item.type
												});
											}
										}}
									>
										{item.flow_count
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									</div> :
									<div
										id={item._id.toString()}
									>
										{item.flow_count
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									</div>
							)}
						</td>
						{/*  <td style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 132 : 60 }}>
                            {item.rules_count !== 0 ?
                                <div className="shadowbox num_flows" onClick={handleRuleMatchClick} id={item._id.toString()}>
                                    <span>{item.rules_count}</span>
                                </div>
                                :
                                <div id={item._id.toString()}>
                                    <span>{item.rules_count}</span>
                                </div>
                            }
                        </td> */}
						<td
							style={{
								width: window.matchMedia('(min-width: 2560px)')
									.matches
									? 132
									: 60
							}}
						>
							{item.issue_count !== 0 ? (
								<div
									className='shadowbox num_flows'
									onClick={() => handleIssueFilterClick(item._id.toString(), item.type)}
									id={item._id.toString()}
								>
									<span>
										{convertToCommaValue(item.issue_count)}
									</span>
								</div>
							) : (
								<div id={item._id.toString()}>
									<span>
										{convertToCommaValue(item.issue_count)}
									</span>
								</div>
							)}
						</td>
						<td
							style={{
								width: window.matchMedia('(min-width: 2560px)')
									.matches
									? 124
									: 56
							}}
						>
							{item.identity_count !== 0 ? (
								<div
									className='shadowbox num_flows'
									onClick={() => {
										handleIdAccess(
											item._id.toString(),
											item.type
										);
									}}
									id={item._id.toString()}
								>
									<span>
										{convertToCommaValue(
											item.identity_count
										)}
									</span>
								</div>
							) : (
								<div id={item._id.toString()}>
									<span>
										{convertToCommaValue(
											item.identity_count
										)}
									</span>
								</div>
							)}
						</td>
						{
							showAdditionalResult && <td className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 220 : 110 }}>
								{getAdditionalResultCellValue(item, selectedField, ENTITIES.ASSETS.pageType)}
							</td>
						}
						<td
							style={{
								width: window.matchMedia('(min-width: 2560px)')
									.matches
									? 192
									: 90
							}}
						>
							<div
								className=''
								style={{
									width: window.matchMedia(
										'(min-width: 2560px)'
									).matches
										? 180
										: 90,
									margin: '0 auto'
								}}
							>
								{moment(item.latest_time).format(
									'MMM DD YYYY, hh:mm A'
								)}
							</div>
						</td>
						<td
							style={{
								width: window.matchMedia('(min-width: 2560px)')
									.matches
									? 122
									: 55
							}}
						>
							<IDScore data={item} />
						</td>
					</tr >
				</React.Fragment >
			);
		});
	};

	const handlePaginate = (selectedPage: any) => {
		param.page =
			selectedPage.selected === 0 ? 1 : selectedPage.selected + 1;
		setQuery(param);
	};

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		handleSearchFn(e, tags);
	};

	const handleLensSearch = () => {
		handleLensSearchFn(tags);
	};

	useEffect(() => {
		if ((tagFlag && tags.length >= 0) || isClearAll) {
			param.page = 1;
			param.q = tags.join('+');
			param.d_name = undefined;
			param.risk = undefined;
			param.d_protocol = undefined;
			param.hash = undefined;
			param.e_time = undefined;
			param.s_time = undefined;
			param.rule_name = undefined;
			setQuery(param);
			setIsClearAll(false); // reset clearall status.
			setTagFlag(false);
		}
	}, [tags, tagFlag]);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInput(e.target.value);
	};

	const deleteTag = (index: number) => {
		const anyActivityTimeTag = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
		let newTags = getTagsForSearchInput(tags).filter((tag, i) => i !== index)
		if (anyActivityTimeTag && anyActivityTimeTag.length > 0) {
			newTags = [...newTags, ...anyActivityTimeTag];
		}

		setTags(newTags);

		setTagFlag(true);
		PostureSearchState.setIsAdSearchApplied(false);
	};

	// ID Asset Widget Begins
	const [summaryFlag, setSummaryFlag] = useState(false);
	const [widgetData, setWidgetData] = useState<IdAssetWidget | undefined>();
	const [idWidget, setIdWidget] = useState<IdAssetWidget | undefined>();
	const [ruleWidget, setRuleWidget] = useState<IdAssetWidget | undefined>();
	const [ruleWidgetCopy, setRuleWidgetCopy] = useState<IdAssetWidget | undefined>();
	const [ruleRootIdentity, setRuleRootIdentity] = useState<
		IdAssetWidget | undefined
	>();
	const [loading, setLoading] = useState(false);

	const handleCheck = () => {
		setCheckFlag((flag) => !flag);
	};

	const [showGraph, setShowGraph] = useState(true);

	const [openModal, setOpenModal] = useState<boolean>(false);

	const handleClose = () => {
		setOpenModal(false);
	}

	const openSettingModal = () => {
		setOpenModal(true);
	}

	const [items, setItems] = useState([]);

	const getPlayBookData = (updatedItems: any) => {
		setShowGraph(false);
		setSelectedItems(updatedItems);

		// Create another array with _id property as value
		const newArray = updatedItems.map(item => item._id);
		// Sort the new array based on the order property value
		newArray.sort((a, b) => {
			const orderA = updatedItems.find(item => item._id === a).order;
			const orderB = updatedItems.find(item => item._id === b).order;
			return orderA - orderB;
		});
		setIssueHeaders(newArray);


		if (JSON.parse(localStorage.getItem('assetsRule'))) {

			const filteredData = JSON.parse(localStorage.getItem('assetsRule'))?.filter(item => newArray.includes(item.name));
			setRuleWidget(filteredData);

		}


	}

	useEffect(() => {
		if (ruleWidget) {
			setShowGraph(true);
		}
	}, [ruleWidget])


	const fetchPlayBookData = async () => {
		await Api.get("/pbviewplaybooks?type=asset", {
			timeout: 30000,
		})
			.then((res) => {
				//res.data = responseAdapter(res.data);
				setItems(res.data);
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					window.location.href = "/login";
				}
			});
	};

	useEffect(() => {

		const fetchData = async () => {
			try {
				const a = await fetchPlayBookData();
				const b = await fetchWidget();
				//  const c =   await setTimeout(()=>{fetchUserSelection()},300);


			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();

	}, []);


	const fetchWidget = () => {
		setLoading(true);
		const queryParams = {
			...(hard_refresh_state && { hard_refresh: 'true' })
		};
		PostureSummary.GET(
			queryParams,
			(res: IApiResponse) => {
				if (res.status === 200) {
					setWidgetData(undefined);
					setLoading(false);
					setTimeout(() => setWidgetData(res.data));
					//setIssueHeaders(res.data.asset_rule_types);
					//Set ID widget
					setIdWidget(res.data.asset_summary);
					const rulesArray: any = [];
					//Set All rule root data
					rulesArray.push(
						...Object.values(res.data.asset_rules_summary.rules)
					);
					//Set Asset Widget Data
					// setRuleWidget(rulesArray);
					// setRuleWidgetCopy(rulesArray);
					localStorage.setItem('assetsRule', JSON.stringify(rulesArray));

					const rulesRoot: any = [];
					rulesRoot.push(
						...Object.values(
							res.data.asset_rules_summary.root_cause_detail
						)
					);
					//SetRuleRootCount Data
					setRuleRootIdentity(
						res.data.asset_rules_summary.root_cause_detail
					);
					scrollToIssueColumn();
				}
			},
			(err: any) => { }
		);
	};

	const handleGotoPage = () => {
		let pageValue = (
			document.getElementsByName('page_no')[0] as HTMLTextAreaElement
		).value;
		pageValue = pageValue !== '' ? pageValue : '1';
		let currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set('page', pageValue);
		(
			document.getElementsByName('page_no')[0] as HTMLTextAreaElement
		).value = '';
		history.push(
			window.location.pathname + '?' + currentUrlParams.toString()
		);
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	};

	//Download CSV
	const headers = [
		{ label: 'Asset Name', key: '_id' },
		{ label: 'Asset Type', key: 'type' },
		{ label: 'Host 1', key: 'host_detail[0].hostname' },
		{ label: 'Host 2', key: 'host_detail[1].hostname' },
		{ label: 'Host 3', key: 'host_detail[2].hostname' },
		{ label: 'Host 4', key: 'host_detail[3].hostname' },
		{ label: 'Host 5', key: 'host_detail[4].hostname' },
		{ label: 'IP:Port 1', key: 'host_detail[0].ip_port' },
		{ label: 'IP:Port 2', key: 'host_detail[1].ip_port' },
		{ label: 'IP:Port 3', key: 'host_detail[2].ip_port' },
		{ label: 'IP:Port 4', key: 'host_detail[3].ip_port' },
		{ label: 'IP:Port 5', key: 'host_detail[4].ip_port' },
		{ label: '# Flows', key: 'flow_count' },
		// { label: "Overall # Issues Matched", key: "rules_count" },
		{ label: 'Overall # Incidents', key: 'issue_count' },
		{ label: '# Identites Accessed', key: 'identity_count' },
		{ label: 'Latest Activity', key: 'latest_time' },
		{ label: 'Score', key: 'score' }
	];

	const [downData, setDownData] = useState<string | Data>([]);
	const [totalDownloaded, setTotalDownloaded] = useState<number | undefined>(
		0
	);
	const [loadingCSV, setLoadingCSV] = useState({
		loading: false,
		setData: false
	});
	const currDownData: any = [];
	const csvLinkEl = useRef<any>();

	const getUserList = async (selectedPage?: any) => {
		param.page = selectedPage === 0 ? 1 : selectedPage + 1;
		param.rpp = 100;
		const searchHeaders = await getSearchHeaders(param)
		return Api.get('/assets?is_external_not_allowed=true', {
			params: { ...param, is_export_csv: true },
			paramsSerializer: (params) =>
				qs.stringify(params, { arrayFormat: 'repeat' }),
			...searchHeaders
		})
			.then((res) => {
				res.data = validateLocalIps(res.data, 'host_detail');
				currDownData.push(...formatCSVData(res));
				return currDownData;
			})
			.catch((error: any) => {
				setLoadingCSV({ loading: false, setData: false });
				if (error.response.status === 500 || error.response.status === 524) {
					addToast('Sorry, something went wrong there, try again.', {
						appearance: 'error',
						autoDismiss: true
					});
				} else if (error.response.status === 404) {
					addToast(
						'We are not able to find associated email, please check and try again.',
						{
							appearance: 'error',
							autoDismiss: true
						}
					);
				}
			});
	};

	const downloadCSV = async () => {
		setTotalDownloaded(0);
		setLoadingCSV({ loading: true, setData: false });
		let i = 0;
		do {
			const downData = await getUserList(i);
			setTotalDownloaded(i * 100);
			if (i > Math.floor(totalCount! / 100)) {
				setDownData(downData);
				setTimeout(() => {
					setLoadingCSV({ loading: false, setData: true });
					if (csvLinkEl && csvLinkEl?.current && csvLinkEl?.current?.link) {
						csvLinkEl?.current?.link?.click();
					}

				});
			}
			i = i + 1;
		} while (i <= Math.floor(totalCount! / 100) + 1);
	};

	useEffect(() => {
		if (issueHeader?.length > 0 && responseData?.result?.length) {
			setShowAnimation(true);
		}
	}, [responseData, issueHeader]);

	const scrollToIssueColumn = () => {
		scrollToIssueColumnFn(query, q, scrollIssueRef, 'assets');
	};

	useEffect(() => {
		updateColumnEffect(showAnimation, history, q, 'assets');
	}, [showAnimation]);

	const handleFilterWidget = (widgetState: any) => {
		if (filterData) {
			setOpenPanelFilterWidgets(widgetState);
		} else {
			addToast(
				'Unable to apply filters, please retry in few minutes or contact administrator.',
				{ appearance: 'error' }
			);
		}
	};

	const handleFilterDisabled = (widgetState: any) => {
		setDisabledFilter(widgetState);
		// reloadIssuesDisabled(widgetState);
	};

	const onCloseAdvanceSearch = useCallback(() => {
		setShowAdvanceSearch(false);
	}, []);

	const applySearchHandler = useCallback(
		(data: any, clearAll: boolean = false, deletedKeys = []) => {
			let tempFilterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
			let filterObj = {};
			for (let i in tempFilterObj) {
				if (deletedKeys.indexOf(i) == -1)
					filterObj[i] = tempFilterObj[i];
			}

			filterObj = { ...filterObj, ...data }


			if (clearAll) {
				if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
					clearAll = false;
					filterObj = { [TIME_BASED_SEARCH_FIELD]: filterObj[TIME_BASED_SEARCH_FIELD] };
				}
			}
			handleApplyAdvancedSearch(
				filterObj,
				clearAll,
				setIsClearAll,
				setTags,
				setTagFlag,
				ENTITIES.ASSETS.pageType
			);
			resetSelectionState();
		},
		[tags, q]
	);

	const applyTimeBasedSearchHandler = ((data, clear) => {
		let filterObj = getSearchFilterObjectFromTags(PostureSearchTemplate, tags);
		if (clear) {
			if (filterObj.hasOwnProperty(TIME_BASED_SEARCH_FIELD)) {
				delete filterObj[TIME_BASED_SEARCH_FIELD];
				clear = false;
			}
			clear = Object.keys(filterObj).length === 0 ? true : false;
		} else {
			filterObj = { ...filterObj, ...data }
		}
		handleApplyAdvancedSearch(
			filterObj,
			clear,
			setIsClearAll,
			setTags,
			setTagFlag,
			ENTITIES.IDENTITY.pageType
		);
	})

	useEffect(() => {
		handleManualSearch(tags, ENTITIES.ASSETS.pageType)
	}, [tags])


	const onAdvanceSearchClick = () => {
		setShowAdvanceSearch(true);
	}

	const handleTabClick = (to: string) => {
		if (PostureSearchState.isAdSearchApplied) {
			const tempTags = tags.map(item => encodeURIComponent(item));
			history.push(`/${to}?order_by=desc&sort_by=score&q=${tempTags.join('%2B')}`)
		} else {
			history.push(`/${to}?order_by=desc&sort_by=score`);
		}
	}

	useEffect(() => {
		if (PostureSearchState.isAdvancedSearchOpen) {
			setShowAdvanceSearch(true)
		}
	}, [])

	useEffect(() => {
		PostureSearchState.setIsAdvancedSearchOpen(showAdvanceSearch);
	}, [showAdvanceSearch])

	const matchingPlaybookFoundFn = () => {
		const newParams = { ...query };
		newParams.entity_type = ENTITIES.ASSETS.id;
		setQuery(newParams);
		matchingPlaybookFound(newParams, getSearchHeaders)
	}

	const onFieldSelect = (field, fields) => {
		setSelectedField(field);
		setAdditionalFields(fields);
	}

	return (
		<>
			{!summaryFlag && widgetData ?
				<div className="idasset_widget_summary_box"
					style={{
						pointerEvents: showAdvanceSearch ? 'none' : 'initial'
					}}
				>
					{(idWidget !== undefined &&
						ruleWidget !== undefined &&
						ruleRootIdentity !== undefined) ?
						<>
							<SummaryWidget issues={widgetData} />
							<ReportByIdAsset customData={{
								title: "ASSETS",
								subtitle: "Assets",
								is_exclude: checkFlag,
								totalCount: widgetData?.asset_count, issueCount: widgetData?.asset_count_with_issues
							}} issues={idWidget} />
							{showGraph && <RuleRootWidget customData={{
								title: "ASSET ISSUES",
								chartTitle: "Assets",
								is_exclude: checkFlag,
								totalCount: widgetData?.asset_rules_summary.rules_count,
								issueCount: widgetData?.asset_count_with_issues
							}} issues={ruleWidget} rootDetail={ruleRootIdentity} groupData={items} selectedData={selectedItems} />}
							{/* <ReportByIdAsset customData={{title:"ASSETS", 
                      is_exclude : checkFlag,
                      totalCount:widgetData?.asset_count, issueCount:widgetData?.asset_count_with_issues}} issues={assetWidget}/> */}
						</>
						: null}
				</div>
				:
				(!responseData ? null :
					<div className="idasset_widget_summary_box">
						<div className='summary_widget'>
						</div>
						<div className='idasset_chart'></div>
						<div className='idasset_chart'></div>
					</div>)
			}
			<div className="clrBoth margintop10"></div>
			{
				useMemo(() => {
					return <AMBreadcrumb selectedItems={items} />
				}, [tags, items])
			}
			<div className="margintop24">
				<div className="flow_table_container">

					<div style={{ "display": "none" }} className="idassetSwitch">
						Hide Excluded Assets&nbsp; &nbsp;<SwitchButton onChange={handleCheck} checked={checkFlag} />
					</div>

					<div className={showAdditionalResult ? "posture_search_section posture_search_section_additional_col" : "posture_search_section posture_search_section_no_additional_col"}>
						<PostureTabTooltip>
							<div style={{ width: 'fit-content' }}>
								<div
									data-id='tab1'
									style={{ marginRight: 5 }}
									className={
										'tab' +
										(!PostureSearchState?.activeTabs
											?.identities
											? ' disable-config-item disable-tab disable-tab'
											: '')
									}
									onClick={() => handleTabClick('identities')}
								>
									IDENTITIES
								</div>
								<div
									data-id='tab2'
									style={{ marginRight: 5 }}
									className={
										'tab selected_tab ' +
										(!PostureSearchState?.activeTabs
											?.accesses
											? ' '
											: '')
									}
								>
									ASSETS{' '}
									{totalCount != undefined
										? '(' +
										convertToCommaValue(totalCount) +
										')'
										: ''}
								</div>
								<div
									data-id='tab3'
									style={{ marginRight: 5 }}
									className={
										'tab' +
										(!PostureSearchState?.activeTabs
											?.accesses
											? ' disable-config-item disable-tab disable-tab'
											: '')
									}
									onClick={() => handleTabClick('accesses')}
								>
									ACCESSES
								</div>
								<div
									data-id='tab4'
									style={{ marginRight: 5 }}
									className={
										'tab' +
										(!PostureSearchState?.activeTabs
											?.directory
											? ' disable-config-item disable-tab disable-tab'
											: '')
									}
									onClick={() => handleTabClick(`directory`)}
								>
									IDENTITY SYSTEMS
								</div>
							</div>
						</PostureTabTooltip>
						<TimeBasedSearchContainer onApply={applyTimeBasedSearchHandler} filters={getTagsForTimeBasedSearch(tags)} apiUrl={'/posture/daily_flow_count?entity_type=asset'} pageType={'assets'}></TimeBasedSearchContainer>
						<PostureArchiveTooltip numberOfDays={widgetData?.posture_view_date_range} />
						<PostureActionButtons
							entity='assets' selectAll={selectAll}
							onArchiveClick={onArchive.bind(null, reloadAssets)}
							selectCount={selectCount}
							isOpenIncidents={isIncidentsOpen} onArchiveEstimate={onArchiveEstimate.bind(null)}
							ref={archiveBtnRef} isHidden={true} />
						{showAdvanceSearch && <PostureFilter
							filterTemplate={PostureSearchTemplate.filter(item => item.search_key !== TIME_BASED_SEARCH_FIELD)}
							notSupportedKeys={notSupportedKeys} filters={tags}
							isOpen={showAdvanceSearch} onApplyFilter={applySearchHandler}
							onClose={onCloseAdvanceSearch}
							disableCreatePlaybookBtn={isValidResultCountThreshold(responseData)}
							matchingPlaybookFoundFn={matchingPlaybookFoundFn}
							entityType={ENTITIES.ASSETS.id}
							query={query}
							showCreatePlaybookBtn={query && query['playbook_type'] === POSTURE_ISSUE.type ? false : true}
							preMetaDataHandler={preMetaDataHandler}
							isTimeBasedSearchApplied={isTimeBasedSearchApplied}
						/>}
						{
							openPanelFilterWidgets ?
								<FilterWidget toggleWidget={handleFilterWidget}
									disabledState={handleFilterDisabled}
									updateAfterFilter={reloadAssets} issueFiltersData={filterData} /> : ""
						}
					</div>

					<div id="tab1" className="tab-panel">
						<table id="table" className={showAdditionalResult ? "id_asset_tb box_shadow sticky_table_top posture-table-width" : "id_asset_tb box_shadow sticky_table_top"}>
							<thead>
								<tr>
									<th colSpan={13} className="rounded_topheader" style={{ background: "#ffffff", color: "#4e5267", paddingRight: 0 }} >
										<div className='flex-space-between'>
											<div>
												<div className="reload_container">
													<div ref={reloadRef} className="reload_color_icon" onClick={reloadAssets}></div>
												</div>

												<div className="table_title">Last Updated: {lastUpdate}</div>
											</div>
											{/* <div className="search_white_icon">&nbsp;</div> */}
											{/* <input type="text" className="table_search clear_search" onKeyUp={handleSearch} placeholder="Searched item" title=""/> */}
											<div className='maxWidth550 display-flex'>
												<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
													<div className="search_container">
														<div className="search_white_icon"
															style={{ cursor: 'pointer' }}
															onClick={handleLensSearch}
														>&nbsp;</div>&nbsp;
														<Tags tags={getTagsForSearchInput(tags)} deleteTag={deleteTag} />&nbsp;
														{/* {tags?.map((tag, index) => <div key={tag + index} title={tag.toString()} className="search_tag">{tag}&nbsp;<span className="search_close_btn" onClick={() => deleteTag(tag)}>X</span></div>)}&nbsp; */}
														<input
															className="search_input"
															value={searchInput}
															placeholder="Search..."
															onKeyDown={handleSearch}
															onChange={handleSearchChange}
														/>
														{getTagsForSearchInput(tags)?.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={() => {
															setTagFlag(true);
															PostureSearchState.setIsAdSearchApplied(false)
															if (isTimeBasedSearchApplied) {
																const tempTags = tags.filter(item => item.indexOf(TIME_BASED_SEARCH_FIELD) > -1);
																setTags(tempTags);
															} else {
																setTags([])
															}
														}}>&nbsp;X</span> : null}
													</div>
												</div>
												<div className='issue-advance-search-btn-con display_flex_center'
													style={{ float: 'right' }}
												><button style={{ float: 'left' }} onClick={onAdvanceSearchClick} className={'button_styled issue-advance-search'} >Advanced Search</button>
													<Tooltip classes={{ tooltip: 'playbook-level-widget-tooltip' }} title="Configure View : Asset Issue">
														<div style={{ float: 'right' }}
															className=" gear-setting-icon setting-identity-icon"
															onClick={openSettingModal}>
														</div>
													</Tooltip>
													<PlaybookTableActions actions={MoreActionsOptions} rowData={{}}
														isDisabled={selectCount == 0 || showAdvanceSearch || isTimeBasedSearchApplied}
													/>
												</div>

											</div>
										</div>

									</th>
								</tr>

								<tr>
									{
										authDetails?.permissions?.Posture?.manual_archive == 'readwrite' &&
										<th className={showAdditionalResult ? "posture-table-checkbox-column" : ''} style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 60 : 30, padding: '0px' }}>
											<AMMultiCheckbox
												indeterminate={selectAllIndeterminate}
												menuItems={PostureMultiSelectCheckboxItems}
												onClick={PostureMultiSelectCheckboxItems[0].callback}
												// checked={selectAll || false}
												checked={selectAll}
												// disabled={!openIssuesCount || openIssuesCount < 1}
												disabled={false}
											/>
										</th>
									}
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 500 : 415 }} data-headerName="_id" className={(selectedColumn == '_id' ? currentSort : " tablesort_down")} onClick={handleSort}><span className='align_left float_left'>Asset Name</span></th>
									<th style={{ minWidth: window.matchMedia("(min-width: 2560px)").matches ? 100 : 50 }} data-headerName="type" className={(selectedColumn == 'type' ? currentSort : " tablesort_down")} onClick={handleSort}>Type</th>
									<th className="no_padding" style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50 }}>
										<ScrollIssueHeader issueList={issueHeader} title='Asset Issue' query={query} handleSort={handleSort} ref={scrollIssueRef} selectedItems={items} isAdditionalResultColVisible={showAdditionalResult} tableWidth={"307px"} />
									</th>
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 116 : 58 }} data-headerName="flow_count" className={(selectedColumn == 'flow_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall Number of Flows</th>
									{/* <th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 120 : 60 }} data-headerName="Overall Number of Issues Matched" className={rulesCountSort} onClick={handleSort}>Overall<br />Number<br />of<br />Issues<br />Matched</th> */}
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 120 : 60 }} data-headerName="issue_count" className={(selectedColumn == 'issue_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Overall<br />Number<br />of<br />Incidents</th>
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 112 : 56 }} data-headerName="identity_count" className={(selectedColumn == 'identity_count' ? currentSort : " tablesort_down")} onClick={handleSort}>Number<br />of<br />Identities<br />Accessed</th>
									{showAdditionalResult && (
										selectedField !== null ? (
											<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 220 : 110 }} data-headerName={selectedField['search_key']} className={additionalResultHighlightClass + ' ' + (additionalFields.includes(selectedColumn) ? currentSort : " tablesort_down") + (additionalFields.length > 1 ? ' additional_result_sort_icon' : '')} onClick={handleSort}>
												<div className='additional-results-col'>
													<AdditionalResult options={tags} filterTemplate={PostureSearchTemplate} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.ASSETS.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
												</div>
											</th>)
											: (
												<th className={additionalResultHighlightClass} style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 220 : 110 }}>
													<div className='additional-results-col'>
														<AdditionalResult options={tags} filterTemplate={PostureSearchTemplate} notSupportedKeys={notSupportedKeys} pageType={ENTITIES.ASSETS.pageType} onFieldSelect={onFieldSelect} handleAddtionalResultColumn={handleAddtionalResultColumn} />
													</div>
												</th>
											)
									)
									}
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 180 : 100 }} data-headerName="latest_time" className={(selectedColumn == 'latest_time' ? currentSort : " tablesort_down")} onClick={handleSort}>Latest Activity</th>
									<th style={{ width: window.matchMedia("(min-width: 2560px)").matches ? 110 : 55 }} data-headerName="score" className={(selectedColumn == 'score' ? currentSort : " tablesort_down")} onClick={handleSort}>Score</th>
								</tr>
							</thead>

							<tbody>
								{
									loading ?
										<tr>
											<td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
										</tr> :
										responseData?.result == 'error' || responseData?.result.length == 0 ?
											<tr>
												<td colSpan={13} style={{ textAlign: 'center' }}>{tableFetchError}</td>
											</tr>
											:
											responseData && responseData.result !== null && responseData.result.length > 0 ? renderData(responseData.result) :
												<tr>
													<td colSpan={13} style={{ textAlign: 'center' }}><div className='loader spinner'></div></td>
												</tr>
								}
							</tbody>

						</table>
						<div className="clrBoth"></div>
					</div>
					<div className="download_section">
						<button type={('button')} onClick={downloadCSV} className={"width180 " + (loadingCSV.loading ? 'loader export_loader' : 'button_styled')}>Export CSV</button>

						{loadingCSV.loading ?
							<div className='loading_info'>
								Received <span ><b>{totalDownloaded}</b></span> of <span ><b>{totalCount}</b></span> Assets
							</div>
							: null}
						{PartnerConfigState?.PartnerShortProduct && <CSVLink
							headers={getHeaders(headers, issueHeader, 'Asset Issues')}
							filename={`${PartnerConfigState?.PartnerShortProduct?.replaceAll(" ", "_")}_Assets.csv`}
							data={downData!}
							ref={csvLinkEl}
						/>}
						<div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
							<Link to={'/archivedata'} className='link-text font12'>Assets inactive for more than 60 days are archived here.</Link>
						</div>
					</div>

					<div className="float_right goto_page">Go to page:
						<input
							type="text"
							name="page_no"
							className=""
							defaultValue=""
							placeholder="No."
						/>
						<button type="button" onClick={handleGotoPage} className="button_gray float_right">Go</button>
					</div>
					<ReactPaginate
						previousLabel={"← Previous"}
						nextLabel={"Next →"}
						forcePage={param.page !== undefined ? (param.page - 1) : 0}
						pageCount={responseData && responseData.result.length > 0 ? (Math.ceil(responseData.total / responseData.rpp)) : 1}
						pageRangeDisplayed={2}
						marginPagesDisplayed={2}
						containerClassName={"pagination"}
						previousLinkClassName={"pagination__link"}
						nextLinkClassName={"pagination__link"}
						disabledClassName={"pagination__link--disabled"}
						activeClassName={"pagination__link--active"}
						onPageChange={handlePaginate}
					/>
				</div>
			</div >
			{showRuleMatch ? <RuleMatch prevURL={prevUrl} data={ruleMatch} handleRuleMatchClose={() => setShowRuleMatch(false)} /> : null
			}
			{showIDAccess ? <IdAccess data={idAccess} prevURL={prevUrl} aname={asset_name} handleIDAccessClose={() => setShowIDAccess(false)} /> : null}
			{openFlow ? <FlowTrend data={flowsTrendResponseData} chartDetails={flowsChartDetails} closeFlowPopup={() => setOpenFlow(false)} /> : ''}
			<PlayBookView widId='asset' openModal={openModal} handleClose={handleClose} getData={getPlayBookData}></PlayBookView>

		</>
	)
}

export default React.memo(withQueryParams({
	q: StringParam,
	page: StringParam,
	rpp: NumberParam,
	sort_by: StringParam,
	order_by: StringParam,
	rule_name: StringParam,
	d_protocol: StringParam,
	risk: NumberParam,
	hash: StringParam,
	d_name: StringParam,
	s_time: NumberParam,
	e_time: NumberParam,
	hard_refresh: StringParam,
	persist_active_flg: StringParam,
	filters: withDefault(ArrayParam, []),
	entity_type: StringParam,
	playbook_type: StringParam
}, Assets));